import { BaseModel } from '@/domain/model/base_model.ts';

export class TrxFeeModel extends BaseModel {
  amount: number;
  gram: number;
  name: string;
  trxUID: string;
  type: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    amount: number,
    gram: number,
    name: string,
    trxUID: string,
    type: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.amount = amount;
    this.gram = gram;
    this.name = name;
    this.trxUID = trxUID;
    this.type = type;
  }

  public static fromJson(data: any): any | TrxFeeModel {
    try {
      return new TrxFeeModel(
        data.id,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.uid,
        data.flags,
        data.amount,
        data.gram,
        data.name,
        data.trx_uid,
        data.type
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): TrxFeeModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(TrxFeeModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
