import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useBalanceContext } from '@/delivery/ui/admin/data_member/member_list/context/balance_context.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';

const DialogLockGold = (props: ModalDialogProps) => {
  const { handleLockGoldModal, handleReleaseGold, formBalance, setFormBalance } = useBalanceContext();

  return (
    <ModalDialog overflow={false} size="small" title="Unlock Gold" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField block label={'Amount (gr)'} value={formBalance.amount} disabled />
        <TextField
          block
          type={'number'}
          label={'Amount to Unlock (gr)'}
          // value={formBalance?.new_amount}
          onChange={e => {
            setFormBalance({ ...formBalance, new_amount: parseFloat(e.target.value) });
          }}
        />
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleLockGoldModal.setState(false)} />
        <Button label="Unlock" onClick={() => handleReleaseGold()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogLockGold;
