import { useEffect } from 'react';
import GoldPricePage from '@/delivery/ui/admin/gold_price';
import { GoldPriceProvider } from '@/delivery/ui/admin/gold_price/context/gold_price_context';

const ViewGoldPrice = () => {
  useEffect(() => {
    document.title = 'Harga Emas';
  }, []);

  return (
    <div>
      <GoldPriceProvider>
        <GoldPricePage />
      </GoldPriceProvider>
    </div>
  );
};

export default ViewGoldPrice;
