import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import ProfileInterface, { ProfileValue } from '@/domain/repository/interface/profile_interface.ts';
import { Profile } from '@/domain/constant/api_list.ts';
import { ProfileModel } from '@/domain/model/profile_model.ts';
import { UserModelQuery } from '@/domain/model/user_model_query.ts';

interface roleValue {
  data: any;
  pagination: any;
}

export class ProfileRepository implements ProfileInterface {
  api = new ApiClient();

  async fetchProfile(data: ProfileValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Profile.FETCH, {
            params: data
          })
          .then(result => {
            const data = UserModelQuery.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role: roleValue = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showProfile(data: ProfileValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Profile.SHOW + data.uuid, {})
          .then(result => {
            const data = ProfileModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateProfile(data: ProfileValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(Profile.UPDATE + data.uuid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
