import useAlertToast from '@/delivery/hooks/useAlertComponent';
import React, { createContext, useContext, ReactNode, useState } from 'react';
import { UserStatisticRepository } from '@/domain/repository/user_statistic_repository.ts';
import { UserStatisticModel } from '@/domain/model/user_statistic_model.ts';
import { MemberChartRepository } from '@/domain/repository/dashboard/member_chart_repository.ts';
import { MemberChartModel } from '@/domain/model/dashboard/member_chart_model.ts';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import { TransactionChartRepository } from '@/domain/repository/dashboard/transaction_chart_repository.ts';
import TransactionChartModel from '@/domain/model/dashboard/transaction_chart_model.ts';
import TransactionSummaryModel from '@/domain/model/dashboard/transaction_summary_model.ts';

interface OverviewContextProps {
  children: ReactNode;
}

interface OverviewParams {
  start_date?: string;
  end_date?: string;
}

interface OverviewContextValue {
  transactionChartSellData: TransactionChartModel[];
  transactionChartBuyData: TransactionChartModel[];
  memberRegisterChart: MemberChartModel[];
  numberTotalTransaction: number;
  loadingNumberTransaction: boolean;
  usersNumberTotalRegister: number;
  loadingUserRegister: boolean;
  handleFetchUserAsNumberTotalRegister: () => void;
  handleFetchTransactionAsNumberTotalTransaction: () => void;
  handleRegisterDailyChart: (_data: OverviewParams) => Promise<void>;
  transactionChartSell: (_data: OverviewParams) => Promise<void>;
  transactionChartBuy: (_data: OverviewParams) => Promise<void>;
}

const StockGoldContext = createContext<OverviewContextValue | null>(null);

const userStatistic = new UserStatisticRepository();
const memberRegisterChartRepository = new MemberChartRepository();
const transactionStatistic = new TransactionChartRepository();

const OverviewProvider: React.FC<OverviewContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  //user
  const [usersNumberTotalRegister, setUsersNumberTotalRegister] = useState<number>(0);
  const [loadingUserRegister, setLoadingUserRegister] = useState<boolean>(false);

  //transaction
  const [numberTotalTransaction, setNumberTotalTransaction] = useState<number>(0);
  const [loadingNumberTransaction, setLoadingNumberTransaction] = useState<boolean>(false);

  const [memberRegisterChart, setMemberRegisterChart] = useState<MemberChartModel[]>([]);

  //statistic
  const [transactionChartSellData, setTransactionChartSellData] = useState<TransactionChartModel[]>([]);
  const [transactionChartBuyData, setTransactionChartBuyData] = useState<TransactionChartModel[]>([]);

  //COUNT USERS
  const handleFetchUserAsNumberTotalRegister = () => {
    setLoadingUserRegister(true);

    userStatistic
      .countUserStatus()
      .then((result: any) => {
        const res: UserStatisticModel = result;
        setLoadingUserRegister(false);

        setUsersNumberTotalRegister(res.active + res.inactive);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //COUNT TRANSACTION
  const handleFetchTransactionAsNumberTotalTransaction = () => {
    setLoadingNumberTransaction(true);

    transactionStatistic
      .transactionSummary()
      .then((result: any) => {
        const res: TransactionSummaryModel = result;
        setLoadingNumberTransaction(false);
        setNumberTotalTransaction(res.totalTransaction.total);
      })
      .catch(error => {
        alertToast.errorAlert(error);
        setLoadingNumberTransaction(false);
      });
  };

  //MEMBER CHART DAILY
  const handleRegisterDailyChart = async (_data: OverviewParams) => {
    const params = {
      date_range: _data.start_date ? [`${_data.start_date}`, `${_data.end_date}`] : null
    };

    await memberRegisterChartRepository
      .statisticRegisterDaily(params)
      .then((result: any) => {
        setMemberRegisterChart(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //TRANSACTION SELL
  const transactionChartSell = async (_data: OverviewParams) => {
    const params = {
      type: TYPE_TRANSACTION.SELL,
      status: STATUS_TRANSACTION.SUCCESS,
      date_range: _data.start_date ? [`${_data.start_date}`, `${_data.end_date}`] : null
    };

    await transactionStatistic.statisticDashboardTransaction(params).then((result: any) => {
      setTransactionChartSellData(result);
    });
  };

  //TRANSACTION BUY
  const transactionChartBuy = async (_data: OverviewParams) => {
    const params = {
      type: TYPE_TRANSACTION.BUY,
      status: STATUS_TRANSACTION.SUCCESS,
      date_range: _data.start_date ? [`${_data.start_date}`, `${_data.end_date}`] : null
    };

    await transactionStatistic.statisticDashboardTransaction(params).then((result: any) => {
      setTransactionChartBuyData(result);
    });
  };

  const contextValue: OverviewContextValue = {
    transactionChartSellData,
    transactionChartBuyData,
    memberRegisterChart,
    numberTotalTransaction,
    loadingNumberTransaction,
    usersNumberTotalRegister,
    loadingUserRegister,
    handleFetchUserAsNumberTotalRegister,
    handleFetchTransactionAsNumberTotalTransaction,
    handleRegisterDailyChart,
    transactionChartSell,
    transactionChartBuy
  };

  return <StockGoldContext.Provider value={contextValue}>{children}</StockGoldContext.Provider>;
};

const useOverviewContext = (): OverviewContextValue => {
  const context = useContext(StockGoldContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a OverviewProvider');
  }
  return context;
};

export { OverviewProvider, useOverviewContext };
