import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { useAccountRequestContext } from '@/delivery/ui/admin/data_dca/account_request/context/account_request_context.tsx';
import { DCA_CLOSE_METHOD_LIST } from '@/domain/constant/dca/close_method.ts';

const DialogCloseAccountRequest = (props: ModalDialogProps) => {
  const { handleCloseDCA, handleUpdateAccountRequestModal, formAccountRequest, setFormAccountRequest } =
    useAccountRequestContext();
  return (
    <ModalDialog overflow={false} size="small" title="Close DCA" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Select2
          label={'Close Method'}
          isSearchable={false}
          value={formAccountRequest.status}
          options={DCA_CLOSE_METHOD_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          onChange={e => setFormAccountRequest({ ...formAccountRequest, close_method: e.value })}
        />
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleUpdateAccountRequestModal.setState(false)} />
        <Button
          label="Close DCA"
          onClick={() => {
            handleCloseDCA();
          }}
        />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogCloseAccountRequest;
