import Navbar from '@/delivery/components/organisms/navbar/navbar';
import DinaranSetting from '@/delivery/ui/admin/settings/dinaran_setting/dinaran_setting.tsx';

const DinaranSettingPage = () => {
  return (
    <div>
      <Navbar label={'Dinaran Setting'}></Navbar>
      <DinaranSetting />
    </div>
  );
};

export default DinaranSettingPage;
