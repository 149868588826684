import { BaseModel } from '@/domain/model/base_model.ts';

export class VendorModel extends BaseModel {
  code: string;
  name: string;
  type: string;
  description: string;
  status: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    code: string,
    name: string,
    type: string,
    description: string,
    status: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.code = code;
    this.name = name;
    this.type = type;
    this.description = description;
    this.status = status;
  }

  public static fromJson(data: any): VendorModel | any {
    try {
      return new VendorModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.code,
        data.name,
        data.type,
        data.description,
        data.status
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): VendorModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(VendorModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
