import { useEffect } from 'react';
import AffiliateTransactionPage from '@/delivery/ui/admin/reseller_affiliate/affiliate_transaction';
import { AffiliateTransactionProvider } from '@/delivery/ui/admin/reseller_affiliate/affiliate_transaction/context/affiliate_transaction_context';

const ViewAffiliateTransaction = () => {
  useEffect(() => {
    document.title = 'Affiliate Transaction';
  }, []);

  return (
    <div>
      <AffiliateTransactionProvider>
        <AffiliateTransactionPage />
      </AffiliateTransactionProvider>
    </div>
  );
};

export default ViewAffiliateTransaction;
