import { BaseModel } from '@/domain/model/base_model.ts';

export class RegionCityModel extends BaseModel {
  parentID: string;
  code: string;
  name: string;
  type: string;
  group: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    parentID: string,
    code: string,
    name: string,
    type: string,
    group: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.parentID = parentID;
    this.code = code;
    this.name = name;
    this.type = type;
    this.group = group;
  }

  public static fromJson(data: any): RegionCityModel | any {
    return new RegionCityModel(
      data.id,
      data.uid,
      data.uuid,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by,
      data.parent_id,
      data.code,
      data.name,
      data.type,
      data.group
    );
  }

  public static fromJsonList(data: any): RegionCityModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(RegionCityModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
