import { Table, Tbody, Td, Tfooter, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { useParams } from 'react-router-dom';
import { useBalanceContext } from '@/delivery/ui/admin/data_member/member_list/context/balance_context.tsx';
import { useEffect } from 'react';

const TableBalanceLockHistory = () => {
  const { uuid } = useParams();
  const { handleFetchBalanceLock, balanceLocks, balanceLockPagination } = useBalanceContext();

  useEffect(() => {
    if (uuid) {
      handleFetchBalanceLock({ user_uid: uuid });
    }
  }, [uuid]);

  return (
    <div>
      <Theader>
        <div>
          <Typography size={14} fontWeight={600}>
            Balance Lock History
          </Typography>
        </div>
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Lock Date</Th>
            <Th>Release Date</Th>
            <Th>Amount (gr)</Th>
            <Th>Incoming</Th>
            <Th>Outgoing</Th>
            <Th>Last Balance</Th>
          </Tr>
        </Thead>
        <Tbody>
          {balanceLocks.length > 0 ? (
            <>
              {balanceLocks.map((item, index) => (
                <Tr>
                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {item?.getCreatedAt}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {item?.getReleaseAt}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {item?.amount}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {item?.incoming}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {item?.outgoing}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {item?.lastBalance}
                    </Typography>
                  </Td>
                </Tr>
              ))}
            </>
          ) : (
            <Tr>
              <Td colSpan={6}>
                <Typography size={12} style={'italic'}>
                  belum memiliki history lock
                </Typography>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          handlePagination={page => {
            handleFetchBalanceLock({
              page: page
            });
          }}
          page={balanceLockPagination?.page || 1}
          totalPages={balanceLockPagination?.totalPages || 1}
        />
      </Tfooter>
    </div>
  );
};

export default TableBalanceLockHistory;
