import { BaseModel } from '@/domain/model/base_model.ts';

interface CounterInterface {
  UUID: string;
  name: string;
}

interface StockInterface {
  goldPieceUUID: string;
  gram: number;
  quantity: number;
  locked: number;
  available: number;
}

export class GoldStockSummaryModel extends BaseModel {
  counter: CounterInterface | null;
  stock: StockInterface[];

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    counter: CounterInterface | null,
    stock: StockInterface[]
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.counter = counter;
    this.stock = stock;
  }

  public static fromJson(data: any): GoldStockSummaryModel | null {
    try {
      // If data is nested, extract the relevant part
      const relevantData = data.data ?? data;

      return new GoldStockSummaryModel(
        BigInt(relevantData.id || 0),
        relevantData.uid || '',
        relevantData.uuid || '',
        relevantData.created_at || '',
        relevantData.created_by || '',
        relevantData.updated_at || '',
        relevantData.updated_by || '',
        relevantData.deleted_at || '',
        relevantData.deleted_by || '',
        relevantData.flags || [],
        relevantData.counter
          ? {
              UUID: relevantData.counter.uuid || '',
              name: relevantData.counter.name || ''
            }
          : null,
        Array.isArray(relevantData.stock)
          ? relevantData.stock.map((stockItem: any) => ({
              goldPieceUUID: stockItem.gold_piece_uuid || '',
              gram: stockItem.gram || 0,
              quantity: stockItem.quantity || 0,
              locked: stockItem.locked || 0,
              available: stockItem.available || 0
            }))
          : []
      );
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
    }
  }

  public static fromJsonList(data: any): GoldStockSummaryModel[] {
    try {
      const listData = Array.isArray(data) ? data : data.data;
      if (!Array.isArray(listData)) {
        throw new Error('Data is not an array');
      }
      return listData.map(item => {
        const model = GoldStockSummaryModel.fromJson(item);
        if (model === null) {
          throw new Error('Failed to parse item');
        }
        return model;
      });
    } catch (error) {
      console.error('Error parsing JSON list:', error);
      return [];
    }
  }
}
