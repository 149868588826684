import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { STATUS_LIST } from '@/domain/constant/status.ts';
import TypeAppNotify from '@/domain/constant/type_app_notify.ts';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import Switch from '@/delivery/components/atoms/switch/switch.tsx';
import Dropzone from '@/delivery/components/atoms/dropzone/dropzone.tsx';
import { useNotifyAppContext } from '@/delivery/ui/admin/web_admin/notify_app/context/notify_app_context.tsx';
import ChannelNotify from '@/domain/constant/channel_notification.ts';
import Line from '@/delivery/components/atoms/line/line.tsx';
import { RECIPIENT_TYPE, RECIPIENT_TYPE_LIST } from '@/domain/constant/notify_app/recipient_type.ts';
import Icon from '@/domain/constant/icon.tsx';
import { NOTIFY_CATEGORY_LIST } from '@/domain/constant/notify_app/category.ts';

const DialogAddNotificationApp = (props: ModalDialogProps) => {
  const {
    setUserSelected,
    userSelected,
    handleShowUserListModal,
    formNotifyApp,
    setFormNotifyApp,
    handleStoreDataNotification,
    handleAddNotifyAppModal
  } = useNotifyAppContext();

  return (
    <ModalDialog size="medium" title="Add Notification App" modal={props.modal}>
      <TextField
        required
        block
        label={'Title'}
        onChange={e => setFormNotifyApp({ ...formNotifyApp, title: e.target.value })}
      />
      <div className={'grid grid-cols-1 lg:grid-cols-2 gap-[24px] my-[24px]'}>
        <Select2
          isMulti
          options={TypeAppNotify.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          block
          label={'Type'}
          onChange={e => {
            setFormNotifyApp({
              ...formNotifyApp,
              type: e.map((item: any) => item.value)
            });
          }}
        />
        <Select2
          required
          options={STATUS_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          block
          label={'Status'}
          onChange={e => {
            setFormNotifyApp({ ...formNotifyApp, status: e.value });
          }}
        />
        <Datepicker
          value={formNotifyApp.start_date}
          block
          label={'Publish Date'}
          onChange={e => {
            setFormNotifyApp({ ...formNotifyApp, start_date: e });
          }}
        />
        <Datepicker
          value={formNotifyApp.end_date}
          block
          label={'End Date'}
          onChange={e => {
            setFormNotifyApp({ ...formNotifyApp, end_date: e });
          }}
        />
        <Select2
          isMulti
          options={ChannelNotify.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          block
          label={'Channels'}
          onChange={e => {
            {
              setFormNotifyApp({
                ...formNotifyApp,
                channels: e.map((item: any) => item.value)
              });
            }
          }}
        />

        <div className={'flex items-center'}>
          <Switch
            label={'Notifikasi Sekali Muncul'}
            onChange={e => {
              setFormNotifyApp({
                ...formNotifyApp,
                one_time_notification: e.target.checked
              });
            }}
          />
        </div>
      </div>
      <div className={'flex flex-col gap-[24px]'}>
        <TextField
          block
          label={'Link Notification'}
          onChange={e => setFormNotifyApp({ ...formNotifyApp, cta: e.target.value })}
        />
        <Dropzone id={'notify-app'} label={'Image'} onChange={e => setFormNotifyApp({ ...formNotifyApp, media: e })} />
        <Textarea
          block
          label={'Message'}
          height={150}
          onChange={e => setFormNotifyApp({ ...formNotifyApp, message: e.target.value })}
        />
      </div>
      <div className={'my-[20px]'}>
        <Line />
      </div>

      <div className={'flex flex-col gap-[24px]'}>
        <Select2
          options={NOTIFY_CATEGORY_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          block
          label={'Category'}
          onChange={e => setFormNotifyApp({ ...formNotifyApp, category: e?.value })}
        />

        <Select2
          options={RECIPIENT_TYPE_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          block
          label={'Recipient Type'}
          onChange={e => setFormNotifyApp({ ...formNotifyApp, recipient_type: e?.value })}
        />
        {formNotifyApp.recipient_type === RECIPIENT_TYPE.SELECTED ? (
          <div className={'flex flex-col gap-[20px]'}>
            <div>
              <Button
                prefixIcon={Icon.plus}
                label={'Add User'}
                onClick={() => handleShowUserListModal.setState(true)}
              />
            </div>

            <div>
              {userSelected.map((item, index) => (
                <div className={'flex items-center'}>
                  <Button
                    iconButton={true}
                    icon={Icon.trash}
                    onClick={() => setUserSelected(prevUsers => prevUsers.filter(u => u.uuid !== item.uuid))}
                  />
                  <p className={'text-[14px] font-medium'}>
                    {index + 1}. {item.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleAddNotifyAppModal.setState(false)} />
        <Button label="Save" onClick={() => handleStoreDataNotification()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddNotificationApp;
