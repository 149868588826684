import Navbar from '@/delivery/components/organisms/navbar/navbar';

import DataTransaction from './data_transaction';

const DataTransactionPage = () => {
  return (
    <div>
      <Navbar label={'Transaction Corporate List'}></Navbar>
      <DataTransaction />
    </div>
  );
};

export default DataTransactionPage;
