import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useRiceRateContext } from '@/delivery/ui/admin/master/rice_rate/context/rice_rate_context.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { STATUS_LIST } from '@/domain/constant/status.ts';

const DialogAddRiceRate = (props: ModalDialogProps) => {
  const { handleAddRiceRateModal, handleAddRiceRate, setFormRiceRate, formRiceRate } = useRiceRateContext();
  return (
    <ModalDialog size="small" title="Add Rice Rate" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField
          type={'currency'}
          label="Harga"
          placeholder="Harga"
          block
          onChange={(e: any) => setFormRiceRate({ ...formRiceRate, sell: parseInt(e.value) })}
        />

        <Select2
          isSearchable
          options={STATUS_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          label="Status"
          block
          placeholder="Status"
          onChange={e => setFormRiceRate({ ...formRiceRate, status: e.value })}
        />
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleAddRiceRateModal.setState(false)} />
        <Button block label="Save" onClick={() => handleAddRiceRate()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddRiceRate;
