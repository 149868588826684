// import { useDashboardOverviewContext } from "@/domain/use_case/context/dashboard_overview/dashboard_overview_context";
import Overview from './overview';
import Navbar from '@/delivery/components/organisms/navbar/navbar';

const OverviewPage = () => {
  // const {} = useDashboardOverviewContext();

  return (
    <div>
      <Navbar label={'Overview'} />
      <Overview />
    </div>
  );
};

export default OverviewPage;
