import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { RiceRateRepository } from '@/domain/repository/rice_rate_repository.ts';
import { RiceRateModel } from '@/domain/model/rice_rate_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';

interface DialogContextProps {
  children: ReactNode;
}

interface RiceRateValueParams {
  page?: number;
  limit?: number;
}

interface FormRiceRateInterface {
  uid?: string;
  sell?: number;
  status?: string;
}

interface RiceRateValue {
  formRiceRate: FormRiceRateInterface;
  setFormRiceRate: Dispatch<SetStateAction<FormRiceRateInterface>>;
  riceRates: RiceRateModel[];
  riceRatePagination?: PaginationModel;
  handleAddRiceRateModal: ModalObject;
  handleUpdateRiceRateModal: ModalObject;
  handleFetchRiceRate: (_data: RiceRateValueParams) => void;
  handleShowRiceRate: (_data: RiceRateModel) => void;
  handleUpdateRiceRate: () => void;
  handleAddRiceRate: () => void;
  handleDeleteRiceRate: (_data: RiceRateModel) => void;
}

const RiceRateContext = createContext<RiceRateValue | null>(null);

const riceRateRepository = new RiceRateRepository();

const RiceRateProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const handleAddRiceRateModal = useVisibleComponent(false);
  const handleUpdateRiceRateModal = useVisibleComponent(false);

  // const [riceRate, setRiceRate] = useState<RiceRateModel>();
  const [riceRates, setRiceRates] = useState<RiceRateModel[]>([]);
  const [riceRatePagination, setRiceRatePagination] = useState<PaginationModel>();

  const [formRiceRate, setFormRiceRate] = useState<FormRiceRateInterface>({});

  //FETCH TIER
  const handleFetchRiceRate = (_data: RiceRateValueParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data?.page ?? 1,
      limit: _data?.limit ?? 10
    };

    riceRateRepository
      .fetchRiceRate(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setRiceRates(result.data);
        setRiceRatePagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW TIER
  const handleShowRiceRate = (_data: RiceRateModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };

    riceRateRepository
      .showRiceRate(params)
      .then((res: any) => {
        const result: RiceRateModel = res;
        alertToast.updateLoading(x);
        setFormRiceRate({
          uid: result.UID,
          sell: result.sell,
          status: result.status
        });
        handleUpdateRiceRateModal.setState(true);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //UPDATE TIER
  const handleUpdateRiceRate = () => {
    const x = alertToast.loadingAlert('');

    riceRateRepository
      .updateRiceRate(formRiceRate)
      .then(() => {
        alertToast.updateLoading(x);
        handleFetchRiceRate({ page: 1 });
        handleUpdateRiceRateModal.setState(false);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //ADD TIER
  const handleAddRiceRate = () => {
    const x = alertToast.loadingAlert('');

    riceRateRepository
      .storeRiceRate(formRiceRate)
      .then(() => {
        alertToast.updateLoading(x);
        alertToast.successAlert();
        handleAddRiceRateModal.setState(false);
        handleFetchRiceRate({ page: 1 });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //DELETE TIER
  const handleDeleteRiceRate = (_data: RiceRateModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const params = {
          uid: _data.UID
        };
        riceRateRepository
          .deleteRiceRate(params)
          .then(() => {
            alertToast.successAlert();
            handleFetchRiceRate({ page: 1 });
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: RiceRateValue = {
    formRiceRate,
    setFormRiceRate,
    riceRates,
    riceRatePagination,
    handleFetchRiceRate,
    handleAddRiceRateModal,
    handleUpdateRiceRateModal,
    handleShowRiceRate,
    handleUpdateRiceRate,
    handleAddRiceRate,
    handleDeleteRiceRate
  };

  return <RiceRateContext.Provider value={contextValue}>{children}</RiceRateContext.Provider>;
};

const useRiceRateContext = (): RiceRateValue => {
  const context = useContext(RiceRateContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a RiceRateProvider');
  }
  return context;
};

export { RiceRateProvider, useRiceRateContext };
