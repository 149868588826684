import Navbar from '@/delivery/components/organisms/navbar/navbar';
import MemberVerifyEMoney from './member_verify_e_money';

const VerifyEMoneyPage = () => {
  return (
    <div>
      <Navbar label={'Member Request Verify e-money'}></Navbar>
      <MemberVerifyEMoney />
    </div>
  );
};

export default VerifyEMoneyPage;
