import { ApiClient } from '@/main/apiClient';
import AuthInterface, { AuthValue } from '@/domain/repository/interface/auth_interface';
import { API_BASE_URL } from '@/domain/constant/setting';
import { Auth } from '@/domain/constant/api_list';
import { UserModel } from '@/domain/model/user_model.ts';

export class AuthRepository implements AuthInterface {
  api = new ApiClient();

  async login(data: AuthValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Auth.LOGIN, data, {
            activeUrl: API_BASE_URL
          })
          .then(result => {
            resolve(result.data.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async authInquiry(data: AuthValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Auth.INQUIRY, data, {
            activeUrl: API_BASE_URL
          })
          .then(result => {
            resolve(result.data.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async loginOTP(data: AuthValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Auth.LOGIN_OTP + data.email, '', {
            activeUrl: API_BASE_URL
          })
          .then(result => {
            resolve(result.data.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async excLoginOTP(data: AuthValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(
            Auth.EXC_LOGIN_OTP + data.otp,
            { username: data.username, company_uuid: data.company_uuid },
            {
              activeUrl: API_BASE_URL
            }
          )
          .then(result => {
            resolve(result.data.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async userAccess() {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Auth.USER_ACCESS, '')
          .then(result => {
            resolve(result.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async resetPassword(data: any) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Auth.RESET_PASSWORD, data)
          .then(result => {
            resolve(result.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async register(data: any) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Auth.REGISTER, data)
          .then(result => {
            const data = UserModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async switchCompany(data: AuthValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Auth.SWITCH_COMPANY + data.company_uuid, data)
          .then(result => {
            resolve(result.data.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async impersonateCompany(data: AuthValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get('impersonate-company/' + data.company_uuid, data)
          .then(result => {
            resolve(result.data.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
