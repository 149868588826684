import { useEffect } from 'react';
import ATMLocationPage from '@/delivery/ui/admin/transaction/atm_gold/atm_location';

const ViewATMLocation = () => {
  useEffect(() => {
    document.title = 'Lokasi ATM';
  }, []);

  return (
    <div>
      <ATMLocationPage />
    </div>
  );
};

export default ViewATMLocation;
