import { Table, Tbody, Td, Th, Thead, Theader, Tr, TBox } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';

import TableKTP from '@/delivery/ui/admin/data_member/member_list/detail/tabs/data_profile/table_ktp.tsx';
import TableBankAccount from '@/delivery/ui/admin/data_member/member_list/detail/tabs/data_profile/table_bank_account.tsx';
import TableDocument from '@/delivery/ui/admin/data_member/member_list/detail/tabs/data_profile/table_document.tsx';
import TableSenderAddress from '@/delivery/ui/admin/data_member/member_list/detail/tabs/data_profile/table_sender_address.tsx';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import { STATUS_EMAIL_VERIFIED } from '@/domain/constant/status_email_verified.ts';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';
import DialogUpdateDataUser from '@/delivery/ui/admin/data_member/member_list/detail/tabs/data_profile/dialog/dialog_update_data_user.tsx';
// import TableIdentityAddress from '@/delivery/ui/admin/data_member/member_list/detail/tabs/data_profile/table_identity_address.tsx';

const DataProfile = () => {
  const { handleEditDataUserModal, user, handleShowDataUser } = useMemberListContext();

  return (
    <div>
      <div>
        <Theader>
          <div>
            <Typography size={14} fontWeight={600}>
              Data Register
            </Typography>
          </div>

          <div>
            <span
              className={'text-text-primary font-[600] text-[14px] cursor-pointer'}
              onClick={() => {
                if (user) {
                  handleShowDataUser(user);
                }
              }}
            >
              Edit
            </span>
          </div>
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Dinaran Number</Th>
              <Th>Nama Lengkap</Th>
              <Th>Email</Th>
              <Th>Phone</Th>
              <Th>Reg Date</Th>
              <Th>Email Verified</Th>
              <Th>Member Verified</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {user?.serialID}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {user?.getFullName}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {user?.email}
                </Typography>
              </Td>

              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {user?.phone}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {user?.getCreatedAt}
                </Typography>
              </Td>
              <Td>
                <Chips
                  label={user?.emailVerified ? STATUS_EMAIL_VERIFIED.VERIFIED : STATUS_EMAIL_VERIFIED.UNVERIFIED}
                  type={user?.emailVerified ? 'primary-light' : 'danger-light'}
                />
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {user?.getVerifiedAt}
                </Typography>
              </Td>
              <Td>
                <Chips
                  label={user?.userVerification?.status ?? STATUS_CORPORATE.UNVERIFIED}
                  type={user?.getStatusUserColor ?? 'danger-light'}
                />
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {user?.userVerification?.getCreatedAt}
                </Typography>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </div>

      <div className={'mt-[20px]'}>
        <Theader>
          <div>
            <Typography size={14} fontWeight={600}>
              Data Verifikasi
            </Typography>
          </div>

          {/*<div>*/}
          {/*  <span className={'text-text-primary font-[600] text-[14px] cursor-pointer'}>Edit</span>*/}
          {/*</div>*/}
        </Theader>
        <TBox>
          {user?.userVerification ? (
            <div className={'grid lg:grid-cols-2 sm:grid-cols-1 gap-[20px]'}>
              <div className={'flex flex-col gap-[20px]'}>
                <TableKTP />
                <TableBankAccount />
                <TableDocument />
              </div>
              <div className={'flex flex-col gap-[20px]'}>
                {/*<TableIdentityAddress />*/}
                <TableSenderAddress />
              </div>
            </div>
          ) : (
            <p className={'text-[12px] italic'}>user ini belum melakukan verifikasi</p>
          )}
        </TBox>
      </div>

      {handleEditDataUserModal.state && <DialogUpdateDataUser modal={handleEditDataUserModal} />}
    </div>
  );
};

export default DataProfile;
