import Navbar from '@/delivery/components/organisms/navbar/navbar';
import Media from './media';
import Button from '@/delivery/components/atoms/button/button';

import { useMediaContext } from './context/media_context';
import DialogAddMedia from './dialog/dialog_add_media';
import Icon from '@/domain/constant/icon.tsx';

const MediaPage = () => {
  const { handleAddMediaModal } = useMediaContext();

  return (
    <div>
      <Navbar label={'Media'}>
        <div className="flex gap-[12px]">
          <Button
            type="primary"
            prefixIcon={Icon.plus}
            label="Add Media"
            onClick={() => handleAddMediaModal.setState(true)}
          />
        </div>
      </Navbar>
      <Media />

      {handleAddMediaModal.state && <DialogAddMedia modal={handleAddMediaModal} />}
    </div>
  );
};

export default MediaPage;
