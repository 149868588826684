import Navbar from '@/delivery/components/organisms/navbar/navbar';
import OpenChannel from '@/delivery/ui/admin/settings/open_channel/open_channel.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import { useOpenChannelContext } from '@/delivery/ui/admin/settings/open_channel/context/open_channel_context.tsx';
import DialogAddPaymentManual from '@/delivery/ui/admin/settings/open_channel/dialog/dialog_add_payment_manual.tsx';

const OpenChannelPage = () => {
  const { handleAddPaymentManualModal } = useOpenChannelContext();
  return (
    <div>
      <Navbar label={'Open Channel'}>
        <Button
          prefixIcon={Icon.plus}
          label={'Add Manual Channel'}
          onClick={() => handleAddPaymentManualModal.setState(true)}
        />
      </Navbar>
      <OpenChannel />

      {handleAddPaymentManualModal.state && <DialogAddPaymentManual modal={handleAddPaymentManualModal} />}
    </div>
  );
};

export default OpenChannelPage;
