import { GoThumbsup } from 'react-icons/go';
import { RiForbidLine } from 'react-icons/ri';

import Button from '@/delivery/components/atoms/button/button';
import Typography from '@/delivery/components/atoms/typography/typography';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table';
import Chips from '@/delivery/components/atoms/chips/chips';
import { useTransactionListContext } from '@/delivery/ui/admin/transaction/transaction_list/context/transaction_list_context.tsx';
import { USER_STATUS, USER_VERIFICATION } from '@/domain/constant/user/user_verification.ts';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import QRCodeView from '@/delivery/components/atoms/qr_code/qr_code.tsx';
import { PAYMENT_METHOD } from '@/domain/constant/payment/payment_method.ts';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';

const DialogDetailTransaction = (props: ModalDialogProps) => {
  const { handleCancelTransaction, transactionList, handleApprovePayment } = useTransactionListContext();
  return (
    <ModalDialog size="xlarge" title="Detail Transaction" modal={props.modal}>
      <div className="flex flex-col lg:flex-row gap-[24px] justify-between overflow-y-auto">
        {/* SECTION 1 */}
        <div className="w-full">
          <Table noBorder>
            <Thead>
              <Tr>
                <Th noBorder colSpan={2}>
                  Data Member
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Nama
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.user?.getFullName}
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Email
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.user?.email}
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Phone
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.user?.phone}
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Status
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Chips
                      label={transactionList?.user?.status.toUpperCase()}
                      type={
                        transactionList?.user?.status.toUpperCase() === USER_STATUS.ACTIVE
                          ? 'primary-light'
                          : 'danger-light'
                      }
                    />
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    User Verification
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Chips
                      label={
                        transactionList?.user?.userVerification
                          ? USER_VERIFICATION.VERIFIED
                          : USER_VERIFICATION.UNVERIFIED
                      }
                      type={transactionList?.user?.userVerification ? 'primary-light' : 'danger-light'}
                    />
                  </div>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </div>

        {/* SECTION 2 */}
        <div className="w-full">
          <Table noBorder className="rounded-b-[8px] w-full">
            <Thead>
              <Tr>
                <Th noBorder colSpan={2}>
                  Data Transaksi
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    ID Transaksi
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.code}
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Tanggal Transaksi
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.getCreatedAt}
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Transaksi Sukses
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.getSucceedAtSingle}
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Ref ID
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.referenceID}
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Type Transaksi
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Chips label={transactionList?.type} removeDot />
                  </div>
                </Td>
              </Tr>
              {transactionList?.type === TYPE_TRANSACTION.TRANSFER ? (
                <>
                  <Tr>
                    <Td noBorder>
                      <Typography size={12} fontWeight={400} type="tertiary-invert">
                        Pengirim
                      </Typography>
                    </Td>
                    <Td noBorder>
                      <div className="text-right">
                        <Typography size={12} fontWeight={400} type="primary-invert">
                          {transactionList?.senderName || '-'}
                        </Typography>
                      </div>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td noBorder>
                      <Typography size={12} fontWeight={400} type="tertiary-invert">
                        Penerima
                      </Typography>
                    </Td>
                    <Td noBorder>
                      <div className="text-right">
                        <Typography size={12} fontWeight={400} type="primary-invert">
                          {transactionList?.receiverName || '-'}
                        </Typography>
                      </div>
                    </Td>
                  </Tr>
                </>
              ) : (
                ''
              )}

              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Total Gramasi
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.getGram}
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Gold Rate Buy
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.rate.getBuy}
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Gold Rate Sell
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.rate.getSell}
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Fee
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.getFeeRupiahSingle}
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Sub Total Amount
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.getSubtotalSingle}
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Total Amount
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.getAmountSingle}
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Coin
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.coin}
                    </Typography>
                  </div>
                </Td>
              </Tr>

              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Reversal
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    {transactionList?.type === TYPE_TRANSACTION.REVERSAL ? (
                      <Chips label={transactionList?.type} />
                    ) : (
                      '-'
                    )}
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Status Pembayaran
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Chips label={transactionList?.status} type={transactionList?.getStatusColor} />
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Note
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      {transactionList?.notes ?? '-'}
                    </Typography>
                  </div>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </div>

        {/* SECTION 3 */}
        {transactionList?.trxVendor !== null ? (
          <div className="w-full">
            <Table noBorder className="rounded-b-[8px] w-full">
              <Thead>
                <Tr>
                  <Th noBorder colSpan={2}>
                    Data Vendor
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td noBorder>
                    <Typography size={12} fontWeight={400} type="tertiary-invert">
                      Nama Vendor
                    </Typography>
                  </Td>
                  <Td noBorder>
                    <div className="text-right">
                      <Typography size={12} fontWeight={400} type="primary-invert">
                        {transactionList?.trxVendor?.vendorUID}
                      </Typography>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td noBorder>
                    <Typography size={12} fontWeight={400} type="tertiary-invert">
                      Vendor ID
                    </Typography>
                  </Td>
                  <Td noBorder>
                    <div className="text-right">
                      <Typography size={12} fontWeight={400} type="primary-invert">
                        {transactionList?.trxVendor?.vendorRefID}
                      </Typography>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td noBorder>
                    <Typography size={12} fontWeight={400} type="tertiary-invert">
                      Ref ID
                    </Typography>
                  </Td>
                  <Td noBorder>
                    <div className="text-right">
                      <Typography size={12} fontWeight={400} type="primary-invert">
                        {transactionList?.referenceID}
                      </Typography>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td noBorder>
                    <Typography size={12} fontWeight={400} type="tertiary-invert">
                      Payment Channel
                    </Typography>
                  </Td>
                  <Td noBorder>
                    <div className="text-right">
                      <Typography size={12} fontWeight={400} type="primary-invert">
                        {transactionList?.trxVendor?.paymentChannel?.name}
                      </Typography>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td noBorder>
                    <Typography size={12} fontWeight={400} type="tertiary-invert">
                      Payment Method
                    </Typography>
                  </Td>
                  <Td noBorder>
                    <div className="text-right">
                      <Typography size={12} fontWeight={400} type="primary-invert">
                        {transactionList?.trxVendor?.paymentChannel?.paymentMethod?.name}
                      </Typography>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td noBorder>
                    <Typography size={12} fontWeight={400} type="tertiary-invert">
                      Payment No
                    </Typography>
                  </Td>
                  <Td noBorder>
                    <div className="text-right">
                      <Typography size={12} fontWeight={400} type="primary-invert">
                        {transactionList?.trxVendor?.paymentChannel?.paymentMethod?.paymentFlow ===
                        PAYMENT_METHOD.QRIS ? (
                          <QRCodeView value={transactionList?.trxVendor.paymentCode} size={200} />
                        ) : (
                          <>{transactionList?.trxVendor.paymentCode}</>
                        )}
                      </Typography>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td noBorder>
                    <Typography size={12} fontWeight={400} type="tertiary-invert">
                      Received Amount
                    </Typography>
                  </Td>
                  <Td noBorder>
                    <div className="text-right">
                      <Typography size={12} fontWeight={400} type="primary-invert">
                        {transactionList?.getAmountSingle}
                      </Typography>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td noBorder>
                    <Typography size={12} fontWeight={400} type="tertiary-invert">
                      Created
                    </Typography>
                  </Td>
                  <Td noBorder>
                    <div className="text-right">
                      <Typography size={12} fontWeight={400} type="primary-invert">
                        {transactionList?.getCreatedAt}
                      </Typography>
                    </div>
                  </Td>
                </Tr>
                <Tr>
                  <Td noBorder>
                    <Typography size={12} fontWeight={400} type="tertiary-invert">
                      Expired
                    </Typography>
                  </Td>
                  <Td noBorder>
                    <div className="text-right">
                      <Typography size={12} fontWeight={400} type="primary-invert">
                        {transactionList?.trxVendor.getPaymentExpired}
                      </Typography>
                    </div>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </div>
        ) : (
          ''
        )}
      </div>
      <ModalDialogFooter className="flex justify-end gap-[20px]">
        {transactionList?.type !== TYPE_TRANSACTION.REVERSAL ? (
          <Button
            type="secondary"
            label="Reversal"
            prefixIcon={<RiForbidLine />}
            onClick={() => handleCancelTransaction(transactionList)}
          />
        ) : (
          ''
        )}

        {transactionList?.status === STATUS_TRANSACTION.SUCCESS ? (
          ''
        ) : (
          <Button label="Approve" prefixIcon={<GoThumbsup />} onClick={() => handleApprovePayment(transactionList)} />
        )}
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogDetailTransaction;
