import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Dropzone from '@/delivery/components/atoms/dropzone/dropzone.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';
import { useEffect } from 'react';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import Checkbox from '@/delivery/components/atoms/checkbox/checkbox.tsx';
import { useCorporateContext } from '@/delivery/ui/admin/data_member/member_list/context/corporate_context.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';

const DialogCreateBusinessAccount = (props: ModalDialogProps) => {
  const {
    masterBanks,
    handleFetchMasterBank,
    handleStoreCorporate,
    user,
    formCreateCorporate,
    setFormCreateCorporate,
    handleDialogCreateBusinessAccountModal,
    mailAddressCopyBusinessAddress,
    handleMailAddressCopyBusinessAddress,

    handleFetchProvince,
    provinces,
    cities,
    districts,
    villages,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    // villageID,
    setVillageID
  } = useCorporateContext();

  // const [page, setPage] = useState<number>(1);

  useEffect(() => {
    handleFetchMasterBank();
  }, []);

  useEffect(() => {
    handleFetchProvince();
  }, []);

  //fetch cities
  useEffect(() => {
    if (provinceID !== null) {
      handleFetchCities();
    }
  }, [provinceID]);

  //fetch district
  useEffect(() => {
    if (cityID !== 0) {
      handleFetchDistricts();
    }
  }, [cityID]);

  //fetch village
  useEffect(() => {
    if (districtID > 0) {
      handleFetchVillage();
    }
  }, [districtID]);

  return (
    <ModalDialog size="large" title="Tambah Akun Bisnis" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        {/*PAGE 1*/}
        {/*{page === 1 ? (*/}
        <div className="flex flex-col gap-[20px]">
          <Typography size={16} fontWeight={600}>
            Penanggung Jawab
          </Typography>
          <Line />
          <div className="grid grid-cols-2 gap-[20px]">
            <TextField block label={'Nama'} value={user?.getFullName} disabled />
            <TextField block label={'Email'} value={user?.email} disabled />
            {/*<TextField block label={'Jabatan'} />*/}
            <TextField block label={'Phone'} value={user?.phone} disabled />
          </div>
          <Dropzone
            id={'identity'}
            label={'Foto KTP Penanggung jawab'}
            onChange={value => {
              setFormCreateCorporate({ ...formCreateCorporate, identity_media: value });
            }}
          />

          <Typography size={16} fontWeight={600} className={'mt-[20px]'}>
            Informasi Bisnis
          </Typography>
          <div className="grid grid-cols-2 gap-[20px]">
            <TextField
              required
              value={formCreateCorporate.name}
              block
              label={'Nama Bisnis/Usaha'}
              onChange={e => setFormCreateCorporate({ ...formCreateCorporate, name: e.target.value })}
            />
            <TextField
              type={'email'}
              value={formCreateCorporate.email}
              block
              label={'Email Bisnis '}
              onChange={e => setFormCreateCorporate({ ...formCreateCorporate, email: e.target.value })}
            />
            <TextField
              required
              value={formCreateCorporate.website}
              block
              label={'Website'}
              onChange={e => setFormCreateCorporate({ ...formCreateCorporate, website: e.target.value })}
            />
            <TextField
              type={'number'}
              value={formCreateCorporate.phone}
              block
              label={'Phone Bisnis'}
              onChange={e => setFormCreateCorporate({ ...formCreateCorporate, phone: e.target.value })}
            />
          </div>
          <div className="grid grid-cols-3 gap-[20px]">
            <Dropzone
              required
              id={'nib'}
              label={'Ijin Usaha (NIB)'}
              onChange={value =>
                setFormCreateCorporate({ ...formCreateCorporate, company_registration_number_media: value })
              }
            />
            <Dropzone
              id={'akta'}
              label={'Akta'}
              onChange={value => setFormCreateCorporate({ ...formCreateCorporate, company_deed_media: value })}
            />
            <Dropzone
              id={'npwp'}
              label={'NPWP'}
              onChange={value =>
                setFormCreateCorporate({ ...formCreateCorporate, tax_payer_identification_number_media: value })
              }
            />
          </div>
        </div>
        {/*) : (*/}
        {/*  ''*/}
        {/*)}*/}
        {/*PAGE 2*/}
        {/*{page === 2 ? (*/}
        <div className="flex flex-col gap-[20px]">
          <Typography size={16} fontWeight={600}>
            Akun Bank Bisnis
          </Typography>
          <Line />
          <div className="grid grid-cols-3 gap-[20px]">
            <Select2
              options={masterBanks.map(item => {
                return {
                  value: item.UID,
                  label: item.name
                };
              })}
              block
              label={'Bank Name'}
              onChange={e => {
                setFormCreateCorporate({ ...formCreateCorporate, master_bank_uuid: e.value });
              }}
            />
            <TextField
              type={'number'}
              block
              label={'Bank Acc No'}
              placeholder={'Bank Acc No'}
              onChange={e => setFormCreateCorporate({ ...formCreateCorporate, account_number: e.target.value })}
            />
            <TextField
              required
              block
              label={'Bank Acc Holder'}
              placeholder={'Bank Acc Holder'}
              onChange={e => setFormCreateCorporate({ ...formCreateCorporate, account_holder_name: e.target.value })}
            />
          </div>
          <div className="grid grid-cols-2 gap-[20px]">
            <Dropzone
              label={'Foto Buku Tabungan'}
              onChange={value => setFormCreateCorporate({ ...formCreateCorporate, checking_account_media: value })}
            />
            <Dropzone required label={'Foto Rekening Koran'} />
          </div>

          <div className={'flex flex-col gap-[20px]'}>
            <Typography fontWeight={600} size={16}>
              Informasi Alamat Bisnis
            </Typography>
            <Line />
            <div className={'grid grid-cols-2 gap-[24px]'}>
              <Select2
                value={provinceID?.toString()}
                block
                label={'Provinsi'}
                options={provinces?.map(item => {
                  return {
                    label: item.name,
                    value: item.ID.toString()
                  };
                })}
                onChange={e => {
                  setFormCreateCorporate({ ...formCreateCorporate, province: e.label });
                  setProvinceID(parseInt(e.value));
                }}
              />
              <Select2
                value={cityID.toString()}
                block
                label={'Kota/Kab'}
                options={cities?.map(item => {
                  return {
                    label: item.name,
                    value: item.ID.toString()
                  };
                })}
                onChange={e => {
                  setFormCreateCorporate({ ...formCreateCorporate, city: e.label });
                  setCityID(parseInt(e.value));
                }}
              />
              <Select2
                block
                label={'Kecamatan'}
                options={districts?.map(item => {
                  return {
                    label: item.name,
                    value: item.ID.toString()
                  };
                })}
                onChange={e => {
                  setFormCreateCorporate({ ...formCreateCorporate, district: e.label });
                  setDistrictID(parseInt(e.value));
                }}
              />
              <Select2
                block
                label={'Kelurahan'}
                options={villages?.map(item => {
                  return {
                    label: item.name,
                    value: item.ID.toString()
                  };
                })}
                onChange={e => {
                  setFormCreateCorporate({ ...formCreateCorporate, village: e.label });
                  setVillageID(parseInt(e.value));
                }}
              />
            </div>
            <div className={'flex gap-[24px]'}>
              <TextField
                required
                block
                label={'Alamat Bisnis/Usaha'}
                onChange={e =>
                  setFormCreateCorporate({
                    ...formCreateCorporate,
                    full_address: e.target.value
                  })
                }
              />
              <TextField
                required
                label={'Kode POS'}
                onChange={e => setFormCreateCorporate({ ...formCreateCorporate, zip_code: e.target.value })}
              />
            </div>

            {/*alamat surat*/}
            <div className={'flex items-center gap-[20px]'}>
              <Typography fontWeight={600} size={16}>
                Informasi Alamat Surat
              </Typography>
              <Checkbox
                checked={mailAddressCopyBusinessAddress}
                label={'sama dengan alamat bisnis'}
                onChange={() => handleMailAddressCopyBusinessAddress()}
              />
            </div>

            <Line />
            <div>
              <Textarea
                disabled={mailAddressCopyBusinessAddress}
                label={'Mail Address'}
                height={150}
                block
                onChange={e => setFormCreateCorporate({ ...formCreateCorporate, mail_address_first: e.target.value })}
              />
            </div>
          </div>
        </div>
        {/*) : (*/}
        {/*  ''*/}
        {/*)}*/}
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        {/*{page === 1 ? (*/}
        {/*  <>*/}
        {/*    <Button*/}
        {/*      type="secondary"*/}
        {/*      label="Cancel"*/}
        {/*      onClick={() => handleDialogCreateBusinessAccountModal.setState(false)}*/}
        {/*    />*/}
        {/*    <Button label="Selenjutnya" onClick={() => setPage(2)} />*/}
        {/*  </>*/}
        {/*) : (*/}
        {/*  ''*/}
        {/*)}*/}

        {/*{page === 2 ? (*/}
        <>
          {/*<Button type="secondary" label="Kembali" onClick={() => setPage(1)} />*/}
          <Button
            type="secondary"
            label="Cancel"
            onClick={() => handleDialogCreateBusinessAccountModal.setState(false)}
          />
          <Button
            label="Submit Data"
            onClick={() => {
              // console.log(user?.UUID, 'll');
              handleStoreCorporate({ user_uid: user?.UUID });
            }}
          />
        </>
        {/*// ) : (*/}
        {/*//   ''*/}
        {/*// )}*/}
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogCreateBusinessAccount;
