import { RegionNationalityModel } from './region_nationality_model';
import { RegionProvinceModel } from './region_province_model';
import { RegionCityModel } from './region_city_model';
import { RegionDistrictModel } from './region_district_model';
import { RegionVillageModel } from './region_village_model';
import { BaseModel } from '@/domain/model/base_model.ts';

export default class AddressModel extends BaseModel {
  flags: string[];
  latitude: number;
  longitude: string;
  fullAddress: string;
  nationalID: string;
  nationality: RegionNationalityModel;
  provinceID: string;
  province: RegionProvinceModel;
  cityID: string;
  city: RegionCityModel;
  districtID: string;
  district: RegionDistrictModel;
  villageID: string;
  village: RegionVillageModel;
  zipCode: string;

  get fullAddressFormat(): string {
    const arrayAddress = [
      this.fullAddress,
      this.village?.name || '',
      this.district?.name || '',
      this.city?.name || '',
      this.province?.name || ''
    ];
    return arrayAddress.filter(item => item !== '').join(', ');
  }

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    latitude: number,
    longitude: string,
    fullAddress: string,
    nationalID: string,
    nationality: RegionNationalityModel,
    provinceID: string,
    province: RegionProvinceModel,
    cityID: string,
    city: RegionCityModel,
    districtID: string,
    district: RegionDistrictModel,
    villageID: string,
    village: RegionVillageModel,
    zipCode: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.flags = flags;
    this.latitude = latitude;
    this.longitude = longitude;
    this.fullAddress = fullAddress;
    this.nationalID = nationalID;
    this.nationality = nationality;
    this.provinceID = provinceID;
    this.province = province;
    this.cityID = cityID;
    this.city = city;
    this.districtID = districtID;
    this.district = district;
    this.villageID = villageID;
    this.village = village;
    this.zipCode = zipCode;
  }

  public static fromJson(data: any): any | AddressModel {
    try {
      return new AddressModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.latitude,
        data.longitude,
        data.full_address,
        data.national_id,
        data.nationality ? RegionNationalityModel.fromJson(data.nationality) : null,
        data.province_id,
        data.province ? RegionProvinceModel.fromJson(data.province) : null,
        data.city_id,
        data.city ? RegionCityModel.fromJson(data.city) : null,
        data.district_id,
        data.district ? RegionDistrictModel.fromJson(data.district) : null,
        data.village_id,
        data.village ? RegionVillageModel.fromJson(data.village) : null,
        data.zip_code
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): AddressModel[] | any {
    try {
      const listAddressModel = [];

      for (let x = 0; x < data.length; x++) {
        listAddressModel.push(AddressModel.fromJson(data[x]));
      }

      return listAddressModel;
    } catch {
      return [];
    }
  }
}
