import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { TransactionChartRepository } from '@/domain/repository/dashboard/transaction_chart_repository.ts';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { LIMIT_RANK_DISPLAY } from '@/domain/constant/dashboard/rank.ts';
import RankModel from '@/domain/model/dashboard/rank_model.ts';

interface DashboardRankingContextProps {
  children: ReactNode;
}

interface DashboardRankingContextValue {
  rankingBuyData: RankModel[];
  rankingBuyLoading: boolean;
  rankingSellData: RankModel[];
  rankingSellLoading: boolean;
  setRankingBuyLoading: Dispatch<SetStateAction<boolean>>;
  setRankingSellLoading: Dispatch<SetStateAction<boolean>>;
  setRankingHighProfitLoading: Dispatch<SetStateAction<boolean>>;
  setRankingBalanceLoading: Dispatch<SetStateAction<boolean>>;
  rankingHighProfitData: RankModel[];
  rankingHighProfitLoading: boolean;
  rankingBalanceData: RankModel[];
  rankingBalanceLoading: boolean;
  handleRankHighProfit: () => Promise<void>;
  handleRankingBalance: () => Promise<void>;
  handleRankingBuy: () => Promise<void>;
  handleRankingSell: () => Promise<void>;
}

const DashboardRankingContext = createContext<DashboardRankingContextValue | null>(null);

const transactionStatistic = new TransactionChartRepository();

const DashboardRankingProvider: React.FC<DashboardRankingContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  //ranking high profit
  const [rankingHighProfitData, setRankingHighProfitData] = useState<RankModel[]>([]);
  const [rankingHighProfitLoading, setRankingHighProfitLoading] = useState<boolean>(false);

  //ranking high profit
  const [rankingBalanceData, setRankingBalanceData] = useState<RankModel[]>([]);
  const [rankingBalanceLoading, setRankingBalanceLoading] = useState<boolean>(false);

  //ranking high buy
  const [rankingBuyData, setRankingBuyData] = useState<RankModel[]>([]);
  const [rankingBuyLoading, setRankingBuyLoading] = useState<boolean>(false);

  //ranking high sell
  const [rankingSellData, setRankingSellData] = useState<RankModel[]>([]);
  const [rankingSellLoading, setRankingSellLoading] = useState<boolean>(false);

  //HANDLE RANKING HIGH PROFIT
  const handleRankHighProfit = async () => {
    setRankingHighProfitLoading(true);

    const params = {
      sort: 'net_profit',
      limit: LIMIT_RANK_DISPLAY
    };

    await transactionStatistic
      .transactionRank(params)
      .then((result: any) => {
        setRankingHighProfitData(result);
        setRankingHighProfitLoading(false);
      })
      .catch(error => {
        setRankingHighProfitLoading(false);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE RANKING BALANCE
  const handleRankingBalance = async () => {
    setRankingBalanceLoading(true);

    const params = {
      sort: 'gram_hold',
      limit: LIMIT_RANK_DISPLAY
    };

    await transactionStatistic
      .transactionRank(params)
      .then((result: any) => {
        setRankingBalanceData(result);
        setRankingBalanceLoading(false);
      })
      .catch(error => {
        setRankingHighProfitLoading(false);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE BEST BUY
  const handleRankingBuy = async () => {
    setRankingBuyLoading(true);

    const params = {
      sort: 'gram_buy',
      limit: LIMIT_RANK_DISPLAY
    };

    await transactionStatistic
      .transactionRank(params)
      .then((result: any) => {
        setRankingBuyData(result);
        setRankingBuyLoading(false);
      })
      .catch(error => {
        setRankingBuyLoading(false);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE BEST SELL
  const handleRankingSell = async () => {
    setRankingSellLoading(true);

    const params = {
      sort: 'gram_sell',
      limit: LIMIT_RANK_DISPLAY
    };

    await transactionStatistic
      .transactionRank(params)
      .then((result: any) => {
        setRankingSellData(result);
        setRankingSellLoading(false);
      })
      .catch(error => {
        setRankingSellLoading(false);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: DashboardRankingContextValue = {
    rankingBuyLoading,
    rankingSellLoading,
    rankingBuyData,
    rankingSellData,
    setRankingBuyLoading,
    setRankingSellLoading,
    rankingBalanceData,
    rankingBalanceLoading,
    rankingHighProfitData,
    rankingHighProfitLoading,
    handleRankingBuy,
    handleRankingSell,
    handleRankHighProfit,
    handleRankingBalance,
    setRankingHighProfitLoading,
    setRankingBalanceLoading
  };

  return <DashboardRankingContext.Provider value={contextValue}>{children}</DashboardRankingContext.Provider>;
};

const useDashboardRankingContext = (): DashboardRankingContextValue => {
  const context = useContext(DashboardRankingContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a DashboardRankingProvider');
  }
  return context;
};

export { DashboardRankingProvider, useDashboardRankingContext };
