import { MediaAssignmentRepository } from '@/domain/repository/media_assignment_repository';
import MediaRepository from '@/domain/repository/media_repository';
import { BUCKET_UPLOAD_MEDIA } from '@/domain/constant/setting.ts';

const uploadMediaHelper = async (file: any, assigned_to_uuid: string | undefined) => {
  const mediaRepository = new MediaRepository();
  const mediaAssignmentRepository = new MediaAssignmentRepository();

  const formData = new FormData();
  const formDataMultiple = new FormData();

  // const files = file.concat(...file);

  const storeAssignmentMedia = async (media_uuid: string) =>
    await mediaAssignmentRepository.storeMediaAssignment({
      media_uuid: media_uuid,
      assigned_to_uuid: assigned_to_uuid,
      assigned: 'users',
      is_default: true,
      media_type: 'image'
    });

  if (Array.isArray(file) && file.length > 0) {
    file.forEach((fileItem: any, index: number) => {
      formDataMultiple.append('files', fileItem, `file${index}`);
    });

    formDataMultiple.append('bucket', BUCKET_UPLOAD_MEDIA ? BUCKET_UPLOAD_MEDIA : '');
    formDataMultiple.append('type', 'image');
    formDataMultiple.append('path', 'test');

    const storeMediaMultiple = async () =>
      await mediaRepository.storeMediaMultiple(formDataMultiple).then((res: any) => {
        for (let x = 0; x < res.length; x++) {
          storeAssignmentMedia(res[x].UUID);
        }
      });

    await storeMediaMultiple();
  } else {
    formData.append('bucket', BUCKET_UPLOAD_MEDIA ? BUCKET_UPLOAD_MEDIA : '');
    formData.append('file', file);
    formData.append('path', 'test');
    formData.append('type', 'png');

    const storeMedia = async () =>
      await mediaRepository.storeMedia(formData).then((res: any) => {
        storeAssignmentMedia(res.UUID);
      });

    await storeMedia();
  }
};

const uploadMediaHelperWithUID = async (file: any, assigned_to_uid: string | undefined) => {
  const mediaRepository = new MediaRepository();
  const mediaAssignmentRepository = new MediaAssignmentRepository();

  const formData = new FormData();
  const formDataMultiple = new FormData();

  // const files = file.concat(...file);

  const storeAssignmentMedia = async (media_uuid: string) =>
    await mediaAssignmentRepository.storeMediaAssignment({
      media_uuid: media_uuid,
      assigned_to_uid: assigned_to_uid,
      assigned: 'users',
      is_default: true,
      media_type: 'image'
    });

  if (Array.isArray(file) && file.length > 0) {
    file.forEach((fileItem: any, index: number) => {
      formDataMultiple.append('files', fileItem, `file${index}`);
    });

    formDataMultiple.append('bucket', process.env.S3_BUCKET_NAME ?? 'msclinic');
    formDataMultiple.append('type', 'image');
    formDataMultiple.append('path', 'test');

    const storeMediaMultiple = async () =>
      await mediaRepository.storeMediaMultiple(formDataMultiple).then((res: any) => {
        for (let x = 0; x < res.length; x++) {
          storeAssignmentMedia(res[x].UUID);
        }
      });

    await storeMediaMultiple();
  } else {
    formData.append('bucket', process.env.S3_BUCKET_NAME ?? 'msclinic');
    formData.append('file', file);
    formData.append('path', 'test');
    formData.append('type', 'png');

    const storeMedia = async () =>
      await mediaRepository.storeMedia(formData).then((res: any) => {
        storeAssignmentMedia(res.UID);
      });

    await storeMedia();
  }
};

export { uploadMediaHelper, uploadMediaHelperWithUID };
