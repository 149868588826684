import { useEffect } from 'react';
import DinaranSettingPage from '@/delivery/ui/admin/settings/dinaran_setting';
import { DinaranSettingProvider } from '@/delivery/ui/admin/settings/dinaran_setting/context/dinaran_setting_context.tsx';

const ViewDinaranSetting = () => {
  useEffect(() => {
    document.title = 'Dinaran Setting';
  }, []);

  return (
    <div>
      <DinaranSettingProvider>
        <DinaranSettingPage />
      </DinaranSettingProvider>
    </div>
  );
};

export default ViewDinaranSetting;
