import { useEffect } from 'react';
import ReferralTransactionPage from '@/delivery/ui/admin/data_corporate/referral_transaction';

const ViewReferralTransaction = () => {
  useEffect(() => {
    document.title = 'Referral Transaction';
  }, []);

  return (
    <div>
      <ReferralTransactionPage />
    </div>
  );
};

export default ViewReferralTransaction;
