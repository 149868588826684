import { Table, Tbody, Td, Tfooter, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { useRiceRateContext } from '@/delivery/ui/admin/master/rice_rate/context/rice_rate_context.tsx';
import { useEffect } from 'react';
import { RiceRateModel } from '@/domain/model/rice_rate_model.ts';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import { STATUS_CONST } from '@/domain/constant/status.ts';
import DialogUpdateRiceRate from '@/delivery/ui/admin/master/rice_rate/dialog/dialog_update_rice_rate.tsx';

const RiceRate = () => {
  const {
    handleShowRiceRate,
    handleDeleteRiceRate,
    handleUpdateRiceRateModal,
    riceRatePagination,
    riceRates,
    handleFetchRiceRate
  } = useRiceRateContext();

  useEffect(() => {
    handleFetchRiceRate({});
  }, []);

  return (
    <div>
      <Table className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Harga Jual</Th>
            <Th>Status</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {riceRates.map((item: RiceRateModel, index) => (
            <Tr key={index}>
              <Td>
                <Typography size={14} fontWeight={400} type="secondary-invert">
                  {item.getSellRupiah}
                </Typography>
              </Td>
              <Td>
                <Chips
                  label={item.status}
                  type={item.status === STATUS_CONST.ACTIVE ? 'primary-light' : 'danger-light'}
                />
              </Td>

              <Td>
                <Button iconButton iconType="delete" onClick={() => handleDeleteRiceRate(item)} />
                <Button iconButton iconType="edit" onClick={() => handleShowRiceRate(item)} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={riceRatePagination?.page || 1}
          totalPages={riceRatePagination?.totalPages || 1}
          handlePagination={page => {
            handleFetchRiceRate({
              page: page
            });
          }}
        />
      </Tfooter>

      {handleUpdateRiceRateModal.state && <DialogUpdateRiceRate modal={handleUpdateRiceRateModal} />}
    </div>
  );
};

export default RiceRate;
