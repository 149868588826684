import { useManualTransactionContext } from '@/delivery/ui/admin/transaction/manual_transaction/context/manual_transaction_context.tsx';
import DialogListUser from '@/delivery/ui/admin/transaction/manual_transaction/dialog/dialog_list_user.tsx';
import { useEffect, useState } from 'react';
import { formatDateTime } from '@/infrastructure/helper/formatDate.ts';
import { TabContent, TabPane2, TabPaneList2 } from '@/delivery/components/atoms/tab_pane/tab_pane';
import BuyTransaction from '@/delivery/ui/admin/transaction/manual_transaction/tabs/buy_transaction.tsx';
import SaleTransaction from '@/delivery/ui/admin/transaction/manual_transaction/tabs/sale_transaction.tsx';

const ManualTransaction = () => {
  const {
    setFormManualTransaction,
    handleFetchGoldRateLatest,
    formManualTransaction,
    handleShowUserListModal,
    handleFetchPaymentChannel,
    handleGetRateByDate
  } = useManualTransactionContext();

  useEffect(() => {
    handleFetchGoldRateLatest();
    handleFetchPaymentChannel();
  }, []);

  const [tabPage, setTabPage] = useState(0);
  const data = [
    {
      label: 'SEARCH TRANSACTION',
      index: 0
      // handleClick: () => {}
    },
    {
      label: 'MANUAL TRANSACTION',
      index: 1
      // handleClick: () => {}
    }
  ];

  useEffect(() => {
    const nowDate = formatDateTime(new Date());
    setFormManualTransaction({ ...formManualTransaction, rate_date: nowDate });
  }, []);

  useEffect(() => {
    if (formManualTransaction.rate_date) {
      handleGetRateByDate();
    }
  }, [formManualTransaction.rate_date]);

  return (
    <div>
      <TabPane2>
        {data?.map((item, index) => (
          <TabPaneList2 label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
        ))}
      </TabPane2>
      <div className=" pt-[24px] border-t border-border-secondary mt-[24px]">
        <TabContent index={0} tab={tabPage}>
          <BuyTransaction />
        </TabContent>
        <TabContent index={1} tab={tabPage}>
          <SaleTransaction />
        </TabContent>
      </div>

      {handleShowUserListModal.state && <DialogListUser modal={handleShowUserListModal} />}
    </div>
  );
};

export default ManualTransaction;
