import Navbar from '@/delivery/components/organisms/navbar/navbar';
import Button from '@/delivery/components/atoms/button/button';
import Icon from '@/domain/constant/icon.tsx';
import StockATMGold from '@/delivery/ui/admin/transaction/atm_gold/stock_atm_gold/stock_atm_gold.tsx';

const StockATMGoldPage = () => {
  return (
    <div>
      <Navbar label={'Stock ATM Emas'}>
        <Button
          prefixIcon={Icon.plus}
          label="Add Stock"
          //   onClick={() => handleAddCounterOfflineModal.setState(true)}
        />
      </Navbar>
      <StockATMGold />
    </div>
  );
};

export default StockATMGoldPage;
