import { useEffect } from 'react';
import StockGoldPage from '@/delivery/ui/admin/transaction/take_gold/stock_gold';
import { StockGoldProvider } from '@/delivery/ui/admin/transaction/take_gold/stock_gold/context/stock_gold_context';

const ViewStockGold = () => {
  useEffect(() => {
    document.title = 'Stock emas';
  }, []);

  return (
    <div>
      <StockGoldProvider>
        <StockGoldPage />
      </StockGoldProvider>
    </div>
  );
};

export default ViewStockGold;
