import CreateWebPage from '@/delivery/ui/admin/web_admin/web_pages/create/create_web_page.tsx';
import { WebPageProvider } from '@/delivery/ui/admin/web_admin/web_pages/context/web_page_context.tsx';

const CreateWebPagePage = () => {
  return (
    <div>
      <WebPageProvider>
        <CreateWebPage />
      </WebPageProvider>
    </div>
  );
};

export default CreateWebPagePage;
