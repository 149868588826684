import { TBox } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useDinaranSettingContext } from '@/delivery/ui/admin/settings/dinaran_setting/context/dinaran_setting_context.tsx';
import { useEffect } from 'react';
import Icon from '@/domain/constant/icon.tsx';
import DialogListUser from '@/delivery/ui/admin/settings/dinaran_setting/dialog/dialog_list_user.tsx';

const DinaranSetting = () => {
  const {
    user,
    handleShowUser,
    handleShowUserListModal,
    handleUpdateDinaranSetting,
    handleShowDinaranSetting,
    formDinaranSetting,
    setFormDinaranSetting
  } = useDinaranSettingContext();

  useEffect(() => {
    handleShowDinaranSetting();
  }, []);

  //CHECK NAME
  useEffect(() => {
    if (formDinaranSetting.zakat_account) {
      handleShowUser({ uuid: formDinaranSetting.zakat_account });
    }
  }, [formDinaranSetting.zakat_account]);

  return (
    <div className={'flex justify-center'}>
      <TBox className={'flex flex-col gap-[15px] lg:w-3/4 w-full'}>
        {/*dca limit*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>DCA Limit (%)</Typography>
          <TextField
            block
            maxLength={3}
            type={'number'}
            placeholder={'dca limit (%)'}
            value={formDinaranSetting?.dca_limit}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, dca_limit: parseInt(e.target.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*dca_minimal_convert*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>DCA Minimal Convert</Typography>
          <TextField
            block
            maxLength={3}
            type={'number'}
            placeholder={'dca_minimal_convert'}
            value={formDinaranSetting?.dca_minimal_convert}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, dca_minimal_convert: parseInt(e.target.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*dca_fee*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>DCA Fee (%)</Typography>
          <TextField
            block
            maxLength={3}
            type={'number'}
            placeholder={'dca_fee'}
            value={formDinaranSetting?.dca_fee}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, dca_fee: parseInt(e.target.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*coin_convert*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>Coin Convert</Typography>
          <TextField
            block
            maxLength={3}
            type={'number'}
            placeholder={'coin_convert'}
            value={formDinaranSetting?.coin_convert}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, coin_convert: parseInt(e.target.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*first_buy*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>First Buy</Typography>
          <TextField
            block
            type={'currency'}
            placeholder={'first_buy'}
            value={formDinaranSetting.first_buy}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, first_buy: parseInt(e.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*first_buy_transfer*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>First Buy Transfer</Typography>
          <TextField
            block
            type={'currency'}
            placeholder={'first_buy_transfer'}
            value={formDinaranSetting.first_buy_transfer}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, first_buy_transfer: parseInt(e.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*maximum_buy*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>Maximum Buy</Typography>
          <TextField
            block
            type={'currency'}
            placeholder={'maximum_buy'}
            value={formDinaranSetting.maximum_buy}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, maximum_buy: parseInt(e.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*maximum_sell*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>Maximum Sell</Typography>
          <TextField
            block
            type={'currency'}
            placeholder={'maximum_sell'}
            value={formDinaranSetting.maximum_sell}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, maximum_sell: parseInt(e.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*maximum_sell_daily*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>Maximum Sell Daily</Typography>
          <TextField
            block
            type={'currency'}
            placeholder={'maximum_sell_daily'}
            value={formDinaranSetting.maximum_sell_daily}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, maximum_sell_daily: parseInt(e.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*maximum_sell_e-money*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>Maximum Sell E-money</Typography>
          <TextField
            block
            type={'currency'}
            placeholder={'maximum_sell_e-money'}
            value={formDinaranSetting.maximum_sell_emoney}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, maximum_sell_emoney: parseInt(e.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*maximum_transfer*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>Maximum Transfer</Typography>
          <TextField
            block
            type={'currency'}
            placeholder={'maximum_transfer'}
            value={formDinaranSetting.maximum_transfer}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, maximum_transfer: parseInt(e.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*maximum_transfer_daily*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>Maximum Transfer Daily</Typography>
          <TextField
            block
            type={'currency'}
            placeholder={'maximum_transfer_daily'}
            value={formDinaranSetting.maximum_transfer_daily}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, maximum_transfer_daily: parseInt(e.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*minimum_buy*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>Minimum Buy</Typography>
          <TextField
            block
            type={'currency'}
            placeholder={'minimum_buy'}
            value={formDinaranSetting.minimum_buy}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, minimum_buy: parseInt(e.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*minimum_sell*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>Minimum Sell</Typography>
          <TextField
            block
            type={'currency'}
            placeholder={'minimum_sell'}
            value={formDinaranSetting.minimum_sell}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, minimum_sell: parseInt(e.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*minimum_sell_emoney*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>Minimum Sell E-money</Typography>
          <TextField
            block
            type={'currency'}
            placeholder={'minimum_sell_emoney'}
            value={formDinaranSetting.minimum_sell_emoney}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, minimum_sell_emoney: parseInt(e.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*minimum_transfer*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>Minimum Transfer</Typography>
          <TextField
            block
            type={'currency'}
            placeholder={'minimum_transfer'}
            value={formDinaranSetting.minimum_transfer}
            onChange={(e: any) => {
              setFormDinaranSetting({ ...formDinaranSetting, minimum_transfer: parseInt(e.value) });
            }}
          />
        </div>
        <div className={'col-span-2'}>
          <Line />
        </div>

        {/*zakat account*/}
        <div className={'grid grid-cols-2 items-center'}>
          <Typography>Zakat Account</Typography>
          <div className={'flex gap-[10px]'}>
            <TextField disabled block placeholder={'Zakat Account'} value={user?.getFullName} />
            <Button prefixIcon={Icon.plus} onClick={() => handleShowUserListModal.setState(true)} />
          </div>
        </div>

        <div className={'col-span-2 mt-[20px]'}>
          <Line />
        </div>
        <div className={'flex justify-end gap-[20px]'}>
          <Button label={'Save Changes'} onClick={() => handleUpdateDinaranSetting()} />
        </div>
      </TBox>

      {handleShowUserListModal.state && <DialogListUser modal={handleShowUserListModal} />}
    </div>
  );
};

export default DinaranSetting;
