import clsx from 'clsx';

interface SwitchProps {
  label?: string;
  description?: string;
  onChange?: (e: any) => void;
  checked?: boolean;
  skeleton?: boolean;
  disabled?: boolean;
}

const Switch = (props: SwitchProps) => {
  return (
    <div>
      <label className="relative flex items-center cursor-pointer">
        <input
          type="checkbox"
          value=""
          checked={props.checked}
          className="sr-only peer"
          onChange={props.onChange}
          disabled={props.disabled}
        />
        {/* <div className="relative"> */}
        <div
          className={clsx({
            "border border-border-tertiary bg-border-tertiary w-11 h-6 bg-slate-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-button-primary-active  rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-border-tertiary after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-text-white after:border-border-tertiary after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-bg-primary":
              !props.skeleton && !props.disabled,
            ' border border-border-tertiary bg-border-tertiary w-11 h-6 loading-screen rounded-full after:absolute after:top-[2px] after:left-[2px] after:border after:rounded-full after:h-5 after:w-5 after:transition-all':
              props.skeleton,
            "border border-border-tertiary bg-border-tertiary w-11 h-6 bg-slate-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-button-primary-active  rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-border-tertiary after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-border-tertiary after:border-border-tertiary after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-bg":
              props.disabled
          })}
        ></div>
        {/* </div> */}
        <div className="flex flex-col">
          <span
            className={clsx({
              'ml-3 text-sm font-medium ': !props.skeleton,
              'ml-3 loading-screen rounded-full text-xs w-28': props.skeleton
            })}
          >
            {props.label}
          </span>
          <span
            className={clsx({
              'ml-3 text-[8px] ': !props.skeleton,
              'ml-3 loading-screen rounded-full text-xs w-28': props.skeleton
            })}
          >
            {props.description}
          </span>
        </div>
      </label>
    </div>
  );
};

export default Switch;
