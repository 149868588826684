import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useDepositByAdminContext } from './context/deposit_by_admin_context';
import DialogDepositByAdmin from './dialog/dialog_detail_depost_by_admin';
import { useEffect } from 'react';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import DialogListUserDeposit from '@/delivery/ui/admin/deposit_gold/deposit_by_admin/dialog/dialog_list_user.tsx';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import DialogDetailTransactionDeposit from '@/delivery/ui/admin/deposit_gold/deposit_by_admin/dialog/dialog_detail_transaction_deposit.tsx';
import FilterDepositByAdmin from '@/delivery/ui/admin/deposit_gold/deposit_by_admin/filter_deposit_gold.tsx';

const DepositByAdmin = () => {
  const {
    handleDetailTransactionDepositModal,
    handleShowTransaction,
    handleShowUserListModal,
    transactions,
    transactionPagination,
    handleFetchTransactionDeposit,
    handleDetailDepositByAdminModal
  } = useDepositByAdminContext();

  useEffect(() => {
    handleFetchTransactionDeposit({});
  }, []);

  return (
    <div>
      <div>
        <div className={'flex justify-end mb-[20px]'}>
          <FilterDepositByAdmin />
        </div>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>TrxID</Th>
              <Th>Member</Th>
              <Th>Type</Th>
              <Th>Status</Th>
              <Th>
                <span className={'whitespace-nowrap'}>Trx Date</span>
              </Th>
              <Th>Paid Amount</Th>
              <Th>Gramasi</Th>
              <Th>
                <span className={'whitespace-nowrap'}>Paid Date</span>
              </Th>
              <Th>Channel</Th>
              <Th>Gold Rate</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions.map((item: TransactionModel, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.code}
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {item.user?.getFullName}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.user?.email}
                  </Typography>
                </Td>
                <Td>
                  <span className={'whitespace-nowrap'}>
                    <Chips label={item.type} removeDot />
                  </span>
                </Td>
                <Td>
                  <Chips label={item.status} type={item.getStatusColor} />
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    <span className={'whitespace-nowrap'}>{item.getTransactionAtSingle}</span>
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getAmountSingle}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.gram}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    <span className={'whitespace-nowrap'}>{item.getSucceedAtSingle}</span>
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    <span className={'whitespace-nowrap'}>{item.trxDepositChannel}</span>
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="p" fontWeight={600} size={12} type="tertiary-invert">
                    {item.rate.getBuy}
                  </Typography>
                  <Typography tag="p" fontWeight={400} size={12} type="tertiary-invert">
                    {item.rate.getSell}
                  </Typography>
                </Td>

                <Td>
                  <div className="flex gap-[4px]">
                    <Button iconButton iconType={'view'} onClick={() => handleShowTransaction(item)} />
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            handlePagination={page => {
              handleFetchTransactionDeposit({
                page: page
              });
            }}
            page={transactionPagination?.page || 1}
            totalPages={transactionPagination?.totalPages || 1}
          />
        </Tfooter>
      </div>

      {handleDetailTransactionDepositModal.state && (
        <DialogDetailTransactionDeposit modal={handleDetailTransactionDepositModal} />
      )}

      {handleShowUserListModal.state && <DialogListUserDeposit modal={handleShowUserListModal} />}

      {handleDetailDepositByAdminModal.state && <DialogDepositByAdmin modal={handleDetailDepositByAdminModal} />}
    </div>
  );
};

export default DepositByAdmin;
