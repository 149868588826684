export const TYPE_VENDOR = {
  PAYMENT_CHANNEL: 'PAYMENT_CHANNEL',
  WITHDRAW_CHANNEL: 'WITHDRAW_CHANNEL',
  SHIPPING: 'SHIPPING',
  VENDING_MACHINE: 'VENDING_MACHINE'
};

export const TYPE_VENDOR_LIST = [
  { UUID: 'PAYMENT_CHANNEL', name: 'PAYMENT_CHANNEL' },
  { UUID: 'WITHDRAW_CHANNEL', name: 'WITHDRAW_CHANNEL' },
  { UUID: 'SHIPPING', name: 'SHIPPING' }
];
