import UserVerificationInterface, {
  UserVerificationValue
} from '@/domain/repository/interface/user_verification_interface';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { UserVerificationModel } from '@/domain/model/user_verification_model';
import { UserVerification } from '@/domain/constant/api_list';

export class UserVerificationRepository implements UserVerificationInterface {
  api = new ApiClient();

  async fetchUserVerification(data: UserVerificationValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(UserVerification.FETCH, {
            params: data
          })
          .then(result => {
            const data = UserVerificationModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showUserVerification(data: UserVerificationValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(UserVerification.SHOW, {
            params: data
          })
          .then(result => {
            const data = UserVerificationModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeUserVerification(data: UserVerificationValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(UserVerification.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeUserVerificationDashboard(data: UserVerificationValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(UserVerification.STORE_DASHBOARD, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkUserVerification(data: UserVerificationValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(UserVerification.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateUserVerification(data: UserVerificationValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(UserVerification.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteUserVerification(data: UserVerificationValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(UserVerification.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
