import OfflineCounterInterface, {
  OfflineCounterValue
} from '@/domain/repository/interface/offline_counter_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { OfflineCounterModel } from '@/domain/model/offline_counter_model.ts';
import { OfflineCounter } from '@/domain/constant/api_list';

export class OfflineCounterRepository implements OfflineCounterInterface {
  api = new ApiClient();

  async fetchOfflineCounter(data: OfflineCounterValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(OfflineCounter.FETCH, {
            params: data
          })
          .then(result => {
            const data = OfflineCounterModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showOfflineCounter(data: OfflineCounterValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(OfflineCounter.SHOW, {
            params: data
          })
          .then(result => {
            const data = OfflineCounterModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeOfflineCounter(data: OfflineCounterValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(OfflineCounter.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkOfflineCounter(data: OfflineCounterValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(OfflineCounter.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateOfflineCounter(data: OfflineCounterValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(OfflineCounter.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteOfflineCounter(data: OfflineCounterValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(OfflineCounter.DELETE + data.uid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
