import Navbar from '@/delivery/components/organisms/navbar/navbar';
import StockGold from './stock_gold';
import Button from '@/delivery/components/atoms/button/button';
import DialogAddStock from './dialog/dialog_add_stock';
import { useStockGoldeContext } from './context/stock_gold_context';
import Icon from '@/domain/constant/icon.tsx';

const StockGoldPage = () => {
  const { handleAddStockGoldModal } = useStockGoldeContext();
  return (
    <div>
      <Navbar label={'Stock Emas'}>
        <Button onClick={() => handleAddStockGoldModal.setState(true)} prefixIcon={Icon.plus} label="Add Stock" />
      </Navbar>
      <StockGold />

      {handleAddStockGoldModal.state && <DialogAddStock modal={handleAddStockGoldModal} />}
    </div>
  );
};

export default StockGoldPage;
