export const TYPE_TRANSACTION = {
  LOCK: 'LOCK',
  DREAM_GOLD: 'DREAM GOLD',
  COMMISSION: 'COMMISSION',
  BUY: 'BUY',
  SELL: 'SELL',
  TRANSFER: 'TRANSFER',
  PRINT: 'PRINT',
  DCA: 'DCA',
  DEPOSIT: 'DEPOSIT',
  ZAKAT: 'ZAKAT',
  REVERSAL: 'REVERSAL'
};
