import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';

import Button from '@/delivery/components/atoms/button/button.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useRoleeContext } from '@/delivery/ui/admin/settings/admin_access/context/role_context.tsx';

const DialogUpdateRole = (props: ModalDialogProps) => {
  const { handleUpdateRoleModal, handleUpdateRole, formRole, setFormRole } = useRoleeContext();
  return (
    <ModalDialog size="small" title="Update Role" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField
          label={'Role'}
          value={formRole?.name}
          block
          onChange={e => setFormRole({ ...formRole, name: e.target.value })}
        />
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleUpdateRoleModal.setState(false)} />
        <Button block label="Save" onClick={() => handleUpdateRole()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogUpdateRole;
