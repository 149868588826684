import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import RoleAccessInterface, { RoleAccessValue } from '@/domain/repository/interface/role_access_interface';
import { RoleAccess } from '@/domain/constant/api_list';
import { RoleAccessModel } from '@/domain/model/role_access_model';

interface roleValue {
  data: any;
  pagination: any;
}

export class RoleAccessRepository implements RoleAccessInterface {
  api = new ApiClient();

  async fetchRoleAccess(data: RoleAccessValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(RoleAccess.FETCH, {
            params: data
          })
          .then(result => {
            const data = RoleAccessModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role: roleValue = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showRoleAccess(data: RoleAccessValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(RoleAccess.SHOW, {
            params: data
          })
          .then(result => {
            const data = RoleAccessModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeRoleAccess(data: RoleAccessValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(RoleAccess.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkRoleAccess(data: RoleAccessValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(RoleAccess.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateRoleAccess(data: RoleAccessValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(RoleAccess.UPDATE + data.uuid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteRoleAccess(data: RoleAccessValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(RoleAccess.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
