import {
  Table,
  Tbody,
  TBox,
  Td,
  Tfooter,
  Th,
  Thead,
  Theader,
  Tr
} from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { useParams } from 'react-router-dom';
import { useGoldPieceContext } from '@/delivery/ui/admin/master/gold_piece/context/gold_piece_context.tsx';
import { useEffect } from 'react';
import { GoldPieceFeeModel } from '@/domain/model/gold_piece_fee_model.ts';
import DialogAddGoldPieceFee from '@/delivery/ui/admin/master/gold_piece/detail/gold_piece_fee/dialog_add_gold_piece_fee.tsx';

export const GoldPieceFee = () => {
  const { uid } = useParams();

  const { handleAddGoldPieceFeeModal, goldPieceFees, goldPieceFeePagination, handleFetchGoldPieceFee } =
    useGoldPieceContext();

  useEffect(() => {
    if (uid) {
      handleFetchGoldPieceFee({ gold_piece_uid: uid });
    }
  }, [uid]);

  return (
    <TBox>
      <Theader>
        <Typography size={16} fontWeight={600} type={'secondary-invert'}>
          Channel Pambayaran Fee
        </Typography>
        <Button
          prefixIcon={Icon.plus}
          label={'Add Payment Fee'}
          onClick={() => handleAddGoldPieceFeeModal.setState(true)}
        />
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Fee</Th>
            <Th>Type</Th>
            <Th>Effective Date Start</Th>
            <Th>Effective Date End</Th>
          </Tr>
        </Thead>
        <Tbody>
          {goldPieceFees?.map((item: GoldPieceFeeModel, index: number) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.getFee}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.type}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.getEffectiveDateStart}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.getEffectiveDateEnd}
                </Typography>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={goldPieceFeePagination?.page || 1}
          totalPages={goldPieceFeePagination?.totalPages || 1}
          handlePagination={page => {
            handleFetchGoldPieceFee({
              page: page,
              gold_piece_uid: uid
            });
          }}
        />
      </Tfooter>

      {handleAddGoldPieceFeeModal.state && <DialogAddGoldPieceFee modal={handleAddGoldPieceFeeModal} />}
    </TBox>
  );
};

export default GoldPieceFee;
