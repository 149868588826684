import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
// import Button from '@/delivery/components/atoms/button/button.tsx';
// import { FiDownloadCloud } from 'react-icons/fi';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { STATUS_TRANSACTION_LIST } from '@/domain/constant/transaction/status_transaction.ts';
import { useDepositByAdminContext } from '@/delivery/ui/admin/deposit_gold/deposit_by_admin/context/deposit_by_admin_context.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import { useEffect, useState } from 'react';

const FilterDepositByAdmin = () => {
  const { handleFetchTransactionDeposit, formFilterTransaction, setFormFilterTransaction } = useDepositByAdminContext();

  const [isResetting, setIsResetting] = useState(false);

  const handleReset = () => {
    setFormFilterTransaction({ search: '', status: '', transaction_at_start: '', transaction_at_end: '' });
    setIsResetting(true);
  };

  useEffect(() => {
    if (isResetting) {
      handleFetchTransactionDeposit({ page: 1, limit: 10 });
      setIsResetting(false);
    }
  }, [setFormFilterTransaction, isResetting]);

  return (
    <div className="flex gap-[12px]">
      <Button type={'secondary'} prefixIcon={Icon.reset} label={'Reset Filter'} onClick={handleReset} />
      <ButtonFilter
        onClickApply={() => handleFetchTransactionDeposit({})}
        size={'large'}
        titleFilter={'Filter Transaction'}
        label={'More Filter'}
      >
        <div className={'flex flex-col gap-[20px]'}>
          <Select2
            isSearchable={false}
            options={STATUS_TRANSACTION_LIST.map(item => {
              return {
                label: item.name,
                value: item.UUID
              };
            })}
            block
            label={'Status'}
            onChange={e => setFormFilterTransaction({ ...formFilterTransaction, status: e.value })}
          />
          <div className={'grid grid-cols-2 gap-[20px]'}>
            <Datepicker
              value={formFilterTransaction.transaction_at_start}
              label={'Start Date'}
              block
              onChange={e =>
                setFormFilterTransaction({
                  ...formFilterTransaction,
                  transaction_at_start: e
                })
              }
            />
            <Datepicker
              value={formFilterTransaction.transaction_at_end}
              label={'End Date'}
              block
              onChange={e =>
                setFormFilterTransaction({
                  ...formFilterTransaction,
                  transaction_at_end: e
                })
              }
            />
          </div>
        </div>
      </ButtonFilter>
      {/*<Button label="Export by Filter" type="secondary" prefixIcon={<FiDownloadCloud />} />*/}
    </div>
  );
};

export default FilterDepositByAdmin;
