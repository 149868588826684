import { formatDate, formatDateTime } from '@/infrastructure/helper/formatDate';
import { BaseModel } from '@/domain/model/base_model';
import CompanyModel from '@/domain/model/company_model';
import { MediaModel } from '@/domain/model/media_model';
import { UserVerificationModel } from '@/domain/model/user_verification_model.ts';
import { BalanceModel } from '@/domain/model/balance_model.ts';
import { UserCompanyModel } from '@/domain/model/user_company_model.ts';

// import { UserGroupRoleModel } from './user_group_role_model';

export class UserModel extends BaseModel {
  company: CompanyModel;
  companyUUID: string;
  defaultCompanyUUID: string;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  phone: string;
  registeredCompany: CompanyModel[];
  status: string;
  type: string;
  verifiedAt: string;
  // userGroup: UserGroupRoleModel[];
  // userRole: UserGroupRoleModel[];
  username: string;
  fullName: string;

  media: MediaModel;
  userVerification: UserModel;
  gender: string;
  balance: BalanceModel;
  birthPlace: string;
  birthDate: string;
  religion: string;
  name: string;
  balanceFiat: number;
  balanceGram: number;
  serialID: string;
  dcaStatus: string;
  userCompany: UserCompanyModel[];

  // get userGroupRole(): UserGroupRoleModel[] {
  //   const userGroup: any = [];
  //   if (this.userGroup.length > 0) {
  //     this.userGroup.map((item: UserGroupRoleModel) => {
  //       if (item.group) {
  //         userGroup.push(item);
  //       }
  //     });

  //     return userGroup;
  //   }
  //   return [];
  // }

  get getFullName(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }

    if (this.firstName && this.lastName === undefined) {
      return `${this.firstName}`;
    }

    if (this.firstName === undefined && this.lastName) {
      return `${this.lastName}`;
    }
    return '-';
  }

  get getCreatedAt(): string {
    if (this.createdAt) {
      return formatDateTime(this.createdAt);
    }
    return '-';
  }

  get getVerifiedAt(): string {
    if (this.verifiedAt) {
      return formatDateTime(this.verifiedAt);
    }
    return '-';
  }

  get getBirthDate(): string {
    if (this.birthDate) {
      return formatDate(this.birthDate);
    }
    return '-';
  }

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    company: CompanyModel,
    companyUUID: string,
    defaultCompanyUUID: string,
    email: string,
    emailVerified: boolean,
    firstName: string,
    lastName: string,
    phone: string,
    registeredCompany: CompanyModel[],
    status: string,
    type: string,
    verifiedAt: string,
    // userGroup: UserGroupRoleModel[],
    // userRole: UserGroupRoleModel[],
    username: string,
    media: MediaModel,
    userVerification: UserModel,
    gender: string,
    balance: BalanceModel,
    birthPlace: string,
    birthDate: string,
    religion: string,
    name: string,
    balanceFiat: number,
    balanceGram: number,
    serialID: string,
    dcaStatus: string,
    userCompany: UserCompanyModel[]
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.company = company;
    this.companyUUID = companyUUID;
    this.defaultCompanyUUID = defaultCompanyUUID;
    this.email = email;
    this.emailVerified = emailVerified;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.registeredCompany = registeredCompany;
    this.status = status;
    this.type = type;
    this.verifiedAt = verifiedAt;
    // this.userGroup = userGroup;
    // this.userRole = userRole;
    this.username = username;
    this.fullName = this.getFullName;
    this.media = media;
    this.userVerification = userVerification;
    this.gender = gender;
    this.balance = balance;
    this.birthPlace = birthPlace;
    this.birthDate = birthDate;
    this.religion = religion;
    this.name = name;
    this.balanceFiat = balanceFiat;
    this.balanceGram = balanceGram;
    this.serialID = serialID;
    this.dcaStatus = dcaStatus;
    this.userCompany = userCompany;
  }

  public static fromJson(data: any): any | UserModel {
    try {
      return new UserModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.company ? CompanyModel.fromJson(data.company) : {},
        data.company_uuid,
        data.default_company_uuid,
        data.email,
        data.email_verified,
        data.first_name,
        data.last_name,
        data.phone,
        data.registered_company,
        data.status,
        data.type,
        data.verified_at,
        // data.user_group ? UserGroupRoleModel.fromJsonList(data.user_group) : [],
        // data.user_roles,
        data.username,
        data.media_assignment ? MediaModel.fromJson(data.media_assignment?.media) : null,
        data.user_verification ? UserVerificationModel.fromJson(data.user_verification) : null,
        data.gender,
        data.balance ? BalanceModel.fromJson(data.balance) : null,
        data.birth_place,
        data.birth_date,
        data.religion,
        data.name,
        data.balance_fiat,
        data.balance_gram,
        data.serial_id,
        data.dca_status,
        data.user_company ? UserCompanyModel.fromJsonList(data.user_company) : []
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): UserModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(UserModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
