import styles from './Dropzone.module.css';
import useDropzone from '@/delivery/hooks/useDropzone';
import { HiMiniXMark } from 'react-icons/hi2';
import LimitCharacter from '@/infrastructure/helper/limitCharacter.tsx';
import { LuUploadCloud } from 'react-icons/lu';
import clsx from 'clsx';

interface DropZoneProps {
  id?: string;
  skeleton?: boolean;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  label?: string;
  onChange?: (e: any) => void;
  isMultiple?: boolean;
  fileTypes?: string[];
  required?: boolean;
  error?: string;
  value?: any;
}

const ADropzone = (props: DropZoneProps) => {
  const { value, id, isMultiple = false, size = 'medium', fileTypes = ['image/*'] } = props;
  const dropzone = useDropzone();

  const { data, dispatch } = dropzone;
  // onDragEnter sets inDropZone to true
  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
  };

  // onDragLeave sets inDropZone to false
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
  };

  // onDragOver sets inDropZone to true
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    // set dropEffect to copy i.e copy of the source item
    e.dataTransfer.dropEffect = 'copy';
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
  };

  // onDrop sets inDropZone to false and adds files to fileList
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    // get files from event on the dataTransfer object as an array
    let files = [...e.dataTransfer.files];

    // ensure a file or files are dropped
    if (files && files.length > 0) {
      // loop over existing files
      const existingFiles = data.fileList.map((f: any) => f.name);
      // check if file already exists, if so, don't add to fileList
      // this is to prevent duplicates
      files = files.filter(f => !existingFiles.includes(f.name));

      // dispatch action to add droped file or files to fileList
      dispatch({ type: 'ADD_FILE_TO_LIST', files });
      // reset inDropZone to false
      dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
    }
  };

  // handle file selection via input element
  const handleFileSelect = (e: any) => {
    // get files from event on the input element as an array
    let files = [...e.target.files];

    // ensure a file or files are selected
    if (files && files.length > 0) {
      // loop over existing files
      const existingFiles = data.fileList.map((f: any) => f.name);
      // check if file already exists, if so, don't add to fileList
      // this is to prevent duplicates
      files = files.filter(f => !existingFiles.includes(f.name));

      // dispatch action to add selected file or files to fileList
      dispatch({ type: 'ADD_FILE_TO_LIST', files });
      if (isMultiple) {
        props.onChange && props.onChange(files);
      } else {
        props.onChange && props.onChange(files[0]);
      }
    }
  };

  const handleRemoveFile = (file: any) => {
    // dispatch action to remove file from fileList
    dispatch({ type: 'REMOVE_FILE_FROM_LIST', file });
  };

  return (
    <>
      <div>
        <div className="flex pb-1">
          <span
            className={clsx('text-text-secondary-invert text-[14px] text-[500]', {
              'loading-screen rounded-full w-44': props.skeleton
            })}
          >
            {props.label}
          </span>
          {props.required && <span className="text-text-danger text-xs">*</span>}
        </div>
        <div
          className={clsx('border-border-tertiary border rounded-[10px]', {
            'loading-screen rounded-lg': props.skeleton,
            dropzone: !props.skeleton,
            'min-h-[200px]': size === 'large',
            'min-h-[150px]': size === 'medium',
            'min-h-[100px]': size === 'small'
          })}
          onDrop={e => handleDrop(e)}
          onDragOver={e => handleDragOver(e)}
          onDragEnter={e => handleDragEnter(e)}
          onDragLeave={e => handleDragLeave(e)}
        >
          {data?.fileList.length > 0 ? (
            <div className={styles.fileList}>
              <div className={clsx(styles.fileContainer, 'flex-col gap-[10px]')}>
                {/* loop over the fileData */}
                {data.fileList.map((f: any) => {
                  return (
                    <div
                      key={f.name}
                      className={'flex justify-between items-center w-full gap-2 shadow-lg rounded-md overflow-hidden'}
                    >
                      <div className="flex gap-4 items-center">
                        <img src={URL.createObjectURL(f)} alt={f.name} width={60} height={50} />
                        <span className={'text-xs text-gray-500'}>{LimitCharacter(f.name, 20)}</span>
                      </div>
                      <div className="px-3">
                        <HiMiniXMark
                          className={clsx('w-6 h-6 cursor-pointer text-button-danger', {
                            'text-gray-500': !props.isMultiple
                          })}
                          onClick={() => {
                            handleRemoveFile(f);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="px-4">
              <input
                value={value}
                id={`fileSelect-` + id}
                type="file"
                multiple={isMultiple}
                className={styles.files}
                onChange={e => handleFileSelect(e)}
                accept={fileTypes.join(', ')}
              />
              <div className={'flex flex-col items-center justify-center min-h-[150px]'}>
                <div className={'flex justify-center'}>
                  <LuUploadCloud className={'text-text-tertiary-invert'} size={30} />
                </div>

                <div className="flex justify-center font-semibold gap-1.5">
                  <label
                    htmlFor={`fileSelect-` + id}
                    className={'text-[14px] font-[400] text-text-primary cursor-pointer'}
                  >
                    Click to Upload
                  </label>
                  {/*<span>Click to Upload</span>*/}
                  <label
                    className={clsx('text-[14px] font-[400]', 'label-' + size, {
                      'text-base-primary ': !props.skeleton
                    })}
                  >
                    or drag and drop
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
        {props.error && props.error !== '' && (
          <div className="py-0.5 px-1">
            <small className="text-[10px] text-danger">{props.error}</small>
          </div>
        )}
        {/* Pass the selectect or dropped files as props */}
        {/* Only show upload button after selecting atleast 1 file */}
      </div>
    </>
  );
};

export default ADropzone;
