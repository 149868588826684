import { BaseModel } from '@/domain/model/base_model.ts';
import { DreamGoldModel } from '@/domain/model/dream_gold_model.ts';
import { BalanceModel } from '@/domain/model/balance_model.ts';
import { TierModel } from '@/domain/model/tier_model.ts';
import { ChipType } from '@/domain/constant/component/chip_colors.ts';
import { MEMBER_LOYALTY } from '@/domain/constant/member/member_loyalty.ts';

export class UserDashboardBalanceModel extends BaseModel {
  dreamGold: DreamGoldModel[];
  balanceTotal: BalanceModel;
  balanceSystemLock: BalanceModel;
  balanceMemberLock: BalanceModel;
  balance: BalanceModel;
  balanceCoin: BalanceModel;
  balanceDCA: BalanceModel[];
  tier: TierModel;
  profitLoss: number;
  dreamGoldTotal: number;
  dcaTotal: number;
  dcaLockTotal: number;
  transactionCount: number;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    dreamGold: DreamGoldModel[],
    balanceTotal: BalanceModel,
    balanceSystemLock: BalanceModel,
    balanceMemberLock: BalanceModel,
    balance: BalanceModel,
    balanceCoin: BalanceModel,
    balanceDCA: BalanceModel[],
    tier: TierModel,
    profitLoss: number,
    dreamGoldTotal: number,
    dcaTotal: number,
    dcaLockTotal: number,
    transactionCount: number
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.dreamGold = dreamGold;
    this.balanceTotal = balanceTotal;
    this.balanceSystemLock = balanceSystemLock;
    this.balanceMemberLock = balanceMemberLock;
    this.balance = balance;
    this.balanceCoin = balanceCoin;
    this.balanceDCA = balanceDCA;
    this.tier = tier;
    this.profitLoss = profitLoss;
    this.dreamGoldTotal = dreamGoldTotal;
    this.dcaTotal = dcaTotal;
    this.dcaLockTotal = dcaLockTotal;
    this.transactionCount = transactionCount;
  }

  get getTierColor(): ChipType {
    if (this.tier.name === MEMBER_LOYALTY.PLATINUM) {
      return 'primary-light';
    }
    if (this.tier.name === MEMBER_LOYALTY.GOLD) {
      return 'warning-light';
    }
    if (this.tier.name === MEMBER_LOYALTY.SILVER) {
      return 'info-light';
    }
  }

  public static fromJson(data: any): any | UserDashboardBalanceModel {
    try {
      return new UserDashboardBalanceModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.dream_gold ? DreamGoldModel.fromJsonList(data.dream_gold) : [],
        data.balance_total ? BalanceModel.fromJson(data.balance_total) : null,
        data.balance_system_lock ? BalanceModel.fromJson(data.balance_system_lock) : null,
        data.balance_member_lock ? BalanceModel.fromJson(data.balance_member_lock) : null,
        data.balance ? BalanceModel.fromJson(data.balance) : null,
        data.balance_coin ? BalanceModel.fromJson(data.balance_coin) : null,
        data.balance_dca ? BalanceModel.fromJsonList(data.balance_dca) : [],
        data.tier ? TierModel.fromJson(data.tier) : null,
        data.profit_loss,
        data.dream_gold_total,
        data.dca_total,
        data.dca_lock_total,
        data.transaction_count
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): UserDashboardBalanceModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(UserDashboardBalanceModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
