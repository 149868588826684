import LayoutAdmin from '@/delivery/components/template/layout_admin/layout_admin';
import { Outlet } from 'react-router-dom';

const MainApp = () => {
  return (
    <LayoutAdmin label="Dinaran">
      <Outlet />
    </LayoutAdmin>
  );
};

export default MainApp;
