import styles from './Chips.module.css';
import clsx from 'clsx';
import { GoDotFill } from 'react-icons/go';
import { ChipType } from '@/domain/constant/component/chip_colors.ts';

interface ChipsProps {
  className?: string;
  label?: string;
  prefixIcon?: any;
  removeDot?: boolean;
  type?: ChipType;
}

const Chips = (props: ChipsProps) => {
  const { type, label, className, prefixIcon = <GoDotFill />, removeDot = false } = props;

  const classProps = clsx(className, styles['chips'], styles[`${type}`]);

  return (
    <div className={classProps}>
      <div className="text-center inline-flex items-center align-middle">
        {removeDot === false ? (
          <span className={`${styles[`${type}`]} mr-[6px]`}>{prefixIcon ? prefixIcon : null}</span>
        ) : (
          ''
        )}

        <span>{label}</span>
      </div>
    </div>
  );
};

export default Chips;
