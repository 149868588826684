import GoldRateInterface, { GoldRateValue } from '@/domain/repository/interface/gold_rate_interface';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { GoldRateModel } from '@/domain/model/gold_rate_model';
import { GoldRate } from '@/domain/constant/api_list';
import { GoldRateLatestModel } from '@/domain/model/gold_rate_lates_model.ts';
import { parseParams } from '@/infrastructure/helper/parseParams.tsx';

export class GoldRateRepository implements GoldRateInterface {
  api = new ApiClient();

  async fetchGoldRate(data: GoldRateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(GoldRate.FETCH, {
            params: data,
            paramsSerializer: (params: any) => parseParams(data)
          })
          .then(result => {
            const data = GoldRateModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchGoldRateByDate(data: GoldRateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(GoldRate.FETCH, {
            params: data
          })
          .then(result => {
            const data = GoldRateModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showGoldRate(data: GoldRateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(GoldRate.SHOW, {
            params: data
          })
          .then(result => {
            const data = GoldRateModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async latestGoldRate() {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(GoldRate.LATEST, {})
          .then(result => {
            const data = GoldRateLatestModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeGoldRate(data: GoldRateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(GoldRate.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkGoldRate(data: GoldRateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(GoldRate.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateGoldRate(data: GoldRateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(GoldRate.UPDATE + data.uuid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteGoldRate(data: GoldRateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(GoldRate.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
