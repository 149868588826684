import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';

import Button from '@/delivery/components/atoms/button/button.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useEffect } from 'react';
import { usePermissioneContext } from '@/delivery/ui/admin/settings/admin_access/context/permission_context.tsx';

const DialogAddPermission = (props: ModalDialogProps) => {
  const { handleAddPermission, handleAddPermissionModal, formPermission, setFormPermission } = usePermissioneContext();

  useEffect(() => {
    setFormPermission({ access: '', UUID: '' });
  }, []);

  return (
    <ModalDialog size="small" title="Add Permission" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField
          label={'Access'}
          value={formPermission?.access}
          block
          onChange={e => setFormPermission({ ...formPermission, access: e.target.value })}
        />
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleAddPermissionModal.setState(false)} />
        <Button block label="Save" onClick={() => handleAddPermission()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddPermission;
