import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';

import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import { UserWithdrawalAccountRepository } from '@/domain/repository/user_withdrawal_account_repository';
import { UserWithdrawalAccountValue } from '@/domain/repository/interface/user_withdrawal_account_interface';
import { UserWithdrawalAccountModel } from '@/domain/model/user_withdrawal_account_model';
import { PaginationModel } from '@/domain/model/pagination_model';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { MasterBankRepository } from '@/domain/repository/master_bank_repository.ts';
import { MasterBankModel } from '@/domain/model/master_bank_model.ts';

interface MemberVerifyContContextProps {
  children: ReactNode;
}

interface FormVerifyEMoney {
  uid?: string;
  notes?: string;
}

interface FormFilterMemberVerifyEMoney {
  search?: string;
  master_bank_uuid?: string;
  account_holder_name?: string;
  account_number?: string;
  type?: string;
  status?: string;
}

interface VerifyEMoneyContextValue {
  masterBanks: MasterBankModel[];
  formFilterMemberVerifyEMoney: FormFilterMemberVerifyEMoney;
  setFormFilterMemberVerifyEMoney: Dispatch<SetStateAction<FormFilterMemberVerifyEMoney>>;
  formVerifyEMoney: FormVerifyEMoney;
  setFormVerifyEMoney: Dispatch<SetStateAction<FormVerifyEMoney>>;
  verifyEMoney?: UserWithdrawalAccountModel;
  verifyEMonies: UserWithdrawalAccountModel[];
  verifyEMoneyPagination?: PaginationModel;
  handleDetailMemberVerifyModal: ModalObject;
  handleFetchMemberRequestVerifyEMoney: (_data: UserWithdrawalAccountValue) => void;
  handleDetailMemberVerify: (_data: UserWithdrawalAccountModel) => void;
  handleApproveEMoney: () => void;
  handleRejectEMoney: () => void;
  handleFetchMasterBank: () => void;
}

const MemberVerifyContext = createContext<VerifyEMoneyContextValue | null>(null);

const userWithdrawal = new UserWithdrawalAccountRepository();
const masterBankRep = new MasterBankRepository();

const VerifyEMoneyProvider: React.FC<MemberVerifyContContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [masterBanks, setMasterBanks] = useState<MasterBankModel[]>([]);

  const [verifyEMoney, setVerifyEMoney] = useState<UserWithdrawalAccountModel>();
  const [verifyEMonies, setVerifyEMonies] = useState<UserWithdrawalAccountModel[]>([]);
  const [verifyEMoneyPagination, setVerifyEMoneyPagination] = useState<PaginationModel>();

  const [formVerifyEMoney, setFormVerifyEMoney] = useState<FormVerifyEMoney>({});

  const handleDetailMemberVerifyModal = useVisibleComponent(false);

  const [formFilterMemberVerifyEMoney, setFormFilterMemberVerifyEMoney] = useState<FormFilterMemberVerifyEMoney>({});

  //HANDLE FETCH DATA MEMBER VERIFY E MONEY
  const handleFetchMemberRequestVerifyEMoney = (_data: UserWithdrawalAccountValue) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      search: formFilterMemberVerifyEMoney.search ?? null,
      master_bank_uuid: formFilterMemberVerifyEMoney.master_bank_uuid ?? null,
      account_holder_name: formFilterMemberVerifyEMoney.account_holder_name ?? null,
      account_number: formFilterMemberVerifyEMoney.account_number ?? null,
      type: formFilterMemberVerifyEMoney.type ?? null,
      status: formFilterMemberVerifyEMoney.status ?? null
    };

    userWithdrawal
      .fetchUserWithdrawalAccount(params)
      .then((result: any) => {
        setVerifyEMonies(result.data);
        setVerifyEMoneyPagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE DETAIL DATA MEMBER
  const handleDetailMemberVerify = (_data: UserWithdrawalAccountModel) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uid: _data.UID
    };

    userWithdrawal
      .showUserWithdrawalAccount(params)
      .then((result: any) => {
        const res: UserWithdrawalAccountModel = result;
        setVerifyEMoney(result);
        setFormVerifyEMoney({
          uid: res.UID,
          notes: res.notes
        });
        handleDetailMemberVerifyModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE APPROVE / UPDATE E MONEY
  const handleApproveEMoney = () => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          uid: verifyEMoney?.UID,
          account_holder_name: verifyEMoney?.accountHolderName,
          account_number: verifyEMoney?.accountNumber,
          master_bank_uuid: verifyEMoney?.masterBankUUID,
          media_uid: verifyEMoney?.mediaUID,
          notes: formVerifyEMoney.notes,
          status: STATUS_CORPORATE.VERIFIED,
          type: verifyEMoney?.type,
          user_uid: verifyEMoney?.userUID
        };

        userWithdrawal
          .updateUserWithdrawalAccount(params)
          .then(() => {
            handleFetchMemberRequestVerifyEMoney({ page: 1 });
            handleDetailMemberVerifyModal.setState(false);
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE REJECT / UPDATE E MONEY
  const handleRejectEMoney = () => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          uid: verifyEMoney?.UID,
          account_holder_name: verifyEMoney?.accountHolderName,
          account_number: verifyEMoney?.accountNumber,
          master_bank_uuid: verifyEMoney?.masterBankUUID,
          media_uid: verifyEMoney?.mediaUID,
          notes: formVerifyEMoney.notes,
          status: STATUS_CORPORATE.REJECT,
          type: verifyEMoney?.type,
          user_uid: verifyEMoney?.userUID
        };

        userWithdrawal
          .updateUserWithdrawalAccount(params)
          .then(() => {
            handleFetchMemberRequestVerifyEMoney({ page: 1 });
            handleDetailMemberVerifyModal.setState(false);
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const handleFetchMasterBank = () => {
    const x = alertToast.loadingAlert('Fetching master bank');

    const params = {
      page: 1,
      limit: -1,
      e_money: true
    };

    masterBankRep
      .fetchMasterBank(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setMasterBanks(result.data);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: VerifyEMoneyContextValue = {
    masterBanks,
    handleFetchMasterBank,
    formFilterMemberVerifyEMoney,
    setFormFilterMemberVerifyEMoney,
    formVerifyEMoney,
    setFormVerifyEMoney,
    verifyEMoney,
    verifyEMonies,
    verifyEMoneyPagination,
    handleDetailMemberVerifyModal,
    handleFetchMemberRequestVerifyEMoney,
    handleDetailMemberVerify,
    handleApproveEMoney,
    handleRejectEMoney
  };

  return <MemberVerifyContext.Provider value={contextValue}>{children}</MemberVerifyContext.Provider>;
};

const useVerifyEMoneyContext = (): VerifyEMoneyContextValue => {
  const context = useContext(MemberVerifyContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a VerifyEMoneyProvider');
  }
  return context;
};

export { VerifyEMoneyProvider, useVerifyEMoneyContext };
