import Navbar from '@/delivery/components/organisms/navbar/navbar';
import ActivityLog from './activity_log';

const ActivityLogPage = () => {
  return (
    <div>
      <Navbar label={'Activity Log'}></Navbar>
      <ActivityLog />
    </div>
  );
};

export default ActivityLogPage;
