import { BaseModel } from '@/domain/model/base_model.ts';
import { UserModel } from '@/domain/model/user_model.ts';

export class UserDashboardModel extends BaseModel {
  username: string;
  firstName: string;
  serialID: string;
  birthDate: string;
  email: string;
  phone: string;
  parentAffiliate: UserModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    username: string,
    firstName: string,
    serialID: string,
    birthDate: string,
    email: string,
    phone: string,
    parentAffiliate: UserModel
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.username = username;
    this.firstName = firstName;
    this.serialID = serialID;
    this.birthDate = birthDate;
    this.email = email;
    this.phone = phone;
    this.parentAffiliate = parentAffiliate;
  }

  public static fromJson(data: any): UserDashboardModel | any {
    try {
      return new UserDashboardModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.username,
        data.first_name,
        data.serial_id,
        data.birth_date,
        data.email,
        data.phone,
        data.parent_user_affiliate?.parent ? UserModel.fromJson(data.parent_user_affiliate?.parent) : null
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): UserDashboardModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(UserDashboardModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
