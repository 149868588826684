import Navbar from '@/delivery/components/organisms/navbar/navbar';
import ReferralTransaction from './referral_transaction';

const ReferralTransactionPage = () => {
  return (
    <div>
      <Navbar label={'Referral Transaksi'}></Navbar>
      <ReferralTransaction />
    </div>
  );
};

export default ReferralTransactionPage;
