import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { useStockGoldeContext } from '@/delivery/ui/admin/transaction/take_gold/stock_gold/context/stock_gold_context.tsx';
import { TypeGoldStockList } from '@/domain/constant/gold_stock/type_gold_stock.ts';
import { useEffect } from 'react';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';

export const FilterStockGold = () => {
  const {
    handleFetchOfflineCounter,
    offlineCounter,
    handleFetchGoldPiece,
    goldPieces,
    handleFetchGoldStock,
    formFilterGoldStock,
    setFormFilterGoldStock
  } = useStockGoldeContext();

  useEffect(() => {
    handleFetchGoldPiece();
    handleFetchOfflineCounter();
  }, []);

  return (
    <div className="flex gap-[12px]">
      <ButtonFilter
        onClickApply={() => handleFetchGoldStock({})}
        size={'large'}
        titleFilter={'Filter Stock Gold'}
        label={'More Filter'}
      >
        <div className={'grid grid-cols-2 gap-[20px]'}>
          <Select2
            isSearchable={false}
            options={TypeGoldStockList.map(item => {
              return {
                label: item.name,
                value: item.UUID
              };
            })}
            block
            label={'Type'}
            onChange={e => setFormFilterGoldStock({ ...formFilterGoldStock, type: e.value })}
          />
          <Select2
            isSearchable={false}
            options={goldPieces.map(item => {
              return {
                label: item.gram,
                value: item.gram.toString()
              };
            })}
            block
            label={'Type'}
            onChange={e => setFormFilterGoldStock({ ...formFilterGoldStock, gram: parseInt(e.value) })}
          />
          <Select2
            isSearchable={true}
            options={offlineCounter.map(item => {
              return {
                label: item.name,
                value: item.UID
              };
            })}
            block
            label={'Type'}
            onChange={e => setFormFilterGoldStock({ ...formFilterGoldStock, counter_uid: e.value })}
          />
          <TextField
            type={'number'}
            block
            label={'Quantity'}
            onChange={e => setFormFilterGoldStock({ ...formFilterGoldStock, quantity: parseInt(e.target.value) })}
          />
        </div>
      </ButtonFilter>
    </div>
  );
};

export default FilterStockGold;
