import Navbar from '@/delivery/components/organisms/navbar/navbar';
import AccountList from '@/delivery/ui/admin/data_dca/account_list/account_list.tsx';

const AccountListPage = () => {
  return (
    <div>
      <Navbar label={'Account List'}></Navbar>
      <AccountList />
    </div>
  );
};

export default AccountListPage;
