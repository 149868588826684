import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { RoleRepository } from '@/domain/repository/role_repository.ts';
import { RoleModel } from '@/domain/model/role_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { getProfile } from '@/infrastructure/helper/auth.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';

interface DialogContextProps {
  children: ReactNode;
}

interface RoleValueParams {
  page?: number;
  limit?: number;
}

interface FormRoleInterface {
  UUID?: string;
  name?: string;
  company_uuid?: string;
}

interface RoleValue {
  formRole: FormRoleInterface;
  setFormRole: Dispatch<SetStateAction<FormRoleInterface>>;
  // role: FormRoleInterface | undefined;
  roles: RoleModel[];
  rolePagination?: PaginationModel;
  handleAddRoleModal: ModalObject;
  handleUpdateRoleModal: ModalObject;
  handleFetchRole: (_data: RoleValueParams) => void;
  handleShowRole: (_data: FormRoleInterface) => void;
  handleUpdateRole: () => void;
  handleAddRole: () => void;
  handleDeleteRole: (_data: RoleModel) => void;
}

const RoleContext = createContext<RoleValue | null>(null);

const roleRepository = new RoleRepository();

const RoleProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const profile = getProfile();

  const handleAddRoleModal = useVisibleComponent(false);
  const handleUpdateRoleModal = useVisibleComponent(false);

  // const [role, setRole] = useState<RoleModel>();
  const [roles, setRoles] = useState<RoleModel[]>([]);
  const [rolePagination, setRolePagination] = useState<PaginationModel>();

  const [formRole, setFormRole] = useState<FormRoleInterface>({});

  //FETCH ROLE
  const handleFetchRole = (_data: RoleValueParams) => {
    // let x = alertToast.loadingAlert("");
    const params = {
      page: _data?.page ?? 1,
      limit: _data?.limit ?? 10
    };

    roleRepository
      .fetchRole(params)
      .then((result: any) => {
        // alertToast.updateLoading(x);
        setRoles(result.data);
        setRolePagination(result.pagination);
      })
      .catch(error => {
        // alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW ROLE
  const handleShowRole = (_data: FormRoleInterface) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uuid: _data.UUID
    };

    roleRepository
      .showRole(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setFormRole({
          UUID: result.UUID,
          name: result.name
        });
        handleUpdateRoleModal.setState(true);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //UPDATE ROLE
  const handleUpdateRole = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: formRole.UUID,
      name: formRole.name
    };

    roleRepository
      .updateRole(params)
      .then(() => {
        alertToast.updateLoading(x);
        handleFetchRole({ page: 1 });
        handleUpdateRoleModal.setState(false);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //ADD ROLE
  const handleAddRole = () => {
    const x = alertToast.loadingAlert('');
    const params = {
      name: formRole.name,
      company_uuid: profile?.company_uuid
    };

    roleRepository
      .storeRole(params)
      .then(() => {
        alertToast.updateLoading(x);
        alertToast.successAlert();
        handleAddRoleModal.setState(false);
        handleFetchRole({ page: 1 });
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //DELETE ROLE
  const handleDeleteRole = (_data: RoleModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const params = {
          uuid: _data.UUID
        };
        roleRepository
          .deleteRole(params)
          .then(() => {
            alertToast.successAlert();
            handleFetchRole({ page: 1 });
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: RoleValue = {
    formRole,
    setFormRole,
    // role,
    roles,
    rolePagination,
    handleFetchRole,
    handleAddRoleModal,
    handleUpdateRoleModal,
    handleShowRole,
    handleUpdateRole,
    handleAddRole,
    handleDeleteRole
  };

  return <RoleContext.Provider value={contextValue}>{children}</RoleContext.Provider>;
};

const useRoleeContext = (): RoleValue => {
  const context = useContext(RoleContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a RoleProvider');
  }
  return context;
};

export { RoleProvider, useRoleeContext };
