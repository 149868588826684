import Transaction from './transaction';
import Navbar from '@/delivery/components/organisms/navbar/navbar';

const TransactionPage = () => {
  return (
    <div>
      <Navbar label={'Transaction'}>
        {/*<div className="flex gap-[12px]">*/}
        {/*  <Button type="secondary" prefixIcon={<AiOutlineCalendar />} label="19 Apr 2023 - 19 May 2023" />*/}
        {/*  <Button type="secondary" prefixIcon={<BsFilter />} label="Filter" />*/}
        {/*</div>*/}
      </Navbar>
      <Transaction />
    </div>
  );
};

export default TransactionPage;
