import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';
import Dropzone from '@/delivery/components/atoms/dropzone/dropzone.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useBusinessAccountContext } from '@/delivery/ui/admin/data_member/member_list/context/business_account_context.tsx';

const DialogUpdateDocumentCorporate = (props: ModalDialogProps) => {
  const { handleEditDocumentModal, handleEditDocument, corporate, setFormCorporate, formCorporate } =
    useBusinessAccountContext();

  return (
    <ModalDialog size="small" title="Update Dokumen" modal={props.modal}>
      <div className="flex flex-col gap-[30px]">
        <div className={'flex gap-[20px]'}>
          {corporate?.companyRegistrationNumberMediaUID !== '' ? (
            <div className={'flex-col gap-[10px]'}>
              <Typography className={'mb-[5px]'} size={14} type={'tertiary-invert'}>
                NIB
              </Typography>
              <Image url={corporate?.getCompanyRegistrationNumberUrl} width={300} />
            </div>
          ) : (
            ''
          )}

          <Dropzone
            id={'nib'}
            label={'NIB Baru'}
            onChange={e => setFormCorporate({ ...formCorporate, company_registration_number_media: e })}
          />
        </div>

        <div className={'flex gap-[20px]'}>
          {corporate?.companyDeedMediaUID !== '' ? (
            <div className={'flex-col gap-[10px]'}>
              <Typography className={'mb-[5px]'} size={14} type={'tertiary-invert'}>
                Akta
              </Typography>
              <Image url={corporate?.getCompanyDeedMediaUrl} width={300} />
            </div>
          ) : (
            ''
          )}

          <Dropzone
            id={'akta'}
            label={'Akta Baru'}
            onChange={e => setFormCorporate({ ...formCorporate, company_deed_media: e })}
          />
        </div>

        <div className={'flex gap-[20px]'}>
          {corporate?.taxpayerIdentificationNumberMediaUID !== '' ? (
            <div className={'flex-col gap-[10px]'}>
              <Typography className={'mb-[5px]'} size={14} type={'tertiary-invert'}>
                NPWP
              </Typography>
              <Image url={corporate?.getTaxpayerIdentificationNumberUrl} width={300} />
            </div>
          ) : (
            ''
          )}

          <Dropzone
            id={'npwp'}
            label={'NPWP'}
            onChange={e => setFormCorporate({ ...formCorporate, tax_payer_identification_number_media: e })}
          />
        </div>

        <div className={'flex gap-[20px]'}>
          {corporate?.identityMediaUID !== '' ? (
            <div className={'flex-col gap-[10px]'}>
              <Typography className={'mb-[5px]'} size={14} type={'tertiary-invert'}>
                KTP Penangungjawab
              </Typography>
              <Image url={corporate?.getIdentityUrl} width={300} />
            </div>
          ) : (
            ''
          )}

          <Dropzone
            id={'ktp'}
            label={'KTP Penanggungjawab'}
            onChange={e => setFormCorporate({ ...formCorporate, identity_media: e })}
          />
        </div>

        <ModalDialogFooter className="flex gap-[20px] justify-end">
          <Button type="secondary" label="Cancel" onClick={() => handleEditDocumentModal.setState(false)} />
          <Button label="Save" onClick={() => handleEditDocument()} />
        </ModalDialogFooter>
      </div>
    </ModalDialog>
  );
};

export default DialogUpdateDocumentCorporate;
