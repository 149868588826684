import { Table, Tbody, Td, Th, Thead, Theader, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useTiereContext } from '@/delivery/ui/admin/master/member_loyalty/context/member_loyalty_context.tsx';
import { useEffect, useState } from 'react';
import { TierModel } from '@/domain/model/tier_model.ts';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import DialogUpdateMemberLoyalty from '@/delivery/ui/admin/master/member_loyalty/dialog/dialog_update_member_loyalty.tsx';

const MasterMemberLoyalty = () => {
  const { handleDeleteTier, handleUpdateTierModal, handleShowTier, tierPagination, handleFetchTier, tiers } =
    useTiereContext();

  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    handleFetchTier({});
  }, []);

  useEffect(() => {
    if (search !== '') {
      handleFetchTier({ name: search });
    } else {
      handleFetchTier({});
    }
  }, [search]);

  return (
    <div>
      <Theader>
        <TextField
          search
          placeholder="search name"
          onChange={e => {
            setTimeout(() => {
              setSearch(e.target.value);
            }, 1000);
          }}
        />
      </Theader>
      <Table className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Gram minimum (gr)</Th>
            <Th>Sell</Th>
            <Th>Transfer</Th>
            <Th>Description</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tiers.map((item: TierModel, index) => (
            <Tr key={index}>
              <Td>
                <Typography size={14} fontWeight={400} type="secondary-invert">
                  {item.name}
                </Typography>
              </Td>
              <Td>
                <Typography size={14} fontWeight={400} type="secondary-invert">
                  {item.gramMinimal}
                </Typography>
              </Td>
              <Td>
                <Typography size={14} fontWeight={400} type="secondary-invert">
                  Max :{' '}
                  <Typography tag={'span'} size={14} fontWeight={600} type="secondary-invert">
                    {item.getMaximumSell}
                  </Typography>
                </Typography>
                <Typography size={14} fontWeight={400} type="secondary-invert">
                  Max Harian :{' '}
                  <Typography tag={'span'} size={14} fontWeight={600} type="secondary-invert">
                    {item.getMaximumSellDaily}
                  </Typography>
                </Typography>
              </Td>
              <Td>
                <Typography size={14} fontWeight={400} type="secondary-invert">
                  Max :{' '}
                  <Typography tag={'span'} size={14} fontWeight={600} type="secondary-invert">
                    {item.getMaximumTransfer}
                  </Typography>
                </Typography>
                <Typography size={14} fontWeight={400} type="secondary-invert">
                  Max Harian :{' '}
                  <Typography tag={'span'} size={14} fontWeight={600} type="secondary-invert">
                    {item.getMaximumTransferDaily}
                  </Typography>
                </Typography>
              </Td>
              <Td>
                <Typography size={14} fontWeight={400} type="secondary-invert">
                  {item.description}
                </Typography>
              </Td>
              <Td>
                <Button iconButton iconType="delete" onClick={() => handleDeleteTier(item)} />
                <Button iconButton iconType="edit" onClick={() => handleShowTier(item)} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={tierPagination?.page || 1}
          totalPages={tierPagination?.totalPages || 1}
          handlePagination={page => {
            handleFetchTier({
              page: page
            });
          }}
        />
      </Tfooter>

      {handleUpdateTierModal.state && <DialogUpdateMemberLoyalty modal={handleUpdateTierModal} />}
    </div>
  );
};

export default MasterMemberLoyalty;
