import useAlertToast from '@/delivery/hooks/useAlertComponent';

import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { DinaranSettingRepository } from '@/domain/repository/dinaran_setting_repository.ts';
import { getProfile } from '@/infrastructure/helper/auth.ts';
import { DinaranSettingModel } from '@/domain/model/dinaran_setting_model.ts';
import { ModalObject } from '@/delivery/interface/modal_interface.ts';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent.tsx';
import { ProfileModel } from '@/domain/model/profile_model.ts';
import { ProfileRepository } from '@/domain/repository/profile_repository.ts';

interface DinaranSettingContextProps {
  children: ReactNode;
}

interface FormUser {
  uuid?: string;
  search?: string;
}

interface DinaranSettingValueInterface {
  uid?: string;
  company_uuid?: string;
  dca_limit?: number;
  first_buy?: number;
  first_buy_transfer?: number;
  maximum_buy?: number;
  maximum_sell?: number;
  maximum_sell_daily?: number;
  maximum_sell_emoney?: number;
  maximum_transfer?: number;
  maximum_transfer_daily?: number;
  minimum_buy?: number;
  minimum_sell?: number;
  minimum_sell_emoney?: number;
  minimum_transfer?: number;
  dca_minimal_convert?: number;
  dca_fee?: number;
  coin_convert?: number;
  zakat_account?: string;
}

interface DinaranSettingContextValue {
  user?: ProfileModel;
  users: ProfileModel[];
  loading: boolean;
  handleShowUserListModal: ModalObject;
  setUsers: Dispatch<SetStateAction<ProfileModel[]>>;
  formDinaranSetting: DinaranSettingValueInterface;
  setFormDinaranSetting: Dispatch<SetStateAction<DinaranSettingValueInterface>>;
  handleShowDinaranSetting: () => void;
  handleUpdateDinaranSetting: () => void;
  handleFetchUser: (_data: FormUser) => void;
  handleShowUser: (_data: FormUser) => void;
}

const DinaranSettingContext = createContext<DinaranSettingContextValue | null>(null);

const dinaranSetting = new DinaranSettingRepository();
const userRepository = new ProfileRepository();

const DinaranSettingProvider: React.FC<DinaranSettingContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const profile = getProfile();

  const [user, setUser] = useState<ProfileModel>();
  const [users, setUsers] = useState<ProfileModel[]>([]);
  const [formDinaranSetting, setFormDinaranSetting] = useState<DinaranSettingValueInterface>({});

  const [loading, setLoading] = useState<boolean>(false);

  const handleShowUserListModal = useVisibleComponent(false);

  //HANDLE SHOW DINARAN SETTING
  const handleShowDinaranSetting = () => {
    const x = alertToast.loadingAlert('');
    const params = {
      company_uuid: profile?.company_uuid
    };

    dinaranSetting
      .showDinaranSetting(params)
      .then((res: any) => {
        const result: DinaranSettingModel = res;
        setFormDinaranSetting({
          uid: result.UID,
          company_uuid: result.companyUUID,
          dca_limit: result.dcaLimit,
          first_buy: result.firstBuy,
          first_buy_transfer: result.firstBuyTransfer,
          maximum_buy: result.maximumBuy,
          maximum_sell: result.maximumSell,
          maximum_sell_daily: result.maximumSellDaily,
          maximum_sell_emoney: result.maximumSellEmoney,
          maximum_transfer: result.maximumTransfer,
          maximum_transfer_daily: result.maximumTransferDaily,
          minimum_buy: result.minimumBuy,
          minimum_sell: result.minimumSell,
          minimum_sell_emoney: result.minimumSellEmoney,
          minimum_transfer: result.minimumTransfer,
          dca_minimal_convert: result.dcaMinimalConvert,
          dca_fee: result.dcaFee,
          coin_convert: result.coinConvert,
          zakat_account: result.zakatAccount
        });
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
        alertToast.updateLoading(x);
      });
  };

  //HANDLE UPDATE DINARAN SETTING
  const handleUpdateDinaranSetting = () => {
    const x = alertToast.loadingAlert('');

    dinaranSetting
      .updateDinaranSetting(formDinaranSetting)
      .then((result: any) => {
        alertToast.successAlert();
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //FETCH USER TRANSACTION SEARCH
  const handleFetchUser = (_data: FormUser) => {
    setLoading(true);
    const params = {
      search: _data.search
      // type: MEMBER_TYPE.USER
    };
    userRepository
      .fetchProfile(params)
      .then((result: any) => {
        setLoading(false);
        setUsers(result.data);
      })
      .catch(error => {
        setLoading(false);
        alertToast.errorAlert(error);
      });
  };

  //SHOW USER
  const handleShowUser = (_data: FormUser) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uuid: _data.uuid
    };
    userRepository
      .showProfile(params)
      .then((result: any) => {
        setUser(result);
        alertToast.updateLoading(x);
        setUsers(result.data);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: DinaranSettingContextValue = {
    setUsers,
    user,
    users,
    loading,
    formDinaranSetting,
    setFormDinaranSetting,
    handleShowDinaranSetting,
    handleUpdateDinaranSetting,
    handleFetchUser,
    handleShowUser,
    handleShowUserListModal
  };

  return <DinaranSettingContext.Provider value={contextValue}>{children}</DinaranSettingContext.Provider>;
};

const useDinaranSettingContext = (): DinaranSettingContextValue => {
  const context = useContext(DinaranSettingContext);
  if (!context) {
    throw new Error('useDinaranSettingContext must be used within a DinaranSettingProvider');
  }
  return context;
};

export { DinaranSettingProvider, useDinaranSettingContext };
