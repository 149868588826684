import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useOpenChannelContext } from '@/delivery/ui/admin/settings/open_channel/context/open_channel_context.tsx';
import TypeFee, { TypeFeeConst } from '@/domain/constant/type_fee.tsx';
import { useEffect } from 'react';
// import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import { useParams } from 'react-router-dom';

const DialogAddPaymentFee = (props: ModalDialogProps) => {
  const { uid } = useParams();
  const { handleAddPaymentFeeDetailModal, formChannelFee, setFormChannelFee, handleStoreChannelFee } =
    useOpenChannelContext();

  useEffect(() => {
    setFormChannelFee({});
  }, []);

  useEffect(() => {
    setFormChannelFee({ paymentChannelUID: uid });
  }, [uid]);

  return (
    <ModalDialog size="small" title="Add Payment Fee" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Select2
          label={'Type'}
          options={TypeFee.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          onChange={e => setFormChannelFee({ ...formChannelFee, type: e.value })}
        />

        {formChannelFee.type === TypeFeeConst.FLAT ? (
          <TextField
            type={'currency'}
            label={'Fee (Rp)'}
            block
            onChange={(e: any) => {
              setFormChannelFee({
                ...formChannelFee,
                fee: e.floatValue
              });
            }}
          />
        ) : (
          ''
        )}

        {formChannelFee.type === TypeFeeConst.PERCENT ? (
          <TextField
            type={'number'}
            label={'Fee (%)'}
            block
            onChange={(e: any) => {
              setFormChannelFee({
                ...formChannelFee,
                fee: parseInt(e.target.value)
              });
            }}
          />
        ) : (
          ''
        )}

        {/*<Datepicker*/}
        {/*  value={formChannelFee.effectiveDateStart}*/}
        {/*  label={'Effective End Start'}*/}
        {/*  block*/}
        {/*  onChange={e =>*/}
        {/*    setFormChannelFee({*/}
        {/*      ...formChannelFee,*/}
        {/*      effectiveDateStart: e*/}
        {/*    })*/}
        {/*  }*/}
        {/*/>*/}

        {/*<Datepicker*/}
        {/*  value={formChannelFee.effectiveDateEnd}*/}
        {/*  label={'Effective End Date'}*/}
        {/*  block*/}
        {/*  onChange={e =>*/}
        {/*    setFormChannelFee({*/}
        {/*      ...formChannelFee,*/}
        {/*      effectiveDateEnd: e*/}
        {/*    })*/}
        {/*  }*/}
        {/*/>*/}
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleAddPaymentFeeDetailModal.setState(false)} />
        <Button block label="Save" onClick={() => handleStoreChannelFee()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddPaymentFee;
