import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import { PaginationModel } from '@/domain/model/pagination_model';
import { PaymentChannelModel } from '@/domain/model/payment/payment_channel_model.ts';
import { PaymentChannelRepository } from '@/domain/repository/payment_channel_repository.ts';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { PaymentChannelFeeRepository } from '@/domain/repository/payment_channel_fee_repository.ts';
import { PaymentChannelFeeModel } from '@/domain/model/payment/payment_channel_fee_model.ts';
import { formatDateTimeParam } from '@/infrastructure/helper/formatDate.ts';
import UploadMedia from '@/infrastructure/helper/uploadMedia.tsx';
import MEDIA_PATH from '@/domain/constant/media/media_path.ts';
import { PaymentMethodRepository } from '@/domain/repository/payment_method_repository.ts';
import { PaymentMethodModel } from '@/domain/model/payment/payment_method_model.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';

interface OpenChannelContextContextProps {
  children: ReactNode;
}

interface FormManualPayment {
  code?: string;
  description?: string;
  media_uid?: string;
  name?: string;
  payment_method_uid?: string;
  status?: string;
}

interface OpenChannelValue {
  payment_channel_uid?: string;
  page?: number;
  limit?: number;
  name?: string;
}

interface FormPaymentChannelInterface {
  UID?: string;
  name?: string;
  code?: string;
  paymentMethodUID?: string;
  description?: string;
  media?: any;
  mediaURL?: string;
  mediaUID?: string;
  status?: string;
  fee?: number;
  vendor_name?: string;
  channel_name?: string;
  method_name?: string;
  system_note?: string;
}

interface FormPaymentChannelFeeInterface {
  paymentChannelUID?: string;
  additionalFlatFee?: number;
  additionalPercentFee?: number;
  effectiveDateEnd?: string;
  effectiveDateStart?: string;
  fee?: number;
  type?: string;
}

interface OpenChannelContextValue {
  paymentMethod?: PaymentMethodModel;
  formManualPayment: FormManualPayment;
  setFormManualPayment: Dispatch<SetStateAction<FormManualPayment>>;
  formChannelFee: FormPaymentChannelFeeInterface;
  paymentChannel: FormPaymentChannelInterface;
  setFormChannelFee: Dispatch<SetStateAction<FormPaymentChannelFeeInterface>>;
  setPaymentChannel: Dispatch<SetStateAction<FormPaymentChannelInterface>>;
  paymentChannelFees: PaymentChannelFeeModel[];
  paymentChannelFeePagination?: PaginationModel;
  paymentChannels: PaymentChannelModel[];
  paymentChannelsOnFee: PaymentChannelModel[];
  paymentChannelPagination?: PaginationModel;
  handleUpdateOpenChannelModal: ModalObject;
  handleAddPaymentManualModal: ModalObject;
  handleAddPaymentFeeModal: ModalObject;
  handleAddPaymentFeeDetailModal: ModalObject;
  handleShowPaymentChannel: (_data: FormPaymentChannelInterface) => void;
  handleFetchPaymentChannel: (_data: OpenChannelValue) => void;
  handlePaymentChannelFee: (_data: OpenChannelValue) => void;
  handleAddChannelFee: () => void;
  handleStoreChannelFee: () => void;
  handleUpdatePaymentChannel: () => void;
  handleShowPaymentMethodManual: (_data: FormPaymentChannelInterface) => void;
  handleAddManualPayment: () => void;
  handleDeleteManualChannel: (_data: PaymentChannelModel) => void;
  // fetchPaymentChannel:(params) => void;
}

const OpenChannelContext = createContext<OpenChannelContextValue | null>(null);

const paymentChannelRepository = new PaymentChannelRepository();
const paymentChannelFee = new PaymentChannelFeeRepository();
const paymentMethodRepository = new PaymentMethodRepository();

const OpenChannelProvider: React.FC<OpenChannelContextContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodModel>();

  const [paymentChannels, setPaymentChannels] = useState<PaymentChannelModel[]>([]);
  const [paymentChannelPagination, setPaymentChannelPagination] = useState<PaginationModel>();

  const [formManualPayment, setFormManualPayment] = useState<FormManualPayment>({});
  const [paymentChannel, setPaymentChannel] = useState<FormPaymentChannelInterface>({});
  const [formChannelFee, setFormChannelFee] = useState<FormPaymentChannelFeeInterface>({});

  const [paymentChannelFees, setPaymentChannelFees] = useState<PaymentChannelFeeModel[]>([]);
  const [paymentChannelFeePagination, setPaymentChannelFeePagination] = useState<PaginationModel>();

  //all data payment channel
  const [paymentChannelsOnFee, setPaymentChannelsOnFee] = useState<PaymentChannelModel[]>([]);

  const handleUpdateOpenChannelModal = useVisibleComponent(false);
  const handleAddPaymentFeeModal = useVisibleComponent(false);
  const handleAddPaymentFeeDetailModal = useVisibleComponent(false);
  const handleAddPaymentManualModal = useVisibleComponent(false);

  //HANDLE SHOW PAYMENT METHOD MANUAL
  const handleShowPaymentMethodManual = (_data: FormPaymentChannelInterface) => {
    const x = alertToast.loadingAlert('Showing method manual');
    const params = {
      code: _data.code
    };

    paymentMethodRepository
      .showPaymentMethod(params)
      .then((result: any) => {
        setPaymentMethod(result);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE CREATE MANUAL PAYMENT
  const handleAddManualPayment = () => {
    const x = alertToast.loadingAlert('');
    const params = {
      code: formManualPayment.code,
      description: formManualPayment.description,
      flags: ['MANUAL'],
      media_uid: null,
      name: formManualPayment.name,
      payment_method_uid: formManualPayment.payment_method_uid,
      status: formManualPayment.status
    };

    paymentChannelRepository
      .storePaymentChannel(params)
      .then(() => {
        handleFetchPaymentChannel({ page: 1 });
        handleAddPaymentManualModal.setState(false);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE DELETE MANUAL PAYMENT
  const handleDeleteManualChannel = (_data: PaymentChannelModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        paymentChannelRepository
          .deletePaymentChannel({ uid: _data.UID })
          .then((result: any) => {
            handleFetchPaymentChannel({ page: 1 });
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE SHOW PAYMENT CHANNEL MODAL
  const handleShowPaymentChannel = (_data: FormPaymentChannelInterface) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };
    paymentChannelRepository
      .showPaymentChannel(params)
      .then((res: any) => {
        const result: PaymentChannelModel = res;

        setPaymentChannel({
          UID: result.UID,
          name: result.name,
          status: result.status,
          paymentMethodUID: result.paymentMethodUID,
          code: result.code,
          description: result.description,
          mediaUID: result.mediaUID,
          mediaURL: result.getMediaUrl,
          fee: result.paymentChannelFee.fee,
          vendor_name: result.paymentMethod.vendor.name,
          channel_name: result.name,
          method_name: result.paymentMethod.name,
          system_note: result.systemNote
        });

        setFormChannelFee({
          fee: result.paymentChannelFee.fee,
          effectiveDateStart: result.paymentChannelFee.effectiveDateStart,
          effectiveDateEnd: result.paymentChannelFee.effectiveDateEnd,
          type: result.paymentChannelFee.type.toLowerCase()
        });
        alertToast.updateLoading(x);
        handleUpdateOpenChannelModal.setState(true);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SUBMIT UPDATE PAYMENT CHANNEL
  const handleUpdatePaymentChannel = async () => {
    const x = alertToast.loadingAlert('');

    let mediaUUID = '';
    if (paymentChannel.media) {
      mediaUUID = await UploadMedia(paymentChannel.media, MEDIA_PATH.ICON_PAYMENT_CHANNEL);
    } else {
      mediaUUID = '';
    }

    const params = {
      uid: paymentChannel.UID,
      code: paymentChannel.code,
      description: paymentChannel.description,
      media_uid: mediaUUID,
      name: paymentChannel.name,
      payment_method_uid: paymentChannel.paymentMethodUID,
      // priority: 1,
      status: paymentChannel.status,
      system_note: paymentChannel.system_note
    };

    paymentChannelRepository
      .updatePaymentChannel(params)
      .then(result => {
        handleFetchPaymentChannel({ page: 1, limit: 10 });
        alertToast.updateLoading(x);
        alertToast.successAlert('Success');
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //FETCH USER / MEMBER LIST
  const handleFetchPaymentChannel = (_data: OpenChannelValue) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      name: _data.name ?? null,
      with_manual: true
    };

    paymentChannelRepository
      .fetchPaymentChannel(params)
      .then((result: any) => {
        setPaymentChannels(result.data);
        setPaymentChannelPagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH PAYMENT CHANNEL FEE
  const handlePaymentChannelFee = async (_data: OpenChannelValue) => {
    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      payment_channel_uid: _data.payment_channel_uid
    };

    return paymentChannelFee
      .fetchPaymentChannelFee(params)
      .then((result: any) => {
        setPaymentChannelFees(result.data);
        setPaymentChannelFeePagination(result.pagination);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const handleAddChannelFee = () => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: 1,
      limit: -1
    };

    return paymentChannelRepository
      .fetchPaymentChannel(params)
      .then((result: any) => {
        setPaymentChannelsOnFee(result.data);
        handleAddPaymentFeeModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const handleStoreChannelFee = () => {
    const x = alertToast.loadingAlert('');
    const params = {
      additional_flat_fee: 0,
      additional_percent_fee: 0,
      effective_date_start: formatDateTimeParam(new Date()),
      // effective_date_end: formChannelFee.effectiveDateEnd,
      fee: formChannelFee.fee,
      payment_channel_uid: formChannelFee.paymentChannelUID,
      type: formChannelFee.type
    };

    paymentChannelFee
      .storePaymentChannelFee(params)
      .then(result => {
        handleAddPaymentFeeDetailModal.setState(false);
        alertToast.successAlert('Success');
        handlePaymentChannelFee({ page: 1, payment_channel_uid: formChannelFee.paymentChannelUID });
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: OpenChannelContextValue = {
    paymentMethod,
    handleAddPaymentManualModal,
    formManualPayment,
    setFormManualPayment,
    formChannelFee,
    setFormChannelFee,
    paymentChannelsOnFee,
    handleAddChannelFee,
    handleAddPaymentFeeModal,
    handleAddPaymentFeeDetailModal,
    paymentChannel,
    setPaymentChannel,
    paymentChannelFees,
    paymentChannelFeePagination,
    handlePaymentChannelFee,
    paymentChannelPagination,
    paymentChannels,
    handleFetchPaymentChannel,
    handleUpdateOpenChannelModal,
    handleShowPaymentChannel,
    handleStoreChannelFee,
    handleUpdatePaymentChannel,
    handleShowPaymentMethodManual,
    handleAddManualPayment,
    handleDeleteManualChannel
  };

  return <OpenChannelContext.Provider value={contextValue}>{children}</OpenChannelContext.Provider>;
};

const useOpenChannelContext = (): OpenChannelContextValue => {
  const context = useContext(OpenChannelContext);
  if (!context) {
    throw new Error('useOpenChannelContext must be used within a OpenChannelProvider');
  }
  return context;
};

export { OpenChannelProvider, useOpenChannelContext };
