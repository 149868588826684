import { useEffect } from 'react';
import CheckDataPage from '@/delivery/ui/admin/settings/check_data';

const ViewCheckData = () => {
  useEffect(() => {
    document.title = 'Notify App';
  }, []);

  return (
    <div>
      <CheckDataPage />
    </div>
  );
};

export default ViewCheckData;
