import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import CorporateInterface, { CorporateValue } from '@/domain/repository/interface/corporate_interface.ts';
import { Corporate } from '@/domain/constant/api_list';
import { CorporateModel } from '@/domain/model/corporate_model.ts';

interface roleValue {
  data: any;
  pagination: any;
}

export class CorporateRepository implements CorporateInterface {
  api = new ApiClient();

  async fetchCorporate(data: CorporateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Corporate.FETCH, {
            params: data
          })
          .then(result => {
            const data = CorporateModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role: roleValue = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showCorporate(data: CorporateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Corporate.SHOW, {
            params: data
          })
          .then(result => {
            const data = CorporateModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeCorporate(data: CorporateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Corporate.STORE, data)
          .then(result => {
            const data = CorporateModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkCorporate(data: CorporateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Corporate.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateCorporate(data: CorporateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(Corporate.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteCorporate(data: CorporateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(Corporate.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
