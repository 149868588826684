import { BaseModel } from '@/domain/model/base_model.ts';

export class VendingMachineModel extends BaseModel {
  vendorUID: string;
  code: string;
  name: string;
  status: string;
  fullAddress: string;
  nationalID: number;
  provinceID: number;
  cityID: number;
  districtID: number;
  villageID: number;
  zipCode: string;
  latitude: number;
  longitude: number;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    vendorUID: string,
    code: string,
    name: string,
    status: string,
    fullAddress: string,
    nationalID: number,
    provinceID: number,
    cityID: number,
    districtID: number,
    villageID: number,
    zipCode: string,
    latitude: number,
    longitude: number
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.vendorUID = vendorUID;
    this.code = code;
    this.name = name;
    this.status = status;
    this.fullAddress = fullAddress;
    this.nationalID = nationalID;
    this.provinceID = provinceID;
    this.cityID = cityID;
    this.districtID = districtID;
    this.villageID = villageID;
    this.zipCode = zipCode;
    this.latitude = latitude;
    this.longitude = longitude;
  }

  public static fromJson(data: any): VendingMachineModel | any {
    try {
      return new VendingMachineModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.vendor_uid,
        data.code,
        data.name,
        data.status,
        data.full_address,
        data.national_id,
        data.province_id,
        data.city_id,
        data.district_id,
        data.village_id,
        data.zip_code,
        data.latitude,
        data.lognitude
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): VendingMachineModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(VendingMachineModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
