import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Chips from '@/delivery/components/atoms/chips/chips';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useBusinessVerifyContext } from '@/delivery/ui/admin/data_member/business_verify/context/business_verify_context.tsx';
import { useEffect } from 'react';
import { CorporateModel } from '@/domain/model/corporate_model.ts';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import FilterBusinessVerify from '@/delivery/ui/admin/data_member/business_verify/filter_business_verify.tsx';

const BusinessVerify = () => {
  const navigate = useNavigate();
  const { handleFetchBusinessVerify, businessVerifies, businessVerifyPagination } = useBusinessVerifyContext();

  useEffect(() => {
    handleFetchBusinessVerify({});
  }, []);

  return (
    <div>
      <div>
        <div className={'flex justify-end mb-[12px]'}>
          <FilterBusinessVerify />
        </div>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Member</Th>
              <Th>Penangung Jawab</Th>
              <Th>Bank Account</Th>
              <Th>Balance (gr)</Th>
              <Th>Request Date</Th>
              <Th>Status</Th>
              <Th>Updated By </Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {businessVerifies.map((item: CorporateModel, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {item.name}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.emailCorporate}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.phoneCorporate}
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {item.picName}
                  </Typography>
                  {/*<Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                  {/*  Direktur*/}
                  {/*</Typography>*/}
                  {/*<Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                  {/*  {item.ph}*/}
                  {/*</Typography>*/}
                </Td>
                <Td>
                  <div className=" text-[12px] font-[400] text-text-tertiary-invert whitespace-nowrap">
                    <Typography fontWeight={600} size={12} type="primary-invert">
                      Bank Central Asia (BCA)
                    </Typography>
                    <p>
                      Acc Name:{' '}
                      <Typography tag="span" fontWeight={600} size={12} type="secondary-invert">
                        Gillette
                      </Typography>
                    </p>
                    <p>
                      Acc No:{' '}
                      <Typography tag="span" fontWeight={600} size={12} type="secondary-invert">
                        4022334455
                      </Typography>
                    </p>
                  </div>
                </Td>
                <Td>
                  <div className="text-right text-[12px] font-[400] text-text-tertiary-invert whitespace-nowrap">
                    <Typography fontWeight={600} size={12} type="primary-invert">
                      24,00
                    </Typography>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      24.1755555
                    </Typography>
                  </div>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getCreatedAt}
                  </Typography>
                </Td>
                <Td>
                  <div className={'whitespace-nowrap'}>
                    <Chips type={item?.getStatusColor} label={item?.status} className="leading-4" />
                    <Typography className="leading-4" fontWeight={400} size={10} type="tertiary-invert">
                      {/*2023-05-20 20:02:46*/}
                    </Typography>
                  </div>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    System
                  </Typography>
                </Td>
                <Td>
                  <div className="flex gap-[4px]">
                    <Button
                      iconButton
                      iconType={'view'}
                      onClick={() => navigate(Routing.DATA_MEMBER.BUSINESS_VERIFY_DETAIL + item.UID)}
                    />
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            handlePagination={page => {
              handleFetchBusinessVerify({
                page: page
              });
            }}
            page={businessVerifyPagination?.page || 1}
            totalPages={businessVerifyPagination?.totalPages || 1}
          />
        </Tfooter>
      </div>
    </div>
  );
};

export default BusinessVerify;
