import Button from '@/delivery/components/atoms/button/button';
import Pagination from '@/delivery/components/atoms/pagination/pagination';
import Typography from '@/delivery/components/atoms/typography/typography';
import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import { useWebPageContext } from '@/delivery/ui/admin/web_admin/web_pages/context/web_page_context.tsx';
import { WebPageModel } from '@/domain/model/web_page_model.ts';
import { useEffect } from 'react';
import { Routing } from '@/domain/constant/router_path.ts';
import { useNavigate } from 'react-router-dom';

const WebPages = () => {
  const navigate = useNavigate();
  const { webPages, handleFetchWebPage, webPagePagination, handleDeleteWebPage } = useWebPageContext();

  useEffect(() => {
    handleFetchWebPage({});
  }, []);

  return (
    <div>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Title</Th>
            <Th>Slug</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {webPages.map((item: WebPageModel, index) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.title}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {item.slug}
                </Typography>
              </Td>

              <Td>
                <div className="flex gap-[4px]">
                  <Button iconButton iconType={'delete'} onClick={() => handleDeleteWebPage(item)} />
                  <Button
                    iconButton
                    iconType={'edit'}
                    onClick={() => navigate(Routing.WEB_ADMIN.WEB_PAGES_UPDATE + item.UID)}
                  />
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          handlePagination={page => {
            handleFetchWebPage({
              page: page
            });
          }}
          page={webPagePagination?.page || 1}
          totalPages={webPagePagination?.totalPages || 1}
        />
      </Tfooter>
    </div>
  );
};

export default WebPages;
