import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useAccountListContext } from '@/delivery/ui/admin/data_dca/account_list/context/account_list_context.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { STATUS_LIST } from '@/domain/constant/status.ts';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import { ReactNode, useEffect, useState } from 'react';

interface FilterInterface {
  children?: ReactNode;
}

const FilterAccountList = (props: FilterInterface) => {
  const { handleFetchAccountList, formFilterAccountList, setFormFilterAccountList } = useAccountListContext();

  const [isResetting, setIsResetting] = useState(false);

  const handleReset = () => {
    setFormFilterAccountList({ search: '', type: '', status: '', phone: '', email: '' });
    setIsResetting(true);
  };

  useEffect(() => {
    if (isResetting) {
      handleFetchAccountList({ page: 1, limit: 10 });
      setIsResetting(false);
    }
  }, [setFormFilterAccountList, isResetting]);

  return (
    <div className="flex gap-[12px]">
      <Button type={'secondary'} prefixIcon={Icon.reset} label={'Reset Filter'} onClick={handleReset} />
      <ButtonFilter
        onClickApply={() => handleFetchAccountList({})}
        size={'large'}
        titleFilter={'Filter Business Verify'}
        label={'More Filter'}
      >
        <div className={'flex flex-col gap-[20px]'}>
          <TextField
            value={formFilterAccountList.search}
            search
            placeholder={'Search...'}
            block
            onChange={e =>
              setFormFilterAccountList({
                ...formFilterAccountList,
                search: e.target.value
              })
            }
          />
          <div className={'grid grid-cols-2 gap-[20px]'}>
            <TextField
              value={formFilterAccountList.email}
              type={'email'}
              block
              label={'Email'}
              onChange={e =>
                setFormFilterAccountList({
                  ...formFilterAccountList,
                  email: e.target.value
                })
              }
            />
            <TextField
              value={formFilterAccountList.phone}
              block
              type={'number'}
              label={'Phone'}
              onChange={e =>
                setFormFilterAccountList({
                  ...formFilterAccountList,
                  phone: e.target.value
                })
              }
            />
            <Select2
              value={formFilterAccountList.status}
              options={STATUS_LIST.map((item: any) => {
                return {
                  label: item.name,
                  value: item.UUID
                };
              })}
              block
              label={'Status'}
              onChange={e => setFormFilterAccountList({ ...formFilterAccountList, status: e.value })}
            />
            <TextField
              value={formFilterAccountList.type}
              block
              label={'Type'}
              onChange={e =>
                setFormFilterAccountList({
                  ...formFilterAccountList,
                  type: e.target.value
                })
              }
            />
          </div>
        </div>
      </ButtonFilter>
      {props.children}
    </div>
  );
};

export default FilterAccountList;
