import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
// import Select from '@/delivery/components/atoms/select/select.tsx';

import { STATUS_LIST } from '@/domain/constant/status.ts';
import { useOpenChannelContext } from '@/delivery/ui/admin/settings/open_channel/context/open_channel_context.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import TypeFee, { TypeFeeConst } from '@/domain/constant/type_fee.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';
import Switch from '@/delivery/components/atoms/switch/switch.tsx';

const DialogUpdateOpenChannel = (props: ModalDialogProps) => {
  const {
    setPaymentChannel,
    paymentChannel,
    handleUpdateOpenChannelModal,
    handleUpdatePaymentChannel,
    formChannelFee,
    setFormChannelFee
  } = useOpenChannelContext();

  return (
    <ModalDialog size="large" title="Update Channel Pembayaran" modal={props.modal}>
      <div className={' flex gap-[20px]'}>
        <div className="flex flex-col gap-[20px] w-full">
          <Typography fontWeight={600} size={14}>
            Payment Channel
          </Typography>
          <Line />
          <TextField
            disabled={true}
            value={paymentChannel.name}
            label="Name Penyedia"
            placeholder="Nama Penyedia"
            block
          />
          <TextField disabled={true} value={paymentChannel.code} label="Channel" placeholder="Channel" block />
          <TextField
            disabled={true}
            value={paymentChannel.paymentMethodUID}
            label="Method"
            placeholder="Method"
            block
          />
          <Textarea
            label="Description"
            placeholder="Description"
            block
            value={paymentChannel.description}
            onChange={e => {
              setPaymentChannel({
                ...paymentChannel,
                description: e.target.value
              });
            }}
          />
          {/*<TextField*/}
          {/*  label="Payment fee"*/}
          {/*  placeholder="Method"*/}
          {/*  block*/}
          {/*  type={"number"}*/}
          {/*/>*/}

          <Select2
            value={paymentChannel.status}
            label={'Status'}
            options={STATUS_LIST.map(item => {
              return {
                label: item.name,
                value: item.UUID
              };
            })}
            onChange={(e: any) => {
              setPaymentChannel({
                ...paymentChannel,
                status: e.value
              });
            }}
          />
        </div>

        <div className="flex flex-col gap-[20px] w-full">
          <div className={'flex justify-between'}>
            <Typography fontWeight={600} size={14}>
              Payment Channel Fee
            </Typography>
            <Switch label={'add fee'} />
          </div>

          <Line />
          <Select2
            label={'Type'}
            value={formChannelFee.type}
            options={TypeFee.map(item => {
              return {
                label: item.name,
                value: item.UUID
              };
            })}
            onChange={e => setFormChannelFee({ ...formChannelFee, type: e.value })}
          />
          {formChannelFee.type === TypeFeeConst.FLAT ? (
            <TextField
              value={formChannelFee.fee}
              type={'currency'}
              label={'Fee (Rp)'}
              block
              onChange={(e: any) => {
                setFormChannelFee({
                  ...formChannelFee,
                  fee: e.floatValue
                });
              }}
            />
          ) : (
            ''
          )}

          {formChannelFee.type === TypeFeeConst.PERCENT ? (
            <TextField
              value={formChannelFee.fee}
              type={'number'}
              label={'Fee (%)'}
              block
              onChange={(e: any) => {
                setFormChannelFee({
                  ...formChannelFee,
                  fee: parseInt(e.target.value)
                });
              }}
            />
          ) : (
            ''
          )}

          <Datepicker
            value={formChannelFee.effectiveDateStart}
            label={'Effective End Start'}
            block
            onChange={e =>
              setFormChannelFee({
                ...formChannelFee,
                effectiveDateStart: e
              })
            }
          />

          <Datepicker
            value={formChannelFee.effectiveDateEnd}
            label={'Effective End Date'}
            block
            onChange={e =>
              setFormChannelFee({
                ...formChannelFee,
                effectiveDateEnd: e
              })
            }
          />
        </div>
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleUpdateOpenChannelModal.setState(false)} />
        <Button label="Save" onClick={() => handleUpdatePaymentChannel()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogUpdateOpenChannel;
