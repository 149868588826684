import { BaseModel } from '@/domain/model/base_model.ts';

export class UserTokenAffiliateModel extends BaseModel {
  userUID: string;
  token: string;
  userAffiliate: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    userUID: string,
    token: string,
    userAffiliate: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.userUID = userUID;
    this.token = token;
    this.userAffiliate = userAffiliate;
  }

  public static fromJson(data: any): any | UserTokenAffiliateModel {
    try {
      return new UserTokenAffiliateModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.user_uid,
        data.token,
        data.user_affiliate
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): UserTokenAffiliateModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(UserTokenAffiliateModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
