import { BaseModel } from '@/domain/model/base_model.ts';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';

export class QueryTransactionVendorModel extends BaseModel {
  referenceID: string;
  vendorRefID: string;
  createdDate: string;
  currency: string;
  expiredDate: string;
  isPaid: boolean;
  notes: string;
  paidDate: string;
  paymentChannel: string;
  paymentCode: string;
  paymentMethod: string;
  paymentName: string;
  paymentUrl: string;
  statusInVendor: string;
  subTotal: number;
  total: number;
  vendorFee: number;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    referenceID: string,
    vendorRefID: string,
    createdDate: string,
    currency: string,
    expiredDate: string,
    isPaid: boolean,
    notes: string,
    paidDate: string,
    paymentChannel: string,
    paymentCode: string,
    paymentMethod: string,
    paymentName: string,
    paymentUrl: string,
    statusInVendor: string,
    subTotal: number,
    total: number,
    vendorFee: number
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.referenceID = referenceID;
    this.vendorRefID = vendorRefID;
    this.createdDate = createdDate;
    this.currency = currency;
    this.expiredDate = expiredDate;
    this.isPaid = isPaid;
    this.notes = notes;
    this.paidDate = paidDate;
    this.paymentChannel = paymentChannel;
    this.paymentCode = paymentCode;
    this.paymentMethod = paymentMethod;
    this.paymentName = paymentName;
    this.paymentUrl = paymentUrl;
    this.statusInVendor = statusInVendor;
    this.subTotal = subTotal;
    this.total = total;
    this.vendorFee = vendorFee;
  }

  get getTotalRupiah(): string {
    if (this.total) {
      return formatRupiah(this.total);
    }
    return '-';
  }

  get getVendorFeeRupiah(): string {
    if (this.vendorFee) {
      return formatRupiah(this.vendorFee);
    }
    return '-';
  }

  public static fromJson(data: any): QueryTransactionVendorModel | any {
    try {
      return new QueryTransactionVendorModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.reference_id,
        data.vendor_ref_id,
        data.created_date,
        data.currency,
        data.expired_date,
        data.is_paid,
        data.notes,
        data.paid_date,
        data.payment_channel,
        data.payment_code,
        data.payment_method,
        data.payment_name,
        data.payment_url,
        data.status_in_vendor,
        data.sub_total,
        data.total,
        data.vendor_fee
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): QueryTransactionVendorModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(QueryTransactionVendorModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
