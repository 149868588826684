import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Theader, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Chips from '@/delivery/components/atoms/chips/chips';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useAccountRequestContext } from '@/delivery/ui/admin/data_dca/account_request/context/account_request_context.tsx';
import { useEffect } from 'react';
import { DCARequestModel } from '@/domain/model/dca_request_model.ts';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { truncateDecimal } from '@/infrastructure/helper/truncateDecimalNumber.ts';
import DialogUpdateAccountRequest from '@/delivery/ui/admin/data_dca/account_request/dialog/dialog_update_account_request.tsx';
import FilterAccountRequest from '@/delivery/ui/admin/data_dca/account_request/filter_account_request.tsx';
import DialogCloseAccountRequest from '@/delivery/ui/admin/data_dca/account_request/dialog/dialog_close_account_request.tsx';
import { DCA_STATUS } from '@/domain/constant/dca/dca_status.ts';

const AccountRequest = () => {
  const {
    handleUpdateCloseDCAModal,
    handleShowAccountRequestCloseDCA,
    handleUpdateAccountRequestModal,
    handleShowAccountRequest,
    accountRequestPagination,
    handleFetchAccountRequest,
    accountRequests
  } = useAccountRequestContext();

  useEffect(() => {
    handleFetchAccountRequest({});
  }, []);

  return (
    <div>
      <div className="mt-[32px]">
        <Theader>
          <div></div>
          <FilterAccountRequest />
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Member</Th>
              {/*<Th>Bank Account</Th>*/}
              <Th>Available Balance</Th>
              <Th>Fiat</Th>
              <Th>Gram</Th>
              <Th>Reg Date</Th>
              <Th>Fee</Th>
              <Th>Sell Rate</Th>
              <Th>Lock DCA (20%)</Th>
              <Th>Status</Th>
              <Th>Updated By </Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {accountRequests.map((item: DCARequestModel, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {item.user.name}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.user.email}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.user.phone}
                  </Typography>
                </Td>
                {/*<Td>*/}
                {/*  <div className=" text-nowrap">*/}
                {/*    <Typography fontWeight={600} size={12} type="primary-invert">*/}
                {/*      {item.bankName}*/}
                {/*    </Typography>*/}
                {/*    <p>*/}
                {/*      <Typography tag={'span'} fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*        Acc. Name:{' '}*/}
                {/*      </Typography>*/}
                {/*      <Typography tag={'span'} fontWeight={400} size={12} type="primary-invert">*/}
                {/*        {item.accountName}*/}
                {/*      </Typography>*/}
                {/*    </p>*/}
                {/*    <p>*/}
                {/*      <Typography tag={'span'} fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*        Acc. No:{' '}*/}
                {/*      </Typography>*/}
                {/*      <Typography tag={'span'} fontWeight={400} size={12} type="primary-invert">*/}
                {/*        {item.accountNumber}*/}
                {/*      </Typography>*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*</Td>*/}
                <Td>
                  <div className="text-right">
                    <Typography fontWeight={600} size={12} type="primary-invert">
                      {truncateDecimal(item.user.balanceGram)}
                    </Typography>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {item.user.balanceGram}
                    </Typography>
                  </div>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getFiatRupiah}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.gram}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getRequestDate}
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getFeeRupiah}
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getRateRupiah}
                  </Typography>
                </Td>
                <Td>
                  <div className="text-right">
                    <Typography fontWeight={600} size={12} type="primary-invert">
                      {truncateDecimal(item.getBalanceGram20Percent)}
                    </Typography>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {item.getBalanceGram20Percent}
                    </Typography>
                  </div>
                </Td>
                <Td>
                  <Chips type={item.getStatusColor} label={item.status} className="leading-4" />
                  <Typography className="leading-4" fontWeight={400} size={10} type="tertiary-invert">
                    {item.getApprovedDate}
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.updatedBy}
                  </Typography>
                </Td>
                <Td>
                  <div className="flex gap-[4px]">
                    <Button iconButton iconType={'view'} onClick={() => handleShowAccountRequest(item)} />
                    <Button
                      disabled={item.status === DCA_STATUS.APPROVED ? false : true}
                      iconButton
                      iconType={'banned'}
                      onClick={() => handleShowAccountRequestCloseDCA(item)}
                    />
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            handlePagination={page => {
              handleFetchAccountRequest({
                page: page
              });
            }}
            page={accountRequestPagination?.page || 1}
            totalPages={accountRequestPagination?.totalPages || 1}
          />
        </Tfooter>
      </div>

      {handleUpdateAccountRequestModal.state && <DialogUpdateAccountRequest modal={handleUpdateAccountRequestModal} />}

      {handleUpdateCloseDCAModal.state && <DialogCloseAccountRequest modal={handleUpdateCloseDCAModal} />}
    </div>
  );
};

export default AccountRequest;
