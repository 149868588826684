import DChart from '@/delivery/components/atoms/chart/chart';
import { useGoldPriceContext } from '@/delivery/ui/admin/gold_price/context/gold_price_context.tsx';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';

const ChartGoldPrice = () => {
  const { goldRatesCharts } = useGoldPriceContext();

  const state: any = {
    series: [
      {
        name: 'Buy Rate',
        data: goldRatesCharts.map(item => item.buy)
      },
      {
        name: 'Sell Rate',
        data: goldRatesCharts.map(item => item.sell)
      }
    ],
    options: {
      legend: {
        show: true,
        position: 'top'
      },
      colors: ['#508A48', '#85C27C'],
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        with: 1,
        curve: 'smooth'
      },
      xaxis: {
        // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        categories: goldRatesCharts.map(item => item.getCreatedAtDate)
      },

      yaxis: {
        title: {
          text: 'Gold Rate'
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          formatter: function (val: any) {
            return formatRupiah(val);
          }
        }
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        }
      }
    }
  };

  return (
    <div>
      <DChart options={state.options} series={state.series} type="area" height={350} />
    </div>
  );
};

export default ChartGoldPrice;
