import Button from '@/delivery/components/atoms/button/button';
import Chips from '@/delivery/components/atoms/chips/chips';
import { Image } from '@/delivery/components/atoms/image/image';
import Pagination from '@/delivery/components/atoms/pagination/pagination';
import Typography from '@/delivery/components/atoms/typography/typography';
import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import { useNotifyAppContext } from '@/delivery/ui/admin/web_admin/notify_app/context/notify_app_context.tsx';
import { NotificationModel } from '@/domain/model/notification_model.ts';
import STATUS_CONSTANT from '@/domain/constant/status_constanat.ts';
import { useEffect } from 'react';
import DialogUpdateNotificationApp from '@/delivery/ui/admin/web_admin/notify_app/dialog/dialog_update_notification_app.tsx';
import DialogListUser from '@/delivery/ui/admin/web_admin/notify_app/dialog/dialog_list_user.tsx';

const NotifyApp = () => {
  const {
    handleDeleteNotifyApp,
    notifyApps,
    handleFetchNotifyApp,
    notifyAppPagination,
    handleUpdateNotifyAppModal,
    handleShowNotifyApp,
    handleShowUserListModal
  } = useNotifyAppContext();

  useEffect(() => {
    handleFetchNotifyApp({});
  }, []);

  return (
    <div>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Channels</Th>
            <Th>Content</Th>
            <Th>URL</Th>
            <Th>Publish Date</Th>
            <Th>Status</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {notifyApps.map((item: NotificationModel, index) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.getType}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.getChannel}
                </Typography>
              </Td>
              <Td>
                {item.mediaUID ? <Image width={200} url={item.getMedia} /> : ''}

                <br />
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {item.message}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  <a href={item.cta} className={'underline text-bg-info italic'}>
                    {item.cta}
                  </a>
                </Typography>
              </Td>
              <Td>
                <div className={'whitespace-nowrap'}>
                  <Typography fontWeight={400} size={14} type="tertiary-invert">
                    {item.getStartDate}
                  </Typography>
                </div>
              </Td>
              <Td>
                <Chips
                  label={item.status}
                  type={item.status === STATUS_CONSTANT.active ? 'primary-light' : 'danger-light'}
                />
              </Td>
              <Td>
                <div className="flex gap-[4px]">
                  <Button iconButton iconType={'delete'} onClick={() => handleDeleteNotifyApp(item)} />
                  <Button iconButton iconType={'edit'} onClick={() => handleShowNotifyApp(item)} />
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          handlePagination={page => {
            handleFetchNotifyApp({
              page: page
            });
          }}
          page={notifyAppPagination?.page || 1}
          totalPages={notifyAppPagination?.totalPages || 1}
        />
      </Tfooter>

      {handleShowUserListModal.state && <DialogListUser modal={handleShowUserListModal} />}

      {handleUpdateNotifyAppModal.state && <DialogUpdateNotificationApp modal={handleUpdateNotifyAppModal} />}
    </div>
  );
};

export default NotifyApp;
