import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';

import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { CompanySettingRepository } from '@/domain/repository/company_setting_repository.ts';
import { getProfile } from '@/infrastructure/helper/auth.ts';
import { CompanySettingModel } from '@/domain/model/company_setting_model.ts';

interface CompanySettingContextProps {
  children: ReactNode;
}

interface CompanySettingValueInterface {
  uuid?: string;
  company_uuid?: string;
  max_sub_company?: number;
  login_expired_on_minute?: number;
  invoice_expired_on_minute?: string;
  currency?: string;
  time_zone?: string;
  default_sales_station_uuid?: string;
  default_receiving_station_uuid?: string;
  transit_station_uuid?: string;
  email_maximum_stock_alert?: string;
  email_minimal_stock_alert?: string;
  phone_minimal_stock_alert?: string;
  include_tax?: boolean;
  is_tax_collection?: boolean;
  tax_holder?: string;
  taxable_income_date?: string;
  tax_number?: string;
  tax_type?: string;
  tax_address_uuid?: string;
  midtrans_system_key?: string;
  xendit_system_key?: string;
  xendit_x_callback?: string;
  xendit_endpoint?: string;
  doku_secret?: string;
  doku_client_id?: string;
  doku_endpoint?: string;
  ipaymu_api_key?: string;
  ipaymu_secret_key?: string;
  expired_on_minute?: number;
  email_approve_sender?: string;
  phone_approve_sender?: string;
  email_otp?: string;
  phone_access_otp?: string;
  midtrans_sandbox?: boolean;
}

interface CompanySettingContextValue {
  formCompanySetting: CompanySettingValueInterface;
  setFormCompanySetting: Dispatch<SetStateAction<CompanySettingValueInterface>>;
  handleAddCompanySettingModal: ModalObject;
  handleShowCompanySetting: () => void;
  handleUpdateCompanySetting: () => void;
}

const CompanySettingContext = createContext<CompanySettingContextValue | null>(null);

const companySettingRepository = new CompanySettingRepository();
const CompanySettingProvider: React.FC<CompanySettingContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const profile = getProfile();

  const [formCompanySetting, setFormCompanySetting] = useState<CompanySettingValueInterface>({});

  const handleAddCompanySettingModal = useVisibleComponent(false);

  //SHOW COMPANY SETTING
  const handleShowCompanySetting = () => {
    const x = alertToast.loadingAlert('');
    const params = {
      company_uuid: profile?.company_uuid
    };

    companySettingRepository
      .showCompanySetting(params)
      .then((res: any) => {
        const result: CompanySettingModel = res;

        setFormCompanySetting({
          uuid: result.UUID,
          company_uuid: result.companyUUID,
          phone_access_otp: result.phoneAccessOtp,
          midtrans_system_key: result.midtransSystemKey,
          is_tax_collection: result.isTaxCollection,
          midtrans_sandbox: result.midtransSandbox,
          include_tax: result.includeTax,
          currency: result.currency,
          default_receiving_station_uuid: result.defaultReceivingStationUUID,
          default_sales_station_uuid: result.defaultReceivingStationUUID,
          doku_client_id: result.dokuClientID,
          doku_endpoint: result.dokuEndpoint,
          doku_secret: result.dokuSecret,
          email_approve_sender: result.emailApproveSender,
          email_maximum_stock_alert: result.emailMaximumStockAlert,
          email_minimal_stock_alert: result.emailMinimalStockAlert,
          email_otp: result.emailOtp,
          expired_on_minute: result.expiredOnMinute,
          invoice_expired_on_minute: result.invoiceExpiredOnMinute,
          ipaymu_api_key: result.ipaymuApiKey,
          ipaymu_secret_key: result.ipaymuSecretKey,
          login_expired_on_minute: result.loginExpiredOnMinute,
          max_sub_company: result.maxSubCompany,
          phone_approve_sender: result.phoneApproveSender,
          phone_minimal_stock_alert: result.emailMinimalStockAlert,
          tax_address_uuid: result.taxAddressUUID,
          tax_holder: result.taxHolder,
          tax_number: result.taxNumber,
          tax_type: result.taxType,
          taxable_income_date: result.taxableIncomeDate,
          time_zone: result.timeZone,
          transit_station_uuid: result.transitStationUUID,
          xendit_endpoint: result.xenditEndpoint,
          xendit_system_key: result.xenditSystemKey,
          xendit_x_callback: result.xenditXCallback
        });
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
        alertToast.updateLoading(x);
      });
  };

  //HANDLE UPDATE COMPANY SETTING
  const handleUpdateCompanySetting = () => {
    const x = alertToast.loadingAlert('');

    companySettingRepository
      .updateCompanySetting(formCompanySetting)
      .then(() => {
        alertToast.updateLoading(x);
        alertToast.successAlert('Success');
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const contextValue: CompanySettingContextValue = {
    handleAddCompanySettingModal,
    formCompanySetting,
    setFormCompanySetting,
    handleShowCompanySetting,
    handleUpdateCompanySetting
  };

  return <CompanySettingContext.Provider value={contextValue}>{children}</CompanySettingContext.Provider>;
};

const useCompanySettingContext = (): CompanySettingContextValue => {
  const context = useContext(CompanySettingContext);
  if (!context) {
    throw new Error('useCompanySettingContext must be used within a CompanySettingProvider');
  }
  return context;
};

export { CompanySettingProvider, useCompanySettingContext };
