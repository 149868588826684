import VendingMachinePage from '@/delivery/ui/admin/transaction/take_gold/vending_machine';
import { useEffect } from 'react';
import { VendingMachineProvider } from '@/delivery/ui/admin/transaction/take_gold/vending_machine/context/vending_machine_context.tsx';

const ViewVendingMachine = () => {
  useEffect(() => {
    document.title = 'Vending Machine';
  }, []);

  return (
    <div>
      <VendingMachineProvider>
        <VendingMachinePage />
      </VendingMachineProvider>
    </div>
  );
};

export default ViewVendingMachine;
