import { BaseModel } from '@/domain/model/base_model.ts';
import { OfflineCounterModel } from '@/domain/model/offline_counter_model.ts';
import { ChipType } from '@/domain/constant/component/chip_colors.ts';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import { formatDate } from '@/infrastructure/helper/formatDate.ts';

export class TrxPrintModel extends BaseModel {
  goldPieceUID: string;
  gram: number;
  offlineCounter: OfflineCounterModel;
  offlineCounterUID: string;
  quantity: number;
  status: string;
  transactionShippingUID: string;
  trxUID: string;
  type: string;
  vendingMachineUID: string;
  pickupDate: string;
  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    goldPieceUID: string,
    gram: number,
    offlineCounter: OfflineCounterModel,
    offlineCounterUID: string,
    quantity: number,
    status: string,
    transactionShippingUID: string,
    trxUID: string,
    type: string,
    vendingMachineUID: string,
    pickupDate: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.goldPieceUID = goldPieceUID;
    this.gram = gram;
    this.offlineCounter = offlineCounter;
    this.offlineCounterUID = offlineCounterUID;
    this.quantity = quantity;
    this.status = status;
    this.transactionShippingUID = transactionShippingUID;
    this.trxUID = trxUID;
    this.type = type;
    this.vendingMachineUID = vendingMachineUID;
    this.pickupDate = pickupDate;
  }

  get getStatusColor(): ChipType {
    if (this.status === STATUS_TRANSACTION.SUCCESS) {
      return 'primary-light';
    }

    if (this.status === STATUS_TRANSACTION.PENDING) {
      return 'warning-light';
    }

    if (this.status === STATUS_TRANSACTION.REJECT) {
      return 'danger-lite';
    }

    if (this.status === STATUS_TRANSACTION.FAILED) {
      return 'danger-light';
    }

    if (this.status === STATUS_TRANSACTION.REQUEST) {
      return 'info-light';
    }

    if (this.status === STATUS_TRANSACTION.AWAITING_PICKUP) {
      return 'warning-light';
    }
  }

  get getPickUpDate(): string {
    if (this.pickupDate) {
      return formatDate(this.pickupDate);
    }

    return '-';
  }

  public static fromJson(data: any): any | TrxPrintModel {
    try {
      return new TrxPrintModel(
        data.id,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.uid,
        data.flags,
        data.gold_piece_uid,
        data.gram,
        data.offline_counter ? OfflineCounterModel.fromJson(data.offline_counter) : null,
        data.offline_counter_uid,
        data.quantity,
        data.status,
        data.transaction_shipping_uid,
        data.trx_uid,
        data.type,
        data.vending_machine_uid,
        data.pickup_date
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): TrxPrintModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(TrxPrintModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
