import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';
import { FiUnlock } from 'react-icons/fi';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useBalanceContext } from '@/delivery/ui/admin/data_member/member_list/context/balance_context.tsx';

const TableLockGoldByDinaran = () => {
  const { handleShowBalanceLock, balance } = useBalanceContext();

  const { user } = useMemberListContext();

  return (
    <div>
      <Theader>
        <div>
          <Typography size={14} fontWeight={600}>
            Lock Emas by Dinaran
          </Typography>
        </div>
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Total Emas (gr)</Th>
            <Th>Total Rupiah (Rp)</Th>
            <Th>Create Date</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {balance?.balanceSystemLock.amount ? (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {balance?.balanceSystemLock.amount}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {user?.getFiatBalanceSystemLockRupiah}
                </Typography>
              </Td>

              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {balance?.balanceSystemLock.getCreatedAt}
                </Typography>
              </Td>

              <Td>
                <Button
                  iconButton
                  icon={<FiUnlock />}
                  onClick={() => handleShowBalanceLock(balance?.balanceSystemLock)}
                />
              </Td>
            </Tr>
          ) : (
            <Tr>
              <Td colSpan={5}>
                <Typography size={12} style={'italic'}>
                  belum memiliki lock emas by dinaran
                </Typography>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
};

export default TableLockGoldByDinaran;
