import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useBalanceContext } from '@/delivery/ui/admin/data_member/member_list/context/balance_context.tsx';
import { DreamGoldModel } from '@/domain/model/dream_gold_model.ts';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { FiUnlock } from 'react-icons/fi';
import ProgressBar from '@/delivery/components/atoms/progress_bar/progress_bar.tsx';
import DialogUpdateDreamGold from '@/delivery/ui/admin/data_member/member_list/detail/tabs/balance/dream_gold/dialog_update_dream_gold.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import { DREAM_GOLD_STATUS } from '@/domain/constant/dream_gold/dream_gold_status.ts';

const TableLockEmasImpian = () => {
  const { handleUpdateDreamGoldModal, handleShowDreamGold, balance } = useBalanceContext();

  return (
    <div>
      <Theader>
        <div>
          <Typography size={14} fontWeight={600}>
            Lock Emas Impian
          </Typography>
        </div>
      </Theader>

      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Total Emas (gr)</Th>
            {/*<Th>Total Rupiah (Rp)</Th>*/}
            <Th>Target Emas (gr)</Th>
            <Th>Progress</Th>
            <Th>Create Date</Th>
            <Th>Status</Th>
            {/*<Th>End Date</Th>*/}
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {balance?.dreamGold?.length !== 0 ? (
            <>
              {balance?.dreamGold.map((item: DreamGoldModel) => (
                <Tr>
                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {item.name}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {item.balanceDreamGold.amount}
                    </Typography>
                  </Td>
                  {/*<Td>*/}
                  {/*  <Typography fontWeight={400} size={12} type="primary-invert">*/}
                  {/*    DN123456*/}
                  {/*  </Typography>*/}
                  {/*</Td>*/}

                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {item.target}
                    </Typography>
                  </Td>
                  <Td>
                    {/*<Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                    {/*  {item.progress}*/}
                    {/*</Typography>*/}
                    <ProgressBar progress={item.progress} />
                  </Td>

                  <Td>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {item.getCreatedAt}
                    </Typography>
                  </Td>
                  <Td>
                    <Chips removeDot label={item.status} />
                  </Td>
                  {/*<Td>*/}
                  {/*  <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                  {/*    192*/}
                  {/*  </Typography>*/}
                  {/*</Td>*/}
                  <Td>
                    <Button
                      disabled={
                        item.status === DREAM_GOLD_STATUS.IN_PROGRESS || item.status === DREAM_GOLD_STATUS.LOCKED
                          ? true
                          : false
                      }
                      iconButton
                      icon={<FiUnlock />}
                      onClick={() => handleShowDreamGold(item)}
                    />
                  </Td>
                </Tr>
              ))}
            </>
          ) : (
            <Tr>
              <Td colSpan={7}>
                <Typography size={12} style={'italic'}>
                  belum memiliki emas impian
                </Typography>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {handleUpdateDreamGoldModal.state && <DialogUpdateDreamGold modal={handleUpdateDreamGoldModal} />}
    </div>
  );
};

export default TableLockEmasImpian;
