import AffiliateInterface, { AffiliateValue } from '@/domain/repository/interface/affiliate_interface';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { AffiliateModel } from '@/domain/model/affiliate_model';
import { Affiliate } from '@/domain/constant/api_list';
import { TransactionModel } from '@/domain/model/transaction_model.ts';

export class AffiliateRepository implements AffiliateInterface {
  api = new ApiClient();

  async fetchAffiliate(data: AffiliateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Affiliate.FETCH, {
            params: data
          })
          .then(result => {
            const data = AffiliateModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchAffiliateCount(data: AffiliateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Affiliate.FETCH_AFFILIATE_COUNT, {
            params: data
          })
          .then(result => {
            const data = AffiliateModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchAffiliateUser(data: AffiliateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Affiliate.FETCH_AFFILIATE_USER, {
            params: data
          })
          .then(result => {
            const data = AffiliateModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchAffiliateTransaction(data: AffiliateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Affiliate.FETCH_TRANSACTION, {
            params: data
          })
          .then(result => {
            const data = TransactionModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showAffiliate(data: AffiliateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Affiliate.SHOW, {
            params: data
          })
          .then(result => {
            const data = AffiliateModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeAffiliate(data: AffiliateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Affiliate.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkAffiliate(data: AffiliateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Affiliate.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateAffiliate(data: AffiliateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(Affiliate.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteAffiliate(data: AffiliateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(Affiliate.DELETE + data.uid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
