import Navbar from '@/delivery/components/organisms/navbar/navbar';
import Button from '@/delivery/components/atoms/button/button';
import WebPages from './web_pages';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';
import Icon from '@/domain/constant/icon.tsx';

const WebPagesPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Navbar label={'Web Pages'}>
        <div className="flex gap-[12px]">
          <Button
            type="primary"
            prefixIcon={Icon.plus}
            label="Web Pages"
            onClick={() => navigate(Routing.WEB_ADMIN.WEB_PAGES_ADD)}
          />
        </div>
      </Navbar>
      <WebPages />
    </div>
  );
};

export default WebPagesPage;
