import Navbar from '@/delivery/components/organisms/navbar/navbar';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import RiceRate from '@/delivery/ui/admin/master/rice_rate/rice_rate.tsx';
import { useRiceRateContext } from '@/delivery/ui/admin/master/rice_rate/context/rice_rate_context.tsx';
import DialogAddRiceRate from '@/delivery/ui/admin/master/rice_rate/dialog/dialog_add_rice_rate.tsx';

const RiceRatePage = () => {
  const { handleAddRiceRateModal } = useRiceRateContext();
  return (
    <div>
      <Navbar label={'Rice Rate'}>
        <Button prefixIcon={Icon.plus} label="Add Rice Rate" onClick={() => handleAddRiceRateModal.setState(true)} />
      </Navbar>
      <RiceRate />

      {handleAddRiceRateModal.state && <DialogAddRiceRate modal={handleAddRiceRateModal} />}
    </div>
  );
};

export default RiceRatePage;
