import { useEffect } from 'react';
import TransactionListPage from '@/delivery/ui/admin/transaction/transaction_list';
import { TransactionListProvider } from '@/delivery/ui/admin/transaction/transaction_list/context/transaction_list_context.tsx';

const ViewTransactionList = () => {
  useEffect(() => {
    document.title = 'Transaction List';
  }, []);

  return (
    <div>
      <TransactionListProvider>
        <TransactionListPage />
      </TransactionListProvider>
    </div>
  );
};

export default ViewTransactionList;
