import Button from '@/delivery/components/atoms/button/button';
import CorporateList from './corporate_list';
import Navbar from '@/delivery/components/organisms/navbar/navbar';
import DialogCreateCorporate from '@/delivery/ui/admin/data_corporate/corporate_list/dialog/dialog_create_corporate.tsx';
import { useCorporateContext } from '@/delivery/ui/admin/data_corporate/corporate_list/context/corporate_list_context.tsx';
import Icon from '@/domain/constant/icon.tsx';

const CorporateListPage = () => {
  const { handleAddCorporateModal } = useCorporateContext();
  return (
    <div>
      <Navbar label={'List Member Corporate'}>
        <Button
          prefixIcon={Icon.plus}
          label="Tambah Corporate"
          onClick={() => handleAddCorporateModal.setState(true)}
        />
      </Navbar>
      <CorporateList />

      {handleAddCorporateModal.state && <DialogCreateCorporate modal={handleAddCorporateModal} />}
    </div>
  );
};

export default CorporateListPage;
