import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useAccountRequestContext } from '@/delivery/ui/admin/data_dca/account_request/context/account_request_context.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import { useEffect, useState } from 'react';

const FilterAccountRequest = () => {
  const { handleFetchAccountRequest, formFilterAccountRequest, setFormFilterAccountRequest } =
    useAccountRequestContext();

  const [isResetting, setIsResetting] = useState(false);

  const handleReset = () => {
    setFormFilterAccountRequest({ fiat: undefined, gram: undefined, approved_at: '' });
    setIsResetting(true);
  };

  useEffect(() => {
    if (isResetting) {
      handleFetchAccountRequest({ page: 1, limit: 10 });
      setIsResetting(false);
    }
  }, [setFormFilterAccountRequest, isResetting]);

  return (
    <div className="flex gap-[12px]">
      <Button type={'secondary'} prefixIcon={Icon.reset} label={'Reset Filter'} onClick={handleReset} />
      <ButtonFilter
        onClickApply={() => handleFetchAccountRequest({})}
        size={'large'}
        titleFilter={'Filter Business Verify'}
        label={'More Filter'}
      >
        <div className={'flex flex-col gap-[20px]'}>
          <TextField
            search
            block
            label={'Search'}
            onChange={e =>
              setFormFilterAccountRequest({
                ...formFilterAccountRequest,
                search: e.target.value
              })
            }
          />
          <div className={'grid grid-cols-2 gap-[20px]'}>
            <TextField
              type={'number'}
              block
              label={'Fiat'}
              onChange={e =>
                setFormFilterAccountRequest({
                  ...formFilterAccountRequest,
                  fiat: parseInt(e.target.value)
                })
              }
            />
            <TextField
              block
              type={'number'}
              label={'Gram'}
              onChange={e =>
                setFormFilterAccountRequest({
                  ...formFilterAccountRequest,
                  gram: parseInt(e.target.value)
                })
              }
            />
            <Datepicker
              value={formFilterAccountRequest.approved_at}
              block
              label={'Approve At'}
              onChange={e => {
                setFormFilterAccountRequest({ ...formFilterAccountRequest, approved_at: e });
              }}
            />
          </div>
        </div>
      </ButtonFilter>
    </div>
  );
};

export default FilterAccountRequest;
