import DetailMemberVerify from '@/delivery/ui/admin/data_member/member_verify/detail/detail_member_verify.tsx';
import { MemberVerifyProvider } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';

const DetailMemberVerifyPage = () => {
  return (
    <div>
      <MemberVerifyProvider>
        <DetailMemberVerify />
      </MemberVerifyProvider>
    </div>
  );
};

export default DetailMemberVerifyPage;
