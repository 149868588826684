import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import { useMediaContext } from '../context/media_context';

const DialogAddMedia = (props: ModalDialogProps) => {
  const { handleAddMediaModal } = useMediaContext();

  return (
    <ModalDialog size="xsmall" title="Add Counter" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField label="Nama Counter" placeholder="Nama Counter" block />
        <TextField label="Media" placeholder="Alamat Counter" block />
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleAddMediaModal.setState(false)} />
        <Button
          block
          label="Confirm"
          //   onClick={() => handleSubmitAddCounterGold()}
        />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddMedia;
