import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { GoldStockRepository } from '@/domain/repository/gold_stock_repository.ts';
import { GoldStockModel } from '@/domain/model/gold_stock_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { OfflineCounterRepository } from '@/domain/repository/offline_counter_repository.ts';
import { OfflineCounterModel } from '@/domain/model/offline_counter_model.ts';
import { GoldPieceRepository } from '@/domain/repository/gold_piece_repository.ts';
import { GoldPieceModel } from '@/domain/model/gold_piece_model.ts';
import { formatDateTimeParam } from '@/infrastructure/helper/formatDate.ts';
import { GoldStockSummaryModel } from '@/domain/model/gold_stock_summary_model.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface StockGoldValue {
  page?: number;
  limit?: number;
}

interface FormFilterStok {
  type?: string;
  gram?: number;
  counter_uid?: string;
  quantity?: number;
}

interface FormStockGoldInterface {
  trxPrintUID?: string;
  type?: string;
  gram?: number;
  quantity?: number;
  notes?: string;
  counterUID?: string;
  goldPieceUID?: string;
}

interface GoldStockValue {
  goldStockSummary?: GoldStockSummaryModel;
  goldPieces: GoldPieceModel[];
  formFilterGoldStock: FormFilterStok;
  setFormFilterGoldStock: Dispatch<SetStateAction<FormFilterStok>>;
  offlineCounter: OfflineCounterModel[];
  formStockGold: FormStockGoldInterface;
  setFormStockGold: Dispatch<SetStateAction<FormStockGoldInterface>>;
  goldStocks: GoldStockModel[];
  goldStockPagination?: PaginationModel;
  handleAddStockGoldModal: ModalObject;
  handleSubmitAddStockGold: () => void;
  handleFetchGoldStock: (_data: StockGoldValue) => void;
  handleFetchOfflineCounter: () => void;
  handleFetchGoldPiece: () => void;
  handleFetchGoldStockSummary: () => void;
}

const StockGoldContext = createContext<GoldStockValue | null>(null);

const goldStockRepository = new GoldStockRepository();
const offlineCounterRep = new OfflineCounterRepository();
const goldPieceRep = new GoldPieceRepository();

const StockGoldProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const handleAddStockGoldModal = useVisibleComponent(false);

  const [goldPieces, setGoldPieces] = useState<GoldPieceModel[]>([]);

  const [goldStocks, setGoldStocks] = useState<GoldStockModel[]>([]);
  const [goldStockPagination, setGoldStockPagination] = useState<PaginationModel>();

  const [offlineCounter, setOfflineCounter] = useState<OfflineCounterModel[]>([]);
  const [formStockGold, setFormStockGold] = useState<FormStockGoldInterface>({});

  const [goldStockSummary, setGoldStockSummary] = useState<GoldStockSummaryModel>();

  const [formFilterGoldStock, setFormFilterGoldStock] = useState<FormFilterStok>({});

  //HANDLE FETCH OFFLINE COUNTER
  const handleFetchOfflineCounter = () => {
    const params = {
      current_time: formatDateTimeParam(new Date())
    };
    offlineCounterRep
      .fetchOfflineCounter(params)
      .then((result: any) => {
        setOfflineCounter(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE ADD STOCK
  const handleSubmitAddStockGold = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      gram: formStockGold.gram,
      type: formStockGold.type,
      quantity: formStockGold.quantity,
      notes: formStockGold.notes,
      counter_uid: formStockGold.counterUID,
      gold_piece_uid: formStockGold.goldPieceUID
    };

    goldStockRepository
      .storeGoldStock(params)
      .then(() => {
        handleFetchGoldStock({ page: 1 });
        handleAddStockGoldModal.setState(false);
        alertToast.successAlert();
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //FETCH GOLD STOCK
  const handleFetchGoldStock = (_data: StockGoldValue) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      type: formFilterGoldStock.type ?? null,
      gram: formFilterGoldStock.gram ?? null,
      counter_uid: formFilterGoldStock.counter_uid ?? null,
      quantity: formFilterGoldStock.quantity ?? null
    };

    goldStockRepository
      .fetchGoldStock(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setGoldStocks(result.data);
        setGoldStockPagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //FETCH GOLD STOCK SUMMARY
  const handleFetchGoldStockSummary = () => {
    const x = alertToast.loadingAlert('');

    goldStockRepository
      .fetchGoldStockSummary({})
      .then((result: any) => {
        setGoldStockSummary(result);

        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH GOLD PIECE
  const handleFetchGoldPiece = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      page: 1,
      limit: -1
    };

    goldPieceRep
      .fetchGoldPiece(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setGoldPieces(result.data);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: GoldStockValue = {
    goldStockSummary,
    goldPieces,
    formFilterGoldStock,
    setFormFilterGoldStock,
    offlineCounter,
    formStockGold,
    setFormStockGold,
    goldStocks,
    goldStockPagination,
    handleFetchGoldStock,
    handleAddStockGoldModal,
    handleSubmitAddStockGold,
    handleFetchOfflineCounter,
    handleFetchGoldPiece,
    handleFetchGoldStockSummary
  };

  return <StockGoldContext.Provider value={contextValue}>{children}</StockGoldContext.Provider>;
};

const useStockGoldeContext = (): GoldStockValue => {
  const context = useContext(StockGoldContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a StockGoldProvider');
  }
  return context;
};

export { StockGoldProvider, useStockGoldeContext };
