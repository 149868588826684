import { useEffect } from 'react';
import AccountListPage from '@/delivery/ui/admin/data_dca/account_list';
import { AccountListProvider } from '@/delivery/ui/admin/data_dca/account_list/context/account_list_context.tsx';

const ViewAccountList = () => {
  useEffect(() => {
    document.title = 'Account List';
  }, []);

  return (
    <div>
      <AccountListProvider>
        <AccountListPage />
      </AccountListProvider>
    </div>
  );
};

export default ViewAccountList;
