import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { ModalDialogFooter } from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';
import { useEffect, useState } from 'react';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { DreamGoldModel } from '@/domain/model/dream_gold_model.ts';

const EmasImpian = () => {
  const {
    formDreamGold,
    setFormDreamGold,
    dreamGolds,
    handleFetchDreamGold,
    handleUpdateDreamGold,
    handleDialogLockMemberBalance
  } = useMemberListContext();

  const [selectedDreamGold, setSelectedDreamGold] = useState<DreamGoldModel>();

  useEffect(() => {
    setFormDreamGold({ ...formDreamGold, target: 0 });
    handleFetchDreamGold();
  }, []);

  //GET SELECTED DREAM GOLD
  useEffect(() => {
    if (formDreamGold) {
      const selectedDreamGold = dreamGolds.filter(item => item.UID === formDreamGold.uid);
      setSelectedDreamGold(selectedDreamGold[0]);
    }
  }, [formDreamGold]);

  //SET TARGET TO STATE
  useEffect(() => {
    if (selectedDreamGold) {
      setFormDreamGold({ ...formDreamGold, target: selectedDreamGold.target });
    }
  }, [selectedDreamGold]);

  return (
    <div className={'flex flex-col gap-[20px]'}>
      {dreamGolds.length > 0 ? (
        <div className={'flex flex-col gap-[20px]'}>
          <Select2
            label={'Pilih Impian'}
            options={dreamGolds.map(item => {
              return {
                label: item.name,
                value: item.UID
              };
            })}
            onChange={e => {
              setFormDreamGold({ ...formDreamGold, uid: e.value });
            }}
          />

          <TextField disabled value={selectedDreamGold?.target} type={'number'} block label={'Target Emas (gr)'} />

          <ModalDialogFooter className="flex gap-[20px]">
            <Button
              block
              type="secondary"
              label="Cancel"
              onClick={() => handleDialogLockMemberBalance.setState(false)}
            />
            <Button block label="Confirm" onClick={() => handleUpdateDreamGold()} />
          </ModalDialogFooter>
        </div>
      ) : (
        <div className={'flex justify-center'}>
          <Typography size={12} type={'tertiary-invert'} fontWeight={400}>
            Belum memiliki emas impian
          </Typography>
        </div>
      )}
    </div>
  );
};

export default EmasImpian;
