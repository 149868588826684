import { BaseModel } from '@/domain/model/base_model';
import { formatDate } from '@/infrastructure/helper/formatDate.ts';

export class GoldStockModel extends BaseModel {
  trxPrintUID: string;
  type: string;
  gram: string;
  quantity: number;
  notes: string;
  counterUID: string;
  locked: number;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    trxPrintUID: string,
    type: string,
    gram: string,
    quantity: number,
    notes: string,
    counterUID: string,
    locked: number
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.trxPrintUID = trxPrintUID;
    this.type = type;
    this.gram = gram;
    this.quantity = quantity;
    this.notes = notes;
    this.counterUID = counterUID;
    this.locked = locked;
  }

  get getCreatedAt(): string {
    if (this.createdAt) {
      return formatDate(this.createdAt);
    }

    return '-';
  }

  public static fromJson(data: any): any | GoldStockModel {
    try {
      return new GoldStockModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.trx_print_uid,
        data.type,
        data.gram,
        data.quantity,
        data.notes,
        data.counter_uid,
        data.locked
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): GoldStockModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(GoldStockModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
