import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card';
import { useMemberListCountContext } from './context/member_list_count_context';
import { useEffect } from 'react';
import formatNumberWithDot from '@/infrastructure/helper/formatNumberWithDot.ts';

const MemberListCount = () => {
  const {
    handleFetchUserAsNumberTotalRegister,
    handleFetchUserAsNumberTotalVerified,
    handleFetchUserAsNumberTotalTransaction,
    usersNumberTotalVerified,
    loadingUserVerified,
    loadingUserRegister,
    usersNumberTotalRegister,
    usersNumberTotalActive,
    loadingUserActive
  } = useMemberListCountContext();

  useEffect(() => {
    handleFetchUserAsNumberTotalRegister();
    handleFetchUserAsNumberTotalVerified();
    handleFetchUserAsNumberTotalTransaction();
  }, []);

  return (
    <div>
      <div className="flex flex-col lg:flex-row gap-[24px]">
        <SimpleCard
          loading={loadingUserRegister}
          title="Total User Register"
          bigTitle={formatNumberWithDot(usersNumberTotalRegister)}
        />
        <SimpleCard
          loading={loadingUserVerified}
          title="Total User Verified"
          bigTitle={formatNumberWithDot(usersNumberTotalVerified)}
        />

        <SimpleCard
          loading={loadingUserActive}
          title="Total User Transaksi"
          bigTitle={formatNumberWithDot(usersNumberTotalActive)}
        />
      </div>
    </div>
  );
};

export default MemberListCount;
