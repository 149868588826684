export class UserStatisticModel {
  active: number;
  inactive: number;
  suspended: number;
  unverifiedEmail: number;
  verified: number;
  rejected: number;
  requested: number;
  userTransaction: number;
  userWithoutTransaction: number;

  constructor(
    active: number,
    inactive: number,
    suspended: number,
    unverifiedEmail: number,
    verified: number,
    rejected: number,
    requested: number,
    userTransaction: number,
    userWithoutTransaction: number
  ) {
    this.active = active;
    this.inactive = inactive;
    this.suspended = suspended;
    this.unverifiedEmail = unverifiedEmail;
    this.verified = verified;
    this.rejected = rejected;
    this.requested = requested;
    this.userTransaction = userTransaction;
    this.userWithoutTransaction = userWithoutTransaction;
  }

  public static fromJson(data: any): any | UserStatisticModel {
    try {
      return new UserStatisticModel(
        data.active,
        data.inactive,
        data.suspended,
        data.unverified_email,
        data.verified,
        data.rejected,
        data.requested,
        data.user_transaction,
        data.user_without_transaction
      );
    } catch {
      return null;
    }
  }
}
