import Button from '@/delivery/components/atoms/button/button';
// import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card';
import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Chips from '@/delivery/components/atoms/chips/chips';
import { useEffect } from 'react';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { useVerifyEMoneyContext } from '@/delivery/ui/admin/data_member/verify_e_money/context/verify_e_money_context.tsx';
import DialogDetailMemberVerify from '@/delivery/ui/admin/data_member/verify_e_money/dialog/dialog_detail_verify_e_money/dialog_detail_verify_e_money.tsx';
import { UserWithdrawalAccountModel } from '@/domain/model/user_withdrawal_account_model.ts';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import FilterMemberVerifyEMoney from '@/delivery/ui/admin/data_member/verify_e_money/filter_member_verify_e_money.tsx';
import { MEMBER_E_MONEY_TYPE } from '@/domain/constant/member_e_money/member_e_money_type.ts';

const MemberVerifyEMoney = () => {
  const {
    handleDetailMemberVerifyModal,
    handleFetchMemberRequestVerifyEMoney,
    verifyEMoneyPagination,
    verifyEMonies,
    handleDetailMemberVerify,
    setFormFilterMemberVerifyEMoney,
    formFilterMemberVerifyEMoney
  } = useVerifyEMoneyContext();

  useEffect(() => {
    setFormFilterMemberVerifyEMoney({ ...formFilterMemberVerifyEMoney, type: MEMBER_E_MONEY_TYPE.E_MONEY });
  }, []);

  useEffect(() => {
    if (formFilterMemberVerifyEMoney.type) {
      handleFetchMemberRequestVerifyEMoney({ page: 1, limit: 10 });
    }
  }, [formFilterMemberVerifyEMoney.type]);

  return (
    <div>
      {/*<div className="flex gap-[24px]">*/}
      {/*  <SimpleCard title="Total User Request Verify" bigTitle="365" />*/}
      {/*  <SimpleCard title="Total User Verified" bigTitle="55.642" />*/}
      {/*  <SimpleCard title="Total User Unverify" bigTitle="79.041" />*/}
      {/*</div>*/}
      <div>
        <div className="flex justify-end mb-[12px]">
          <FilterMemberVerifyEMoney />
        </div>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Request Date</Th>
              <Th>Member</Th>
              <Th>E-Money Type</Th>
              <Th>Number</Th>
              <Th>Status</Th>
              <Th>Notes</Th>
              <Th>Update By</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {verifyEMonies.map((item: UserWithdrawalAccountModel, index) => (
              <Tr>
                <Td>
                  <Typography size={12} fontWeight={400}>
                    {item.getCreatedAt}
                  </Typography>
                </Td>
                <Td>
                  <Typography size={12} fontWeight={500}>
                    {item.user?.fullName}
                  </Typography>
                  <Typography size={12} fontWeight={400}>
                    {item.user?.email}
                  </Typography>
                  <Typography size={12} fontWeight={400}>
                    {item.user?.phone}
                  </Typography>
                </Td>
                <Td>{item.masterBankUUID}</Td>
                <Td>
                  <Typography size={12} fontWeight={400} type={'tertiary-invert'}>
                    {item.accountNumber}
                  </Typography>
                </Td>
                <Td>
                  <Chips label={item.status} type={item.getStatusColor} className="leading-4" />
                  {/*<p className="text-[12px] font-[400] text-text-tertiary-invert">2023-05-20 20:02:46</p>*/}
                </Td>
                <Td>
                  <Typography fontWeight={400}>{item.notes}</Typography>
                </Td>
                <Td>{item.userUpdate}</Td>
                <Td>
                  <Button iconButton iconType={'view'} onClick={() => handleDetailMemberVerify(item)} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            handlePagination={page => {
              handleFetchMemberRequestVerifyEMoney({
                page: page
              });
            }}
            page={verifyEMoneyPagination?.page || 1}
            totalPages={verifyEMoneyPagination?.totalPages || 1}
          />
        </Tfooter>
      </div>

      {handleDetailMemberVerifyModal.state && <DialogDetailMemberVerify modal={handleDetailMemberVerifyModal} />}
    </div>
  );
};

export default MemberVerifyEMoney;
