import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useMemberVefiryContext } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';
import DialogUpdateDocument from '@/delivery/ui/admin/data_member/member_verify/detail/dialog/dialog_update_document.tsx';

const TableDocument = () => {
  const { handleEditDocumentModal, userVerification } = useMemberVefiryContext();

  return (
    <>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr noBorder>
            <Th noBorder>
              <Typography fontWeight={600} size={14} type="primary-invert">
                Dokumen
              </Typography>
            </Th>
            <Th noBorder>
              <div className={'justify-end flex'}>
                <span
                  className={'text-text-primary font-[600] text-[14px] cursor-pointer'}
                  onClick={() => {
                    handleEditDocumentModal.setState(true);
                  }}
                >
                  Edit
                </span>
              </div>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {/*bank name*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Foto KTP
              </Typography>
            </Td>
            <Td noBorder>
              <div className={'flex gap-[20px] mr-[20px] items-center'}>
                <Typography fontWeight={400} size={14} type="primary-invert">
                  :
                </Typography>
                <Image url={userVerification?.getIdentityMediaUrl} width={200} />
              </div>
            </Td>
          </Tr>

          {/*bank acc no*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Foto Selfie
              </Typography>
            </Td>
            <Td noBorder>
              <div className={'flex gap-[20px] mr-[20px] items-center'}>
                <Typography fontWeight={400} size={14} type="primary-invert">
                  :
                </Typography>
                <Image url={userVerification?.getSelfieMediaUrl} width={200} />
              </div>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      {handleEditDocumentModal.state && <DialogUpdateDocument modal={handleEditDocumentModal} />}
    </>
  );
};

export default TableDocument;
