import { useEffect } from 'react';
import RequestVerifyPage from '@/delivery/ui/admin/data_corporate/request_verify';

const ViewRequestVerify = () => {
  useEffect(() => {
    document.title = 'List Request Verify Corporate';
  }, []);

  return (
    <div>
      <RequestVerifyPage />
    </div>
  );
};

export default ViewRequestVerify;
