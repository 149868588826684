import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';
import Dropzone from '@/delivery/components/atoms/dropzone/dropzone.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';

const DialogUpdateDocument = (props: ModalDialogProps) => {
  const { userVerification, user, handleUpdateDocument, handleEditDocumentModal, formUser, setFormUser } =
    useMemberListContext();

  return (
    <ModalDialog size="small" title="Update Dokumen" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <div className={'flex gap-[20px]'}>
          {user?.identityMediaUID !== '' ? (
            <div className={'flex-col gap-[10px]'}>
              <Typography className={'mb-[5px]'} size={14} type={'tertiary-invert'}>
                Foto KTP
              </Typography>
              <Image url={userVerification?.getIdentityMediaUrl} width={300} />
            </div>
          ) : (
            ''
          )}

          <Dropzone
            id={'identity'}
            label={'Foto KTP Baru'}
            onChange={e => setFormUser({ ...formUser, identity_media: e })}
          />
        </div>

        <div className={'flex gap-[20px]'}>
          {user?.selfieMediaUID !== '' ? (
            <div className={'flex-col gap-[10px]'}>
              <Typography className={'mb-[5px]'} size={14} type={'tertiary-invert'}>
                Foto Selfie
              </Typography>
              <Image url={userVerification?.getSelfieMediaUrl} width={300} />
            </div>
          ) : (
            ''
          )}

          <Dropzone
            id={'selfie'}
            label={'Foto Selfie Baru'}
            onChange={e => setFormUser({ ...formUser, selfie_media: e })}
          />
        </div>

        <ModalDialogFooter className="flex gap-[20px] justify-end">
          <Button type="secondary" label="Cancel" onClick={() => handleEditDocumentModal.setState(false)} />
          <Button label="Save" onClick={() => handleUpdateDocument()} />
        </ModalDialogFooter>
      </div>
    </ModalDialog>
  );
};

export default DialogUpdateDocument;
