import { BaseModel } from '@/domain/model/base_model.ts';
import CompanyModel from '@/domain/model/company_model.ts';

export class UserCompanyModel extends BaseModel {
  userUUID: string;
  companyUUID: string;
  company: CompanyModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    userUUID: string,
    companyUUID: string,
    company: CompanyModel
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.flags = flags;
    this.userUUID = userUUID;
    this.companyUUID = companyUUID;
    this.company = company;
  }

  public static fromJson(data: any): any | UserCompanyModel {
    try {
      return new UserCompanyModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.user_uuid,
        data.company_uuid,
        data.company ? CompanyModel.fromJson(data.company) : null
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): UserCompanyModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(UserCompanyModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
