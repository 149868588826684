import { useVendingMachineContext } from '@/delivery/ui/admin/transaction/take_gold/vending_machine/context/vending_machine_context.tsx';
import { useEffect } from 'react';
import { Table, Tbody, Td, Tfooter, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { VendingMachineModel } from '@/domain/model/vending_machine_model.ts';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import { STATUS_CONST } from '@/domain/constant/status.ts';
import { Routing } from '@/domain/constant/router_path.ts';
import { useNavigate } from 'react-router-dom';
import DialogAddVendingMachine from '@/delivery/ui/admin/transaction/take_gold/vending_machine/dialog/dialog_add_vending_machine.tsx';
import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card.tsx';

const VendingMachine = () => {
  const navigate = useNavigate();
  const {
    handleDeleteVendingMachine,
    handleAddVendingMachineModal,
    vendingMachines,
    handleFetchVendingMachine,
    vendingMachinePagination,
    handleFetchVendingMachineStockSummary,
    stockSummary
  } = useVendingMachineContext();

  useEffect(() => {
    handleFetchVendingMachine({});
    handleFetchVendingMachineStockSummary();
  }, []);

  return (
    <div>
      <div className={'mb-[24px]'}>
        <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-[24px]">
          {stockSummary.map(itemStock => (
            <SimpleCard title={itemStock.vendingMachine}>
              <div className={'flex flex-col w-full'}>
                {itemStock.stockSummary.map(item => (
                  <div className={'flex justify-between w-full'}>
                    <p>{item.gram} gr :</p>
                    <p>{item.totalStock}</p>
                  </div>
                ))}
              </div>
            </SimpleCard>
          ))}
        </div>
      </div>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Code</Th>
            <Th>Full Address</Th>
            <Th>Status</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {vendingMachines.map((item: VendingMachineModel, index) => (
            <Tr>
              <Td>
                <Typography size={12} fontWeight={400}>
                  {item.name}
                </Typography>
              </Td>
              <Td>
                <Typography size={12} fontWeight={400}>
                  {item.code}
                </Typography>
              </Td>
              <Td>
                <Typography size={12} fontWeight={400}>
                  {item.fullAddress}
                </Typography>
              </Td>
              <Td>
                <Chips
                  label={item.status}
                  type={item.status.toUpperCase() === STATUS_CONST.ACTIVE ? 'primary-light' : 'danger-light'}
                />
              </Td>
              <Td>
                <Button
                  iconButton
                  iconType={'edit'}
                  onClick={() => navigate(Routing.TRANSACTION.AMBIL_EMAS.VENDING_MACHINE_DETAIL + item.UID)}
                />
                <Button iconButton iconType={'delete'} onClick={() => handleDeleteVendingMachine(item)} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={vendingMachinePagination?.page || 1}
          totalPages={vendingMachinePagination?.totalPages || 1}
          handlePagination={page => {
            handleFetchVendingMachine({
              page: page
            });
          }}
        />
      </Tfooter>

      {handleAddVendingMachineModal.state && <DialogAddVendingMachine modal={handleAddVendingMachineModal} />}
    </div>
  );
};

export default VendingMachine;
