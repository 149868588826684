import { ModalDialog, ModalDialogProps } from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';
import { useState } from 'react';
import { TabContent, TabPane, TabPaneList } from '@/delivery/components/atoms/tab_pane/tab_pane.tsx';
import LockByDinaran from '@/delivery/ui/admin/data_member/member_list/dialog/dialog_lock_member_balance/lock_by_dinaran.tsx';
import EmasImpian from '@/delivery/ui/admin/data_member/member_list/dialog/dialog_lock_member_balance/emas_impian.tsx';

const DialogLocMemberBalance = (props: ModalDialogProps) => {
  const { user } = useMemberListContext();

  const [tabPage, setTabPage] = useState(0);
  const data = [
    {
      label: 'Lock by Dinaran',
      index: 0
    },
    {
      label: 'Emas Impian',
      index: 1
    }
  ];

  return (
    <ModalDialog overflow={false} size="small" title="Lock Member Balance" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField type={'number'} label={'Last balance'} value={user?.balance.lastBalance} block disabled />
        <TabPane>
          {data?.map((item, index) => (
            <TabPaneList label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
          ))}
        </TabPane>

        <TabContent index={0} tab={tabPage}>
          <LockByDinaran />
        </TabContent>
        <TabContent index={1} tab={tabPage}>
          <EmasImpian />
        </TabContent>
      </div>
    </ModalDialog>
  );
};

export default DialogLocMemberBalance;
