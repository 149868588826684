import { BaseModel } from '@/domain/model/base_model.ts';
import { formatDate } from '@/infrastructure/helper/formatDate.ts';

export class TrxShippingModel extends BaseModel {
  awb: string;
  pickUpCode: string;
  pickUpPin: string;
  destinationAddress: string;
  receivedAt: string;
  status: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    awb: string,
    pickUpCode: string,
    pickUpPin: string,
    destinationAddress: string,
    receivedAt: string,
    status: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.awb = awb;
    this.pickUpCode = pickUpCode;
    this.pickUpPin = pickUpPin;
    this.destinationAddress = destinationAddress;
    this.receivedAt = receivedAt;
    this.status = status;
  }

  get getReceivedAt(): string {
    if (this.receivedAt) {
      return formatDate(this.receivedAt);
    }

    return '-';
  }

  public static fromJson(data: any): any | TrxShippingModel {
    try {
      return new TrxShippingModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.awb,
        data.pick_up_code,
        data.pick_up_pin,
        data.destination_address,
        data.received_at,
        data.status
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): TrxShippingModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(TrxShippingModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
