import { Routing } from '@/domain/constant/router_path.ts';

const responseErrorApiCall = (errorRes: any) => {
  switch (errorRes.code) {
    case 'UNCAUGHT_ERROR':
      return Promise.reject(errorRes.internal_error.message);
      // return Promise.reject('Terjadi kesalahan');
      break;
    case 'CREDENTIAL-INVALID':
      return Promise.reject(errorRes.message);
      break;
    case 'VALIDATION_ERROR':
      return Promise.reject('Validasi gagal');
      break;
    case 'RECORD-NOT-FOUND':
      return Promise.reject('Data tidak ditemukan');
      break;
    // case "ORDER-GENERATE_FAILED":
    //   return Promise.reject(errorRes.message);
    //   break;
    // case "ORDER-UPDATE_FAILED":
    //   return Promise.reject(errorRes.message);
    //   break;
    // case "CLAIM-INVALID":
    //   return Promise.reject(errorRes.message);
    //   break;
    // case "PRICE_UNCAUGHT":
    //   return Promise.reject(errorRes.message);
    //   break;
    // case "ITEM_BUNDLING-COMPOSITION_UNREGISTERED":
    //   return Promise.reject(errorRes.message);
    //   break;
    // case "ORDER-INVALID_PROGRESS":
    //   return Promise.reject(errorRes.message);
    //   break;
    // case "ORDER-RECEIPT_FAILED":
    //   return Promise.reject(errorRes.message);
    //   break;
    // case "ORDER-UPDATE_NOT_ALLOWED":
    //   return Promise.reject(errorRes.message);
    //   break;
    // case "INVOICE-GENERATE_FAILED":
    //   return Promise.reject(errorRes.message);
    //   break;
    // case "INVOICE-UPDATE_FAILED":
    //   return Promise.reject(errorRes.message);
    //   break;
    // case "PAYMENT-FAILED":
    //   return Promise.reject(errorRes.message);
    //   break;
    // case "PAYMENT-INSUFFICIENT":
    //   return Promise.reject(errorRes.message);
    //   break;

    case 'AUTH-INVALID_TOKEN':
      return (window.location.href = Routing.LOGIN);
      break;
    case 'AUTH-FAILED':
      return (window.location.href = Routing.LOGIN);
      break;
    // case 'FORBIDDEN_ACCESS':
    //   return Promise.reject('Akses terbatas');
    //   break;
    case 'CREDENTIAL-OLD':
      return Promise.reject('Data kredensial kedaluwarsa');
      break;
    case 'INVALID_REQUEST':
      return Promise.reject('Permintaan tidak valid');
      break;
    case 'VERIFICATION-FAILED':
      return Promise.reject('Verifikasi gagal');
      break;
    case 'GoldRate-Fail':
      return Promise.reject('Harga emas tidak ditemukan');
      break;
    case 'TransactionManual-Failed':
      return Promise.reject(errorRes.message);
      break;
    case 'BALANCE-ERROR':
      return Promise.reject('Balance error');
      break;
    default:
      return Promise.reject(errorRes.message);
      break;
  }
};

export default responseErrorApiCall;
