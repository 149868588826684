import { Table, Tbody, Td, Th, Thead, Theader, Tr, TBox } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';

import TableLockEmasImpian from '@/delivery/ui/admin/data_member/member_list/detail/tabs/balance/dream_gold/table_lock_emas_imipan.tsx';
import TableLockGoldByDinaran from '@/delivery/ui/admin/data_member/member_list/detail/tabs/balance/table_lock_gold_by_dinaran.tsx';
import TableLockGoldByUser from '@/delivery/ui/admin/data_member/member_list/detail/tabs/balance/table_lock_gold_by_user.tsx';
import TableLockGoldDCA from '@/delivery/ui/admin/data_member/member_list/detail/tabs/balance/table_lock_gold_by_dca.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';
import { useEffect } from 'react';
import { useBalanceContext } from '@/delivery/ui/admin/data_member/member_list/context/balance_context.tsx';
import { useParams } from 'react-router-dom';
import DialogLockGold from '@/delivery/ui/admin/data_member/member_list/detail/tabs/balance/dialog_lock_gold.tsx';

const Balance = () => {
  const { uuid } = useParams();
  const { handleLockGoldModal, balance, handleShowBalance } = useBalanceContext();

  useEffect(() => {
    if (uuid) {
      handleShowBalance({ user_uid: uuid });
    }
  }, [uuid]);

  return (
    <div>
      <div>
        <Theader>
          <div>
            <Typography size={14} fontWeight={600}>
              Data Balance
            </Typography>
          </div>
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Trx</Th>
              <Th>Balance (gr)</Th>
              <Th>Loyalty</Th>
              <Th>Real Balance (gr)</Th>
              <Th>Amount (Rp)</Th>
              <Th>Profit Loss</Th>
              <Th>Emas Impian</Th>
              <Th>Lock Dinaran</Th>
              <Th>Lock User</Th>
              <Th>DCA</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {balance?.transactionCount}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {balance?.balance?.getTruncateAmountDecimal}
                </Typography>
              </Td>
              <Td>
                <Chips label={balance?.tier.name} type={balance?.getTierColor} removeDot />
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {balance?.balanceTotal.amount}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {balance?.balanceTotal.fiat ? formatRupiah(balance?.balanceTotal.fiat) : '-'}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {balance?.profitLoss}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {balance?.dreamGoldTotal}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {balance?.balanceSystemLock.amount}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {balance?.balanceMemberLock.amount}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {balance?.dcaTotal}
                </Typography>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </div>

      <div className={'mt-[20px]'}>
        <Theader>
          <div>
            <Typography size={14} fontWeight={600}>
              Lock Balance
            </Typography>
          </div>
        </Theader>
        <TBox>
          <div className={'flex flex-col gap-[20px]'}>
            <TableLockEmasImpian />
            <TableLockGoldByDinaran />
            <TableLockGoldByUser />
            <TableLockGoldDCA />
          </div>
        </TBox>
      </div>

      {handleLockGoldModal.state && <DialogLockGold modal={handleLockGoldModal} />}
    </div>
  );
};

export default Balance;
