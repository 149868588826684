import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import { useStockGoldeContext } from '@/delivery/ui/admin/transaction/take_gold/stock_gold/context/stock_gold_context.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { TypeGoldStockList } from '@/domain/constant/gold_stock/type_gold_stock.ts';
import { useEffect } from 'react';

const DialogAddStock = (props: ModalDialogProps) => {
  const {
    handleFetchOfflineCounter,
    handleAddStockGoldModal,
    handleSubmitAddStockGold,
    formStockGold,
    setFormStockGold,
    offlineCounter,
    handleFetchGoldPiece,
    goldPieces
  } = useStockGoldeContext();

  useEffect(() => {
    handleFetchOfflineCounter();
    handleFetchGoldPiece();
  }, []);

  return (
    <ModalDialog size="xsmall" title="Add Stock Emas" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Select2
          label="Counter"
          block
          options={offlineCounter.map(item => {
            return {
              label: item.name,
              value: item.UID
            };
          })}
          onChange={e =>
            setFormStockGold({
              ...formStockGold,
              counterUID: e.value
            })
          }
        />

        <Select2
          isSearchable={false}
          label="Type"
          block
          options={TypeGoldStockList.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          onChange={e =>
            setFormStockGold({
              ...formStockGold,
              type: e.value
            })
          }
        />

        <Select2
          isSearchable={false}
          label="Gram (gr)"
          block
          options={goldPieces.map(item => {
            return {
              label: item.gram,
              value: item.UID
            };
          })}
          onChange={e => {
            setFormStockGold({
              ...formStockGold,
              goldPieceUID: e.value
            });
          }}
        />

        {/*<TextField*/}
        {/*  label="Gram (gr)"*/}
        {/*  block*/}
        {/*  type={'number'}*/}
        {/*  onChange={e =>*/}
        {/*    setFormStockGold({*/}
        {/*      ...formStockGold,*/}
        {/*      gram: parseInt(e.target.value)*/}
        {/*    })*/}
        {/*  }*/}
        {/*/>*/}
        <TextField
          label="Quantity"
          block
          type={'number'}
          onChange={e =>
            setFormStockGold({
              ...formStockGold,
              quantity: parseInt(e.target.value)
            })
          }
        />
        <Textarea
          label={'Notes'}
          block
          onChange={e =>
            setFormStockGold({
              ...formStockGold,
              notes: e.target.value
            })
          }
        />
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleAddStockGoldModal.setState(false)} />
        <Button block label="Confirm" onClick={() => handleSubmitAddStockGold()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddStock;
