import { BaseModel } from '@/domain/model/base_model.ts';
import { GoldPieceFeeModel } from '@/domain/model/gold_piece_fee_model.ts';

export class GoldPieceModel extends BaseModel {
  name: string;
  gram: number;
  status: string;
  notes: string;
  goldPieceFee: GoldPieceFeeModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    name: string,
    gram: number,
    status: string,
    notes: string,
    goldPieceFee: GoldPieceFeeModel
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.name = name;
    this.gram = gram;
    this.status = status;
    this.notes = notes;
    this.goldPieceFee = goldPieceFee;
  }

  public static fromJson(data: any): any | GoldPieceModel {
    try {
      return new GoldPieceModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.name,
        data.gram,
        data.status,
        data.notes,
        data.gold_piece_fee ? GoldPieceFeeModel.fromJson(data.gold_piece_fee) : null
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): GoldPieceModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(GoldPieceModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
