import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card';
import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Chips from '@/delivery/components/atoms/chips/chips';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useAccountListContext } from '@/delivery/ui/admin/data_dca/account_list/context/account_list_context.tsx';
import { useEffect } from 'react';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { DCAModel } from '@/domain/model/dca_model.ts';
import { truncateDecimal } from '@/infrastructure/helper/truncateDecimalNumber.ts';
import FilterAccountList from '@/delivery/ui/admin/data_dca/account_list/filter_account_list.tsx';
import formatNumberWithDot from '@/infrastructure/helper/formatNumberWithDot.ts';
import { usePDF } from 'react-to-pdf';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { IoDocumentTextOutline } from 'react-icons/io5';

const AccountList = () => {
  const { toPDF, targetRef } = usePDF({ filename: `DCA-account-list-${formatDateParam(new Date())}.pdf` });
  const {
    accountListExport,
    // fetchAccountListExport,
    DCAStatistic,
    handleShowStatistic,
    handleFetchAccountList,
    accountLists,
    accountListPagination
  } = useAccountListContext();

  useEffect(() => {
    handleFetchAccountList({});
    handleShowStatistic();
  }, []);

  // useEffect(() => {
  //   if (accountListExport.length > 0) {
  //     toPDF();
  //   }
  // }, [accountListExport]);

  // const handleExportData = () => {
  //   fetchAccountListExport();
  // };

  return (
    <div>
      <div className="flex lg:flex-row flex-col gap-[24px]">
        <SimpleCard
          title="Total Account DCA"
          bigTitle={DCAStatistic?.all.count ? formatNumberWithDot(DCAStatistic?.all.count) : '0'}
        />
        <SimpleCard
          title="Total Account DCA Verified"
          bigTitle={DCAStatistic?.approved.count ? formatNumberWithDot(DCAStatistic?.approved.count) : '0'}
        />
        <SimpleCard
          title="Total Account DCA Request"
          bigTitle={DCAStatistic?.request.count ? formatNumberWithDot(DCAStatistic?.request.count) : '0'}
        />
        {/*<SimpleCard title="Total Account DCA Jatuh Tempo" bigTitle="93" />*/}
      </div>
      <div className="mt-[32px]">
        <div className={'flex justify-end mb-[20px]'}>
          <FilterAccountList>
            <Button
              label="Export PDF"
              type="secondary"
              prefixIcon={<IoDocumentTextOutline />}
              onClick={() => toPDF()}
            />
          </FilterAccountList>
        </div>
        <div ref={targetRef}>
          <Table noBorder className="rounded-b-[8px]">
            <Thead>
              <Tr>
                <Th>Member</Th>
                <Th>Balance (gr)</Th>
                <Th>Bal DCA (80%)</Th>
                <Th>Fasilitas DCA</Th>
                <Th>Lock DCA</Th>
                <Th>Reg Date</Th>
                <Th>Status</Th>
                {/*<Th>Updated By </Th>*/}
                {/*<Th>Action</Th>*/}
              </Tr>
            </Thead>
            <Tbody>
              {accountLists.map((item: DCAModel, index) => (
                <Tr>
                  <Td>
                    <Typography fontWeight={600} size={12} type="primary-invert">
                      {item.getFullName}
                    </Typography>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {item.email}
                    </Typography>
                  </Td>
                  <Td>
                    <div className="text-right">
                      <Typography fontWeight={600} size={12} type="primary-invert">
                        {truncateDecimal(item.totalBalance.amount)}
                      </Typography>
                      <Typography fontWeight={400} size={12} type="tertiary-invert">
                        {item.totalBalance.amount}
                      </Typography>
                    </div>
                  </Td>
                  <Td>
                    <div className="text-right ">
                      <Typography fontWeight={600} size={12} type="primary-invert">
                        {item.getDCAFiatRupiah}
                      </Typography>
                      <Typography fontWeight={400} size={12} type="tertiary-invert">
                        {item.dcaBalanceGram} gr
                      </Typography>
                    </div>
                  </Td>
                  <Td>
                    <div className={'flex flex-col gap-[10px]'}>
                      <table>
                        <tbody>
                          {item.dcaBalance.map(item => (
                            <tr>
                              <td className={'text-nowrap px-[10px] py-[8px]'}>
                                <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                                  {item.serialID}
                                </Typography>
                              </td>
                              <td className={'text-left'}>
                                <Typography tag="span" fontWeight={600} size={12} type="tertiary-invert">
                                  {item.getFiat}
                                </Typography>
                              </td>
                              <td>
                                <Chips label={item.status} type={item.getStatusColor} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Td>

                  <Td>
                    <div className="text-right ">
                      <Typography fontWeight={600} size={12} type="primary-invert">
                        {truncateDecimal(item.dcaLockGram)}
                      </Typography>
                      <Typography fontWeight={400} size={12} type="tertiary-invert">
                        {item.dcaLockGram}
                      </Typography>
                    </div>
                  </Td>
                  <Td>
                    <Typography className="leading-4" fontWeight={400} size={12} type="tertiary-invert">
                      {item.getCreatedAt}
                    </Typography>
                  </Td>
                  <Td>
                    <Chips type={item.getStatusActiveColor} label={item.dcaStatus} className="leading-4" />
                    <Typography className="leading-4" fontWeight={400} size={10} type="tertiary-invert">
                      {item.getVerifiedAt}
                    </Typography>
                  </Td>
                  {/*<Td>*/}
                  {/*  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
                  {/*    System*/}
                  {/*  </Typography>*/}
                  {/*</Td>*/}
                  {/*<Td>*/}
                  {/*  <div className="flex gap-[4px]">*/}
                  {/*    <Button iconButton iconType={'view'} />*/}
                  {/*  </div>*/}
                  {/*</Td>*/}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Tfooter>
            <Pagination
              handlePagination={page => {
                handleFetchAccountList({
                  page: page
                });
              }}
              page={accountListPagination?.page || 1}
              totalPages={accountListPagination?.totalPages || 1}
            />
          </Tfooter>
        </div>
      </div>

      {/*EXPORT RESULT*/}
      <div className={'hidden'}>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Member</Th>
              <Th>Balance (gr)</Th>
              <Th>Bal DCA (80%)</Th>
              <Th>Fasilitas DCA</Th>
              <Th>Lock DCA</Th>
              <Th>Reg Date</Th>
              <Th>Status</Th>
              {/*<Th>Updated By </Th>*/}
              {/*<Th>Action</Th>*/}
            </Tr>
          </Thead>
          <Tbody>
            {accountListExport.map((item: DCAModel, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {item.getFullName}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.email}
                  </Typography>
                </Td>
                <Td>
                  <div className="text-right">
                    <Typography fontWeight={600} size={12} type="primary-invert">
                      {truncateDecimal(item.totalBalance.amount)}
                    </Typography>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {item.totalBalance.amount}
                    </Typography>
                  </div>
                </Td>
                <Td>
                  <div className="text-right ">
                    <Typography fontWeight={600} size={12} type="primary-invert">
                      {item.getDCAFiatRupiah}
                    </Typography>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {item.dcaBalanceGram} gr
                    </Typography>
                  </div>
                </Td>
                <Td>
                  <div className={'flex flex-col gap-[10px]'}>
                    <table>
                      <tbody>
                        {item.dcaBalance.map(item => (
                          <tr>
                            <td className={'text-nowrap px-[10px] py-[8px]'}>
                              <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                                {item.serialID}
                              </Typography>
                            </td>
                            <td className={'text-left'}>
                              <Typography tag="span" fontWeight={600} size={12} type="tertiary-invert">
                                {item.getFiat}
                              </Typography>
                            </td>
                            <td>
                              <p className={`text-${item.getStatusColor}`}>{item.status}</p>
                              {/*<Chips label={item.status} type={item.getStatusColor} />*/}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Td>

                <Td>
                  <div className="text-right ">
                    <Typography fontWeight={600} size={12} type="primary-invert">
                      {truncateDecimal(item.dcaLockGram)}
                    </Typography>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {item.dcaLockGram}
                    </Typography>
                  </div>
                </Td>
                <Td>
                  <Typography className="leading-4" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getCreatedAt}
                  </Typography>
                </Td>
                <Td>
                  <Chips type={item.getStatusActiveColor} label={item.dcaStatus} className="leading-4" />
                  <Typography className="leading-4" fontWeight={400} size={10} type="tertiary-invert">
                    {item.getVerifiedAt}
                  </Typography>
                </Td>
                {/*<Td>*/}
                {/*  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*    System*/}
                {/*  </Typography>*/}
                {/*</Td>*/}
                {/*<Td>*/}
                {/*  <div className="flex gap-[4px]">*/}
                {/*    <Button iconButton iconType={'view'} />*/}
                {/*  </div>*/}
                {/*</Td>*/}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default AccountList;
