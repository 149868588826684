import Navbar from '@/delivery/components/organisms/navbar/navbar';
import Button from '@/delivery/components/atoms/button/button';
import CustomVariable from '@/delivery/ui/admin/settings/custom_variable/custom_variable';
import Icon from '@/domain/constant/icon.tsx';

const CustomVariablePage = () => {
  return (
    <div>
      <Navbar label={'Custom Variable'}>
        <Button prefixIcon={Icon.plus} label="Add Custom Variable" />
      </Navbar>
      <CustomVariable />
    </div>
  );
};

export default CustomVariablePage;
