import Button from '@/delivery/components/atoms/button/button';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { STATUS_CORPORATE_LIST } from '@/domain/constant/status_corporate.ts';
// import Line from '@/delivery/components/atoms/line/line.tsx';
// import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useBusinessVerifyContext } from '@/delivery/ui/admin/data_member/business_verify/context/business_verify_context.tsx';
import { useParams } from 'react-router-dom';

const DialogApproveBusinessVerify = (props: ModalDialogProps) => {
  const { uid } = useParams();
  const { handleApproveBusinessVerifyModal, formBusinessVerify, setFormBusinessVerify, handleApproveBusinessVerify } =
    useBusinessVerifyContext();
  return (
    <ModalDialog size="small" title="Approval business request verify" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Select2
          value={formBusinessVerify.status}
          options={STATUS_CORPORATE_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          block
          label={'status'}
          onChange={e => setFormBusinessVerify({ ...formBusinessVerify, status: e.value })}
        />
        <Textarea
          block
          label={'Note'}
          value={formBusinessVerify.notes}
          onChange={e => setFormBusinessVerify({ ...formBusinessVerify, notes: e.target.value })}
        />
        {/*<Line />*/}
        {/*<TextField label={'Sales'} block />*/}
        {/*<TextField label={'Komisi Sales (%)'} block />*/}
        {/*<TextField label={'Komisi Bisnis (%)'} block />*/}
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button
          type="secondary"
          label="Cancel"
          block
          onClick={() => handleApproveBusinessVerifyModal.setState(false)}
        />
        <Button label="Confirm" block onClick={() => handleApproveBusinessVerify({ uid: uid })} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogApproveBusinessVerify;
