import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import GenderList from '@/domain/constant/gender.ts';
import ReligionList from '@/domain/constant/religion.ts';
import { formatDateTimeTParam } from '@/infrastructure/helper/formatDate.ts';

const DialogUpdateDataID = (props: ModalDialogProps) => {
  const { handleUpdateDataID, handleEditDataIDModal, formUser, setFormUser } = useMemberListContext();

  return (
    <ModalDialog overflow={false} size="small" title="Update Info KTP" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField
          value={formUser?.identity_number}
          label={'NIK'}
          block
          onChange={e => setFormUser({ ...formUser, identity_number: e.target.value })}
        />
        <TextField
          value={formUser.birth_place}
          label={'Tempat lahir'}
          block
          onChange={e => setFormUser({ ...formUser, birth_place: e.target.value })}
        />
        <Datepicker
          value={formUser.birth_date}
          label={'Tanggal lahir'}
          block
          onChange={e => setFormUser({ ...formUser, birth_date: formatDateTimeTParam(e) })}
        />
        <Select2
          options={ReligionList.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          value={formUser.religion}
          label={'Agama'}
          block
          onChange={e => setFormUser({ ...formUser, religion: e.label })}
        />
        <Select2
          options={GenderList.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          value={formUser.gender}
          label={'Gender'}
          block
          onChange={e => setFormUser({ ...formUser, gender: e.label })}
        />
        <ModalDialogFooter className="flex gap-[20px] justify-end">
          <Button type="secondary" label="Cancel" onClick={() => handleEditDataIDModal.setState(false)} />
          <Button label="Save" onClick={() => handleUpdateDataID()} />
        </ModalDialogFooter>
      </div>
    </ModalDialog>
  );
};

export default DialogUpdateDataID;
