import styles from './TextField.module.css';
import clsx from 'clsx';
import { NumberFormatBase } from 'react-number-format';
import formatCurrency from '@/infrastructure/helper/format_currency.ts';
import { useEffect, useState } from 'react';

interface TextFieldProps {
  search?: boolean;
  className?: string;
  placeholder?: string;
  label?: string;
  value?: string | number;
  min?: number;
  max?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  block?: boolean;
  disabled?: boolean;
  type?: 'text' | 'date' | 'number' | 'password' | 'file' | 'currency' | 'time' | 'email';
  onclick?: () => void;
  required?: boolean;
  maxLength?: number;
}

const TextField = (props: TextFieldProps) => {
  const {
    onclick,
    value,
    placeholder,
    className,
    label,
    min,
    max,
    onChange,
    onKeyDown,
    block = false,
    disabled = false,
    type = 'text',
    search,
    required = false,
    maxLength
  } = props;

  const classProps = clsx(
    className,
    styles['input'],
    `${disabled ? styles['disabled'] : ''}`,
    `${search ? styles['search'] : ''}`,
    `${block ? 'w-full' : ''}`
  );

  const [emailValid, setEmailValid] = useState(true);

  //EMAIL VALIDATION
  useEffect(() => {
    if (type === 'email') {
      if (value !== undefined || value !== '') {
        if (!validateEmail(value)) {
          setEmailValid(false);
          return;
        } else {
          setEmailValid(true);
        }
      }
    }
  }, [type, value]);

  const validateEmail = (value: any) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(value);
  };

  return (
    <div className={`${block ? 'w-full' : ''} flex flex-col`}>
      {label && (
        <div className={'flex gap-[3px]'}>
          <div className="text-text-secondary-invert text-[14px] text-[500] mb-[6px]">{label}</div>
          {required && <span className="text-text-danger text-xs">*</span>}
        </div>
      )}

      <div className="relative">
        {type !== 'currency' ? (
          <>
            {search === true ? (
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-text-tertiary-invert">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
            ) : (
              ''
            )}

            <input
              step={0.01}
              onClick={onclick}
              onKeyDown={onKeyDown}
              type={type}
              value={value}
              disabled={disabled}
              maxLength={maxLength}
              max={max}
              min={min}
              className={classProps}
              placeholder={placeholder}
              onChange={onChange}
            />
            {emailValid === false ? (
              <div className={'text-text-danger text-[12px] absolute'}>email tidak valid</div>
            ) : (
              ''
            )}
          </>
        ) : (
          <NumberFormatCurrency className={classProps} value={value} onValueChange={onChange} disabled={disabled} />
        )}

        {/*{type === 'time' ? (*/}
        {/*  <div>*/}
        {/*    <input*/}
        {/*      onKeyDown={onKeyDown}*/}
        {/*      type={t}*/}
        {/*      value={value}*/}
        {/*      disabled={disabled}*/}
        {/*      max={max}*/}
        {/*      min={min}*/}
        {/*      className={classProps}*/}
        {/*      placeholder={placeholder}*/}
        {/*      onChange={onChange}*/}
        {/*    />*/}
        {/*    <span>:</span>*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  ''*/}
        {/*)}*/}
      </div>
    </div>
  );
};

function NumberFormatCurrency(props: any) {
  return <NumberFormatBase {...props} format={formatCurrency} />;
}

export default TextField;
