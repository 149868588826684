import Navbar from '@/delivery/components/organisms/navbar/navbar';
import CheckData from '@/delivery/ui/admin/settings/check_data/check_data';

const CheckDataPage = () => {
  return (
    <div>
      <Navbar label={'Check Data'}></Navbar>
      <CheckData />
    </div>
  );
};

export default CheckDataPage;
