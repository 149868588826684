import DetailBusinessVerify from '@/delivery/ui/admin/data_member/business_verify/detail/detail_business_verify.tsx';
import { BusinessVerifyProvider } from '@/delivery/ui/admin/data_member/business_verify/context/business_verify_context.tsx';

const DetailBusinessVerifyPage = () => {
  return (
    <div>
      <BusinessVerifyProvider>
        <DetailBusinessVerify />
      </BusinessVerifyProvider>
    </div>
  );
};

export default DetailBusinessVerifyPage;
