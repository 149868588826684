import useAlertToast from '@/delivery/hooks/useAlertComponent';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { VendingMachineModel } from '@/domain/model/vending_machine_model.ts';
import { VendingMachineRepository } from '@/domain/repository/vending_machine_repository.ts';
import { NATIONAL_ID } from '@/domain/constant/national_id.ts';
import { VendorRepository } from '@/domain/repository/vendor_repository.ts';
import { VendorModel } from '@/domain/model/vendor_model.ts';
import { RegionProvinceModel } from '@/domain/model/region_province_model.ts';
import { RegionCityModel } from '@/domain/model/region_city_model.ts';
import { RegionDistrictModel } from '@/domain/model/region_district_model.ts';
import { RegionVillageModel } from '@/domain/model/region_village_model.ts';
import { RegionRepository } from '@/domain/repository/region_repository.ts';
import { ModalObject } from '@/delivery/interface/modal_interface.ts';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent.tsx';
import { TYPE_VENDOR } from '@/domain/constant/vendor/vendor_type.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { GoldStockVendingMachineRepository } from '@/domain/repository/gold_stock_vending_machine_repository.ts';
import { VendingMachineStockSummaryModel } from '@/domain/model/vending_machine_stock_summary_model.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface VendingMachineParams {
  uid?: string;
  page?: number;
  limit?: number;
}

interface FormFilterVendingMachine {
  type?: string;
  gram?: number;
  counter_uid?: string;
  quantity?: number;
}

interface FormVendingMachineInterface {
  uid?: string;
  cityID?: number;
  code?: string;
  districtID?: number;
  fullAddress?: string;
  name?: string;
  nationalID?: number;
  provinceID?: number;
  status?: string;
  vendorUID?: string;
  villageID?: number;
  zipCode?: string;
}

interface VendingMachineValue {
  stockSummary: VendingMachineStockSummaryModel[];
  vendors: VendorModel[];
  handleFetchVendors: (_data: VendingMachineParams) => void;
  formFilterVendingMachine: FormFilterVendingMachine;
  setFormFilterVendingMachine: Dispatch<SetStateAction<FormFilterVendingMachine>>;
  formVendingMachine: FormVendingMachineInterface;
  setFormVendingMachine: Dispatch<SetStateAction<FormVendingMachineInterface>>;
  vendingMachines: VendingMachineModel[];
  vendingMachinePagination?: PaginationModel;
  handleSubmitAddVendingMachine: () => void;
  handleFetchVendingMachine: (_data: VendingMachineParams) => void;
  handleShowVendingMachine: (_data: VendingMachineParams) => void;
  handleUpdateAddVendingMachine: () => void;
  handleAddVendingMachineModal: ModalObject;
  handleDeleteVendingMachine: (_data: VendingMachineModel) => void;
  handleFetchVendingMachineStockSummary: () => void;

  provinces: RegionProvinceModel[];
  cities: RegionDistrictModel[];
  districts: RegionDistrictModel[];
  villages: RegionVillageModel[];
  handleFetchProvince: () => void;
  handleFetchCities: () => void;
  handleFetchDistricts: () => void;
  handleFetchVillage: () => void;

  provinceID: number;
  setProvinceID: Dispatch<SetStateAction<number>>;
  cityID: number;
  setCityID: Dispatch<SetStateAction<number>>;
  districtID: number;
  setDistrictID: Dispatch<SetStateAction<number>>;
  villageID: number;
  setVillageID: Dispatch<SetStateAction<number>>;
}

const VendingMachineContext = createContext<VendingMachineValue | null>(null);

const vendingMachineRepository = new VendingMachineRepository();
const vendorRepository = new VendorRepository();
const regionRepository = new RegionRepository();
const vendingMachineStockRep = new GoldStockVendingMachineRepository();

const VendingMachineProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const handleAddVendingMachineModal = useVisibleComponent(false);

  const [vendors, setVendors] = useState<VendorModel[]>([]);

  const [vendingMachines, setVendingMachines] = useState<VendingMachineModel[]>([]);
  const [vendingMachinePagination, setVendingMachinePagination] = useState<PaginationModel>();

  const [formVendingMachine, setFormVendingMachine] = useState<FormVendingMachineInterface>({});
  const [formFilterVendingMachine, setFormFilterVendingMachine] = useState<FormFilterVendingMachine>({});

  const [stockSummary, setStockSummary] = useState<VendingMachineStockSummaryModel[]>([]);

  const [provinces, setProvinces] = useState<RegionProvinceModel[]>([]);
  const [cities, setCities] = useState<RegionCityModel[]>([]);
  const [districts, setDistricts] = useState<RegionDistrictModel[]>([]);
  const [villages, setVillages] = useState<RegionVillageModel[]>([]);

  const [provinceID, setProvinceID] = useState<number>(0);
  const [cityID, setCityID] = useState<number>(0);
  const [districtID, setDistrictID] = useState<number>(0);
  const [villageID, setVillageID] = useState<number>(0);

  //FETCH VENDORS
  const handleFetchVendors = (_data: VendingMachineParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: 1,
      limit: -1,
      type: TYPE_VENDOR.VENDING_MACHINE
    };

    vendorRepository
      .fetchVendor(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setVendors(result.data);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE ADD VENDING MACHINE
  const handleSubmitAddVendingMachine = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      full_address: formVendingMachine.fullAddress,
      name: formVendingMachine.name,
      code: formVendingMachine.code,
      city_id: formVendingMachine.cityID,
      district_id: formVendingMachine.districtID,
      national_id: NATIONAL_ID,
      province_id: formVendingMachine.provinceID,
      status: formVendingMachine.status,
      vendor_uid: formVendingMachine.vendorUID,
      village_id: formVendingMachine.villageID,
      zip_code: formVendingMachine.zipCode
    };

    vendingMachineRepository
      .storeVendingMachine(params)
      .then(() => {
        handleFetchVendingMachine({ page: 1 });
        handleAddVendingMachineModal.setState(false);
        alertToast.successAlert();
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //FETCH VENDING MACHINE
  const handleFetchVendingMachine = (_data: VendingMachineParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10
    };

    vendingMachineRepository
      .fetchVendingMachine(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setVendingMachines(result.data);
        setVendingMachinePagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW VENDING MACHINE
  const handleShowVendingMachine = (_data: VendingMachineParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.uid
    };

    vendingMachineRepository
      .showVendingMachine(params)
      .then((result: any) => {
        const res: VendingMachineModel = result;
        alertToast.updateLoading(x);
        setFormVendingMachine({
          uid: res.UID,
          name: res.name,
          code: res.code,
          nationalID: res.nationalID,
          provinceID: res.provinceID,
          districtID: res.districtID,
          cityID: res.cityID,
          villageID: res.villageID,
          zipCode: res.zipCode,
          vendorUID: res.vendorUID,
          status: res.status,
          fullAddress: res.fullAddress
        });

        setProvinceID(parseInt(result.provinceID));
        setCityID(parseInt(result.cityID));
        setDistrictID(parseInt(result.districtID));
        setVillageID(parseInt(result.villageID));
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE VENDING MACHINE
  const handleUpdateAddVendingMachine = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      uid: formVendingMachine.uid,
      full_address: formVendingMachine.fullAddress,
      name: formVendingMachine.name,
      code: formVendingMachine.code,
      national_id: NATIONAL_ID,
      province_id: provinceID,
      city_id: cityID,
      district_id: districtID,
      village_id: villageID,
      status: formVendingMachine.status,
      vendor_uid: formVendingMachine.vendorUID,
      zip_code: formVendingMachine.zipCode
    };

    vendingMachineRepository
      .updateVendingMachine(params)
      .then(() => {
        handleFetchVendingMachine({ page: 1 });
        alertToast.successAlert();
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE DELETE VENDING MACHINE
  const handleDeleteVendingMachine = (_data: VendingMachineModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        const params = {
          uid: _data.UID
        };

        vendingMachineRepository
          .deleteVendingMachine(params)
          .then(() => {
            handleFetchVendingMachine({ page: 1 });
            alertToast.successAlert();
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //STOCK VENDING MACHINE SUMMARY
  const handleFetchVendingMachineStockSummary = () => {
    const x = alertToast.loadingAlert('Fetching Summary...');

    vendingMachineStockRep
      .fetchGoldStockVendingMachineSummary({})
      .then((result: any) => {
        setStockSummary(result);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  // ------ LOCATION ADDRESS BELOW ------ //

  //HANDLE FETCH PROVINCE
  const handleFetchProvince = () => {
    regionRepository
      .fetchProvince()
      .then((result: any) => {
        setProvinces(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH CITIES
  const handleFetchCities = () => {
    const params = {
      parent_id: provinceID
    };
    regionRepository
      .fetchCities(params)
      .then((result: any) => {
        setCities(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH DISTRICT
  const handleFetchDistricts = () => {
    const params = {
      parent_id: cityID
    };
    regionRepository
      .fetchDistrict(params)
      .then((result: any) => {
        setDistricts(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH VILLAGE
  const handleFetchVillage = () => {
    const params = {
      parent_id: districtID
    };

    regionRepository
      .fetchVillage(params)
      .then((result: any) => {
        setVillages(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const contextValue: VendingMachineValue = {
    stockSummary,
    vendors,
    formFilterVendingMachine,
    setFormFilterVendingMachine,
    formVendingMachine,
    setFormVendingMachine,
    vendingMachines,
    vendingMachinePagination,
    handleFetchVendingMachine,
    handleSubmitAddVendingMachine,
    handleShowVendingMachine,
    handleUpdateAddVendingMachine,
    handleFetchVendors,
    handleAddVendingMachineModal,
    handleDeleteVendingMachine,
    handleFetchVendingMachineStockSummary,

    provinces,
    cities,
    districts,
    villages,
    handleFetchProvince,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    villageID,
    setVillageID
  };

  return <VendingMachineContext.Provider value={contextValue}>{children}</VendingMachineContext.Provider>;
};

const useVendingMachineContext = (): VendingMachineValue => {
  const context = useContext(VendingMachineContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a VendingMachineProvider');
  }
  return context;
};

export { VendingMachineProvider, useVendingMachineContext };
