import { useEffect } from 'react';
import VerifyEMoneyPage from '@/delivery/ui/admin/data_member/verify_e_money';
import { VerifyEMoneyProvider } from '@/delivery/ui/admin/data_member/verify_e_money/context/verify_e_money_context';
import { VerifyEMoneyCountProvider } from '@/delivery/ui/admin/data_member/verify_e_money/context/verify_e_money_count_context';

const ViewMemberVerifyEMoney = () => {
  useEffect(() => {
    document.title = 'Member Verify E-Money';
  }, []);

  return (
    <div>
      <VerifyEMoneyCountProvider>
        <VerifyEMoneyProvider>
          <VerifyEMoneyPage />
        </VerifyEMoneyProvider>
      </VerifyEMoneyCountProvider>
    </div>
  );
};

export default ViewMemberVerifyEMoney;
