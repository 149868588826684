import { BaseModel } from '@/domain/model/base_model.ts';
import { UserModel } from '@/domain/model/user_model.ts';
import { MasterBankModel } from '@/domain/model/master_bank_model.ts';
import { RegionNationalityModel } from '@/domain/model/region_nationality_model.ts';
import { RegionProvinceModel } from '@/domain/model/region_province_model.ts';
import { RegionCityModel } from '@/domain/model/region_city_model.ts';
import { RegionDistrictModel } from '@/domain/model/region_district_model.ts';
import { RegionVillageModel } from '@/domain/model/region_village_model.ts';
import { formatDateTime } from '@/infrastructure/helper/formatDate.ts';
import { ChipType } from '@/domain/constant/component/chip_colors.ts';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';
import { IMAGE_URL } from '@/domain/constant/image_url.ts';

export class CorporateModel extends BaseModel {
  corporateUID: string;
  name: string;
  emailCorporate: string;
  phoneCorporate: string;
  regDate: string;
  picName: string;
  permit: string;
  website: string;
  mediaUID: string;
  companyRegistrationNumberMediaUID: string;
  companyDeedMediaUID: string;
  taxpayerIdentificationNumberMediaUID: string;
  checkingAccountMediaUID: string;
  identityMediaUID: string;
  mailAddress: string;
  userUID: string;
  notes: string;
  status: string;
  defaultWithdrawalUID: string;
  latitude: number;
  longitude: number;
  fullAddress: string;
  nationalID: number;
  provinceID: number;
  cityID: number;
  districtID: number;
  villageID: number;
  zipCode: string;
  user: UserModel;
  defaultWithdrawal: string;
  masterBankUUID: string;
  masterBank: MasterBankModel;
  nationality: RegionNationalityModel;
  province: RegionProvinceModel;
  city: RegionCityModel;
  district: RegionDistrictModel;
  village: RegionVillageModel;
  accountNumber: string;
  accountHolderName: string;
  type: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    corporateUID: string,
    name: string,
    emailCorporate: string,
    phoneCorporate: string,
    regDate: string,
    picName: string,
    permit: string,
    website: string,
    mediaUID: string,
    companyRegistrationNumberMediaUID: string,
    companyDeedMediaUID: string,
    taxpayerIdentificationNumberMediaUID: string,
    checkingAccountMediaUID: string,
    identityMediaUID: string,
    mailAddress: string,
    userUID: string,
    notes: string,
    status: string,
    defaultWithdrawalUID: string,
    latitude: number,
    longitude: number,
    fullAddress: string,
    nationalID: number,
    provinceID: number,
    cityID: number,
    districtID: number,
    villageID: number,
    zipCode: string,
    user: UserModel,
    defaultWithdrawal: string,
    masterBankUUID: string,
    masterBank: MasterBankModel,
    nationality: RegionNationalityModel,
    province: RegionProvinceModel,
    city: RegionCityModel,
    district: RegionDistrictModel,
    village: RegionVillageModel,
    accountNumber: string,
    accountHolderName: string,
    type: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.corporateUID = corporateUID;
    this.name = name;
    this.emailCorporate = emailCorporate;
    this.phoneCorporate = phoneCorporate;
    this.regDate = regDate;
    this.picName = picName;
    this.permit = permit;
    this.website = website;
    this.mediaUID = mediaUID;
    this.companyRegistrationNumberMediaUID = companyRegistrationNumberMediaUID;
    this.companyDeedMediaUID = companyDeedMediaUID;
    this.taxpayerIdentificationNumberMediaUID = taxpayerIdentificationNumberMediaUID;
    this.checkingAccountMediaUID = checkingAccountMediaUID;
    this.identityMediaUID = identityMediaUID;
    this.mailAddress = mailAddress;
    this.userUID = userUID;
    this.notes = notes;
    this.status = status;
    this.defaultWithdrawalUID = defaultWithdrawalUID;
    this.latitude = latitude;
    this.longitude = longitude;
    this.fullAddress = fullAddress;
    this.nationalID = nationalID;
    this.provinceID = provinceID;
    this.cityID = cityID;
    this.districtID = districtID;
    this.villageID = villageID;
    this.zipCode = zipCode;
    this.user = user;
    this.defaultWithdrawal = defaultWithdrawal;
    this.masterBankUUID = masterBankUUID;
    this.masterBank = masterBank;
    this.nationality = nationality;
    this.province = province;
    this.city = city;
    this.district = district;
    this.village = village;
    this.accountNumber = accountNumber;
    this.accountHolderName = accountHolderName;
    this.type = type;
  }

  get getCreatedAt(): string {
    if (this.createdAt) {
      return formatDateTime(this.createdAt);
    }

    return '-';
  }

  get getStatusColor(): ChipType {
    if (this.status === STATUS_CORPORATE.VERIFIED) {
      return 'primary-light';
    }

    if (this.status === STATUS_CORPORATE.REQUEST) {
      return 'warning-light';
    }

    if (this.status === STATUS_CORPORATE.REJECT) {
      return 'danger-light';
    }
  }

  get getCompanyRegistrationNumberUrl(): string {
    if (this.companyRegistrationNumberMediaUID) {
      return `${IMAGE_URL}/${this.companyRegistrationNumberMediaUID}`;
    }
    return '#';
  }

  get getCompanyDeedMediaUrl(): string {
    if (this.companyDeedMediaUID) {
      return `${IMAGE_URL}/${this.companyDeedMediaUID}`;
    }
    return '#';
  }

  get getTaxpayerIdentificationNumberUrl(): string {
    if (this.taxpayerIdentificationNumberMediaUID) {
      return `${IMAGE_URL}/${this.taxpayerIdentificationNumberMediaUID}`;
    }
    return '#';
  }

  get getCheckingAccountUrl(): string {
    if (this.checkingAccountMediaUID) {
      return `${IMAGE_URL}/${this.checkingAccountMediaUID}`;
    }
    return '#';
  }

  get getIdentityUrl(): string {
    if (this.identityMediaUID) {
      return `${IMAGE_URL}/${this.identityMediaUID}`;
    }
    return '#';
  }

  public static fromJson(data: any): any | CorporateModel {
    try {
      return new CorporateModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.corporate_uid,
        data.name,
        data.email_corporate,
        data.phone_corporate,
        data.reg_date,
        data.pic_name,
        data.permit,
        data.website,
        data.media_uid,
        data.company_registration_number_media_uid,
        data.company_deed_media_uid,
        data.taxpayer_identification_number_media_uid,
        data.checking_account_media_uid,
        data.identity_media_uid,
        data.mail_address,
        data.user_uid,
        data.notes,
        data.status,
        data.default_withdrawal_uid,
        data.latitude,
        data.longitude,
        data.full_address,
        data.national_id,
        data.province_id,
        data.city_id,
        data.district_id,
        data.village_id,
        data.zip_code,
        data.user,
        data.default_withdrawal,
        data.master_bank_uuid,
        data.master_bank ? MasterBankModel.fromJson(data.master_bank) : null,
        data.nationality ? RegionNationalityModel.fromJson(data.nationality) : null,
        data.province ? RegionProvinceModel.fromJson(data.province) : null,
        data.city ? RegionCityModel.fromJson(data.city) : null,
        data.district ? RegionDistrictModel.fromJson(data.district) : null,
        data.village ? RegionVillageModel.fromJson(data.village) : null,
        data.account_number,
        data.account_holder_name,
        data.type
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): CorporateModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(CorporateModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
