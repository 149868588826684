import { ApiClient } from '@/main/apiClient';
import { StatisticTransaction } from '@/domain/constant/api_list';
import TransactionChartInterface, {
  TransactionChartValue
} from '@/domain/repository/dashboard/interface/transaction_chart_repository.ts';
import TransactionChartModel from '@/domain/model/dashboard/transaction_chart_model.ts';
import TransactionSummaryModel from '@/domain/model/dashboard/transaction_summary_model.ts';
import RankModel from '@/domain/model/dashboard/rank_model.ts';

export class TransactionChartRepository implements TransactionChartInterface {
  api = new ApiClient();

  async statisticDashboardTransaction(data: TransactionChartValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(StatisticTransaction.TRANSACTION_STATISTIC, {
            params: data
          })
          .then(result => {
            const data = TransactionChartModel.fromJsonList(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async transactionSummary() {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(StatisticTransaction.TRANSACTION_SUMMARY, {})
          .then(result => {
            const data = TransactionSummaryModel.fromJson(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async transactionRank(data: TransactionChartValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(StatisticTransaction.TRANSACTION_RANK, {
            params: data
          })
          .then(result => {
            const data = RankModel.fromJsonList(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
