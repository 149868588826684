import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { BsFilter } from 'react-icons/bs';
import { FiDownloadCloud } from 'react-icons/fi';

import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Theader, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Chips from '@/delivery/components/atoms/chips/chips';
import Typography from '@/delivery/components/atoms/typography/typography';

const MicrogoldByATM = () => {
  return (
    <div>
      <div>
        <Theader>
          <TextField search placeholder="Search" />
          <div className="flex gap-[12px]">
            <Button label="More Filter" type="secondary" prefixIcon={<BsFilter />} />
            <Button label="Export by Filter" type="secondary" prefixIcon={<FiDownloadCloud />} />
          </div>
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Member</Th>
              <Th>Balance (gr)</Th>
              <Th>Loyalty</Th>
              <Th>Profit Loss</Th>
              <Th>Lock Ball</Th>
              <Th>Reg Date</Th>
              <Th>Email Verified</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    John Dukes
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    iva838@outlook.com
                  </Typography>
                </Td>
                <Td>
                  <div className="text-right">
                    <Typography fontWeight={600} size={12} type="secondary-invert">
                      145,18
                    </Typography>

                    <Typography fontWeight={400} size={10} type="tertiary-invert">
                      145.17599680325
                    </Typography>
                  </div>
                </Td>
                <Td>
                  <Chips label="PLATINUM" removeDot />
                </Td>
                <Td>
                  <p className="text-[12px] font-[600] text-text-secondary-invert">2.521.980</p>
                </Td>
                <Td>
                  <div className="text-right text-[12px] font-[400] text-text-tertiary-invert">
                    <p>
                      Emas Impian: <span className="text-text-secondary-invert font-[600]">12.00</span> gr
                    </p>
                    <p>
                      Dinaran: <span className="text-text-secondary-invert font-[600]">0.00</span> gr
                    </p>
                    <p>
                      User: <span className="text-text-secondary-invert font-[600]">2.00</span> gr
                    </p>
                  </div>
                </Td>
                <Td>
                  <p className="text-[12px] font-[400] text-text-tertiary-invert">2023-05-20 20:02:46</p>
                </Td>
                <Td>
                  <Chips label="Verified" className="leading-4" />
                  <p className="text-[10px] font-[400] text-text-tertiary-invert leading-4">2023-05-22 21:04:56</p>
                </Td>
                <Td>
                  <div className="flex gap-[4px]">
                    <Button iconButton iconType={'lock'} />
                    <Button iconButton iconType={'view'} />
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Button prefixIcon={<FiArrowLeft />} type="secondary" label="Prvious" />
          <Button type="secondary" label="Pagination" />
          <Button suffixIcon={<FiArrowRight />} type="secondary" label="Next" />
        </Tfooter>
      </div>
    </div>
  );
};

export default MicrogoldByATM;
