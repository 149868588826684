import { useEffect } from 'react';
import HistoryTakeGoldPage from '@/delivery/ui/admin/transaction/take_gold/history';
import { HistoryProvider } from '@/delivery/ui/admin/transaction/take_gold/history/context/history_context.tsx';

const ViewHistoryTakeGold = () => {
  useEffect(() => {
    document.title = 'History ambil emas';
  }, []);

  return (
    <div>
      <HistoryProvider>
        <HistoryTakeGoldPage />
      </HistoryProvider>
    </div>
  );
};

export default ViewHistoryTakeGold;
