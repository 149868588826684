import { AiOutlinePlus } from 'react-icons/ai';
import { FiDownloadCloud, FiEye } from 'react-icons/fi';
import { LuLock } from 'react-icons/lu';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { RiForbidLine } from 'react-icons/ri';
import { FaCheck } from 'react-icons/fa6';
import { GrPowerReset } from 'react-icons/gr';
import { FaRegThumbsUp } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { GiHamburgerMenu } from 'react-icons/gi';
import { LuPrinter } from 'react-icons/lu';

const Icon = {
  plus: <AiOutlinePlus />,
  lock: <LuLock />,
  eye: <FiEye />,
  downloadCloud: <FiDownloadCloud />,
  document: <IoDocumentTextOutline />,
  trash: <FiTrash2 />,
  pen: <FiEdit />,
  banned: <RiForbidLine />,
  check: <FaCheck />,
  reset: <GrPowerReset />,
  thumbsUp: <FaRegThumbsUp />,
  close: <IoClose />,
  burger: <GiHamburgerMenu />,
  print: <LuPrinter />
};

export default Icon;
