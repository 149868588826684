import { ApiClient } from '@/main/apiClient';

import { RoleUserCompanyModel } from '@/domain/model/role_user_company_model.ts';
import { RoleUserCompany } from '@/domain/constant/api_list.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import RoleUserCompanyInterface, {
  RoleUserCompanyValue
} from '@/domain/repository/interface/role_user_company_interface.ts';

export class RoleUserCompanyRepository implements RoleUserCompanyInterface {
  api = new ApiClient();
  async fetchRoleUserCompany(data?: RoleUserCompanyValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(RoleUserCompany.FETCH, {
            params: data
          })
          .then(result => {
            const data = RoleUserCompanyModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const uom = {
              data: data,
              pagination: pagination
            };
            resolve(uom);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showRoleUserCompany(data: RoleUserCompanyValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(RoleUserCompany.SHOW, {
            params: data
          })
          .then(result => {
            const obj = RoleUserCompanyModel.fromJson(result.data.data);
            resolve(obj);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeRoleUserCompany(data: RoleUserCompanyValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(RoleUserCompany.STORE, data)
          .then(result => {
            const data = RoleUserCompanyModel.fromJsonList(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkRoleUserCompany(data: RoleUserCompanyValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(RoleUserCompany.STORE_BULK, data)
          .then(result => {
            const data = RoleUserCompanyModel.fromJsonList(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateRoleUserCompany(data: RoleUserCompanyValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(RoleUserCompany.UPDATE + data.uuid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteRoleUserCompany(_uuid: string) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(RoleUserCompany.DELETE + _uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
