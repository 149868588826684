import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';
import DialogUpdateAddress from '@/delivery/ui/admin/data_member/member_list/detail/tabs/data_profile/dialog/dialog_update_data_address.tsx';

const TableSenderAddress = () => {
  const { handleEditAddressModal, handleShowAddress, userAddress } = useMemberListContext();

  return (
    <>
      <Table noBorder>
        <Thead>
          <Tr noBorder>
            <Th noBorder>
              <Typography fontWeight={600} size={14} type="primary-invert">
                Alamat Pengiriman
              </Typography>
            </Th>
            <Th noBorder>
              <div className={'justify-end flex'}>
                <span
                  className={'text-text-primary font-[600] text-[14px] cursor-pointer'}
                  onClick={() => {
                    if (userAddress?.address) handleShowAddress(userAddress?.address);
                  }}
                >
                  Edit
                </span>
              </div>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {/*provinsi*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Provinsi
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userAddress?.address?.province?.name}
              </Typography>
            </Td>
          </Tr>

          {/*kabupaten*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Kabupaten
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userAddress?.address?.city?.name}
              </Typography>
            </Td>
          </Tr>

          {/*Kecamatan*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Kecamatan
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userAddress?.address?.district?.name}
              </Typography>
            </Td>
          </Tr>

          {/*kelurahan*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Kelurahan
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userAddress?.address?.village?.name}
              </Typography>
            </Td>
          </Tr>

          {/*alamat*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Alamat
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userAddress?.address?.fullAddress}
              </Typography>
            </Td>
          </Tr>

          {/*Kode pos*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Kode POS
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userAddress?.address?.zipCode}
              </Typography>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      {handleEditAddressModal.state && <DialogUpdateAddress modal={handleEditAddressModal} />}
    </>
  );
};

export default TableSenderAddress;
