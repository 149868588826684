import { useEffect } from 'react';
import SettlementPage from '@/delivery/ui/admin/transaction/settlement';
import { SettlementProvider } from '@/delivery/ui/admin/transaction/settlement/context/settlement_context.tsx';

const ViewSettlement = () => {
  useEffect(() => {
    document.title = 'Settlement';
  }, []);

  return (
    <div>
      <SettlementProvider>
        <SettlementPage />
      </SettlementProvider>
    </div>
  );
};

export default ViewSettlement;
