import Button from '@/delivery/components/atoms/button/button';
import Pagination from '@/delivery/components/atoms/pagination/pagination';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Typography from '@/delivery/components/atoms/typography/typography';
import { Table, Tbody, Td, Th, Thead, Theader, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import { useRoleeContext } from '@/delivery/ui/admin/settings/admin_access/context/role_context.tsx';
import { useEffect } from 'react';
import { RoleModel } from '@/domain/model/role_model.ts';
import Icon from '@/domain/constant/icon.tsx';

const RoleTab = () => {
  const { handleAddRoleModal, handleFetchRole, roles, rolePagination, handleShowRole } = useRoleeContext();

  useEffect(() => {
    handleFetchRole({});
  }, []);

  return (
    <div className="lg:w-1/2 w-full">
      <Theader>
        <TextField search placeholder="Search" />
        <Button prefixIcon={Icon.plus} label="Add Role" onClick={() => handleAddRoleModal.setState(true)} />
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Group</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {roles.map((item: RoleModel, index) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.name}
                </Typography>
              </Td>
              <Td>
                <div className="flex gap-[4px]">
                  {/*<Button iconButton iconType={'delete'} onClick={() => handleDeleteRole(item)} />*/}
                  <Button iconButton iconType={'edit'} onClick={() => handleShowRole(item)} />
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={rolePagination?.page || 1}
          totalPages={rolePagination?.totalPages || 1}
          handlePagination={page => {
            handleFetchRole({
              page: page
            });
          }}
        />
      </Tfooter>
    </div>
  );
};

export default RoleTab;
