import { BaseModel } from '@/domain/model/base_model.ts';
import { OfflineCounterUnavailabilityModel } from '@/domain/model/offline_counter_unavailability_model.ts';
import { formatTime } from '@/infrastructure/helper/formatDate.ts';
import { RegionDistrictModel } from '@/domain/model/region_district_model.ts';
import { RegionProvinceModel } from '@/domain/model/region_province_model.ts';
import { RegionCityModel } from '@/domain/model/region_city_model.ts';
import { RegionVillageModel } from '@/domain/model/region_village_model.ts';

export class OfflineCounterModel extends BaseModel {
  name: string;
  pic: string;
  phone: string;
  email: string;
  status: string;
  days: string[];
  startHour: string;
  endHour: string;
  countryID: string;
  country: string;
  provinceID: string;
  province: RegionProvinceModel;
  cityID: string;
  city: RegionCityModel;
  districtID: string;
  district: RegionDistrictModel;
  unavailability: OfflineCounterUnavailabilityModel[];
  nationalID: string;
  villageID: string;
  fullAddress: string;
  zipCode: string;
  availability: boolean;
  village: RegionVillageModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    name: string,
    pic: string,
    phone: string,
    email: string,
    status: string,
    days: string[],
    startHour: string,
    endHour: string,
    countryID: string,
    country: string,
    provinceID: string,
    province: RegionProvinceModel,
    cityID: string,
    city: RegionCityModel,
    districtID: string,
    district: RegionDistrictModel,
    unavailability: OfflineCounterUnavailabilityModel[],
    nationalID: string,
    villageID: string,
    fullAddress: string,
    zipCode: string,
    availability: boolean,
    village: RegionVillageModel
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.name = name;
    this.pic = pic;
    this.phone = phone;
    this.email = email;
    this.status = status;
    this.days = days;
    this.startHour = startHour;
    this.endHour = endHour;
    this.countryID = countryID;
    this.country = country;
    this.provinceID = provinceID;
    this.province = province;
    this.cityID = cityID;
    this.city = city;
    this.districtID = districtID;
    this.district = district;
    this.unavailability = unavailability;
    this.nationalID = nationalID;
    this.villageID = villageID;
    this.fullAddress = fullAddress;
    this.zipCode = zipCode;
    this.availability = availability;
    this.village = village;
  }

  get getStartHour(): string {
    if (this.startHour) {
      return formatTime(this.startHour);
    }

    return '-';
  }

  get getEndHour(): string {
    if (this.endHour) {
      return formatTime(this.endHour);
    }

    return '-';
  }

  get getFullAddress(): string {
    if (this.province) {
      return `${this.village.name}, ${this.district.name}, ${this.city.name}, ${this.province.name},  ${this.zipCode}`;
    }

    return '-';
  }

  public static fromJson(data: any): OfflineCounterModel | any {
    try {
      return new OfflineCounterModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.name,
        data.pic,
        data.phone,
        data.email,
        data.status,
        data.days,
        data.start_hour,
        data.end_hour,
        data.country_id,
        data.country_name,
        data.province_id,
        data.province ? RegionProvinceModel.fromJson(data.province) : null,
        data.city_id,
        data.city ? RegionCityModel.fromJson(data.city) : null,
        data.district_id,
        data.district ? RegionDistrictModel.fromJson(data.district) : null,
        data.unavailability ? OfflineCounterUnavailabilityModel.fromJsonList(data.unavailability) : [],
        data.national_id,
        data.village_id,
        data.full_address,
        data.zip_code,
        data.availability,
        data.village ? RegionVillageModel.fromJson(data.village) : null
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): OfflineCounterModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(OfflineCounterModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
