import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
// import Select from '@/delivery/components/atoms/select/select.tsx';

import { STATUS_LIST } from '@/domain/constant/status.ts';
import { useOpenChannelContext } from '@/delivery/ui/admin/settings/open_channel/context/open_channel_context.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import Dropzone from '@/delivery/components/atoms/dropzone/dropzone.tsx';
import { useEffect } from 'react';
import { PAYMENT_MANUAL_CODE } from '@/domain/constant/payment/payment_manual_code.ts';

const DialogAddPaymentManual = (props: ModalDialogProps) => {
  const {
    paymentMethod,
    handleShowPaymentMethodManual,
    formManualPayment,
    setFormManualPayment,
    handleAddManualPayment
  } = useOpenChannelContext();

  useEffect(() => {
    handleShowPaymentMethodManual({ code: PAYMENT_MANUAL_CODE });
  }, []);

  useEffect(() => {
    if (paymentMethod) {
      setFormManualPayment({
        ...formManualPayment,
        payment_method_uid: paymentMethod?.UID
      });
    }
  }, [paymentMethod]);

  return (
    <ModalDialog size="medium" title="Add Channel Manual" modal={props.modal}>
      <div className={'flex gap-[20px]'}>
        <div className="flex flex-col gap-[20px] w-full">
          <TextField disabled={true} value={paymentMethod?.name} label="Method" placeholder="Method" block />

          <TextField
            label="Channel Name"
            placeholder="Channel Name"
            block
            onChange={e => {
              setFormManualPayment({
                ...formManualPayment,
                name: e.target.value
              });
            }}
          />
          <TextField
            label="Code"
            placeholder="Code"
            block
            onChange={e => {
              setFormManualPayment({
                ...formManualPayment,
                code: e.target.value
              });
            }}
          />

          <Dropzone
            label={'Image Icon'}
            onChange={value => {
              setFormManualPayment({
                ...formManualPayment,
                media_uid: value
              });
            }}
          />

          <Textarea
            label="Description"
            placeholder="Description"
            block
            onChange={e => {
              setFormManualPayment({
                ...formManualPayment,
                description: e.target.value
              });
            }}
          />

          <Select2
            label={'Status'}
            options={STATUS_LIST.map(item => {
              return {
                label: item.name,
                value: item.UUID
              };
            })}
            onChange={(e: any) => {
              setFormManualPayment({
                ...formManualPayment,
                status: e.value
              });
            }}
          />
        </div>
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button label="Save" onClick={() => handleAddManualPayment()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddPaymentManual;
