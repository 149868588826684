import { BaseModel } from '@/domain/model/base_model.ts';

export class RegionProvinceModel extends BaseModel {
  parentID: number;
  code: string;
  name: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    parentID: number,
    code: string,
    name: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.parentID = parentID;
    this.code = code;
    this.name = name;
  }

  public static fromJson(data: any): RegionProvinceModel | any {
    return new RegionProvinceModel(
      data.id,
      data.uid,
      data.uuid,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by,
      data.parent_id,
      data.code,
      data.name
    );
  }

  public static fromJsonList(data: any): RegionProvinceModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(RegionProvinceModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
