import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { AffiliateRepository } from '@/domain/repository/affiliate_repository.ts';
import { AffiliateModel } from '@/domain/model/affiliate_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';

interface DialogContextProps {
  children: ReactNode;
}

interface AffiliateValueParams {
  page?: number;
  limit?: number;
  name?: string;
}

interface FormAffiliateInterface {
  uid?: string;
  commission?: number;
  commission_type?: string;
  type?: string;
}

interface AffiliateValue {
  formAffiliate: FormAffiliateInterface;
  setFormAffiliate: Dispatch<SetStateAction<FormAffiliateInterface>>;
  // affiliate: FormAffiliateInterface | undefined;
  affiliates: AffiliateModel[];
  affiliatePagination?: PaginationModel;
  handleAddAffiliateModal: ModalObject;
  handleUpdateAffiliateModal: ModalObject;
  handleFetchAffiliate: (_data: AffiliateValueParams) => void;
  handleShowAffiliate: (_data: AffiliateModel) => void;
  handleUpdateAffiliate: () => void;
  handleAddAffiliate: () => void;
  handleDeleteAffiliate: (_data: AffiliateModel) => void;
}

const AffiliateContext = createContext<AffiliateValue | null>(null);

const affiliateRepository = new AffiliateRepository();

const AffiliateProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const handleAddAffiliateModal = useVisibleComponent(false);
  const handleUpdateAffiliateModal = useVisibleComponent(false);

  // const [affiliate, setAffiliate] = useState<AffiliateModel>();
  const [affiliates, setAffiliates] = useState<AffiliateModel[]>([]);
  const [affiliatePagination, setAffiliatePagination] = useState<PaginationModel>();

  const [formAffiliate, setFormAffiliate] = useState<FormAffiliateInterface>({});

  //FETCH AFFILIATE
  const handleFetchAffiliate = (_data: AffiliateValueParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data?.page ?? 1,
      limit: _data?.limit ?? 10,
      name: _data?.name ?? null
    };

    affiliateRepository
      .fetchAffiliate(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setAffiliates(result.data);
        setAffiliatePagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW AFFILIATE
  const handleShowAffiliate = (_data: AffiliateModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };

    affiliateRepository
      .showAffiliate(params)
      .then((res: any) => {
        const result: AffiliateModel = res;
        alertToast.updateLoading(x);
        setFormAffiliate({
          uid: result.UID,
          type: result.type,
          commission: result.commission,
          commission_type: result.commissionType
        });
        handleUpdateAffiliateModal.setState(true);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //UPDATE AFFILIATE
  const handleUpdateAffiliate = () => {
    const x = alertToast.loadingAlert('');

    affiliateRepository
      .updateAffiliate(formAffiliate)
      .then(() => {
        alertToast.updateLoading(x);
        handleFetchAffiliate({ page: 1 });
        handleUpdateAffiliateModal.setState(false);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //ADD AFFILIATE
  const handleAddAffiliate = () => {
    const x = alertToast.loadingAlert('');

    affiliateRepository
      .storeAffiliate(formAffiliate)
      .then(() => {
        alertToast.updateLoading(x);
        alertToast.successAlert();
        handleAddAffiliateModal.setState(false);
        handleFetchAffiliate({ page: 1 });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //DELETE AFFILIATE
  const handleDeleteAffiliate = (_data: AffiliateModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const params = {
          uid: _data.UID
        };
        affiliateRepository
          .deleteAffiliate(params)
          .then(() => {
            alertToast.successAlert();
            handleFetchAffiliate({ page: 1 });
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: AffiliateValue = {
    formAffiliate,
    setFormAffiliate,
    // affiliate,
    affiliates,
    affiliatePagination,
    handleFetchAffiliate,
    handleAddAffiliateModal,
    handleUpdateAffiliateModal,
    handleShowAffiliate,
    handleUpdateAffiliate,
    handleAddAffiliate,
    handleDeleteAffiliate
  };

  return <AffiliateContext.Provider value={contextValue}>{children}</AffiliateContext.Provider>;
};

const useAffiliateContext = (): AffiliateValue => {
  const context = useContext(AffiliateContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a AffiliateProvider');
  }
  return context;
};

export { AffiliateProvider, useAffiliateContext };
