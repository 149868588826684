import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Typography from '@/delivery/components/atoms/typography/typography';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import { useAuthContext } from '../context/auth_context';
import { useState } from 'react';

const DialogForgotPassword = (props: ModalDialogProps) => {
  const { handleResetPassword, handleForgotPasswordModal } = useAuthContext();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  return (
    <ModalDialog title="Forgot Password" modal={props.modal}>
      <div className="mb-[24px]">
        <Typography size={12} fontWeight={400}>
          Silahkan masukan alamat email yang terdaftar <br />
          klik link yang diterima pada email untuk melakukan reset password dan silahkan login kembali
        </Typography>
      </div>
      <div className="flex flex-col gap-[20px] w-1/2">
        <TextField block placeholder="email" onChange={e => setEmail(e.target.value)} />
        <TextField block placeholder="password" type="password" onChange={e => setPassword(e.target.value)} />
      </div>
      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleForgotPasswordModal.setState(false)} />
        <Button
          label="Submit"
          onClick={() =>
            handleResetPassword({
              email: email,
              password: password
            })
          }
        />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogForgotPassword;
