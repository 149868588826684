import useAlertToast from '@/delivery/hooks/useAlertComponent';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { UserStatisticModel } from '@/domain/model/user_statistic_model.ts';
import { UserStatisticRepository } from '@/domain/repository/user_statistic_repository.ts';
import { TransactionStatisticModel } from '@/domain/model/transaction_statistic_model.ts';
import { MemberChartModel } from '@/domain/model/dashboard/member_chart_model.ts';
import { MemberChartRepository } from '@/domain/repository/dashboard/member_chart_repository.ts';
import { MemberChartMonthlyModel } from '@/domain/model/dashboard/member_chart_model_monthly.ts';

interface DashboardMemberContextProps {
  children: ReactNode;
}

interface FormDashboardMember {
  start_date?: string;
  end_date?: string;
  type?: string;
}

interface DashboardMemberContextValue {
  memberRegisterChartMonthly: MemberChartMonthlyModel[];
  formDashboardMember: FormDashboardMember;
  setFormDashboardMember: Dispatch<SetStateAction<FormDashboardMember>>;
  memberRegisterChart: MemberChartModel[];
  usersNumberRequest: number;
  usersNumberUserNonTransaction: number;
  usersNumberUserTransaction: number;
  loadingUserUserTransaction: boolean;
  usersNumberUnverified: number;
  loadingUserUnverified: boolean;
  usersNumberVerified: number;
  loadingUserVerified: boolean;
  usersNumberTotalNotActive: number;
  usersNumberTotalActive: number;
  numberTotalTransaction: number;
  loadingNumberTransaction: boolean;
  usersNumberTotalRegister: number;
  loadingUserRegister: boolean;
  handleFetchUserAsNumberTotalRegister: () => void;
  handleFetchTransactionAsNumberTotalTransaction: () => void;
  handleFetchUserAsNumberVerifiedUser: () => void;
  handleFetchUserAsNumberTransactionUser: () => void;
  handleRegisterDailyChart: () => Promise<void>;
  handleRegisterMonthlyChart: () => Promise<void>;
}

const StockGoldContext = createContext<DashboardMemberContextValue | null>(null);

const userStatistic = new UserStatisticRepository();
const memberRegisterChartRepository = new MemberChartRepository();

const DashboardMemberProvider: React.FC<DashboardMemberContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  //user
  const [usersNumberTotalRegister, setUsersNumberTotalRegister] = useState<number>(0);
  const [loadingUserRegister, setLoadingUserRegister] = useState<boolean>(false);

  //get count user active
  const [usersNumberTotalActive, setUsersNumberTotalActive] = useState<number>(0);

  //get count user not active
  const [usersNumberTotalNotActive, setUsersNumberTotalNotActive] = useState<number>(0);

  //get count user verified
  const [usersNumberVerified, setUsersNumberVerified] = useState<number>(0);
  const [loadingUserVerified, setLoadingUserVerified] = useState<boolean>(false);

  //get count user request
  const [usersNumberRequest, setUsersNumberRequest] = useState<number>(0);
  //get count user unverified
  const [usersNumberUnverified, setUsersNumberUnverified] = useState<number>(0);
  const [loadingUserUnverified, setLoadingUserUnverified] = useState<boolean>(false);

  //get count transaction user
  const [usersNumberUserTransaction, setUsersNumberUserTransaction] = useState<number>(0);
  const [usersNumberUserNonTransaction, setUsersNumberUserNonTransaction] = useState<number>(0);
  const [loadingUserUserTransaction, setLoadingUserUserTransaction] = useState<boolean>(false);

  //transaction
  const [numberTotalTransaction, setNumberTotalTransaction] = useState<number>(0);
  const [loadingNumberTransaction, setLoadingNumberTransaction] = useState<boolean>(false);

  //data chart
  const [memberRegisterChart, setMemberRegisterChart] = useState<MemberChartModel[]>([]);
  const [memberRegisterChartMonthly, setMemberRegisterChartMonthly] = useState<MemberChartMonthlyModel[]>([]);

  //form
  const [formDashboardMember, setFormDashboardMember] = useState<FormDashboardMember>({});

  //COUNT USERS
  const handleFetchUserAsNumberTotalRegister = () => {
    setLoadingUserRegister(true);

    userStatistic
      .countUserStatus()
      .then((result: any) => {
        const res: UserStatisticModel = result;
        setLoadingUserRegister(false);

        setUsersNumberTotalRegister(res.active + res.inactive);
        setUsersNumberTotalActive(res.active);
        setUsersNumberTotalNotActive(res.inactive);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //COUNT VERIFIED USER
  const handleFetchUserAsNumberVerifiedUser = async () => {
    setLoadingUserVerified(true);

    try {
      // First async operation
      const result: any = await userStatistic.countUserVerification();
      const res: UserStatisticModel = result;

      // Second async operation
      const resultUser: any = await userStatistic.countUserStatus();
      const resUser: UserStatisticModel = resultUser;

      // Calculate userUnverified
      const totalUser = resUser?.active + resUser?.inactive;
      const userUnverified = totalUser - res?.verified;

      // Update state
      setUsersNumberVerified(res.verified);
      setUsersNumberRequest(res.requested);
      setUsersNumberUnverified(userUnverified);

      // Update loading state
      setLoadingUserVerified(false);
      setLoadingUserUnverified(false);
    } catch (error: any) {
      setLoadingUserVerified(false);
      alertToast.errorAlert(error);
    }
  };

  //COUNT USER TRANSACTION
  const handleFetchUserAsNumberTransactionUser = async () => {
    setLoadingUserUserTransaction(true);

    userStatistic
      .countUserTransaction()
      .then((result: any) => {
        const res: UserStatisticModel = result;
        setLoadingUserUserTransaction(false);
        setUsersNumberUserTransaction(res.userTransaction);
        setUsersNumberUserNonTransaction(res.userWithoutTransaction);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      })
      .finally(() => {
        setLoadingUserUserTransaction(false);
      });
  };

  //COUNT TRANSACTION
  const handleFetchTransactionAsNumberTotalTransaction = () => {
    setLoadingNumberTransaction(true);

    userStatistic
      .countTransaction()
      .then((result: any) => {
        const res: TransactionStatisticModel[] = result;

        const totalCount = res.reduce((sum, item) => sum + item.count, 0);
        setNumberTotalTransaction(totalCount);
        setLoadingNumberTransaction(false);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //MEMBER CHART DAILY
  const handleRegisterDailyChart = async () => {
    const params = {
      date_range: formDashboardMember.start_date
        ? [`${formDashboardMember.start_date}`, `${formDashboardMember.end_date}`]
        : null
    };

    memberRegisterChartRepository
      .statisticRegisterDaily(params)
      .then((result: any) => {
        setMemberRegisterChart(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //MEMBER CHART MONTHLY
  const handleRegisterMonthlyChart = async () => {
    const params = {
      date_range: formDashboardMember.start_date
        ? [`${formDashboardMember.start_date}`, `${formDashboardMember.end_date}`]
        : null
    };

    memberRegisterChartRepository
      .statisticRegisterMonthly(params)
      .then((result: any) => {
        setMemberRegisterChartMonthly(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const contextValue: DashboardMemberContextValue = {
    formDashboardMember,
    setFormDashboardMember,
    memberRegisterChartMonthly,
    memberRegisterChart,
    usersNumberRequest,
    usersNumberUserTransaction,
    loadingUserUserTransaction,
    usersNumberUnverified,
    loadingUserUnverified,
    usersNumberVerified,
    loadingUserVerified,
    usersNumberTotalNotActive,
    usersNumberTotalActive,
    numberTotalTransaction,
    loadingNumberTransaction,
    usersNumberTotalRegister,
    loadingUserRegister,
    usersNumberUserNonTransaction,
    handleFetchUserAsNumberTotalRegister,
    handleFetchTransactionAsNumberTotalTransaction,
    handleFetchUserAsNumberVerifiedUser,
    handleFetchUserAsNumberTransactionUser,
    handleRegisterDailyChart,
    handleRegisterMonthlyChart
  };

  return <StockGoldContext.Provider value={contextValue}>{children}</StockGoldContext.Provider>;
};

const useDashboardMemberContext = (): DashboardMemberContextValue => {
  const context = useContext(StockGoldContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a DashboardMemberProvider');
  }
  return context;
};

export { DashboardMemberProvider, useDashboardMemberContext };
