import Button from '@/delivery/components/atoms/button/button';
import Chips from '@/delivery/components/atoms/chips/chips';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Typography from '@/delivery/components/atoms/typography/typography';
import { Table, Tbody, Td, Tfooter, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table';

import { MdEventAvailable } from 'react-icons/md';
import { useCounterOfflineContext } from '@/delivery/ui/admin/transaction/take_gold/counter_offline/context/counter_offline_context.tsx';
import { useEffect } from 'react';
import DialogUpdateCounter from '@/delivery/ui/admin/transaction/take_gold/counter_offline/dialog/dialog_update_offline_counter.tsx';
import DialogAddCounterAvailability from '@/delivery/ui/admin/transaction/take_gold/counter_offline/dialog/counter_unavailability/dialog_counter_unavailability.tsx';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { STATUS_CONST } from '@/domain/constant/status.ts';
import { AVAILABILITY_STATUS } from '@/domain/constant/offline_counter/availability_status.ts';
import { OfflineCounterModel } from '@/domain/model/offline_counter_model.ts';

const CounterOffline = () => {
  const {
    handleDeleteCounterOffLine,
    handleShowCounterOffLine,
    handleUpdateCounterOfflineModal,
    handleFetchOfflineCounter,
    offlineCounters,
    handleAddDialogCounterUnavailability,
    handleAddCounterUnavailabilityModal,
    offlineCounterPagination,
    filterOfflineCounter,
    setFilterOfflineCounter
  } = useCounterOfflineContext();

  useEffect(() => {
    handleFetchOfflineCounter({});
  }, []);

  useEffect(() => {
    if (filterOfflineCounter) {
      handleFetchOfflineCounter({});
    }
  }, [filterOfflineCounter]);

  return (
    <div>
      <Theader>
        <TextField
          search
          placeholder="Search"
          onChange={e => setFilterOfflineCounter({ ...filterOfflineCounter, search: e.target.value })}
        />
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Nama Counter</Th>
            <Th>PIC</Th>
            <Th>Alamat</Th>
            <Th>Availability</Th>
            <Th>Status</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {offlineCounters?.map((item: OfflineCounterModel, index) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {item.name}
                </Typography>
              </Td>
              <Td>
                <Typography tag="span" fontWeight={400} size={12} type="primary-invert">
                  {item.pic}
                </Typography>
              </Td>
              <Td>
                <div className={'flex flex-col gap-2'}>
                  <Typography tag="span" fontWeight={400} size={12} type="primary-invert">
                    {item?.getFullAddress}
                  </Typography>
                  <Typography tag="span" fontWeight={400} size={12} type="primary-invert">
                    {item?.fullAddress}
                  </Typography>
                </div>
              </Td>
              <Td>
                <Chips
                  removeDot
                  label={item.availability ? AVAILABILITY_STATUS.AVAILABLE : AVAILABILITY_STATUS.UNAVAILABLE}
                  type={item.availability ? 'primary' : 'danger'}
                />
              </Td>
              <Td>
                <Chips
                  label={item.status}
                  type={item.status === STATUS_CONST.ACTIVE ? 'primary-light' : 'danger-light'}
                />
              </Td>
              <Td>
                <div className="flex gap-[4px]">
                  <Button
                    iconButton
                    icon={<MdEventAvailable />}
                    onClick={() => handleAddDialogCounterUnavailability({ uid: item.UID })}
                  />
                  <Button iconButton iconType={'delete'} onClick={() => handleDeleteCounterOffLine(item)} />
                  <Button iconButton iconType={'edit'} onClick={() => handleShowCounterOffLine(item)} />
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={offlineCounterPagination?.page || 1}
          totalPages={offlineCounterPagination?.totalPages || 1}
          handlePagination={page => {
            handleFetchOfflineCounter({
              page: page
            });
          }}
        />
      </Tfooter>

      {handleAddCounterUnavailabilityModal.state && (
        <DialogAddCounterAvailability modal={handleAddCounterUnavailabilityModal} />
      )}
      {handleUpdateCounterOfflineModal.state && <DialogUpdateCounter modal={handleUpdateCounterOfflineModal} />}
    </div>
  );
};

export default CounterOffline;
