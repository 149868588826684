import VendingMachineInterface, {
  VendingMachineValue
} from '@/domain/repository/interface/vending_machine_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { VendingMachineModel } from '@/domain/model/vending_machine_model.ts';
import { VendingMachine } from '@/domain/constant/api_list';

export class VendingMachineRepository implements VendingMachineInterface {
  api = new ApiClient();

  async fetchVendingMachine(data: VendingMachineValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(VendingMachine.FETCH, {
            params: data
          })
          .then(result => {
            const data = VendingMachineModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showVendingMachine(data: VendingMachineValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(VendingMachine.SHOW, {
            params: data
          })
          .then(result => {
            const data = VendingMachineModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeVendingMachine(data: VendingMachineValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(VendingMachine.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkVendingMachine(data: VendingMachineValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(VendingMachine.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateVendingMachine(data: VendingMachineValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(VendingMachine.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteVendingMachine(data: VendingMachineValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(VendingMachine.DELETE + data.uid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
