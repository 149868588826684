import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Chips from '@/delivery/components/atoms/chips/chips';
import Pagination from '@/delivery/components/atoms/pagination/pagination';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useTransactionListContext } from '@/delivery/ui/admin/transaction/transaction_list/context/transaction_list_context.tsx';
import { useEffect } from 'react';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import DialogDetailTransaction from '@/delivery/ui/admin/transaction/transaction_list/dialog/dialog_detail_transaction.tsx';
import FilterTransactionList from '@/delivery/ui/admin/transaction/transaction_list/filter_transaction_list.tsx';
import Icon from '@/domain/constant/icon.tsx';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';
import { DBTransaction } from '@/infrastructure/db/transaction_db.ts';

const TransactionList = () => {
  const db = new DBTransaction();
  const {
    handleSendICDXTransaction,
    handleFetchTransactionList,
    transactionLists,
    transactionListPagination,
    handleShowTransaction,
    handleDetailTransactionListModal,
    handleCancelTransaction
  } = useTransactionListContext();

  //INITIALING DATABASE LOCAL
  useEffect(() => {
    // db.transactionExport.add({
    //   name: 'deny',
    //   type: 'buy',
    //   reversal: 'reversal',
    //   email: 'denyekayana@gmail.com',
    //   trx_id: 'DIN-123'
    // });

    db.transactionExport.delete(1);
    db.transactionExport.clear();
  }, []);

  useEffect(() => {
    handleFetchTransactionList({});
  }, []);

  return (
    <div>
      <div>
        <div className={'flex justify-end mb-[20px]'}>
          <FilterTransactionList />
        </div>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>TrxID</Th>
              <Th>Member</Th>
              <Th>Type</Th>
              <Th>Reversal</Th>
              {/*<Th>Pengirim</Th>*/}
              {/*<Th>Penerima</Th>*/}
              <Th>Status</Th>
              <Th>ICDX Send</Th>
              <Th>
                <span className={'whitespace-nowrap'}>Trx Date</span>
              </Th>
              <Th>
                <span className={'whitespace-nowrap'}>Paid Date</span>
              </Th>
              <Th>Paid Amount</Th>
              <Th>Fee</Th>
              <Th>Vendor</Th>
              <Th>Vendor Ref ID</Th>
              <Th>Gramasi</Th>
              <Th>Gold Rate</Th>
              <Th>Update By</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactionLists?.map((item: TransactionModel, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.code}
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {item.userEmail?.name}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.userEmail?.email}
                  </Typography>
                </Td>
                <Td>
                  <span className={'whitespace-nowrap'}>
                    <Chips label={item.type} removeDot />
                  </span>
                </Td>
                <Td>
                  {item.flags?.includes(TYPE_TRANSACTION.REVERSAL) ? (
                    <span className={'whitespace-nowrap'}>
                      <Chips type={'danger-light'} label={item.flags?.join(TYPE_TRANSACTION.REVERSAL)} removeDot />
                    </span>
                  ) : (
                    '-'
                  )}
                </Td>
                {/*<Td>*/}
                {/*  <Typography fontWeight={600} size={12} type="primary-invert">*/}
                {/*    {item?.senderName || ''}*/}
                {/*  </Typography>*/}
                {/*  <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*    {item?.senderEmail || ''}*/}
                {/*  </Typography>*/}
                {/*</Td>*/}
                {/*<Td>*/}
                {/*  <Typography fontWeight={600} size={12} type="primary-invert">*/}
                {/*    {item?.receiverName || ''}*/}
                {/*  </Typography>*/}
                {/*  <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*    {item?.receiverEmail || ''}*/}
                {/*  </Typography>*/}
                {/*</Td>*/}
                <Td>
                  {item.flags && item.type === TYPE_TRANSACTION.REVERSAL ? (
                    <Chips label={`${item.status} ${item.type}`} type={item.getStatusColor} />
                  ) : (
                    <Chips label={item.status} type={item.getStatusColor} />
                  )}
                </Td>
                <Td>
                  <div className={'whitespace-nowrap'}>
                    <Chips
                      removeDot
                      label={item.flags?.includes('ICDX_SYNCED') ? 'SENT' : 'NOT SENT'}
                      type={item.flags?.includes('ICDX_SYNCED') ? 'primary' : 'danger'}
                    />
                  </div>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    <span className={'whitespace-nowrap'}>{item.getTransactionAt}</span>
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    <span className={'whitespace-nowrap'}>{item.getSucceedAt}</span>
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getAmountRupiah}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getFeeRupiah}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.payment?.vendor}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.payment?.vendorRefID}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.amount?.gram}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="p" fontWeight={600} size={12} type="tertiary-invert">
                    {item.rate.getBuy}
                  </Typography>
                  <Typography tag="p" fontWeight={400} size={12} type="tertiary-invert">
                    {item.rate.getSell}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.userUpdate?.name}
                  </Typography>
                </Td>
                <Td>
                  <div className="flex gap-[4px]">
                    <Button iconButton iconType={'view'} onClick={() => handleShowTransaction(item)} />
                    <Button iconButton iconType={'banned'} onClick={() => handleCancelTransaction(item)} />
                    {item.type === TYPE_TRANSACTION.BUY || item.type === TYPE_TRANSACTION.SELL ? (
                      item.flags?.includes('ICDX_SYNCED') ? (
                        <Button
                          disabled
                          iconButton
                          icon={Icon.thumbsUp}
                          onClick={() => handleSendICDXTransaction(item)}
                        />
                      ) : (
                        <Button iconButton icon={Icon.thumbsUp} onClick={() => handleSendICDXTransaction(item)} />
                      )
                    ) : (
                      ''
                    )}
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            page={transactionListPagination?.page || 1}
            totalPages={transactionListPagination?.totalPages || 1}
            handlePagination={page => {
              handleFetchTransactionList({
                page: page
              });
            }}
          />
        </Tfooter>
      </div>

      {handleDetailTransactionListModal.state && <DialogDetailTransaction modal={handleDetailTransactionListModal} />}
    </div>
  );
};

export default TransactionList;
