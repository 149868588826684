import { BaseModel } from './base_model';
import { formatDateTime } from '@/infrastructure/helper/formatDate.ts';
import { UserModel } from '@/domain/model/user_model.ts';
import { IMAGE_URL } from '@/domain/constant/image_url.ts';
import { ChipType } from '@/domain/constant/component/chip_colors.ts';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';
import { MasterBankModel } from '@/domain/model/master_bank_model.ts';

export class UserWithdrawalAccountModel extends BaseModel {
  userUID: string;
  refUserVerification: string;
  isDefault: boolean;
  status: string;
  masterBankUUID: string;
  type: string;
  accountNumber: string;
  accountHolderName: string;
  notes: string;
  mediaUID: string;
  user: UserModel;
  masterBank: MasterBankModel;
  userUpdate: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    userUID: string,
    refUserVerification: string,
    isDefault: boolean,
    status: string,
    masterBankUUID: string,
    type: string,
    accountNumber: string,
    accountHolderName: string,
    notes: string,
    mediaUID: string,
    user: UserModel,
    masterBank: MasterBankModel,
    userUpdate: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.userUID = userUID;
    this.refUserVerification = refUserVerification;
    this.isDefault = isDefault;
    this.status = status;
    this.masterBankUUID = masterBankUUID;
    this.type = type;
    this.accountNumber = accountNumber;
    this.accountHolderName = accountHolderName;
    this.notes = notes;
    this.mediaUID = mediaUID;
    this.user = user;
    this.masterBank = masterBank;
    this.userUpdate = userUpdate;
  }

  get getCreatedAt(): string {
    if (this.createdAt) {
      return formatDateTime(this.createdAt);
    }

    return '-';
  }

  get getMediaUrl(): string {
    if (this.mediaUID) {
      return `${IMAGE_URL}/${this.mediaUID}`;
    }
    return '#';
  }

  get getStatusColor(): ChipType {
    if (this.status === STATUS_CORPORATE.VERIFIED) {
      return 'primary-light';
    }

    if (this.status === STATUS_CORPORATE.REQUEST) {
      return 'warning-light';
    }

    if (this.status === STATUS_CORPORATE.REJECT) {
      return 'danger-light';
    }
  }

  public static fromJson(data: any): any | UserWithdrawalAccountModel {
    try {
      return new UserWithdrawalAccountModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.user_uid,
        data.ref_user_verification,
        data.is_default,
        data.status,
        data.master_bank_uuid,
        data.type,
        data.account_number,
        data.account_holder_name,
        data.notes,
        data.media_uid,
        data.user ? UserModel.fromJson(data.user) : null,
        data.master_bank ? MasterBankModel.fromJson(data.master_bank) : null,
        data.updated_by_user ? data.updated_by_user.first_name : ''
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): UserWithdrawalAccountModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(UserWithdrawalAccountModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
