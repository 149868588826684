import GoldPieceFeeInterface, { GoldPieceFeeValue } from '@/domain/repository/interface/gold_piece_fee_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { GoldPieceFeeModel } from '@/domain/model/gold_piece_fee_model.ts';
import { GoldPieceFee } from '@/domain/constant/api_list';

export class GoldPieceFeeRepository implements GoldPieceFeeInterface {
  api = new ApiClient();

  async fetchGoldPieceFee(data: GoldPieceFeeValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(GoldPieceFee.FETCH, {
            params: data
          })
          .then(result => {
            const data = GoldPieceFeeModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showGoldPieceFee(data: GoldPieceFeeValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(GoldPieceFee.SHOW, {
            params: data
          })
          .then(result => {
            const data = GoldPieceFeeModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeGoldPieceFee(data: GoldPieceFeeValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(GoldPieceFee.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkGoldPieceFee(data: GoldPieceFeeValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(GoldPieceFee.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateGoldPieceFee(data: GoldPieceFeeValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(GoldPieceFee.UPDATE + data.uuid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteGoldPieceFee(data: GoldPieceFeeValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(GoldPieceFee.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
