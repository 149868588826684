import TransactionPrintInterface, {
  TransactionPrintValue
} from '@/domain/repository/interface/transaction_print_interface.ts';
import { ApiClient } from '@/main/apiClient';
// import { TransactionPrintBalanceModel } from '@/domain/model/user_dashboard_balance.ts';
import { TransactionPrint } from '@/domain/constant/api_list';

export class TransactionPrintRepository implements TransactionPrintInterface {
  api = new ApiClient();

  async transactionPrintConfirmation(data: TransactionPrintValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(TransactionPrint.CONFIRMATION + `/${data.trx_uid}`, data)
          .then(result => {
            // const data = TransactionPrintBalanceModel.fromJson(result.data.data);

            resolve(result.data.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
