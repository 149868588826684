import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Routing } from '@/domain/constant/router_path.ts';
import { FaArrowLeft } from 'react-icons/fa6';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { TabContent, TabPane2, TabPaneList2 } from '@/delivery/components/atoms/tab_pane/tab_pane.tsx';
import { WithdrawalProvider } from '@/delivery/ui/admin/settings/withdrawal/context/withdrawal_context.tsx';
import DetailWithdrawalMethod from '@/delivery/ui/admin/settings/withdrawal/detail/withdrawal_method/withdrawal_method.tsx';
import DetailWithdrawalFee from '@/delivery/ui/admin/settings/withdrawal/detail/withdrawal_fee/withdrawal_fee.tsx';

const DetailWithdrawalPage = () => {
  const navigate = useNavigate();
  const [tabPage, setTabPage] = useState(0);
  const data = [
    {
      label: 'Withdrawal Method',
      index: 0
      // handleClick: () => {}
    },
    {
      label: 'Withdrawal Fee',
      index: 1
      // handleClick: () => {}
    }
  ];
  return (
    <div className={'mt-[24px] flex flex-col gap-[20px]'}>
      <div
        className={'flex gap-[10px] items-center text-text-primary cursor-pointer'}
        onClick={() => navigate(Routing.SETTINGS.WITHDRAWAL)}
      >
        <FaArrowLeft />
        <Typography size={14} fontWeight={500}>
          Back to withdrawal
        </Typography>
      </div>
      <div>
        <Typography size={24} fontWeight={600}>
          Withdrawal Detail
        </Typography>
      </div>
      <div className="flex flex-col gap-[20px]">
        <TabPane2>
          {data?.map((item, index) => (
            <TabPaneList2 label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
          ))}
        </TabPane2>

        <TabContent index={0} tab={tabPage}>
          <WithdrawalProvider>
            <DetailWithdrawalMethod />
          </WithdrawalProvider>
        </TabContent>
        <TabContent index={1} tab={tabPage}>
          <WithdrawalProvider>
            <DetailWithdrawalFee />
          </WithdrawalProvider>
        </TabContent>
      </div>
    </div>
  );
};

export default DetailWithdrawalPage;
