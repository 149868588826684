import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useDCAActivationContext } from '@/delivery/ui/admin/data_dca/dca_activation/context/dca_activation_context.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { DCA_STATUS_ACTIVATION_LIST } from '@/domain/constant/dca/dca_status.ts';

const DialogUpdateDCA = (props: ModalDialogProps) => {
  const { formDCAActivation, setFormDCAActivation, handleUpdateUser, handleShowUserModal } = useDCAActivationContext();
  return (
    <ModalDialog size="small" title="DCA Activation" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Select2
          value={formDCAActivation?.dca_status}
          options={DCA_STATUS_ACTIVATION_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          onChange={e => setFormDCAActivation({ ...formDCAActivation, dca_status: e.value })}
        />
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleShowUserModal.setState(false)} />
        <Button
          label="Submit"
          onClick={() => {
            handleUpdateUser();
          }}
        />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogUpdateDCA;
