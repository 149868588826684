import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import Navbar from '@/delivery/components/organisms/navbar/navbar.tsx';
import VendingMachine from '@/delivery/ui/admin/transaction/take_gold/vending_machine/vending_machine.tsx';
import { useVendingMachineContext } from '@/delivery/ui/admin/transaction/take_gold/vending_machine/context/vending_machine_context.tsx';

const VendingMachinePage = () => {
  const { handleAddVendingMachineModal } = useVendingMachineContext();
  return (
    <div>
      <Navbar label={'Vending Machine'}>
        <Button
          prefixIcon={Icon.plus}
          label="Add Vending Machine"
          onClick={() => handleAddVendingMachineModal.setState(true)}
        />
      </Navbar>
      <VendingMachine />
    </div>
  );
};

export default VendingMachinePage;
