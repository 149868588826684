import { useHistoryContext } from '@/delivery/ui/admin/transaction/take_gold/history/context/history_context.tsx';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';
import { FaArrowLeft } from 'react-icons/fa6';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';
import DataMemberHistory from '@/delivery/ui/admin/transaction/take_gold/history/detail/data_member.tsx';
import DataPrintGoldHistory from '@/delivery/ui/admin/transaction/take_gold/history/detail/data_print_gold.tsx';
import DataPrintGoldCounter from '@/delivery/ui/admin/transaction/take_gold/history/detail/data_print_gold_counter.tsx';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';

const DetailHistory = () => {
  const navigate = useNavigate();
  const { uid } = useParams();
  const { handleCancelTransaction, history, handleShowDetailHistory } = useHistoryContext();

  useEffect(() => {
    handleShowDetailHistory({ uid: uid });
  }, []);

  return (
    <div className={'mt-[24px] flex flex-col gap-[20px]'}>
      <div
        className={'flex gap-[10px] items-center text-text-primary cursor-pointer'}
        onClick={() => navigate(Routing.TRANSACTION.AMBIL_EMAS.HISTORY)}
      >
        <FaArrowLeft />
        <Typography size={14} fontWeight={500}>
          Back to all history
        </Typography>
      </div>
      <div className={'flex justify-between items-center'}>
        <Typography tag={'p'} size={24} fontWeight={600}>
          Detail history
        </Typography>
        <div>
          {history?.status === STATUS_TRANSACTION.SUCCESS && history?.flags?.join('') !== TYPE_TRANSACTION.REVERSAL ? (
            <Button type={'danger'} label={'Cancel'} onClick={() => handleCancelTransaction(history)} />
          ) : (
            ''
          )}
        </div>
      </div>
      <Line />
      <div className={'grid lg:grid-cols-2 sm:grid-cols-1 gap-[20px]'}>
        <div className={'flex-col flex gap-[20px]'}>
          <DataMemberHistory />
          {history?.status === STATUS_TRANSACTION.SUCCESS ? <DataPrintGoldCounter /> : ''}
        </div>
        <div className={'flex flex-col gap-[20px]'}>
          <DataPrintGoldHistory />
        </div>

        {/*<div>*/}
        {/*  <Theader>*/}
        {/*    <Typography fontWeight={600} size={16} type="primary-invert">*/}
        {/*      Counter*/}
        {/*    </Typography>*/}
        {/*  </Theader>*/}
        {/*  <TBox>asdasdc</TBox>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default DetailHistory;
