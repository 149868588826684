import Navbar from '@/delivery/components/organisms/navbar/navbar';

import RequestVerify from './request_verify';

const RequestVerifyPage = () => {
  return (
    <div>
      <Navbar label={'List Request Verify Corporate'}></Navbar>
      <RequestVerify />
    </div>
  );
};

export default RequestVerifyPage;
