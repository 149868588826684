import { useEffect } from 'react';
import TransactionPage from '@/delivery/ui/admin/dashboard/transaction';
import { DashboardTransactionProvider } from '@/delivery/ui/admin/dashboard/transaction/context/dashboard_transaction_context.tsx';

const ViewDashboardTransaction = () => {
  useEffect(() => {
    document.title = 'Transaction';
  }, []);

  return (
    <div>
      <DashboardTransactionProvider>
        <TransactionPage />
      </DashboardTransactionProvider>
    </div>
  );
};

export default ViewDashboardTransaction;
