import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useMemberVefiryContext } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';
import DialogUpdateUserWithdrawal from '@/delivery/ui/admin/data_member/member_verify/detail/dialog/dialog_update_user_withdrawal.tsx';
import { MEMBER_E_MONEY_TYPE } from '@/domain/constant/member_e_money/member_e_money_type.ts';

const TableBankAccount = () => {
  const { handleEditUserWithdrawalModal, user, handleShowUserWithdrawal, userVerification } = useMemberVefiryContext();
  return (
    <>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr noBorder>
            <Th noBorder>
              <Typography fontWeight={600} size={14} type="primary-invert">
                Akun Bank
              </Typography>
            </Th>
            <Th noBorder>
              <div className={'justify-end flex'}>
                <span
                  className={'text-text-primary font-[600] text-[14px] cursor-pointer'}
                  onClick={() => {
                    if (user) handleShowUserWithdrawal(user);
                  }}
                >
                  Edit
                </span>
              </div>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {/*bank name*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Bank Name
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.masterBank?.name}
              </Typography>
            </Td>
          </Tr>

          {/*bank acc no*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Bank Acc No
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.accountNumber}
              </Typography>
            </Td>
          </Tr>

          {/*Bank acc holder*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Bank Acc Holder
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.accountHolderName}
              </Typography>
            </Td>
          </Tr>

          {/*buku tabungan*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Buku Tabungan
              </Typography>
            </Td>
            <Td noBorder>
              <div className={'flex gap-[20px] mr-[20px] items-center'}>
                <Typography fontWeight={400} size={14} type="primary-invert">
                  :
                </Typography>
                {userVerification?.getMediaUrl !== '#' ? (
                  <Image url={userVerification?.getMediaUrl} width={200} />
                ) : (
                  <Image
                    url={user?.userWithdrawal.find(item => item.type === MEMBER_E_MONEY_TYPE.BANK)?.getMediaUrl}
                    width={200}
                  />
                )}
              </div>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      {handleEditUserWithdrawalModal.state && <DialogUpdateUserWithdrawal modal={handleEditUserWithdrawalModal} />}
    </>
  );
};

export default TableBankAccount;
