import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import { TransactionRepository } from '@/domain/repository/transaction_repository.ts';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
interface SettlementContextProps {
  children: ReactNode;
}

interface SetttlementValue {
  uid?: string;
  page?: number;
  limit?: number;
}

interface SettlementContextValue {
  transactionList: string[];
  setTransactionList: Dispatch<SetStateAction<string[]>>;
  transactionSettlements: TransactionModel[];
  transactionSettlementPagination?: PaginationModel;
  handleAddSettlementModal: ModalObject;
  handleFetchTransactionSettlement: (_data: SetttlementValue) => void;
  handleSettleDataTransaction: () => void;
}

const SettlementContext = createContext<SettlementContextValue | null>(null);

const transactionRep = new TransactionRepository();

const SettlementProvider: React.FC<SettlementContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [transactionList, setTransactionList] = useState<string[]>([]);

  const [transactionSettlements, setTransactionSettlements] = useState<TransactionModel[]>([]);
  const [transactionSettlementPagination, setTransactionSettlementPagination] = useState<PaginationModel>();

  const handleAddSettlementModal = useVisibleComponent(false);

  //HANDLE FETCH TRANSACTION SETTLEMENT
  const handleFetchTransactionSettlement = (_data: SetttlementValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      sort: 'created_at desc',
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      status: STATUS_TRANSACTION.AWAITING_SETTLEMENT
    };

    transactionRep
      .fetchTransactionDashboard(params)
      .then((result: any) => {
        setTransactionSettlements(result.data);
        setTransactionSettlementPagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SETTLE DATA TRANSACTION
  const handleSettleDataTransaction = () => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        const params = {
          transaction_list: transactionList,
          status: STATUS_TRANSACTION.SUCCESS
        };

        transactionRep
          .settlementTransaction(params)
          .then((result: any) => {
            handleFetchTransactionSettlement({});
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: SettlementContextValue = {
    transactionList,
    setTransactionList,
    transactionSettlements,
    transactionSettlementPagination,
    handleFetchTransactionSettlement,
    handleAddSettlementModal,
    handleSettleDataTransaction
  };

  return <SettlementContext.Provider value={contextValue}>{children}</SettlementContext.Provider>;
};

const useSettlementContext = (): SettlementContextValue => {
  const context = useContext(SettlementContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a SettlementProvider');
  }
  return context;
};

export { SettlementProvider, useSettlementContext };
