import Navbar from '@/delivery/components/organisms/navbar/navbar';
import ManualTransaction from './manual_transaction';

const ManualTransactionPage = () => {
  return (
    <div>
      <Navbar label={'Manual Transaction'}></Navbar>
      <ManualTransaction />
    </div>
  );
};

export default ManualTransactionPage;
