import { ApiClient } from '@/main/apiClient';
import UserAddressInterface, { UserAddressValue } from '@/domain/repository/interface/user_address_interface.ts';
import { UserAddress } from '@/domain/constant/api_list';
import { PaginationModel } from '@/domain/model/pagination_model';
import { UserAddressModel } from '@/domain/model/user_address_model.ts';

export class UserAddressRepository implements UserAddressInterface {
  api = new ApiClient();

  async fetchUserAddress(data: UserAddressValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(UserAddress.FETCH, {
            params: data
          })
          .then(result => {
            const data = UserAddressModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showUserAddress(data: UserAddressValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(UserAddress.SHOW, {
            params: data
          })
          .then(result => {
            const data = UserAddressModel.fromJson(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateUserAddress(data: UserAddressValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(UserAddress.UPDATE + data.uid, data)
          .then(result => {
            const data = UserAddressModel.fromJsonList(result.data.data.result);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteUserAddress(data: UserAddressValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(UserAddress.DELETE + data.uid, {
            params: data
          })
          .then(result => {
            const data = UserAddressModel.fromJsonList(result.data.data.result);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeUserAddress(data: UserAddressValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(UserAddress.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
