import Textarea from '@/delivery/components/atoms/textarea/textarea';
import Typography from '@/delivery/components/atoms/typography/typography';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { useMemberVefiryContext } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';
import { STATUS_CORPORATE_LIST } from '@/domain/constant/status_corporate.ts';

const ApprovalDataVerify = () => {
  const { formMemberVerify, setFormMemberVerify } = useMemberVefiryContext();

  return (
    <div className="flex flex-col gap-[24px]">
      <div className="grid grid-cols-3 gap-[24px] items-center">
        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Status
          </Typography>
        </div>
        <div className="col-span-2">
          <Select2
            value={formMemberVerify.status}
            options={STATUS_CORPORATE_LIST.map(item => {
              return {
                label: item.name,
                value: item.UUID
              };
            })}
            onChange={e => setFormMemberVerify({ ...formMemberVerify, status: e.value })}
            placeholder="Status"
          />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Note
          </Typography>
        </div>
        <div className="col-span-2">
          <Textarea
            block
            placeholder="Note"
            value={formMemberVerify.notes}
            onChange={e =>
              setFormMemberVerify({
                ...formMemberVerify,
                notes: e.target.value
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ApprovalDataVerify;
