import Checkbox from '@/delivery/components/atoms/checkbox/checkbox.tsx';
import { useAccessControllereContext } from '@/delivery/ui/admin/settings/admin_access/context/access_controller_context.tsx';
import { GroupedAccessItemInterface } from '@/infrastructure/helper/getAccessItems.ts';
import { HiOutlineChevronUp, HiOutlineChevronDown } from 'react-icons/hi2';
import { useState } from 'react';
import Button from '@/delivery/components/atoms/button/button.tsx';

interface AssignedAccessProps {
  data: GroupedAccessItemInterface;
}

const AssignedAccess = (props: AssignedAccessProps) => {
  const { data } = props;
  const [open, setOpen] = useState<boolean>(false);

  const { formCurrentAccess, setFormCurrentAccess } = useAccessControllereContext();

  return (
    <div className={'border border-border-tertiary p-[10px] m-[10px] rounded-[8px]'}>
      <div>
        <div className={'flex'}>
          <Button
            iconButton
            icon={open ? <HiOutlineChevronDown /> : <HiOutlineChevronUp />}
            onClick={() => setOpen(!open)}
          />
          <Checkbox
            checked={data.access_list.every(item => formCurrentAccess.includes(item.UUID))}
            fontWeight={'bold'}
            label={data.access}
            onChange={e => {
              if (e.target.checked) {
                data.access_list.forEach(itemAccess => {
                  setFormCurrentAccess(prevState => [...prevState, itemAccess.UUID]);
                });
              } else {
                data.access_list.forEach(item => {
                  setFormCurrentAccess(prevState => prevState.filter(itemInState => itemInState !== item.UUID));
                });
              }
            }}
          />
        </div>

        {/*ACCESS LIST*/}
        <div className={`ml-[50px] ${!open ? 'hidden' : ''}`}>
          {data.access_list.map((itemList, index) => (
            <Checkbox
              checked={formCurrentAccess.includes(itemList.UUID)}
              id={itemList.access}
              label={itemList.access}
              onChange={e => {
                if (e.target.checked) {
                  setFormCurrentAccess(prevState => [...prevState, itemList.UUID]);
                } else {
                  setFormCurrentAccess(prevState => prevState.filter(item => item !== itemList.UUID));
                }
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AssignedAccess;
