import { useEffect } from 'react';
import DataTransactionPage from '@/delivery/ui/admin/data_corporate/data_transaction';

const ViewDataTransaction = () => {
  useEffect(() => {
    document.title = 'Transaction Corporate List';
  }, []);

  return (
    <div>
      <DataTransactionPage />
    </div>
  );
};

export default ViewDataTransaction;
