import { useEffect } from 'react';
import WithdrawalPage from '@/delivery/ui/admin/settings/withdrawal';
import { WithdrawalProvider } from '@/delivery/ui/admin/settings/withdrawal/context/withdrawal_context.tsx';

const ViewWithdrawal = () => {
  useEffect(() => {
    document.title = 'Withdrawal Setting';
  }, []);

  return (
    <div>
      <WithdrawalProvider>
        <WithdrawalPage />
      </WithdrawalProvider>
    </div>
  );
};

export default ViewWithdrawal;
