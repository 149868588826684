import { ModalDialog, ModalDialogProps } from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';

import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useState } from 'react';
import { TabContent, TabPane2, TabPaneList2 } from '@/delivery/components/atoms/tab_pane/tab_pane.tsx';
import AddUnavailability from '@/delivery/ui/admin/transaction/take_gold/counter_offline/dialog/counter_unavailability/add_unavailability.tsx';
import UnavailabilityList from '@/delivery/ui/admin/transaction/take_gold/counter_offline/dialog/counter_unavailability/unavailability_list.tsx';

const DialogAddCounterAvailability = (props: ModalDialogProps) => {
  const [tabPage, setTabPage] = useState(0);

  const data = [
    {
      label: 'Add New',
      index: 0
      // handleClick: () => {}
    },
    {
      label: 'Unavailability list',
      index: 1
      // handleClick: () => {}
    }
  ];

  return (
    <ModalDialog overflow={false} size="medium" title="Add Counter Unvailability" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TabPane2>
          {data?.map((item, index) => (
            <TabPaneList2 label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
          ))}
        </TabPane2>

        <TabContent index={0} tab={tabPage}>
          <AddUnavailability />
        </TabContent>
        <TabContent index={1} tab={tabPage}>
          <UnavailabilityList />
        </TabContent>
      </div>
    </ModalDialog>
  );
};

export default DialogAddCounterAvailability;
