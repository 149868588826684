export const DCA_CLOSE_METHOD = {
  BANK_TRANSFER: 'BANK TRANSFER',
  CASH: 'CASH',
  DCA_GRAM: 'DCA GRAM',
  OTHER: 'OTHER'
};

export const DCA_CLOSE_METHOD_LIST = [
  { UUID: 'BANK TRANSFER', name: 'BANK TRANSFER' },
  { UUID: 'DCA GRAM', name: 'DCA GRAM' },
  { UUID: 'CASH', name: 'CASH' },
  { UUID: 'OTHER', name: 'OTHER' }
];
