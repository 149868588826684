import { BaseModel } from '@/domain/model/base_model.ts';
import { BalanceModel } from '@/domain/model/balance_model.ts';
import { DCABalanceModel } from '@/domain/model/dca_balance_model.ts';
import { formatDateTime } from '@/infrastructure/helper/formatDate.ts';
import { ChipType } from '@/domain/constant/component/chip_colors.ts';
import { DCA_STATUS_ACTIVE } from '@/domain/constant/dca/dca_status.ts';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';

export class DCAModel extends BaseModel {
  username: string;
  last_name: string;
  first_name: string;
  serial_id: string;
  birth_date: string;
  email: string;
  emailVerified: boolean;
  verifiedAt: string;
  phone: string;
  status: string;
  type: string;
  profileImgUUID: string;
  defaultCompanyUUID: string;
  dcaStatus: string;
  totalBalance: BalanceModel;
  dcaBalanceFiat: number;
  dcaBalanceGram: number;
  dcaLockGram: number;
  dcaBalance: DCABalanceModel[];

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    username: string,
    last_name: string,
    first_name: string,
    serial_id: string,
    birth_date: string,
    email: string,
    emailVerified: boolean,
    verifiedAt: string,
    phone: string,
    status: string,
    type: string,
    profileImgUUID: string,
    defaultCompanyUUID: string,
    dcaStatus: string,
    totalBalance: BalanceModel,
    dcaBalanceFiat: number,
    dcaBalanceGram: number,
    dcaLockGram: number,
    dcaBalance: DCABalanceModel[]
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.username = username;
    this.last_name = last_name;
    this.first_name = first_name;
    this.serial_id = serial_id;
    this.birth_date = birth_date;
    this.email = email;
    this.emailVerified = emailVerified;
    this.verifiedAt = verifiedAt;
    this.phone = phone;
    this.status = status;
    this.type = type;
    this.profileImgUUID = profileImgUUID;
    this.defaultCompanyUUID = defaultCompanyUUID;
    this.dcaStatus = dcaStatus;
    this.totalBalance = totalBalance;
    this.dcaBalanceFiat = dcaBalanceFiat;
    this.dcaBalanceGram = dcaBalanceGram;
    this.dcaLockGram = dcaLockGram;
    this.dcaBalance = dcaBalance;
  }

  get getDCAFiatRupiah(): string {
    if (this.dcaBalanceFiat) {
      return formatRupiah(this.dcaBalanceFiat);
    }

    return formatRupiah(0);
  }

  get getFullName(): string {
    if (this.first_name && this.last_name) {
      return `${this.first_name} ${this.last_name}`;
    }

    if (this.first_name && this.last_name === null) {
      return `${this.first_name}`;
    }

    if (this.first_name === null && this.last_name) {
      return `${this.last_name}`;
    }

    if (this.first_name) {
      return this.first_name;
    }

    return '-';
  }

  get getCreatedAt(): string {
    if (this.createdAt) {
      return formatDateTime(this.createdAt);
    }

    return '-';
  }

  get getVerifiedAt(): string {
    if (this.verifiedAt) {
      return formatDateTime(this.verifiedAt);
    }

    return '-';
  }

  get getStatusActiveColor(): ChipType {
    if (this.status === DCA_STATUS_ACTIVE.ACTIVE) {
      return 'primary-light';
    }

    if (this.status === DCA_STATUS_ACTIVE.INACTIVE) {
      return 'danger-light';
    }
  }

  public static fromJson(data: any): any | DCAModel {
    try {
      return new DCAModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.username,
        data.last_name,
        data.first_name,
        data.serial_id,
        data.birth_date,
        data.email,
        data.email_verified,
        data.verified_at,
        data.phone,
        data.status,
        data.type,
        data.profile_img_uuid,
        data.default_company_uuid,
        data.status,
        data.total_balance ? BalanceModel.fromJson(data.total_balance) : null,
        data.dca_balance_fiat,
        data.dca_balance_gram,
        data.dca_lock_gram,
        data.dca_balance ? DCABalanceModel.fromJsonList(data.dca_balance) : []
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): DCAModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(DCAModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
