import { useEffect } from 'react';
import DepositByAdminPage from '@/delivery/ui/admin/deposit_gold/deposit_by_admin';
import { DepositByAdminProvider } from '@/delivery/ui/admin/deposit_gold/deposit_by_admin/context/deposit_by_admin_context';

const ViewDepositByAdmin = () => {
  useEffect(() => {
    document.title = 'Setor Emas by Admin';
  }, []);

  return (
    <div>
      <DepositByAdminProvider>
        <DepositByAdminPage />
      </DepositByAdminProvider>
    </div>
  );
};

export default ViewDepositByAdmin;
