import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useDashboardRankingContext } from '@/delivery/ui/admin/dashboard/ranking/context/dashboard_ranking_context.tsx';
import RankModel from '@/domain/model/dashboard/rank_model.ts';
import { LIMIT_RANK_DISPLAY } from '@/domain/constant/dashboard/rank.ts';
import Loading from '@/delivery/components/atoms/loading/loading.tsx';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { formatDateTimeParam } from '@/infrastructure/helper/formatDate.ts';
import { useRef } from 'react';

const TableHighProfit = () => {
  const tableRef = useRef(null);
  const { rankingHighProfitData, rankingHighProfitLoading } = useDashboardRankingContext();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `top-high-profit-${formatDateTimeParam(new Date())}`,
    sheet: 'high profit'
  });

  const handleExport = () => {
    onDownload();
  };

  return (
    <div className="w-full">
      <Theader>
        <div className="flex gap-[8px]">
          <span className="text-[18px] font-[600]">Top {LIMIT_RANK_DISPLAY} High Profit</span>
          <Chips removeDot label={`${LIMIT_RANK_DISPLAY} users`} type={'primary-light'} />
        </div>

        <Button label="Export" type="secondary" onClick={() => handleExport()} />
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th noBorder>#</Th>
            <Th noBorder>Member</Th>
            <Th noBorder>Profit</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rankingHighProfitLoading === false ? (
            <>
              {rankingHighProfitData?.map((item: RankModel, index) => (
                <Tr>
                  <Td noBorder>{index + 1}</Td>
                  <Td noBorder>
                    <p className="text-[14px] font-[500]">{item.name}</p>
                    <p className="text-[11px] font-[400]">
                      {item.phone ?? '-'} - {item.email ?? '-'}
                    </p>
                  </Td>
                  <Td noBorder>
                    <div className="flex items-center gap-[2px]">
                      <span className="text-[14px] font-[400]">{item.getNetProfit}</span>
                    </div>
                  </Td>
                </Tr>
              ))}
            </>
          ) : (
            <Tr>
              <Td colSpan={3}>
                <div className={'w-full flex justify-center'}>
                  <Loading type={'linear'} />
                </div>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {/*//export template*/}
      <table ref={tableRef} className={'border border-solid hidden'}>
        <tbody>
          <tr>
            <th colSpan={5} className={'border border-solid'}>
              Table Net Profit
            </th>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <th className={'border border-solid'}>No</th>
            <th className={'border border-solid'}>Nama</th>
            <th className={'border border-solid'}>Phone</th>
            <th className={'border border-solid'}>Email</th>
            <th className={'border border-solid'}>Net Profit</th>
          </tr>

          {rankingHighProfitData.map((item, index) => (
            <tr>
              <td className={'border border-solid'}>{index + 1}</td>
              <td className={'border border-solid'}>{item.name}</td>
              <td className={'border border-solid'}>{item.phone}</td>
              <td className={'border border-solid'}>{item.email}</td>
              <td className={'border border-solid'}>{item.netProfit}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableHighProfit;
