import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import { useTiereContext } from '@/delivery/ui/admin/master/member_loyalty/context/member_loyalty_context.tsx';

const DialogAddMemberLoyalty = (props: ModalDialogProps) => {
  const { handleAddTier, handleAddTierModal, formTier, setFormTier } = useTiereContext();
  return (
    <ModalDialog size="small" title="Add Member Loyalty" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField
          label="Name"
          placeholder="Name"
          block
          onChange={e => setFormTier({ ...formTier, name: e.target.value })}
        />
        <TextField
          type={'number'}
          label="Gram Minimum (gr)"
          placeholder="Gram Minimum"
          block
          onChange={e => setFormTier({ ...formTier, gram_minimal: parseFloat(e.target.value) })}
        />

        <TextField
          type={'currency'}
          label="Max Sell"
          placeholder="Max Sell"
          block
          onChange={(e: any) => setFormTier({ ...formTier, maximum_sell: parseInt(e.value) })}
        />
        <TextField
          type={'currency'}
          label="Max Sell Daily"
          placeholder="Max Sell Daily"
          block
          onChange={(e: any) => setFormTier({ ...formTier, maximum_sell_daily: parseInt(e.value) })}
        />
        <TextField
          type={'currency'}
          label="Max Transfer"
          placeholder="Max Transfer"
          block
          onChange={(e: any) => setFormTier({ ...formTier, maximum_transfer: parseInt(e.value) })}
        />
        <TextField
          type={'currency'}
          label="Max Transfer Daily"
          placeholder="Max Transfer Daily"
          block
          onChange={(e: any) => setFormTier({ ...formTier, maximum_transfer_daily: parseInt(e.value) })}
        />

        <Textarea label="Description" placeholder="Description" block />
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleAddTierModal.setState(false)} />
        <Button block label="Save" onClick={() => handleAddTier()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddMemberLoyalty;
