// utils.ts

// function paramSerializer(params: { [key: string]: any }): string {
//   const serializedParams: string[] = [];
//
//   Object.keys(params).forEach(key => {
//     const value = params[key];
//
//     if (Array.isArray(value)) {
//       // Handle arrays like `date_range`
//       value.forEach(val => {
//         serializedParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
//       });
//     } else {
//       // Handle non-array values
//       serializedParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
//     }
//   });
//
//   return serializedParams.join('&');
// }
//
// export { paramSerializer };

// utils.ts

function paramSerializer(params: { [key: string]: any }): string {
  const serializedParams: string[] = [];

  Object.keys(params).forEach(key => {
    const value = params[key];

    if (key === 'date_range' || key === 'date') {
      if (Array.isArray(value)) {
        // Handle array `date_range`
        value.forEach(val => {
          serializedParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
        });
      } else if (typeof value === 'string') {
        // Handle string `date_range`
        serializedParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        serializedParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    } else {
      if (Array.isArray(value)) {
        // Handle other arrays
        value.forEach(val => {
          serializedParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
        });
      } else {
        // Handle non-array and non-`date_range` values
        serializedParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  });

  return serializedParams.join('&');
}

export { paramSerializer };
