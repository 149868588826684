import TextField from '@/delivery/components/atoms/text_field/text_field';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useMemberVefiryContext } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';

const DataRegister = () => {
  const { formMemberVerify } = useMemberVefiryContext();

  return (
    <div className="flex flex-col gap-[24px]">
      <div className="grid grid-cols-3 gap-[24px] items-center">
        {/* name */}
        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Name
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField disabled block placeholder="Nama Depan" value={formMemberVerify.name} />
        </div>
        {/*<div>*/}
        {/*  <TextField block placeholder="Nama Belakang" />*/}
        {/*</div>*/}

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Email
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField block placeholder="Email" />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Phone
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField block placeholder="Phone" />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Register Date
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField block placeholder=" Register Date" />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Email Verified
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField block placeholder="Email Verified" />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Trx count
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField block placeholder="Trx count" />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Balance
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField block placeholder="Balance" />
        </div>
      </div>
    </div>
  );
};

export default DataRegister;
