export class MemberChartMonthlyModel {
  count: string;
  month: string;
  year: string;

  constructor(count: string, month: string, year: string) {
    this.count = count;
    this.month = month;
    this.year = year;
  }

  public static fromJson(data: any): any | MemberChartMonthlyModel {
    try {
      return new MemberChartMonthlyModel(
        data.count ? data.count : null,
        data.month ? data.month : null,
        data.year ? data.year : null
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): MemberChartMonthlyModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(MemberChartMonthlyModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
