export const DCA_STATUS = {
  APPROVED: 'APPROVED',
  REQUEST: 'REQUEST',
  CLOSED: 'CLOSED'
};

export const DCA_STATUS_LIST = [
  { UUID: 'APPROVED', name: 'APPROVED' },
  { UUID: 'REQUEST', name: 'REQUEST' }
];

export const DCA_STATUS_ACTIVATION = {
  VERIFIED: 'VERIFIED',
  REQUEST: 'REQUEST',
  CLOSED: 'CLOSED'
};

export const DCA_STATUS_ACTIVATION_LIST = [
  { UUID: 'VERIFIED', name: 'VERIFIED' },
  { UUID: 'REQUEST', name: 'REQUEST' }
];

export const DCA_STATUS_ACTIVE = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};
