import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useGoldPieceContext } from '@/delivery/ui/admin/master/gold_piece/context/gold_piece_context.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { STATUS_LIST } from '@/domain/constant/status.ts';
import { ModalDialogFooter } from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { TBox } from '@/delivery/components/molecules/table/table.tsx';

export const DetailGoldPiece = () => {
  const { uid } = useParams();

  const { handleUpdateGoldPiece, handleShowGoldPiece, formGoldPiece, setFormGoldPiece } = useGoldPieceContext();

  useEffect(() => {
    if (uid) {
      handleShowGoldPiece({ uid: uid });
    }
  }, [uid]);

  return (
    <TBox className={'lg:w-1/2 w-full'}>
      <div className={'flex gap-[20px]'}>
        <div className="flex flex-col gap-[20px] w-full">
          <Typography fontWeight={600} size={14}>
            Gold Piece
          </Typography>
          <Line />
          <TextField
            value={formGoldPiece?.name}
            label="Name"
            placeholder="Nama"
            block
            onChange={e => setFormGoldPiece({ ...formGoldPiece, name: e.target.value })}
          />
          <TextField
            type={'number'}
            value={formGoldPiece.gram}
            label="Gram"
            placeholder="Gram"
            block
            onChange={e => setFormGoldPiece({ ...formGoldPiece, gram: parseFloat(e.target.value) })}
          />
          <Textarea
            height={150}
            label="Notes"
            placeholder="Notes"
            block
            value={formGoldPiece.notes}
            onChange={e => {
              setFormGoldPiece({
                ...formGoldPiece,
                notes: e.target.value
              });
            }}
          />

          <Select2
            value={formGoldPiece.status}
            label={'Status'}
            options={STATUS_LIST.map(item => {
              return {
                label: item.name,
                value: item.UUID
              };
            })}
            onChange={(e: any) => {
              setFormGoldPiece({
                ...formGoldPiece,
                status: e.value
              });
            }}
          />
        </div>
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button label="Save" onClick={() => handleUpdateGoldPiece()} />
      </ModalDialogFooter>
    </TBox>
  );
};

export default DetailGoldPiece;
