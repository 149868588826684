import { BsFilter } from 'react-icons/bs';
import { FiDownloadCloud } from 'react-icons/fi';

import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Theader, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Chips from '@/delivery/components/atoms/chips/chips';
import Typography from '@/delivery/components/atoms/typography/typography';

const ReferralTransaction = () => {
  return (
    <div>
      <div className="mt-[32px]">
        <Theader>
          <TextField search placeholder="Search" />
          <div className="flex gap-[12px]">
            <Button label="More Filter" type="secondary" prefixIcon={<BsFilter />} />
            <Button label="Export by Filter" type="secondary" prefixIcon={<FiDownloadCloud />} />
          </div>
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>TrxID</Th>
              <Th>Corporate Member</Th>
              <Th>Type</Th>
              <Th>Status</Th>
              <Th>Trx Date</Th>
              <Th>Paid Amount</Th>
              <Th>Gramasi</Th>
              <Th>Paid Date</Th>
              <Th>Gold Rate</Th>
              <Th>Payment</Th>
              <Th>Update By</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    123456
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    PT Harapan Sentosa Indonesia
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    ptharapansentosaindonesia@gmail.com
                  </Typography>
                </Td>
                <Td>
                  <Chips label="Pembelian" type="warning" removeDot />
                </Td>
                <Td>
                  <Chips label="Berhasil" type="primary-light" />
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    2023-05-20 20:02:46
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    500.000,00
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    0,495
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    2023-05-20 20:02:46
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="p" fontWeight={600} size={12} type="tertiary-invert">
                    1.00000
                  </Typography>
                  <Typography tag="p" fontWeight={400} size={12} type="tertiary-invert">
                    1.00000
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" fontWeight={460} size={12} type="tertiary-invert">
                    Qris
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    System
                  </Typography>
                </Td>
                <Td>
                  <div className="flex gap-[4px]">
                    <Button iconButton iconType={'lock'} />
                    <Button iconButton iconType={'view'} />
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter></Tfooter>
      </div>
    </div>
  );
};

export default ReferralTransaction;
