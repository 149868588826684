import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import { PaginationModel } from '@/domain/model/pagination_model';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';

import { NotificationRepository } from '@/domain/repository/notification_repository.ts';
import { formatDateTimeParam } from '@/infrastructure/helper/formatDate.ts';
import { NotificationModel } from '@/domain/model/notification_model.ts';
import UploadMedia from '@/infrastructure/helper/uploadMedia.tsx';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import MEDIA_PATH from '@/domain/constant/media/media_path.ts';
import { NOTIFY_CATEGORY } from '@/domain/constant/notify_app/category.ts';
import { RECIPIENT_TYPE } from '@/domain/constant/notify_app/recipient_type.ts';
import { UserRepository } from '@/domain/repository/user_repository.ts';
import { UserModel } from '@/domain/model/user_model.ts';

interface NotifyAppContextContextProps {
  children: ReactNode;
}

interface FormUser {
  uuid?: string;
  search?: string;
}

interface NotifyAppValue {
  uid?: string;
  page?: number;
  limit?: number;
}

interface UserSelected {
  uuid?: string;
  name?: string;
}

interface FormPaymentChannelInterface {
  UID?: string;
  category?: string;
  channels?: string[];
  cta?: string;
  end_date?: string;
  start_date?: string;
  flags?: string;
  media?: string;
  media_uid?: string;
  message?: string;
  message_html?: string;
  meta?: string;
  one_time_notification?: boolean;
  recipient_type?: string;
  status?: string;
  title?: string;
  type?: string[];
}

interface NotifyAppContextValue {
  userSelected: UserSelected[];
  setUserSelected: Dispatch<SetStateAction<UserSelected[]>>;
  users: UserModel[];
  setUsers: Dispatch<SetStateAction<UserModel[]>>;
  loading: boolean;
  handleShowUserListModal: ModalObject;
  formNotifyApp: FormPaymentChannelInterface;
  setFormNotifyApp: Dispatch<SetStateAction<FormPaymentChannelInterface>>;
  notifyApps: NotificationModel[];
  notifyAppPagination?: PaginationModel;
  handleUpdateNotifyAppModal: ModalObject;
  handleAddNotifyAppModal: ModalObject;
  handleFetchNotifyApp: (_data: NotifyAppValue) => Promise<void>;
  handleStoreDataNotification: () => void;
  handleShowNotifyApp: (_data: NotificationModel) => void;
  handleUpdateDataNotification: () => void;
  handleDeleteNotifyApp: (_data: NotificationModel) => void;
  handleFetchUser: (_data: FormUser) => void;
}

const NotifyAppContext = createContext<NotifyAppContextValue | null>(null);

const notificationRepository = new NotificationRepository();
const userRepository = new UserRepository();

const NotifyAppProvider: React.FC<NotifyAppContextContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [loading, setLoading] = useState<boolean>(false);

  const [users, setUsers] = useState<UserModel[]>([]);

  const [notifyApps, setNotifyApps] = useState<NotificationModel[]>([]);
  const [notifyAppPagination, setNotifyAppPagination] = useState<PaginationModel>();

  const [formNotifyApp, setFormNotifyApp] = useState<FormPaymentChannelInterface>({});

  const [userSelected, setUserSelected] = useState<UserSelected[]>([]);

  const handleUpdateNotifyAppModal = useVisibleComponent(false);
  const handleAddNotifyAppModal = useVisibleComponent(false);
  const handleShowUserListModal = useVisibleComponent(false);

  //FETCH USER TRANSACTION SEARCH
  const handleFetchUser = (_data: FormUser) => {
    setLoading(true);
    const params = {
      search: _data.search
      // type: MEMBER_TYPE.USER
    };
    userRepository
      .fetchUser(params)
      .then((result: any) => {
        setLoading(false);
        setUsers(result.data);
      })
      .catch(error => {
        setLoading(false);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH
  const handleFetchNotifyApp = async (_data: NotifyAppValue) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10
    };

    return notificationRepository
      .fetchNotification(params)
      .then((result: any) => {
        setNotifyApps(result.data);
        setNotifyAppPagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE DELETE
  const handleDeleteNotifyApp = (_data: NotificationModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        const params = {
          uid: _data.UID
        };

        notificationRepository
          .deleteNotification(params)
          .then((result: any) => {
            handleFetchNotifyApp({ page: 1 });
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE SHOW NOTIFY APP
  const handleShowNotifyApp = (_data: NotificationModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };

    notificationRepository
      .showNotification(params)
      .then((res: any) => {
        const result: NotificationModel = res;

        setFormNotifyApp({
          UID: result.UID,
          title: result.title,
          type: result.type,
          status: result.status,
          start_date: result.startDate,
          end_date: result.endDate,
          channels: result.channels,
          one_time_notification: result.oneTimeNotification,
          cta: result.cta,
          message: result.message,
          media_uid: result.getMedia
        });
        handleUpdateNotifyAppModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE ADD DATA
  const handleStoreDataNotification = async () => {
    if (formNotifyApp.title || formNotifyApp.status) {
      const x = alertToast.loadingAlert('');

      let mediaUUID = '';
      if (formNotifyApp.media) {
        mediaUUID = await UploadMedia(formNotifyApp.media, MEDIA_PATH.NOTIFICATION);
      } else {
        mediaUUID = '';
      }

      const newRecipiant = [];
      for (let x = 0; userSelected.length > x; x++) {
        newRecipiant.push({ user_uid: userSelected[x].uuid });
      }

      const params = {
        category: formNotifyApp.category,
        channels: formNotifyApp.channels,
        cta: formNotifyApp.cta,
        end_date: formatDateTimeParam(formNotifyApp.end_date),
        start_date: formatDateTimeParam(formNotifyApp.start_date),
        flags: formNotifyApp.flags,
        media_uid: mediaUUID,
        message: formNotifyApp.message,
        message_html: formNotifyApp.message_html,
        meta: formNotifyApp.meta,
        one_time_notification: formNotifyApp.one_time_notification,
        recipient_type: formNotifyApp.recipient_type,
        status: formNotifyApp.status,
        title: formNotifyApp.title,
        type: formNotifyApp.type,
        recipients: RECIPIENT_TYPE.SELECTED ? newRecipiant : null
      };

      notificationRepository
        .storeNotification(params)
        .then((result: any) => {
          alertToast.updateLoading(x);
          alertToast.successAlert('Success');
          handleAddNotifyAppModal.setState(false);
          handleFetchNotifyApp({ page: 1 });
        })
        .catch(error => {
          alertToast.updateLoading(x);
          alertToast.errorAlert(error);
        });
    } else {
      alertToast.warningAlertValidation();
    }
  };

  //HANDLE UPDATE DATA
  const handleUpdateDataNotification = async () => {
    if (formNotifyApp.title || formNotifyApp.status) {
      const x = alertToast.loadingAlert('');

      let mediaUUID = '';
      if (formNotifyApp.media) {
        mediaUUID = await UploadMedia(formNotifyApp.media, MEDIA_PATH.NOTIFICATION);
      } else {
        mediaUUID = '';
      }

      const params = {
        uid: formNotifyApp.UID,
        category: NOTIFY_CATEGORY.GENERAL,
        channels: formNotifyApp.channels,
        cta: formNotifyApp.cta,
        end_date: formNotifyApp.end_date,
        start_date: formNotifyApp.start_date,
        flags: formNotifyApp.flags,
        media_uid: mediaUUID,
        message: formNotifyApp.message,
        message_html: formNotifyApp.message_html,
        meta: formNotifyApp.meta,
        one_time_notification: formNotifyApp.one_time_notification,
        recipient_type: formNotifyApp.recipient_type,
        status: formNotifyApp.status,
        title: formNotifyApp.title,
        type: formNotifyApp.type
      };

      notificationRepository
        .updateNotification(params)
        .then((result: any) => {
          alertToast.updateLoading(x);
          alertToast.successAlert('Success');
          handleUpdateNotifyAppModal.setState(false);
          handleFetchNotifyApp({ page: 1 });
        })
        .catch(error => {
          alertToast.updateLoading(x);
          alertToast.errorAlert(error);
        });
    } else {
      alertToast.warningAlertValidation();
    }
  };

  const contextValue: NotifyAppContextValue = {
    userSelected,
    setUserSelected,
    setUsers,
    users,
    handleFetchUser,
    loading,
    handleDeleteNotifyApp,
    formNotifyApp,
    setFormNotifyApp,
    handleStoreDataNotification,
    notifyAppPagination,
    notifyApps,
    handleUpdateNotifyAppModal,
    handleAddNotifyAppModal,
    handleFetchNotifyApp,
    handleShowNotifyApp,
    handleUpdateDataNotification,
    handleShowUserListModal
  };

  return <NotifyAppContext.Provider value={contextValue}>{children}</NotifyAppContext.Provider>;
};

const useNotifyAppContext = (): NotifyAppContextValue => {
  const context = useContext(NotifyAppContext);
  if (!context) {
    throw new Error('useNotifyAppContext must be used within a NotifyAppProvider');
  }
  return context;
};

export { NotifyAppProvider, useNotifyAppContext };
