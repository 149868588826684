import { ReactNode } from 'react';
import styles from './Card.module.css';
import clsx from 'clsx';

interface CardProps {
  children?: ReactNode;
  className?: string;
}

const Card = (props: CardProps) => {
  const { children, className } = props;
  return (
    <div className={clsx(styles['card'])}>
      <div className={`p-[16px] ${className}`}>{children}</div>
      {/* {children ? (
        <div className="px-[24px] py-[12px] flex justify-end border-t border-border-secondary">
          {children}
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default Card;
