import QRCode from 'react-qr-code';

interface QRCodeProps {
  value: string;
  height?: number;
  width?: number;
  size?: number;
}

const QRCodeView = (props: QRCodeProps) => {
  const { size = 200, value, height = 50, width = 1 } = props;
  return <QRCode size={size} value={value} height={height} width={width} />;
};

export default QRCodeView;
