import DChart from '@/delivery/components/atoms/chart/chart.tsx';
import { formatDate } from '@/infrastructure/helper/formatDate.ts';
import { MemberChartModel } from '@/domain/model/dashboard/member_chart_model.ts';

interface ChartRegisterVerifiedProps {
  data: MemberChartModel[];
}

const ChartRegisterVerified = (props: ChartRegisterVerifiedProps) => {
  const { data } = props;

  const state: any = {
    series: [
      {
        name: 'Register User',
        data: data?.map(item => item.count)
      }
      // {
      //   name: 'Verified User',
      //   data: [13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27]
      // }
    ],
    options: {
      colors: ['#426B3C', '#67A85E'],
      dataLabels: {
        enabled: false
      },
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          columnWidth: '50%',
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            enabled: false,
            total: {
              enabled: false,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        }
      },
      xaxis: {
        categories: data?.map(item => formatDate(item.date))
      },
      yaxis: {
        title: {
          text: 'Count'
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          formatter: function (val: any) {
            return val;
          }
        }
      },
      legend: {
        position: 'top'
      },
      fill: {
        opacity: 1
      }
    }
  };

  return (
    <div>
      <DChart options={state.options} series={state.series} type="bar" height={350} />
    </div>
  );
};

export default ChartRegisterVerified;
