import { BaseModel } from '@/domain/model/base_model.ts';
import { PaymentChannelModel } from '@/domain/model/payment/payment_channel_model.ts';
import { formatDateTime } from '@/infrastructure/helper/formatDate.ts';

export class TransactionVendorModel extends BaseModel {
  vendorUID: string;
  trxUID: string;
  referenceID: string;
  amount: number;
  receivedAmount: number;
  paymentChannelUID: string;
  vendorRefID: string;
  paymentCode: string;
  paymentName: string;
  paymentURL: string;
  paymentNotes: string;
  paymentExpired: string;
  paymentChannel: PaymentChannelModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    vendorUID: string,
    trxUID: string,
    referenceID: string,
    amount: number,
    receivedAmount: number,
    paymentChannelUID: string,
    vendorRefID: string,
    paymentCode: string,
    paymentName: string,
    paymentURL: string,
    paymentNotes: string,
    paymentExpired: string,
    paymentChannel: PaymentChannelModel
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.vendorUID = vendorUID;
    this.trxUID = trxUID;
    this.referenceID = referenceID;
    this.amount = amount;
    this.receivedAmount = receivedAmount;
    this.paymentChannelUID = paymentChannelUID;
    this.vendorRefID = vendorRefID;
    this.paymentCode = paymentCode;
    this.paymentName = paymentName;
    this.paymentURL = paymentURL;
    this.paymentNotes = paymentNotes;
    this.paymentExpired = paymentExpired;
    this.paymentChannel = paymentChannel;
  }

  get getPaymentExpired(): string {
    if (this.paymentExpired) {
      return formatDateTime(this.paymentExpired);
    }

    return '-';
  }

  public static fromJson(data: any): any | TransactionVendorModel {
    try {
      return new TransactionVendorModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.vendor_uid,
        data.trx_uid,
        data.reference_id,
        data.amount,
        data.received_amount,
        data.payment_channel_uid,
        data.vendor_ref_id,
        data.payment_code,
        data.payment_name,
        data.payment_url,
        data.payment_notes,
        data.payment_expired,
        data.payment_channel ? PaymentChannelModel.fromJson(data.payment_channel) : null
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): TransactionVendorModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(TransactionVendorModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
