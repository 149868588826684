import Navbar from '@/delivery/components/organisms/navbar/navbar';
import MasterMemberLoyalty from './master_member_loyalty';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useTiereContext } from '@/delivery/ui/admin/master/member_loyalty/context/member_loyalty_context.tsx';
import DialogAddMemberLoyalty from '@/delivery/ui/admin/master/member_loyalty/dialog/dialog_add_member_loyalty.tsx';
import Icon from '@/domain/constant/icon.tsx';

const MasterMemberLoyaltyPage = () => {
  const { handleAddTierModal } = useTiereContext();
  return (
    <div>
      <Navbar label={'Master Member Loyalty'}>
        <Button prefixIcon={Icon.plus} label="Add Member Loyalty" onClick={() => handleAddTierModal.setState(true)} />
      </Navbar>
      <MasterMemberLoyalty />

      {handleAddTierModal.state && <DialogAddMemberLoyalty modal={handleAddTierModal} />}
    </div>
  );
};

export default MasterMemberLoyaltyPage;
