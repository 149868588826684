import DreamGoldInterface, { DreamGoldValue } from '@/domain/repository/interface/dream_gold_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { DreamGoldModel } from '@/domain/model/dream_gold_model.ts';
import { DreamGold } from '@/domain/constant/api_list';

export class DreamGoldRepository implements DreamGoldInterface {
  api = new ApiClient();

  async fetchDreamGold(data: DreamGoldValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(DreamGold.FETCH, {
            params: data
          })
          .then(result => {
            const data = DreamGoldModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showDreamGold(data: DreamGoldValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(DreamGold.SHOW, {
            params: data
          })
          .then(result => {
            const data = DreamGoldModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeDreamGold(data: DreamGoldValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(DreamGold.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkDreamGold(data: DreamGoldValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(DreamGold.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateDreamGold(data: DreamGoldValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(DreamGold.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteDreamGold(data: DreamGoldValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(DreamGold.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
