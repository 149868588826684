import TablePaymentChannel from '@/delivery/ui/admin/settings/open_channel/table/table_payment_channel.tsx';

const OpenChannel = () => {
  return (
    <div>
      <TablePaymentChannel />

      {/*<div className="mt-[24px]">*/}
      {/*  <TablePaymentChannelFee />*/}
      {/*</div>*/}
    </div>
  );
};

export default OpenChannel;
