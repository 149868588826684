import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Chips from '@/delivery/components/atoms/chips/chips';
import { useMemberVefiryContext } from './context/member_verify_context';
import DialogDetailMemberVerify from './dialog/detail_member_verify/dialog_detail_member_verify';
import { useEffect } from 'react';
import Pagination from '@/delivery/components/atoms/pagination/pagination';
import { UserVerificationModel } from '@/domain/model/user_verification_model.ts';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { Routing } from '@/domain/constant/router_path.ts';
import { useNavigate } from 'react-router-dom';
import FilterMemberVerifyList from '@/delivery/ui/admin/data_member/member_verify/filter_member_verify.tsx';

const MemberVerify = () => {
  const navigate = useNavigate();
  const {
    handleDetailMemberVerifyModal,
    handleFetchUserDataVerification,
    userVerifications,
    userVerificationPagination
    // handleShowMemberVerify
  } = useMemberVefiryContext();

  useEffect(() => {
    handleFetchUserDataVerification({ page: 1, limit: 10 });
  }, []);

  return (
    <div>
      <div>
        <div className="flex justify-end mb-[12px]">
          <FilterMemberVerifyList />
        </div>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Request Date</Th>
              <Th>Member</Th>
              <Th>Bank Account</Th>
              {/*<Th>Trx Count</Th>*/}
              <Th>Balance (gr)</Th>
              <Th>Status</Th>
              <Th>Update By</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userVerifications.map((item: UserVerificationModel, index) => (
              <Tr>
                <Td>
                  <Typography size={12} fontWeight={400}>
                    {item.getCreatedAt}
                  </Typography>
                </Td>
                <Td>
                  <Typography size={12} fontWeight={500}>
                    <span className={'text-text-primary'}>{item?.user?.serialID}</span>
                  </Typography>
                  <Typography size={12} fontWeight={500}>
                    {item.user?.fullName}
                  </Typography>
                  <Typography size={12} fontWeight={500}>
                    {item.user?.email}
                  </Typography>
                  <Typography size={12} fontWeight={400}>
                    {item.user?.gender}
                  </Typography>
                </Td>
                <Td>
                  <div className="text-right whitespace-nowrap">
                    <p className="text-[12px] font-[400] text-text-tertiary-invert">{item.masterBank?.name}</p>
                    <p>
                      <span className="text-[12px] font-[400] text-text-tertiary-invert">Acc. Name: </span>
                      <span className="text-[12px] font-[600] text-text-secondary-invert">
                        {item.accountHolderName}
                      </span>
                    </p>
                    <p>
                      <span className="text-[12px] font-[400] text-text-tertiary-invert">Acc. No: </span>
                      <span className="text-[12px] font-[600] text-text-secondary-invert">{item.accountNumber}</span>
                    </p>
                  </div>
                </Td>
                {/*<Td>10</Td>*/}
                <Td>
                  <p className="text-[12px] font-[600] text-text-primary-invert">
                    {item.user?.balance?.getTruncateAmountDecimal}
                  </p>
                  <p className="text-[12px] font-[400] text-text-tertiary-invert">{item.user?.balance.amount}</p>
                </Td>
                <Td>
                  {item.status === STATUS_CORPORATE.REQUEST ? (
                    <Chips label={item.status} type={'info-light'} className="leading-4" />
                  ) : (
                    <>
                      {item.status === STATUS_CORPORATE.VERIFIED ? (
                        <Chips label={item.status} type={'primary-light'} className="leading-4" />
                      ) : (
                        <Chips label={item.status} type={'danger-light'} className="leading-4" />
                      )}
                    </>
                  )}

                  {/*<p className="text-[12px] font-[400] text-text-tertiary-invert">*/}
                  {/*  2023-05-20 20:02:46*/}
                  {/*</p>*/}
                </Td>
                <Td>{item.updatedByUser}</Td>
                <Td>
                  <Button
                    iconButton
                    iconType={'view'}
                    label="Preview"
                    type="secondary"
                    onClick={() => navigate(Routing.DATA_MEMBER.MEMBER_VERIFY_DETAIL + item.UID)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            page={userVerificationPagination?.page || 1}
            totalPages={userVerificationPagination?.totalPages || 1}
            handlePagination={page => {
              handleFetchUserDataVerification({
                page: page
              });
            }}
          />
        </Tfooter>
      </div>

      {handleDetailMemberVerifyModal.state && <DialogDetailMemberVerify modal={handleDetailMemberVerifyModal} />}
    </div>
  );
};

export default MemberVerify;
