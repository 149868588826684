import { ApiClientInterface } from '@/domain/interface/api_client';
import AxiosClient from '@/infrastructure/consume_api/axios';
import SignatureHelper from '@/infrastructure/helper/signatureHelper.tsx';
import paramNullChecker from '@/infrastructure/helper/paramNullChecker.ts';
import { paramSerializer } from '@/infrastructure/helper/paramSerializer.ts';
// import encryptRequest from '@/infrastructure/helper/encryption/encryptData.ts';
// import { PAIRING_KEY } from '@/domain/constant/setting.ts';
// import encryptRequest from '@/infrastructure/helper/encryption/encryptData.ts';

interface ConfigParams {
  responseType?: any;
  contentType?: string;
  authorization?: string;
  activeUrl?: string | null;
}
// const data = { date_range: ['2024-08-01', '2024-08-31'], limit: -1, page: 1, sort: 'id asc' };

export class ApiClient implements ApiClientInterface {
  // async post(url: string, data: any, config?: ConfigParams) {
  //   const encData = await encryptRequest(data, PAIRING_KEY);
  //
  //   return await AxiosClient({ config:{...config} }).post(
  //     url,
  //     {
  //       req: encData.encryptedData
  //     },
  //     {
  //       responseType: config?.responseType || undefined,
  //     }
  //   );
  // }

  async post(url: string, data: any, config?: ConfigParams) {
    // const encData = await encryptRequest(data, PAIRING_KEY);
    const signature = SignatureHelper(url, data, window.navigator.userAgent);

    return await AxiosClient({ config: { ...config, signature } }).post(url, data, config);
  }

  async get(url: string, data: any, config?: ConfigParams) {
    if (data?.params) {
      const params = data?.params;
      const newParams = paramNullChecker(params);
      const u = new URLSearchParams(paramSerializer(newParams)).toString();
      url = url + '?' + u;
    }
    const signature = SignatureHelper(url, undefined, window.navigator.userAgent);
    return await AxiosClient({
      config: {
        ...config,
        signature
      }
    }).get(url);
  }

  async put(url: string, data: any, config?: ConfigParams) {
    const signature = SignatureHelper(url, data, window.navigator.userAgent);

    return await AxiosClient({
      config: {
        ...config,
        signature
      }
    }).put(url, data);
  }

  async delete(url: string, data: any, config?: ConfigParams) {
    const signature = SignatureHelper(url, data, window.navigator.userAgent);

    return await AxiosClient({
      config: {
        ...config,
        signature
      }
    }).delete(url, { data });
  }

  async patch(url: string, data: any, config?: ConfigParams) {
    const signature = SignatureHelper(url, data, window.navigator.userAgent);
    return await AxiosClient({
      config: {
        ...config,
        signature
      }
    }).patch(url, data);
  }
}
