import { BaseModel } from '@/domain/model/base_model.ts';
import { UserModel } from '@/domain/model/user_model.ts';
import { ChipType } from '@/domain/constant/component/chip_colors.ts';
import { DCA_STATUS } from '@/domain/constant/dca/dca_status.ts';
import { formatDateTime } from '@/infrastructure/helper/formatDate.ts';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';

export class DCARequestModel extends BaseModel {
  serialID: string;
  bankName: string;
  accountNumber: string;
  accountName: string;
  fiat: number;
  gram: number;
  durationMonth: number;
  fee: number;
  dueDate: string;
  totalFiat: number;
  status: string;
  user: UserModel;
  requestDate: string;
  approvedDate: string;
  rate: number;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    serialID: string,
    bankName: string,
    accountNumber: string,
    accountName: string,
    fiat: number,
    gram: number,
    durationMonth: number,
    fee: number,
    dueDate: string,
    totalFiat: number,
    status: string,
    user: UserModel,
    requestDate: string,
    approvedDate: string,
    rate: number
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.serialID = serialID;
    this.bankName = bankName;
    this.accountNumber = accountNumber;
    this.accountName = accountName;
    this.fiat = fiat;
    this.gram = gram;
    this.durationMonth = durationMonth;
    this.fee = fee;
    this.dueDate = dueDate;
    this.totalFiat = totalFiat;
    this.status = status;
    this.user = user;
    this.requestDate = requestDate;
    this.approvedDate = approvedDate;
    this.rate = rate;
  }

  get getStatusColor(): ChipType {
    if (this.status === DCA_STATUS.REQUEST) {
      return 'info-light';
    }

    if (this.status === DCA_STATUS.APPROVED) {
      return 'primary-light';
    }
  }

  get getRequestDate(): string {
    if (this.requestDate) {
      return formatDateTime(this.requestDate);
    }

    return '-';
  }

  get getFiatRupiah(): string {
    if (this.fiat) {
      return formatRupiah(this.fiat);
    }

    return '-';
  }

  get getApprovedDate(): string {
    if (this.approvedDate) {
      return formatDateTime(this.approvedDate);
    }

    return '-';
  }

  get getFeeRupiah(): string {
    if (this.fee) {
      return formatRupiah(this.fee);
    }

    return '-';
  }

  get getRateRupiah(): string {
    if (this.rate) {
      return formatRupiah(this.rate);
    }

    return '-';
  }

  get getBalanceGram20Percent(): number {
    if (this.user.balanceGram) {
      return (this.user.balanceGram * 20) / 100;
    }

    return 0;
  }

  public static fromJson(data: any): any | DCARequestModel {
    try {
      return new DCARequestModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.update_by,
        data.deleted_at,
        data.deleted_by,
        data.serial_id,
        data.bank_name,
        data.account_number,
        data.account_name,
        data.fiat,
        data.gram,
        data.duration_month,
        data.fee,
        data.due_date,
        data.total_fiat,
        data.status,
        data.user ? UserModel.fromJson(data.user) : null,
        data.request_date,
        data.approved_date,
        data.rate
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): DCARequestModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(DCARequestModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
