import { Table, Tbody, Th, Thead, Theader, Tr, Td, Tfooter } from '@/delivery/components/molecules/table/table.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useGoldPieceContext } from '@/delivery/ui/admin/master/gold_piece/context/gold_piece_context.tsx';
import { useEffect, useState } from 'react';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { GoldPieceModel } from '@/domain/model/gold_piece_model.ts';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { Routing } from '@/domain/constant/router_path.ts';
import { useNavigate } from 'react-router-dom';
import STATUS_CONSTANT from '@/domain/constant/status_constanat.ts';

const GoldPiece = () => {
  const navigate = useNavigate();
  const { handleDeleteGoldPrice, handleFetchGoldPiece, goldPieces, goldPiecePagination } = useGoldPieceContext();

  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    handleFetchGoldPiece({});
  }, []);

  useEffect(() => {
    if (search !== '') {
      handleFetchGoldPiece({ name: search });
    } else {
      handleFetchGoldPiece({});
    }
  }, [search]);

  return (
    <div className="mt-[32px]">
      <Theader>
        <TextField
          search
          placeholder={'search name'}
          onChange={e => {
            setTimeout(() => {
              setSearch(e.target.value);
            }, 1000);
          }}
        />
      </Theader>
      <Table className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Gram (gr)</Th>
            <Th>Status</Th>
            <Th>Notes</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {goldPieces.map((item: GoldPieceModel, index) => (
            <Tr key={index}>
              <Td>{item.name}</Td>
              <Td>{item.gram}</Td>
              <Td>
                <Chips
                  label={item.status}
                  type={STATUS_CONSTANT.active === item.status ? 'primary-light' : 'danger-light'}
                />
              </Td>
              <Td>{item.notes}</Td>
              <Td>
                <Button iconButton iconType="delete" onClick={() => handleDeleteGoldPrice({ uid: item.UID })} />
                <Button
                  iconButton
                  iconType="edit"
                  onClick={() => navigate(Routing.MASTER.GOLD_PIECE_DETAIL + item.UID)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={goldPiecePagination?.page || 1}
          totalPages={goldPiecePagination?.totalPages || 1}
          handlePagination={page => {
            handleFetchGoldPiece({
              page: page
            });
          }}
        />
      </Tfooter>
    </div>
  );
};

export default GoldPiece;
