import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode } from 'react';

interface MediaContextProps {
  children: ReactNode;
}

interface CounterOfflineContextValue {
  handleAddMediaModal: ModalObject;
  handleSubmitAddCounterGold: () => void;
}

const MediaContext = createContext<CounterOfflineContextValue | null>(null);

const MediaProvider: React.FC<MediaContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const handleAddMediaModal = useVisibleComponent(false);

  //HANDLE ADD STOCK
  const handleSubmitAddCounterGold = () => {
    alertToast.loadingAlert('');
  };

  const contextValue: CounterOfflineContextValue = {
    handleAddMediaModal,
    handleSubmitAddCounterGold
  };

  return <MediaContext.Provider value={contextValue}>{children}</MediaContext.Provider>;
};

const useMediaContext = (): CounterOfflineContextValue => {
  const context = useContext(MediaContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a MediaProvider');
  }
  return context;
};

export { MediaProvider, useMediaContext };
