import styles from './Textarea.module.css';
import clsx from 'clsx';

interface TextFieldProps {
  className?: string;
  placeholder?: string;
  label?: string;
  value?: string | number;
  block?: boolean;
  disabled?: boolean;
  width?: number;
  height?: number;
  onChange?: (e: any) => void;
}

const Textarea = (props: TextFieldProps) => {
  const { onChange, value, placeholder, className, label, block = false, disabled = false, width, height } = props;

  const classProps = clsx(
    className,
    styles['input'],
    `${disabled ? styles['disabled'] : ''}`,
    `${block ? 'w-full' : ''}`
  );

  return (
    <div className={`${block ? 'w-full' : ''} flex flex-col`}>
      {label ? <div className="text-text-secondary-invert text-[14px] text-[500] mb-[6px]">{label}</div> : ''}

      <div className="relative">
        <textarea
          style={{ width: width, height: height }}
          value={value}
          disabled={disabled}
          className={classProps}
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Textarea;
