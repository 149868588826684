import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card.tsx';
import formatNumberWithDot from '@/infrastructure/helper/formatNumberWithDot.ts';
import { useDashboardMemberContext } from '@/delivery/ui/admin/dashboard/member/context/dashbord_member_context.tsx';

const SummaryMember = () => {
  const {
    usersNumberRequest,
    usersNumberUserTransaction,
    loadingUserUserTransaction,
    usersNumberUnverified,
    usersNumberVerified,
    loadingUserVerified,
    usersNumberTotalActive,
    usersNumberTotalRegister,
    loadingUserRegister,
    usersNumberTotalNotActive,
    usersNumberUserNonTransaction
  } = useDashboardMemberContext();
  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-[24px]">
      <SimpleCard
        loading={loadingUserRegister}
        title="Total User Register"
        bigTitle={formatNumberWithDot(usersNumberTotalRegister)}
      />
      <SimpleCard
        loading={loadingUserRegister}
        title="Total User Aktif"
        bigTitle={formatNumberWithDot(usersNumberTotalActive)}
      />
      <SimpleCard
        loading={loadingUserRegister}
        title="Total User Non Aktif"
        bigTitle={formatNumberWithDot(usersNumberTotalNotActive)}
      />
      <SimpleCard
        loading={loadingUserRegister}
        title="Total Request Verifikasi"
        bigTitle={formatNumberWithDot(usersNumberRequest)}
      />
      <SimpleCard
        loading={loadingUserVerified}
        title="Total User Verified"
        bigTitle={formatNumberWithDot(usersNumberVerified)}
      />
      <SimpleCard
        loading={loadingUserVerified}
        title="Total User Unferified"
        bigTitle={formatNumberWithDot(usersNumberUnverified)}
      />
      <SimpleCard
        loading={loadingUserUserTransaction}
        title="Total User Transaksi"
        bigTitle={formatNumberWithDot(usersNumberUserTransaction)}
      />
      <SimpleCard
        loading={loadingUserUserTransaction}
        title="Total User Non Transaksi"
        bigTitle={formatNumberWithDot(usersNumberUserNonTransaction)}
      />
    </div>
  );
};

export default SummaryMember;
