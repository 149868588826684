import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { IoWarningOutline } from 'react-icons/io5';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useAuthContext } from '@/delivery/ui/login/context/auth_context.tsx';

const DialogLogout = (props: ModalDialogProps) => {
  const { handleConfirmLogoutModal, handleLogOut } = useAuthContext();

  return (
    <ModalDialog noHeader size="xsmall" title="Lock Member Balance" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <div
          className={
            'font-weight-600 text-[24px] bg-bg-amber text-text-warning rounded-full w-[48px] h-[48px] flex justify-center items-center'
          }
        >
          <IoWarningOutline />
        </div>
        <div>
          <Typography fontWeight={600} size={16}>
            Logout
          </Typography>
          <Typography fontWeight={400} size={14}>
            Apakah anda yakin ingin logout?
          </Typography>
        </div>
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleConfirmLogoutModal.setState(false)} />
        <Button type={'danger'} label="Logout" onClick={() => handleLogOut()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogLogout;
