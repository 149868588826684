import {
  Table,
  Tbody,
  TBox,
  Td,
  Tfooter,
  Th,
  Thead,
  Theader,
  Tr
} from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { VendingMachineRackModel } from '@/domain/model/vending_machine_rack_model.ts';
import { useVendingMachineRackContext } from '@/delivery/ui/admin/transaction/take_gold/vending_machine/context/vending_machine_rack_context.tsx';
import DialogAddVendingMachineRack from '@/delivery/ui/admin/transaction/take_gold/vending_machine/detail/vending_machine_rack/dialog_add_rack.tsx';
import DialogUpdateVendingMachineRack from '@/delivery/ui/admin/transaction/take_gold/vending_machine/detail/vending_machine_rack/dialog_update_rack.tsx';

const TableVendingMachineRack = () => {
  const { uid } = useParams();
  const {
    handleShowVendingMachineRack,
    handleUpdateVendingMachineRackModal,
    handleAddVendingMachineRackModal,
    vendingMachineRackPagination,
    vendingMachineRacks,
    handleFetchVendingMachineRack,
    handleDeleteVendingMachineRack
  } = useVendingMachineRackContext();

  useEffect(() => {
    handleFetchVendingMachineRack({ vending_machine_uid: uid });
  }, []);
  return (
    <TBox>
      <Theader>
        <Typography size={16} fontWeight={600} type={'secondary-invert'}>
          Vending Machine Rack
        </Typography>
        <Button
          prefixIcon={Icon.plus}
          label={'Add Rack'}
          onClick={() => handleAddVendingMachineRackModal.setState(true)}
        />
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Rack</Th>
            <Th>Stock</Th>
            <Th>Gold Piece</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {vendingMachineRacks?.map((item: VendingMachineRackModel, index: number) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.rack}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.stock}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.goldPiece.name}
                </Typography>
              </Td>
              <Td>
                <Button iconButton iconType={'edit'} onClick={() => handleShowVendingMachineRack(item)} />
                <Button iconButton iconType={'delete'} onClick={() => handleDeleteVendingMachineRack(item)} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={vendingMachineRackPagination?.page || 1}
          totalPages={vendingMachineRackPagination?.totalPages || 1}
          handlePagination={page => {
            handleFetchVendingMachineRack({
              page: page,
              uid: uid
            });
          }}
        />
      </Tfooter>

      {handleUpdateVendingMachineRackModal.state && (
        <DialogUpdateVendingMachineRack modal={handleUpdateVendingMachineRackModal} />
      )}

      {handleAddVendingMachineRackModal.state && (
        <DialogAddVendingMachineRack modal={handleAddVendingMachineRackModal} />
      )}
    </TBox>
  );
};

export default TableVendingMachineRack;
