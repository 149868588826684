import Button from '@/delivery/components/atoms/button/button';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import { TabContent, TabPane, TabPaneList } from '@/delivery/components/atoms/tab_pane/tab_pane';
import { useState } from 'react';

//icons
import { BsPerson } from 'react-icons/bs';
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { LiaMapSolid } from 'react-icons/lia';
import { IoServerOutline } from 'react-icons/io5';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { LuNetwork } from 'react-icons/lu';

//tabs
import TabMemberProfile from '@/delivery/ui/admin/data_member/member_list/dialog/dialog_member_list/tabs/tab_member_profile.tsx';
import TabMemberAddress from '@/delivery/ui/admin/data_member/member_list/dialog/dialog_member_list/tabs/tab_member_address.tsx';

const DialogDetailMemberList = (props: ModalDialogProps) => {
  const [tabPage, setTabPage] = useState(0);
  const data = [
    {
      prefixIcon: <BsPerson />,
      label: 'Profile',
      index: 0
      // handleClick: () => {}
    },
    {
      prefixIcon: <LiaMapSolid />,
      label: 'Address',
      index: 1
      // handleClick: () => {}
    },
    {
      prefixIcon: <BsFillPersonCheckFill />,
      label: 'Data Verify',
      index: 2
      // handleClick: () => {}
    },
    {
      prefixIcon: <IoServerOutline />,
      label: 'Balance',
      index: 3
      // handleClick: () => {}
    },
    {
      prefixIcon: <IoDocumentTextOutline />,
      label: 'Ahli Waris',
      index: 4
      // handleClick: () => {}
    },
    {
      prefixIcon: <LuNetwork />,
      label: 'Data Referral',
      index: 5
      // handleClick: () => {}
    }
  ];

  return (
    <ModalDialog size="large" title="Data Member" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TabPane>
          {data?.map((item, index) => (
            <TabPaneList
              prefixIcon={item.prefixIcon}
              label={item.label}
              index={index}
              tab={tabPage}
              handleClick={() => setTabPage(index)}
            />
          ))}
        </TabPane>

        <TabContent index={0} tab={tabPage}>
          <TabMemberProfile />
        </TabContent>
        <TabContent index={1} tab={tabPage}>
          <TabMemberAddress />
        </TabContent>
        <TabContent index={2} tab={tabPage}>
          data verify
        </TabContent>
        <TabContent index={3} tab={tabPage}>
          balance
        </TabContent>
        <TabContent index={4} tab={tabPage}>
          ahli waris
        </TabContent>
        <TabContent index={5} tab={tabPage}>
          referral
        </TabContent>
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" />
        <Button label="Save changes" />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogDetailMemberList;
