// import PageNotFound from '@/delivery/assets/404_page.svg';
import { Image } from '@/delivery/components/atoms/image/image.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';

import { FaArrowLeft } from 'react-icons/fa6';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';

const PageNotFoundPage = () => {
  return (
    <div className={'flex w-full justify-center items-center h-[100vh]'}>
      <div className={'flex-col gap-[20px] justify-center'}>
        <Image width={750} url={'https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif'} />
        <div className={'justify-center flex mb-[20px]'}>
          <Typography size={18} fontWeight={400} type={'tertiary-invert'}>
            Halaman tidak ditemukan
          </Typography>
        </div>
        <div className={'justify-center flex'}>
          <Button label={'Kembali ke Halaman'} prefixIcon={<FaArrowLeft />} onClick={() => window.history.go(-1)} />
        </div>
      </div>
    </div>
  );
};

export default PageNotFoundPage;
