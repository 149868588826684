import { BaseModel } from './base_model';

export class MediaModel extends BaseModel {
  bucket: string;
  extension: string;
  filename: string;
  mime: string;
  path: string;
  size: number;
  storage: string;
  type: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    bucket: string,
    extension: string,
    filename: string,
    mime: string,
    path: string,
    size: number,
    storage: string,
    type: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);

    this.bucket = bucket;
    this.extension = extension;
    this.filename = filename;
    this.mime = mime;
    this.path = path;
    this.size = size;
    this.storage = storage;
    this.type = type;
  }

  public static fromJson(data: any): any | MediaModel {
    return new MediaModel(
      data.id,
      data.uid,
      data.uuid,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by,
      data.flags,
      data.bucket,
      data.extension,
      data.filename,
      data.mime,
      data.path,
      data.size,
      data.storage,
      data.type
    );
  }

  public static fromJsonList(data: any): MediaModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(MediaModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
