import { useEffect } from 'react';
import OpenChannelPage from '@/delivery/ui/admin/settings/open_channel';
import { OpenChannelProvider } from '@/delivery/ui/admin/settings/open_channel/context/open_channel_context.tsx';

const ViewOpenChannel = () => {
  useEffect(() => {
    document.title = 'Open Channel';
  }, []);

  return (
    <div>
      <OpenChannelProvider>
        <OpenChannelPage />
      </OpenChannelProvider>
    </div>
  );
};

export default ViewOpenChannel;
