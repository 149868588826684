import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { TierRepository } from '@/domain/repository/tier_repository.ts';
import { TierModel } from '@/domain/model/tier_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';

interface DialogContextProps {
  children: ReactNode;
}

interface TierValueParams {
  page?: number;
  limit?: number;
  name?: string;
}

interface FormTierInterface {
  uid?: string;
  description?: string;
  gram_minimal?: number;
  name?: string;
  maximum_sell?: number;
  maximum_sell_daily?: number;
  maximum_transfer?: number;
  maximum_transfer_daily?: number;
}

interface TierValue {
  formTier: FormTierInterface;
  setFormTier: Dispatch<SetStateAction<FormTierInterface>>;
  // tier: FormTierInterface | undefined;
  tiers: TierModel[];
  tierPagination?: PaginationModel;
  handleAddTierModal: ModalObject;
  handleUpdateTierModal: ModalObject;
  handleFetchTier: (_data: TierValueParams) => void;
  handleShowTier: (_data: TierModel) => void;
  handleUpdateTier: () => void;
  handleAddTier: () => void;
  handleDeleteTier: (_data: TierModel) => void;
}

const TierContext = createContext<TierValue | null>(null);

const tierRepository = new TierRepository();

const TierProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const handleAddTierModal = useVisibleComponent(false);
  const handleUpdateTierModal = useVisibleComponent(false);

  // const [tier, setTier] = useState<TierModel>();
  const [tiers, setTiers] = useState<TierModel[]>([]);
  const [tierPagination, setTierPagination] = useState<PaginationModel>();

  const [formTier, setFormTier] = useState<FormTierInterface>({});

  //FETCH TIER
  const handleFetchTier = (_data: TierValueParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data?.page ?? 1,
      limit: _data?.limit ?? 10,
      name: _data?.name ?? null
    };

    tierRepository
      .fetchTier(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setTiers(result.data);
        setTierPagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW TIER
  const handleShowTier = (_data: TierModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };

    tierRepository
      .showTier(params)
      .then((res: any) => {
        const result: TierModel = res;
        alertToast.updateLoading(x);
        setFormTier({
          uid: result.UID,
          name: result.name,
          maximum_transfer_daily: result.maximumTransferDaily,
          maximum_transfer: result.maximumTransfer,
          maximum_sell_daily: result.maximumSellDaily,
          maximum_sell: result.maximumSell,
          gram_minimal: result.gramMinimal,
          description: result.description
        });
        handleUpdateTierModal.setState(true);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //UPDATE TIER
  const handleUpdateTier = () => {
    const x = alertToast.loadingAlert('');

    tierRepository
      .updateTier(formTier)
      .then(() => {
        alertToast.updateLoading(x);
        handleFetchTier({ page: 1 });
        handleUpdateTierModal.setState(false);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //ADD TIER
  const handleAddTier = () => {
    const x = alertToast.loadingAlert('');

    tierRepository
      .storeTier(formTier)
      .then(() => {
        alertToast.updateLoading(x);
        alertToast.successAlert();
        handleAddTierModal.setState(false);
        handleFetchTier({ page: 1 });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //DELETE TIER
  const handleDeleteTier = (_data: TierModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const params = {
          uid: _data.UID
        };
        tierRepository
          .deleteTier(params)
          .then(() => {
            alertToast.successAlert();
            handleFetchTier({ page: 1 });
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: TierValue = {
    formTier,
    setFormTier,
    // tier,
    tiers,
    tierPagination,
    handleFetchTier,
    handleAddTierModal,
    handleUpdateTierModal,
    handleShowTier,
    handleUpdateTier,
    handleAddTier,
    handleDeleteTier
  };

  return <TierContext.Provider value={contextValue}>{children}</TierContext.Provider>;
};

const useTiereContext = (): TierValue => {
  const context = useContext(TierContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a TierProvider');
  }
  return context;
};

export { TierProvider, useTiereContext };
