import { useEffect } from 'react';
import EventReferralPage from '@/delivery/ui/admin/reseller_affiliate/event_referral';
import { EventReferralProvider } from '@/delivery/ui/admin/reseller_affiliate/event_referral/context/event_referral_context.tsx';

const ViewEventReferral = () => {
  useEffect(() => {
    document.title = 'Event Referral';
  }, []);

  return (
    <div>
      <EventReferralProvider>
        <EventReferralPage />
      </EventReferralProvider>
    </div>
  );
};

export default ViewEventReferral;
