const DaysList = [
  { UUID: 'Sunday', name: 'Sunday' },
  { UUID: 'Monday', name: 'Monday' },
  { UUID: 'Tuesday', name: 'Tuesday' },
  { UUID: 'Wednesday', name: 'Wednesday' },
  { UUID: 'Thursday', name: 'Thursday' },
  { UUID: 'Friday', name: 'Friday' },
  { UUID: 'Saturday', name: 'Saturday' }
];
export default DaysList;
