interface ProgressBarProps {
  progress: number;
}

const ProgressBar = (props: ProgressBarProps) => {
  const { progress } = props;
  return (
    <div className={'flex justify-between items-center gap-[3px] w-full'}>
      <div className="w-full bg-bg-primary-light rounded-full h-2.5 dark:bg-gray-700">
        <div className="w-full bg-bg-primary h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
      </div>
      <div className={' text-[12px]'}>{Math.round(progress)}%</div>
    </div>
  );
};

export default ProgressBar;
