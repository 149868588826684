import GoldStockVendingMachineInterface, {
  GoldStockVendingMachineValue
} from '@/domain/repository/interface/gold_stock_vending_machine.ts';
import { ApiClient } from '@/main/apiClient';
import { GoldStockVendingMachine } from '@/domain/constant/api_list';
import { VendingMachineStockSummaryModel } from '@/domain/model/vending_machine_stock_summary_model.ts';

export class GoldStockVendingMachineRepository implements GoldStockVendingMachineInterface {
  api = new ApiClient();

  async fetchGoldStockVendingMachineSummary(data: GoldStockVendingMachineValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(GoldStockVendingMachine.STOCK_SUMMARY, {
            params: data
          })
          .then(result => {
            const data = VendingMachineStockSummaryModel.fromJsonList(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchGoldStockVendingMachine(data: GoldStockVendingMachineValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(GoldStockVendingMachine.FETCH, {
            params: data
          })
          .then(result => {
            resolve(result.data.data.result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeGoldStockVendingMachine(data: GoldStockVendingMachineValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(GoldStockVendingMachine.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async syncGoldStockVendingMachine(data: GoldStockVendingMachineValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(GoldStockVendingMachine.SYNC, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
