import Button from '@/delivery/components/atoms/button/button';

import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';
import { useVerifyEMoneyContext } from '@/delivery/ui/admin/data_member/verify_e_money/context/verify_e_money_context.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import Icon from '@/domain/constant/icon.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';

const DialogDetailMemberVerify = (props: ModalDialogProps) => {
  const { verifyEMoney, formVerifyEMoney, setFormVerifyEMoney, handleApproveEMoney, handleRejectEMoney } =
    useVerifyEMoneyContext();
  return (
    <ModalDialog size="small" title="Verifikasi akun E-Money" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <div className={'grid grid-cols-2'}>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            Name
          </Typography>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            : {verifyEMoney?.accountHolderName ? verifyEMoney?.accountHolderName : verifyEMoney?.user.fullName}
          </Typography>
        </div>
        <Line />

        <div className={'grid grid-cols-2'}>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            Email
          </Typography>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            : {verifyEMoney?.user.email}
          </Typography>
        </div>
        <Line />

        <div className={'grid grid-cols-2'}>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            Phone
          </Typography>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            : {verifyEMoney?.user.phone}
          </Typography>
        </div>
        <Line />

        <div className={'grid grid-cols-2'}>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            E-Money Type
          </Typography>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            : {verifyEMoney?.masterBank.name}
          </Typography>
        </div>
        <Line />
        <div className={'grid grid-cols-2'}>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            E Money Number
          </Typography>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            : {verifyEMoney?.accountNumber}
          </Typography>
        </div>
        <Line />
        <div className={'grid grid-cols-2'}>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            E Money Image
          </Typography>
          <div className={'flex gap-[10px]'}>
            <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
              :
            </Typography>
            <Image url={verifyEMoney?.getMediaUrl} width={200} />
          </div>
        </div>
        <Line />
        <Textarea
          value={formVerifyEMoney.notes}
          label={'Notes'}
          block
          height={150}
          onChange={e => setFormVerifyEMoney({ ...formVerifyEMoney, notes: e.target.value })}
        />
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button prefixIcon={Icon.banned} type="danger" label="Reject" onClick={() => handleRejectEMoney()} />
        {verifyEMoney?.status !== STATUS_CORPORATE.VERIFIED && (
          <Button prefixIcon={Icon.check} label="Approve" onClick={() => handleApproveEMoney()} />
        )}
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogDetailMemberVerify;
