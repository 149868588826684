import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
// import Button from '@/delivery/components/atoms/button/button.tsx';
// import { FiDownloadCloud } from 'react-icons/fi';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { STATUS_TRANSACTION_LIST } from '@/domain/constant/transaction/status_transaction.ts';
import { useAffiliateTransactionContext } from '@/delivery/ui/admin/reseller_affiliate/affiliate_transaction/context/affiliate_transaction_context.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import { useEffect, useState } from 'react';

const FilterAffiliateTransaction = () => {
  const { handleFetchAffiliateTransaction, formFilterTransaction, setFormFilterTransaction } =
    useAffiliateTransactionContext();

  const [isResetting, setIsResetting] = useState(false);

  const handleReset = () => {
    setFormFilterTransaction({ search: '', status: '' });
    setIsResetting(true);
  };

  useEffect(() => {
    if (isResetting) {
      handleFetchAffiliateTransaction({ page: 1, limit: 10 });
      setIsResetting(false);
    }
  }, [setFormFilterTransaction, isResetting]);

  return (
    <div className="flex gap-[12px]">
      <Button type={'secondary'} prefixIcon={Icon.reset} label={'Reset Filter'} onClick={handleReset} />
      <ButtonFilter
        onClickApply={() => handleFetchAffiliateTransaction({})}
        size={'large'}
        titleFilter={'Filter Transaction'}
        label={'More Filter'}
      >
        <div className={'flex flex-col gap-[20px]'}>
          <div>
            <Select2
              defaultValue={formFilterTransaction.status}
              value={formFilterTransaction.status}
              isSearchable={false}
              options={STATUS_TRANSACTION_LIST.map(item => {
                return {
                  label: item.name,
                  value: item.UUID
                };
              })}
              block
              label={'Status'}
              onChange={e => setFormFilterTransaction({ ...formFilterTransaction, status: e.value })}
            />
          </div>
          <div className={'grid grid-cols-2 gap-[20px]'}>
            <Datepicker
              value={formFilterTransaction.transaction_at_start}
              label={'Start Date'}
              block
              onChange={e =>
                setFormFilterTransaction({
                  ...formFilterTransaction,
                  transaction_at_start: e
                })
              }
            />
            <Datepicker
              value={formFilterTransaction.transaction_at_end}
              label={'End Date'}
              block
              onChange={e =>
                setFormFilterTransaction({
                  ...formFilterTransaction,
                  transaction_at_end: e
                })
              }
            />
          </div>
        </div>
      </ButtonFilter>
      {/*<Button label="Export by Filter" type="secondary" prefixIcon={<FiDownloadCloud />} />*/}
    </div>
  );
};

export default FilterAffiliateTransaction;
