import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useBalanceContext } from '@/delivery/ui/admin/data_member/member_list/context/balance_context.tsx';

const TableLockGoldDCA = () => {
  const { balance } = useBalanceContext();
  return (
    <div>
      <Theader>
        <div>
          <Typography size={14} fontWeight={600}>
            Lock Emas DCA
          </Typography>
        </div>
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Total Emas (gr)</Th>
            <Th>Total Rupiah (Rp)</Th>
            <Th>Create Date</Th>
            <Th>Due Date</Th>
            {/*<Th>Action</Th>*/}
          </Tr>
        </Thead>
        <Tbody>
          {balance?.balanceDCA.length ? (
            <>
              {balance?.balanceDCA.map(item => (
                <Tr>
                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {item.gram}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {item.getAmountRupiah}
                    </Typography>
                  </Td>

                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {item.getCreatedAt}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {item.getReleaseAt}
                    </Typography>
                  </Td>
                  {/*<Td>*/}
                  {/*  <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                  {/*    <Button iconButton icon={<FiUnlock />} />*/}
                  {/*  </Typography>*/}
                  {/*</Td>*/}
                </Tr>
              ))}
            </>
          ) : (
            <Tr>
              <Td colSpan={5}>
                <Typography size={12} style={'italic'}>
                  belum memiliki lock emas by dca
                </Typography>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
};

export default TableLockGoldDCA;
