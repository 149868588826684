import { HmacSHA256, SHA256 } from 'crypto-js';
import { SIGNATURE_KEY } from '@/domain/constant/setting.ts';

// npm install crypto-js
// var fetch           = require('node-fetch'); // npm install node-fetch --save

export const formDataToJson = (formData: FormData): Record<string, any> => {
  const jsonObject: Record<string, any> = {};

  formData.forEach((value, key) => {
    jsonObject[key] = value;
  });

  return jsonObject;
};

export const objectToFormData = (obj: Record<string, any>) => {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};

const SignatureHelper = function (requestUrl: string, reqBody?: any, userAgent?: string) {
  const agent = userAgent ? userAgent : navigator.userAgent;
  const encryptedURL = SHA256(requestUrl);
  const encryptedAgent = SHA256(agent);
  let stringtosign = encryptedAgent + '' + encryptedURL;

  let body = '';
  let encryptedBody = null;
  if (reqBody != undefined) {
    if (typeof reqBody === 'object' && reqBody instanceof FormData) {
      reqBody = formDataToJson(reqBody);
    }

    body = JSON.stringify(reqBody);
    if (body != '') {
      encryptedBody = SHA256(body);
    }

    stringtosign = stringtosign + encryptedBody;
  }

  const signature = HmacSHA256(SIGNATURE_KEY, stringtosign);
  return signature.toString();
};

export default SignatureHelper;
