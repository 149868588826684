import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import { PaginationModel } from '@/domain/model/pagination_model';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';

import { WebPageModel } from '@/domain/model/web_page_model.ts';
import { WebPageRepository } from '@/domain/repository/web_page_repository.ts';
import { Routing } from '@/domain/constant/router_path.ts';
import { useNavigate } from 'react-router-dom';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';

interface WebPageContextContextProps {
  children: ReactNode;
}

interface WebPageValue {
  uid?: string;
  page?: number;
  limit?: number;
}

interface FormWebpageInterface {
  UID?: string;
  content?: string;
  flags?: string;
  meta?: string;
  title?: string;
  slug?: string;
}

interface WebPageContextValue {
  formWebPage: FormWebpageInterface;
  setFormWebPage: Dispatch<SetStateAction<FormWebpageInterface>>;
  webPages: WebPageModel[];
  webPagePagination?: PaginationModel;
  handleUpdateWebPageModal: ModalObject;
  handleAddWebPageModal: ModalObject;
  handleFetchWebPage: (_data: WebPageValue) => Promise<void>;
  handleStoreDataWebPage: () => void;
  handleShowWebPage: (_data: WebPageValue) => void;
  handleUpdateDataWebPage: () => void;
  handleDeleteWebPage: (_data: WebPageModel) => void;
}

const WebPageContext = createContext<WebPageContextValue | null>(null);

const webPageRepository = new WebPageRepository();

const WebPageProvider: React.FC<WebPageContextContextProps> = ({ children }) => {
  const navigate = useNavigate();
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [webPages, setWebPages] = useState<WebPageModel[]>([]);
  const [webPagePagination, setWebPagePagination] = useState<PaginationModel>();

  const [formWebPage, setFormWebPage] = useState<FormWebpageInterface>({});

  const handleUpdateWebPageModal = useVisibleComponent(false);
  const handleAddWebPageModal = useVisibleComponent(false);

  //HANDLE FETCH
  const handleFetchWebPage = async (_data: WebPageValue) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10
    };

    return webPageRepository
      .fetchWebPage(params)
      .then((result: any) => {
        setWebPages(result.data);
        setWebPagePagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW WEB PAGE
  const handleShowWebPage = (_data: WebPageValue) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.uid
    };

    webPageRepository
      .showWebPage(params)
      .then((res: any) => {
        const result: WebPageModel = res;
        setFormWebPage({
          UID: result.UID,
          title: result.title,
          slug: result.slug,
          content: result.content
        });
        handleUpdateWebPageModal.setState(true);
        navigate(Routing.WEB_ADMIN.WEB_PAGES_UPDATE + result.UID);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE ADD DATA
  const handleStoreDataWebPage = () => {
    const x = alertToast.loadingAlert('');
    const params: FormWebpageInterface = {
      content: formWebPage.content,
      title: formWebPage.title,
      slug: formWebPage.slug
    };

    webPageRepository
      .storeWebPage(params)
      .then((result: any) => {
        alertToast.successAlert('Success');
        handleAddWebPageModal.setState(false);
        handleFetchWebPage({ page: 1 });

        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE DATA
  const handleUpdateDataWebPage = () => {
    const params = {
      uid: formWebPage.UID,
      title: formWebPage.title,
      slug: formWebPage.slug,
      content: formWebPage.content
    };

    webPageRepository
      .updateWebPage(params)
      .then((result: any) => {
        alertToast.successAlert('Success');
        handleUpdateWebPageModal.setState(false);
        handleFetchWebPage({ page: 1 });
        navigate(Routing.WEB_ADMIN.WEB_PAGES);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE DELETE DATA
  const handleDeleteWebPage = (_data: WebPageModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const params = {
          uid: _data.UID
        };

        webPageRepository
          .deleteWebPage(params)
          .then((result: any) => {
            alertToast.successAlert('Success');

            handleFetchWebPage({ page: 1 });
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: WebPageContextValue = {
    formWebPage,
    setFormWebPage,
    handleStoreDataWebPage,
    webPagePagination,
    webPages,
    handleUpdateWebPageModal,
    handleAddWebPageModal,
    handleFetchWebPage,
    handleShowWebPage,
    handleUpdateDataWebPage,
    handleDeleteWebPage
  };

  return <WebPageContext.Provider value={contextValue}>{children}</WebPageContext.Provider>;
};

const useWebPageContext = (): WebPageContextValue => {
  const context = useContext(WebPageContext);
  if (!context) {
    throw new Error('useWebPageContext must be used within a WebPageProvider');
  }
  return context;
};

export { WebPageProvider, useWebPageContext };
