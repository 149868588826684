import Navbar from '@/delivery/components/organisms/navbar/navbar';
import TransactionList from './transaction_list';

const TransactionListPage = () => {
  return (
    <div>
      <Navbar label={'Transaction List'}></Navbar>
      <TransactionList />
    </div>
  );
};

export default TransactionListPage;
