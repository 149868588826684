import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { TransactionChartRepository } from '@/domain/repository/dashboard/transaction_chart_repository.ts';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import TransactionChartModel from '@/domain/model/dashboard/transaction_chart_model.ts';
import TransactionSummaryModel from '@/domain/model/dashboard/transaction_summary_model.ts';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';

interface DashboardTransactionContextProps {
  children: ReactNode;
}

interface FormDashboardTransaction {
  start_date?: string;
  end_date?: string;
  type?: string;
}

interface DashboardTransactionContextValue {
  transactionSummaryData?: TransactionSummaryModel;
  transactionSummaryLoading: boolean;
  transactionChartSellData: TransactionChartModel[];
  transactionChartBuyData: TransactionChartModel[];
  transactionChartSell: () => Promise<void>;
  transactionChartBuy: () => Promise<void>;
  formDashboardTransaction: FormDashboardTransaction;
  setFormDashboardTransaction: Dispatch<SetStateAction<FormDashboardTransaction>>;
  transactionSummary: () => void;
}

const DashboardTransactionContext = createContext<DashboardTransactionContextValue | null>(null);

const transactionStatistic = new TransactionChartRepository();

const DashboardTransactionProvider: React.FC<DashboardTransactionContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  //form
  const [formDashboardTransaction, setFormDashboardTransaction] = useState<FormDashboardTransaction>({});

  //statistic
  const [transactionChartSellData, setTransactionChartSellData] = useState<TransactionChartModel[]>([]);
  const [transactionChartBuyData, setTransactionChartBuyData] = useState<TransactionChartModel[]>([]);

  //transaction summary
  const [transactionSummaryData, setTransactionSummaryData] = useState<TransactionSummaryModel>();
  const [transactionSummaryLoading, setTransactionSummaryLoading] = useState<boolean>(false);

  //TRANSACTION SELL
  const transactionChartSell = async () => {
    const params = {
      type: TYPE_TRANSACTION.SELL,
      status: STATUS_TRANSACTION.SUCCESS,
      date_range: formDashboardTransaction.start_date
        ? [`${formDashboardTransaction.start_date}`, `${formDashboardTransaction.end_date}`]
        : null
    };

    transactionStatistic.statisticDashboardTransaction(params).then((result: any) => {
      setTransactionChartSellData(result);
    });
  };

  //TRANSACTION BUY
  const transactionChartBuy = async () => {
    const params = {
      type: TYPE_TRANSACTION.BUY,
      status: STATUS_TRANSACTION.SUCCESS,
      date_range: formDashboardTransaction.start_date
        ? [`${formDashboardTransaction.start_date}`, `${formDashboardTransaction.end_date}`]
        : null
    };

    transactionStatistic.statisticDashboardTransaction(params).then((result: any) => {
      setTransactionChartBuyData(result);
    });
  };

  const transactionSummary = () => {
    setTransactionSummaryLoading(true);

    transactionStatistic
      .transactionSummary()
      .then((result: any) => {
        setTransactionSummaryData(result);
        setTransactionSummaryLoading(false);
      })
      .catch(error => {
        setTransactionSummaryLoading(false);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: DashboardTransactionContextValue = {
    transactionSummaryData,
    transactionSummaryLoading,
    transactionChartSellData,
    transactionChartBuyData,
    transactionChartSell,
    transactionChartBuy,
    formDashboardTransaction,
    setFormDashboardTransaction,
    transactionSummary
  };

  return <DashboardTransactionContext.Provider value={contextValue}>{children}</DashboardTransactionContext.Provider>;
};

const useDashboardTransactionContext = (): DashboardTransactionContextValue => {
  const context = useContext(DashboardTransactionContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a DashboardTransactionProvider');
  }
  return context;
};

export { DashboardTransactionProvider, useDashboardTransactionContext };
