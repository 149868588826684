import { BaseModel } from '@/domain/model/base_model.ts';

export class CustomAccessModel extends BaseModel {
  flags: string[];
  access: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    access: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.flags = flags;
    this.access = access;
  }

  public static fromJson(data: any): CustomAccessModel | any {
    return new CustomAccessModel(
      data.id,
      data.uid,
      data.uuid,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by,
      data.flags,
      data.access
    );
  }

  public static fromJsonList(data: any): CustomAccessModel[] | any {
    try {
      const list: CustomAccessModel[] = [];

      for (let x = 0; x < data.length; x++) {
        list.push(CustomAccessModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
