import useAlertToast from '@/delivery/hooks/useAlertComponent';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { AccessControllerRepository } from '@/domain/repository/access_controller_repository.ts';
import { RoleAccessRepository } from '@/domain/repository/role_access_repository.ts';
import { RoleAccessModel } from '@/domain/model/role_access_model.ts';
import { RoleRepository } from '@/domain/repository/role_repository.ts';
import { RoleModel } from '@/domain/model/role_model.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface FormAccessControllerInterface {
  UUID?: string;
  access?: string;
  company_uuid?: string;
  role_uuid?: string;
}

interface AccessControllerValue {
  formAvailableAccess: string[];
  setFormAvailableAccess: Dispatch<SetStateAction<string[]>>;
  formCurrentAccess: string[];
  setFormCurrentAccess: Dispatch<SetStateAction<string[]>>;

  roles: RoleModel[];
  roleAccesses: RoleAccessModel[];
  formAccessController: FormAccessControllerInterface;
  setFormAccessController: Dispatch<SetStateAction<FormAccessControllerInterface>>;
  accessControllers: string[];
  handleFetchAccessController: () => Promise<void>;
  handlefetchRole: () => Promise<void>;
  handleFetchAccessRole: () => void;
  handleAssignAvailableRole: () => void;
  removeCurrentRole: () => void;
}

const AccessControllerContext = createContext<AccessControllerValue | null>(null);

const accessControllerRep = new AccessControllerRepository();
const roleRep = new RoleRepository();
const roleAccessRep = new RoleAccessRepository();

const AccessControllerProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const [accessControllers, setAccessControllers] = useState<string[]>([]);
  const [roles, setRoles] = useState<RoleModel[]>([]);
  const [roleAccesses, setRoleAccesses] = useState<RoleAccessModel[]>([]);

  const [formAccessController, setFormAccessController] = useState<FormAccessControllerInterface>({});

  const [formAvailableAccess, setFormAvailableAccess] = useState<string[]>([]);
  const [formCurrentAccess, setFormCurrentAccess] = useState<string[]>([]);

  //FETCH ACCESS CONTROLLER
  const handleFetchAccessController = async () => {
    return await accessControllerRep
      .fetchAccessController()
      .then((result: any) => {
        setAccessControllers(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //FETCH ROLE
  const handlefetchRole = async () => {
    const x = alertToast.loadingAlert('Fetching role');
    const params = {
      page: 1,
      limit: -1
    };

    return roleRep
      .fetchRole(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setRoles(result.data);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //FETCH ACCESS ROLE
  const handleFetchAccessRole = () => {
    const params = {
      role_uuid: formAccessController.role_uuid,
      page: 1,
      limit: -1
    };
    roleAccessRep
      .fetchRoleAccess(params)
      .then((result: any) => {
        setRoleAccesses(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //ASSIGN ROLE
  const handleAssignAvailableRole = () => {
    const x = alertToast.loadingAlert('Assigning access');
    const roleAccessParams = [];
    for (let x = 0; formAvailableAccess.length > x; x++) {
      roleAccessParams.push({
        access: formAvailableAccess[x],
        role_uuid: formAccessController.role_uuid
      });
    }

    const params = {
      role_access_params: roleAccessParams
    };

    roleAccessRep
      .storeBulkRoleAccess(params)
      .then(() => {
        alertToast.updateLoading(x);
        setFormAvailableAccess([]);
        handleFetchAccessController();
        handleFetchAccessRole();
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //REMOVE ROLE
  const removeCurrentRole = () => {
    let completedIterations = 0;
    for (let x = 0; formCurrentAccess.length > x; x++) {
      const params = {
        uuid: formCurrentAccess[x]
      };
      const z = alertToast.loadingAlert('Removing access');
      roleAccessRep
        .deleteRoleAccess(params)
        .then(() => {
          completedIterations++;
          alertToast.updateLoading(z);

          if (completedIterations === formCurrentAccess.length) {
            alertToast.updateLoading(z);
            handleFetchAccessController();
            handleFetchAccessRole();
          }
        })
        .catch(error => {
          alertToast.updateLoading(z);
          alertToast.errorAlert(error);
        });
    }
  };

  const contextValue: AccessControllerValue = {
    formAvailableAccess,
    setFormAvailableAccess,
    formCurrentAccess,
    setFormCurrentAccess,

    roles,
    roleAccesses,
    handlefetchRole,
    handleFetchAccessRole,
    formAccessController,
    setFormAccessController,
    accessControllers,
    handleFetchAccessController,
    handleAssignAvailableRole,
    removeCurrentRole
  };

  return <AccessControllerContext.Provider value={contextValue}>{children}</AccessControllerContext.Provider>;
};

const useAccessControllereContext = (): AccessControllerValue => {
  const context = useContext(AccessControllerContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a AccessControllerProvider');
  }
  return context;
};

export { AccessControllerProvider, useAccessControllereContext };
