import { useEffect } from 'react';
import CustomVariablePage from '@/delivery/ui/admin/settings/custom_variable';

const ViewCustomVariable = () => {
  useEffect(() => {
    document.title = 'Custom Variable';
  }, []);

  return (
    <div>
      <CustomVariablePage />
    </div>
  );
};

export default ViewCustomVariable;
