import Button from '../button/button';
import styles from './Pagination.module.css';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import formatNumberWithDot from '@/infrastructure/helper/formatNumberWithDot.ts';

interface paginationProps {
  page: number;
  totalPages: number;
  handlePagination: (page: number) => void;
}

const Pagination = (props: paginationProps) => {
  const { page, totalPages, handlePagination } = props;
  return (
    <div className="flex justify-between w-full">
      <Button
        disabled={page === 1 || totalPages === 0}
        prefixIcon={<FiArrowLeft />}
        type="secondary"
        label="Prvious"
        onClick={() => handlePagination(page - 1)}
      />

      {totalPages > 1 ? (
        <div className="flex gap-[2px] items-center">
          <button
            className={`${styles['button-pagination']} ${page === 1 ? styles['button-pagination-active'] : ''}`}
            onClick={() => handlePagination(1)}
          >
            1
          </button>

          {page > 3 && <span>...</span>}

          {page === totalPages && totalPages > 3 && (
            <button className={`${styles['button-pagination']}`} onClick={() => handlePagination(page - 2)}>
              {(page - 2).toString()}
            </button>
          )}

          {page > 2 && (
            <button
              className={`${styles['button-pagination']} ${
                page - 1 === page ? styles['button-pagination-active'] : ''
              }`}
              onClick={() => handlePagination(page - 1)}
            >
              {(page - 1).toString()}
            </button>
          )}

          {page !== 1 && page !== totalPages && (
            <button onClick={() => handlePagination(page)} className={`${styles['button-pagination-active']}`}>
              {page.toString()}
            </button>
          )}

          {page < totalPages - 1 && (
            <button
              className={`${styles['button-pagination']} ${
                page + 1 === page ? styles['button-pagination-active'] : ''
              }`}
              onClick={() => handlePagination(page + 1)}
            >
              {(page + 1).toString()}
            </button>
          )}

          {page === 1 && totalPages > 3 && (
            <button className={`${styles['button-pagination']}`} onClick={() => handlePagination(page + 2)}>
              {(page + 2).toString()}
            </button>
          )}

          {page < totalPages - 2 && (
            <button
              className={`${styles['button-pagination']}`}
              onClick={() => handlePagination(totalPages)}
              disabled={page === totalPages}
            >
              ...
            </button>
          )}

          {totalPages === 0 && <p className={`${styles['button-pagination']}`}>Data is empty</p>}

          <button
            className={`${styles['button-pagination']} ${
              page === totalPages ? styles['button-pagination-active'] : ''
            }`}
            onClick={() => handlePagination(totalPages)}
            disabled={page === totalPages}
          >
            {formatNumberWithDot(totalPages)}
          </button>
        </div>
      ) : (
        ''
      )}

      {totalPages === 1 ? (
        <div className="flex gap-[2px] items-center">
          <button className={`${styles['button-pagination-active']}`}>1</button>
        </div>
      ) : (
        ''
      )}

      {totalPages === 0 ? (
        <div className="flex gap-[2px] items-center">
          <button className={`text-[14px] font-[500] text-text-tertiary-invert`}>Data is empty</button>
        </div>
      ) : (
        ''
      )}

      <Button
        disabled={page === totalPages || totalPages === 0}
        suffixIcon={<FiArrowRight />}
        type="secondary"
        label="Next"
        onClick={() => handlePagination(page + 1)}
      />
    </div>
  );
};

export default Pagination;
