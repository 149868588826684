import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { STATUS_LIST } from '@/domain/constant/status.ts';
import { ModalDialogFooter } from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useOpenChannelContext } from '@/delivery/ui/admin/settings/open_channel/context/open_channel_context.tsx';
import { TBox } from '@/delivery/components/molecules/table/table.tsx';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Dropzone from '@/delivery/components/atoms/dropzone/dropzone.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';

const DetailPaymentChannel = () => {
  const { uid } = useParams();
  const { handleShowPaymentChannel, setPaymentChannel, paymentChannel, handleUpdatePaymentChannel } =
    useOpenChannelContext();

  useEffect(() => {
    if (uid) {
      handleShowPaymentChannel({ UID: uid });
    }
  }, [uid]);

  return (
    <TBox className={'lg:w-1/2 w-full'}>
      <div className={'flex gap-[20px]'}>
        <div className="flex flex-col gap-[20px] w-full">
          <Typography fontWeight={600} size={14}>
            Payment Channel
          </Typography>
          <Line />
          <TextField
            disabled={true}
            value={paymentChannel.vendor_name}
            label="Name Penyedia"
            placeholder="Nama Penyedia"
            block
          />
          <TextField disabled={true} value={paymentChannel.channel_name} label="Channel" placeholder="Channel" block />
          <TextField disabled={true} value={paymentChannel.method_name} label="Method" placeholder="Method" block />

          {paymentChannel.mediaUID ? (
            <div className={'flex justify-between gap-[10px] items-center'}>
              <div className={'w-full'}>
                <Image url={paymentChannel.mediaURL} />
              </div>
              <div className={'w-full'}>
                <Dropzone
                  label={'Image Icon'}
                  onChange={value => {
                    setPaymentChannel({
                      ...paymentChannel,
                      media: value
                    });
                  }}
                />
              </div>
            </div>
          ) : (
            <Dropzone
              label={'Image Icon'}
              onChange={value => {
                setPaymentChannel({
                  ...paymentChannel,
                  media: value
                });
              }}
            />
          )}

          <Textarea
            label="System Note"
            placeholder="System Note"
            block
            value={paymentChannel.system_note}
            onChange={e => {
              setPaymentChannel({
                ...paymentChannel,
                system_note: e.target.value
              });
            }}
          />
          <Textarea
            label="Description"
            placeholder="Description"
            block
            value={paymentChannel.description}
            onChange={e => {
              setPaymentChannel({
                ...paymentChannel,
                description: e.target.value
              });
            }}
          />

          <Select2
            value={paymentChannel.status}
            label={'Status'}
            options={STATUS_LIST.map(item => {
              return {
                label: item.name,
                value: item.UUID
              };
            })}
            onChange={(e: any) => {
              setPaymentChannel({
                ...paymentChannel,
                status: e.value
              });
            }}
          />
        </div>
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button label="Save" onClick={() => handleUpdatePaymentChannel()} />
      </ModalDialogFooter>
    </TBox>
  );
};

export default DetailPaymentChannel;
