import TransactionInterface, { TransactionValue } from '@/domain/repository/interface/transaction_interface';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { TransactionModel } from '@/domain/model/transaction_model';
import { Transaction } from '@/domain/constant/api_list';
import { parseParams } from '@/infrastructure/helper/parseParams.tsx';

export class TransactionRepository implements TransactionInterface {
  api = new ApiClient();

  async fetchTransaction(data: TransactionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Transaction.FETCH, {
            params: data,
            paramsSerializer: (params: any) => parseParams(data)
          })
          .then(result => {
            const data = TransactionModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchTransactionDashboard(data: TransactionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Transaction.FETCH_DASHBOARD, {
            params: data,
            paramsSerializer: (params: any) => parseParams(data)
          })
          .then(result => {
            const data = TransactionModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchTransactionPrint(data: TransactionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Transaction.FETCH_PRINT, {
            params: data,
            paramsSerializer: (params: any) => parseParams(data)
          })
          .then(result => {
            const data = TransactionModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showTransaction(data: TransactionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Transaction.SHOW, {
            params: data
          })
          .then(result => {
            const data = TransactionModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async manualTransaction(data: TransactionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Transaction.MANUAL, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async settlementTransaction(data: TransactionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Transaction.SETTLEMENT, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async lockTransaction(data: TransactionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Transaction.LOCK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateTransaction(data: TransactionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(Transaction.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteTransaction(data: TransactionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(Transaction.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async withdrawTransaction(data: TransactionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(Transaction.WITHDRAW, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async buyTransaction(data: TransactionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(Transaction.BUY, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async cancelTransaction(data: TransactionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(Transaction.CANCEL + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
