import { WebPageProvider } from '@/delivery/ui/admin/web_admin/web_pages/context/web_page_context.tsx';
import UpdateWebPage from '@/delivery/ui/admin/web_admin/web_pages/update/update_web_page.tsx';

const UpdateWebPagePage = () => {
  return (
    <div>
      <WebPageProvider>
        <UpdateWebPage />
      </WebPageProvider>
    </div>
  );
};

export default UpdateWebPagePage;
