import styles from './Button.module.css';
import clsx from 'clsx';

// icons
import { GoCopy } from 'react-icons/go';
import Icon from '@/domain/constant/icon.tsx';

interface ButtonProps {
  disabled?: boolean;
  className?: string;
  iconButton?: boolean;
  label?: string;
  placeholder?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLButtonElement>;
  type?: 'primary' | 'secondary' | 'danger';
  icon?: any;
  prefixIcon?: any;
  suffixIcon?: any;
  block?: boolean;
  loading?: boolean;
  iconType?: 'edit' | 'view' | 'delete' | 'copy' | 'lock' | 'banned' | '';
}

const Button = (props: ButtonProps) => {
  const {
    label,
    onClick,
    onKeyUp,
    type = 'primary',
    icon,
    iconButton = false,
    prefixIcon,
    suffixIcon,
    block = false,
    loading = false,
    iconType,
    className,
    disabled
  } = props;

  let iconButtonChoosed: any = '';
  switch (iconType) {
    case 'copy': {
      iconButtonChoosed = <GoCopy />;
      break;
    }
    case 'edit': {
      iconButtonChoosed = Icon.pen;
      break;
    }
    case 'view': {
      iconButtonChoosed = Icon.eye;
      break;
    }
    case 'delete': {
      iconButtonChoosed = Icon.trash;
      break;
    }
    case 'lock': {
      iconButtonChoosed = Icon.lock;
      break;
    }
    case 'banned': {
      iconButtonChoosed = Icon.banned;
      break;
    }
    default: {
      //statements;
      break;
    }
  }

  return (
    <>
      {iconButton ? (
        <>
          {disabled ? (
            <button
              onClick={onClick}
              className={clsx(styles['circle-button'], 'cursor-not-allowed opacity-25', className)}
            >
              {iconButtonChoosed !== '' ? iconButtonChoosed : icon}
            </button>
          ) : (
            <button onClick={onClick} className={clsx(styles['circle-button'], className)}>
              {iconButtonChoosed !== '' ? iconButtonChoosed : icon}
            </button>
          )}
        </>
      ) : (
        <button
          disabled={disabled}
          onKeyUp={onKeyUp}
          className={clsx(
            styles[type],
            `${block ? 'w-full' : ''}`,
            'flex gap-2 justify-center leading-none',
            disabled ? 'opacity-40 cursor-not-allowed' : '',
            className
          )}
          onClick={onClick}
        >
          {/* <div className="text-center flex gap-2 items-center"> */}
          {loading ? (
            <div className="flex justify-center items-center">
              <svg
                className={`animate-spin mx-3 h-[24px] w-[20px] text-white`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : (
            <div className="flex items-center gap-[8px]">
              {prefixIcon ? (
                <>
                  <span className="text-[20px]">{prefixIcon}</span>
                </>
              ) : null}

              <span className="font-[500] text-[16px]">{label}</span>
              {suffixIcon ? (
                <>
                  <span className="text-[20px]">{suffixIcon}</span>
                </>
              ) : null}
            </div>
          )}

          {/* </div> */}
        </button>
      )}
    </>
  );
};

export default Button;
