import TableHighProfit from '@/delivery/ui/admin/dashboard/ranking/table_ranking/table_high_profit.tsx';
import { useDashboardRankingContext } from '@/delivery/ui/admin/dashboard/ranking/context/dashboard_ranking_context.tsx';
import { useEffect } from 'react';
import TableBalance from '@/delivery/ui/admin/dashboard/ranking/table_ranking/table_balance.tsx';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import TableBuy from '@/delivery/ui/admin/dashboard/ranking/table_ranking/table_buy.tsx';
import TableSell from '@/delivery/ui/admin/dashboard/ranking/table_ranking/table_sell.tsx';

const Ranking = () => {
  const alertToast = useAlertToast();

  const {
    setRankingSellLoading,
    setRankingBuyLoading,
    setRankingHighProfitLoading,
    setRankingBalanceLoading,
    handleRankingBuy,
    handleRankingSell,
    handleRankHighProfit,
    handleRankingBalance
  } = useDashboardRankingContext();

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleFetchData = async () => {
    try {
      setRankingHighProfitLoading(true);
      setRankingBalanceLoading(true);
      setRankingSellLoading(true);
      setRankingBuyLoading(true);
      await handleRankHighProfit();
      await handleRankingBalance();
      await handleRankingBuy();
      await handleRankingSell();
    } catch (error: any) {
      alertToast.errorAlert(error);
    }
  };

  return (
    <div>
      <div>
        <div className="grid grid-cols-1 lg:grid-cols-2 justify-between gap-[24px]">
          <TableHighProfit />
          <TableBalance />
          <TableBuy />
          <TableSell />
        </div>
      </div>
    </div>
  );
};

export default Ranking;
