import { ApiClient } from '@/main/apiClient';
import AccessControllerInterface from '@/domain/repository/interface/access_controller_interface';
import { AccessController } from '@/domain/constant/api_list';

export class AccessControllerRepository implements AccessControllerInterface {
  api = new ApiClient();

  async fetchAccessController() {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(AccessController.FETCH, {})
          .then(result => {
            const data = result.data.data;

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchAccessControllerModule() {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(AccessController.MODULE, {})
          .then(result => {
            const data = result.data;

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
