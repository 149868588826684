import { RoleAccessModel } from '@/domain/model/role_access_model.ts';

export interface AccessItem {
  access: string;
  access_list: string[];
}

interface AccessItemCurrent {
  UUID: string;
  access: string;
}

export interface GroupedAccessItemInterface {
  access: string;
  access_list: AccessItemCurrent[];
}

const getAccessItems = (_data: string[]) => {
  const accessItemsRes: AccessItem[] = _data.reduce((acc: AccessItem[], curr: string) => {
    const [access] = curr.split(':');
    const existingItem = acc.find(item => item.access === access);

    if (existingItem) {
      existingItem.access_list.push(curr);
    } else {
      acc.push({ access, access_list: [curr] });
    }

    return acc;
  }, []);

  return accessItemsRes;
};

const getAccessItemCurrent = (_data: RoleAccessModel[]) => {
  const accessItemCurrent: GroupedAccessItemInterface[] = _data.reduce(
    (acc: GroupedAccessItemInterface[], curr: AccessItemCurrent) => {
      if (!curr.access) return acc; // Type guard to ensure curr.access is defined

      const [access] = curr.access.split(':');
      const existingItemIndex = acc.findIndex(item => item.access === access);

      if (existingItemIndex !== -1) {
        acc[existingItemIndex].access_list.push(curr);
      } else {
        acc.push({ access, access_list: [curr] });
      }

      return acc;
    },
    []
  );

  return accessItemCurrent;
};

export { getAccessItems, getAccessItemCurrent };
