import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useCorporateContext } from '@/delivery/ui/admin/data_corporate/corporate_list/context/corporate_list_context.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { useEffect } from 'react';
import Dropzone from '@/delivery/components/atoms/dropzone/dropzone.tsx';

const DialogCreateCorporate = (props: ModalDialogProps) => {
  const {
    handleAddCorporateModal,
    handleFetchProvince,
    provinces,
    cities,
    districts,
    villages,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    // villageID,
    setVillageID,
    setFormCorporate,
    formCorporate,
    handleAddCorporate
  } = useCorporateContext();

  useEffect(() => {
    handleFetchProvince();
  }, []);

  //fetch cities
  useEffect(() => {
    if (provinceID !== null) {
      handleFetchCities();
    }
  }, [provinceID]);

  //fetch district
  useEffect(() => {
    if (cityID !== 0) {
      handleFetchDistricts();
    }
  }, [cityID]);

  //fetch village
  useEffect(() => {
    if (districtID > 0) {
      handleFetchVillage();
    }
  }, [districtID]);

  return (
    <ModalDialog size="large" title="Tambah Corporate" modal={props.modal}>
      <div className={'flex flex-col gap-[20px]'}>
        <Typography fontWeight={600} size={16}>
          Informasi Bisnis
        </Typography>
        <Line />
        <div className={'grid grid-cols-2 gap-[24px]'}>
          <TextField
            block
            label={'Nama Bisnis/Usaha'}
            onChange={e => setFormCorporate({ ...formCorporate, name: e.target.value })}
          />
          <TextField
            block
            label={'Website'}
            onChange={e => setFormCorporate({ ...formCorporate, website: e.target.value })}
          />
          <TextField
            block
            label={'Email'}
            onChange={e => setFormCorporate({ ...formCorporate, email: e.target.value })}
          />
          <TextField
            type={'number'}
            block
            label={'Phone'}
            onChange={e => setFormCorporate({ ...formCorporate, phone: e.target.value })}
          />

          <Dropzone
            key={'1'}
            label={'Ijin Usaha/NIB'}
            onChange={value => {
              setFormCorporate({
                ...formCorporate,
                company_registration_number_media_uid: value
              });
            }}
          />
          <Dropzone key={'2'} isMultiple label={'Foto Rekening Koran'} />
        </div>
      </div>
      <div className={'my-[35px]'}>
        <Line />
      </div>

      <div className={'flex flex-col gap-[20px]'}>
        <Typography fontWeight={600} size={16}>
          Informasi Alamat Bisnis
        </Typography>
        <Line />
        <div className={'grid grid-cols-2 gap-[24px]'}>
          <Select2
            value={provinceID?.toString()}
            block
            label={'Provinsi'}
            options={provinces?.map(item => {
              return {
                label: item.name,
                value: item.ID.toString()
              };
            })}
            onChange={e => {
              setProvinceID(parseInt(e.value));
            }}
          />
          <Select2
            value={cityID.toString()}
            block
            label={'Kota/Kab'}
            options={cities?.map(item => {
              return {
                label: item.name,
                value: item.ID.toString()
              };
            })}
            onChange={e => setCityID(parseInt(e.value))}
          />
          <Select2
            block
            label={'Kecamatan'}
            options={districts?.map(item => {
              return {
                label: item.name,
                value: item.ID.toString()
              };
            })}
            onChange={e => setDistrictID(parseInt(e.value))}
          />
          <Select2
            block
            label={'Kelurahan'}
            options={villages?.map(item => {
              return {
                label: item.name,
                value: item.ID.toString()
              };
            })}
            onChange={e => setVillageID(parseInt(e.value))}
          />
        </div>
        <div className={'flex gap-[24px]'}>
          <TextField
            block
            label={'Alamat Bisnis/Usaha'}
            onChange={e =>
              setFormCorporate({
                ...formCorporate,
                full_address: e.target.value
              })
            }
          />
          <TextField
            label={'Kode POS'}
            onChange={e => setFormCorporate({ ...formCorporate, zip_code: e.target.value })}
          />
        </div>
      </div>
      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleAddCorporateModal.setState(false)} />
        <Button label="Tambah Corporate" onClick={() => handleAddCorporate()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogCreateCorporate;
