import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { CorporateVerifyRepository } from '@/domain/repository/corporate_verify_repository.ts';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { CorporateModel } from '@/domain/model/corporate_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';
import { MasterBankRepository } from '@/domain/repository/master_bank_repository.ts';
import { MasterBankModel } from '@/domain/model/master_bank_model.ts';

interface BusinessVerifyProps {
  children: ReactNode;
}

interface BusinessVerifyInterface {
  uid?: string;
  page?: number;
  limit?: number;
  sell?: number;
  buy?: number;
  status?: string;
}

interface FormBusinessVerifyInterface {
  uid?: string;
  status?: string;
  notes?: string;
  account_holder_name?: string;
  account_number?: string;
  checking_account_media_uid?: string;
  city_id?: number;
  company_deed_media_uid?: string;
  company_registration_number_media_uid?: string;
  corporate_uid?: string;
  district_id?: number;
  email?: string;
  flags?: string[];
  full_address?: string;
  identity_media_uid?: string;
  latitude?: number;
  longitude?: number;
  mail_address?: string;
  master_bank_uuid?: string;
  media_uid?: string;
  name?: string;
  national_id?: number;
  permit?: string;
  phone?: string;
  pic_name?: string;
  province_id?: number;
  reg_date?: string;
  tax_payer_identification_number_media_uid?: string;
  type?: string;
  village_id?: number;
  website?: string;
  zip_code?: string;
}

interface FormFilterBusinessVerify {
  search?: string;
  status?: string;
  balance_search_option?: string;
  register_date?: string;
  religion?: string;
  gender?: string;
  province?: string;
  account_holder_name?: string;
  master_bank_uuid?: string;
  account_number?: string;
  type?: string;
}

interface BusinessVerifyContextValue {
  masterBanks: MasterBankModel[];
  formFilterBusinessVerify: FormFilterBusinessVerify;
  setFormFilterBusinessVerify: Dispatch<SetStateAction<FormFilterBusinessVerify>>;
  businessVerify?: CorporateModel;
  businessVerifies: CorporateModel[];
  businessVerifyPagination?: PaginationModel;
  handleDetailBusinessVerifyModal: ModalObject;
  handleAddBusinessVerifyModal: ModalObject;
  handleApproveBusinessVerifyModal: ModalObject;
  handleFetchBusinessVerify: (_data: BusinessVerifyInterface) => void;
  handleShowBusinessVerify: (_data: BusinessVerifyInterface) => void;
  formBusinessVerify: FormBusinessVerifyInterface;
  setFormBusinessVerify: Dispatch<SetStateAction<FormBusinessVerifyInterface>>;
  handleApproveBusinessVerifyShowModal: (_data: BusinessVerifyInterface) => void;
  handleApproveBusinessVerify: (_data: BusinessVerifyInterface) => void;
  handleFetchMasterBank: () => void;
}

const BusinessVerifyContext = createContext<BusinessVerifyContextValue | null>(null);

const corporateVerify = new CorporateVerifyRepository();
const masterBankRep = new MasterBankRepository();

const BusinessVerifyProvider: React.FC<BusinessVerifyProps> = ({ children }) => {
  const navigate = useNavigate();
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [businessVerify, setBusinessVerify] = useState<CorporateModel>();
  const [businessVerifies, setBusinessVerifies] = useState<CorporateModel[]>([]);
  const [businessVerifyPagination, setBusinessVerifyPagination] = useState<PaginationModel>();
  const [masterBanks, setMasterBanks] = useState<MasterBankModel[]>([]);

  const [formBusinessVerify, setFormBusinessVerify] = useState<FormBusinessVerifyInterface>({});

  const handleDetailBusinessVerifyModal = useVisibleComponent(false);
  const handleAddBusinessVerifyModal = useVisibleComponent(false);
  const handleApproveBusinessVerifyModal = useVisibleComponent(false);

  const [formFilterBusinessVerify, setFormFilterBusinessVerify] = useState<FormFilterBusinessVerify>({});

  //HANDLE FETCH BUSINESS
  const handleFetchBusinessVerify = (_data: BusinessVerifyInterface) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      search: formFilterBusinessVerify.search ?? null,
      master_bank_uuid: formFilterBusinessVerify.master_bank_uuid ?? null,
      account_holder_name: formFilterBusinessVerify.account_holder_name ?? null,
      account_number: formFilterBusinessVerify.account_number ?? null,
      type: formFilterBusinessVerify.type ?? null,
      status: formFilterBusinessVerify.status ?? null
    };

    corporateVerify
      .fetchCorporateVerify(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setBusinessVerifies(result.data);
        setBusinessVerifyPagination(result.pagination);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW BUSINESS VERIFY
  const handleShowBusinessVerify = (_data: BusinessVerifyInterface) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.uid
    };

    corporateVerify
      .showCorporateVerify(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setBusinessVerify(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE APPROVE SHOW MODAL
  const handleApproveBusinessVerifyShowModal = (_data: BusinessVerifyInterface) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.uid
    };

    corporateVerify
      .showCorporateVerify(params)
      .then((res: any) => {
        alertToast.updateLoading(x);
        const result: CorporateModel = res;
        setFormBusinessVerify({
          status: result.status,
          notes: result.notes
        });
        handleApproveBusinessVerifyModal.setState(true);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE APPROVE BUSINESS VERIFY
  const handleApproveBusinessVerify = (_data: BusinessVerifyInterface) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params: FormBusinessVerifyInterface = {
          // uid: _data.uid,
          status: formBusinessVerify.status,
          notes: formBusinessVerify.notes,
          mail_address: businessVerify?.mailAddress,
          company_registration_number_media_uid: businessVerify?.companyRegistrationNumberMediaUID,
          tax_payer_identification_number_media_uid: businessVerify?.taxpayerIdentificationNumberMediaUID,
          name: businessVerify?.name,
          checking_account_media_uid: businessVerify?.checkingAccountMediaUID,
          account_holder_name: businessVerify?.accountHolderName,
          corporate_uid: businessVerify?.corporateUID,
          company_deed_media_uid: businessVerify?.companyDeedMediaUID,
          email: businessVerify?.emailCorporate,
          type: businessVerify?.type,
          identity_media_uid: businessVerify?.identityMediaUID,
          account_number: businessVerify?.accountNumber,
          master_bank_uuid: businessVerify?.masterBankUUID,
          media_uid: businessVerify?.mediaUID,
          website: businessVerify?.website,
          national_id: businessVerify?.nationalID,
          province_id: businessVerify?.provinceID,
          district_id: businessVerify?.districtID,
          village_id: businessVerify?.villageID,
          full_address: businessVerify?.fullAddress,
          zip_code: businessVerify?.zipCode,
          city_id: businessVerify?.cityID,
          permit: businessVerify?.permit,
          phone: businessVerify?.phoneCorporate,
          pic_name: businessVerify?.picName,
          reg_date: businessVerify?.regDate,
          latitude: businessVerify?.latitude,
          longitude: businessVerify?.longitude,
          flags: businessVerify?.flags
        };

        corporateVerify
          .storeCorporateVerify(params)
          .then((res: any) => {
            alertToast.updateLoading(x);
            navigate(Routing.DATA_MEMBER.BUSINESS_VERIFY);
            handleShowBusinessVerify({ uid: businessVerify?.UID });
            handleApproveBusinessVerifyModal.setState(false);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const handleFetchMasterBank = () => {
    const x = alertToast.loadingAlert('Fetching master bank');
    masterBankRep
      .fetchMasterBank({ page: 1, limit: -1 })
      .then((result: any) => {
        alertToast.updateLoading(x);
        setMasterBanks(result.data);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: BusinessVerifyContextValue = {
    masterBanks,
    formFilterBusinessVerify,
    setFormFilterBusinessVerify,
    handleApproveBusinessVerifyShowModal,
    formBusinessVerify,
    setFormBusinessVerify,
    businessVerify,
    handleShowBusinessVerify,
    handleFetchBusinessVerify,
    businessVerifies,
    businessVerifyPagination,
    handleDetailBusinessVerifyModal,
    handleApproveBusinessVerifyModal,
    handleAddBusinessVerifyModal,
    handleApproveBusinessVerify,
    handleFetchMasterBank
  };

  return <BusinessVerifyContext.Provider value={contextValue}>{children}</BusinessVerifyContext.Provider>;
};

const useBusinessVerifyContext = (): BusinessVerifyContextValue => {
  const context = useContext(BusinessVerifyContext);
  if (!context) {
    throw new Error('useBusinessVerifyContext must be used within a BusinessVerifyProvider');
  }
  return context;
};

export { BusinessVerifyProvider, useBusinessVerifyContext };
