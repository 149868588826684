import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useReferralContext } from '@/delivery/ui/admin/data_member/member_list/context/referral_context.tsx';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AffiliateModel } from '@/domain/model/affiliate_model.ts';
import { COMMISSION_TYPE } from '@/domain/constant/affiliate/type_affiliate.ts';
import { REFERRAL_LINK } from '@/domain/constant/referral/referral_link.ts';

const Referral = () => {
  const { uuid } = useParams();
  const { userDashboard, handleGetDataUserParentAffiliate, handleShowReferral, affiliate } = useReferralContext();

  useEffect(() => {
    if (uuid) {
      handleShowReferral({ user_uid: uuid });
      handleGetDataUserParentAffiliate({ user_uid: uuid });
    }
  }, [uuid]);

  useEffect(() => {
    if (userDashboard) {
      console.log(userDashboard, 'userDashboard');
    }
  }, [userDashboard]);

  return (
    <div className={'flex flex-col gap-[20px]'}>
      <div>
        <Theader>
          <div>
            <Typography size={14} fontWeight={600}>
              Parent Referral
            </Typography>
          </div>
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Serial ID</Th>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Phone</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userDashboard?.parentAffiliate !== null ? (
              <Tr>
                <Td>
                  <Typography fontWeight={400} size={12} type="primary-invert">
                    {userDashboard?.parentAffiliate?.serialID}
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={400} size={12} type="primary-invert">
                    {userDashboard?.parentAffiliate?.firstName}
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={400} size={12} type="primary-invert">
                    {userDashboard?.parentAffiliate?.email}
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={400} size={12} type="primary-invert">
                    {userDashboard?.parentAffiliate?.phone}
                  </Typography>
                </Td>
              </Tr>
            ) : (
              <Tr>
                <Td colSpan={4}>
                  <Typography size={12} style={'italic'}>
                    belum memiliki parent referral
                  </Typography>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </div>

      <div>
        <Theader>
          <div>
            <Typography size={14} fontWeight={600}>
              Data Referral
            </Typography>
          </div>

          {/*<div>*/}
          {/*  <span className={'text-text-primary font-[600] text-[14px] cursor-pointer'}>Edit</span>*/}
          {/*</div>*/}
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Token Referral</Th>
              <Th>Link Referral</Th>
              {/*<Th>Referral of</Th>*/}
              <Th>Total Komisi (%)</Th>
              <Th>Total Komisi (Rp)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {affiliate.length > 0 ? (
              <>
                {affiliate?.map((item: AffiliateModel) => (
                  <Tr>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {item.token}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {`${REFERRAL_LINK}/${item.token}`}
                      </Typography>
                    </Td>
                    {/*<Td>*/}
                    {/*  <Typography fontWeight={400} size={12} type="primary-invert">*/}
                    {/*    //*/}
                    {/*  </Typography>*/}
                    {/*</Td>*/}

                    <Td>
                      {item.commission_type === COMMISSION_TYPE.PERCENT ? (
                        <Typography fontWeight={400} size={12} type="primary-invert">
                          {item.commission}
                        </Typography>
                      ) : (
                        <Typography fontWeight={400} size={12} type="primary-invert">
                          -
                        </Typography>
                      )}
                    </Td>
                    <Td>
                      {item.commission_type === COMMISSION_TYPE.FLAT ? (
                        <Typography fontWeight={400} size={12} type="primary-invert">
                          {item.commission}
                        </Typography>
                      ) : (
                        <Typography fontWeight={400} size={12} type="primary-invert">
                          -
                        </Typography>
                      )}
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <Tr>
                <Td colSpan={4}>
                  <Typography size={12} fontWeight={500} style={'italic'}>
                    belum memiliki referral
                  </Typography>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default Referral;
