import Navbar from '@/delivery/components/organisms/navbar/navbar';
import WebPopUp from './web_pop_up';
import Button from '@/delivery/components/atoms/button/button';
import Icon from '@/domain/constant/icon.tsx';

const WebPopUpPage = () => {
  return (
    <div>
      <Navbar label={'Web Pop Up'}>
        <div className="flex gap-[12px]">
          <Button type="primary" prefixIcon={Icon.plus} label="Add Pop Up" />
        </div>
      </Navbar>
      <WebPopUp />
    </div>
  );
};

export default WebPopUpPage;
