import Button from '@/delivery/components/atoms/button/button.tsx';
import { BsFilter } from 'react-icons/bs';
import { Dispatch, ReactNode, RefObject, SetStateAction, useState } from 'react';
import { GrFormClose } from 'react-icons/gr';
import styles from './ButtonFilter.module.css';
// import { GrFormClose } from 'react-icons/gr';

interface ModalObject {
  ref: RefObject<HTMLDivElement>;
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
}

interface ButtonFilterProps {
  label?: string;
  children?: ReactNode;
  modal?: ModalObject;
  titleFilter?: string;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  onClickApply?: () => void;
  onClickReset?: () => void;
}

const ButtonFilter = (props: ButtonFilterProps) => {
  const { size = 'medium', onClickApply, titleFilter, children, label } = props;

  const [openCloseList, setOpenCloseList] = useState(false);

  const onClickApplyWithClose = () => {
    if (onClickApply) {
      onClickApply();
      setOpenCloseList(!openCloseList);
    }
  };

  return (
    <div className={'relative'}>
      <div>
        <Button
          prefixIcon={<BsFilter />}
          label={label}
          type={'secondary'}
          onClick={() => setOpenCloseList(!openCloseList)}
        />
      </div>

      <div
        className={
          openCloseList
            ? `lg:right-0 absolute ${styles[size]} mt-1 rounded-[8px] border border-border-tertiary z-10 bg-text-white`
            : `hidden`
        }
      >
        <div className="py-4 px-[24px] flex justify-between items-center border-b-[1px] border-[200] border-border-secondary">
          <div className="text-text-primary-invert font-[600]">{titleFilter}</div>
          <div
            className="text-text-tertiary-invert text-[20px] font-[300] cursor-pointer"
            onClick={() => setOpenCloseList(!openCloseList)}
          >
            <GrFormClose />
          </div>
        </div>
        <div className={'p-[24px]'}>{children}</div>

        <div
          className={
            'py-[10px] px-[20px] flex justify-end border-t-[1px] border-[200] border-border-secondary gap-[10px]'
          }
        >
          <Button label={'Close'} type={'secondary'} onClick={() => setOpenCloseList(!openCloseList)} />
          <Button label={'Apply'} onClick={onClickApplyWithClose} />
        </div>
      </div>
    </div>
  );
};

export default ButtonFilter;
