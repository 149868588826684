import IMediaAssignment, { IMediaAssignmentParams } from '@/domain/repository/interface/media_assignment_interface';
import { MediaAssignmentModel } from '@/domain/model/media_assignment_model';
import { ApiClient } from '@/main/apiClient';
import { MediaAssignments } from '@/domain/constant/api_list.ts';

//import { da } from 'date-fns/locale';

export class MediaAssignmentRepository implements IMediaAssignment {
  api = new ApiClient();

  async fetchMediaAssignment(data: IMediaAssignmentParams) {
    return new Promise((resolve, reject) => {
      this.api
        .get(MediaAssignments.FETCH, {
          params: data
        })
        .then(result => {
          const data = MediaAssignmentModel.fromJsonList(result.data.data);

          const media_assignment = {
            data: data
          };

          return resolve(media_assignment);
        })
        .catch(err => {
          return reject(err);
        });
    });
  }

  async fetchMediaAssignmentList(data: IMediaAssignmentParams) {
    return new Promise((resolve, reject) => {
      this.api
        .get(MediaAssignments.FETCH, {
          params: data
        })
        .then(result => {
          const data = MediaAssignmentModel.fromJsonList(result.data.data);

          const media_assignment = {
            data: data
          };

          return resolve(media_assignment);
        })
        .catch(err => {
          return reject(err);
        });
    });
  }

  async storeMediaAssignment(data: IMediaAssignmentParams) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(MediaAssignments.STORE, data)
          .then((result: any) => {
            const data = MediaAssignmentModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch {
      return null;
    }
  }

  async updateMediaAssignment(data: IMediaAssignmentParams) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(`${MediaAssignments.UPDATE}/${data.uuid}`, data)
          .then(result => {
            const data = MediaAssignmentModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch {
      return null;
    }
  }

  async deleteMediaAssignment(data: IMediaAssignmentParams) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(`${MediaAssignments.DELETE}/${data.uuid}`, {})
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch {
      return null;
    }
  }
}
