import Navbar from '@/delivery/components/organisms/navbar/navbar';
import BusinessVerify from '@/delivery/ui/admin/data_member/business_verify/business_verify.tsx';

const BusinessVerifyPage = () => {
  return (
    <div>
      <Navbar label={'Member Business Request Verify'}></Navbar>
      <BusinessVerify />
    </div>
  );
};

export default BusinessVerifyPage;
