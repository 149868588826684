import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { AffiliateRepository } from '@/domain/repository/affiliate_repository.ts';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import { TransactionRepository } from '@/domain/repository/transaction_repository.ts';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';

interface AffiliateContextContextProps {
  children: ReactNode;
}

interface AffiliateTransactionParams {
  page?: number;
  limit?: number;
}

interface FormFilterTransaction {
  search?: string;
  type?: string;
  status?: string;
  start_date?: string;
  end_date?: string;
  transaction_at_start?: string;
  transaction_at_end?: string;
  gram?: number;
  amount?: number;
}

interface AffiliateContextValue {
  formFilterTransaction: FormFilterTransaction;
  setFormFilterTransaction: Dispatch<SetStateAction<FormFilterTransaction>>;
  transaction?: TransactionModel;
  affiliateTransactions: TransactionModel[];
  affiliateTransactionPagination?: PaginationModel;
  handleDetailAffiliateTransactionModal: ModalObject;
  handleFetchAffiliateTransaction: (_data: AffiliateTransactionParams) => void;
  handleShowTransaction: (_data: TransactionModel) => void;
  handleApprovePayment: (_data: TransactionModel | undefined) => void;
  handleCancelTransaction: (_data: TransactionModel | undefined) => void;
}

const MemberVerifyContext = createContext<AffiliateContextValue | null>(null);

const affiliateRep = new AffiliateRepository();
const transactionRep = new TransactionRepository();

const AffiliateTransactionProvider: React.FC<AffiliateContextContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [transaction, setTransaction] = useState<TransactionModel>();
  const [affiliateTransactions, setAffiliateTransactions] = useState<TransactionModel[]>([]);
  const [affiliateTransactionPagination, seAffiliateTransactionPagination] = useState<PaginationModel>();

  const [formFilterTransaction, setFormFilterTransaction] = useState<FormFilterTransaction>({});

  const handleDetailAffiliateTransactionModal = useVisibleComponent(false);

  //HANDLE FETCH AFFILIATE TRANSACTION
  const handleFetchAffiliateTransaction = (_data: AffiliateTransactionParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      transaction_at: formFilterTransaction.transaction_at_start
        ? [
            `${formatDateParam(formFilterTransaction.transaction_at_start)}`,
            `${formatDateParam(formFilterTransaction.transaction_at_end)}`
          ]
        : null,
      status: formFilterTransaction.status ?? null
    };

    affiliateRep
      .fetchAffiliateTransaction(params)
      .then((result: any) => {
        setAffiliateTransactions(result.data);
        seAffiliateTransactionPagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW TRANSACTION
  const handleShowTransaction = (_data: TransactionModel) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uid: _data.UID
    };

    transactionRep
      .showTransaction(params)
      .then((result: any) => {
        setTransaction(result);
        handleDetailAffiliateTransactionModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE APPROVE TRANSACTION
  const handleApprovePayment = (_data: TransactionModel | undefined) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          uid: _data?.UID,
          status: STATUS_TRANSACTION.SUCCESS
        };

        transactionRep
          .updateTransaction(params)
          .then((result: any) => {
            alertToast.updateLoading(x);
            handleDetailAffiliateTransactionModal.setState(false);
            handleFetchAffiliateTransaction({ page: 1 });
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE CANCEL TRANSACTION
  const handleCancelTransaction = (_data: TransactionModel | undefined) => {
    alertSweet.questionAlert('Apakah anda yakin cancel transaksi?').then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          uid: _data?.UID
        };

        transactionRep
          .cancelTransaction(params)
          .then((result: any) => {
            alertToast.updateLoading(x);
            handleDetailAffiliateTransactionModal.setState(false);
            handleFetchAffiliateTransaction({ page: 1 });
            alertToast.successAlert();
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: AffiliateContextValue = {
    formFilterTransaction,
    setFormFilterTransaction,
    handleShowTransaction,
    transaction,
    affiliateTransactions,
    affiliateTransactionPagination,
    handleDetailAffiliateTransactionModal,
    handleFetchAffiliateTransaction,
    handleApprovePayment,
    handleCancelTransaction
  };

  return <MemberVerifyContext.Provider value={contextValue}>{children}</MemberVerifyContext.Provider>;
};

const useAffiliateTransactionContext = (): AffiliateContextValue => {
  const context = useContext(MemberVerifyContext);
  if (!context) {
    throw new Error('useAffiliateTransactionContext must be used within a AffiliateTransactionProvider');
  }
  return context;
};

export { AffiliateTransactionProvider, useAffiliateTransactionContext };
