import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';

import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { TransactionRepository } from '@/domain/repository/transaction_repository.ts';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';
import { TransactionPrintRepository } from '@/domain/repository/transaction_print_repository.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { UserAddressRepository } from '@/domain/repository/user_address_repository.ts';
import { UserAddressModel } from '@/domain/model/user_address_model.ts';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';
import CONFIRM_FLOW_TYPE from '@/domain/constant/history/confirm_flow_type.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface HistoryValue {
  uid?: string;
  page?: number;
  limit?: number;
  trx_uid?: string;
  flow?: string;
  rejected_reason?: string;
}

interface FormFilterHistory {
  search?: string;
  type?: string;
  status?: string;
  transaction_at_start?: string;
  transaction_at_end?: string;
}

interface FormHistoryInterface {
  trxPrintUID?: string;
  type?: string;
  gram?: number;
  quantity?: number;
  notes?: string;
  counterUID?: string;
  rejectedReason?: string;
}

interface HistoriesValue {
  formFilterHistory: FormFilterHistory;
  setFormFilterHistory: Dispatch<SetStateAction<FormFilterHistory>>;
  userAddress?: UserAddressModel;
  formHistory: FormHistoryInterface;
  setFormHistory: Dispatch<SetStateAction<FormHistoryInterface>>;
  history?: TransactionModel;
  histories: TransactionModel[];
  historyPagination?: PaginationModel;
  handleAddHistoryModal: ModalObject;
  handleRejectPrintGoldModal: ModalObject;
  handleFetchTransaction: (_data: HistoryValue) => void;
  handleShowDetailHistory: (_data: HistoryValue) => void;
  handleConfirmPrintGoldPickUp: (_data: HistoryValue) => void;
  handleRejectPrintGoldPickUp: (_data: HistoryValue) => void;
  handleCancelTransaction: (_data: TransactionModel | undefined) => void;
}

const HistoryContext = createContext<HistoriesValue | null>(null);

const historyRepository = new TransactionRepository();
const transactionPrint = new TransactionPrintRepository();
const userAddressRep = new UserAddressRepository();
const transactionListRepository = new TransactionRepository();

const HistoryProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const handleAddHistoryModal = useVisibleComponent(false);
  const handleRejectPrintGoldModal = useVisibleComponent(false);

  const [userAddress, setUserAddress] = useState<UserAddressModel>();
  const [history, setHistory] = useState<TransactionModel>();
  const [histories, setHistories] = useState<TransactionModel[]>([]);
  const [historyPagination, setHistoryPagination] = useState<PaginationModel>();

  const [formHistory, setFormHistory] = useState<FormHistoryInterface>({});

  const [formFilterHistory, setFormFilterHistory] = useState<FormFilterHistory>({});

  //HANDLE FETCH TRANSACTION HISTORY /
  const handleFetchTransaction = (_data: HistoryValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      sort: 'created_at desc',
      type: TYPE_TRANSACTION.PRINT,
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      search: formFilterHistory.search ?? null,
      status: formFilterHistory.status ?? null,
      transaction_at: formFilterHistory.transaction_at_start
        ? [
            `${formatDateParam(formFilterHistory.transaction_at_start)}`,
            `${formatDateParam(formFilterHistory.transaction_at_end)}`
          ]
        : null
    };

    historyRepository
      .fetchTransactionPrint(params)
      .then((result: any) => {
        setHistories(result.data);
        setHistoryPagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW DETAIL HISTORY
  const handleShowDetailHistory = (_data: HistoryValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uid: _data.uid
    };

    historyRepository
      .showTransaction(params)
      .then((result: any) => {
        const res: TransactionModel = result;
        setHistory(res);

        const paramAddress = {
          user_uuid: res.userUID
        };

        userAddressRep
          .showUserAddress(paramAddress)
          .then((resultAddress: any) => {
            alertToast.updateLoading(x);
            setUserAddress(resultAddress);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE CONFIRM PRINT GOLD PICK UP
  const handleConfirmPrintGoldPickUp = (_data: HistoryValue) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        const params = {
          trx_uid: _data.trx_uid,
          flow: _data.flow // confirm, reject, finish
        };

        transactionPrint
          .transactionPrintConfirmation(params)
          .then(() => {
            alertToast.updateLoading(x);
            handleShowDetailHistory({ uid: _data.trx_uid });
            alertToast.successAlert();
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE CONFIRM PRINT GOLD PICK UP
  const handleRejectPrintGoldPickUp = (_data: HistoryValue) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        const params = {
          trx_uid: _data.trx_uid,
          flow: CONFIRM_FLOW_TYPE.REJECT,
          rejected_reason: formHistory.rejectedReason
        };

        transactionPrint
          .transactionPrintConfirmation(params)
          .then(() => {
            handleRejectPrintGoldModal.setState(false);
            alertToast.updateLoading(x);
            handleShowDetailHistory({ uid: _data.trx_uid });
            alertToast.successAlert();
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE CANCEL TRANSACTION
  const handleCancelTransaction = (_data: TransactionModel | undefined) => {
    alertSweet.questionAlert('Apakah anda yakin cancel transaksi?').then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          uid: _data?.UID
        };

        transactionListRepository
          .cancelTransaction(params)
          .then((result: any) => {
            alertToast.updateLoading(x);
            handleShowDetailHistory({ uid: _data?.UID });
            alertToast.successAlert();
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  // //HANDLE SHOW USER ADDRESS
  // const handleShowUserAddress = (_data: HistoryValue) => {
  //   const params = {
  //     user_uid: _data.uid
  //   };
  //
  //   userAddressRep
  //     .showUserAddress(params)
  //     .then((result: any) => {
  //       setUserAddress(result);
  //       setHistory(result);
  //     })
  //     .catch(error => {
  //       alertToast.errorAlert(error);
  //     });
  // };

  const contextValue: HistoriesValue = {
    formFilterHistory,
    setFormFilterHistory,
    userAddress,
    history,
    handleShowDetailHistory,
    formHistory,
    setFormHistory,
    histories,
    historyPagination,
    handleAddHistoryModal,
    handleRejectPrintGoldModal,
    handleCancelTransaction,
    handleFetchTransaction,
    handleConfirmPrintGoldPickUp,
    handleRejectPrintGoldPickUp
  };

  return <HistoryContext.Provider value={contextValue}>{children}</HistoryContext.Provider>;
};

const useHistoryContext = (): HistoriesValue => {
  const context = useContext(HistoryContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a HistoryProvider');
  }
  return context;
};

export { HistoryProvider, useHistoryContext };
