import { ApiClient } from '@/main/apiClient';
import AddressInterface, { AddressValue } from '@/domain/repository/interface/address_interface.ts';
import { Address } from '@/domain/constant/api_list';
import { PaginationModel } from '@/domain/model/pagination_model';
import AddressModel from '@/domain/model/address_model.ts';

export class AddressRepository implements AddressInterface {
  api = new ApiClient();

  async fetchAddress(data: AddressValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Address.FETCH, {
            params: data
          })
          .then(result => {
            const data = AddressModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showAddress(data: AddressValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Address.SHOW, {
            params: data
          })
          .then(result => {
            const data = AddressModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateAddress(data: AddressValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(Address.UPDATE + data.uuid, data)
          .then(result => {
            const data = AddressModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteAddress(data: AddressValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(Address.DELETE + data.uid, {
            params: data
          })
          .then(result => {
            const data = AddressModel.fromJsonList(result.data.data.result);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeAddress(data: AddressValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Address.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
