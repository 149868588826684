import { useEffect } from 'react';
import { RiceRateProvider } from '@/delivery/ui/admin/master/rice_rate/context/rice_rate_context.tsx';
import RiceRatePage from '@/delivery/ui/admin/master/rice_rate';

const ViewRiceRate = () => {
  useEffect(() => {
    document.title = 'Rice Rate';
  }, []);

  return (
    <div>
      <RiceRateProvider>
        <RiceRatePage />
      </RiceRateProvider>
    </div>
  );
};

export default ViewRiceRate;
