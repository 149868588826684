import { BaseModel } from '@/domain/model/base_model.ts';
import { formatDate, formatTime } from '@/infrastructure/helper/formatDate.ts';

export class OfflineCounterUnavailabilityModel extends BaseModel {
  startHour: string;
  endHour: string;
  startDate: string;
  endDate: string;
  offlineCounterUID: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    startHour: string,
    endHour: string,
    startDate: string,
    endDate: string,
    offlineCounterUID: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.startHour = startHour;
    this.endHour = endHour;
    this.offlineCounterUID = offlineCounterUID;
    this.startDate = startDate;
    this.endDate = endDate;
  }

  get getStartDate(): string {
    if (this.startDate) {
      return formatDate(this.startDate);
    }

    return '-';
  }

  get getEndDate(): string {
    if (this.endDate) {
      return formatDate(this.endDate);
    }

    return '-';
  }

  get getStartHour(): string {
    if (this.startHour) {
      return formatTime(this.startHour);
    }

    return '-';
  }

  get getEndHour(): string {
    if (this.endHour) {
      return formatTime(this.endHour);
    }

    return '-';
  }

  public static fromJson(data: any): OfflineCounterUnavailabilityModel | any {
    try {
      return new OfflineCounterUnavailabilityModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.start_hour,
        data.end_hour,
        data.start_date,
        data.end_date,
        data.offline_counter_uid
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): OfflineCounterUnavailabilityModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(OfflineCounterUnavailabilityModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
