import { Link } from 'react-router-dom';
import { useState } from 'react';
import clsx from 'clsx';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import styles from './Menu.module.css';

import { useLocation } from 'react-router-dom';
import accessMenuCheck from '@/infrastructure/middleware/access_menu.ts';

interface MenuProps {
  icon?: any;
  submenu?: any[];
  label?: string;
  href?: string | any;
  hidden?: boolean;
}

const Menu = (props: MenuProps) => {
  const location = useLocation();
  const { icon, submenu, label, href, hidden } = props;

  const [open, setOpen] = useState(false);
  const [openSubSubmenu, setOpenSubSubmenu] = useState(false);

  const handleToggleOpen = () => {
    setOpen(prevOpen => !prevOpen);
  };

  return (
    <>
      {submenu ? (
        <div className={hidden ? 'hidden' : ''}>
          <div
            onClick={handleToggleOpen}
            className={clsx(styles['menu'], `${location.pathname.includes(href) ? styles['active'] : ''}`)}
          >
            <div className="flex flex-row items-center gap-[12px]">
              <span className="text-text-tertiary-invert">{icon}</span>
              <span className="text-text-secondary-invert">{label}</span>
            </div>

            <span className="text-text-tertiary-invert">{open ? <FiChevronUp /> : <FiChevronDown />}</span>
          </div>
          {submenu?.map((item, index) => (
            <div>
              <div key={item.label} className={accessMenuCheck(item.access || []) === false ? 'hidden' : ''}>
                {item.submenu ? (
                  <div className={!open ? 'hidden' : ''}>
                    <div
                      onClick={() => setOpenSubSubmenu(!openSubSubmenu)}
                      className={clsx(
                        styles['menu'],
                        'ml-[30px]',
                        `${location.pathname.includes(item.href) ? styles['active'] : ''}`
                      )}
                    >
                      <div className="flex flex-row items-center gap-[12px]">
                        <span className="text-text-tertiary-invert">{item.prefixIcon}</span>
                        <span className="text-text-secondary-invert">{item.label}</span>
                      </div>

                      <span className="text-text-tertiary-invert">
                        {openSubSubmenu ? <FiChevronUp /> : <FiChevronDown />}
                      </span>
                    </div>
                    {item?.submenu?.map((iteSub: any) => (
                      <Link to={iteSub.href}>
                        <div
                          className={clsx(
                            `${openSubSubmenu ? 'flex' : 'hidden'}`,
                            `${iteSub.href === location.pathname ? styles['active'] : ''}`,
                            `${styles['menu-sub']}`,
                            'ml-[30px]'
                          )}
                        >
                          <div className="flex flex-row items-center gap-[12px]">
                            <span className="text-text-secondary-invert pl-[28px]">{iteSub.label}</span>
                          </div>

                          <span className="text-text-tertiary-invert"></span>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <Link to={item.href}>
                    <div
                      className={clsx(
                        `${open ? 'flex' : 'hidden'}`,
                        `${item.href === location.pathname ? styles['active'] : ''}`,
                        `${styles['menu-sub']}`
                      )}
                    >
                      <div className="flex flex-row items-center gap-[12px]">
                        <span className="text-text-secondary-invert pl-[28px]">{item.label}</span>
                      </div>

                      <span className="text-text-tertiary-invert"></span>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Link className={hidden ? 'hidden' : ''} to={href}>
          <div className={`${href === location.pathname ? styles['active'] : ''} ${styles['menu']}`}>
            <div className="flex flex-row items-center gap-[12px]">
              <span className="text-text-tertiary-invert">{icon}</span>
              <span className="text-text-secondary-invert">{label}</span>
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export default Menu;
