import { useEffect } from 'react';
import GoldPiecePage from '@/delivery/ui/admin/master/gold_piece';
import { GoldPieceProvider } from '@/delivery/ui/admin/master/gold_piece/context/gold_piece_context.tsx';

const ViewGoldPiece = () => {
  useEffect(() => {
    document.title = 'Gold Piece';
  }, []);

  return (
    <div>
      <GoldPieceProvider>
        <GoldPiecePage />
      </GoldPieceProvider>
    </div>
  );
};

export default ViewGoldPiece;
