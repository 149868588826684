import { useEffect } from 'react';
import MemberListPage from '@/delivery/ui/admin/data_member/member_list';
import { MemberListProvider } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context';
import { MemberListCountProvider } from '@/delivery/ui/admin/data_member/member_list/context/member_list_count_context';
import { CorporateProvider } from '@/delivery/ui/admin/data_member/member_list/context/corporate_context.tsx';
import { BalanceProvider } from '@/delivery/ui/admin/data_member/member_list/context/balance_context.tsx';

const ViewMemberList = () => {
  useEffect(() => {
    document.title = 'Member List';
    // context = useMemberListContext();
    // userContext = userContext()
    // Contexxt.fetchUser = (param) => {
    //   return useInRouterContext.fetchUser(params)
    // }
  }, []);

  return (
    <div>
      <BalanceProvider>
        <CorporateProvider>
          <MemberListProvider>
            <MemberListCountProvider>
              <MemberListPage />
            </MemberListCountProvider>
          </MemberListProvider>
        </CorporateProvider>
      </BalanceProvider>
    </div>
  );
};

export default ViewMemberList;
