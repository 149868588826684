import DinaranSettingInterface, {
  DinaranSettingValue
} from '@/domain/repository/interface/dinaran_setting_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { DinaranSettingModel } from '@/domain/model/dinaran_setting_model.ts';
import { DinaranSetting } from '@/domain/constant/api_list';

export class DinaranSettingRepository implements DinaranSettingInterface {
  api = new ApiClient();

  async fetchDinaranSetting(data: DinaranSettingValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(DinaranSetting.FETCH, {
            params: data
          })
          .then(result => {
            const data = DinaranSettingModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showDinaranSetting(data: DinaranSettingValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(DinaranSetting.SHOW, {
            params: data
          })
          .then(result => {
            const data = DinaranSettingModel.fromJson(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeDinaranSetting(data: DinaranSettingValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(DinaranSetting.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkDinaranSetting(data: DinaranSettingValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(DinaranSetting.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateDinaranSetting(data: DinaranSettingValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(DinaranSetting.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteDinaranSetting(data: DinaranSettingValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(DinaranSetting.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
