import { Table, Tbody, Td, Tfooter, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import STATUS_CONSTANT from '@/domain/constant/status_constanat.ts';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { Routing } from '@/domain/constant/router_path.ts';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { useWithdrawalContext } from '@/delivery/ui/admin/settings/withdrawal/context/withdrawal_context.tsx';
import { WithdrawalMethodModel } from '@/domain/model/withdrawal_method_model.ts';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Withdrawal = () => {
  const navigate = useNavigate();
  const { handleFetchWithdrawalMethod, withdrawalMethods, withdrawalMethodPagination } = useWithdrawalContext();

  useEffect(() => {
    handleFetchWithdrawalMethod({});
  }, []);

  return (
    <div>
      <Theader>
        <Typography size={16} fontWeight={600} type={'secondary-invert'}>
          Channel Pambayaran
        </Typography>
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Nama Penyedia</Th>
            <Th>Channel</Th>
            <Th>Description</Th>
            <Th>Status</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {withdrawalMethods?.map((item: WithdrawalMethodModel, index: number) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.name}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.code}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.description}
                </Typography>
              </Td>
              <Td>
                <Chips
                  type={item.status === STATUS_CONSTANT.active ? 'primary-light' : 'danger-light'}
                  label={item.status}
                />
              </Td>
              <Td>
                <div className="flex gap-[4px]">
                  <Button
                    iconButton
                    iconType={'edit'}
                    onClick={() => navigate(Routing.SETTINGS.WITHDRAWAL_DETAIL + item.UID)}
                  />
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={withdrawalMethodPagination?.page || 1}
          totalPages={withdrawalMethodPagination?.totalPages || 1}
          handlePagination={page => {
            handleFetchWithdrawalMethod({
              page: page
            });
          }}
        />
      </Tfooter>
    </div>
  );
};

export default Withdrawal;
