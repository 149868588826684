import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useMemberVefiryContext } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';
import DialogUpdateIdentity from '@/delivery/ui/admin/data_member/member_verify/detail/dialog/dialog_update_identity.tsx';

const TableKTP = () => {
  const { handleShowUpdateIdentityModal, userVerification } = useMemberVefiryContext();
  return (
    <div>
      <Table noBorder>
        <Thead>
          <Tr noBorder>
            <Th noBorder>
              <Typography fontWeight={600} size={14} type="primary-invert">
                KTP
              </Typography>
            </Th>
            <Th noBorder>
              <div className={'justify-end flex'}>
                <span
                  className={'text-text-primary font-[600] text-[14px] cursor-pointer'}
                  onClick={() => handleShowUpdateIdentityModal.setState(true)}
                >
                  Edit
                </span>
              </div>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {/*NIK*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                NIK
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.identityNumber}
              </Typography>
            </Td>
          </Tr>

          {/*nama lengkap*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Nama Lengkap
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.user.fullName}
              </Typography>
            </Td>
          </Tr>

          {/*tempat lahir*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Tempat Lahir
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.user.birthPlace}
              </Typography>
            </Td>
          </Tr>

          {/*tanggal lahir*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Tanggal lahir
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.user.getBirthDate}
              </Typography>
            </Td>
          </Tr>

          {/*agama*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Agama
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.user.religion}
              </Typography>
            </Td>
          </Tr>

          {/*janis kelamin*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Jenis Kelamin
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.user.gender}
              </Typography>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      {handleShowUpdateIdentityModal.state && <DialogUpdateIdentity modal={handleShowUpdateIdentityModal} />}
    </div>
  );
};

export default TableKTP;
