export const DREAM_GOLD_STATUS = {
  COMPLETE: 'COMPLETE',
  CLOSED: 'CLOSED',
  IN_PROGRESS: 'IN PROGRESS',
  FORCE_CLOSE: 'FORCE CLOSE',
  LOCKED: 'LOCKED'
};

export const DREAM_GOLD_STATUS_LIST = [
  { UUID: 'CLOSED', name: 'CLOSED' },
  { UUID: 'IN PROGRESS', name: 'IN PROGRESS' },
  { UUID: 'FORCE CLOSE', name: 'FORCE CLOSE' },
  { UUID: 'COMPLETE', name: 'COMPLETE' },
  { UUID: 'LOCKED', name: 'LOCKED' }
];
