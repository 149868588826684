export const NOTIFY_CATEGORY = {
  GENERAL: 'GENERAL',
  TRANSACTION: 'TRANSACTION',
  ACCOUNT: 'ACCOUNT',
  PROMOTION: 'PROMOTION',
  RATE_DOWN: 'RATE_DOWN',
  RATE_UP: 'RATE_UP'
};

export const NOTIFY_CATEGORY_LIST = [
  { UUID: 'GENERAL', name: 'GENERAL' },
  { UUID: 'TRANSACTION', name: 'TRANSACTION' },
  { UUID: 'ACCOUNT', name: 'ACCOUNT' },
  { UUID: 'PROMOTION', name: 'PROMOTION' },
  { UUID: 'RATE_DOWN', name: 'RATE DOWN' },
  { UUID: 'RATE_UP', name: 'RATE UP' }
];
