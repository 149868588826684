import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useEffect } from 'react';
import { useVendingMachineRackContext } from '@/delivery/ui/admin/transaction/take_gold/vending_machine/context/vending_machine_rack_context.tsx';
import { useParams } from 'react-router-dom';
import { TypeGoldStockList } from '@/domain/constant/gold_stock/type_gold_stock.ts';

const DialogUpdateVendingMachineRack = (props: ModalDialogProps) => {
  const { uid } = useParams();
  const {
    handleUpdateVendingMachineRackModal,
    handleUpdateVendingMachine,
    goldPieces,
    handleFetchGoldPiece,
    formVendingMachineRack,
    setFormVendingMachineRack,
    vendingMachineRack
  } = useVendingMachineRackContext();

  useEffect(() => {
    handleFetchGoldPiece();
  }, []);

  return (
    <ModalDialog overflow={false} size="small" title="Update Vending Machine Rack" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField
          value={formVendingMachineRack.rack}
          label="Rack"
          placeholder="Rack"
          block
          onChange={e => {
            setFormVendingMachineRack({
              ...formVendingMachineRack,
              rack: e.target.value
            });
          }}
        />

        <Select2
          value={formVendingMachineRack.goldPieceUID}
          label={'Gold Piece'}
          options={goldPieces.map(item => {
            return {
              label: item.name,
              value: item.UID
            };
          })}
          onChange={(e: any) => {
            setFormVendingMachineRack({
              ...formVendingMachineRack,
              goldPieceUID: e?.value
            });
          }}
        />

        <div className={'flex gap-[20px]'}>
          <TextField
            disabled
            type={'number'}
            value={vendingMachineRack?.stock}
            label="Old Stock"
            placeholder="Old Stock"
            block
          />
          <TextField
            type={'number'}
            label="Add Stock"
            placeholder="Add Stock"
            block
            onChange={e => {
              setFormVendingMachineRack({
                ...formVendingMachineRack,
                stock: parseInt(e.target.value)
              });
            }}
          />
        </div>

        <Select2
          value={formVendingMachineRack.type}
          isSearchable={false}
          label="Type Stock"
          block
          options={TypeGoldStockList.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          onChange={e =>
            setFormVendingMachineRack({
              ...formVendingMachineRack,
              type: e?.value
            })
          }
        />
      </div>
      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleUpdateVendingMachineRackModal.setState(false)} />
        <Button label="Submit" onClick={() => handleUpdateVendingMachine({ vending_machine_uid: uid })} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogUpdateVendingMachineRack;
