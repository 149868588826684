export const truncateDecimal = (number: number) => {
  if (number > 0) {
    return Math.floor(number * 100) / 100;
  }

  if (number < 0) {
    const newNumber = number * -1;
    return Math.floor(newNumber * 100) / 100;
  }

  return 0;
};
