import Navbar from '@/delivery/components/organisms/navbar/navbar.tsx';
import Settlement from '@/delivery/ui/admin/transaction/settlement/settlement.tsx';

const SettlementPage = () => {
  return (
    <div>
      <Navbar label={'Settlement'}></Navbar>
      <Settlement />
    </div>
  );
};

export default SettlementPage;
