import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card.tsx';
import { useDashboardTransactionContext } from '@/delivery/ui/admin/dashboard/transaction/context/dashboard_transaction_context.tsx';
import { useEffect } from 'react';
import formatNumberWithDot from '@/infrastructure/helper/formatNumberWithDot.ts';

const SummaryTransaction = () => {
  const { transactionSummaryData, transactionSummaryLoading, transactionSummary } = useDashboardTransactionContext();

  useEffect(() => {
    transactionSummary();
  }, []);

  useEffect(() => {
    if (transactionSummaryData) {
      console.log(transactionSummaryData, 'transactionSummaryData');
    }
  }, [transactionSummaryData]);

  return (
    <div>
      <div className="grid grid-cols-4 gap-[24px]">
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total Transaksi"
          bigTitle={formatNumberWithDot(transactionSummaryData?.totalTransaction.total ?? 0).toString()}
        />
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total Transaksi Beli Emas"
          bigTitle={formatNumberWithDot(transactionSummaryData?.totalBuy.total ?? 0).toString()}
        />
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total Transaksi Jual Emas"
          bigTitle={formatNumberWithDot(transactionSummaryData?.totalSell.total ?? 0).toString()}
        />
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total Transaksi Setor Emas"
          bigTitle={formatNumberWithDot(transactionSummaryData?.totalDeposit.total ?? 0).toString()}
        />
      </div>
      <div className="grid grid-cols-4 gap-[24px] mt-[24px]">
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total Transaksi Gramasi Emas"
          bigTitle={formatNumberWithDot(transactionSummaryData?.totalTransaction.totalGram ?? 0).toString()}
          unit={'gr'}
        />
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total Gramasi Beli Emas"
          bigTitle={formatNumberWithDot(transactionSummaryData?.totalBuy.totalGram ?? 0).toString()}
          unit={'gr'}
        />
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total Gramasi Jual Emas"
          bigTitle={formatNumberWithDot(transactionSummaryData?.totalSell.totalGram ?? 0).toString()}
          unit={'gr'}
        />
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total Gramasi Jual Emas"
          bigTitle={formatNumberWithDot(transactionSummaryData?.totalDeposit.totalGram ?? 0).toString()}
          unit={'gr'}
        />

        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total Transaksi Rupiah"
          bigTitle={formatNumberWithDot(transactionSummaryData?.totalTransaction.totalFiat ?? 0).toString()}
          unit={'rp'}
        />
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total Rupiah Beli Emas"
          bigTitle={formatNumberWithDot(transactionSummaryData?.totalBuy.totalFiat ?? 0).toString()}
          unit={'rp'}
        />
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total Rupiah Jual Emas"
          bigTitle={formatNumberWithDot(transactionSummaryData?.totalSell.totalFiat ?? 0).toString()}
          unit={'rp'}
        />
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total Rupiah Setor Emas"
          bigTitle={formatNumberWithDot(transactionSummaryData?.totalDeposit.totalFiat ?? 0).toString()}
          unit={'rp'}
        />

        {/*//DCA*/}
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total DCA All Member"
          bigTitle={formatNumberWithDot(transactionSummaryData?.dcaUser ?? 0).toString()}
        />
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total DCA by Gramasi"
          bigTitle={formatNumberWithDot(transactionSummaryData?.dcaGram ?? 0).toString()}
          unit={'gr'}
        />
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total DCA (80%) by Rupiah"
          bigTitle={formatNumberWithDot(transactionSummaryData?.dca.total ?? 0).toString()}
          unit={'rp'}
        />
        <SimpleCard
          loading={transactionSummaryLoading}
          title="Total DCA (20%) by Gramasi"
          bigTitle={formatNumberWithDot(transactionSummaryData?.dcaGramLocked ?? 0).toString()}
          unit={'gr'}
        />
      </div>
    </div>
  );
};

export default SummaryTransaction;
