import MemberList from './member_list';
import Navbar from '@/delivery/components/organisms/navbar/navbar';

const MemberListPage = () => {
  return (
    <div>
      <Navbar label={'Member List'}></Navbar>
      <MemberList />
    </div>
  );
};

export default MemberListPage;
