import { useReducer } from 'react';

const useDropzone = () => {
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'SET_IN_DROP_ZONE':
        return { ...state, inDropZone: action.inDropZone };
      case 'ADD_FILE_TO_LIST':
        return { ...state, fileList: state.fileList.concat(action.files) };
      case 'REMOVE_FILE_FROM_LIST':
        return {
          ...state,
          fileList: state.fileList.filter((f: any) => f !== action.file)
        };
      default:
        return state;
    }
  };

  const [data, dispatch] = useReducer(reducer, {
    inDropZone: false,
    fileList: []
  });

  //   useEffect(() => {
  //     let files = data.fileList;

  //     _executorContext!.mediaHook[1](files);
  //   }, [data]);

  return {
    data,
    dispatch
  };
};

export default useDropzone;
