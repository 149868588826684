import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { UserModel } from '@/domain/model/user_model.ts';
import { UserRepository } from '@/domain/repository/user_repository.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { RoleRepository } from '@/domain/repository/role_repository.ts';
import { RoleModel } from '@/domain/model/role_model.ts';
import { AuthRepository } from '@/domain/repository/auth_repository.ts';
import { getProfile } from '@/infrastructure/helper/auth.ts';
import { MEMBER_TYPE } from '@/domain/constant/member/member_type.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { RoleUserCompanyRepository } from '@/domain/repository/role_user_company_repository.ts';
import { RoleUserCompanyModel } from '@/domain/model/role_user_company_model.ts';
interface UserContextProps {
  children: ReactNode;
}

interface UserParams {
  page?: number;
  limit?: number;
  type?: string;
  user_uuid?: string;
  user_company_uuid?: string;
  search?: string;
}

interface FormUser {
  uuid?: string;
  company_uuid?: string;
  default_company_uuid?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  phone?: string;
  ref_id?: string;
  role_uuid?: string;
  type?: string;
  username?: string;
  verified_status?: boolean;
}

interface UserContextValue {
  formRole: string[];
  setFormRole: Dispatch<SetStateAction<string[]>>;
  formRoleUserCompany: string[];
  setFormRoleUserCompany: Dispatch<SetStateAction<string[]>>;
  roleUserCompanies: RoleUserCompanyModel[];
  formUser: FormUser;
  setFormUser: Dispatch<SetStateAction<FormUser>>;
  roles: RoleModel[];
  user?: UserModel;
  users: UserModel[];
  userPagination?: PaginationModel;
  handleAddUserModal: ModalObject;
  handleUpdateUserModal: ModalObject;
  handleFetchUser: (_data: UserParams) => void;
  handleFetchRole: (_data: UserParams) => void;
  handleStoreUser: () => void;
  handleShowUser: (_data: UserModel) => void;
  handleUpdateUser: () => void;
  handleDeleteUser: (_data: UserModel) => void;
  handleFetchRoleUserCompany: (_data: UserParams) => void;
  handleShowUserObject: (_data: UserParams) => void;
  handleAssignRole: (_data: UserParams) => void;
  handleDeleteRoleUserCompany: (_data: UserParams) => void;
}

const UserContext = createContext<UserContextValue | null>(null);

const userRep = new UserRepository();
const roleRep = new RoleRepository();
const authRep = new AuthRepository();
const roleuserCompanyRep = new RoleUserCompanyRepository();

const UserProvider: React.FC<UserContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();
  const profile = getProfile();

  const [roles, setRoles] = useState<RoleModel[]>([]);

  const [roleUserCompanies, setRoleUserCompanies] = useState<RoleUserCompanyModel[]>([]);

  const [user, setUser] = useState<UserModel>();
  const [users, setUsers] = useState<UserModel[]>([]);
  const [userPagination, setUserPagination] = useState<PaginationModel>();

  const [formUser, setFormUser] = useState<FormUser>({});

  const [formRole, setFormRole] = useState<string[]>([]);
  const [formRoleUserCompany, setFormRoleUserCompany] = useState<string[]>([]);

  const handleAddUserModal = useVisibleComponent(false);
  const handleUpdateUserModal = useVisibleComponent(false);

  //HANDLE ADD STOCK
  const handleFetchUser = (_data: UserParams) => {
    const x = alertToast.loadingAlert('');

    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      search: _data.search ?? null,
      type: MEMBER_TYPE.ADMIN
    };

    userRep
      .fetchUser(params)
      .then((result: any) => {
        setUsers(result.data);
        setUserPagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH ROLE
  const handleFetchRole = (_data: UserParams) => {
    const x = alertToast.loadingAlert('Fetching Role');
    const params = {
      limit: -1
    };

    roleRep
      .fetchRole(params)
      .then((result: any) => {
        setRoles(result.data);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE STORE USER
  const handleStoreUser = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      company_uuid: [`${profile?.company_uuid}`],
      default_company_uuid: profile?.company_uuid,
      email: formUser.email,
      first_name: formUser.first_name,
      last_name: formUser.last_name,
      password: formUser.password,
      phone: formUser.phone,
      role_uuid: [`${formUser.role_uuid}`],
      type: MEMBER_TYPE.ADMIN,
      username: formUser.username,
      verified_status: true
    };

    authRep
      .register(params)
      .then((result: any) => {
        if (result) {
          //UPDATE USER ADMIN REGISTER
          const params = {
            uuid: result.UUID,
            first_name: formUser.first_name,
            last_name: formUser.last_name,
            email: formUser.email,
            username: formUser.username,
            phone: formUser.phone,
            role_uuid: [`${formUser.role_uuid}`],
            type: MEMBER_TYPE.ADMIN
          };

          userRep
            .updateUser(params)
            .then((result: any) => {
              handleFetchUser({ page: 1 });
              alertToast.updateLoading(x);
              alertToast.successAlert();
              handleAddUserModal.setState(false);
            })
            .catch(error => {
              alertToast.updateLoading(x);
              alertToast.errorAlert(error);
            });
        }
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW USER
  const handleShowUser = (_data: UserModel) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: _data.UUID
    };

    userRep
      .showUser(params)
      .then((result: any) => {
        const res: UserModel = result;
        setFormUser({
          uuid: res.UUID,
          first_name: res.firstName,
          last_name: res.lastName,
          email: res.email,
          username: res.username,
          phone: res.phone
        });
        handleUpdateUserModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE USER
  const handleUpdateUser = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: formUser.uuid,
      first_name: formUser.first_name,
      last_name: formUser.last_name,
      email: formUser.email,
      username: formUser.username,
      phone: formUser.phone,
      role_uuid: [`${formUser.role_uuid}`],
      type: MEMBER_TYPE.ADMIN
    };

    userRep
      .updateUser(params)
      .then((result: any) => {
        handleUpdateUserModal.setState(false);
        handleFetchUser({ page: 1 });
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE DELETE USER
  const handleDeleteUser = (_data: UserModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        userRep
          .deleteUser({ uuid: _data.UUID })
          .then((result: any) => {
            handleFetchUser({ page: 1 });
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE SHOW USER
  const handleShowUserObject = (_data: UserParams) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: _data.user_uuid
    };

    userRep
      .showUser(params)
      .then((result: any) => {
        const res: UserModel = result;
        setUser(res);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH USER COMPANY
  const handleFetchRoleUserCompany = (_data: UserParams) => {
    const x = alertToast.loadingAlert('Fetching Role User');

    const params = {
      page: 1,
      limit: -1,
      user_company_uuid: _data.user_company_uuid
    };

    roleuserCompanyRep
      .fetchRoleUserCompany(params)
      .then((result: any) => {
        setRoleUserCompanies(result.data);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE ASSIGN ROLE
  const handleAssignRole = (_data: UserParams) => {
    const x = alertToast.loadingAlert('Assigning Role');

    const formUserParams = [];
    for (let x = 0; x < formRole.length; x++) {
      formUserParams.push({
        role_uuid: formRole[x],
        user_company_uuid: _data.user_company_uuid
      });
    }

    const params = {
      role_user_company_params: formUserParams
    };

    roleuserCompanyRep
      .storeBulkRoleUserCompany(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        handleFetchRole({});
        handleFetchRoleUserCompany({ user_company_uuid: _data.user_company_uuid });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE DELETE ROLE USER COMPANY / ASSIGN ROLE
  const handleDeleteRoleUserCompany = (_data: UserParams) => {
    const x = alertToast.loadingAlert('Removing Role');

    roleuserCompanyRep
      .deleteRoleUserCompany(formRoleUserCompany.join(','))
      .then((result: any) => {
        alertToast.updateLoading(x);
        setFormRole([]);
        handleFetchRole({});
        handleFetchRoleUserCompany({ user_company_uuid: _data.user_company_uuid });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: UserContextValue = {
    formRole,
    setFormRole,
    formRoleUserCompany,
    setFormRoleUserCompany,
    handleShowUserObject,
    user,
    roleUserCompanies,
    handleUpdateUser,
    handleUpdateUserModal,
    handleShowUser,
    formUser,
    setFormUser,
    userPagination,
    roles,
    users,
    handleAddUserModal,
    handleDeleteRoleUserCompany,
    handleFetchUser,
    handleFetchRole,
    handleStoreUser,
    handleDeleteUser,
    handleFetchRoleUserCompany,
    handleAssignRole
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

const useUserContext = (): UserContextValue => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a UserProvider');
  }
  return context;
};

export { UserProvider, useUserContext };
