const COMMISSION_TYPE_LIST = [
  { UUID: 'PERCENT', name: 'Percent (%)' },
  { UUID: 'FLAT', name: 'Flat (Rp)' }
];

export const COMMISSION_TYPE = {
  PERCENT: 'PERCENT',
  FLAT: 'FLAT'
};

export default COMMISSION_TYPE_LIST;
