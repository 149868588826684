import BalanceLockInterface, { BalanceLockValue } from '@/domain/repository/interface/balance_lock_interface';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { BalanceLock } from '@/domain/constant/api_list';
import { BalanceModel } from '@/domain/model/balance_model.ts';

export class BalanceLockRepository implements BalanceLockInterface {
  api = new ApiClient();

  async fetchBalanceLock(data: BalanceLockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(BalanceLock.FETCH, {
            params: data
          })
          .then(result => {
            const data = BalanceModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showBalanceLock(data: BalanceLockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(BalanceLock.SHOW, {
            params: data
          })
          .then(result => {
            const data = BalanceModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBalanceLock(data: BalanceLockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(BalanceLock.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async releaseBalanceLock(data: BalanceLockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(BalanceLock.RELEASE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkBalanceLock(data: BalanceLockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(BalanceLock.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateBalanceLock(data: BalanceLockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(BalanceLock.UPDATE + data.uuid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteBalanceLock(data: BalanceLockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(BalanceLock.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
