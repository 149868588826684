import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import { useCounterOfflineContext } from '@/delivery/ui/admin/transaction/take_gold/counter_offline/context/counter_offline_context.tsx';
import { OfflineCounterUnavailabilityModel } from '@/domain/model/offline_counter_unavailability_model.ts';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';

const UnavailabilityList = () => {
  const { handleDeleteCounterUnavailability, availabilities } = useCounterOfflineContext();

  return (
    <div>
      {availabilities.length > 0 ? (
        <Table className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Start</Th>
              <Th>End</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {availabilities?.map((item: OfflineCounterUnavailabilityModel, index) => (
              <Tr key={index}>
                <Td>
                  {item.getStartDate} {item.getStartHour}
                </Td>
                <Td>
                  {item.getEndDate} {item.getEndHour}
                </Td>
                <Td>
                  <Button iconButton iconType={'delete'} onClick={() => handleDeleteCounterUnavailability(item)} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Typography style={'italic'} size={12}>
          offline counter tersedia
        </Typography>
      )}
    </div>
  );
};

export default UnavailabilityList;
