import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import { useManualTransactionContext } from '@/delivery/ui/admin/transaction/manual_transaction/context/manual_transaction_context.tsx';
import { useEffect, useState } from 'react';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { TypeManualTransactionList } from '@/domain/constant/transaction/type_transaction_list.ts';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';
import { TYPE_VENDOR } from '@/domain/constant/vendor/vendor_type.ts';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { Table, Tbody, TBox, Td, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';

const BuyTransaction = () => {
  const { transactionVendor, vendors, handleFetchVendor, handleShowQueryTransactionVendorBuy } =
    useManualTransactionContext();

  const [trxType, setTrxType] = useState('');
  const [vendor, setVendor] = useState('');
  const [idBuyTrx, setIdBuyTrx] = useState('');

  useEffect(() => {
    if (trxType === TYPE_TRANSACTION.BUY) {
      handleFetchVendor({ type: TYPE_VENDOR.PAYMENT_CHANNEL });
    }

    if (trxType === TYPE_TRANSACTION.SELL) {
      handleFetchVendor({ type: TYPE_VENDOR.WITHDRAW_CHANNEL });
    }
  }, [trxType]);

  return (
    <div>
      <Theader>
        <Typography size={14} fontWeight={600}>
          Search Transaction
        </Typography>
      </Theader>
      <TBox>
        <div className={'flex lg:flex-row flex-col gap-[10px] items-end'}>
          <div className={'w-full'}>
            <Select2
              isSearchable={false}
              options={TypeManualTransactionList.map(item => {
                return {
                  label: item.name,
                  value: item.UUID
                };
              })}
              label="Type Transaksi"
              block
              placeholder="pilih type"
              onChange={e => setTrxType(e.value)}
            />
          </div>
          <div className={'w-full'}>
            <Select2
              disabled={!trxType}
              label={'Vendor'}
              options={vendors.map(item => {
                return {
                  value: item.UID,
                  label: item.name
                };
              })}
              onChange={e => {
                setVendor(e.value);
              }}
            />
          </div>
          <TextField
            label={'Reference ID'}
            block
            placeholder="Reference ID"
            onChange={e => setIdBuyTrx(e.target.value)}
          />
          <div className="w-1/2">
            <Button
              block
              label="Cari Data"
              onClick={() => handleShowQueryTransactionVendorBuy({ vendor_uid: vendor, reference_id: idBuyTrx })}
            />
          </div>
        </div>
      </TBox>

      {transactionVendor && (
        <div className={'mt-[30px]'}>
          <Theader>
            <Typography size={14} fontWeight={600}>
              Data Transaksi Dinaran
            </Typography>
          </Theader>
          <TBox>
            <div className={'flex gap-[20px]'}>
              <div className="w-full">
                <Theader>
                  <Typography size={14} fontWeight={600}>
                    Data Transaksi Vendor
                  </Typography>
                </Theader>
                <Table noBorder className="rounded-b-[8px]">
                  <Tbody>
                    <Tr>
                      <Td noBorder>
                        <Typography size={14} fontWeight={500}>
                          Vendor ID
                        </Typography>
                      </Td>
                      <Td noBorder>
                        <Typography size={14} fontWeight={400}>
                          {transactionVendor?.vendorRefID}
                        </Typography>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td noBorder>
                        <Typography size={14} fontWeight={500}>
                          Ref ID
                        </Typography>
                      </Td>
                      <Td noBorder>
                        <Typography size={14} fontWeight={400}>
                          {transactionVendor?.referenceID}
                        </Typography>
                      </Td>
                    </Tr>
                    {/*<Tr>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={500}>*/}
                    {/*      Buyer Name*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={400}>*/}
                    {/*      xx*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*</Tr>*/}
                    {/*<Tr>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={500}>*/}
                    {/*      Buyer Email*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={400}>*/}
                    {/*      xx*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*</Tr>*/}
                    <Tr>
                      <Td noBorder>
                        <Typography size={14} fontWeight={500}>
                          Amount
                        </Typography>
                      </Td>
                      <Td noBorder>
                        <Typography size={14} fontWeight={400}>
                          {transactionVendor?.getTotalRupiah}
                        </Typography>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td noBorder>
                        <Typography size={14} fontWeight={500}>
                          Fee
                        </Typography>
                      </Td>
                      <Td noBorder>
                        <Typography size={14} fontWeight={400}>
                          {transactionVendor?.getVendorFeeRupiah}
                        </Typography>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td noBorder>
                        <Typography size={14} fontWeight={500}>
                          Status
                        </Typography>
                      </Td>
                      <Td noBorder>
                        <Typography size={14} fontWeight={400}>
                          <Chips label={transactionVendor?.statusInVendor} />
                        </Typography>
                      </Td>
                    </Tr>
                    {/*<Tr>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={500}>*/}
                    {/*      Status Desc*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={400}>*/}
                    {/*      xx*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*</Tr>*/}
                  </Tbody>
                </Table>
              </div>

              <div className="w-full">
                <Theader>
                  <Typography size={14} fontWeight={600}>
                    Detail Transaksi
                  </Typography>
                </Theader>
                <Table noBorder className="rounded-b-[8px]">
                  <Tbody>
                    <Tr>
                      <Td noBorder>
                        <Typography size={14} fontWeight={500}>
                          Payment Channel
                        </Typography>
                      </Td>
                      <Td noBorder>
                        <Typography size={14} fontWeight={400}>
                          {transactionVendor?.paymentChannel}
                        </Typography>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td noBorder>
                        <Typography size={14} fontWeight={500}>
                          Payment Code
                        </Typography>
                      </Td>
                      <Td noBorder>
                        <Typography size={14} fontWeight={400}>
                          {transactionVendor?.paymentCode}
                        </Typography>
                      </Td>
                    </Tr>
                    {/*<Tr>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={500}>*/}
                    {/*      Sender*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={400}>*/}
                    {/*      xx*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*</Tr>*/}
                    {/*<Tr>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={500}>*/}
                    {/*      Receiver*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={400}>*/}
                    {/*      xx*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*</Tr>*/}
                    {/*<Tr>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={500}>*/}
                    {/*      Type*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={400}>*/}
                    {/*      xx*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*</Tr>*/}
                    {/*<Tr>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={500}>*/}
                    {/*      Type Desc*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={400}>*/}
                    {/*      xx*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*</Tr>*/}
                    {/*<Tr>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={500}>*/}
                    {/*      Notes*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={400}>*/}
                    {/*      xx*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*</Tr>*/}
                    {/*<Tr>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={500}>*/}
                    {/*      Success Date*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={400}>*/}
                    {/*      xx*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*</Tr>*/}
                    {/*<Tr>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={500}>*/}
                    {/*      Sattlement Date*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*  <Td noBorder>*/}
                    {/*    <Typography size={14} fontWeight={400}>*/}
                    {/*      xx*/}
                    {/*    </Typography>*/}
                    {/*  </Td>*/}
                    {/*</Tr>*/}
                    <Tr>
                      <Td noBorder>
                        <Typography size={14} fontWeight={500}>
                          Create Date
                        </Typography>
                      </Td>
                      <Td noBorder>
                        <Typography size={14} fontWeight={400}>
                          {transactionVendor?.createdDate}
                        </Typography>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td noBorder>
                        <Typography size={14} fontWeight={500}>
                          Expired Date
                        </Typography>
                      </Td>
                      <Td noBorder>
                        <Typography size={14} fontWeight={400}>
                          {transactionVendor?.expiredDate}
                        </Typography>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </div>
          </TBox>
        </div>
      )}
    </div>
  );
};

export default BuyTransaction;
