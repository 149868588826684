export const getFirstAndLastDateOfCurrentMonth = (): { firstDate: string; lastDate: string } => {
  const now = new Date();

  // Get the current year and month
  const year = now.getFullYear();
  const month = now.getMonth();

  // Create Date objects for the 1st and last day of the current month
  const firstDate = new Date(year, month, 1);
  const lastDate = new Date(year, month + 1, 0); // Setting day to 0 gets the last day of the previous month (i.e., this month)

  // Helper function to format date as YYYY-MM-DD
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return {
    firstDate: formatDate(firstDate),
    lastDate: formatDate(lastDate)
  };
};

// Example usage:
// const { firstDate, lastDate } = getFirstAndLastDateOfCurrentMonth();
// console.log(firstDate); // Output: 2024-09-01 (example for September 2024)
// console.log(lastDate);  // Output: 2024-09-30 (example for September 2024)
