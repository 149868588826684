import { getAccess } from '@/infrastructure/helper/auth.ts';

const accessMenuCheck = (accessRole: string[]): boolean => {
  const accessController = getAccess();

  const accessParse = JSON.parse(accessController);

  const hasAccess = accessRole?.some(item => accessParse.includes(item));

  return hasAccess;
};

export default accessMenuCheck;
