import { useEffect } from 'react';
import { BsFilter } from 'react-icons/bs';
import { FiDownloadCloud } from 'react-icons/fi';

import Button from '@/delivery/components/atoms/button/button';
import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card';
import { Table, Tbody, Td, Th, Thead, Theader, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Chips from '@/delivery/components/atoms/chips/chips';
import Pagination from '@/delivery/components/atoms/pagination/pagination';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useCorporateContext } from '@/delivery/ui/admin/data_corporate/corporate_list/context/corporate_list_context.tsx';
import { CorporateModel } from '@/domain/model/corporate_model.ts';

const CorporateList = () => {
  const { handleFetchCorporate, corporates, corporatePagination } = useCorporateContext();

  useEffect(() => {
    handleFetchCorporate({});
  }, []);

  return (
    <div>
      <div className="flex gap-[24px]">
        <SimpleCard title="Total User Register" bigTitle="307.365" />
        <SimpleCard title="Total Corporate Verified" bigTitle="55.642" />
        <SimpleCard title="Total Corporate Active" bigTitle="79.041" />
      </div>
      <div className="mt-[32px]">
        <Theader>
          <TextField search placeholder="Search" />
          <div className="flex gap-[12px]">
            <Button label="More Filter" type="secondary" prefixIcon={<BsFilter />} />
            <Button label="Export by Filter" type="secondary" prefixIcon={<FiDownloadCloud />} />
          </div>
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Member</Th>
              <Th>Balance (gr)</Th>
              <Th>Lock Bal (gr)</Th>
              <Th>Reg Date</Th>
              <Th>Email Verified</Th>
              <Th>Updated By </Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {corporates.map((item: CorporateModel, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    PT Harapan Sentosa Indonesia
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    ptharapansentosaindonesia@gmail.com
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    08123456789
                  </Typography>
                </Td>
                <Td>
                  <div className="text-right">
                    <Typography fontWeight={600} size={12} type="primary-invert">
                      145,18
                    </Typography>
                    <Typography fontWeight={400} size={10} type="tertiary-invert">
                      145.17599680325
                    </Typography>
                  </div>
                </Td>
                <Td>
                  <div className="text-right text-[12px] font-[400] text-text-tertiary-invert">
                    <p>
                      Emas Impian:{' '}
                      <Typography tag="span" fontWeight={600} size={12} type="secondary-invert">
                        12.00
                      </Typography>{' '}
                      gr
                    </p>
                    <p>
                      Dinaran:{' '}
                      <Typography tag="span" fontWeight={600} size={12} type="secondary-invert">
                        0.00
                      </Typography>{' '}
                      gr
                    </p>
                    <p>
                      User:{' '}
                      <Typography tag="span" fontWeight={600} size={12} type="secondary-invert">
                        2.00
                      </Typography>{' '}
                      gr
                    </p>
                  </div>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    2023-05-20 20:02:46
                  </Typography>
                </Td>
                <Td>
                  <Chips type="danger-light" label="Verified" className="leading-4" />
                  <Typography className="leading-4" fontWeight={400} size={10} type="tertiary-invert">
                    2023-05-20 20:02:46
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    System
                  </Typography>
                </Td>
                <Td>
                  <div className="flex gap-[4px]">
                    <Button iconButton iconType={'lock'} />
                    <Button iconButton iconType={'view'} />
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            handlePagination={page => {
              handleFetchCorporate({
                page: page
              });
            }}
            page={corporatePagination?.page || 1}
            totalPages={corporatePagination?.totalPages || 1}
          />
        </Tfooter>
      </div>
    </div>
  );
};

export default CorporateList;
