import useAlertToast from '@/delivery/hooks/useAlertComponent';
import React, { createContext, useContext, ReactNode, useState } from 'react';
import { UserStatisticRepository } from '@/domain/repository/user_statistic_repository.ts';
import { UserStatisticModel } from '@/domain/model/user_statistic_model.ts';

interface MemberListCountContextContextProps {
  children: ReactNode;
}

interface MemberListCountContextValue {
  usersNumberTotalActive: number;
  loadingUserActive: boolean;

  loadingUserVerified: boolean;
  usersNumberTotalVerified: number;

  loadingUserRegister: boolean;
  usersNumberTotalRegister: number;

  handleFetchUserAsNumberTotalRegister: () => void;
  handleFetchUserAsNumberTotalVerified: () => void;
  handleFetchUserAsNumberTotalTransaction: () => void;
}

const MemberListCountContext = createContext<MemberListCountContextValue | null>(null);

const userStatistic = new UserStatisticRepository();

const MemberListCountProvider: React.FC<MemberListCountContextContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  //get count number total register
  const [usersNumberTotalRegister, setUsersNumberTotalRegister] = useState<number>(0);
  const [loadingUserRegister, setLoadingUserRegister] = useState<boolean>(false);

  //get count number total verified
  const [usersNumberTotalVerified, setUsersNumberTotalVerified] = useState<number>(0);
  const [loadingUserVerified, setLoadingUserVerified] = useState<boolean>(false);

  //get count user active
  const [usersNumberTotalActive, setUsersNumberTotalActive] = useState<number>(0);
  const [loadingUserActive, setLoadingUserActive] = useState<boolean>(false);

  //FETCH USER / MEMBER LIST as NUMBER
  const handleFetchUserAsNumberTotalRegister = () => {
    setLoadingUserRegister(true);

    userStatistic
      .countUserStatus()
      .then((result: any) => {
        const res: UserStatisticModel = result;
        setLoadingUserRegister(false);

        setUsersNumberTotalRegister(res.active + res.inactive);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //FETCH USER / MEMBER LIST as NUMBER USER VERIFIED
  const handleFetchUserAsNumberTotalVerified = () => {
    setLoadingUserVerified(true);

    userStatistic
      .countUserVerification()
      .then((result: any) => {
        const res: UserStatisticModel = result;
        setUsersNumberTotalVerified(res.verified);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      })
      .finally(() => {
        setLoadingUserVerified(false);
      });
  };

  //FETCH USER NUMBER ACTIVE
  const handleFetchUserAsNumberTotalTransaction = () => {
    setLoadingUserActive(true);

    userStatistic
      .countUserTransaction()
      .then((result: any) => {
        const res: UserStatisticModel = result;
        setLoadingUserActive(false);
        setUsersNumberTotalActive(res.userTransaction);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      })
      .finally(() => {
        setLoadingUserActive(false);
      });
  };

  const contextValue: MemberListCountContextValue = {
    usersNumberTotalActive,
    loadingUserActive,
    loadingUserVerified,
    usersNumberTotalVerified,
    loadingUserRegister,
    usersNumberTotalRegister,

    handleFetchUserAsNumberTotalRegister,
    handleFetchUserAsNumberTotalVerified,
    handleFetchUserAsNumberTotalTransaction
  };

  return <MemberListCountContext.Provider value={contextValue}>{children}</MemberListCountContext.Provider>;
};

const useMemberListCountContext = (): MemberListCountContextValue => {
  const context = useContext(MemberListCountContext);
  if (!context) {
    throw new Error('useMemberListCountContext must be used within a MemberListCountProvider');
  }
  return context;
};

export { MemberListCountProvider, useMemberListCountContext };
