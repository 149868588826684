import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import { ModalDialogFooter } from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';

const LockByDinaran = () => {
  const { formLockMember, setFormLockMember, handleStoreLockMemberBalance, handleDialogLockMemberBalance } =
    useMemberListContext();

  return (
    <div className={'flex flex-col gap-[20px]'}>
      <TextField
        type={'number'}
        block
        label={'Lock Balance by Dinaran (gr)'}
        placeholder={'gram'}
        onChange={(e: any) => setFormLockMember({ ...formLockMember, amount: parseFloat(e.target.value) })}
      />

      <Datepicker
        block
        label={'End Date'}
        value={formLockMember.release_date}
        onChange={e => setFormLockMember({ ...formLockMember, release_date: e })}
      />

      <ModalDialogFooter className="flex gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleDialogLockMemberBalance.setState(false)} />
        <Button block label="Confirm" onClick={() => handleStoreLockMemberBalance()} />
      </ModalDialogFooter>
    </div>
  );
};

export default LockByDinaran;
