import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { FiUnlock } from 'react-icons/fi';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useBalanceContext } from '@/delivery/ui/admin/data_member/member_list/context/balance_context.tsx';

const TableLockGoldByUser = () => {
  const { handleShowBalanceLock, balance } = useBalanceContext();
  return (
    <div>
      <Theader>
        <div>
          <Typography size={14} fontWeight={600}>
            Lock Emas by User
          </Typography>
        </div>
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Total Emas (gr)</Th>
            <Th>Total Rupiah (Rp)</Th>
            <Th>Create Date</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {balance?.balanceMemberLock.amount ? (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {balance?.balanceMemberLock.amount}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {balance?.balanceMemberLock.getFiatRupiah}
                </Typography>
              </Td>

              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {balance?.balanceMemberLock.getCreatedAt}
                </Typography>
              </Td>

              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  <Button
                    iconButton
                    icon={<FiUnlock />}
                    onClick={() => handleShowBalanceLock(balance?.balanceMemberLock)}
                  />
                </Typography>
              </Td>
            </Tr>
          ) : (
            <Tr>
              <Td colSpan={5}>
                <Typography size={12} style={'italic'}>
                  belum memiliki lock emas by user
                </Typography>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
};

export default TableLockGoldByUser;
