import { Navigate } from 'react-router-dom';
import { ComponentType } from 'react';
import { Routing } from '@/domain/constant/router_path.ts';
import { getToken } from '@/infrastructure/helper/auth.ts';

const AuthMiddleware = (Component: ComponentType<any>) => {
  const token = getToken();

  if (!token) {
    return <Navigate to={Routing.LOGIN} />;
  }

  return <Component />;
};

export default AuthMiddleware;
