import { useEffect } from 'react';
import FormatEmailPage from '@/delivery/ui/admin/settings/format_email';

const ViewFormatEmail = () => {
  useEffect(() => {
    document.title = 'Format Email';
  }, []);

  return (
    <div>
      <FormatEmailPage />
    </div>
  );
};

export default ViewFormatEmail;
