import { API_BASE_URL } from '@/domain/constant/setting';
import { getToken } from '@/infrastructure/helper/auth';
import axios from 'axios';
import responseErrorApiCall from '@/infrastructure/helper/responseErrorApi';
// import decryptResponse from '@/infrastructure/helper/encryption/decryptResponse.ts';

export interface ConfigParams {
  responseType?: string;
  contentType?: string;
  authorization?: string;
  activeUrl?: string | null;
  customHeaders?: Record<string, string>;
  removeHeader?: string[];
  signature?: string;
}

type AxiosParams = {
  config?: ConfigParams;
};

function AxiosClient(props?: AxiosParams) {
  const dt = new Date();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneOffset = dt.getTimezoneOffset();

  const axiosClient = axios.create({
    baseURL: `${props?.config?.activeUrl ? props.config.activeUrl : API_BASE_URL}`,
    headers: {
      Signature: `${props?.config?.signature}` ?? '',
      'Accept-Encryption': false,
      Authorization: `${props?.config?.authorization ?? getToken()}`,
      'Content-Type': props?.config?.contentType ?? 'application/json',
      // http2: true
      TimeZone: timezone,
      TimezoneOffset: timezoneOffset.toString()
    },
    timeout: 0
  });

  // let defaultHeader: Record<string, string> = {
  //   'Accept-Encryption': true,
  //   Authorization: `${props?.config?.authorization ?? getToken()}`,
  //   'Content-Type': props?.config?.contentType ?? 'application/json',
  //   // Signature: props?.config?.signature ?? ''
  //   // http2: true
  //   TimeZone: timezone,
  //   TimezoneOffset: timezoneOffset.toString()
  // };
  //
  // if (props?.config?.customHeaders) {
  //   defaultHeader = { ...defaultHeader, ...props.config.customHeaders };
  // }
  //
  // if (props?.config?.removeHeader) {
  //   const remHeader = props?.config?.removeHeader;
  //   remHeader.forEach(key => {
  //     delete defaultHeader[key];
  //   });
  // }

  // const axiosClient = axios.create({
  //   baseURL: `${props?.config?.activeUrl ? props.config.activeUrl : API_BASE_URL}`,
  //   headers: defaultHeader,
  //   timeout: 0
  // });

  axiosClient.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error: any) {
      if (error.code === 'ERR_NETWORK') {
        return Promise.reject('Terjadi masalah jaringan');
      }

      if (typeof error.message === 'string' && error.message.startsWith('TypeError')) {
        return Promise.reject('Terjadi kesalahan');
      }

      return responseErrorApiCall(error.response.data);
    }
  );

  return axiosClient;
}

export default AxiosClient;
