import { useEffect } from 'react';
import MicrogoldByATMPage from '@/delivery/ui/admin/deposit_gold/deposit_microgold_by_atm';

const ViewMicrogoldByATM = () => {
  useEffect(() => {
    document.title = 'Microgold by ATM';
  }, []);

  return (
    <div>
      <MicrogoldByATMPage />
    </div>
  );
};

export default ViewMicrogoldByATM;
