import { BaseModel } from '@/domain/model/base_model.ts';

export class DinaranSettingModel extends BaseModel {
  companyUUID: string;
  firstBuy: number;
  minimumBuy: number;
  maximumBuy: number;
  minimumSell: number;
  maximumSell: number;
  maximumSellDaily: number;
  minimumSellEmoney: number;
  maximumSellEmoney: number;
  firstBuyTransfer: number;
  minimumTransfer: number;
  maximumTransfer: number;
  maximumTransferDaily: number;
  dcaLimit: number;
  dcaMinimalConvert: number;
  dcaFee: number;
  coinConvert: number;
  zakatAccount: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    companyUUID: string,
    firstBuy: number,
    minimumBuy: number,
    maximumBuy: number,
    minimumSell: number,
    maximumSell: number,
    maximumSellDaily: number,
    minimumSellEmoney: number,
    maximumSellEmoney: number,
    firstBuyTransfer: number,
    minimumTransfer: number,
    maximumTransfer: number,
    maximumTransferDaily: number,
    dcaLimit: number,
    dcaMinimalConvert: number,
    dcaFee: number,
    coinConvert: number,
    zakatAccount: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.companyUUID = companyUUID;
    this.firstBuy = firstBuy;
    this.minimumBuy = minimumBuy;
    this.maximumBuy = maximumBuy;
    this.minimumSell = minimumSell;
    this.maximumSell = maximumSell;
    this.maximumSellDaily = maximumSellDaily;
    this.minimumSellEmoney = minimumSellEmoney;
    this.maximumSellEmoney = maximumSellEmoney;
    this.firstBuyTransfer = firstBuyTransfer;
    this.minimumTransfer = minimumTransfer;
    this.maximumTransfer = maximumTransfer;
    this.maximumTransferDaily = maximumTransferDaily;
    this.dcaLimit = dcaLimit;
    this.dcaMinimalConvert = dcaMinimalConvert;
    this.dcaFee = dcaFee;
    this.coinConvert = coinConvert;
    this.zakatAccount = zakatAccount;
  }

  public static fromJson(data: any): any | DinaranSettingModel {
    try {
      return new DinaranSettingModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.company_uuid,
        data.first_buy,
        data.minimum_buy,
        data.maximum_buy,
        data.minimum_sell,
        data.maximum_sell,
        data.maximum_sell_daily,
        data.minimum_sell_emoney,
        data.maximum_sell_emoney,
        data.first_buy_transfer,
        data.maximum_transfer,
        data.minimum_transfer,
        data.maximum_transfer_daily,
        data.dca_limit,
        data.dca_minimal_convert,
        data.dca_fee,
        data.coin_convert,
        data.zakat_account
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): DinaranSettingModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(DinaranSettingModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
