const MEMBER_E_MONEY_TYPE_LIST = [
  { UUID: 'E-MONEY', name: 'E-MONEY' },
  { UUID: 'BANK', name: 'BANK' }
];

export const MEMBER_E_MONEY_TYPE = {
  E_MONEY: 'E-MONEY',
  BANK: 'BANK'
};
export default MEMBER_E_MONEY_TYPE_LIST;
