import Button from '@/delivery/components/atoms/button/button';
import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card';
import { Theader, TBox } from '@/delivery/components/molecules/table/table';
import { useOverviewContext } from '@/delivery/ui/admin/dashboard/overview/context/overview_context.tsx';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';
import formatNumberWithDot from '@/infrastructure/helper/formatNumberWithDot.ts';
import ChartRegisterVerified from '@/delivery/ui/admin/dashboard/member/charts/chart_register_verified_member.tsx';
import { getFirstAndLastDateOfCurrentMonth } from '@/domain/constant/get_first_data_and_last_date.ts';
import ChartBuySellTransaction from '@/delivery/ui/admin/dashboard/transaction/chart_buy_sell_transaction.tsx';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';

const Overview = () => {
  const alertToast = useAlertToast();
  const { firstDate, lastDate } = getFirstAndLastDateOfCurrentMonth();
  const navigate = useNavigate();
  const {
    transactionChartSellData,
    transactionChartBuyData,
    handleFetchTransactionAsNumberTotalTransaction,
    loadingNumberTransaction,
    numberTotalTransaction,
    handleFetchUserAsNumberTotalRegister,
    usersNumberTotalRegister,
    loadingUserRegister,
    memberRegisterChart,
    handleRegisterDailyChart,
    transactionChartSell,
    transactionChartBuy
  } = useOverviewContext();

  const handleInitialSellBuyChart = () => {
    const x = alertToast.loadingAlert('');
    transactionChartSell({ start_date: firstDate, end_date: lastDate })
      .then(() => {
        alertToast.updateLoading(x);
        transactionChartBuy({ start_date: firstDate, end_date: lastDate })
          .then(() => {
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  useEffect(() => {
    handleFetchUserAsNumberTotalRegister();
    handleFetchTransactionAsNumberTotalTransaction();
    handleRegisterDailyChart({ start_date: firstDate, end_date: lastDate });
    handleInitialSellBuyChart();
  }, []);

  return (
    <div>
      <div className="flex w-full justify-between gap-[12px]">
        <SimpleCard
          loading={loadingUserRegister}
          title="Total Users"
          bigTitle={formatNumberWithDot(usersNumberTotalRegister)}
        >
          <Button type="secondary" label="View Report" onClick={() => navigate(Routing.DASHBOARD.MEMBER)} />
        </SimpleCard>
        <SimpleCard
          loading={loadingNumberTransaction}
          title="Total Transaction"
          bigTitle={formatNumberWithDot(numberTotalTransaction)}
        >
          <Button type="secondary" label="View Report" onClick={() => navigate(Routing.DASHBOARD.TRANSACTION)} />
        </SimpleCard>
      </div>

      {/*MEMBER*/}
      <div className="mt-[32px]">
        <Theader>
          <span className="text-[18px] font-[600]">User Register Report</span>
          <Button label="View Report" type="secondary" onClick={() => navigate(Routing.DASHBOARD.MEMBER)} />
        </Theader>
        <TBox>
          <ChartRegisterVerified data={memberRegisterChart} />
        </TBox>
      </div>

      {/*TRANSACTION*/}
      <div className="mt-[32px]">
        <Theader>
          <span className="text-[18px] font-[600]">User Transaction Report</span>
          <Button label="View Report" type="secondary" onClick={() => navigate(Routing.DASHBOARD.TRANSACTION)} />
        </Theader>
        <TBox>
          <ChartBuySellTransaction dataTrxSell={transactionChartSellData} dataTrxBuy={transactionChartBuyData} />
        </TBox>
      </div>
      {/*<div className="mt-[32px]">*/}
      {/*  <BoxTitle title="User Ranking Report">*/}
      {/*    <div className="flex justify-between gap-[24px]">*/}
      {/*      <div className="w-full">*/}
      {/*        <Theader>*/}
      {/*          <span className="text-[18px] font-[600]">Top 10 High Profit</span>*/}
      {/*        </Theader>*/}
      {/*        <Table noBorder className="rounded-b-[8px]">*/}
      {/*          <Thead>*/}
      {/*            <Tr>*/}
      {/*              <Th noBorder>#</Th>*/}
      {/*              <Th noBorder>Member</Th>*/}
      {/*              <Th noBorder>Profit</Th>*/}
      {/*            </Tr>*/}
      {/*          </Thead>*/}
      {/*          <Tbody>*/}
      {/*            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (*/}
      {/*              <Tr>*/}
      {/*                <Td noBorder>{index + 1}</Td>*/}
      {/*                <Td noBorder>*/}
      {/*                  <p className="text-[14px] font-[500]">Mardigu Wowiek</p>*/}
      {/*                  <p className="text-[11px] font-[400]">08123456 - denyekayana@gmail.com</p>*/}
      {/*                </Td>*/}
      {/*                <Td noBorder>*/}
      {/*                  <div className="flex items-center gap-[2px]">*/}
      {/*                    <span className="text-[16px]">*/}
      {/*                      <FiArrowUp />*/}
      {/*                    </span>*/}
      {/*                    <span className="text-[14px] font-[400]">39.289.000</span>*/}
      {/*                  </div>*/}
      {/*                </Td>*/}
      {/*              </Tr>*/}
      {/*            ))}*/}
      {/*          </Tbody>*/}
      {/*        </Table>*/}
      {/*      </div>*/}
      {/*      <div className="w-full">*/}
      {/*        <Theader>*/}
      {/*          <span className="text-[18px] font-[600]">Top 10 High Balance</span>*/}
      {/*        </Theader>*/}
      {/*        <Table noBorder>*/}
      {/*          <Thead>*/}
      {/*            <Tr>*/}
      {/*              <Th noBorder>#</Th>*/}
      {/*              <Th noBorder>Member</Th>*/}
      {/*              <Th noBorder>Profit</Th>*/}
      {/*            </Tr>*/}
      {/*          </Thead>*/}
      {/*          <Tbody>*/}
      {/*            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (*/}
      {/*              <Tr>*/}
      {/*                <Td noBorder>{index + 1}</Td>*/}
      {/*                <Td noBorder>*/}
      {/*                  <p className="text-[14px] font-[500]">Mardigu Wowiek</p>*/}
      {/*                  <p className="text-[11px] font-[400]">08123456 - denyekayana@gmail.com</p>*/}
      {/*                </Td>*/}
      {/*                <Td noBorder>*/}
      {/*                  <div className="flex items-center gap-[2px]">*/}
      {/*                    <span className="text-[16px]">*/}
      {/*                      <FiArrowUp />*/}
      {/*                    </span>*/}
      {/*                    <span className="text-[14px] font-[400]">39.289.000</span>*/}
      {/*                  </div>*/}
      {/*                </Td>*/}
      {/*              </Tr>*/}
      {/*            ))}*/}
      {/*          </Tbody>*/}
      {/*        </Table>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </BoxTitle>*/}
      {/*</div>*/}
    </div>
  );
};

export default Overview;
