export const Token = {
  TOKEN: '_Ms0303',
  REFRESH_TOKEN: 'ref_Ms0303'
};

export default class AResponse {
  static SESSION_COOKIE = 'AKS1020182023';
  static ACCESS_COOKIE = 'AKS1120182024';
  // static ACCESS_CONTROLLER = 'AKS1120182023';
  // static RT_COOKIE = 'RTAKS1020182023';
  // static CRYP_TOKEN = 'CRYPAKS1020182023';
}
