import Button from '@/delivery/components/atoms/button/button';
import { TBox, Theader } from '@/delivery/components/molecules/table/table';

import ChartRegisterVerified from './charts/chart_register_verified_member.tsx';
import { useDashboardMemberContext } from '@/delivery/ui/admin/dashboard/member/context/dashbord_member_context.tsx';
import { useEffect, useState } from 'react';
import { TabContent, TabPane3, TabPaneList3 } from '@/delivery/components/atoms/tab_pane/tab_pane.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import SummaryMember from '@/delivery/ui/admin/dashboard/member/summary_member.tsx';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import ChartRegisterVerifiedMonthly from '@/delivery/ui/admin/dashboard/member/charts/chart_register_verified_member_monthly.tsx';
import { usePDF } from 'react-to-pdf';
import { getFirstAndLastDateOfCurrentMonth } from '@/domain/constant/get_first_data_and_last_date.ts';
import Icon from '@/domain/constant/icon.tsx';

const Member = () => {
  const { firstDate, lastDate } = getFirstAndLastDateOfCurrentMonth();
  const { toPDF, targetRef } = usePDF({ filename: `grafik-user-register-${formatDateParam(new Date())}.pdf` });
  const alertToast = useAlertToast();

  const {
    memberRegisterChartMonthly,
    memberRegisterChart,
    handleFetchUserAsNumberTotalRegister,
    handleFetchUserAsNumberVerifiedUser,
    handleFetchUserAsNumberTransactionUser,
    // handleRegisterDailyChart,
    formDashboardMember,
    setFormDashboardMember,
    handleRegisterDailyChart,
    handleRegisterMonthlyChart
  } = useDashboardMemberContext();

  useEffect(() => {
    handleFetchUserAsNumberTransactionUser();
    handleFetchUserAsNumberTotalRegister();
    handleFetchUserAsNumberVerifiedUser();
  }, []);

  const [initialChart, setInitialChart] = useState(false);

  const [tabPage, setTabPage] = useState(0);

  const data = [
    {
      label: 'Daily',
      index: 0
      // handleClick: () => {}
    },
    {
      label: 'Monthly',
      index: 1
      // handleClick: () => {}
    }
  ];

  const handleApplyFilter = () => {
    const x = alertToast.loadingAlert();

    handleRegisterDailyChart()
      .then(() => {
        handleRegisterMonthlyChart()
          .then(() => {
            alertToast.updateLoading(x);
          })
          .then(() => {
            alertToast.updateLoading(x);
          });
      })
      .then(() => {
        alertToast.updateLoading(x);
      });
  };

  //INITIAL
  useEffect(() => {
    if (firstDate) {
      setFormDashboardMember({
        ...formDashboardMember,
        start_date: firstDate.toString(),
        end_date: lastDate.toString()
      });
      setInitialChart(true);
    }
  }, [firstDate]);

  useEffect(() => {
    if (initialChart) {
      setTimeout(() => {
        handleApplyFilter();
      }, 2000);
      setInitialChart(false);
    }
  }, [initialChart]);

  return (
    <div>
      <SummaryMember />
      <div className="mt-[32px]">
        <Theader>
          <span className="text-[18px] font-[600]">Grafik Member Register</span>
          <div className={'flex justify-between gap-[10px]'}>
            <ButtonFilter
              onClickApply={() => handleApplyFilter()}
              size={'small'}
              titleFilter={'Filter Grafik User Register'}
              label={'More Filter'}
            >
              <div className={'flex gap-[20px]'}>
                <Datepicker
                  label={'Start Date'}
                  placeholder={'Start Date'}
                  value={formDashboardMember.start_date}
                  block
                  onChange={e =>
                    setFormDashboardMember({
                      ...formDashboardMember,
                      start_date: formatDateParam(e)
                    })
                  }
                />
                <Datepicker
                  label={'End Date'}
                  value={formDashboardMember.end_date}
                  placeholder={'End Date'}
                  block
                  onChange={e =>
                    setFormDashboardMember({
                      ...formDashboardMember,
                      end_date: formatDateParam(e)
                    })
                  }
                />
              </div>
            </ButtonFilter>
            <Button prefixIcon={Icon.document} label="Export PDF" type="secondary" onClick={() => toPDF()} />
          </div>
        </Theader>
        <TBox>
          <div className={'mb-[20px] flex justify-between items-center'}>
            <TabPane3>
              {data?.map((item, index) => (
                <TabPaneList3 label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
              ))}
            </TabPane3>
          </div>

          <div ref={targetRef}>
            <TabContent index={0} tab={tabPage}>
              <ChartRegisterVerified data={memberRegisterChart} />
            </TabContent>
            <TabContent index={1} tab={tabPage}>
              <ChartRegisterVerifiedMonthly data={memberRegisterChartMonthly} />
            </TabContent>
          </div>
        </TBox>
      </div>
    </div>
  );
};

export default Member;
