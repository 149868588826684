import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';

import Button from '@/delivery/components/atoms/button/button.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useUserContext } from '@/delivery/ui/admin/settings/admin_access/context/user_context.tsx';
import { useEffect } from 'react';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';

const DialogUpdateUserAdmin = (props: ModalDialogProps) => {
  const { roles, handleUpdateUserModal, handleUpdateUser, formUser, setFormUser, handleFetchRole } = useUserContext();

  useEffect(() => {
    handleFetchRole({});
  }, []);

  return (
    <ModalDialog overflow={false} size="medium" title="Add User" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <div className={'grid grid-cols-2 gap-[20px] justify-between'}>
          <TextField
            label={'Nama Depan'}
            value={formUser?.first_name}
            block
            onChange={e => setFormUser({ ...formUser, first_name: e.target.value })}
          />
          <TextField
            label={'Nama Belakang'}
            value={formUser?.last_name}
            block
            onChange={e => setFormUser({ ...formUser, last_name: e.target.value })}
          />
          <TextField
            label={'Username'}
            value={formUser?.username}
            block
            onChange={e => setFormUser({ ...formUser, username: e.target.value })}
          />
          {/*<TextField*/}
          {/*  label={'Password'}*/}
          {/*  value={formUser?.password}*/}
          {/*  block*/}
          {/*  onChange={e => setFormUser({ ...formUser, password: e.target.value })}*/}
          {/*/>*/}
        </div>
        <TextField
          type={'email'}
          label={'Email'}
          value={formUser?.email}
          block
          onChange={e => setFormUser({ ...formUser, email: e.target.value })}
        />
        <TextField
          type={'number'}
          label={'Phone'}
          value={formUser?.phone}
          block
          onChange={e => setFormUser({ ...formUser, phone: e.target.value })}
        />
        <Select2
          label={'Role'}
          value={formUser?.role_uuid}
          options={roles.map(item => {
            return {
              value: item.UUID,
              label: item.name
            };
          })}
          block
          onChange={e => setFormUser({ ...formUser, role_uuid: e.value })}
        />
      </div>
      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleUpdateUserModal.setState(false)} />
        <Button label="Save" onClick={() => handleUpdateUser()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogUpdateUserAdmin;
