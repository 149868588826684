import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Typography from '@/delivery/components/atoms/typography/typography';
import { Table, Tbody, Td, Th, Thead, Theader, Tr, Tfooter } from '@/delivery/components/molecules/table/table';

const CustomVariable = () => {
  return (
    <div>
      <Theader>
        <TextField search placeholder="search" />
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Key</Th>
            <Th>Subject</Th>
            <Th>Template</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {[1, 2, 3, 4, 5, 6].map((item, index) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  send_email_penutupan
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  Penutupan akun
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  Gedung STC Senayan, Lantai 3 No. 178B. Jl. Asia Afrika Pintu IX, Gelora Senayan, Jakarta Pusat - 10270
                </Typography>
              </Td>
              <Td>
                <div className="flex gap-[4px]">
                  <Button iconButton iconType={'delete'} />
                  <Button iconButton iconType={'edit'} />
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter></Tfooter>
    </div>
  );
};

export default CustomVariable;
