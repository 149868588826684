// import Button from '@/delivery/components/atoms/button/button';
// import Chips from '@/delivery/components/atoms/chips/chips';
// import HorizontalForm from '@/delivery/components/atoms/layout/horizontal_form/horizontal_form';
import Radio from '@/delivery/components/atoms/radio/radio';
import Select from '@/delivery/components/atoms/select/select';
import { TabContent, TabPane, TabPaneList } from '@/delivery/components/atoms/tab_pane/tab_pane';
// import TextField from '@/delivery/components/atoms/text_field/text_field';
// import {
//   ModalDialog,
//   ModalDialogContent,
//   ModalDialogFooter
// } from '@/delivery/components/molecules/modal_dialog/modal_dialog';
// import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card';
// import SideBar from '@/delivery/components/organisms/sidebar/sidebar';
import { useState } from 'react';
// import { FaArrowLeft } from 'react-icons/fa';

const Draft = () => {
  const [tabPage, setTabPage] = useState(0);
  const data = [
    {
      label: 'App',
      index: 0
    },
    {
      label: 'Message',
      index: 1
    },
    {
      label: 'Line',
      index: 1
    }
  ];

  const dataSelect = [
    {
      UUID: '123abc',
      name: 'deny'
    },
    {
      UUID: '12fabc',
      name: 'wijaya'
    }
  ];

  return (
    <div className="">
      this is login{' '}
      <div className="mt-4 ml-4">
        <TabPane>
          {data?.map((item, index) => (
            <TabPaneList label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
          ))}
        </TabPane>
        <TabContent index={0} tab={tabPage}>
          <p>app</p>
        </TabContent>
        <TabContent index={1} tab={tabPage}>
          <p>message</p>
        </TabContent>
      </div>
      <div>
        <Select items={dataSelect} placeholder="select gender" onChange={e => console.log(e, 'ee')} />
      </div>
      <div>
        <Radio label="Verified" />
      </div>
    </div>
  );
};

export default Draft;
