import Navbar from '@/delivery/components/organisms/navbar/navbar';
import Button from '@/delivery/components/atoms/button/button';
import EventReferral from '@/delivery/ui/admin/reseller_affiliate/event_referral/event_referral.tsx';
import { useEventReferralContext } from '@/delivery/ui/admin/reseller_affiliate/event_referral/context/event_referral_context.tsx';
import DialogAddEventReferral from '@/delivery/ui/admin/reseller_affiliate/event_referral/dialog/dialog_add_event_referral.tsx';
import Icon from '@/domain/constant/icon.tsx';

const EventReferralPage = () => {
  const { handleAddEventReferralModal } = useEventReferralContext();
  return (
    <div>
      <Navbar label={'Event Referral'}>
        <Button
          prefixIcon={Icon.plus}
          label="Tambah Event"
          onClick={() => handleAddEventReferralModal.setState(true)}
        />
      </Navbar>
      <EventReferral />

      {handleAddEventReferralModal.state && <DialogAddEventReferral modal={handleAddEventReferralModal} />}
    </div>
  );
};

export default EventReferralPage;
