import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useBusinessAccountContext } from '@/delivery/ui/admin/data_member/member_list/context/business_account_context.tsx';

const DialogUpdateInfoBusiness = (props: ModalDialogProps) => {
  const { handleDialogEditInfoBusinessModal, handleUpdateCorporate, formCorporate, setFormCorporate } =
    useBusinessAccountContext();

  return (
    <ModalDialog overflow={false} size="small" title="Update Info Bisnis" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField
          value={formCorporate.name}
          label={'Nama Corporate'}
          block
          onChange={e => setFormCorporate({ ...formCorporate, name: e.target.value })}
        />
        <TextField
          value={formCorporate.email_corporate}
          label={'Email Corporate'}
          block
          onChange={e => setFormCorporate({ ...formCorporate, email_corporate: e.target.value })}
        />
        <TextField
          value={formCorporate.website}
          label={'Website'}
          block
          onChange={e => setFormCorporate({ ...formCorporate, website: e.target.value })}
        />
        <TextField
          type={'number'}
          value={formCorporate.phone_corporate}
          label={'Phone'}
          block
          onChange={e => setFormCorporate({ ...formCorporate, phone_corporate: e.target.value })}
        />

        <ModalDialogFooter className="flex gap-[20px] justify-end">
          <Button type="secondary" label="Cancel" onClick={() => handleDialogEditInfoBusinessModal.setState(false)} />
          <Button label="Save" onClick={() => handleUpdateCorporate()} />
        </ModalDialogFooter>
      </div>
    </ModalDialog>
  );
};

export default DialogUpdateInfoBusiness;
