import Line from '@/delivery/components/atoms/line/line';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useDepositByAdminContext } from '@/delivery/ui/admin/deposit_gold/deposit_by_admin/context/deposit_by_admin_context.tsx';
import TypeGramAmount from '@/domain/constant/transaction/type_gram_amount_list.ts';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import TypeGramAmountConst from '@/domain/constant/transaction/type_gram_amount.ts';
import { useEffect, useState } from 'react';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';

const DialogAddDepositGold = (props: ModalDialogProps) => {
  const {
    handleAddDepositByAdminModal,
    handleSubmitManualTransaction,
    goldRateLatest,
    handleFetchGoldRateLatest,
    handleFetchPaymentChannel,
    paymentChannels,
    setFormManualTransaction,
    formManualTransaction,
    handleShowUserListModal
  } = useDepositByAdminContext();

  const [typeGramAmount, setTypeGramAmount] = useState('amount');

  useEffect(() => {
    handleFetchGoldRateLatest();
    handleFetchPaymentChannel();
  }, []);

  return (
    <ModalDialog overflow={false} size="large" title="Add New Setor Emas" modal={props.modal}>
      <div className="flex flex-col gap-[20px] justify-between">
        <div>
          <Typography size={12} type={'tertiary-invert'}>
            Gold Buy Rate
          </Typography>
          <span className={'text-[24px] font-[500]'}>{goldRateLatest?.currentRate.getBuy}</span>
          <Typography tag={'span'} fontWeight={400} size={12} type={'tertiary-invert'}>
            /gram
          </Typography>
        </div>
        <Typography size={16}>Add Member</Typography>
        <div className={'flex gap-[5px]'}>
          <div>
            <Button label={'Search'} prefixIcon={Icon.plus} onClick={() => handleShowUserListModal.setState(true)} />
          </div>
          <div className={'grid grid-cols-2 w-full gap-[5px]'}>
            <TextField disabled placeholder="Name" block value={formManualTransaction.name} />
            <TextField disabled placeholder="Email" block value={formManualTransaction.email} />
            <TextField disabled placeholder="Phone" block value={formManualTransaction.phone} />
          </div>
        </div>

        <Line />

        <div className="grid grid-cols-2 gap-[24px]">
          <Select2
            value={typeGramAmount}
            options={TypeGramAmount.map(item => {
              return {
                label: item.name,
                value: item.UUID
              };
            })}
            label="Pilih Type (Nominal/Gramasi)"
            block
            onChange={e => setTypeGramAmount(e.value)}
          />
          {typeGramAmount === TypeGramAmountConst.AMOUNT ? (
            <TextField
              type={'currency'}
              label="Nominal (Rp)"
              block
              placeholder="Nominal"
              onChange={(e: any) =>
                setFormManualTransaction({
                  ...formManualTransaction,
                  amount: parseInt(e.value)
                })
              }
            />
          ) : (
            <TextField
              type={'number'}
              label="Jumlah Gramasi (Gr)"
              block
              placeholder="Gr"
              onChange={e => {
                setFormManualTransaction({ ...formManualTransaction, gram: parseFloat(e.target.value) });
              }}
            />
          )}
          <TextField
            type={'currency'}
            label="Fee (Rp)"
            block
            placeholder="Masukan nominal (include fee)"
            onChange={(e: any) => setFormManualTransaction({ ...formManualTransaction, fee: parseInt(e.value) })}
          />
          <Select2
            isSearchable
            options={paymentChannels.map(item => {
              return {
                label: item.code,
                value: item.UID
              };
            })}
            label="Payment Channel"
            block
            placeholder="pilih payment channel"
            onChange={e => setFormManualTransaction({ ...formManualTransaction, payment_channel_uid: e.value })}
          />
        </div>
        {/*<Select2*/}
        {/*  isSearchable*/}
        {/*  options={STATUS_TRANSACTION_LIST.map(item => {*/}
        {/*    return {*/}
        {/*      label: item.name,*/}
        {/*      value: item.UUID*/}
        {/*    };*/}
        {/*  })}*/}
        {/*  label="Status"*/}
        {/*  block*/}
        {/*  placeholder="Status"*/}
        {/*  onChange={e => setFormManualTransaction({ ...formManualTransaction, status: e.value })}*/}
        {/*/>*/}
        <Textarea
          height={100}
          label="Notes"
          block
          placeholder="Notes"
          onChange={(e: any) => setFormManualTransaction({ ...formManualTransaction, notes: e.value })}
        />
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleAddDepositByAdminModal.setState(false)} />
        <Button label="Save" onClick={() => handleSubmitManualTransaction()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddDepositGold;
