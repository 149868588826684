import Navbar from '@/delivery/components/organisms/navbar/navbar';
import ATMLocation from './atm_location';

import Button from '@/delivery/components/atoms/button/button';
import Icon from '@/domain/constant/icon.tsx';

const ATMLocationPage = () => {
  return (
    <div>
      <Navbar label={'Lokasi ATM'}>
        <Button
          prefixIcon={Icon}
          label="Add Lokasi"
          //   onClick={() => handleAddCounterOfflineModal.setState(true)}
        />
      </Navbar>
      <ATMLocation />
    </div>
  );
};

export default ATMLocationPage;
