import Datepicker from '@/delivery/components/atoms/date/date';
import Line from '@/delivery/components/atoms/line/line';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Typography from '@/delivery/components/atoms/typography/typography';
import GenderList from '@/domain/constant/gender';
import ReligionList from '@/domain/constant/religion';
import { useMemberVefiryContext } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { useEffect } from 'react';
import { Image } from '@/delivery/components/atoms/image/image.tsx';

const RequestVerify = () => {
  const {
    formMemberVerify,
    setFormMemberVerify,
    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    villageID,
    setVillageID,

    handleFetchProvince,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinces,
    cities,
    districts,
    villages
  } = useMemberVefiryContext();

  useEffect(() => {
    handleFetchProvince();
  }, []);

  //fetch cities
  useEffect(() => {
    if (provinceID !== null) {
      handleFetchCities();
    }
  }, [provinceID]);

  //fetch district
  useEffect(() => {
    if (cityID !== 0) {
      handleFetchDistricts();
    }
  }, [cityID]);

  //fetch village
  useEffect(() => {
    if (districtID > 0) {
      handleFetchVillage();
    }
  }, [districtID]);

  return (
    <div className="flex flex-col gap-[24px]">
      {/* SECTION 1 */}
      <div className="grid grid-cols-3 gap-[24px] items-center">
        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Name
          </Typography>
        </div>
        <div className={'col-span-2'}>
          <TextField disabled block placeholder="Nama Depan" value={formMemberVerify.name} />
        </div>
        {/*<div>*/}
        {/*  <TextField block placeholder="Nama Belakang" />*/}
        {/*</div>*/}

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            NIK
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField
            block
            placeholder="NIK"
            value={formMemberVerify.identity_number}
            onChange={e =>
              setFormMemberVerify({
                ...formMemberVerify,
                identity_number: e.target.value
              })
            }
          />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Tempat Taggal dan Lahir
          </Typography>
        </div>
        <div>
          <TextField
            block
            placeholder="Tempat lahir"
            value={formMemberVerify.birth_place}
            onChange={e =>
              setFormMemberVerify({
                ...formMemberVerify,
                birth_place: e.target.value
              })
            }
          />
        </div>
        <div>
          <Datepicker
            value={formMemberVerify.birth_date}
            onChange={e =>
              setFormMemberVerify({
                ...formMemberVerify,
                identity_number: e.value
              })
            }
          />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Agama
          </Typography>
        </div>
        <div className="col-span-2">
          <Select2
            value={formMemberVerify.religion}
            placeholder="agama"
            options={ReligionList.map(item => {
              return {
                value: item.UUID,
                label: item.name
              };
            })}
            onChange={e => setFormMemberVerify({ ...formMemberVerify, religion: e.value })}
          />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Gender
          </Typography>
        </div>
        <div className="col-span-2">
          <Select2
            value={formMemberVerify.gender}
            placeholder="gender"
            options={GenderList.map(item => {
              return {
                value: item.UUID,
                label: item.name
              };
            })}
            onChange={e => setFormMemberVerify({ ...formMemberVerify, gender: e.value })}
          />
        </div>
      </div>

      <Line />

      {/* SECTION 2 */}
      <div className="grid grid-cols-3 gap-[24px] items-center">
        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Provinsi
          </Typography>
        </div>
        <div className="col-span-2">
          <Select2
            value={provinceID?.toString()}
            block
            options={provinces?.map(item => {
              return {
                label: item.name,
                value: item.ID.toString()
              };
            })}
            onChange={e => {
              setProvinceID(parseInt(e.value));
            }}
          />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Kabupaten
          </Typography>
        </div>
        <div className="col-span-2">
          <Select2
            value={cityID.toString()}
            block
            options={cities?.map(item => {
              return {
                label: item.name,
                value: item.ID.toString()
              };
            })}
            onChange={e => setCityID(parseInt(e.value))}
          />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Kecamatan
          </Typography>
        </div>
        <div className="col-span-2">
          <Select2
            value={districtID.toString()}
            block
            options={districts?.map(item => {
              return {
                label: item.name,
                value: item.ID.toString()
              };
            })}
            onChange={e => setDistrictID(parseInt(e.value))}
          />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Kelurahan
          </Typography>
        </div>
        <div className="col-span-2">
          <Select2
            value={villageID.toString()}
            block
            options={villages?.map(item => {
              return {
                label: item.name,
                value: item.ID.toString()
              };
            })}
            onChange={e => setVillageID(parseInt(e.value))}
          />
        </div>
        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Alamat
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField
            block
            placeholder="Alamat"
            value={formMemberVerify.full_address}
            onChange={e =>
              setFormMemberVerify({
                ...formMemberVerify,
                full_address: e.target.value
              })
            }
          />
        </div>
        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Kode POS
          </Typography>
        </div>
        <div>
          <TextField
            type="number"
            block
            placeholder="kode POS"
            value={formMemberVerify.zip_code}
            onChange={e =>
              setFormMemberVerify({
                ...formMemberVerify,
                zip_code: e.target.value
              })
            }
          />
        </div>
      </div>

      <Line />

      {/* SECTION 3 */}
      <div className="grid grid-cols-3 gap-[24px] items-center">
        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Nama Bank
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField block placeholder="Nama Bank" />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Bank Acc. No
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField block placeholder="Bank Acc. No" />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Bank Acc. Holder
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField block placeholder="Bank Acc. Holder" />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Buku Tabungan
          </Typography>
        </div>
        <div className="col-span-2">
          <Image url={formMemberVerify.media_url} height={150} />
        </div>
        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            KTP
          </Typography>
        </div>
        <div className="col-span-2">
          <Image url={formMemberVerify.identity_media_url} height={150} />
        </div>
        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Foto Selfie
          </Typography>
        </div>
        <div className="col-span-2">
          <Image url={formMemberVerify.selfie_media_url} height={150} />
        </div>
      </div>
    </div>
  );
};

export default RequestVerify;
