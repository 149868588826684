import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { CorporateRepository } from '@/domain/repository/corporate_repository.ts';
import { CorporateModel } from '@/domain/model/corporate_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { getProfile } from '@/infrastructure/helper/auth.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { RegionProvinceModel } from '@/domain/model/region_province_model.ts';
import { RegionCityModel } from '@/domain/model/region_city_model.ts';
import { RegionDistrictModel } from '@/domain/model/region_district_model.ts';
import { RegionVillageModel } from '@/domain/model/region_village_model.ts';
import { RegionRepository } from '@/domain/repository/region_repository.ts';
import { NATIONAL_ID } from '@/domain/constant/national_id.ts';
import { uploadMediaHelper } from '@/infrastructure/helper/uploadMediaHelper.tsx';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface CorporateValueParams {
  page?: number;
  limit?: number;
  parent_id?: number;
}

interface FormCorporateInterface {
  UUID?: string;
  name?: string;
  company_uuid?: string;
  website?: string;
  company_registration_number_media_uid?: string;
  tax_payer_identification_number_media_uid?: string;
  full_address?: string;
  zip_code?: string;

  checking_account_media_uid?: string;
  company_deed_media_uid?: string;
  identity_media_uid?: string;
  email?: string;
  flags?: string;
  latitude?: string;
  longitude?: string;
  mail_address?: string;
  notes?: string;
  permit?: string;
  phone?: string;
  pic_name?: string;
  reg_date?: string;
  status?: string;
  user_uid?: string;
}

interface CorporateValue {
  formCorporate: FormCorporateInterface;
  setFormCorporate: Dispatch<SetStateAction<FormCorporateInterface>>;
  // corporate: CorporateModel | undefined;
  corporates: CorporateModel[];
  corporatePagination?: PaginationModel;
  handleAddCorporateModal: ModalObject;
  handleUpdateCorporateModal: ModalObject;
  handleFetchCorporate: (_data: CorporateValueParams) => Promise<void>;
  handleShowCorporate: (_data: FormCorporateInterface) => void;
  handleUpdateCorporate: () => void;
  handleAddCorporate: () => void;
  handleDeleteCorporate: (_data: CorporateModel) => void;

  provinces: RegionProvinceModel[];
  cities: RegionDistrictModel[];
  districts: RegionDistrictModel[];
  villages: RegionVillageModel[];
  handleFetchProvince: () => void;
  handleFetchCities: () => void;
  handleFetchDistricts: () => void;
  handleFetchVillage: () => void;

  provinceID: number;
  setProvinceID: Dispatch<SetStateAction<number>>;
  cityID: number;
  setCityID: Dispatch<SetStateAction<number>>;
  districtID: number;
  setDistrictID: Dispatch<SetStateAction<number>>;
  villageID: number;
  setVillageID: Dispatch<SetStateAction<number>>;
}

const CorporateContext = createContext<CorporateValue | null>(null);

const corporateRepository = new CorporateRepository();
const regionRepository = new RegionRepository();

const CorporateProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const profile = getProfile();

  const handleAddCorporateModal = useVisibleComponent(false);
  const handleUpdateCorporateModal = useVisibleComponent(false);

  // const [corporate, setCorporate] = useState<CorporateModel>();
  const [corporates, setCorporates] = useState<CorporateModel[]>([]);
  const [corporatePagination, setCorporatePagination] = useState<PaginationModel>();

  const [formCorporate, setFormCorporate] = useState<FormCorporateInterface>({});

  const [provinces, setProvinces] = useState<RegionProvinceModel[]>([]);
  const [cities, setCities] = useState<RegionCityModel[]>([]);
  const [districts, setDistricts] = useState<RegionDistrictModel[]>([]);
  const [villages, setVillages] = useState<RegionVillageModel[]>([]);

  const [provinceID, setProvinceID] = useState<number>(0);
  const [cityID, setCityID] = useState<number>(0);
  const [districtID, setDistrictID] = useState<number>(0);
  const [villageID, setVillageID] = useState<number>(0);

  //FETCH CORPORATE
  const handleFetchCorporate = async (_data: CorporateValueParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data?.page ?? 1,
      limit: _data?.limit ?? 10
    };

    return corporateRepository
      .fetchCorporate(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setCorporates(result.data);
        setCorporatePagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW CORPORATE
  const handleShowCorporate = (_data: FormCorporateInterface) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uuid: _data.UUID
    };

    corporateRepository
      .showCorporate(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setFormCorporate({
          UUID: result.UUID,
          name: result.name
        });
        handleUpdateCorporateModal.setState(true);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //UPDATE CORPORATE
  const handleUpdateCorporate = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: formCorporate.UUID,
      name: formCorporate.name
    };

    corporateRepository
      .updateCorporate(params)
      .then(() => {
        alertToast.updateLoading(x);
        handleFetchCorporate({ page: 1 });
        handleUpdateCorporateModal.setState(false);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //ADD CORPORATE
  const handleAddCorporate = () => {
    const x = alertToast.loadingAlert('');
    const params = {
      name: formCorporate.name,
      website: formCorporate.website,
      email: formCorporate.email,
      phone: formCorporate.phone,
      national_id: NATIONAL_ID,
      province_id: provinceID,
      city_id: cityID,
      district_id: districtID,
      village_id: villageID,
      full_address: formCorporate.full_address,
      zip_code: formCorporate.zip_code,
      user_uid: profile?.user_uuid,
      company_registration_number_media_uid: 'dinaran',
      status: STATUS_CORPORATE.REQUEST
    };

    corporateRepository
      .storeCorporate(params)
      .then((result: any) => {
        uploadMediaHelper(formCorporate.company_registration_number_media_uid, result.UUID);
        alertToast.updateLoading(x);
        alertToast.successAlert();
        handleAddCorporateModal.setState(false);
        handleFetchCorporate({ page: 1 });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //DELETE CORPORATE
  const handleDeleteCorporate = (_data: CorporateModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const params = {
          uuid: _data.UUID
        };
        corporateRepository
          .deleteCorporate(params)
          .then(() => {
            alertToast.successAlert();
            handleFetchCorporate({ page: 1 });
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE FETCH PROVINCE
  const handleFetchProvince = () => {
    regionRepository
      .fetchProvince()
      .then((result: any) => {
        setProvinces(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH CITIES
  const handleFetchCities = () => {
    const params = {
      parent_id: provinceID
    };
    regionRepository
      .fetchCities(params)
      .then((result: any) => {
        setCities(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH DISTRICT
  const handleFetchDistricts = () => {
    const params = {
      parent_id: cityID
    };
    regionRepository
      .fetchDistrict(params)
      .then((result: any) => {
        setDistricts(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH VILLAGE
  const handleFetchVillage = () => {
    const params = {
      parent_id: districtID
    };

    regionRepository
      .fetchVillage(params)
      .then((result: any) => {
        setVillages(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const contextValue: CorporateValue = {
    formCorporate,
    setFormCorporate,
    // corporate,
    corporates,
    corporatePagination,
    handleFetchCorporate,
    handleAddCorporateModal,
    handleUpdateCorporateModal,
    handleShowCorporate,
    handleUpdateCorporate,
    handleAddCorporate,
    handleDeleteCorporate,

    provinces,
    cities,
    districts,
    villages,
    handleFetchProvince,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    villageID,
    setVillageID
  };

  return <CorporateContext.Provider value={contextValue}>{children}</CorporateContext.Provider>;
};

const useCorporateContext = (): CorporateValue => {
  const context = useContext(CorporateContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a CorporateProvider');
  }
  return context;
};

export { CorporateProvider, useCorporateContext };
