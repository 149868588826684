import styles from './Date.module.css';
// import AButton from "../button/button";
import { Calendar } from 'react-date-range';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { formatDate } from '@/infrastructure/helper/formatDate';
import { CiCalendar } from 'react-icons/ci';
import clsx from 'clsx';
import Button from '@/delivery/components/atoms/button/button.tsx';

interface DateProps {
  label?: string;
  value?: string | number | string[];
  placeholder?: string;
  className?: string;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  color?: 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | 'info';
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onChange?: (item: any) => void;
  theme?: 'theme-1' | 'theme-2';
  block?: boolean;
  right?: boolean;
}

// const Datepicker = (props: DateProps) => {
//   switch (props.theme) {
//     case "theme-1":
//       return <DateTheme1 {...props} />;
//     case "theme-2":
//       return <ADateTheme2 {...props} />;
//     default:
//       return <DateTheme1 {...props} />;
//   }
// };

const Datepicker = (props: DateProps) => {
  const showModal = useVisibleComponent(false);
  const { placeholder = 'Select Date' } = props;

  return (
    <div className={`relative ${props.block ? 'w-full' : ''}`}>
      <div className="flex flex-col ">
        {props.label && (
          <div className="flex pb-1">
            <div className="text-text-secondary-invert text-[14px] text-[500] mb-[6px]">{props.label}</div>
            {props.required && <span className="text-danger text-xs">*</span>}
          </div>
        )}
        <button
          className={clsx('items-center rounded-xl', styles[props.size ?? 'medium'])}
          onClick={() => showModal.setState(true)}
          disabled={props.disabled}
        >
          <div className="flex w-full justify-between items-center gap-3 border-border-tertiary border focus:outline-border-secondary text-text-primary-invert text-[400] text-[16px] py-[12px] px-[14px] rounded-[8px] leading-3">
            <span>{props.value ? formatDate(props.value) : placeholder}</span>
            <CiCalendar className="w-5 h-5" />
          </div>
        </button>
      </div>
      {showModal.state && (
        <div className="absolute z-50 pt-2 bg-w" ref={showModal.ref}>
          <div className="flex-nowrap z-50 absolute border border-border-tertiary rounded-lg bg-text-white">
            <Calendar
              onChange={props.onChange}
              date={props.value ? new Date(props.value.toString()) : new Date()}
              color="#426B3C"
            />
            <div className={'px-[20px] py-[10px] flex justify-end'}>
              {/*<Button type={'secondary'} label={'Clear'} onClick={() => props.value === ''} />*/}
              <Button label={'Ok'} onClick={() => showModal.setState(false)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// const ADateTheme2 = (props: DateProps) => {
//   const {
//     size = "medium",
//     color = "secondary",
//     // variant = 'outline'
//   } = props;
//   return (
//     <>
//       <div className="flex-col justify-start items-start gap-1 flex w-full">
//         {props.label && (
//           <div
//             className={[
//               "textfield-" + color + "-label",
//               styles[size + "-label"],
//               "text-base font-medium tracking-wide",
//             ].join(" ")}>
//             {props.label}
//           </div>
//         )}
//         <div className="flex">
//           {/* <ASelect optionList={[{ label: "test", value: "test" }]} /> */}
//         </div>
//       </div>
//     </>
//   );
// };

export default Datepicker;
