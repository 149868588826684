import { BaseModel } from '@/domain/model/base_model.ts';
import { formatDate } from '@/infrastructure/helper/formatDate.ts';
import { IMAGE_URL } from '@/domain/constant/image_url.ts';

export class NotificationModel extends BaseModel {
  category: string;
  channels: string[];
  cta: string;
  endDate: string;
  startDate: string;
  mediaUID: string;
  message: string;
  messageHTML: string;
  meta: string;
  oneTimeNotification: boolean;
  recipientType: string;
  status: string;
  title: string;
  type: string[];

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    category: string,
    channels: string[],
    cta: string,
    endDate: string,
    startDate: string,
    mediaUID: string,
    message: string,
    messageHTML: string,
    meta: string,
    oneTimeNotification: boolean,
    recipientType: string,
    status: string,
    title: string,
    type: string[]
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.category = category;
    this.channels = channels;
    this.cta = cta;
    this.endDate = endDate;
    this.startDate = startDate;
    this.mediaUID = mediaUID;
    this.message = message;
    this.messageHTML = messageHTML;
    this.meta = meta;
    this.oneTimeNotification = oneTimeNotification;
    this.recipientType = recipientType;
    this.status = status;
    this.title = title;
    this.type = type;
  }

  get getStartDate(): string {
    if (this.startDate) {
      return formatDate(this.startDate);
    }
    return '-';
  }

  get getEndDate(): string {
    if (this.endDate) {
      return formatDate(this.endDate);
    }
    return '-';
  }

  get getType(): string {
    if (this.type) {
      return this.type.join(', ');
    }
    return '-';
  }

  get getChannel(): string {
    if (this.channels) {
      return this.channels.join(', ');
    }
    return '-';
  }

  get getMedia(): string {
    if (this.mediaUID) {
      return `${IMAGE_URL}/${this.mediaUID}`;
    }
    return '-';
  }

  public static fromJson(data: any): NotificationModel | any {
    return new NotificationModel(
      data.id,
      data.uid,
      data.uuid,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by,
      data.flags,
      data.category,
      data.channels,
      data.cta,
      data.end_date,
      data.start_date,
      data.media_uid,
      data.message,
      data.message_html,
      data.meta,
      data.one_time_notification,
      data.recipient_type,
      data.status,
      data.title,
      data.type
    );
  }

  public static fromJsonList(data: any): NotificationModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(NotificationModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
