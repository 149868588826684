interface StockSummaryInterface {
  gram: number;
  totalStock: number;
}

export class VendingMachineStockSummaryModel {
  vendingMachine: string;
  stockSummary: StockSummaryInterface[];

  constructor(vendingMachine: string, stockSummary: StockSummaryInterface[]) {
    this.vendingMachine = vendingMachine;
    this.stockSummary = stockSummary;
  }

  public static fromJson(data: any): VendingMachineStockSummaryModel | null {
    try {
      if (!data.vending_machine || !data.stock_summary) {
        throw new Error('Invalid data structure');
      }

      return new VendingMachineStockSummaryModel(
        data.vending_machine.name,
        Array.isArray(data.stock_summary)
          ? data.stock_summary.map((stockItem: any) => ({
              gram: stockItem.gold_piece?.gram || 0,
              totalStock: stockItem.total_stock || 0
            }))
          : []
      );
    } catch (e) {
      console.error('Error parsing JSON:', e);
      return null;
    }
  }

  public static fromJsonList(data: any[]): VendingMachineStockSummaryModel[] {
    if (!Array.isArray(data)) {
      console.error('Input is not an array');
      return [];
    }

    return data.reduce((list: VendingMachineStockSummaryModel[], item) => {
      const model = VendingMachineStockSummaryModel.fromJson(item);
      if (model) list.push(model);
      return list;
    }, []);
  }
}
