import AddressModel from './address_model';
import { BaseModel } from '@/domain/model/base_model.ts';

export default class CompanyAddressModel extends BaseModel {
  title: string;
  companyUUID: string;
  addressUUID: string;
  userUUID: string;
  isDefault: boolean;
  address: AddressModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    title: string,
    companyUUID: string,
    addressUUID: string,
    userUUID: string,
    isDefault: boolean,
    address: AddressModel
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.flags = flags;
    this.title = title;
    this.companyUUID = companyUUID;
    this.addressUUID = addressUUID;
    this.userUUID = userUUID;
    this.isDefault = isDefault;
    this.address = address;
  }

  public static fromJson(data: any): any | CompanyAddressModel {
    try {
      return new CompanyAddressModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.title,
        data.company_uuid,
        data.address_uuid,
        data.user_uuid,
        data.is_default,
        data.address ? AddressModel.fromJson(data.address) : null
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): CompanyAddressModel[] | any {
    try {
      const listCompanyAddressModel = [];

      for (let x = 0; x < data.length; x++) {
        listCompanyAddressModel.push(CompanyAddressModel.fromJson(data[x]));
      }

      return listCompanyAddressModel;
    } catch {
      return [];
    }
  }
}
