import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { GoldPieceRepository } from '@/domain/repository/gold_piece_repository.ts';
import { GoldPieceModel } from '@/domain/model/gold_piece_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { GoldPieceFeeRepository } from '@/domain/repository/gold_piece_fee_repository.ts';
import { GoldPieceFeeModel } from '@/domain/model/gold_piece_fee_model.ts';
import { formatDateTimeParam } from '@/infrastructure/helper/formatDate.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
interface GoldPieceContextProps {
  children: ReactNode;
}

interface GoldPieceParams {
  uid?: string;
  page?: number;
  limit?: number;
  gold_piece_uid?: string;
  name?: string;
}

interface FormGoldPieceFee {
  uid?: string;
  fee?: number;
  type?: string;
  gold_piece_uid?: string;
  additional_percent_fee?: number;
  additional_flat_fee?: number;
  effective_date_start?: string;
  effective_date_end?: string;
}

interface FormGoldPiece {
  uid?: string;
  gram?: number;
  name?: string;
  notes?: string;
  status?: string;
}

interface GoldPieceContextValue {
  formGoldPieceFee: FormGoldPieceFee;
  setFormGoldPieceFee: Dispatch<SetStateAction<FormGoldPieceFee>>;
  formGoldPiece: FormGoldPiece;
  setFormGoldPiece: Dispatch<SetStateAction<FormGoldPiece>>;
  goldPieceFees: GoldPieceFeeModel[];
  goldPieceFeePagination?: PaginationModel;
  goldPieces: GoldPieceModel[];
  goldPiecePagination?: PaginationModel;
  handleAddGoldPieceModal: ModalObject;
  handleAddGoldPieceFeeModal: ModalObject;
  handleFetchGoldPiece: (_data: GoldPieceParams) => void;
  handleShowGoldPiece: (_data: GoldPieceParams) => void;
  handleUpdateGoldPiece: () => void;
  handleFetchGoldPieceFee: (_data: GoldPieceParams) => void;
  handleStoreGoldPieceFee: () => void;
  handleStoreGoldPiece: () => void;
  handleDeleteGoldPrice: (_data: GoldPieceParams) => void;
}

const GoldPieceContext = createContext<GoldPieceContextValue | null>(null);

const goldPieceRep = new GoldPieceRepository();
const goldPieceFeeRep = new GoldPieceFeeRepository();

const GoldPieceProvider: React.FC<GoldPieceContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [goldPieces, setGoldPieces] = useState<GoldPieceModel[]>([]);
  const [goldPiecePagination, setGoldPiecePagination] = useState<PaginationModel>();

  const [goldPieceFees, setGoldPieceFees] = useState<GoldPieceFeeModel[]>([]);
  const [goldPieceFeePagination, setGoldPieceFeePagination] = useState<PaginationModel>();

  const [formGoldPiece, setFormGoldPiece] = useState<FormGoldPiece>({});
  const [formGoldPieceFee, setFormGoldPieceFee] = useState<FormGoldPieceFee>({});

  const handleAddGoldPieceModal = useVisibleComponent(false);
  const handleAddGoldPieceFeeModal = useVisibleComponent(false);

  //HANDLE FETCH GOLD PIECE
  const handleFetchGoldPiece = (_data: GoldPieceParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data?.page ?? 1,
      limit: _data?.limit ?? 10,
      name: _data.name ?? null
    };

    goldPieceRep
      .fetchGoldPiece(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setGoldPieces(result.data);
        setGoldPiecePagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW GOLD PIECE
  const handleShowGoldPiece = (_data: GoldPieceParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.uid
    };

    goldPieceRep
      .showGoldPiece(params)
      .then((result: any) => {
        const res: GoldPieceModel = result;
        setFormGoldPiece({
          uid: res.UID,
          gram: res.gram,
          status: res.status,
          name: res.name,
          notes: res.notes
        });
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE STORE GOLD PIECE
  const handleStoreGoldPiece = () => {
    if (formGoldPiece.name || formGoldPiece.gram || formGoldPiece.status) {
      const x = alertToast.loadingAlert('');

      const params = {
        name: formGoldPiece.name,
        gram: formGoldPiece.gram,
        notes: formGoldPiece.notes,
        status: formGoldPiece.status
      };

      goldPieceRep
        .storeGoldPiece(params)
        .then(() => {
          alertToast.successAlert();
          handleFetchGoldPiece({ page: 1 });
          handleAddGoldPieceModal.setState(false);
          alertToast.updateLoading(x);
        })
        .catch(error => {
          alertToast.updateLoading(x);
          alertToast.errorAlert(error);
        });
    } else {
      alertToast.warningAlertValidation();
    }
  };

  //HANDLE DELETE GOLD PRICE
  const handleDeleteGoldPrice = (_data: GoldPieceParams) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        const params = {
          uid: _data.uid
        };

        goldPieceRep
          .deleteGoldPiece(params)
          .then(() => {
            alertToast.successAlert();
            handleFetchGoldPiece({ page: 1 });
            handleAddGoldPieceModal.setState(false);
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //SUBMIT UPDATE GOLD PIECE
  const handleUpdateGoldPiece = () => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: formGoldPiece.uid,
      gram: formGoldPiece.gram,
      status: formGoldPiece.status,
      name: formGoldPiece.name,
      notes: formGoldPiece.notes
    };

    goldPieceRep
      .updateGoldPiece(params)
      .then(result => {
        alertToast.updateLoading(x);
        alertToast.successAlert();
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //GOLD PIECE FEE
  const handleFetchGoldPieceFee = (_data: GoldPieceParams) => {
    const x = alertToast.loadingAlert('');

    const params = {
      gold_piece_uid: _data.gold_piece_uid
    };

    goldPieceFeeRep
      .fetchGoldPieceFee(params)
      .then((result: any) => {
        setGoldPieceFees(result.data);
        setGoldPieceFeePagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //GOLD STORE PIECE FEE
  const handleStoreGoldPieceFee = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      effective_date_start: formatDateTimeParam(formGoldPieceFee.effective_date_start),
      effective_date_end: formatDateTimeParam(formGoldPieceFee.effective_date_end),
      fee: formGoldPieceFee.fee,
      gold_piece_uid: formGoldPieceFee.gold_piece_uid,
      type: formGoldPieceFee.type
    };

    goldPieceFeeRep
      .storeGoldPieceFee(params)
      .then((result: any) => {
        handleAddGoldPieceFeeModal.setState(false);
        handleFetchGoldPieceFee({ gold_piece_uid: formGoldPieceFee.gold_piece_uid });
        alertToast.updateLoading(x);
        alertToast.successAlert();
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: GoldPieceContextValue = {
    formGoldPieceFee,
    setFormGoldPieceFee,
    goldPieceFees,
    goldPieceFeePagination,
    formGoldPiece,
    setFormGoldPiece,
    goldPieces,
    goldPiecePagination,
    handleFetchGoldPiece,
    handleAddGoldPieceModal,
    handleShowGoldPiece,
    handleUpdateGoldPiece,
    handleFetchGoldPieceFee,
    handleAddGoldPieceFeeModal,
    handleStoreGoldPieceFee,
    handleStoreGoldPiece,
    handleDeleteGoldPrice
  };

  return <GoldPieceContext.Provider value={contextValue}>{children}</GoldPieceContext.Provider>;
};

const useGoldPieceContext = (): GoldPieceContextValue => {
  const context = useContext(GoldPieceContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a GoldPieceProvider');
  }
  return context;
};

export { GoldPieceProvider, useGoldPieceContext };
