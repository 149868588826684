import Navbar from '@/delivery/components/organisms/navbar/navbar';
import DCAActivation from '@/delivery/ui/admin/data_dca/dca_activation/dca_activation.tsx';

const DCAActivationPage = () => {
  return (
    <div>
      <Navbar label={'DCA Activation'}></Navbar>
      <DCAActivation />
    </div>
  );
};

export default DCAActivationPage;
