// import styles from './Radio.module.css';
// import clsx from 'clsx';
// import { GoDotFill } from 'react-icons/go';

interface RadioProps {
  value?: string | number;
  checked?: boolean;
  label?: string;
  name?: string;
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio = (props: RadioProps) => {
  const { label, name, id, onChange, checked, value } = props;

  return (
    <div className="flex justify-start items-center p-[14px]">
      <input
        value={value}
        checked={checked}
        onChange={onChange}
        id={id}
        name={name}
        type="radio"
        className="text-text-secondary-invert border-border-tertiary focus:text-text-secondary-invert dark:focus:text-text h-[16px] w-[16px]"
      />
      <label htmlFor={id} className="ml-[12px]">
        {label}
      </label>
    </div>
  );
};

export default Radio;
