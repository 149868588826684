import Navbar from '@/delivery/components/organisms/navbar/navbar';
import CompanySetting from './company_setting';

const CompanySettingPage = () => {
  return (
    <div>
      <Navbar label={'Company Setting'}></Navbar>
      <CompanySetting />
    </div>
  );
};

export default CompanySettingPage;
