const Status = [
  { UUID: 'active', name: 'Active' },
  { UUID: 'inactive', name: 'Inactive' }
];

export const Status_const = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export const STATUS_LIST = [
  { UUID: 'ACTIVE', name: 'ACTIVE' },
  { UUID: 'INACTIVE', name: 'INACTIVE' }
];

export const STATUS_CONST = {
  ACTIVE: 'ACTIVE',
  NONACTIVE: 'NONACTIVE'
};

export const STATUS_LIST_LIST = [
  { UUID: 'ACTIVE', name: 'ACTIVE' },
  { UUID: 'NONACTIVE', name: 'NONACTIVE' }
];

export default Status;
