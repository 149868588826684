import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useAffiliateListContext } from '@/delivery/ui/admin/reseller_affiliate/affiliate_list/context/affiliate_list_context.tsx';

const TableDataReferral = () => {
  const { profileAffiliates } = useAffiliateListContext();

  return (
    <div>
      <Theader>
        <div>
          <Typography size={14} fontWeight={600}>
            Data Referral
          </Typography>
        </div>

        <div></div>
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Token</Th>
            <Th>Affiliates</Th>
            <Th>Commission</Th>
          </Tr>
        </Thead>
        <Tbody>
          {profileAffiliates.length > 0 ? (
            <>
              {profileAffiliates.map(item => (
                <Tr>
                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {item?.token}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {item.affiliates}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {item?.getCommission}
                    </Typography>
                  </Td>
                </Tr>
              ))}
            </>
          ) : (
            <Tr>
              <Td colSpan={3}>
                <Typography size={12} style={'italic'}>
                  belum memiliki referral
                </Typography>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
};

export default TableDataReferral;
