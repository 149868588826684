import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useBusinessAccountContext } from '@/delivery/ui/admin/data_member/member_list/context/business_account_context.tsx';
import DialogUpdateInfoBusiness from '@/delivery/ui/admin/data_member/member_list/detail/tabs/business_account/dialog/dialog_update_info_business.tsx';

const TableBusinessInfo = () => {
  const { handleDialogEditInfoBusinessModal, corporate } = useBusinessAccountContext();

  return (
    <div>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr noBorder>
            <Th noBorder>
              <Typography fontWeight={600} size={14} type="primary-invert">
                Informasi Bisnis
              </Typography>
            </Th>
            <Th noBorder>
              <div className={'justify-end flex'}>
                <span
                  className={'text-text-primary font-[600] text-[14px] cursor-pointer'}
                  onClick={() => handleDialogEditInfoBusinessModal.setState(true)}
                >
                  Edit
                </span>
              </div>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {/*business name*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Nama Usaha
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {corporate?.name}
              </Typography>
            </Td>
          </Tr>

          {/*email*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Email
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {corporate?.emailCorporate}
              </Typography>
            </Td>
          </Tr>

          {/*website*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Website
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {corporate?.website}
              </Typography>
            </Td>
          </Tr>

          {/*phone*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Phone
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {corporate?.phoneCorporate}
              </Typography>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      {handleDialogEditInfoBusinessModal.state && (
        <DialogUpdateInfoBusiness modal={handleDialogEditInfoBusinessModal} />
      )}
    </div>
  );
};

export default TableBusinessInfo;
