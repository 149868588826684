import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Typography from '@/delivery/components/atoms/typography/typography';
import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table';

import { FiTrash2, FiEdit } from 'react-icons/fi';

const ATMLocation = () => {
  return (
    <div>
      <Theader>
        <TextField search placeholder="Search" />
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Nama Lokasi</Th>
            <Th>List Member ATM</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {[1].map((item, index) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  OFFLINE COUNTER JKT
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  Gedung STC Senayan, Lantai 3 No. 178B. Jl. Asia Afrika Pintu IX, Gelora Senayan, Jakarta Pusat - 10270
                </Typography>
              </Td>
              <Td>
                <div className="flex gap-[4px]">
                  <Button iconButton icon={<FiTrash2 />} />
                  <Button iconButton icon={<FiEdit />} />
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default ATMLocation;
