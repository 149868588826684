import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { DREAM_GOLD_STATUS_LIST } from '@/domain/constant/dream_gold/dream_gold_status.ts';
import { useBalanceContext } from '@/delivery/ui/admin/data_member/member_list/context/balance_context.tsx';

const DialogUpdateDreamGold = (props: ModalDialogProps) => {
  const { handleUpdateDreamGoldModal, handleUpdateDreamGold, setFormDreamGold, formDreamGold } = useBalanceContext();

  return (
    <ModalDialog overflow={false} size="small" title="Update Dream Gold" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Select2
          isSearchable={false}
          value={formDreamGold.status}
          options={DREAM_GOLD_STATUS_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          onChange={e => setFormDreamGold({ ...formDreamGold, status: e.value })}
        />
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleUpdateDreamGoldModal.setState(false)} />
        <Button label="Submit" onClick={() => handleUpdateDreamGold()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogUpdateDreamGold;
