import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { useEffect } from 'react';
import { useMemberVefiryContext } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';

const DialogUpdateAddress = (props: ModalDialogProps) => {
  const {
    handleUpdateAddress,
    handleEditAddressModal,
    handleFetchProvince,
    provinces,
    cities,
    districts,
    villages,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    villageID,
    setVillageID,

    fullAddress,
    setFullAddress,
    zipCode,
    setZipCode
  } = useMemberVefiryContext();

  useEffect(() => {
    handleFetchProvince();
  }, []);

  //fetch cities
  useEffect(() => {
    if (provinceID !== null) {
      handleFetchCities();
    }
  }, [provinceID]);

  //fetch district
  useEffect(() => {
    if (cityID !== 0) {
      handleFetchDistricts();
    }
  }, [cityID]);

  //fetch village
  useEffect(() => {
    if (districtID > 0) {
      handleFetchVillage();
    }
  }, [districtID]);

  return (
    <ModalDialog overflow={false} size="small" title="Update Alamat" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Select2
          value={provinceID?.toString()}
          block
          label={'Provinsi'}
          options={provinces?.map(item => {
            return {
              label: item.name,
              value: item.ID.toString()
            };
          })}
          onChange={e => {
            setProvinceID(parseInt(e.value));
          }}
        />
        <Select2
          value={cityID.toString()}
          block
          label={'Kota/Kab'}
          options={cities?.map(item => {
            return {
              label: item.name,
              value: item.ID.toString()
            };
          })}
          onChange={e => {
            setCityID(parseInt(e.value));
          }}
        />
        <Select2
          value={districtID.toString()}
          block
          label={'Kecamatan'}
          options={districts?.map(item => {
            return {
              label: item.name,
              value: item.ID.toString()
            };
          })}
          onChange={e => {
            setDistrictID(parseInt(e.value));
          }}
        />
        <Select2
          value={villageID.toString()}
          block
          label={'Kelurahan'}
          options={villages?.map(item => {
            return {
              label: item.name,
              value: item.ID.toString()
            };
          })}
          onChange={e => {
            setVillageID(parseInt(e.value));
          }}
        />
        <TextField
          value={fullAddress}
          block
          label={'Alamat Bisnis/Usaha'}
          onChange={e => setFullAddress(e.target.value)}
        />
        <TextField value={zipCode} type={'number'} label={'Kode POS'} onChange={e => setZipCode(e.target.value)} />

        <ModalDialogFooter className="flex gap-[20px] justify-end">
          <Button type="secondary" label="Cancel" onClick={() => handleEditAddressModal.setState(false)} />
          <Button label="Save" onClick={() => handleUpdateAddress()} />
        </ModalDialogFooter>
      </div>
    </ModalDialog>
  );
};

export default DialogUpdateAddress;
