import { BaseModel } from './base_model';
// import CompanyAddressModel from './company_address_model';

export default class CompanyModel extends BaseModel {
  // companyLocation: CompanyLocationModel;
  name: string;
  parent: CompanyModel;
  parentUUID: string;
  subCompany: CompanyModel;
  type: string;
  phone: string;
  fax: string;
  email: string;
  // defaultCompanyAddress: CompanyAddressModel;
  defaultCompanyAddressUUID: string;
  logo: string;

  // companyAddress: CompanyAddressModel[];

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    // companyLocation: CompanyLocationModel,
    name: string,
    parent: CompanyModel,
    parentUUID: string,
    subCompany: CompanyModel,
    type: string,
    phone: string,
    fax: string,
    email: string,
    // defaultCompanyAddress: CompanyAddressModel,
    defaultCompanyAddressUUID: string,
    logo: string
    // companyAddress: CompanyAddressModel[],
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);

    this.name = name;
    this.parent = parent;
    this.parentUUID = parentUUID;
    this.subCompany = subCompany;
    this.type = type;
    this.phone = phone;
    this.fax = fax;
    this.email = email;
    // this.defaultCompanyAddress = defaultCompanyAddress;
    this.defaultCompanyAddressUUID = defaultCompanyAddressUUID;
    this.logo = logo;
    // this.companyAddress = companyAddress;
  }

  public static fromJson(data: any): any | CompanyModel {
    try {
      return new CompanyModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.company_location,
        data.name,
        data.parent ? CompanyModel.fromJson(data.parent) : null,
        data.parent_uuid,
        data.sub_company,
        data.type,
        data.phone,
        data.fax,
        data.email,
        // data.default_company_address ? CompanyAddressModel.fromJson(data.default_company_address) : null,
        // data.default_company_address_uuid
        data.logo_img_uuid
        // data.company_address ? CompanyAddressModel.fromJsonList(data.company_address) : [],
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): CompanyModel[] | any {
    try {
      const listCompanyModel = [];

      for (let x = 0; x < data.length; x++) {
        listCompanyModel.push(CompanyModel.fromJson(data[x]));
      }

      return listCompanyModel;
    } catch {
      return [];
    }
  }
}
