import { useEffect } from 'react';
import AdminAccessPage from '@/delivery/ui/admin/settings/admin_access';
import { RoleProvider } from '@/delivery/ui/admin/settings/admin_access/context/role_context.tsx';
import { PermissionProvider } from '@/delivery/ui/admin/settings/admin_access/context/permission_context.tsx';
import { AccessControllerProvider } from '@/delivery/ui/admin/settings/admin_access/context/access_controller_context.tsx';
import { UserProvider } from '@/delivery/ui/admin/settings/admin_access/context/user_context.tsx';

const ViewAdminAccess = () => {
  useEffect(() => {
    document.title = 'Admin Access';
  }, []);

  return (
    <div>
      <UserProvider>
        <AccessControllerProvider>
          <PermissionProvider>
            <RoleProvider>
              <AdminAccessPage />
            </RoleProvider>
          </PermissionProvider>
        </AccessControllerProvider>
      </UserProvider>
    </div>
  );
};

export default ViewAdminAccess;
