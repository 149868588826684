import { useEffect } from 'react';
import CompanySettingPage from '@/delivery/ui/admin/settings/company';
import { CompanySettingProvider } from '@/delivery/ui/admin/settings/company/context/company_setting_context.tsx';

const ViewCompanySetting = () => {
  useEffect(() => {
    document.title = 'Company Setting';
  }, []);

  return (
    <div>
      <CompanySettingProvider>
        <CompanySettingPage />
      </CompanySettingProvider>
    </div>
  );
};

export default ViewCompanySetting;
