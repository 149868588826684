import { ENV_LOCAL_BASE_URL_NAME } from '@/domain/constant/environment.ts';

let API_BASE_URL: string | undefined = '';
const localStorageValue = localStorage.getItem(ENV_LOCAL_BASE_URL_NAME);
if (localStorageValue !== null && localStorageValue !== '') {
  API_BASE_URL = localStorageValue;
} else {
  API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
}

export { API_BASE_URL };

// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BUCKET_UPLOAD_MEDIA = process.env.REACT_APP_BUCKET_UPLOAD_MEDIA;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const PAIRING_KEY = process.env.REACT_APP_PAIRING_KEY || '';
export const SIGNATURE_KEY = process.env.REACT_APP_SIGNATURE_KEY || '';
