import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import TierInterface, { TierValue } from '@/domain/repository/interface/tier_interface';
import { Tier } from '@/domain/constant/api_list';
import { TierModel } from '@/domain/model/tier_model.ts';

interface tierValue {
  data: any;
  pagination: any;
}

export class TierRepository implements TierInterface {
  api = new ApiClient();

  async fetchTier(data: TierValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Tier.FETCH, {
            params: data
          })
          .then(result => {
            const data = TierModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const tier: tierValue = {
              data: data,
              pagination: pagination
            };

            resolve(tier);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showTier(data: TierValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Tier.SHOW, {
            params: data
          })
          .then(result => {
            const data = TierModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeTier(data: TierValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Tier.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateTier(data: TierValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(Tier.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteTier(data: TierValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(Tier.DELETE + data.uid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
