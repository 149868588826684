import Navbar from '@/delivery/components/organisms/navbar/navbar';

import ReferralList from './referral_list';

const ReferralListPage = () => {
  return (
    <div>
      <Navbar label={'Referral List'}></Navbar>
      <ReferralList />
    </div>
  );
};

export default ReferralListPage;
