import Ranking from './ranking';
import Navbar from '@/delivery/components/organisms/navbar/navbar';

const RankingPage = () => {
  return (
    <div>
      <Navbar label={'Ranking'} />
      <Ranking />
    </div>
  );
};

export default RankingPage;
