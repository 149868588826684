import { ModalDialog, ModalDialogProps } from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useEffect, useState } from 'react';
import Loading from '@/delivery/components/atoms/loading/loading.tsx';
import { useNotifyAppContext } from '@/delivery/ui/admin/web_admin/notify_app/context/notify_app_context.tsx';
import Checkbox from '@/delivery/components/atoms/checkbox/checkbox.tsx';

interface UserSelected {
  uuid?: string;
  name?: string;
}

const DialogListUser = (props: ModalDialogProps) => {
  const {
    // setFormDinaranSetting,
    // formDinaranSetting,
    loading,
    setUsers,
    users,
    handleFetchUser,
    setUserSelected,
    userSelected
  } = useNotifyAppContext();

  const [searchPrompt, setSearchPrompt] = useState(' ');

  useEffect(() => {
    if (searchPrompt.length >= 3) {
      handleFetchUser({ search: searchPrompt });
    }

    if (searchPrompt.length < 3) {
      setUsers([]);
    }
  }, [searchPrompt]);

  const handleSelectUser = (_data: UserSelected) => {
    setUserSelected([...userSelected, _data]);
  };

  const handleRemoveSelectUser = (_data: UserSelected) => {
    setUserSelected(prevUsers => prevUsers.filter(u => u.uuid !== _data.uuid));
  };

  return (
    <ModalDialog z_index={'z-50'} size="small" title="Select User" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <div className={'flex items-center'}>
          <TextField
            block
            search
            placeholder={'Search member by name (min 3 karakter)'}
            onChange={e => {
              setTimeout(() => setSearchPrompt(e.target.value), 500);
            }}
          />
          <div className={'w-[40px]'}>{loading ? <Loading /> : ''}</div>
        </div>
      </div>
      <div className={'mt-[20px]'}>
        {users?.map(item => (
          <div className={'flex items-center'}>
            <div>
              <Checkbox
                checked={userSelected.some(itemSelected => itemSelected.uuid === item.UUID)}
                onChange={e => {
                  if (userSelected.some(itemSelected => itemSelected.uuid === item.UUID)) {
                    handleRemoveSelectUser({ uuid: item.UUID, name: item.firstName });
                  } else {
                    handleSelectUser({ uuid: item.UUID, name: item.firstName });
                  }
                }}
              />
            </div>
            <div
              className={'cursor-pointer p-[10px] rounded-[8px] hover:bg-bg-primary-light'}
              // onClick={() => handleSelectUser(item)}
            >
              <Typography size={14} fontWeight={600}>
                {item?.firstName} {item?.lastName}
              </Typography>
              <Typography size={12} fontWeight={400}>
                {item?.email}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </ModalDialog>
  );
};

export default DialogListUser;
