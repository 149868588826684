import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { DCA_STATUS, DCA_STATUS_LIST } from '@/domain/constant/dca/dca_status.ts';
import { useAccountRequestContext } from '@/delivery/ui/admin/data_dca/account_request/context/account_request_context.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';

const DialogUpdateAccountRequest = (props: ModalDialogProps) => {
  const {
    accountRequest,
    handleUpdateAccountRequest,
    handleUpdateAccountRequestModal,
    formAccountRequest,
    setFormAccountRequest
  } = useAccountRequestContext();
  return (
    <ModalDialog overflow={false} size="small" title="DCA Account Request Update" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <div className={'grid grid-cols-2'}>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            Nama Bank
          </Typography>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            : {accountRequest?.bankName}
          </Typography>
        </div>
        <Line />

        <div className={'grid grid-cols-2'}>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            Acc. Name:
          </Typography>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            : {accountRequest?.accountName}
          </Typography>
        </div>
        <Line />

        <div className={'grid grid-cols-2'}>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            Acc. No:
          </Typography>
          <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
            : {accountRequest?.accountNumber}
          </Typography>
        </div>

        {accountRequest?.status !== DCA_STATUS.CLOSED && (
          <>
            <Line />
            <Select2
              isSearchable={false}
              value={formAccountRequest.status}
              options={DCA_STATUS_LIST.map(item => {
                return {
                  label: item.name,
                  value: item.UUID
                };
              })}
              onChange={e => setFormAccountRequest({ ...formAccountRequest, status: e.value })}
            />
          </>
        )}
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleUpdateAccountRequestModal.setState(false)} />
        <Button
          label="Submit"
          onClick={() => {
            handleUpdateAccountRequest();
          }}
        />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogUpdateAccountRequest;
