import DetailAffiliate from '@/delivery/ui/admin/reseller_affiliate/affiliate_list/detail/detail_affiliate_list.tsx';
import { AffiliateListProvider } from '@/delivery/ui/admin/reseller_affiliate/affiliate_list/context/affiliate_list_context.tsx';

const DetailAffiliatePage = () => {
  return (
    <div>
      <AffiliateListProvider>
        <DetailAffiliate />
      </AffiliateListProvider>
    </div>
  );
};

export default DetailAffiliatePage;
