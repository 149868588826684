import React, { createContext, useContext, ReactNode } from 'react';

import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';

interface MemberVerifyCountContextContextProps {
  children: ReactNode;
}

interface VerifyEMoneyContextValue {
  handleDetailMemberVerifyCountModal: ModalObject;
  handleSubmitAddCounterGold: () => void;
}

const MemberVerifyCountContext = createContext<VerifyEMoneyContextValue | null>(null);

const VerifyEMoneyCountProvider: React.FC<MemberVerifyCountContextContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const handleDetailMemberVerifyCountModal = useVisibleComponent(false);

  //HANDLE ADD STOCK
  const handleSubmitAddCounterGold = () => {
    alertToast.loadingAlert('');
  };

  const contextValue: VerifyEMoneyContextValue = {
    handleDetailMemberVerifyCountModal,
    handleSubmitAddCounterGold
  };

  return <MemberVerifyCountContext.Provider value={contextValue}>{children}</MemberVerifyCountContext.Provider>;
};

const useVerifyEMoneyCountContext = (): VerifyEMoneyContextValue => {
  const context = useContext(MemberVerifyCountContext);
  if (!context) {
    throw new Error('useVerifyEMoneyCountContext must be used within a VerifyEMoneyCountProvider');
  }
  return context;
};

export { VerifyEMoneyCountProvider, useVerifyEMoneyCountContext };
