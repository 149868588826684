import PaymentMethodInterface, { PaymentMethodValue } from '@/domain/repository/interface/payment_method_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { PaymentMethodModel } from '@/domain/model/payment/payment_method_model.ts';
import { PaymentMethod } from '@/domain/constant/api_list';

export class PaymentMethodRepository implements PaymentMethodInterface {
  api = new ApiClient();

  async fetchPaymentMethod(data: PaymentMethodValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(PaymentMethod.FETCH, {
            params: data
          })
          .then(result => {
            const data = PaymentMethodModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showPaymentMethod(data: PaymentMethodValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(PaymentMethod.SHOW, {
            params: data
          })
          .then(result => {
            const data = PaymentMethodModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storePaymentMethod(data: PaymentMethodValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(PaymentMethod.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkPaymentMethod(data: PaymentMethodValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(PaymentMethod.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updatePaymentMethod(data: PaymentMethodValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(PaymentMethod.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deletePaymentMethod(data: PaymentMethodValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(PaymentMethod.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
