import Navbar from '@/delivery/components/organisms/navbar/navbar';
import AccountTransaction from './account_transaction';

const AccountTransactionPage = () => {
  return (
    <div>
      <Navbar label={'Account Transaction'}></Navbar>
      <AccountTransaction />
    </div>
  );
};

export default AccountTransactionPage;
