import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table';

import { useEffect, useState } from 'react';
import { useAffiliateContext } from '@/delivery/ui/admin/master/member_affiliate/context/member_affiliate_context.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { AffiliateModel } from '@/domain/model/affiliate_model.ts';
import Button from '@/delivery/components/atoms/button/button.tsx';
import DialogUpdateMemberAffiliate from '@/delivery/ui/admin/master/member_affiliate/dialog/dialog_update_member_affiliate.tsx';

const MasterMemberAffiliate = () => {
  const { handleUpdateAffiliateModal, handleDeleteAffiliate, handleShowAffiliate, handleFetchAffiliate, affiliates } =
    useAffiliateContext();

  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    handleFetchAffiliate({});
  }, []);

  useEffect(() => {
    if (search !== '') {
      handleFetchAffiliate({ name: search });
    } else {
      handleFetchAffiliate({});
    }
  }, [search]);

  return (
    <div className="mt-[32px]">
      <Theader>
        <TextField
          search
          placeholder={'search type'}
          onChange={e => {
            setTimeout(() => {
              setSearch(e.target.value);
            }, 1000);
          }}
        />
      </Theader>
      <Table className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Komisi (%)</Th>
            <Th>Tipe Komisi</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {affiliates.map((item: AffiliateModel, index) => (
            <Tr key={index}>
              <Td>{item.type}</Td>
              <Td>{item.getCommission}</Td>
              <Td>{item.commissionType}</Td>
              <Td>
                <Button iconButton iconType="delete" onClick={() => handleDeleteAffiliate(item)} />
                <Button iconButton iconType="edit" onClick={() => handleShowAffiliate(item)} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {handleUpdateAffiliateModal.state && <DialogUpdateMemberAffiliate modal={handleUpdateAffiliateModal} />}
    </div>
  );
};

export default MasterMemberAffiliate;
