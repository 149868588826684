import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';

interface IATextEditorProps {
  // inputHook: [string, React.Dispatch<React.SetStateAction<string>>];
  onChange?: (e: any) => void;
  value?: string;
}

const TextEditor = (props: IATextEditorProps) => {
  const editorRef = useRef(null);
  return (
    <Editor
      value={props.value}
      apiKey="feevil4shh7juydgrd2j3pljksahnmb36nxrg4kccw42re7a"
      onInit={(evt: any, editor: any) => (editorRef.current = editor)}
      // onChange={(e: any) => {
      //   props.inputHook[1](e.target.getContent());
      // }}
      onChange={props.onChange}
      // initialValue="<p>This is the initial content of the editor.</p>"
      init={{
        height: 300,
        menubar: false,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'code',
          'help',
          'wordcount'
        ],
        toolbar:
          'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
  );
};

export default TextEditor;
