interface LoadingInterface {
  type?: 'linear' | 'circle';
}

const Loading = (props: LoadingInterface) => {
  const { type = 'circle' } = props;
  return (
    <div className="flex items-center w-full">
      {type === 'circle' ? (
        <svg
          className={`animate-spin mx-3 h-[24px] w-[20px] text-white`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        ''
      )}

      {type === 'linear' ? (
        <svg
          className="mx-3 h-[4px] w-full text-blue-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 4"
          preserveAspectRatio="none"
        >
          {/* Background bar */}
          <rect width="100%" height="4" fill="currentColor" className="opacity-25" />

          {/* Animated loading bar */}
          <rect className="animate-loadingMaterial" width="100%" height="4" fill="currentColor" />

          {/* Keyframe animation */}
          <style>{`
    @keyframes loadingMaterial {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(100%);
      }
    }

    .animate-loadingMaterial {
      animation: loadingMaterial 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  `}</style>
        </svg>
      ) : (
        ''
      )}
    </div>
  );
};

export default Loading;
