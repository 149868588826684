import { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

import { TabContent, TabPane2, TabPaneList2 } from '@/delivery/components/atoms/tab_pane/tab_pane.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';

import { Routing } from '@/domain/constant/router_path.ts';
import DetailGoldPiece from '@/delivery/ui/admin/master/gold_piece/detail/gold_piece/detail_gold_piece.tsx';
import GoldPieceFee from '@/delivery/ui/admin/master/gold_piece/detail/gold_piece_fee/gold_piece_fee.tsx';
import { GoldPieceProvider } from '@/delivery/ui/admin/master/gold_piece/context/gold_piece_context.tsx';

const DetailGoldPiecePage = () => {
  const navigate = useNavigate();
  const [tabPage, setTabPage] = useState(0);
  const data = [
    {
      label: 'Detail Gold Piece',
      index: 0
      // handleClick: () => {}
    },
    {
      label: 'Gold Piece Fee',
      index: 1
      // handleClick: () => {}
    }
  ];
  return (
    <div className={'mt-[24px] flex flex-col gap-[20px]'}>
      <div
        className={'flex gap-[10px] items-center text-text-primary cursor-pointer'}
        onClick={() => navigate(Routing.MASTER.GOLD_PIECE)}
      >
        <FaArrowLeft />
        <Typography size={14} fontWeight={500}>
          Back to gold piece
        </Typography>
      </div>
      <div>
        <Typography size={24} fontWeight={600}>
          Gold Piece Detail
        </Typography>
      </div>
      <div className="flex flex-col gap-[20px]">
        <TabPane2>
          {data?.map((item, index) => (
            <TabPaneList2 label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
          ))}
        </TabPane2>

        <TabContent index={0} tab={tabPage}>
          <GoldPieceProvider>
            <DetailGoldPiece />
          </GoldPieceProvider>
        </TabContent>
        <TabContent index={1} tab={tabPage}>
          <GoldPieceProvider>
            <GoldPieceFee />
          </GoldPieceProvider>
        </TabContent>
      </div>
    </div>
  );
};

export default DetailGoldPiecePage;
