import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';

import Button from '@/delivery/components/atoms/button/button.tsx';
import { useHistoryContext } from '@/delivery/ui/admin/transaction/take_gold/history/context/history_context.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import { useParams } from 'react-router-dom';

const DialogRejectPrintGold = (props: ModalDialogProps) => {
  const { uid } = useParams();
  const { handleRejectPrintGoldModal, handleRejectPrintGoldPickUp, formHistory, setFormHistory } = useHistoryContext();

  return (
    <ModalDialog size="small" title="Reject Cetak Emas" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Textarea
          label={'Alasan'}
          block
          onChange={e => setFormHistory({ ...formHistory, rejectedReason: e.target.value })}
        />
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleRejectPrintGoldModal.setState(false)} />
        <Button block label="Reject" type={'danger'} onClick={() => handleRejectPrintGoldPickUp({ trx_uid: uid })} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogRejectPrintGold;
