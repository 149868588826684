import Navbar from '@/delivery/components/organisms/navbar/navbar';
import MicrogoldByATM from './microgold_by_atm';

const MicrogoldByATMPage = () => {
  return (
    <div>
      <Navbar label={'Microgold by ATM'}></Navbar>
      <MicrogoldByATM />
    </div>
  );
};

export default MicrogoldByATMPage;
