import { Table, Tbody, Td, Tfooter, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { useSettlementContext } from '@/delivery/ui/admin/transaction/settlement/context/settlement_context.tsx';
import { useEffect, useState } from 'react';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';
import Checkbox from '@/delivery/components/atoms/checkbox/checkbox.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';

const Settlement = () => {
  const {
    transactionList,
    setTransactionList,
    transactionSettlements,
    transactionSettlementPagination,
    handleFetchTransactionSettlement,
    handleSettleDataTransaction
  } = useSettlementContext();

  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [dataCheckedAll, setDataCheckedAll] = useState<string[]>([]);

  useEffect(() => {
    handleFetchTransactionSettlement({});
  }, []);

  //SET DATA ALL CHECKED
  useEffect(() => {
    if (transactionSettlements) {
      const allUIDs = transactionSettlements.map(settlement => settlement.UID);
      setDataCheckedAll(allUIDs);
    }
  }, [transactionSettlements]);

  //HANDLE CHECK ALL
  const handleCheckAll = () => {
    if (checkAll === false) {
      setTransactionList(dataCheckedAll);
      setCheckAll(true);
    } else {
      setTransactionList([]);
      setCheckAll(false);
    }
  };

  return (
    <div>
      <div className={'flex justify-start mb-[20px]'}>
        <Button label={'Settled All Selected'} type={'secondary'} onClick={() => handleSettleDataTransaction()} />
      </div>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>
              <Checkbox checked={checkAll} onChange={() => handleCheckAll()} />
            </Th>
            <Th>TrxID</Th>
            <Th>Member</Th>
            <Th>Type</Th>
            <Th>Reversal</Th>
            {/*<Th>Pengirim</Th>*/}
            {/*<Th>Penerima</Th>*/}
            <Th>Status</Th>
            {/*<Th>ICDX Send</Th>*/}
            <Th>
              <span className={'whitespace-nowrap'}>Trx Date</span>
            </Th>
            <Th>
              <span className={'whitespace-nowrap'}>Paid Date</span>
            </Th>
            <Th>Paid Amount</Th>
            <Th>Vendor</Th>
            <Th>Vendor Ref ID</Th>
            <Th>Gramasi</Th>
            <Th>Gold Rate</Th>
            <Th>Update By</Th>
            {/*<Th>Action</Th>*/}
          </Tr>
        </Thead>
        <Tbody>
          {transactionSettlements?.map((item: TransactionModel, index) => (
            <Tr>
              <Td>
                <Checkbox
                  id={`${index}`}
                  checked={transactionList.includes(item.UID)}
                  onChange={() => {
                    if (transactionList.includes(item.UID)) {
                      setTransactionList(prevData => prevData.filter(uid => uid !== item.UID));
                    } else {
                      setTransactionList([...transactionList, item.UID]);
                    }
                  }}
                />
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {item.code}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={600} size={12} type="primary-invert">
                  {item.userEmail?.name}
                </Typography>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {item.userEmail?.email}
                </Typography>
              </Td>
              <Td>
                {item.flags ? (
                  <span className={'whitespace-nowrap'}>
                    <Chips label={item.flags.join('')} removeDot />
                  </span>
                ) : (
                  <span className={'whitespace-nowrap'}>
                    <Chips label={item.type} removeDot />
                  </span>
                )}
              </Td>
              <Td>
                {item.type === TYPE_TRANSACTION.REVERSAL ? (
                  <span className={'whitespace-nowrap'}>
                    <Chips label={item.type} removeDot />
                  </span>
                ) : (
                  '-'
                )}
              </Td>
              {/*<Td>*/}
              {/*  <Typography fontWeight={600} size={12} type="primary-invert">*/}
              {/*    {item?.senderName || ''}*/}
              {/*  </Typography>*/}
              {/*  <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
              {/*    {item?.senderEmail || ''}*/}
              {/*  </Typography>*/}
              {/*</Td>*/}
              {/*<Td>*/}
              {/*  <Typography fontWeight={600} size={12} type="primary-invert">*/}
              {/*    {item?.receiverName || ''}*/}
              {/*  </Typography>*/}
              {/*  <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
              {/*    {item?.receiverEmail || ''}*/}
              {/*  </Typography>*/}
              {/*</Td>*/}
              <Td>
                {item.flags && item.type === TYPE_TRANSACTION.REVERSAL ? (
                  <Chips label={`${item.status} ${item.type}`} type={item.getStatusColor} />
                ) : (
                  <Chips label={item.status} type={item.getStatusColor} />
                )}
              </Td>
              {/*<Td>*/}
              {/*  <div className={'whitespace-nowrap'}>*/}
              {/*    <Chips*/}
              {/*      removeDot*/}
              {/*      label={item.flags?.includes('ICDX_SYNCED') ? 'SENT' : 'NOT SENT'}*/}
              {/*      type={item.flags?.includes('ICDX_SYNCED') ? 'primary' : 'danger'}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</Td>*/}
              <Td>
                <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                  <span className={'whitespace-nowrap'}>{item.getTransactionAt}</span>
                </Typography>
              </Td>
              <Td>
                <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                  <span className={'whitespace-nowrap'}>{item.getSucceedAt}</span>
                </Typography>
              </Td>
              <Td>
                <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                  {item.getAmountRupiah}
                </Typography>
              </Td>
              <Td>
                <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                  {item.payment?.vendor}
                </Typography>
              </Td>
              <Td>
                <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                  {item.payment?.vendorRefID}
                </Typography>
              </Td>
              <Td>
                <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                  {item.amount?.gram}
                </Typography>
              </Td>
              <Td>
                <Typography tag="p" fontWeight={600} size={12} type="tertiary-invert">
                  {item.rate.getBuy}
                </Typography>
                <Typography tag="p" fontWeight={400} size={12} type="tertiary-invert">
                  {item.rate.getSell}
                </Typography>
              </Td>
              <Td>
                <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                  {item.userUpdate?.name}
                </Typography>
              </Td>
              {/*<Td>*/}
              {/*  <div className="flex gap-[4px]">*/}
              {/*    <Button iconButton iconType={'view'} onClick={() => handleShowTransaction(item)} />*/}
              {/*    <Button iconButton iconType={'banned'} onClick={() => handleCancelTransaction(item)} />*/}
              {/*    {item.type === TYPE_TRANSACTION.BUY || item.type === TYPE_TRANSACTION.SELL ? (*/}
              {/*      item.flags?.includes('ICDX_SYNCED') ? (*/}
              {/*        <Button*/}
              {/*          disabled*/}
              {/*          iconButton*/}
              {/*          icon={Icon.thumbsUp}*/}
              {/*          onClick={() => handleSendICDXTransaction(item)}*/}
              {/*        />*/}
              {/*      ) : (*/}
              {/*        <Button iconButton icon={Icon.thumbsUp} onClick={() => handleSendICDXTransaction(item)} />*/}
              {/*      )*/}
              {/*    ) : (*/}
              {/*      ''*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*</Td>*/}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={transactionSettlementPagination?.page || 1}
          totalPages={transactionSettlementPagination?.totalPages || 1}
          handlePagination={page => {
            handleFetchTransactionSettlement({
              page: page
            });
          }}
        />
      </Tfooter>
    </div>
  );
};

export default Settlement;
