import { ApiClient } from '@/main/apiClient';
import { TransactionStatistic, UserStatistic } from '@/domain/constant/api_list';
import UserStatisticInterface from '@/domain/repository/interface/user_statistic_interface.ts';
import { UserStatisticModel } from '@/domain/model/user_statistic_model.ts';
import { TransactionStatisticModel } from '@/domain/model/transaction_statistic_model.ts';

export class UserStatisticRepository implements UserStatisticInterface {
  api = new ApiClient();

  async countUserStatus() {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(UserStatistic.USER_STATUS, {})
          .then(result => {
            const data = UserStatisticModel.fromJson(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async countUserVerification() {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(UserStatistic.USER_VERIFICATION, {})
          .then(result => {
            const data = UserStatisticModel.fromJson(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async countUserTransaction() {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(UserStatistic.USER_TRANSACTION, {})
          .then(result => {
            const data = UserStatisticModel.fromJson(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async countTransaction() {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(TransactionStatistic.SUMMARY_STATUS, {})
          .then(result => {
            const data = TransactionStatisticModel.fromJsonList(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
