import { ReactNode } from 'react';
import styles from './TabPane.module.css';
import clsx from 'clsx';

interface TabListValue {
  label?: string;
  index?: number;
  handleClick?: any;
  tab?: number;
  prefixIcon?: any;
}

interface TabPaneProps {
  children?: ReactNode;
}

const TabPane = (props: TabPaneProps) => {
  const { children } = props;
  return (
    <div className=" relative w-full">
      <div>
        <ul className="relative bg-content-bg-secondary rounded-[8px] flex list-none flex-wrap p-[4px]">{children}</ul>
        <TabContent />
      </div>
    </div>
  );
};

const TabPaneList = (props: TabListValue) => {
  const { handleClick, index, label, tab, prefixIcon } = props;
  return (
    <>
      <li className="z-30 flex-auto text-center">
        <a
          onClick={handleClick}
          tabIndex={index}
          className={clsx(styles['tab-pane'], `${index === tab ? styles['active'] : styles['not-active']}`)}
        >
          <span className="text-[20px] mr-[8px]">
            {prefixIcon}
            {/* <AiOutlineEye /> */}
          </span>
          <span className="text-[14px]">{label}</span>
        </a>
      </li>
    </>
  );
};

const TabPane2 = (props: TabPaneProps) => {
  const { children } = props;
  return (
    <div className="relative overflow-x-auto">
      <div>
        <ul className="relative bg-content-bg-secondary flex items-center list-none border-b-[1px] border-border-tertiary">
          {children}
        </ul>
        <TabContent />
      </div>
    </div>
  );
};

const TabPaneList2 = (props: TabListValue) => {
  const { handleClick, index, label, tab, prefixIcon } = props;
  return (
    <>
      <li>
        <a
          onClick={handleClick}
          tabIndex={index}
          className={clsx(
            styles['tab-pane-2'],
            `${
              index === tab
                ? 'text-text-primary font-[500] text-[14px] border-b-[3px] border-bg-primary'
                : 'text-text-tertiary-invert font-[500] text-[14px]'
            }`
          )}
        >
          <span className="text-[20px] mr-[8px]">
            {prefixIcon}
            {/* <AiOutlineEye /> */}
          </span>
          <span className="text-[14px]">{label}</span>
        </a>
      </li>
    </>
  );
};

const TabPane3 = (props: TabPaneProps) => {
  const { children } = props;
  return (
    <div className="relative overflow-x-auto">
      <div>
        <ul className="relative flex items-center list-none border-border-tertiary">{children}</ul>
        <TabContent />
      </div>
    </div>
  );
};

const TabPaneList3 = (props: TabListValue) => {
  const { handleClick, index, label, tab, prefixIcon } = props;
  return (
    <>
      <li>
        <a
          onClick={handleClick}
          tabIndex={index}
          className={clsx(
            styles['tab-pane-2'],
            `${
              index === tab
                ? 'text-text-primary font-[500] text-[14px] bg-bg-primary-light rounded-[8px]'
                : 'text-text-tertiary-invert font-[500] text-[14px]'
            }`
          )}
        >
          <span className="text-[20px] mr-[8px]">
            {prefixIcon}
            {/* <AiOutlineEye /> */}
          </span>
          <span className="text-[14px]">{label}</span>
        </a>
      </li>
    </>
  );
};

interface TabContentProps {
  children?: ReactNode;
  index?: number;
  tab?: number;
}

const TabContent = (props: TabContentProps) => {
  const { children, index, tab } = props;
  return (
    <div tabIndex={index} className={`${tab === index ? `block` : `hidden`}`}>
      {children}
    </div>
  );
};

export { TabPane, TabPaneList, TabContent, TabPane2, TabPaneList2, TabPane3, TabPaneList3 };
