import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { OfflineCounterRepository } from '@/domain/repository/offline_counter_repository.ts';
import { OfflineCounterModel } from '@/domain/model/offline_counter_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { OfflineCounterUnavailabilityRepository } from '@/domain/repository/offline_counter_unavailability_repository.ts';
import { formatDateParam, formatDateTimeParam } from '@/infrastructure/helper/formatDate.ts';
import { RegionProvinceModel } from '@/domain/model/region_province_model.ts';
import { RegionCityModel } from '@/domain/model/region_city_model.ts';
import { RegionDistrictModel } from '@/domain/model/region_district_model.ts';
import { RegionVillageModel } from '@/domain/model/region_village_model.ts';
import { RegionRepository } from '@/domain/repository/region_repository.ts';
import { NATIONAL_ID } from '@/domain/constant/national_id.ts';
import { OfflineCounterUnavailabilityModel } from '@/domain/model/offline_counter_unavailability_model.ts';

interface CounterOfflineContextProps {
  children: ReactNode;
}

interface CounterOfflineValue {
  page?: number;
  limit?: number;
}

interface FormFilterOfflineCounter {
  search?: string;
}

interface FormCounterOfflineValue {
  uid?: string;
  days?: string[];
  email?: string;
  name?: string;
  phone?: string;
  pic?: string;
  status?: string;
  full_address?: string;
  zip_code?: string;
  start_hour?: string;
  end_hour?: string;
}

interface FormCounterUnavailabilityValue {
  uid?: string;
  start_date?: string;
  end_date?: string;
  start_hour?: string;
  end_hour?: string;
  offline_counter_uid?: string;
}

interface CounterOfflineContextValue {
  availabilities: OfflineCounterUnavailabilityModel[];
  filterOfflineCounter: FormFilterOfflineCounter;
  setFilterOfflineCounter: Dispatch<SetStateAction<FormFilterOfflineCounter>>;
  formCounterUnavailability: FormCounterUnavailabilityValue;
  setFormCounterUnavailability: Dispatch<SetStateAction<FormCounterUnavailabilityValue>>;
  formCounterOffline: FormCounterOfflineValue;
  setFormCounterOffline: Dispatch<SetStateAction<FormCounterOfflineValue>>;
  offlineCounters: OfflineCounterModel[];
  offlineCounterPagination?: PaginationModel;
  handleAddCounterOfflineModal: ModalObject;
  handleUpdateCounterOfflineModal: ModalObject;
  handleAddCounterUnavailabilityModal: ModalObject;
  handleFetchOfflineCounter: (_data: CounterOfflineValue) => void;
  handleAddCounterOffLine: () => void;
  handleShowCounterOffLine: (_data: OfflineCounterModel) => void;
  handleUpdateCounterOffLine: () => void;
  handleDeleteCounterOffLine: (_data: OfflineCounterModel) => void;
  handleAddDialogCounterUnavailability: (_data: FormCounterOfflineValue) => void;
  handleAddCounterUnavailability: () => void;
  handleDeleteCounterUnavailability: (_data: OfflineCounterUnavailabilityModel) => void;

  provinces: RegionProvinceModel[];
  cities: RegionDistrictModel[];
  districts: RegionDistrictModel[];
  villages: RegionVillageModel[];
  handleFetchProvince: () => void;
  handleFetchCities: () => void;
  handleFetchDistricts: () => void;
  handleFetchVillage: () => void;

  provinceID: number;
  setProvinceID: Dispatch<SetStateAction<number>>;
  cityID: number;
  setCityID: Dispatch<SetStateAction<number>>;
  districtID: number;
  setDistrictID: Dispatch<SetStateAction<number>>;
  villageID: number;
  setVillageID: Dispatch<SetStateAction<number>>;
}

const StockGoldContext = createContext<CounterOfflineContextValue | null>(null);

const offlineCounterRep = new OfflineCounterRepository();
const offlineCounterUnavailability = new OfflineCounterUnavailabilityRepository();
const regionRepository = new RegionRepository();
const offlineCounterRepository = new OfflineCounterUnavailabilityRepository();

const CounterOfflineProvider: React.FC<CounterOfflineContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const handleAddCounterOfflineModal = useVisibleComponent(false);
  const handleUpdateCounterOfflineModal = useVisibleComponent(false);
  const handleAddCounterUnavailabilityModal = useVisibleComponent(false);

  const [filterOfflineCounter, setFilterOfflineCounter] = useState<FormFilterOfflineCounter>({});

  const [offlineCounters, setOfflineCounters] = useState<OfflineCounterModel[]>([]);
  const [offlineCounterPagination, setOfflineCounterPagination] = useState<PaginationModel>();

  const [availabilities, setAvailabilities] = useState<OfflineCounterUnavailabilityModel[]>([]);

  const [formCounterOffline, setFormCounterOffline] = useState<FormCounterOfflineValue>({});
  const [formCounterUnavailability, setFormCounterUnavailability] = useState<FormCounterUnavailabilityValue>({});

  const [provinces, setProvinces] = useState<RegionProvinceModel[]>([]);
  const [cities, setCities] = useState<RegionCityModel[]>([]);
  const [districts, setDistricts] = useState<RegionDistrictModel[]>([]);
  const [villages, setVillages] = useState<RegionVillageModel[]>([]);

  const [provinceID, setProvinceID] = useState<number>(0);
  const [cityID, setCityID] = useState<number>(0);
  const [districtID, setDistrictID] = useState<number>(0);
  const [villageID, setVillageID] = useState<number>(0);

  //HANDLE FETCH OFFLINE COUNTER
  const handleFetchOfflineCounter = (_data: CounterOfflineValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      current_time: formatDateTimeParam(new Date()),
      search: filterOfflineCounter.search ?? null
    };

    offlineCounterRep
      .fetchOfflineCounter(params)
      .then((result: any) => {
        setOfflineCounters(result.data);
        setOfflineCounterPagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE ADD COUNTER OFFLINE
  const handleAddCounterOffLine = () => {
    const params = {
      days: formCounterOffline.days,
      start_hour: `0001-01-01 ${formCounterOffline.start_hour}:00`,
      end_hour: `0001-01-01 ${formCounterOffline.end_hour}:00`,
      email: formCounterOffline.email,
      // flags: null,
      // meta: null,
      name: formCounterOffline.name,
      phone: formCounterOffline.phone,
      pic: formCounterOffline.pic,
      status: formCounterOffline.status,
      full_address: formCounterOffline.full_address,
      national_id: NATIONAL_ID,
      province_id: provinceID,
      city_id: cityID,
      district_id: districtID,
      village_id: villageID,
      zip_code: formCounterOffline.zip_code
    };
    offlineCounterRep
      .storeOfflineCounter(params)
      .then(() => {
        alertToast.successAlert();
        handleFetchOfflineCounter({ page: 1 });
        handleAddCounterOfflineModal.setState(false);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW COUNTER OFFLINE
  const handleShowCounterOffLine = (_data: OfflineCounterModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID,
      current_time: formatDateTimeParam(new Date())
    };
    offlineCounterRep
      .showOfflineCounter(params)
      .then((res: any) => {
        const result: OfflineCounterModel = res;

        setFormCounterOffline({
          uid: result.UID,
          status: result.status,
          email: result.email,
          days: result.days,
          phone: result.phone,
          pic: result.pic,
          name: result.name,
          start_hour: result.getStartHour,
          end_hour: result.getEndHour,
          full_address: result.fullAddress,
          zip_code: result.zipCode
        });

        setProvinceID(parseInt(result.provinceID));
        setCityID(parseInt(result.cityID));
        setDistrictID(parseInt(result.districtID));
        setVillageID(parseInt(result.villageID));

        handleUpdateCounterOfflineModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW COUNTER OFFLINE
  const handleUpdateCounterOffLine = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      uid: formCounterOffline.uid,
      days: formCounterOffline.days,
      start_hour: `${formCounterOffline.start_hour}:00`,
      end_hour: `${formCounterOffline.end_hour}:00`,
      email: formCounterOffline.email,
      // flags: null,
      // meta: null,
      name: formCounterOffline.name,
      phone: formCounterOffline.phone,
      pic: formCounterOffline.pic,
      status: formCounterOffline.status,
      full_address: formCounterOffline.full_address,
      national_id: NATIONAL_ID,
      province_id: provinceID,
      city_id: cityID,
      district_id: districtID,
      village_id: villageID,
      zip_code: formCounterOffline.zip_code
    };

    offlineCounterRep
      .updateOfflineCounter(params)
      .then(() => {
        handleUpdateCounterOfflineModal.setState(false);
        alertToast.updateLoading(x);
        alertToast.successAlert();
        handleFetchOfflineCounter({ page: 1 });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE DELETE COUNTER OFFLINE
  const handleDeleteCounterOffLine = (_data: OfflineCounterModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          uid: _data.UID
        };

        offlineCounterRep
          .deleteOfflineCounter(params)
          .then(() => {
            alertToast.updateLoading(x);
            alertToast.successAlert();
            handleFetchOfflineCounter({ page: 1 });
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE DISPLAY MODAL ADD COUNTER UNAVAILABILITY
  const handleAddDialogCounterUnavailability = (_data: FormCounterOfflineValue) => {
    const x = alertToast.loadingAlert('');
    setFormCounterUnavailability({ ...formCounterOffline, offline_counter_uid: _data.uid });

    const params = {
      offline_counter_uid: _data.uid
    };

    offlineCounterRepository
      .fetchOfflineCounterUnavailability(params)
      .then((result: any) => {
        setAvailabilities(result.data);
        handleAddCounterUnavailabilityModal.setState(true);

        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE ADD COUNTER UNAVAILABILITY
  const handleAddCounterUnavailability = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      start_hour: formCounterUnavailability?.start_hour + ':00',
      end_hour: formCounterUnavailability?.end_hour + ':00',
      offline_counter_uid: formCounterUnavailability?.offline_counter_uid,
      start_date: formatDateParam(formCounterUnavailability.start_date),
      end_date: formatDateParam(formCounterUnavailability.end_date)
    };

    offlineCounterUnavailability
      .storeOfflineCounterUnavailability(params)
      .then(() => {
        alertToast.updateLoading(x);
        alertToast.successAlert();
        handleFetchOfflineCounter({ page: 1 });
        handleAddCounterUnavailabilityModal.setState(false);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE DELETE COUNTER OFFLINE
  const handleDeleteCounterUnavailability = (_data: OfflineCounterUnavailabilityModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          uid: _data.UID
        };

        offlineCounterUnavailability
          .deleteOfflineCounterUnavailability(params)
          .then(() => {
            alertToast.updateLoading(x);
            alertToast.successAlert();
            handleAddDialogCounterUnavailability({ uid: _data.offlineCounterUID });
            handleFetchOfflineCounter({ page: 1 });
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  // ------ LOCATION ADDRESS BELOW ------ //

  //HANDLE FETCH PROVINCE
  const handleFetchProvince = () => {
    regionRepository
      .fetchProvince()
      .then((result: any) => {
        setProvinces(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH CITIES
  const handleFetchCities = () => {
    const params = {
      parent_id: provinceID
    };
    regionRepository
      .fetchCities(params)
      .then((result: any) => {
        setCities(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH DISTRICT
  const handleFetchDistricts = () => {
    const params = {
      parent_id: cityID
    };
    regionRepository
      .fetchDistrict(params)
      .then((result: any) => {
        setDistricts(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH VILLAGE
  const handleFetchVillage = () => {
    const params = {
      parent_id: districtID
    };

    regionRepository
      .fetchVillage(params)
      .then((result: any) => {
        setVillages(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const contextValue: CounterOfflineContextValue = {
    filterOfflineCounter,
    setFilterOfflineCounter,
    formCounterUnavailability,
    setFormCounterUnavailability,
    offlineCounters,
    offlineCounterPagination,
    handleAddCounterOfflineModal,
    handleUpdateCounterOfflineModal,
    handleFetchOfflineCounter,
    availabilities,
    formCounterOffline,
    setFormCounterOffline,
    handleAddCounterOffLine,
    handleShowCounterOffLine,
    handleUpdateCounterOffLine,
    handleDeleteCounterOffLine,
    handleAddCounterUnavailabilityModal,
    handleAddDialogCounterUnavailability,
    handleAddCounterUnavailability,
    handleDeleteCounterUnavailability,

    provinces,
    cities,
    districts,
    villages,
    handleFetchProvince,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    villageID,
    setVillageID
  };

  return <StockGoldContext.Provider value={contextValue}>{children}</StockGoldContext.Provider>;
};

const useCounterOfflineContext = (): CounterOfflineContextValue => {
  const context = useContext(StockGoldContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a CounterOfflineProvider');
  }
  return context;
};

export { CounterOfflineProvider, useCounterOfflineContext };
