import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';
import { IMAGE_URL } from '@/domain/constant/image_url.ts';
import Dropzone from '@/delivery/components/atoms/dropzone/dropzone.tsx';
import { useMemberVefiryContext } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';
import { useEffect } from 'react';

const DialogUpdateUserWithdrawal = (props: ModalDialogProps) => {
  const {
    handleUpdateUserWithdrawal,
    handleEditUserWithdrawalModal,
    formUserWithdrawal,
    setFormUserWithdrawal,
    handleFetchMasterBank,
    masterBanks
  } = useMemberVefiryContext();

  useEffect(() => {
    handleFetchMasterBank();
  }, []);

  return (
    <ModalDialog overflow={true} size="small" title="Update Bank" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Select2
          options={masterBanks.map(item => {
            return {
              value: item.UID,
              label: item.name
            };
          })}
          value={formUserWithdrawal.master_bank_uuid}
          label={'Bank'}
          block
          onChange={e => setFormUserWithdrawal({ ...formUserWithdrawal, master_bank_uuid: e.value })}
        />
        <TextField
          value={formUserWithdrawal.account_number}
          type={'number'}
          label={'Account Number'}
          block
          onChange={e => setFormUserWithdrawal({ ...formUserWithdrawal, account_number: e.target.value })}
        />
        <TextField
          value={formUserWithdrawal.account_holder_name}
          label={'Account Holder Name '}
          block
          onChange={e => setFormUserWithdrawal({ ...formUserWithdrawal, account_holder_name: e.target.value })}
        />
        <Image url={`${IMAGE_URL}/${formUserWithdrawal.media_uid}`} width={300} />
        <Dropzone
          id={'media'}
          label={'Buku Tabungan'}
          onChange={e => setFormUserWithdrawal({ ...formUserWithdrawal, media: e })}
        />
        <ModalDialogFooter className="flex gap-[20px] justify-end">
          <Button type="secondary" label="Cancel" onClick={() => handleEditUserWithdrawalModal.setState(false)} />
          <Button label="Save" onClick={() => handleUpdateUserWithdrawal()} />
        </ModalDialogFooter>
      </div>
    </ModalDialog>
  );
};

export default DialogUpdateUserWithdrawal;
