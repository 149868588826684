import useAlertToast from '@/delivery/hooks/useAlertComponent';
import React, { createContext, useContext, ReactNode, useState } from 'react';
import { AffiliateRepository } from '@/domain/repository/affiliate_repository.ts';
import { AffiliateModel } from '@/domain/model/affiliate_model.ts';
import { UserDashboardRepository } from '@/domain/repository/user_dashboard.ts';
import { UserDashboardModel } from '@/domain/model/user_dashboard_profile_model.ts';

interface ReferralContextProps {
  children: ReactNode;
}

interface ReferralParams {
  user_uid?: string;
  uid?: string;
  uuid?: string;
  page?: number;
  limit?: number;
}

interface ReferralContextValue {
  userDashboard?: UserDashboardModel;
  affiliate: AffiliateModel[];
  handleShowReferral: (_data: ReferralParams) => void;
  handleGetDataUserParentAffiliate: (_data: ReferralParams) => void;
}

const ReferralContext = createContext<ReferralContextValue | null>(null);

const affiliateRep = new AffiliateRepository();
const userDashboardRep = new UserDashboardRepository();

const ReferralProvider: React.FC<ReferralContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const [affiliate, setAffiliate] = useState<AffiliateModel[]>([]);
  const [userDashboard, setUserDashboard] = useState<UserDashboardModel>();

  //HANDLE SHOW CORPORATE
  const handleShowReferral = (_data: ReferralParams) => {
    const param = {
      user_uid: _data.user_uid
    };
    affiliateRep
      .fetchAffiliateCount(param)
      .then((result: any) => {
        setAffiliate(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE GET DATA USER PARENT AFFILIATE
  const handleGetDataUserParentAffiliate = (_data: ReferralParams) => {
    userDashboardRep
      .showUserDashboardProfile({ user_uid: _data.user_uid })
      .then((result: any) => {
        setUserDashboard(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const contextValue: ReferralContextValue = {
    affiliate,
    handleShowReferral,
    userDashboard,
    handleGetDataUserParentAffiliate
  };

  return <ReferralContext.Provider value={contextValue}>{children}</ReferralContext.Provider>;
};

const useReferralContext = (): ReferralContextValue => {
  const context = useContext(ReferralContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a ReferralProvider');
  }
  return context;
};

export { ReferralProvider, useReferralContext };
