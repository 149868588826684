import { BaseModel } from '@/domain/model/base_model.ts';

export class CompanySettingModel extends BaseModel {
  companyUUID: string;
  maxSubCompany: number;
  loginExpiredOnMinute: number;
  invoiceExpiredOnMinute: string;
  currency: string;
  timeZone: string;
  defaultSalesStationUUID: string;
  defaultReceivingStationUUID: string;
  transitStationUUID: string;
  emailMaximumStockAlert: string;
  emailMinimalStockAlert: string;
  phoneMinimalStockAlert: string;
  includeTax: boolean;
  isTaxCollection: boolean;
  taxHolder: string;
  taxableIncomeDate: string;
  taxNumber: string;
  taxType: string;
  taxAddressUUID: string;
  midtransSystemKey: string;
  xenditSystemKey: string;
  xenditXCallback: string;
  xenditEndpoint: string;
  dokuSecret: string;
  dokuClientID: string;
  dokuEndpoint: string;
  ipaymuApiKey: string;
  ipaymuSecretKey: string;
  expiredOnMinute: number;
  emailApproveSender: string;
  phoneApproveSender: string;
  emailOtp: string;
  phoneAccessOtp: string;
  midtransSandbox: boolean;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    companyUUID: string,
    maxSubCompany: number,
    loginExpiredOnMinute: number,
    invoiceExpiredOnMinute: string,
    currency: string,
    timeZone: string,
    defaultSalesStationUUID: string,
    defaultReceivingStationUUID: string,
    transitStationUUID: string,
    emailMaximumStockAlert: string,
    emailMinimalStockAlert: string,
    phoneMinimalStockAlert: string,
    includeTax: boolean,
    isTaxCollection: boolean,
    taxHolder: string,
    taxableIncomeDate: string,
    taxNumber: string,
    taxType: string,
    taxAddressUUID: string,
    midtransSystemKey: string,
    xenditSystemKey: string,
    xenditXCallback: string,
    xenditEndpoint: string,
    dokuSecret: string,
    dokuClientID: string,
    dokuEndpoint: string,
    ipaymuApiKey: string,
    ipaymuSecretKey: string,
    expiredOnMinute: number,
    emailApproveSender: string,
    phoneApproveSender: string,
    emailOtp: string,
    phoneAccessOtp: string,
    midtransSandbox: boolean
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.companyUUID = companyUUID;
    this.maxSubCompany = maxSubCompany;
    this.loginExpiredOnMinute = loginExpiredOnMinute;
    this.invoiceExpiredOnMinute = invoiceExpiredOnMinute;
    this.currency = currency;
    this.timeZone = timeZone;
    this.defaultSalesStationUUID = defaultSalesStationUUID;
    this.defaultReceivingStationUUID = defaultReceivingStationUUID;
    this.transitStationUUID = transitStationUUID;
    this.emailMaximumStockAlert = emailMaximumStockAlert;
    this.emailMinimalStockAlert = emailMinimalStockAlert;
    this.phoneMinimalStockAlert = phoneMinimalStockAlert;
    this.includeTax = includeTax;
    this.isTaxCollection = isTaxCollection;
    this.taxHolder = taxHolder;
    this.taxableIncomeDate = taxableIncomeDate;
    this.taxNumber = taxNumber;
    this.taxType = taxType;
    this.taxAddressUUID = taxAddressUUID;
    this.midtransSystemKey = midtransSystemKey;
    this.xenditSystemKey = xenditSystemKey;
    this.xenditXCallback = xenditXCallback;
    this.xenditEndpoint = xenditEndpoint;
    this.dokuSecret = dokuSecret;
    this.dokuClientID = dokuClientID;
    this.dokuEndpoint = dokuEndpoint;
    this.ipaymuApiKey = ipaymuApiKey;
    this.ipaymuSecretKey = ipaymuSecretKey;
    this.expiredOnMinute = expiredOnMinute;
    this.emailApproveSender = emailApproveSender;
    this.phoneApproveSender = phoneApproveSender;
    this.emailOtp = emailOtp;
    this.phoneAccessOtp = phoneAccessOtp;
    this.midtransSandbox = midtransSandbox;
  }

  public static fromJson(data: any): any | CompanySettingModel {
    try {
      return new CompanySettingModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.company_uuid,
        data.max_sub_company,
        data.login_expired_on_minute,
        data.invoice_expired_on_minute,
        data.currency,
        data.time_zone,
        data.default_sales_station_uuid,
        data.default_receiving_station_uuid,
        data.transit_station_uuid,
        data.email_maximum_stock_alert,
        data.email_minimal_stock_alert,
        data.phone_minimal_stock_alert,
        data.include_tax,
        data.is_tax_collection,
        data.tax_holder,
        data.taxable_income_date,
        data.tax_number,
        data.tax_type,
        data.tax_address_uuid,
        data.midtrans_system_key,
        data.xendit_system_key,
        data.xendit_x_callback,
        data.xendit_endpoint,
        data.doku_secret,
        data.doku_client_id,
        data.doku_endpoint,
        data.ipaymu_api_key,
        data.ipaymu_secret_key,
        data.expired_on_minute,
        data.email_approve_sender,
        data.phone_approve_sender,
        data.email_otp,
        data.phone_access_otp,
        data.midtrans_sandbox
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): CompanySettingModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(CompanySettingModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
