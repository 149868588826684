import { BaseModel } from '@/domain/model/base_model';

export class MediaAssignmentModel extends BaseModel {
  assigned: string;
  assignedToUUID: string;
  isDefault: boolean;
  mediaType: string;
  mediaUUID: string;
  status: boolean;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    assigned: string,
    assignedToUUID: string,
    isDefault: boolean,
    mediaType: string,
    mediaUUID: string,
    status: boolean
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.assigned = assigned;
    this.assignedToUUID = assignedToUUID;
    this.isDefault = isDefault;
    this.mediaType = mediaType;
    this.mediaUUID = mediaUUID;
    this.status = status;
  }

  public static fromJson(data: any): any | MediaAssignmentModel {
    try {
      return new MediaAssignmentModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.assigned,
        data.assigned_to_uuid,
        data.is_default,
        data.media_type,
        data.media_uuid,
        data.status
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): MediaAssignmentModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(MediaAssignmentModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
