import DChart from '@/delivery/components/atoms/chart/chart.tsx';
import { MemberChartMonthlyModel } from '@/domain/model/dashboard/member_chart_model_monthly.ts';

interface ChartRegisterVerifiedMonthlyProps {
  data: MemberChartMonthlyModel[];
}

const ChartRegisterVerifiedMonthly = (props: ChartRegisterVerifiedMonthlyProps) => {
  const { data } = props;

  const state: any = {
    series: [
      {
        name: 'Register User',
        // data: [44, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22, 43]
        data: data?.map(item => item.count)
      }
      // {
      //   name: 'Verified User',
      //   data: [13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27]
      // }
    ],
    options: {
      colors: ['#426B3C', '#67A85E'],
      dataLabels: {
        enabled: false
      },
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          columnWidth: '50%',
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            enabled: false,
            total: {
              enabled: false,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        }
      },
      xaxis: {
        // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        categories: data?.map(item => `${item.month} ${item.year}`)
      },
      yaxis: {
        title: {
          text: 'Count'
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          formatter: function (val: any) {
            return val;
          }
        }
      },
      legend: {
        position: 'top'
      },
      fill: {
        opacity: 1
      }
    }
  };

  return (
    <div>
      <DChart options={state.options} series={state.series} type="bar" height={350} />
    </div>
  );
};

export default ChartRegisterVerifiedMonthly;
