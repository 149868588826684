const ReligionList = [
  { UUID: 'Islam', name: 'Islam' },
  { UUID: 'Hindu', name: 'Hindu' },
  { UUID: 'Katolik', name: 'Katolik' },
  { UUID: 'Kristen', name: 'Kristen' },
  { UUID: 'Budha', name: 'Budha' },
  { UUID: 'Konghucu', name: 'Konghucu' },
  { UUID: 'Lainnya', name: 'Lainnya' }
];

export default ReligionList;
