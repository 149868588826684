import { Table, Tbody, Td, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useMemberVefiryContext } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';

const TableDataRegister = () => {
  const { userVerification } = useMemberVefiryContext();
  return (
    <div>
      <Theader>
        <Typography fontWeight={600} size={14}>
          Data Register
        </Typography>
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        {/*<Thead>*/}
        {/*  <Tr noBorder>*/}
        {/*    <Th noBorder>*/}
        {/*      <Typography fontWeight={600} size={14} type="primary-invert">*/}
        {/*        Data Register*/}
        {/*      </Typography>*/}
        {/*    </Th>*/}
        {/*    <Th noBorder></Th>*/}
        {/*  </Tr>*/}
        {/*</Thead>*/}
        <Tbody>
          {/*nama lengkap*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Nama Lengkap
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.user.fullName}
              </Typography>
            </Td>
          </Tr>

          {/* email*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Email
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.user.email}
              </Typography>
            </Td>
          </Tr>

          {/*phone */}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Phone
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.user.phone}
              </Typography>
            </Td>
          </Tr>

          {/*register date */}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Register Date
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.user.getCreatedAt}
              </Typography>
            </Td>
          </Tr>

          {/*email verified*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Email Verified
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.user.getVerifiedAt}
              </Typography>
            </Td>
          </Tr>

          {/*trx count*/}
          {/*<Tr>*/}
          {/*  <Td noBorder>*/}
          {/*    <Typography fontWeight={400} size={14} type="tertiary-invert">*/}
          {/*      Trx Count*/}
          {/*    </Typography>*/}
          {/*  </Td>*/}
          {/*  <Td noBorder>*/}
          {/*    <Typography fontWeight={400} size={14} type="primary-invert">*/}
          {/*      : {userVerification?.user.gender}*/}
          {/*    </Typography>*/}
          {/*  </Td>*/}
          {/*</Tr>*/}

          {/*Balance*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Balance
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.user.balance.amount}
              </Typography>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
};

export default TableDataRegister;
