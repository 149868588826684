import { ApiClient } from '@/main/apiClient';
import { StatisticRegisterMember } from '@/domain/constant/api_list';
import MemberChartInterface, {
  MemberChartValue
} from '@/domain/repository/dashboard/interface/member_chart_interface.ts';
import { MemberChartModel } from '@/domain/model/dashboard/member_chart_model.ts';
import { MemberChartMonthlyModel } from '@/domain/model/dashboard/member_chart_model_monthly.ts';

export class MemberChartRepository implements MemberChartInterface {
  api = new ApiClient();

  async statisticRegisterDaily(data: MemberChartValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(StatisticRegisterMember.DAILY, {
            params: data
          })
          .then(result => {
            const data = MemberChartModel.fromJsonList(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async statisticRegisterMonthly(data: MemberChartValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(StatisticRegisterMember.MONTHLY, {
            params: data
          })
          .then(result => {
            const data = MemberChartMonthlyModel.fromJsonList(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
