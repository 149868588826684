import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { UserHeirRepository } from '@/domain/repository/user_heir_repository.ts';
import { UserHeirModel } from '@/domain/model/user_heir_model.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';

interface UserHeirContextProps {
  children: ReactNode;
}

interface UserHeirParams {
  user_uid?: string;
  uid?: string;
  uuid?: string;
  page?: number;
  limit?: number;
}

interface FormUserHeir {
  uid1?: string;
  fullName1?: string;
  phone1?: string;
  relation1?: string;
  uid2?: string;
  fullName2?: string;
  phone2?: string;
  relation2?: string;
}

interface UserHeirContextValue {
  formUserHeir: FormUserHeir;
  setFormUserHeir: Dispatch<SetStateAction<FormUserHeir>>;
  addEditUserHeir: boolean;
  userHeirs: UserHeirModel[];
  handleAddUserHeirModal: ModalObject;
  handleSubmitAddCounterGold: () => void;
  handleAddEditUserHeir: (_data: UserHeirParams) => void;
  handleFetchUserHeir: (_data: UserHeirParams) => void;
  handleSubmitAddEditUserHeir: (_data: UserHeirParams) => void;
  handleDeleteHeir: (_data: UserHeirModel) => void;
}

const StockGoldContext = createContext<UserHeirContextValue | null>(null);

const userHeirRepo = new UserHeirRepository();

const UserHeirProvider: React.FC<UserHeirContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [userHeirs, setUserHeirs] = useState<UserHeirModel[]>([]);

  const [addEditUserHeir, setAddEditUserHeir] = useState(false);
  const [formUserHeir, setFormUserHeir] = useState<FormUserHeir>({
    uid1: '',
    fullName1: '',
    phone1: '',
    relation1: '',
    uid2: '',
    fullName2: '',
    phone2: '',
    relation2: ''
  });

  const handleAddUserHeirModal = useVisibleComponent(false);

  //HANDLE ADD STOCK
  const handleSubmitAddCounterGold = () => {
    alertToast.loadingAlert('');
  };

  //HANDLE FETCH USER HEIR
  const handleFetchUserHeir = async (_data: UserHeirParams) => {
    const x = alertToast.loadingAlert('');

    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      user_uid: _data.user_uid
    };

    return userHeirRepo
      .fetchUserHeir(params)
      .then((result: any) => {
        const res: UserHeirModel[] = result.data;
        setUserHeirs(res);

        if (res.length <= 1) {
          setFormUserHeir({
            uid1: res[0]?.UID,
            fullName1: res[0]?.fullName,
            phone1: res[0]?.phone,
            relation1: res[0]?.relation,
            uid2: res[1]?.UID,
            fullName2: res[1]?.fullName,
            phone2: res[1]?.phone,
            relation2: res[1]?.relation
          });
        }

        if (res.length === 2) {
          setFormUserHeir({
            uid1: res[1]?.UID,
            fullName1: res[1]?.fullName,
            phone1: res[1]?.phone,
            relation1: res[1]?.relation,
            uid2: res[0]?.UID,
            fullName2: res[0]?.fullName,
            phone2: res[0]?.phone,
            relation2: res[0]?.relation
          });
        }

        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE ADD OR EDIT USER HEIR
  const handleAddEditUserHeir = (_data: UserHeirParams) => {
    handleFetchUserHeir({ user_uid: _data.user_uid })
      .then(() => {
        setAddEditUserHeir(!addEditUserHeir);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SUBMIT ADD OR EDIT USER HEIR
  const handleSubmitAddEditUserHeir = (_data: UserHeirParams) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        //if both user heir is null
        if (userHeirs.length === 0) {
          //add heir 1
          const params = {
            full_name: formUserHeir.fullName1,
            phone: formUserHeir.phone1,
            relation: formUserHeir.relation1,
            user_uid: _data.user_uid
          };
          userHeirRepo
            .storeUserHeir(params)
            .then((result: any) => {
              if (formUserHeir.fullName2 !== '') {
                //add heir 2
                const params2 = {
                  full_name: formUserHeir.fullName2,
                  phone: formUserHeir.phone2,
                  relation: formUserHeir.relation2,
                  user_uid: _data.user_uid
                };

                userHeirRepo
                  .storeUserHeir(params2)
                  .then((result2: any) => {
                    alertToast.successAlert('Success');
                    setAddEditUserHeir(false);
                    handleFetchUserHeir({ user_uid: _data.user_uid });
                    alertToast.updateLoading(x);
                  })
                  .catch(error => {
                    alertToast.updateLoading(x);
                    alertToast.errorAlert(error);
                  });
              } else {
                alertToast.successAlert('Success');
                setAddEditUserHeir(false);
                alertToast.updateLoading(x);
                handleFetchUserHeir({ user_uid: _data.user_uid });
              }
            })
            .catch(error => {
              alertToast.updateLoading(x);
              alertToast.errorAlert(error);
            });
        }

        //if  user heir 1 is filled and user heir 2 is null
        if (userHeirs.length === 1) {
          //update heir 1
          const params = {
            uid: formUserHeir.uid1,
            full_name: formUserHeir.fullName1,
            phone: formUserHeir.phone1,
            relation: formUserHeir.relation1,
            user_uid: _data.user_uid
          };
          userHeirRepo
            .updateUserHeir(params)
            .then(() => {
              if (formUserHeir.fullName2 !== '') {
                //add heir 2
                const params2 = {
                  full_name: formUserHeir.fullName2,
                  phone: formUserHeir.phone2,
                  relation: formUserHeir.relation2,
                  user_uid: _data.user_uid
                };

                userHeirRepo
                  .storeUserHeir(params2)
                  .then(() => {
                    alertToast.successAlert('Success');
                    setAddEditUserHeir(false);
                    handleFetchUserHeir({ user_uid: _data.user_uid });
                    alertToast.updateLoading(x);
                  })
                  .catch(error => {
                    alertToast.updateLoading(x);
                    alertToast.errorAlert(error);
                  });
              } else {
                alertToast.successAlert('Success');
                setAddEditUserHeir(false);
                handleFetchUserHeir({ user_uid: _data.user_uid });
                alertToast.updateLoading(x);
              }
            })
            .catch(error => {
              alertToast.updateLoading(x);
              alertToast.errorAlert(error);
            });
        }

        //if both user heir is filled
        if (userHeirs.length === 2) {
          //update heir 1
          const params = {
            uid: formUserHeir.uid1,
            full_name: formUserHeir.fullName1,
            phone: formUserHeir.phone1,
            relation: formUserHeir.relation1,
            user_uid: _data.user_uid
          };
          userHeirRepo
            .updateUserHeir(params)
            .then(() => {
              if (formUserHeir.fullName2 !== '') {
                //add heir 2
                const params2 = {
                  uid: formUserHeir.uid2,
                  full_name: formUserHeir.fullName2,
                  phone: formUserHeir.phone2,
                  relation: formUserHeir.relation2,
                  user_uid: _data.user_uid
                };

                userHeirRepo
                  .updateUserHeir(params2)
                  .then(() => {
                    alertToast.successAlert('Success');
                    setAddEditUserHeir(false);
                    handleFetchUserHeir({ user_uid: _data.user_uid });
                    alertToast.updateLoading(x);
                  })
                  .catch(error => {
                    alertToast.updateLoading(x);
                    alertToast.errorAlert(error);
                  });
              } else {
                alertToast.successAlert('Success');
                setAddEditUserHeir(false);
                handleFetchUserHeir({ user_uid: _data.user_uid });
                alertToast.updateLoading(x);
              }
            })
            .catch(error => {
              alertToast.updateLoading(x);
              alertToast.errorAlert(error);
            });
        }
      }
    });
  };

  //HANDLE DELETE HEIR
  const handleDeleteHeir = (_data: UserHeirModel) => {
    const params = {
      uid: _data.UID
    };

    userHeirRepo.deleteUserHeir(params);
  };

  const contextValue: UserHeirContextValue = {
    handleDeleteHeir,
    handleSubmitAddEditUserHeir,
    formUserHeir,
    setFormUserHeir,
    handleAddEditUserHeir,
    addEditUserHeir,
    userHeirs,
    handleFetchUserHeir,
    handleAddUserHeirModal,
    handleSubmitAddCounterGold
  };

  return <StockGoldContext.Provider value={contextValue}>{children}</StockGoldContext.Provider>;
};

const useUserHeirContext = (): UserHeirContextValue => {
  const context = useContext(StockGoldContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a UserHeirProvider');
  }
  return context;
};

export { UserHeirProvider, useUserHeirContext };
