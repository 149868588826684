import { useEffect, useRef, useState } from 'react';

export default function useVisibleComponent(initialState: boolean) {
  const [state, setState] = useState(initialState ?? false);
  const ref = useRef<HTMLDivElement>(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setState(false);
    }
  };

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setState(false);
    }

    if (document.getElementById('close-modal')?.contains(event.target as Node)) {
      setState(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, state, setState };
}
