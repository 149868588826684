import Swal from 'sweetalert2';

export default function useAlertSweetComponent() {
  const successAlert = (title: string, text: string, time?: number) => {
    Swal.fire({
      title,
      text,
      icon: 'success',
      showConfirmButton: false,
      didOpen: () => {
        setTimeout(
          () => {
            Swal.close();
          },
          time ? time : 1000
        );
      },
      didClose: () => {
        Swal.close();
      }
    });
  };

  const errorAlert = (title: string, text: string) => {
    return Swal.fire({
      title,
      text,
      icon: 'warning',
      showConfirmButton: true
    });
  };

  const warningAlert = (title: string, text: string, confirmButton?: string) => {
    return Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#B91D1B',
      cancelButtonColor: '#6B7280',
      cancelButtonText: 'cancel',
      confirmButtonText: confirmButton ?? 'yes'
    });
  };

  const infoAlert = (title: string, text: string) => {
    Swal.fire({
      title,
      text,
      icon: 'info'
    });
  };

  const questionAlert = (text?: string, confirmButton?: string) => {
    return Swal.fire({
      title: 'Are you sure ?',
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#B91D1B',
      cancelButtonColor: '#6B7280',
      cancelButtonText: 'cancel',
      confirmButtonText: confirmButton ?? 'yes'
    });
  };

  return {
    successAlert,
    errorAlert,
    warningAlert,
    infoAlert,
    questionAlert
  };
}
