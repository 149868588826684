import { Routing } from '@/domain/constant/router_path.ts';
import { FaArrowLeft, FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '@/delivery/ui/admin/settings/admin_access/context/user_context.tsx';
import { useEffect, useState } from 'react';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { TBox } from '@/delivery/components/molecules/table/table.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Checkbox from '@/delivery/components/atoms/checkbox/checkbox.tsx';
import { RoleUserCompanyModel } from '@/domain/model/role_user_company_model.ts';
import { RoleModel } from '@/domain/model/role_model.ts';

const UserDetail = () => {
  const { uuid } = useParams();
  const {
    formRole,
    setFormRole,
    formRoleUserCompany,
    setFormRoleUserCompany,
    user,
    handleShowUserObject,
    roles,
    roleUserCompanies,
    handleFetchRoleUserCompany,
    handleFetchRole,
    handleAssignRole,
    handleDeleteRoleUserCompany
  } = useUserContext();

  const navigate = useNavigate();

  const [rolesFilter, setRolesFilter] = useState<RoleModel[]>([]);

  useEffect(() => {
    if (uuid) {
      handleShowUserObject({ user_uuid: uuid });
    }
  }, [uuid]);

  useEffect(() => {
    if (user) {
      handleFetchRole({});
      handleFetchRoleUserCompany({ user_company_uuid: user?.userCompany[0]?.UUID });
    }
  }, [user]);

  //filter roles from roles User Company
  useEffect(() => {
    if (roles && roleUserCompanies) {
      const rolesFilter = roles.filter(
        itemRole => !roleUserCompanies.some(itemRoleCompany => itemRole.UUID === itemRoleCompany.role.UUID)
      );
      setRolesFilter(rolesFilter);
    }
  }, [roles, roleUserCompanies]);

  return (
    <div className={'mt-[24px] flex flex-col gap-[20px]'}>
      <div
        className={'flex gap-[10px] items-center text-text-primary cursor-pointer'}
        onClick={() => navigate(Routing.SETTINGS.ADMIN_ACCESS)}
      >
        <FaArrowLeft />
        <Typography size={14} fontWeight={500}>
          Back to user
        </Typography>
      </div>
      <div>
        <Typography size={24} fontWeight={600}>
          User Role Detail
        </Typography>
      </div>
      <TBox>
        <div className="flex gap-[20px]">
          <div className={'w-full flex flex-col gap-[20px]'}>
            <span>Role Tersedia</span>
            <TextField search block placeholder={'search'} />
            <div className="border border-text-tertiary-invert p-[10px] rounded-[8px] w-full border-opacity-30 h-[550px] overflow-auto">
              {rolesFilter.map((item: RoleModel) => (
                <Checkbox
                  checked={formRole.includes(item.UUID)}
                  label={item.name}
                  onChange={e => {
                    if (e.target.checked) {
                      setFormRole([...formRole, item.UUID]);
                    } else {
                      setFormRole(prevState => prevState.filter(itemInState => itemInState !== item.UUID));
                    }
                  }}
                />
              ))}
            </div>
          </div>

          {/*button*/}
          <div className={'flex flex-col justify-center gap-[20px]'}>
            <Button
              label={'assign'}
              suffixIcon={<FaChevronRight />}
              onClick={() => handleAssignRole({ user_company_uuid: user?.userCompany[0]?.UUID })}
            />
            <Button
              type={'danger'}
              label={'remove'}
              prefixIcon={<FaChevronLeft />}
              onClick={() => handleDeleteRoleUserCompany({ user_company_uuid: user?.userCompany[0]?.UUID })}
            />
          </div>

          <div className={'w-full flex flex-col gap-[20px]'}>
            <span>Role Digunakan</span>
            <TextField search block placeholder={'search'} />
            <div className="border border-text-tertiary-invert p-[10px] rounded-[8px] w-full border-opacity-30 h-[550px] overflow-auto">
              {roleUserCompanies.map((item: RoleUserCompanyModel) => (
                <Checkbox
                  checked={formRoleUserCompany.includes(item.UUID)}
                  label={item.role.name}
                  onChange={e => {
                    if (e.target.checked) {
                      setFormRoleUserCompany([...formRoleUserCompany, item.UUID]);
                    } else {
                      setFormRoleUserCompany(prevState => prevState.filter(itemInState => itemInState !== item.UUID));
                    }
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </TBox>
    </div>
  );
};

export default UserDetail;
