import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';

import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { GoldRateRepository } from '@/domain/repository/gold_rate_repository.ts';
import { GoldRateModel } from '@/domain/model/gold_rate_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import STATUS_CONSTANT from '@/domain/constant/status_constanat.ts';
import { GoldRateLatestModel } from '@/domain/model/gold_rate_lates_model.ts';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';

interface GoldPriceContextProps {
  children: ReactNode;
}

interface GoldPriceValueInterface {
  uid?: string;
  page?: number;
  limit?: number;
  sell?: number;
  buy?: number;
  status?: string;
  start_date?: string;
  end_date?: string;
}

interface GoldPriceContextValue {
  goldRatesExportData: GoldRateModel[];
  formGoldRateTable: GoldPriceValueInterface;
  setFormGoldRateTable: Dispatch<SetStateAction<GoldPriceValueInterface>>;
  goldRatesCharts: GoldRateModel[];
  loadingRateGold: boolean;
  goldRateLatest?: GoldRateLatestModel;
  formGoldRate: GoldPriceValueInterface;
  setFormGoldRate: Dispatch<SetStateAction<GoldPriceValueInterface>>;
  handleAddGoldPriceModal: ModalObject;
  goldRates: GoldRateModel[];
  goldRatePagination?: PaginationModel;
  handleFetchGoldRate: (_data: GoldPriceValueInterface) => void;
  handleAddGoldRateModal: () => void;
  handleStoreGoldRate: (_data: GoldPriceValueInterface) => void;
  handleGetLatestGoldRate: () => void;
  handleFetchGoldRateCharts: () => void;
  handleFetchDataExportTable: () => void;
}

const GoldPriceContext = createContext<GoldPriceContextValue | null>(null);

const GoldPriceProvider: React.FC<GoldPriceContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const goldRateRepository = new GoldRateRepository();

  const [goldRateLatest, setGoldRateLatest] = useState<GoldRateLatestModel>();
  const [loadingRateGold, setLoadingRateGold] = useState<boolean>(false);

  const [goldRatesExportData, setGoldRatesExportData] = useState<GoldRateModel[]>([]);
  const [goldRates, setGoldRates] = useState<GoldRateModel[]>([]);
  const [goldRatePagination, setGoldRatePagination] = useState<PaginationModel>();

  const [goldRatesCharts, setGoldRatesCharts] = useState<GoldRateModel[]>([]);

  const [formGoldRate, setFormGoldRate] = useState<GoldPriceValueInterface>({});

  const [formGoldRateTable, setFormGoldRateTable] = useState<GoldPriceValueInterface>({});

  const handleAddGoldPriceModal = useVisibleComponent(false);

  //ADD GOLD RATE
  const handleAddGoldRateModal = () => {
    handleAddGoldPriceModal.setState(true);
  };

  //FETCH DATA EXPORT TABLE
  const handleFetchDataExportTable = () => {
    const x = alertToast.loadingAlert('Preparing data export');
    const params = {
      page: 1,
      limit: -1,
      date_range: formGoldRateTable.start_date
        ? [`${formatDateParam(formGoldRateTable.start_date)}`, `${formatDateParam(formGoldRateTable.end_date)}`]
        : null
    };

    goldRateRepository
      .fetchGoldRate(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setGoldRatesExportData(result.data);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //FETCH GOLD RATE
  const handleFetchGoldRate = (_data: GoldPriceValueInterface) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      date_range: formGoldRateTable.start_date
        ? [`${formatDateParam(formGoldRateTable.start_date)}`, `${formatDateParam(formGoldRateTable.end_date)}`]
        : null
    };

    goldRateRepository
      .fetchGoldRate(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setGoldRates(result.data);
        setGoldRatePagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //FETCH GOLD RATE CHARTS
  const handleFetchGoldRateCharts = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      page: 1,
      limit: -1,
      date_range: formGoldRate.start_date
        ? [`${formatDateParam(formGoldRate.start_date)}`, `${formatDateParam(formGoldRate.end_date)}`]
        : null,
      sort: 'id asc'
    };

    goldRateRepository
      .fetchGoldRate(params)
      .then((result: any) => {
        setGoldRatesCharts(result.data);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //STORE GOLD RATE
  const handleStoreGoldRate = () => {
    const x = alertToast.loadingAlert('');
    const params = {
      sell: formGoldRate.sell,
      buy: formGoldRate.buy,
      status: STATUS_CONSTANT.active
    };

    goldRateRepository
      .storeGoldRate(params)
      .then(() => {
        handleFetchGoldRate({ page: 1 });
        alertToast.updateLoading(x);
        alertToast.successAlert('Success');
        handleAddGoldPriceModal.setState(false);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //GET LATEST GOLD RATE
  const handleGetLatestGoldRate = () => {
    setLoadingRateGold(true);

    goldRateRepository
      .latestGoldRate()
      .then((result: any) => {
        setLoadingRateGold(false);
        handleFetchGoldRate({ page: 1 });
        setGoldRateLatest(result);
      })
      .catch(error => {
        setLoadingRateGold(false);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: GoldPriceContextValue = {
    goldRatesExportData,
    handleFetchDataExportTable,
    formGoldRateTable,
    setFormGoldRateTable,
    goldRatesCharts,
    loadingRateGold,
    goldRateLatest,
    handleGetLatestGoldRate,
    handleStoreGoldRate,
    handleFetchGoldRate,
    handleAddGoldPriceModal,
    goldRates,
    goldRatePagination,
    handleAddGoldRateModal,
    formGoldRate,
    setFormGoldRate,
    handleFetchGoldRateCharts
  };

  return <GoldPriceContext.Provider value={contextValue}>{children}</GoldPriceContext.Provider>;
};

const useGoldPriceContext = (): GoldPriceContextValue => {
  const context = useContext(GoldPriceContext);
  if (!context) {
    throw new Error('useGoldPriceContext must be used within a GoldPriceProvider');
  }
  return context;
};

export { GoldPriceProvider, useGoldPriceContext };
