export const AFFILIATE_TYPE = {
  MEMBER: 'MEMBER',
  REGULAR: 'REGULAR',
  COMMUNITY: 'COMMUNITY',
  RESELLER: 'RESELLER',
  EXCLUSIVE: 'EXCLUSIVE'
};

export const AFFILIATE_TYPE_LIST = [
  { UUID: 'MEMBER', name: 'MEMBER' },
  { UUID: 'REGULAR', name: 'REGULAR' },
  { UUID: 'COMMUNITY', name: 'COMMUNITY' },
  { UUID: 'RESELLER', name: 'RESELLER' },
  { UUID: 'EXCLUSIVE', name: 'EXCLUSIVE' }
];
