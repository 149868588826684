class TransactionSummarySingleModel {
  success: number;
  pending: number;
  gramSuccess: number;
  gramPending: number;
  fiatSuccess: number;
  fiatPending: number;
  total: number;
  totalGram: number;
  totalFiat: number;
  gram: number;
  gramLocked: number;
  user: number;

  constructor(
    success: number,
    pending: number,
    gramSuccess: number,
    gramPending: number,
    fiatSuccess: number,
    fiatPending: number,
    total: number,
    totalGram: number,
    totalFiat: number,
    gram: number,
    gramLocked: number,
    user: number
  ) {
    this.success = success;
    this.pending = pending;
    this.gramSuccess = gramSuccess;
    this.gramPending = gramPending;
    this.fiatSuccess = fiatSuccess;
    this.fiatPending = fiatPending;
    this.total = total;
    this.totalGram = totalGram;
    this.totalFiat = totalFiat;
    this.gram = gram;
    this.gramLocked = gramLocked;
    this.user = user;
  }

  public static fromJson(data: any): TransactionSummarySingleModel | any {
    try {
      return new TransactionSummarySingleModel(
        data.success,
        data.pending,
        data.gram_success,
        data.gram_pending,
        data.fiat_success,
        data.fiat_pending,
        data.total,
        data.total_gram,
        data.total_fiat,
        data.gram,
        data.gram_locked,
        data.user
      );
    } catch (e) {
      return null;
    }
  }
}

//TRANSACTION SUMMARY MODEL
export default class TransactionSummaryModel {
  totalTransaction: TransactionSummarySingleModel;
  totalBuy: TransactionSummarySingleModel;
  totalSell: TransactionSummarySingleModel;
  totalDeposit: TransactionSummarySingleModel;
  dailyTransaction: TransactionSummarySingleModel;
  dailySell: TransactionSummarySingleModel;
  dailyBuy: TransactionSummarySingleModel;
  average: TransactionSummarySingleModel;
  averageBuy: TransactionSummarySingleModel;
  averageSell: TransactionSummarySingleModel;
  dca: TransactionSummarySingleModel;
  dcaGram: number;
  dcaGramLocked: number;
  dcaUser: number;

  constructor(
    totalTransaction: TransactionSummarySingleModel,
    totalBuy: TransactionSummarySingleModel,
    totalSell: TransactionSummarySingleModel,
    totalDeposit: TransactionSummarySingleModel,
    dailyTransaction: TransactionSummarySingleModel,
    dailySell: TransactionSummarySingleModel,
    dailyBuy: TransactionSummarySingleModel,
    average: TransactionSummarySingleModel,
    averageBuy: TransactionSummarySingleModel,
    averageSell: TransactionSummarySingleModel,
    dca: TransactionSummarySingleModel,
    dcaGram: number,
    dcaGramLocked: number,
    dcaUser: number
  ) {
    this.totalTransaction = totalTransaction;
    this.totalBuy = totalBuy;
    this.totalSell = totalSell;
    this.totalDeposit = totalDeposit;
    this.dailyTransaction = dailyTransaction;
    this.dailySell = dailySell;
    this.dailyBuy = dailyBuy;
    this.average = average;
    this.averageBuy = averageBuy;
    this.averageSell = averageSell;
    this.dca = dca;
    this.dcaGram = dcaGram;
    this.dcaGramLocked = dcaGramLocked;
    this.dcaUser = dcaUser;
  }

  public static fromJson(data: any): TransactionSummaryModel | any {
    try {
      return new TransactionSummaryModel(
        data.total_transaction ? TransactionSummarySingleModel.fromJson(data.total_transaction) : null,
        data.total_buy ? TransactionSummarySingleModel.fromJson(data.total_buy) : null,
        data.total_sell ? TransactionSummarySingleModel.fromJson(data.total_sell) : null,
        data.total_deposit ? TransactionSummarySingleModel.fromJson(data.total_deposit) : null,
        data.daily_transaction ? TransactionSummarySingleModel.fromJson(data.daily_transaction) : null,
        data.daily_sell ? TransactionSummarySingleModel.fromJson(data.daily_sell) : null,
        data.daily_buy ? TransactionSummarySingleModel.fromJson(data.daily_buy) : null,
        data.average ? TransactionSummarySingleModel.fromJson(data.average) : null,
        data.average_buy ? TransactionSummarySingleModel.fromJson(data.average_buy) : null,
        data.average_sell ? TransactionSummarySingleModel.fromJson(data.average_sell) : null,
        data.dca ? TransactionSummarySingleModel.fromJson(data.dca) : null,
        data.dca.gram,
        data.dca.gram_locked,
        data.dca.user
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): TransactionSummaryModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(TransactionSummaryModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
