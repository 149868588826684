import Button from '@/delivery/components/atoms/button/button';
import Card from '@/delivery/components/atoms/card/card';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Typography from '@/delivery/components/atoms/typography/typography';
import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table';

const CheckData = () => {
  return (
    <div className="flex flex-col gap-[24px]">
      <Card>
        <div className="flex justify-between gap-[16px] items-end">
          <TextField block placeholder="Enter Email" label="Email" />
          <Button className="w-[150px]" label="Check Data" />
          {/* <div className="w-[150px]"></div> */}
        </div>
      </Card>
      <div>
        <Theader>
          <Typography size={18} fontWeight={600}>
            Data Balance
          </Typography>
        </Theader>
        <Table className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>User ID</Th>
              <Th>Gramasi</Th>
              <Th>Incoming</Th>
              <Th>Outgoing</Th>
              <Th>Conv Rate</Th>
              <Th>Trx ID</Th>
              <Th>Gram Change</Th>
              <Th>Rate ID</Th>
              <Th>Type</Th>
              <Th>Lock User</Th>
              <Th>Lock Dinaran</Th>
              <Th>Lock Emas Impian</Th>
              <Th>Created At</Th>
              <Th>Updated At</Th>
            </Tr>
          </Thead>
          <Tbody>
            {[1].map((item, index) => (
              <Tr key={index}>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
      <div>
        <Theader>
          <Typography size={18} fontWeight={600}>
            Data Transaction
          </Typography>
        </Theader>
        <Table className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Sender</Th>
              <Th>Receiver</Th>
              <Th>Status</Th>
              <Th>Type</Th>
              <Th>Amount</Th>
              <Th>Gramasi</Th>
              <Th>Rate ID</Th>
              <Th>Comments</Th>
            </Tr>
          </Thead>
          <Tbody>
            {[1].map((item, index) => (
              <Tr key={index}>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
      <div>
        <Theader>
          <Typography size={18} fontWeight={600}>
            Data Transaction iPaymu
          </Typography>
        </Theader>
        <Table className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>User ID</Th>
              <Th>Gramasi</Th>
              <Th>Incoming</Th>
              <Th>Outgoing</Th>
              <Th>Conv Rate</Th>
              <Th>Trx ID</Th>
              <Th>Gram Change</Th>
              <Th>Rate ID</Th>
              <Th>Type</Th>
              <Th>Lock User</Th>
              <Th>Lock Dinaran</Th>
              <Th>Lock Emas Impian</Th>
              <Th>Created At</Th>
              <Th>Updated At</Th>
            </Tr>
          </Thead>
          <Tbody>
            {[1].map((item, index) => (
              <Tr key={index}>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default CheckData;
