import Navbar from '@/delivery/components/organisms/navbar/navbar';
import CounterOffline from './counter_offline';
import Button from '@/delivery/components/atoms/button/button';
import DialogAddCounter from './dialog/dialog_add_counter_offline';
import { useCounterOfflineContext } from './context/counter_offline_context';
import Icon from '@/domain/constant/icon.tsx';

const CounterOfflinePage = () => {
  const { handleAddCounterOfflineModal } = useCounterOfflineContext();
  return (
    <div>
      <Navbar label={'Counter Offline'}>
        <Button
          prefixIcon={Icon.plus}
          label="Add Counter"
          onClick={() => handleAddCounterOfflineModal.setState(true)}
        />
      </Navbar>
      <CounterOffline />

      {handleAddCounterOfflineModal.state && <DialogAddCounter modal={handleAddCounterOfflineModal} />}
    </div>
  );
};

export default CounterOfflinePage;
