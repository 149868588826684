import Navbar from '@/delivery/components/organisms/navbar/navbar';
import AccountRequest from './account_request';

const AccountRequestPage = () => {
  return (
    <div>
      <Navbar label={'Account Request'}></Navbar>
      <AccountRequest />
    </div>
  );
};

export default AccountRequestPage;
