import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import { useCounterOfflineContext } from '../context/counter_offline_context';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import DaysList from '@/domain/constant/days_list.ts';
import { STATUS_LIST } from '@/domain/constant/status.ts';
import { useEffect, useState } from 'react';
import { TabContent, TabPane2, TabPaneList2 } from '@/delivery/components/atoms/tab_pane/tab_pane.tsx';

const DialogUpdateCounter = (props: ModalDialogProps) => {
  const { handleUpdateCounterOffLine, handleUpdateCounterOfflineModal } = useCounterOfflineContext();

  const [tabPage, setTabPage] = useState(0);
  const data = [
    {
      label: 'Detail',
      index: 0
    },
    {
      label: 'Address',
      index: 1
    }
  ];

  return (
    <ModalDialog size="small" title="Update Counter" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TabPane2>
          {data?.map((item, index) => (
            <TabPaneList2 label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
          ))}
        </TabPane2>

        <div>
          <TabContent index={0} tab={tabPage}>
            <DetailOfflineCounter />
          </TabContent>
          <TabContent index={1} tab={tabPage}>
            <AddressOfflineCounter />
          </TabContent>
        </div>
      </div>
      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleUpdateCounterOfflineModal.setState(false)} />
        <Button label="Save" onClick={() => handleUpdateCounterOffLine()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

const DetailOfflineCounter = () => {
  const { formCounterOffline, setFormCounterOffline } = useCounterOfflineContext();

  return (
    <div className="flex flex-col gap-[20px]">
      <TextField
        value={formCounterOffline.name}
        required
        label="Nama Counter"
        placeholder="Nama Counter"
        block
        onChange={e => setFormCounterOffline({ ...formCounterOffline, name: e.target.value })}
      />

      <TextField
        value={formCounterOffline.pic}
        label="PIC"
        placeholder="Nama PIC"
        block
        onChange={e => setFormCounterOffline({ ...formCounterOffline, pic: e.target.value })}
      />

      <Select2
        value={formCounterOffline.days}
        required
        isMulti
        placeholder="Days"
        options={DaysList.map(item => {
          return {
            label: item.name,
            value: item.UUID
          };
        })}
        label="Days"
        onChange={e => {
          const daysString: string[] = e.map((day: any) => day.value);
          setFormCounterOffline({ ...formCounterOffline, days: daysString });
        }}
      />
      <div className={'flex justify-between gap-[20px]'}>
        <TextField
          value={formCounterOffline.start_hour}
          block
          type={'time'}
          label={'Start Hour'}
          onChange={e => setFormCounterOffline({ ...formCounterOffline, start_hour: e.target.value })}
        />
        <TextField
          value={formCounterOffline.end_hour}
          block
          type={'time'}
          label={'End Hour'}
          onChange={e => setFormCounterOffline({ ...formCounterOffline, end_hour: e.target.value })}
        />
      </div>
      <TextField
        value={formCounterOffline.phone}
        placeholder={'phone'}
        label="Phone"
        block
        type="number"
        onChange={e => setFormCounterOffline({ ...formCounterOffline, phone: e.target.value })}
      />
      <TextField
        value={formCounterOffline.email}
        type={'email'}
        required
        placeholder={'email'}
        label="Email"
        block
        onChange={e => setFormCounterOffline({ ...formCounterOffline, email: e.target.value })}
      />
      <Select2
        value={formCounterOffline.status}
        isSearchable={false}
        required
        placeholder="Select status"
        options={STATUS_LIST.map(item => {
          return {
            label: item.name,
            value: item.UUID
          };
        })}
        label="Status"
        onChange={e => setFormCounterOffline({ ...formCounterOffline, status: e.value })}
      />
    </div>
  );
};

const AddressOfflineCounter = () => {
  const {
    handleFetchProvince,
    provinces,
    cities,
    districts,
    villages,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    villageID,
    setVillageID,

    formCounterOffline,
    setFormCounterOffline
  } = useCounterOfflineContext();

  useEffect(() => {
    handleFetchProvince();
  }, []);

  //fetch cities
  useEffect(() => {
    if (provinceID !== null) {
      handleFetchCities();
    }
  }, [provinceID]);

  //fetch district
  useEffect(() => {
    if (cityID !== 0) {
      handleFetchDistricts();
    }
  }, [cityID]);

  //fetch village
  useEffect(() => {
    if (districtID > 0) {
      handleFetchVillage();
    }
  }, [districtID]);

  return (
    <div className="flex flex-col gap-[20px]">
      <Select2
        required
        value={provinceID?.toString()}
        block
        label={'Provinsi'}
        options={provinces?.map(item => {
          return {
            label: item.name,
            value: item.ID.toString()
          };
        })}
        onChange={e => {
          setProvinceID(parseInt(e.value));
        }}
      />
      <Select2
        required
        value={cityID.toString()}
        block
        label={'Kota/Kab'}
        options={cities?.map(item => {
          return {
            label: item.name,
            value: item.ID.toString()
          };
        })}
        onChange={e => setCityID(parseInt(e.value))}
      />
      <Select2
        value={districtID.toString()}
        required
        block
        label={'Kecamatan'}
        options={districts?.map(item => {
          return {
            label: item.name,
            value: item.ID.toString()
          };
        })}
        onChange={e => setDistrictID(parseInt(e.value))}
      />
      <Select2
        value={villageID.toString()}
        required
        block
        label={'Kelurahan'}
        options={villages?.map(item => {
          return {
            label: item.name,
            value: item.ID.toString()
          };
        })}
        onChange={e => setVillageID(parseInt(e.value))}
      />
      <TextField
        value={formCounterOffline.full_address}
        required
        block
        label={'Alamat Lengkap Counter'}
        onChange={e =>
          setFormCounterOffline({
            ...formCounterOffline,
            full_address: e.target.value
          })
        }
      />
      <TextField
        value={formCounterOffline.zip_code}
        required
        type={'number'}
        block
        label={'Kode POS'}
        onChange={e => setFormCounterOffline({ ...formCounterOffline, zip_code: e.target.value })}
      />
    </div>
  );
};

export default DialogUpdateCounter;
