import { useEffect } from 'react';
import MasterMemberAffiliatePage from '@/delivery/ui/admin/master/member_affiliate';
import { AffiliateProvider } from '@/delivery/ui/admin/master/member_affiliate/context/member_affiliate_context.tsx';

const ViewMasterMemberAffiliate = () => {
  useEffect(() => {
    document.title = 'Master Member Affiliate';
  }, []);

  return (
    <div>
      <AffiliateProvider>
        <MasterMemberAffiliatePage />
      </AffiliateProvider>
    </div>
  );
};

export default ViewMasterMemberAffiliate;
