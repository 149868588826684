import { useEffect } from 'react';
import NotifyAppPage from '@/delivery/ui/admin/web_admin/notify_app';
import { NotifyAppProvider } from '@/delivery/ui/admin/web_admin/notify_app/context/notify_app_context.tsx';

const ViewNotifyApp = () => {
  useEffect(() => {
    document.title = 'Notify App';
  }, []);

  return (
    <div>
      <NotifyAppProvider>
        <NotifyAppPage />
      </NotifyAppProvider>
    </div>
  );
};

export default ViewNotifyApp;
