import { Table, TBox, Td, Th, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useHistoryContext } from '@/delivery/ui/admin/transaction/take_gold/history/context/history_context.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import CONFIRM_FLOW_TYPE from '@/domain/constant/history/confirm_flow_type.ts';
import { useParams } from 'react-router-dom';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';

const DataPrintGoldCounter = () => {
  const { uid } = useParams();
  const { handleConfirmPrintGoldPickUp, history } = useHistoryContext();

  return (
    <div>
      <Theader>
        <Typography fontWeight={600} size={16} type="primary-invert">
          Data Cetak Emas
        </Typography>

        {history?.status !== STATUS_TRANSACTION.CANCEL && history?.status !== STATUS_TRANSACTION.SUCCESS ? (
          <Button
            label={'Konfirmasi Pengambilan Emas'}
            onClick={() => handleConfirmPrintGoldPickUp({ flow: CONFIRM_FLOW_TYPE.FINISH, trx_uid: uid })}
          />
        ) : (
          ''
        )}
      </Theader>
      <TBox>
        <Table className="rounded-b-[8px]">
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  No. Ref
                </Typography>
              </div>
            </Th>
            <Td className={'w-full'}>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.referenceID}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Counter
                </Typography>
              </div>
            </Th>
            <Td className={'w-full'}>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.trxPrint[0]?.offlineCounter?.name || '-'}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Vending
                </Typography>
              </div>
            </Th>
            <Td className={'w-full'}>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.trxVending?.vendingMachine.name || '-'}
              </Typography>
            </Td>
          </Tr>
          {history?.trxShipping ? (
            <Tr>
              <Th>
                <div className={'text-nowrap'}>
                  <Typography fontWeight={500} size={12} type="tertiary-invert">
                    Tanggal Sampai
                  </Typography>
                </div>
              </Th>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {history?.trxShipping?.getReceivedAt}
                </Typography>
              </Td>
            </Tr>
          ) : (
            <Tr>
              <Th>
                <div className={'text-nowrap'}>
                  <Typography fontWeight={500} size={12} type="tertiary-invert">
                    Tanggal Pengambilan
                  </Typography>
                </div>
              </Th>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {history?.trxPrint[0]?.getPickUpDate}
                </Typography>
              </Td>
            </Tr>
          )}

          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Type
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={500} size={12} type="tertiary-invert">
                {history?.trxPrint[0]?.type}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Status
                </Typography>
              </div>
            </Th>
            <Td>
              <Chips type={history?.trxPrint[0]?.getStatusColor} label={history?.trxPrint[0]?.status} />
            </Td>
          </Tr>
        </Table>
      </TBox>
    </div>
  );
};

export default DataPrintGoldCounter;
