export const MEDIA_PATH = {
  SELFIE: 'selfie',
  IDENTITY: 'identity',
  COMPANY_REGISTRATION_NUMBER: 'company_registration_number',
  COMPANY_DEED: 'company_deed',
  TAX_PAYER_IDENTIFICATION_NUMBER: 'tax_payer_identification_number',
  CHECKING_ACCOUNT: 'checking_account',
  ICON_PAYMENT_CHANNEL: 'icon_payment_channel',

  NOTIFICATION: 'notification'
};

export default MEDIA_PATH;
