import Card from '@/delivery/components/atoms/card/card';
import { TabContent, TabPane2, TabPaneList2 } from '@/delivery/components/atoms/tab_pane/tab_pane';
import { useState } from 'react';

//tabs
import RoleTab from '@/delivery/ui/admin/settings/admin_access/tabs/role.tsx';
import DialogUpdateRole from '@/delivery/ui/admin/settings/admin_access/dialog/dialog_update_role.tsx';
import { useRoleeContext } from '@/delivery/ui/admin/settings/admin_access/context/role_context.tsx';
import DialogAddRole from '@/delivery/ui/admin/settings/admin_access/dialog/dialog_add_role.tsx';
// import PermissionTab from '@/delivery/ui/admin/settings/admin_access/tabs/permission.tsx';
import DialogAddPermission from '@/delivery/ui/admin/settings/admin_access/dialog/dialog_add_permission.tsx';
import { usePermissioneContext } from '@/delivery/ui/admin/settings/admin_access/context/permission_context.tsx';
import DialogUpdatePermission from '@/delivery/ui/admin/settings/admin_access/dialog/dialog_update_permission.tsx';
import AccessControllerTab from '@/delivery/ui/admin/settings/admin_access/tabs/access_controller.tsx';
import DialogCreateUserAdmin from '@/delivery/ui/admin/settings/admin_access/dialog/user/dialog_create_user_admin.tsx';
import { useUserContext } from '@/delivery/ui/admin/settings/admin_access/context/user_context.tsx';
import DialogUpdateUserAdmin from '@/delivery/ui/admin/settings/admin_access/dialog/user/dialog_update_user_admin.tsx';
import UserTab from '@/delivery/ui/admin/settings/admin_access/tabs/user.tsx';

const AdminAccess = () => {
  const { handleUpdateUserModal, handleAddUserModal } = useUserContext();
  const { handleUpdateRoleModal, handleAddRoleModal } = useRoleeContext();
  const { handleUpdatePermissionModal, handleAddPermissionModal } = usePermissioneContext();

  const [tabPage, setTabPage] = useState(0);
  const data = [
    {
      label: 'USER',
      index: 0
      // handleClick: () => {}
    },
    {
      label: 'ACCESS CONTROLLER',
      index: 1
      // handleClick: () => {}
    },
    {
      label: 'ROLE',
      index: 2
      // handleClick: () => {}
    }
    // {
    //   label: 'PERMISSION',
    //   index: 3
    //   // handleClick: () => {}
    // }
  ];

  return (
    <div>
      <TabPane2>
        {data?.map((item, index) => (
          <TabPaneList2 label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
        ))}
      </TabPane2>
      <div className={'mt-[20px]'}></div>
      <Card>
        <div>
          <TabContent index={0} tab={tabPage}>
            <UserTab key={0} />
          </TabContent>
          <TabContent index={1} tab={tabPage}>
            <AccessControllerTab key={1} />
          </TabContent>
          <TabContent index={2} tab={tabPage}>
            <RoleTab key={2} />
          </TabContent>
          {/*<TabContent index={3} tab={tabPage}>*/}
          {/*  <PermissionTab key={3} />*/}
          {/*</TabContent>*/}
        </div>
      </Card>

      {/*update user*/}
      {handleUpdateUserModal.state && <DialogUpdateUserAdmin modal={handleUpdateUserModal} />}

      {/*add user*/}
      {handleAddUserModal.state && <DialogCreateUserAdmin modal={handleAddUserModal} />}

      {/*add custom access*/}
      {handleAddPermissionModal.state && <DialogAddPermission modal={handleAddPermissionModal} />}

      {/*update custom access*/}
      {handleUpdatePermissionModal.state && <DialogUpdatePermission modal={handleUpdatePermissionModal} />}

      {/*update role modal*/}
      {handleUpdateRoleModal.state && <DialogUpdateRole modal={handleUpdateRoleModal} />}

      {/*add role modal*/}
      {handleAddRoleModal.state && <DialogAddRole modal={handleAddRoleModal} />}
    </div>
  );
};

export default AdminAccess;
