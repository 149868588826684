import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import TypeFee, { TypeFeeConst } from '@/domain/constant/type_fee.tsx';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGoldPieceContext } from '@/delivery/ui/admin/master/gold_piece/context/gold_piece_context.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';

const DialogAddGoldPieceFee = (props: ModalDialogProps) => {
  const { uid } = useParams();
  const { handleStoreGoldPieceFee, setFormGoldPieceFee, formGoldPieceFee, handleAddGoldPieceFeeModal } =
    useGoldPieceContext();

  useEffect(() => {
    setFormGoldPieceFee({});
  }, []);

  useEffect(() => {
    setFormGoldPieceFee({ gold_piece_uid: uid });
  }, [uid]);

  return (
    <ModalDialog overflow={false} size="small" title="Add Gold Piece Fee" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Select2
          label={'Type'}
          options={TypeFee.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          onChange={e => setFormGoldPieceFee({ ...formGoldPieceFee, type: e.value })}
        />

        {formGoldPieceFee.type === TypeFeeConst.FLAT ? (
          <TextField
            type={'currency'}
            label={'Fee (Rp)'}
            block
            onChange={(e: any) => {
              setFormGoldPieceFee({
                ...formGoldPieceFee,
                fee: e.floatValue
              });
            }}
          />
        ) : (
          ''
        )}

        {formGoldPieceFee.type === TypeFeeConst.PERCENT ? (
          <TextField
            type={'number'}
            label={'Fee (%)'}
            block
            onChange={(e: any) => {
              setFormGoldPieceFee({
                ...formGoldPieceFee,
                fee: parseInt(e.target.value)
              });
            }}
          />
        ) : (
          ''
        )}

        <Datepicker
          value={formGoldPieceFee.effective_date_start}
          label={'Start Date'}
          block
          onChange={(e: any) => {
            setFormGoldPieceFee({
              ...formGoldPieceFee,
              effective_date_start: e
            });
          }}
        />

        <Datepicker
          value={formGoldPieceFee.effective_date_end}
          label={'End Date'}
          block
          onChange={(e: any) => {
            setFormGoldPieceFee({
              ...formGoldPieceFee,
              effective_date_end: e
            });
          }}
        />
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleAddGoldPieceFeeModal.setState(false)} />
        <Button block label="Save" onClick={() => handleStoreGoldPieceFee()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddGoldPieceFee;
