import { useEffect } from 'react';
import AccessStatisticPage from '@/delivery/ui/admin/dashboard/access_statistic';

const ViewDashboardAccessStatistic = () => {
  useEffect(() => {
    document.title = 'Statistic Asset';
  }, []);

  return (
    <div>
      <AccessStatisticPage />
    </div>
  );
};

export default ViewDashboardAccessStatistic;
