import { Table, TBox, Td, Th, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useHistoryContext } from '@/delivery/ui/admin/transaction/take_gold/history/context/history_context.tsx';

const DataMemberHistory = () => {
  const { userAddress, history } = useHistoryContext();

  return (
    <div>
      <Theader>
        <Typography fontWeight={600} size={16} type="primary-invert">
          Detail Member
        </Typography>
      </Theader>
      <TBox>
        <Table className="rounded-b-[8px]">
          <Tr>
            <Th>
              <Typography fontWeight={500} size={12} type="tertiary-invert">
                Nama
              </Typography>
            </Th>
            <Td className={'w-full'}>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.user.getFullName}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>Email</Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.user.email}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <Typography fontWeight={500} size={12} type="tertiary-invert">
                Phone
              </Typography>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.user.phone}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Provinsi
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {userAddress?.address?.province?.name}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Kabupaten
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {userAddress?.address?.city?.name}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Kecamatan
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {userAddress?.address?.district?.name}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Kelurahan
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {userAddress?.address?.village?.name}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Kode Pos
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {userAddress?.address?.zipCode}
              </Typography>
            </Td>
          </Tr>
        </Table>
      </TBox>
    </div>
  );
};

export default DataMemberHistory;
