import Button from '@/delivery/components/atoms/button/button.tsx';
import { HiOutlineChevronUp, HiOutlineChevronDown } from 'react-icons/hi2';
import Checkbox from '@/delivery/components/atoms/checkbox/checkbox.tsx';
import { useAccessControllereContext } from '@/delivery/ui/admin/settings/admin_access/context/access_controller_context.tsx';
import { useState } from 'react';
import { AccessItem } from '@/infrastructure/helper/getAccessItems.ts';

interface AvailableAccessProps {
  data: AccessItem;
}

export const AvailableAccess = (props: AvailableAccessProps) => {
  const { data } = props;

  const [open, setOpen] = useState<boolean>(false);

  const { formAvailableAccess, setFormAvailableAccess } = useAccessControllereContext();

  return (
    <div>
      <div className={'border border-border-tertiary p-[10px] m-[10px] rounded-[8px]'}>
        <div className={'flex'}>
          <Button
            iconButton
            icon={open ? <HiOutlineChevronDown /> : <HiOutlineChevronUp />}
            onClick={() => setOpen(!open)}
          />
          <Checkbox
            checked={data.access_list.every(item => formAvailableAccess.includes(item))}
            fontWeight={'bold'}
            label={data.access}
            onChange={e => {
              if (e.target.checked) {
                setFormAvailableAccess(prevState => [...prevState, ...data.access_list]);
              } else {
                data.access_list.forEach(item => {
                  setFormAvailableAccess(prevState => prevState.filter(itemInState => itemInState !== item));
                });
              }
            }}
          />
          {/*<span>{item.access}</span>*/}
        </div>

        {/*LIST ACCESS*/}
        <div className={`ml-[50px] ${!open ? 'hidden' : ''}`}>
          {data.access_list.map((itemList, index) => (
            <Checkbox
              checked={formAvailableAccess.includes(itemList)}
              id={itemList}
              label={itemList}
              onChange={e => {
                if (e.target.checked) {
                  setFormAvailableAccess(prevState => [...prevState, itemList]);
                } else {
                  setFormAvailableAccess(prevState => prevState.filter(item => item !== itemList));
                }
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AvailableAccess;
