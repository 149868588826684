import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';
import { useBusinessAccountContext } from '@/delivery/ui/admin/data_member/member_list/context/business_account_context.tsx';
import { useEffect } from 'react';
import DialogUpdateMasterBank from '@/delivery/ui/admin/data_member/member_list/detail/tabs/business_account/dialog/dialog_update_master_bank.tsx';

const TableBankAccountBusiness = () => {
  const { formCorporate, handleEditMasterBankModal, masterBank, corporateVerify, handleShowCorporateVerification } =
    useBusinessAccountContext();

  useEffect(() => {
    if (formCorporate.uid) {
      handleShowCorporateVerification();
    }
  }, [formCorporate]);

  return (
    <div>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr noBorder>
            <Th noBorder>
              <Typography fontWeight={600} size={14} type="primary-invert">
                Akun Bank
              </Typography>
            </Th>
            <Th noBorder>
              <div className={'justify-end flex'}>
                <span
                  className={'text-text-primary font-[600] text-[14px] cursor-pointer'}
                  onClick={() => handleEditMasterBankModal.setState(true)}
                >
                  Edit
                </span>
              </div>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {/*bank name*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Bank Name
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {masterBank?.name}
              </Typography>
            </Td>
          </Tr>

          {/*bank acc no*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Bank Acc No
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {corporateVerify?.accountNumber}
              </Typography>
            </Td>
          </Tr>

          {/*Bank acc holder*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Bank Acc Holder
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {corporateVerify?.accountHolderName}
              </Typography>
            </Td>
          </Tr>

          {/*buku tabungan*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Buku Tabungan
              </Typography>
            </Td>
            <Td noBorder>
              <div className={'flex gap-[20px] mr-[20px] items-center'}>
                <Typography fontWeight={400} size={14} type="primary-invert">
                  :
                </Typography>
                <Image url={corporateVerify?.getCheckingAccountUrl} width={200} />
              </div>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      {handleEditMasterBankModal.state && <DialogUpdateMasterBank modal={handleEditMasterBankModal} />}
    </div>
  );
};

export default TableBankAccountBusiness;
