import { useEffect } from 'react';
import MemberPage from '@/delivery/ui/admin/dashboard/member';
import { DashboardMemberProvider } from '@/delivery/ui/admin/dashboard/member/context/dashbord_member_context.tsx';

const ViewDashboardMember = () => {
  useEffect(() => {
    document.title = 'Member';
  }, []);

  return (
    <div>
      <DashboardMemberProvider>
        <MemberPage />
      </DashboardMemberProvider>
    </div>
  );
};

export default ViewDashboardMember;
