import { Table, Tbody, Td, Th, Thead, Theader, Tr, TBox } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import TableBusinessInfo from '@/delivery/ui/admin/data_member/member_list/detail/tabs/business_account/table_business_info.tsx';
import TableBankAccountBusiness from '@/delivery/ui/admin/data_member/member_list/detail/tabs/business_account/table_bank_account.tsx';
import TableDocumentBusiness from '@/delivery/ui/admin/data_member/member_list/detail/tabs/business_account/table_document.tsx';
import TableBusinessAddress from '@/delivery/ui/admin/data_member/member_list/detail/tabs/business_account/table_bussines_address.tsx';
// import TableLetterAddress from '@/delivery/ui/admin/data_member/member_list/detail/tabs/business_account/table_letter_address.tsx';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';
import { useEffect } from 'react';
import { useBusinessAccountContext } from '@/delivery/ui/admin/data_member/member_list/context/business_account_context.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';

const BusinessAccount = () => {
  const { user } = useMemberListContext();
  const { handleShowCorporate, corporate } = useBusinessAccountContext();

  useEffect(() => {
    if (user?.corporate) {
      handleShowCorporate({ uid: user?.corporate.UID });
    }
  }, [user?.corporate]);

  return (
    <div>
      {user?.corporate ? (
        <>
          <div>
            <Theader>
              <div>
                <Typography size={14} fontWeight={600}>
                  Data Bisnis
                </Typography>
              </div>
            </Theader>
            <Table noBorder className="rounded-b-[8px]">
              <Thead>
                <Tr>
                  <Th>Member</Th>
                  <Th>Penanggung Jawab</Th>
                  {/*<Th>Komisi</Th>*/}
                  {/*<Th>Sales PIC</Th>*/}
                  <Th>Request Date</Th>
                  <Th>Status</Th>
                  {/*<Th>Approved By</Th>*/}
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Typography fontWeight={600} size={12} type="primary-invert">
                      {corporate?.name}
                    </Typography>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {corporate?.phoneCorporate}
                    </Typography>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {corporate?.emailCorporate}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={12} type="primary-invert">
                      {corporate?.picName}
                    </Typography>
                  </Td>
                  {/*<Td>*/}
                  {/*  <Typography fontWeight={400} size={12} type="primary-invert">*/}
                  {/*    //*/}
                  {/*  </Typography>*/}
                  {/*</Td>*/}
                  {/*<Td>*/}
                  {/*  <Typography fontWeight={400} size={12} type="primary-invert">*/}
                  {/*    //*/}
                  {/*  </Typography>*/}
                  {/*</Td>*/}
                  <Td>
                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {corporate?.getCreatedAt}
                    </Typography>
                  </Td>
                  <Td>
                    <Chips label={corporate?.status} type={corporate?.getStatusColor} />
                  </Td>
                  {/*<Td>*/}
                  {/*  <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                  {/*    //*/}
                  {/*  </Typography>*/}
                  {/*</Td>*/}
                </Tr>
              </Tbody>
            </Table>
          </div>

          <div className={'mt-[20px]'}>
            <Theader>
              <div>
                <Typography size={14} fontWeight={600}>
                  Bisnis Detail
                </Typography>
              </div>
            </Theader>
            <TBox>
              <div className={'grid grid-cols-2 gap-[20px]'}>
                <div className={'flex flex-col gap-[20px]'}>
                  <TableBusinessInfo />
                  <TableBankAccountBusiness />
                  <TableDocumentBusiness />
                </div>
                <div className={'flex flex-col gap-[20px]'}>
                  <TableBusinessAddress />
                  {/*<TableLetterAddress />*/}
                </div>
              </div>
            </TBox>
          </div>
        </>
      ) : (
        <div className={'flex justify-center italic'}>
          <Typography size={12} fontWeight={500}>
            Anda belum memiliki akun bisnis
          </Typography>
        </div>
      )}
    </div>
  );
};

export default BusinessAccount;
