import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { RiErrorWarningLine } from 'react-icons/ri';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';

const DialogConfirmActiveUser = (props: ModalDialogProps) => {
  const { user, handleActiveMember, handleDialogConfirmActiveModal } = useMemberListContext();

  return (
    <ModalDialog noHeader size="xsmall" title="Lock Member Balance" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <div
          className={
            'font-weight-600 text-[24px] bg-bg-danger-light text-text-warning rounded-full w-[48px] h-[48px] flex justify-center items-center'
          }
        >
          <RiErrorWarningLine />
        </div>
        <div>
          <Typography fontWeight={600} size={16}>
            Aktifkan akun
          </Typography>
          <Typography fontWeight={400} size={14}>
            Apakah Anda yakin ingin mengaktifkan akun ini?
          </Typography>
        </div>
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleDialogConfirmActiveModal.setState(false)} />
        {user ? <Button type={'primary'} label="Aktifkan Akun" onClick={() => handleActiveMember(user)} /> : ''}
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogConfirmActiveUser;
