import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useGoldPieceContext } from '@/delivery/ui/admin/master/gold_piece/context/gold_piece_context.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import { STATUS_LIST } from '@/domain/constant/status.ts';

const DialogAddGoldPiece = (props: ModalDialogProps) => {
  const { handleStoreGoldPiece, handleAddGoldPieceModal, setFormGoldPiece, formGoldPiece } = useGoldPieceContext();

  return (
    <ModalDialog overflow={false} size="small" title="Add Gold Piece" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField
          required
          label={'Name'}
          block
          onChange={(e: any) => {
            setFormGoldPiece({
              ...formGoldPiece,
              name: e.target.value
            });
          }}
        />

        <TextField
          required
          type={'number'}
          label={'Gram (gr)'}
          block
          onChange={(e: any) => {
            setFormGoldPiece({
              ...formGoldPiece,
              gram: parseInt(e.target.value)
            });
          }}
        />

        <Textarea
          height={150}
          label={'Notes'}
          block
          onChange={(e: any) => {
            setFormGoldPiece({
              ...formGoldPiece,
              notes: e.target.value
            });
          }}
        />

        <Select2
          required
          value={formGoldPiece.status}
          label={'Status'}
          options={STATUS_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          onChange={(e: any) => {
            setFormGoldPiece({
              ...formGoldPiece,
              status: e.value
            });
          }}
        />
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleAddGoldPieceModal.setState(false)} />
        <Button block label="Save" onClick={() => handleStoreGoldPiece()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddGoldPiece;
