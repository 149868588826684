import { useEffect } from 'react';
import StockATMGoldPage from '@/delivery/ui/admin/transaction/atm_gold/stock_atm_gold';

const ViewStockATMGold = () => {
  useEffect(() => {
    document.title = 'Stock ATM Emas';
  }, []);

  return (
    <div>
      <StockATMGoldPage />
    </div>
  );
};

export default ViewStockATMGold;
