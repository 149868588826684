import EventReferralInterface, { EventReferralValue } from '@/domain/repository/interface/event_referal_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { EventReferralModel } from '@/domain/model/event_referral_model.ts';
import { EventReferral } from '@/domain/constant/api_list';

export class EventReferralRepository implements EventReferralInterface {
  api = new ApiClient();

  async fetchEventReferral(data: EventReferralValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(EventReferral.FETCH, {
            params: data
          })
          .then(result => {
            const data = EventReferralModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showEventReferral(data: EventReferralValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(EventReferral.SHOW, {
            params: data
          })
          .then(result => {
            const data = EventReferralModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeEventReferral(data: EventReferralValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(EventReferral.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkEventReferral(data: EventReferralValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(EventReferral.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateEventReferral(data: EventReferralValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(EventReferral.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteEventReferral(data: EventReferralValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(EventReferral.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
