import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
// import Button from '@/delivery/components/atoms/button/button.tsx';
// import { FiDownloadCloud } from 'react-icons/fi';
import { useHistoryContext } from '@/delivery/ui/admin/transaction/take_gold/history/context/history_context.tsx';
import { STATUS_TRANSACTION_LIST } from '@/domain/constant/transaction/status_transaction.ts';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';

export const FilterHistory = () => {
  const { handleFetchTransaction, setFormFilterHistory, formFilterHistory } = useHistoryContext();

  return (
    <div className="flex gap-[12px]">
      <ButtonFilter
        onClickApply={() => handleFetchTransaction({})}
        size={'small'}
        titleFilter={'Filter Transaction'}
        label={'More Filter'}
      >
        <div className={'flex flex-col gap-[20px]'}>
          <TextField
            search
            placeholder={'Search...'}
            block
            onChange={e => setFormFilterHistory({ ...formFilterHistory, search: e.target.value })}
          />
          <Select2
            isSearchable={false}
            options={STATUS_TRANSACTION_LIST.map(item => {
              return {
                label: item.name,
                value: item.UUID
              };
            })}
            block
            label={'Status'}
            onChange={e => setFormFilterHistory({ ...formFilterHistory, status: e.value })}
          />
          <div className={'grid grid-cols-2 gap-[20px]'}>
            <Datepicker
              value={formFilterHistory.transaction_at_start}
              label={'Start Date'}
              block
              onChange={e =>
                setFormFilterHistory({
                  ...formFilterHistory,
                  transaction_at_start: e
                })
              }
            />
            <Datepicker
              value={formFilterHistory.transaction_at_end}
              label={'End Date'}
              block
              onChange={e =>
                setFormFilterHistory({
                  ...formFilterHistory,
                  transaction_at_end: e
                })
              }
            />
          </div>
        </div>
      </ButtonFilter>
      {/*<Button label="Export by Filter" type="secondary" prefixIcon={<FiDownloadCloud />} />*/}
    </div>
  );
};

export default FilterHistory;
