import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Typography from '@/delivery/components/atoms/typography/typography';
import Pagination from '@/delivery/components/atoms/pagination/pagination';
import { useStockGoldeContext } from '@/delivery/ui/admin/transaction/take_gold/stock_gold/context/stock_gold_context.tsx';
import { useEffect } from 'react';
import { GoldStockModel } from '@/domain/model/gold_stock_model.ts';
import FilterStockGold from '@/delivery/ui/admin/transaction/take_gold/stock_gold/filter_stock_gold.tsx';
import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card.tsx';

const StockGold = () => {
  const { goldStockSummary, handleFetchGoldStockSummary, handleFetchGoldStock, goldStocks, goldStockPagination } =
    useStockGoldeContext();

  useEffect(() => {
    handleFetchGoldStock({});
    handleFetchGoldStockSummary();
  }, []);

  return (
    <div>
      <div className="flex lg:flex-row flex-col gap-[24px] mb-[32px]">
        {goldStockSummary?.stock.map(item => (
          <SimpleCard title={`Keping tersedia ${item.gram} gr`} bigTitle={item.available.toString()} />
        ))}
      </div>
      <div>
        <div className="flex gap-[12px] mb-[12px] justify-end ">
          <FilterStockGold />
        </div>

        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              {/*<Th>TrxID</Th>*/}
              {/*<Th>Member</Th>*/}
              <Th>Gram (gr)</Th>
              <Th>Type</Th>
              {/*<Th>Flag</Th>*/}
              <Th>Locked</Th>
              <Th>Quantity</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {goldStocks.map((item: GoldStockModel, index) => (
              <Tr>
                {/*<Td>*/}
                {/*  <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*    {item.UID}*/}
                {/*  </Typography>*/}
                {/*</Td>*/}

                {/*<Td>*/}
                {/*  <div className="text-right">*/}
                {/*    <Typography fontWeight={600} size={12} type="secondary-invert">*/}
                {/*      Ivan Ryan*/}
                {/*    </Typography>*/}

                {/*    <Typography fontWeight={400} size={10} type="tertiary-invert">*/}
                {/*      iva@gmail.com*/}
                {/*    </Typography>*/}
                {/*  </div>*/}
                {/*</Td>*/}
                <Td>
                  <Typography size={12} fontWeight={400}>
                    {item.gram}
                  </Typography>
                </Td>
                <Td>
                  <Typography size={12} fontWeight={400}>
                    {item.type}
                  </Typography>
                </Td>
                {/*<Td>*/}
                {/*  <Typography size={12} fontWeight={400}>*/}
                {/*    Update Stock*/}
                {/*  </Typography>*/}
                {/*</Td>*/}
                <Td>
                  <Typography size={12} fontWeight={400}>
                    {item.locked}
                  </Typography>
                </Td>
                <Td>
                  <Typography size={12} fontWeight={400}>
                    {item.quantity}
                  </Typography>
                </Td>
                <Td>
                  <Typography size={12} fontWeight={400}>
                    {item.getCreatedAt}
                  </Typography>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            page={goldStockPagination?.page || 1}
            totalPages={goldStockPagination?.totalPages || 1}
            handlePagination={page => {
              handleFetchGoldStock({
                page: page
              });
            }}
          />
        </Tfooter>
      </div>
    </div>
  );
};

export default StockGold;
