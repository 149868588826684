import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { ProfileRepository } from '@/domain/repository/profile_repository.ts';
import { ProfileModel } from '@/domain/model/profile_model.ts';
import { MasterBankRepository } from '@/domain/repository/master_bank_repository.ts';
import { MasterBankModel } from '@/domain/model/master_bank_model.ts';
import { RegionProvinceModel } from '@/domain/model/region_province_model.ts';
import { RegionCityModel } from '@/domain/model/region_city_model.ts';
import { RegionDistrictModel } from '@/domain/model/region_district_model.ts';
import { RegionVillageModel } from '@/domain/model/region_village_model.ts';
import { RegionRepository } from '@/domain/repository/region_repository.ts';
import { NATIONAL_ID } from '@/domain/constant/national_id.ts';
import UploadMedia from '@/infrastructure/helper/uploadMedia.tsx';
import { CorporateRepository } from '@/domain/repository/corporate_repository.ts';
import { CorporateVerifyRepository } from '@/domain/repository/corporate_verify_repository.ts';
import { CorporateModel } from '@/domain/model/corporate_model.ts';
// import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';
import MEDIA_PATH from '@/domain/constant/media/media_path.ts';

interface CorporateContextProps {
  children: ReactNode;
}

interface CorporateValue {
  uid?: string;
  uuid?: string;
  page?: number;
  limit?: number;
  user_uid?: string;
}

interface FormCreateCorporate {
  user_uid?: string;
  pic_name?: string;
  identity_media?: string;

  name?: string;
  phone?: string;
  email?: string;
  website?: string;
  company_deed_media?: string;
  tax_payer_identification_number_media?: string;
  company_registration_number_media?: string;

  master_bank_uuid?: string;
  account_number?: string;
  account_holder_name?: string;
  checking_account_media?: string;

  province?: string;
  city?: string;
  district?: string;
  village?: string;
  full_address?: string;
  national_id?: string;
  province_id?: string;
  city_id?: string;
  district_id?: string;
  village_id?: string;
  zip_code?: string;
  mail_address?: string;
  mail_address_first?: string;
}

interface CorporateContextValue {
  masterBanks: MasterBankModel[];
  user?: ProfileModel;
  formCreateCorporate: FormCreateCorporate;
  setFormCreateCorporate: Dispatch<SetStateAction<FormCreateCorporate>>;
  handleDialogConfirmBusinessModal: ModalObject;
  handleDialogCreateBusinessAccountModal: ModalObject;
  handleOpenConfirmCreate: (_data: CorporateValue) => void;
  handleStoreCorporate: (_data: CorporateValue) => void;
  handleFetchMasterBank: () => void;
  mailAddressCopyBusinessAddress: boolean;
  handleMailAddressCopyBusinessAddress: () => void;

  provinces: RegionProvinceModel[];
  cities: RegionDistrictModel[];
  districts: RegionDistrictModel[];
  villages: RegionVillageModel[];
  handleFetchProvince: () => void;
  handleFetchCities: () => void;
  handleFetchDistricts: () => void;
  handleFetchVillage: () => void;

  provinceID: number;
  setProvinceID: Dispatch<SetStateAction<number>>;
  cityID: number;
  setCityID: Dispatch<SetStateAction<number>>;
  districtID: number;
  setDistrictID: Dispatch<SetStateAction<number>>;
  villageID: number;
  setVillageID: Dispatch<SetStateAction<number>>;
}

const StockGoldContext = createContext<CorporateContextValue | null>(null);

const profileRep = new ProfileRepository();
const masterBankRep = new MasterBankRepository();
const regionRepository = new RegionRepository();
const corporateRep = new CorporateRepository();
const corporateVerifyRep = new CorporateVerifyRepository();

const CorporateProvider: React.FC<CorporateContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const [mailAddressCopyBusinessAddress, setMailAddressCopyBusinessAddress] = useState<boolean>(false);

  const [user, setUser] = useState<ProfileModel>();

  const [masterBanks, setMasterBanks] = useState<MasterBankModel[]>([]);

  const [formCreateCorporate, setFormCreateCorporate] = useState<FormCreateCorporate>({});

  const handleDialogConfirmBusinessModal = useVisibleComponent(false);
  const handleDialogCreateBusinessAccountModal = useVisibleComponent(false);

  const [provinces, setProvinces] = useState<RegionProvinceModel[]>([]);
  const [cities, setCities] = useState<RegionCityModel[]>([]);
  const [districts, setDistricts] = useState<RegionDistrictModel[]>([]);
  const [villages, setVillages] = useState<RegionVillageModel[]>([]);

  const [provinceID, setProvinceID] = useState<number>(0);
  const [cityID, setCityID] = useState<number>(0);
  const [districtID, setDistrictID] = useState<number>(0);
  const [villageID, setVillageID] = useState<number>(0);

  //HANDLE FETCH MASTER BANK
  const handleFetchMasterBank = () => {
    masterBankRep
      .fetchMasterBank({ limit: -1 })
      .then((res: any) => {
        const result: MasterBankModel[] = res.data;
        setMasterBanks(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE CONFIRM AND SHOW MODAL
  const handleOpenConfirmCreate = (_data: CorporateValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: _data.uuid
    };

    profileRep
      .showProfile(params)
      .then((res: any) => {
        const result: ProfileModel = res;
        setUser(result);
        handleDialogConfirmBusinessModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE STORE CORPORATE
  const handleStoreCorporate = async (_data: CorporateValue) => {
    if (
      formCreateCorporate.name ||
      formCreateCorporate.website ||
      formCreateCorporate.company_registration_number_media ||
      formCreateCorporate.full_address ||
      formCreateCorporate.zip_code
    ) {
      const x = alertToast.loadingAlert('');

      if (!mailAddressCopyBusinessAddress) {
        handleMailAddressCopyBusinessAddress();
      }

      let identityMediaUID = '';
      if (formCreateCorporate.identity_media) {
        identityMediaUID = await UploadMedia(formCreateCorporate.identity_media, MEDIA_PATH.IDENTITY);
      } else {
        identityMediaUID = '';
      }

      let companyRegistrationNumberMediaUID = '';
      if (formCreateCorporate.company_registration_number_media) {
        companyRegistrationNumberMediaUID = await UploadMedia(
          formCreateCorporate.company_registration_number_media,
          MEDIA_PATH.COMPANY_REGISTRATION_NUMBER
        );
      } else {
        companyRegistrationNumberMediaUID = '';
      }

      let companyDeedMediaUID = '';
      if (formCreateCorporate.company_deed_media) {
        companyDeedMediaUID = await UploadMedia(formCreateCorporate.company_deed_media, MEDIA_PATH.COMPANY_DEED);
      } else {
        companyDeedMediaUID = '';
      }

      let taxPayerIdentificationNumberMediaUID = '';
      if (formCreateCorporate.tax_payer_identification_number_media) {
        taxPayerIdentificationNumberMediaUID = await UploadMedia(
          formCreateCorporate.tax_payer_identification_number_media,
          MEDIA_PATH.TAX_PAYER_IDENTIFICATION_NUMBER
        );
      } else {
        taxPayerIdentificationNumberMediaUID = '';
      }

      let checkingAccountMediaUID = '';
      if (formCreateCorporate.checking_account_media) {
        checkingAccountMediaUID = await UploadMedia(
          formCreateCorporate.checking_account_media,
          MEDIA_PATH.CHECKING_ACCOUNT
        );
      } else {
        checkingAccountMediaUID = '';
      }

      const paramsCorporate = {
        user_uid: _data?.user_uid,
        name: formCreateCorporate.name,
        email: formCreateCorporate.email,
        phone: formCreateCorporate.phone,
        // reg_date: formatDateParam(new Date()),
        pic_name: user?.getFullName,
        permit: '',
        website: formCreateCorporate.website,
        company_registration_number_media_uid: companyRegistrationNumberMediaUID,
        company_deed_media_uid: companyDeedMediaUID,
        tax_payer_identification_number_media_uid: taxPayerIdentificationNumberMediaUID,
        checking_account_media_uid: checkingAccountMediaUID,
        identity_media_uid: identityMediaUID,
        mail_address: formCreateCorporate.mail_address,
        notes: '',
        status: STATUS_CORPORATE.VERIFIED,
        latitude: 0,
        longitude: 0,
        full_address: formCreateCorporate.full_address,
        national_id: NATIONAL_ID,
        province_id: provinceID,
        city_id: cityID,
        district_id: districtID,
        village_id: villageID,
        zip_code: formCreateCorporate.zip_code
      };

      corporateRep
        .storeCorporate(paramsCorporate)
        .then((result: any) => {
          const res: CorporateModel = result;

          const paramsCorporateVerify = {
            flags: [],
            corporate_uid: res.UID,
            status: STATUS_CORPORATE.REQUEST,
            name: formCreateCorporate.name,
            email: formCreateCorporate.email,
            phone: formCreateCorporate.phone,
            // reg_date: formatDateParam(new Date()),
            pic_name: user?.getFullName,
            // permit: '',
            website: formCreateCorporate.website,
            company_registration_number_media_uid: companyRegistrationNumberMediaUID,
            company_deed_media_uid: companyDeedMediaUID,
            tax_payer_identification_number_media_uid: taxPayerIdentificationNumberMediaUID,
            checking_account_media_uid: checkingAccountMediaUID,
            identity_media_uid: identityMediaUID,
            mail_address: formCreateCorporate.mail_address,
            master_bank_uuid: formCreateCorporate.master_bank_uuid,
            type: '',
            account_number: formCreateCorporate.account_number,
            account_holder_name: formCreateCorporate.account_holder_name,
            notes: '',
            media_uid: '',
            latitude: 0,
            longitude: 0,
            full_address: formCreateCorporate.full_address,
            national_id: NATIONAL_ID,
            province_id: provinceID,
            city_id: cityID,
            district_id: districtID,
            village_id: villageID,
            zip_code: formCreateCorporate.zip_code
          };

          corporateVerifyRep
            .storeCorporateVerify(paramsCorporateVerify)
            .then(() => {
              alertToast.updateLoading(x);
              alertToast.successAlert();
              handleDialogCreateBusinessAccountModal.setState(false);
            })
            .catch(error => {
              alertToast.updateLoading(x);
              alertToast.errorAlert(error);
            });
        })
        .catch(error => {
          alertToast.updateLoading(x);
          alertToast.errorAlert(error);
        });
    } else {
      alertToast.warningAlertValidation();
    }
  };

  //HANDLE MAIL ADDRESS COPY BUSINESS ADDRESS
  const handleMailAddressCopyBusinessAddress = () => {
    setMailAddressCopyBusinessAddress(!mailAddressCopyBusinessAddress);

    const copyMailAddress = `${formCreateCorporate.village}, ${formCreateCorporate.district}, ${formCreateCorporate.city}, ${formCreateCorporate.province}, ${formCreateCorporate.full_address}, ${formCreateCorporate.zip_code}, `;
    const mailAddress = formCreateCorporate.mail_address_first;

    if (!mailAddressCopyBusinessAddress) {
      setFormCreateCorporate({ ...formCreateCorporate, mail_address: copyMailAddress });
    } else {
      setFormCreateCorporate({ ...formCreateCorporate, mail_address: mailAddress });
    }
  };

  // ------ LOCATION ADDRESS BELOW ------ //

  //HANDLE FETCH PROVINCE
  const handleFetchProvince = () => {
    regionRepository
      .fetchProvince()
      .then((result: any) => {
        setProvinces(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH CITIES
  const handleFetchCities = () => {
    const params = {
      parent_id: provinceID
    };
    regionRepository
      .fetchCities(params)
      .then((result: any) => {
        setCities(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH DISTRICT
  const handleFetchDistricts = () => {
    const params = {
      parent_id: cityID
    };
    regionRepository
      .fetchDistrict(params)
      .then((result: any) => {
        setDistricts(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH VILLAGE
  const handleFetchVillage = () => {
    const params = {
      parent_id: districtID
    };

    regionRepository
      .fetchVillage(params)
      .then((result: any) => {
        setVillages(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const contextValue: CorporateContextValue = {
    mailAddressCopyBusinessAddress,
    handleMailAddressCopyBusinessAddress,
    masterBanks,
    handleStoreCorporate,
    formCreateCorporate,
    setFormCreateCorporate,
    user,
    handleDialogConfirmBusinessModal,
    handleDialogCreateBusinessAccountModal,
    handleOpenConfirmCreate,
    handleFetchMasterBank,

    provinces,
    cities,
    districts,
    villages,
    handleFetchProvince,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    villageID,
    setVillageID
  };

  return <StockGoldContext.Provider value={contextValue}>{children}</StockGoldContext.Provider>;
};

const useCorporateContext = (): CorporateContextValue => {
  const context = useContext(StockGoldContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a CorporateProvider');
  }
  return context;
};

export { CorporateProvider, useCorporateContext };
