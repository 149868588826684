import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';
import formatNumberWithDot from '@/infrastructure/helper/formatNumberWithDot.ts';

export default class RankModel {
  email: string;
  phone: string;
  name: string;
  cost: number;
  revenue: number;
  gramBuy: number;
  gramSell: number;
  gramHold: number;
  holdProfit: number;
  grossProfit: number;
  netProfit: number;

  constructor(
    email: string,
    phone: string,
    name: string,
    cost: number,
    revenue: number,
    gramBuy: number,
    gramSell: number,
    gramHold: number,
    holdProfit: number,
    grossProfit: number,
    netProfit: number
  ) {
    this.email = email;
    this.phone = phone;
    this.name = name;
    this.cost = cost;
    this.revenue = revenue;
    this.gramBuy = gramBuy;
    this.gramSell = gramSell;
    this.gramHold = gramHold;
    this.holdProfit = holdProfit;
    this.grossProfit = grossProfit;
    this.netProfit = netProfit;
  }

  get getNetProfit(): string {
    if (this.netProfit) {
      return formatRupiah(this.netProfit);
    } else {
      return formatRupiah(0);
    }
  }

  get getGramHold(): string {
    if (this.gramHold) {
      return `${formatNumberWithDot(this.gramHold)}`;
    }
    return formatNumberWithDot(0);
  }

  get getGramBuy(): string {
    if (this.gramBuy) {
      return `${formatNumberWithDot(this.gramBuy)}`;
    }
    return formatNumberWithDot(0);
  }

  get getGramSell(): string {
    if (this.gramSell) {
      return `${formatNumberWithDot(this.gramSell)}`;
    }
    return formatNumberWithDot(0);
  }

  public static fromJson(data: any): RankModel | any {
    try {
      return new RankModel(
        data.email,
        data.phone,
        data.name,
        data.cost,
        data.revenue,
        data.gram_buy,
        data.gram_sell,
        data.gram_hold,
        data.hold_profit,
        data.gross_profit,
        data.net_profit
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): RankModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(RankModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
