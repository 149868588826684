import Navbar from '@/delivery/components/organisms/navbar/navbar';
import AdminAccess from '@/delivery/ui/admin/settings/admin_access/admin_access';

const AdminAccessPage = () => {
  return (
    <div>
      <Navbar label={'Admin Access'}></Navbar>
      <AdminAccess />
    </div>
  );
};

export default AdminAccessPage;
