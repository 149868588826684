import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { ModalDialogFooter } from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useCounterOfflineContext } from '@/delivery/ui/admin/transaction/take_gold/counter_offline/context/counter_offline_context.tsx';

const AddUnavailability = () => {
  const {
    handleAddCounterUnavailability,
    handleAddCounterUnavailabilityModal,
    formCounterUnavailability,
    setFormCounterUnavailability
  } = useCounterOfflineContext();

  return (
    <div className={'flex flex-col gap-[20px]'}>
      <div className={'flex gap-[20px]'}>
        <Datepicker
          block
          label={'Start Date'}
          value={formCounterUnavailability.start_date}
          onChange={e => setFormCounterUnavailability({ ...formCounterUnavailability, start_date: e })}
        />
        <TextField
          block
          type={'time'}
          label={'Start Hour'}
          onChange={e =>
            setFormCounterUnavailability({
              ...formCounterUnavailability,
              start_hour: e.target.value
            })
          }
        />
      </div>
      <div className={'flex gap-[20px]'}>
        <Datepicker
          block
          label={'End Date'}
          value={formCounterUnavailability.end_date}
          onChange={e => setFormCounterUnavailability({ ...formCounterUnavailability, end_date: e })}
        />
        <TextField
          block
          type={'time'}
          label={'End Hour'}
          onChange={e =>
            setFormCounterUnavailability({
              ...formCounterUnavailability,
              end_hour: e.target.value
            })
          }
        />
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleAddCounterUnavailabilityModal.setState(false)} />
        <Button label="Save" onClick={() => handleAddCounterUnavailability()} />
      </ModalDialogFooter>
    </div>
  );
};

export default AddUnavailability;
