import { useEffect } from 'react';

import DCAActivationPage from '@/delivery/ui/admin/data_dca/dca_activation';
import { DCAActivationProvider } from '@/delivery/ui/admin/data_dca/dca_activation/context/dca_activation_context.tsx';

const ViewDCAActivation = () => {
  useEffect(() => {
    document.title = 'DCA Activation';
  }, []);

  return (
    <div>
      <DCAActivationProvider>
        <DCAActivationPage />
      </DCAActivationProvider>
    </div>
  );
};

export default ViewDCAActivation;
