import MediaRepository from '@/domain/repository/media_repository.ts';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { BUCKET_UPLOAD_MEDIA } from '@/domain/constant/setting.ts';

const UploadMedia = async (file: any, media_path: string) => {
  const alertToast = useAlertToast();
  const mediaRep = new MediaRepository();

  const params = {
    bucket: BUCKET_UPLOAD_MEDIA,
    file: file,
    path: media_path,
    type: media_path
  };

  try {
    if (file !== '') {
      const result: any = await mediaRep.storeMedia(params);
      return result.UUID;
    } else {
      return '';
    }
  } catch (error: any) {
    alertToast.errorAlert(error);
    throw error;
  }
};

export default UploadMedia;
