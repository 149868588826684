export class PaginationModel {
  limit: number;
  page: number;
  search: string;
  sort: string;
  totalPages: number;
  totalRows: number;

  constructor(limit: number, page: number, search: string, sort: string, totalPages: number, totalRows: number) {
    this.limit = limit;
    this.page = page;
    this.search = search;
    this.sort = sort;
    this.totalPages = totalPages;
    this.totalRows = totalRows;
  }

  public static fromJson(data: any): PaginationModel {
    return new PaginationModel(data.limit, data.page, data.search, data.sort, data.total_pages, data.total_rows);
  }
}
