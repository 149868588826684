import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import { TransactionRepository } from '@/domain/repository/transaction_repository.ts';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface AccountTransactionValueParams {
  page?: number;
  limit?: number;
}

interface FormFilterTransaction {
  search?: string;
  type?: string;
  status?: string;
  start_date?: string;
  end_date?: string;
  transaction_at_start?: string;
  transaction_at_end?: string;
  gram?: number;
  amount?: number;
}

interface FormAccountTransactionInterface {
  uid?: string;
  commission?: number;
  commission_type?: string;
  type?: string;
  succeed_at?: string;
}

interface AccountTransactionValue {
  formFilterTransaction: FormFilterTransaction;
  setFormFilterTransaction: Dispatch<SetStateAction<FormFilterTransaction>>;
  formAccountTransaction: FormAccountTransactionInterface;
  setFormAccountTransaction: Dispatch<SetStateAction<FormAccountTransactionInterface>>;
  // accountTransaction: FormAccountTransactionInterface | undefined;
  accountTransaction?: TransactionModel;
  accountTransactions: TransactionModel[];
  accountTransactionPagination?: PaginationModel;
  handleDetailTransactionModal: ModalObject;
  handleUpdateTransactionAtModal: ModalObject;
  handleFetchAccountTransaction: (_data: AccountTransactionValueParams) => void;
  handleShowAccountTransaction: (_data: TransactionModel) => void;
  handleShowTransactionAt: (_data: TransactionModel) => void;
  handleUpdateTransactionAt: () => void;
  handleApproveTransaction: (_data: TransactionModel | undefined) => void;
  handleCancelTransaction: (_data: TransactionModel | undefined) => void;
}

const AccountTransactionContext = createContext<AccountTransactionValue | null>(null);

const accountTransactionRepository = new TransactionRepository();

const AccountTransactionProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const handleDetailTransactionModal = useVisibleComponent(false);
  const handleUpdateTransactionAtModal = useVisibleComponent(false);

  const [formFilterTransaction, setFormFilterTransaction] = useState<FormFilterTransaction>({});
  // const [accountTransaction, setAccountTransaction] = useState<AccountTransactionModel>();
  const [accountTransaction, setAccountTransaction] = useState<TransactionModel>();
  const [accountTransactions, setAccountTransactions] = useState<TransactionModel[]>([]);
  const [accountTransactionPagination, setAccountTransactionPagination] = useState<PaginationModel>();

  const [formAccountTransaction, setFormAccountTransaction] = useState<FormAccountTransactionInterface>({});

  //FETCH ACCOUNT TRANSACTION
  const handleFetchAccountTransaction = (_data: AccountTransactionValueParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      sort: 'created_at desc',
      type: TYPE_TRANSACTION.DCA,
      page: _data?.page ?? 1,
      limit: _data?.limit ?? 10,
      transaction_at: formFilterTransaction.transaction_at_start
        ? [
            `${formatDateParam(formFilterTransaction.transaction_at_start)}`,
            `${formatDateParam(formFilterTransaction.transaction_at_end)}`
          ]
        : null,
      status: formFilterTransaction.status ?? null,
      search: formFilterTransaction.search
    };

    accountTransactionRepository
      .fetchTransactionDashboard(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setAccountTransactions(result.data);
        setAccountTransactionPagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW ACCOUNT TRANSACTION
  const handleShowAccountTransaction = (_data: TransactionModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };

    accountTransactionRepository
      .showTransaction(params)
      .then((res: any) => {
        const result: TransactionModel = res;

        setAccountTransaction(result);
        alertToast.updateLoading(x);

        handleDetailTransactionModal.setState(true);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //UPDATE SHOW ACCOUNT TRANSACTION AT
  const handleShowTransactionAt = (_data: TransactionModel) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uid: _data.UID
    };

    accountTransactionRepository
      .showTransaction(params)
      .then((res: any) => {
        const result: TransactionModel = res;
        setFormAccountTransaction({
          uid: result.UID,
          succeed_at: result.succeedAt
        });
        alertToast.updateLoading(x);
        handleUpdateTransactionAtModal.setState(true);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //UPDATE ACCOUNT TRANSACTION AT
  const handleUpdateTransactionAt = () => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          uid: formAccountTransaction.uid,
          succeed_at: formAccountTransaction.succeed_at,
          status: accountTransaction?.status
        };

        accountTransactionRepository
          .updateTransaction(params)
          .then(() => {
            alertToast.updateLoading(x);
            handleFetchAccountTransaction({ page: 1 });
            handleDetailTransactionModal.setState(false);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //APPROVE ACCOUNT TRANSACTION
  const handleApproveTransaction = (_data: TransactionModel | undefined) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          uid: _data?.UID,
          status: STATUS_TRANSACTION.SUCCESS
        };

        accountTransactionRepository
          .updateTransaction(params)
          .then((result: any) => {
            alertToast.updateLoading(x);
            alertToast.successAlert();
            handleDetailTransactionModal.setState(false);
            handleFetchAccountTransaction({ page: 1 });
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE CANCEL TRANSACTION
  const handleCancelTransaction = (_data: TransactionModel | undefined) => {
    alertSweet.questionAlert('Apakah anda yakin cancel transaksi?').then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          uid: _data?.UID
        };

        accountTransactionRepository
          .cancelTransaction(params)
          .then((result: any) => {
            alertToast.updateLoading(x);
            handleDetailTransactionModal.setState(false);
            handleFetchAccountTransaction({ page: 1 });
            alertToast.successAlert();
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: AccountTransactionValue = {
    formFilterTransaction,
    setFormFilterTransaction,
    accountTransaction,
    formAccountTransaction,
    setFormAccountTransaction,
    // accountTransaction,
    accountTransactions,
    accountTransactionPagination,
    handleFetchAccountTransaction,
    handleShowAccountTransaction,
    handleUpdateTransactionAt,
    handleDetailTransactionModal,
    handleApproveTransaction,
    handleUpdateTransactionAtModal,
    handleShowTransactionAt,
    handleCancelTransaction
  };

  return <AccountTransactionContext.Provider value={contextValue}>{children}</AccountTransactionContext.Provider>;
};

const useAccountTransactionContext = (): AccountTransactionValue => {
  const context = useContext(AccountTransactionContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a AccountTransactionProvider');
  }
  return context;
};

export { AccountTransactionProvider, useAccountTransactionContext };
