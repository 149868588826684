import DetailVendingMachine from '@/delivery/ui/admin/transaction/take_gold/vending_machine/detail/detail_vending_machine.tsx';
import { VendingMachineProvider } from '@/delivery/ui/admin/transaction/take_gold/vending_machine/context/vending_machine_context.tsx';
import { VendingMachineRackProvider } from '@/delivery/ui/admin/transaction/take_gold/vending_machine/context/vending_machine_rack_context.tsx';

const VendingMachineDetail = () => {
  return (
    <div>
      <VendingMachineRackProvider>
        <VendingMachineProvider>
          <DetailVendingMachine />
        </VendingMachineProvider>
      </VendingMachineRackProvider>
    </div>
  );
};

export default VendingMachineDetail;
