import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { TransactionRepository } from '@/domain/repository/transaction_repository.ts';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { PaymentChannelRepository } from '@/domain/repository/payment_channel_repository.ts';
import { PaymentChannelModel } from '@/domain/model/payment/payment_channel_model.ts';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { GoldRateRepository } from '@/domain/repository/gold_rate_repository.ts';
import { GoldRateLatestModel } from '@/domain/model/gold_rate_lates_model.ts';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';
import { UserRepository } from '@/domain/repository/user_repository.ts';
import { UserModel } from '@/domain/model/user_model.ts';
// import { MEMBER_TYPE } from '@/domain/constant/member/member_type.ts';

interface DepositByAdminProps {
  children: ReactNode;
}

interface TransactionParams {
  page?: number;
  limit?: number;
  type?: string;
  search?: string;
}

interface FormFilterTransaction {
  search?: string;
  type?: string;
  status?: string;
  start_date?: string;
  end_date?: string;
  transaction_at_start?: string;
  transaction_at_end?: string;
  gram?: number;
  amount?: number;
}

interface FormManualTransactionInterface {
  amount_balance?: number;
  userUID?: string;
  rateUID?: string;
  referenceID?: string;
  type?: string;
  status?: string;
  name?: string;
  amount?: number;
  gram?: number;
  notes?: string;
  flow?: string;
  relatedUID?: string;
  transactionAt?: string;
  settledAt?: string;
  succeedAt?: string;
  reconAt?: string;
  email?: string;
  phone?: string;
  fee?: number;
  payment_channel_uid?: string;
}

interface DepositByAdminContextValue {
  formFilterTransaction: FormFilterTransaction;
  setFormFilterTransaction: Dispatch<SetStateAction<FormFilterTransaction>>;
  goldRateLatest?: GoldRateLatestModel;
  paymentChannels: PaymentChannelModel[];
  handleShowUserListModal: ModalObject;
  formManualTransaction: FormManualTransactionInterface;
  setFormManualTransaction: Dispatch<SetStateAction<FormManualTransactionInterface>>;
  users: UserModel[];
  loading: boolean;
  setUsers: Dispatch<SetStateAction<UserModel[]>>;
  transaction?: TransactionModel;
  transactions: TransactionModel[];
  transactionPagination?: PaginationModel;
  handleDetailTransactionDepositModal: ModalObject;
  handleDetailDepositByAdminModal: ModalObject;
  handleAddDepositByAdminModal: ModalObject;
  handleFetchTransactionDeposit: (_data: TransactionParams) => void;
  handleFetchUser: (_data: TransactionParams) => void;
  handleSelectUser: (_data: UserModel) => void;
  handleFetchPaymentChannel: () => void;
  handleFetchGoldRateLatest: () => void;
  handleSubmitManualTransaction: () => void;
  handleShowTransaction: (_data: TransactionModel) => void;
}

const DepositByAdminContext = createContext<DepositByAdminContextValue | null>(null);

const transactionRep = new TransactionRepository();
const profileRep = new UserRepository();
const paymentChannelRepository = new PaymentChannelRepository();
const goldRateRepository = new GoldRateRepository();

const DepositByAdminProvider: React.FC<DepositByAdminProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [transaction, setTransaction] = useState<TransactionModel>();

  const [transactions, setTransactions] = useState<TransactionModel[]>([]);
  const [transactionPagination, transactionUserPagination] = useState<PaginationModel>();

  const [users, setUsers] = useState<UserModel[]>([]);
  const [paymentChannels, setPaymentChannels] = useState<PaymentChannelModel[]>([]);
  const [goldRateLatest, setGoldRateLatest] = useState<GoldRateLatestModel>();

  const [formManualTransaction, setFormManualTransaction] = useState<FormManualTransactionInterface>({});

  const handleDetailDepositByAdminModal = useVisibleComponent(false);
  const handleAddDepositByAdminModal = useVisibleComponent(false);
  const handleShowUserListModal = useVisibleComponent(false);
  const handleDetailTransactionDepositModal = useVisibleComponent(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [formFilterTransaction, setFormFilterTransaction] = useState<FormFilterTransaction>({});

  //HANDLE FETCH TRANSACTION DEPOSIT
  const handleFetchTransactionDeposit = (_data: TransactionParams) => {
    const x = alertToast.loadingAlert('Fetching transactions');
    const params = {
      sort: 'created_at desc',
      page: _data.page,
      limit: _data.limit,
      type: TYPE_TRANSACTION.DEPOSIT,
      transaction_at: formFilterTransaction.transaction_at_start
        ? [
            `${formatDateParam(formFilterTransaction.transaction_at_start)}`,
            `${formatDateParam(formFilterTransaction.transaction_at_end)}`
          ]
        : null,
      status: formFilterTransaction.status ?? null
    };

    transactionRep
      .fetchTransaction(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setTransactions(result.data);
        transactionUserPagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //FETCH USER TRANSACTION SEARCH
  const handleFetchUser = (_data: TransactionParams) => {
    setLoading(true);
    const params = {
      search: _data.search
      // type: MEMBER_TYPE.USER
    };
    profileRep
      .fetchUser(params)
      .then((result: any) => {
        setLoading(false);
        setUsers(result.data);
      })
      .catch(error => {
        setLoading(false);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW TRANSACTION
  const handleShowTransaction = (_data: TransactionModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };

    transactionRep
      .showTransaction(params)
      .then((result: any) => {
        setTransaction(result);
        alertToast.updateLoading(x);
        handleDetailTransactionDepositModal.setState(true);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //SELECT USER
  const handleSelectUser = (_data: UserModel) => {
    setFormManualTransaction({
      userUID: _data.UUID,
      name: _data.firstName + ' ' + _data.lastName,
      email: _data.email,
      phone: _data.phone
      // amount_balance: _data.balance.amount
    });

    handleShowUserListModal.setState(false);
  };

  //FETCH PAYMENT CHANNEL
  const handleFetchPaymentChannel = () => {
    const params = {
      page: 1,
      limit: -1,
      with_manual: true
    };

    paymentChannelRepository
      .fetchPaymentChannel(params)
      .then((result: any) => {
        setPaymentChannels(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //FETCH GOLD RATE
  const handleFetchGoldRateLatest = () => {
    goldRateRepository
      .latestGoldRate()
      .then((result: any) => {
        setGoldRateLatest(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //SUBMIT TRANSACTION MANUAL
  const handleSubmitManualTransaction = () => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          amount: formManualTransaction.amount,
          fee: formManualTransaction.fee,
          gram: formManualTransaction.gram,
          notes: formManualTransaction.notes,
          payment_channel_uid: formManualTransaction.payment_channel_uid,
          rate_uid: goldRateLatest?.currentRate.UID,
          // recon_at: '{{recon_at_time.Time}}',
          // reference_id: '{{reference_id_string}}',
          // related_uid: '{{related_uid_string}}',
          // settled_at: '{{settled_at_time.Time}}',
          status: STATUS_TRANSACTION.SUCCESS, // formManualTransaction.status,
          succeed_at: formManualTransaction.status === STATUS_TRANSACTION.SUCCESS ? new Date() : null,
          type: TYPE_TRANSACTION.DEPOSIT,
          user_uid: formManualTransaction.userUID

          // user_withdrawal_uid: '{{user_withdrawal_uid_string}}'
        };

        transactionRep
          .manualTransaction(params)
          .then(() => {
            handleFetchTransactionDeposit({ page: 1 });
            handleAddDepositByAdminModal.setState(false);
            alertToast.successAlert('Success');
            alertToast.updateLoading(x);
            setFormManualTransaction({
              amount: 0,
              fee: 0,
              gram: 0,
              notes: '',
              payment_channel_uid: '',
              status: '',
              succeedAt: '',
              type: '',
              userUID: ''
            });
          })
          .catch(error => {
            alertToast.errorAlert(error);
            alertToast.updateLoading(x);
          });
      }
    });
  };

  const contextValue: DepositByAdminContextValue = {
    formFilterTransaction,
    setFormFilterTransaction,
    handleDetailTransactionDepositModal,
    transaction,
    goldRateLatest,
    handleFetchPaymentChannel,
    paymentChannels,
    handleFetchUser,
    handleSelectUser,
    handleShowUserListModal,
    formManualTransaction,
    setFormManualTransaction,
    setUsers,
    users,
    loading,
    transactions,
    transactionPagination,
    handleFetchTransactionDeposit,
    handleDetailDepositByAdminModal,
    handleAddDepositByAdminModal,
    handleFetchGoldRateLatest,
    handleSubmitManualTransaction,
    handleShowTransaction
  };

  return <DepositByAdminContext.Provider value={contextValue}>{children}</DepositByAdminContext.Provider>;
};

const useDepositByAdminContext = (): DepositByAdminContextValue => {
  const context = useContext(DepositByAdminContext);
  if (!context) {
    throw new Error('useDepositByAdminContext must be used within a DepositByAdminProvider');
  }
  return context;
};

export { DepositByAdminProvider, useDepositByAdminContext };
