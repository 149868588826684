import { useEffect } from 'react';
import TransactionLogPage from '@/delivery/ui/admin/settings/transaction_log';

const ViewTransactionLog = () => {
  useEffect(() => {
    document.title = 'Transaction Log';
  }, []);

  return (
    <div>
      <TransactionLogPage />
    </div>
  );
};

export default ViewTransactionLog;
