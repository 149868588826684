import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Theader, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Chips from '@/delivery/components/atoms/chips/chips';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useAffiliateTransactionContext } from './context/affiliate_transaction_context';
import DialogDetailAffiliateTransaction from './dialog/dialog_detail_affiliate_transaction';
import { useEffect } from 'react';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import FilterAffiliateTransaction from '@/delivery/ui/admin/reseller_affiliate/affiliate_transaction/filter_affiliate_transaction.tsx';

const AffiliateTransaction = () => {
  const {
    affiliateTransactionPagination,
    affiliateTransactions,
    handleFetchAffiliateTransaction,
    handleDetailAffiliateTransactionModal,
    handleShowTransaction,
    handleCancelTransaction
  } = useAffiliateTransactionContext();

  useEffect(() => {
    handleFetchAffiliateTransaction({});
  }, []);

  return (
    <div>
      <Theader>
        <div></div>
        <div className="flex gap-[12px] justify-end">
          <FilterAffiliateTransaction />
        </div>
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Trx ID</Th>
            <Th>Member</Th>
            <Th>Type</Th>
            <Th>Status</Th>
            <Th>Trx Date</Th>
            <Th>Paid Amount</Th>
            <Th>Gramasi (Gr)</Th>
            <Th>Paid Date</Th>
            <Th>Gold Rate</Th>
            <Th>Payment</Th>
            <Th>Update By</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {affiliateTransactions.map((item: TransactionModel, index) => (
            <Tr>
              <Td>
                <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                  {item.code}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={600} size={12} type="primary-invert">
                  {item.userEmail?.name}
                </Typography>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {item.userEmail?.email}
                </Typography>
              </Td>
              <Td>
                <Chips label={item.type} removeDot />
              </Td>
              <Td>
                <Chips label={item.status} type={item.getStatusColor} />
              </Td>
              <Td>
                <div className={'text-nowrap'}>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getTransactionAt}
                  </Typography>
                </div>
              </Td>
              <Td>
                <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                  {item.getAmountRupiah}
                </Typography>
              </Td>
              <Td>
                <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                  {item.amount?.gram}
                </Typography>
              </Td>
              <Td>
                <div className={'text-nowrap'}>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getSucceedAt}
                  </Typography>
                </div>
              </Td>
              <Td>
                <Typography fontWeight={600} size={12} type="tertiary-invert">
                  {item.rate.getBuy}
                </Typography>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {item.rate.getSell}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {item.payment?.method}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {item.userUpdate?.name}
                </Typography>
              </Td>
              <Td>
                <div className="flex gap-[4px]">
                  <Button iconButton iconType="view" onClick={() => handleShowTransaction(item)} />
                  <Button iconButton iconType="banned" onClick={() => handleCancelTransaction(item)} />
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={affiliateTransactionPagination?.page || 1}
          totalPages={affiliateTransactionPagination?.totalPages || 1}
          handlePagination={page => {
            handleFetchAffiliateTransaction({
              page: page
            });
          }}
        />
      </Tfooter>

      {handleDetailAffiliateTransactionModal.state && (
        <DialogDetailAffiliateTransaction modal={handleDetailAffiliateTransactionModal} />
      )}
    </div>
  );
};

export default AffiliateTransaction;
