import { ReactNode } from 'react';
import styles from './SimpleCard.module.css';
import clsx from 'clsx';
import Loading from '@/delivery/components/atoms/loading/loading';

interface SimpleCardProps {
  children?: ReactNode;
  title?: string;
  bigTitle?: string;
  loading?: boolean;
  unit?: 'gr' | 'rp' | 'none';
}

const SimpleCard = (props: SimpleCardProps) => {
  const { unit = 'none', children, title, bigTitle, loading } = props;
  return (
    <div className={clsx(styles['card'])}>
      <div className="p-[24px] gap-[16px] flex flex-col">
        <span className="text-[12px] font-[500] leading-[20px] text-text-tertiary-invert">{title}</span>
        <span>
          {loading ? (
            <div className="flex justify-start">
              <Loading />
            </div>
          ) : (
            <div className={'flex justify-between items-start'}>
              <div className={'flex gap-[10px] items-start'}>
                {unit === 'rp' ? <div className={'text-text-primary-green'}>Rp</div> : ''}
                <div className="text-[24px] font-[600] leading-[38px]">{bigTitle}</div>
              </div>
              {unit === 'gr' ? <div className={'text-bg-warning'}>gr</div> : ''}
            </div>
          )}
        </span>
      </div>
      {children ? (
        <div className="px-[24px] py-[12px] flex justify-end border-t border-border-secondary">{children}</div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SimpleCard;
