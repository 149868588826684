import { ApiClient } from '@/main/apiClient';
import UserWithdrawalAccountInterface, {
  UserWithdrawalAccountValue
} from '@/domain/repository/interface/user_withdrawal_account_interface';
import { UserWithdrawalAccount } from '@/domain/constant/api_list';
import { UserWithdrawalAccountModel } from '@/domain/model/user_withdrawal_account_model';
import { PaginationModel } from '@/domain/model/pagination_model';

export class UserWithdrawalAccountRepository implements UserWithdrawalAccountInterface {
  api = new ApiClient();

  async fetchUserWithdrawalAccount(data: UserWithdrawalAccountValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(UserWithdrawalAccount.FETCH, {
            params: data
          })
          .then(result => {
            const data = UserWithdrawalAccountModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showUserWithdrawalAccount(data: UserWithdrawalAccountValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(UserWithdrawalAccount.SHOW, {
            params: data
          })
          .then(result => {
            const data = UserWithdrawalAccountModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeUserWithdrawalAccount(data: UserWithdrawalAccountValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(UserWithdrawalAccount.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkUserWithdrawalAccount(data: UserWithdrawalAccountValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(UserWithdrawalAccount.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateUserWithdrawalAccount(data: UserWithdrawalAccountValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(UserWithdrawalAccount.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteUserWithdrawalAccount(data: UserWithdrawalAccountValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(UserWithdrawalAccount.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
