import { useEffect } from 'react';
import MemberVerifyPage from '@/delivery/ui/admin/data_member/member_verify';
import { MemberVerifyProvider } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context';

const ViewMemberVerify = () => {
  useEffect(() => {
    document.title = 'Member Verify';
  }, []);

  return (
    <div>
      <MemberVerifyProvider>
        <MemberVerifyPage />
      </MemberVerifyProvider>
    </div>
  );
};

export default ViewMemberVerify;
