import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';

const DialogUpdateDataUser = (props: ModalDialogProps) => {
  const { handleUpdateDataUser, handleEditDataUserModal, user, formUser, setFormUser } = useMemberListContext();

  return (
    <ModalDialog overflow={false} size="small" title="Update User" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField disabled value={user?.serialID} label={'Dinaran Number'} block />
        <div className={'flex gap-[20px]'}>
          <TextField
            value={formUser.first_name}
            label={'Nama Depan'}
            block
            onChange={e => setFormUser({ ...formUser, first_name: e.target.value })}
          />
          <TextField
            value={formUser.last_name}
            label={'Nama Belakang'}
            block
            onChange={e => setFormUser({ ...formUser, last_name: e.target.value })}
          />
        </div>
        <TextField
          value={formUser.email}
          label={'Email'}
          block
          onChange={e => setFormUser({ ...formUser, email: e.target.value })}
        />
        <TextField
          value={formUser.phone}
          type={'number'}
          label={'Phone'}
          block
          onChange={e => setFormUser({ ...formUser, phone: e.target.value })}
        />
        <ModalDialogFooter className="flex gap-[20px] justify-end">
          <Button type="secondary" label="Cancel" onClick={() => handleEditDataUserModal.setState(false)} />
          <Button label="Save" onClick={() => handleUpdateDataUser()} />
        </ModalDialogFooter>
      </div>
    </ModalDialog>
  );
};

export default DialogUpdateDataUser;
