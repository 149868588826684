import { useEffect } from 'react';
import AccountRequestPage from '@/delivery/ui/admin/data_dca/account_request';
import { AccountRequestProvider } from '@/delivery/ui/admin/data_dca/account_request/context/account_request_context.tsx';

const ViewAccountRequest = () => {
  useEffect(() => {
    document.title = 'Account Request';
  }, []);

  return (
    <div>
      <AccountRequestProvider>
        <AccountRequestPage />
      </AccountRequestProvider>
    </div>
  );
};

export default ViewAccountRequest;
