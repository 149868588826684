import { ApiClient } from '@/main/apiClient';
import { ICDX } from '@/domain/constant/api_list';
import ICDXInterface, { ICDXValue } from '@/domain/repository/interface/icdx_interface.ts';

export class ICDXRepository implements ICDXInterface {
  api = new ApiClient();

  async sendICDXTransaction(data: ICDXValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(ICDX.SEND, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
