import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useBusinessAccountContext } from '@/delivery/ui/admin/data_member/member_list/context/business_account_context.tsx';
import DialogUpdateAddressCorporate from '@/delivery/ui/admin/data_member/member_list/detail/tabs/business_account/dialog/dialog_update_data_address.tsx';

const TableBusinessAddress = () => {
  const { handleEditAddressModal, corporate } = useBusinessAccountContext();

  return (
    <div>
      <Table noBorder className="rounded-b-[8px]" rounded>
        <Thead>
          <Tr noBorder>
            <Th noBorder>
              <Typography fontWeight={600} size={14} type="primary-invert">
                Alamat Bisnis
              </Typography>
            </Th>
            <Th noBorder>
              <div className={'justify-end flex'}>
                <span
                  className={'text-text-primary font-[600] text-[14px] cursor-pointer'}
                  onClick={() => handleEditAddressModal.setState(true)}
                >
                  Edit
                </span>
              </div>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {/*provinsi*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Provinsi
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {corporate?.province.name}
              </Typography>
            </Td>
          </Tr>

          {/*kabupaten*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Kabupaten
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {corporate?.city.name}
              </Typography>
            </Td>
          </Tr>

          {/*Kecamatan*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Kecamatan
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {corporate?.district.name}
              </Typography>
            </Td>
          </Tr>

          {/*kelurahan*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Kelurahan
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {corporate?.village.name}
              </Typography>
            </Td>
          </Tr>

          {/*alamat*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Alamat
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {corporate?.fullAddress}
              </Typography>
            </Td>
          </Tr>

          {/*Kode pos*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Kode POS
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {corporate?.zipCode}
              </Typography>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      {handleEditAddressModal.state && <DialogUpdateAddressCorporate modal={handleEditAddressModal} />}
    </div>
  );
};

export default TableBusinessAddress;
