export const parseParams = (params: any) => {
  let options = '';

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      for (const element of value) {
        options += `${key}=${element}&`;
      }
    } else {
      if (value !== null && value !== undefined && value !== '') {
        if (key === 'meta') {
          for (const [k, v] of Object.entries(value)) {
            options += `${key}[${k}]=${v}&`;
          }
        } else {
          options += `${key}=${value}&`;
        }
      }
    }
  }

  return options.slice(0, -1);
};

export const parseParamsToUrlencode = (params: any) => {
  let options = '';

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      for (const element of value) {
        const encodedElemant = encodeURIComponent(element);
        options += `${key}=${encodedElemant}&`;
      }
    } else {
      if (value !== null && value !== undefined && value !== '') {
        if (key === 'meta') {
          for (const [k, v] of Object.entries(value)) {
            const encodedValue = encodeURIComponent(v);
            options += `meta[${k}]=${encodedValue}&`;
          }
        } else {
          const encodedValue = value.toString();
          // disable encodeURIComponent karena masalah di param yang beri slash. contoh ...&search=O-5T-20240419-1/70&...
          // const encodedValue = encodeURIComponent(value.toString());
          options += `${key}=${encodedValue}&`;
        }
      }
    }
  }

  return options.slice(0, -1);
};
