import { BaseModel } from '@/domain/model/base_model';

export class UserHeirModel extends BaseModel {
  userUID: string;
  fullName: string;
  phone: string;
  relation: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    userUID: string,
    fullName: string,
    phone: string,
    relation: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.userUID = userUID;
    this.fullName = fullName;
    this.phone = phone;
    this.relation = relation;
  }

  public static fromJson(data: any): any | UserHeirModel {
    try {
      return new UserHeirModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.user_uid,
        data.full_name,
        data.phone,
        data.relation
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): UserHeirModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(UserHeirModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
