import Navbar from '@/delivery/components/organisms/navbar/navbar';
import HistoryTakeGold from './history';

const HistoryTakeGoldPage = () => {
  return (
    <div>
      <Navbar label={'History Ambil Emas'}></Navbar>
      <HistoryTakeGold />
    </div>
  );
};

export default HistoryTakeGoldPage;
