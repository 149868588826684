import { useEffect } from 'react';
import { AuthProvider } from './context/auth_context';
import LoginOTP from '@/delivery/ui/login/login_otp.tsx';
// import Login from '@/delivery/ui/login/login.tsx';

const LoginPage = () => {
  useEffect(() => {
    document.title = 'Login - Dinaran';
  }, []);

  return (
    <div>
      <AuthProvider>
        <LoginOTP />
      </AuthProvider>
    </div>
  );
};

export default LoginPage;
