import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useAffiliateListContext } from '@/delivery/ui/admin/reseller_affiliate/affiliate_list/context/affiliate_list_context.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import DialogExportAffiliate from '@/delivery/ui/admin/reseller_affiliate/affiliate_list/dialog/dialog_export_data_affiliate_list.tsx';
import { useEffect, useState } from 'react';

const FilterAffiliateList = () => {
  const {
    handleExportDataAffiliateListModal,
    handleExportAffiliateList,
    handleFetchAffiliateList,
    formFilterAffiliateList,
    setFormFilterAffiliateList
  } = useAffiliateListContext();

  const [isResetting, setIsResetting] = useState(false);

  const handleReset = () => {
    setFormFilterAffiliateList({ search: '' });
    setIsResetting(true);
  };

  useEffect(() => {
    if (isResetting) {
      handleFetchAffiliateList({ search: '' });
      setIsResetting(false);
    }
  }, [formFilterAffiliateList, isResetting]);

  return (
    <div className="flex gap-[12px]">
      <Button type={'secondary'} prefixIcon={Icon.reset} label={'Reset Filter'} onClick={handleReset} />
      <ButtonFilter
        onClickApply={() => handleFetchAffiliateList({})}
        size={'medium'}
        titleFilter={'Filter Transaction'}
        label={'More Filter'}
      >
        <div className={'flex flex-col gap-[20px]'}>
          <TextField
            value={formFilterAffiliateList.search}
            block
            search
            placeholder="Search"
            onChange={e => setFormFilterAffiliateList({ search: e.target.value })}
          />
        </div>
      </ButtonFilter>
      <Button
        type={'secondary'}
        prefixIcon={Icon.downloadCloud}
        label={'Export Filter'}
        onClick={() => handleExportAffiliateList()}
      />

      {handleExportDataAffiliateListModal.state && <DialogExportAffiliate modal={handleExportDataAffiliateListModal} />}
    </div>
  );
};

export default FilterAffiliateList;
