import { BaseModel } from '@/domain/model/base_model';

export class RoleModel extends BaseModel {
  CompanyUUID: string;
  name: string;
  slug: string;
  roleAccess: any;
  roleUserCompany: any;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    CompanyUUID: string,
    name: string,
    slug: string,
    roleAccess: any,
    roleUserCompany: any
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.CompanyUUID = CompanyUUID;
    this.name = name;
    this.slug = slug;
    this.roleAccess = roleAccess;
    this.roleUserCompany = roleUserCompany;
  }

  public static fromJson(data: any): RoleModel | any {
    try {
      return new RoleModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.company_uuid,
        data.name,
        data.slug,
        data.role_access,
        data.role_user_company
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): RoleModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(RoleModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
