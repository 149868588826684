import { BaseModel } from '@/domain/model/base_model.ts';
import { PaymentMethodModel } from '@/domain/model/payment/payment_method_model.ts';
import { PaymentChannelFeeModel } from '@/domain/model/payment/payment_channel_fee_model.ts';
import { IMAGE_URL } from '@/domain/constant/image_url.ts';

export class PaymentChannelModel extends BaseModel {
  paymentMethodUID: string;
  code: string;
  name: string;
  description: string;
  status: string;
  priority: string;
  mediaUID: string;
  paymentMethod: PaymentMethodModel;
  paymentChannelFee: PaymentChannelFeeModel;
  systemNote: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    paymentMethodUID: string,
    code: string,
    name: string,
    description: string,
    status: string,
    priority: string,
    mediaUID: string,
    paymentMethod: PaymentMethodModel,
    paymentChannelFee: PaymentChannelFeeModel,
    systemNote: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.paymentMethodUID = paymentMethodUID;
    this.code = code;
    this.name = name;
    this.description = description;
    this.status = status;
    this.priority = priority;
    this.mediaUID = mediaUID;
    this.paymentMethod = paymentMethod;
    this.paymentChannelFee = paymentChannelFee;
    this.systemNote = systemNote;
  }

  get getMediaUrl(): string {
    if (this.mediaUID) {
      return `${IMAGE_URL}/${this.mediaUID}`;
    }
    return '#';
  }

  public static fromJson(data: any): any | PaymentChannelModel {
    try {
      return new PaymentChannelModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.payment_method_uid,
        data.code,
        data.name,
        data.description,
        data.status,
        data.priority,
        data.media_uid,
        data.payment_method ? PaymentMethodModel.fromJson(data.payment_method) : null,
        data.payment_channel_fee ? PaymentChannelFeeModel.fromJson(data.payment_channel_fee) : null,
        data.system_note
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): PaymentChannelModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(PaymentChannelModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
