import RiceRateInterface, { RiceRateValue } from '@/domain/repository/interface/rice_rate_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { RiceRateModel } from '@/domain/model/rice_rate_model.ts';
import { RiceRate } from '@/domain/constant/api_list';

export class RiceRateRepository implements RiceRateInterface {
  api = new ApiClient();

  async fetchRiceRate(data: RiceRateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(RiceRate.FETCH, {
            params: data
          })
          .then(result => {
            const data = RiceRateModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showRiceRate(data: RiceRateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(RiceRate.SHOW, {
            params: data
          })
          .then(result => {
            const data = RiceRateModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeRiceRate(data: RiceRateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(RiceRate.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkRiceRate(data: RiceRateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(RiceRate.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateRiceRate(data: RiceRateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(RiceRate.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteRiceRate(data: RiceRateValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(RiceRate.DELETE + data.uid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
