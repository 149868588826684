import { ApiClient } from '@/main/apiClient';
import { Region } from '@/domain/constant/api_list.ts';
import RegionInterface, { RegionValue } from '@/domain/repository/interface/region_interface.ts';
import { RegionNationalityModel } from '@/domain/model/region_nationality_model.ts';
import { RegionProvinceModel } from '@/domain/model/region_province_model.ts';
import { RegionCityModel } from '@/domain/model/region_city_model.ts';
import { RegionDistrictModel } from '@/domain/model/region_district_model.ts';
import { RegionVillageModel } from '@/domain/model/region_village_model.ts';

export class RegionRepository implements RegionInterface {
  api = new ApiClient();

  async fetchNationality(data?: RegionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Region.NATIONALITY, {
            params: data
          })
          .then(result => {
            const data = RegionNationalityModel.fromJsonList(result.data.data);

            const nationality = {
              data: data
            };
            resolve(nationality);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchProvince(data?: RegionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Region.PROVINCE, {
            params: data
          })
          .then(result => {
            //console.log('result uom', result.data.data);
            const data = RegionProvinceModel.fromJsonList(result.data.data);

            const province = {
              data: data
            };
            resolve(province);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchCities(data: RegionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Region.CITY, {
            params: data
          })
          .then(result => {
            const data = RegionCityModel.fromJsonList(result.data.data);

            const city = {
              data: data
            };
            resolve(city);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchDistrict(data: RegionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Region.DISTRICT, {
            params: data
          })
          .then(result => {
            const data = RegionDistrictModel.fromJsonList(result.data.data);

            const district = {
              data: data
            };
            resolve(district);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchVillage(data: RegionValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Region.VILLAGE, {
            params: data
          })
          .then(result => {
            const data = RegionVillageModel.fromJsonList(result.data.data);

            const village = {
              data: data
            };
            resolve(village);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
