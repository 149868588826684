import { useEffect } from 'react';
import ActivityLogPage from '@/delivery/ui/admin/settings/activity_log';

const ViewActivityLog = () => {
  useEffect(() => {
    document.title = 'Activity Log';
  }, []);

  return (
    <div>
      <ActivityLogPage />
    </div>
  );
};

export default ViewActivityLog;
