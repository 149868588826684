import { useEffect } from 'react';
import ReferralListPage from '@/delivery/ui/admin/data_corporate/referral_list';

const ViewReferralList = () => {
  useEffect(() => {
    document.title = 'Referral List';
  }, []);

  return (
    <div>
      <ReferralListPage />
    </div>
  );
};

export default ViewReferralList;
