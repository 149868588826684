import UserDashboardInterface, { UserDashboardValue } from '@/domain/repository/interface/user_dashboard_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { UserDashboardBalanceModel } from '@/domain/model/user_dashboard_balance.ts';
import { UserDashboard } from '@/domain/constant/api_list';
import { UserDashboardModel } from '@/domain/model/user_dashboard_profile_model.ts';

export class UserDashboardRepository implements UserDashboardInterface {
  api = new ApiClient();

  async showUserDashboardBalance(data: UserDashboardValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(UserDashboard.SHOW_BALANCE + `/${data.user_uid}`, '')
          .then(result => {
            const data = UserDashboardBalanceModel.fromJson(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showUserDashboardProfile(data: UserDashboardValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(UserDashboard.SHOW_PROFILE + `/${data.user_uid}`, {})
          .then(result => {
            const data = UserDashboardModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
