import VendorInterface, { VendorValue } from '@/domain/repository/interface/vendor_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { VendorModel } from '@/domain/model/vendor_model.ts';
import { Vendor } from '@/domain/constant/api_list';

export class VendorRepository implements VendorInterface {
  api = new ApiClient();

  async fetchVendor(data: VendorValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Vendor.FETCH, {
            params: data
          })
          .then(result => {
            const data = VendorModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showVendor(data: VendorValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(Vendor.SHOW, {
            params: data
          })
          .then(result => {
            const data = VendorModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeVendor(data: VendorValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Vendor.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkVendor(data: VendorValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Vendor.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateVendor(data: VendorValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(Vendor.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteVendor(data: VendorValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(Vendor.DELETE + data.uid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
