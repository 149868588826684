import { ApiClient } from '@/main/apiClient';
import UserHeirInterface, { UserHeirValue } from '@/domain/repository/interface/user_heir_repository.ts';
import { UserHeir } from '@/domain/constant/api_list';
import { PaginationModel } from '@/domain/model/pagination_model';
import { UserHeirModel } from '@/domain/model/user_heir_model.ts';

export class UserHeirRepository implements UserHeirInterface {
  api = new ApiClient();

  async fetchUserHeir(data: UserHeirValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(UserHeir.FETCH, {
            params: data
          })
          .then(result => {
            const data = UserHeirModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showUserHeir(data: UserHeirValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(UserHeir.SHOW, {
            params: data
          })
          .then(result => {
            const data = UserHeirModel.fromJsonList(result.data.data.result);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateUserHeir(data: UserHeirValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(UserHeir.UPDATE + data.uid, data)
          .then(result => {
            const data = UserHeirModel.fromJsonList(result.data.data.result);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteUserHeir(data: UserHeirValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(UserHeir.DELETE + data.uid, {
            params: data
          })
          .then(result => {
            const data = UserHeirModel.fromJsonList(result.data.data.result);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkUserHeir(data: UserHeirValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(UserHeir.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeUserHeir(data: UserHeirValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(UserHeir.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
