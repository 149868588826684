import { BaseModel } from '@/domain/model/base_model';
import { RoleModel } from './role_model';

export class RoleAccessModel extends BaseModel {
  roleUUID: string;
  access: string;
  role: RoleModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    roleUUID: string,
    access: string,
    role: RoleModel
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);

    this.roleUUID = roleUUID;
    this.access = access;
    this.role = role;
  }

  public static fromJson(data: any): RoleAccessModel | any {
    try {
      return new RoleAccessModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.role_uuid,
        data.access,
        data.role
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): RoleAccessModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(RoleAccessModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
