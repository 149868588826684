import { BsFilter } from 'react-icons/bs';

import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Theader, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import TextField from '@/delivery/components/atoms/text_field/text_field';

import Typography from '@/delivery/components/atoms/typography/typography';
import Card from '@/delivery/components/atoms/card/card';

const StockATMGold = () => {
  return (
    <div>
      <div className="flex gap-[24px]">
        {[1, 2, 3, 4, 5].map(() => (
          <Card className="flex flex-col gap-[16px]">
            <Typography size={12} fontWeight={500} type="tertiary-invert">
              Gramasi 2gr
            </Typography>
            <Typography size={24} fontWeight={600}>
              109
            </Typography>
            <hr className="text-border-secondary" />
            <div className="flex-col flex gep-[4px]">
              <div className=" flex justify-between">
                <Typography tag="span" size={14} fontWeight={400} type="tertiary-invert">
                  Nomor Rak 1:
                </Typography>
                <Typography tag="span" size={14} fontWeight={500}>
                  14
                </Typography>
              </div>
              <div className=" flex justify-between">
                <Typography tag="span" size={14} fontWeight={400} type="tertiary-invert">
                  Nomor Rak 1:
                </Typography>
                <Typography tag="span" size={14} fontWeight={500}>
                  14
                </Typography>
              </div>
            </div>
          </Card>
        ))}
      </div>
      <div className="mt-[32px]">
        <Theader>
          <TextField search placeholder="Search" />
          <div className="flex gap-[12px]">
            <Button label="More Filter" type="secondary" prefixIcon={<BsFilter />} />
          </div>
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>TrxID</Th>
              <Th>Date</Th>
              <Th>Member</Th>
              <Th>Type</Th>
              <Th>Flag</Th>
              <Th>Stock 2gr</Th>
              <Th>Stock 3gr</Th>
              <Th>Stock 5gr</Th>
              <Th>Stock 10gr</Th>
            </Tr>
          </Thead>
          <Tbody>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    503
                  </Typography>
                </Td>
                <Td>
                  <Typography size={12} fontWeight={400}>
                    2023-05-22 21:04:56
                  </Typography>
                </Td>
                <Td>
                  <div className="text-right">
                    <Typography fontWeight={600} size={12} type="secondary-invert">
                      Ivan Ryan
                    </Typography>

                    <Typography fontWeight={400} size={10} type="tertiary-invert">
                      iva@gmail.com
                    </Typography>
                  </div>
                </Td>
                <Td>
                  <Typography size={12} fontWeight={400}>
                    IN
                  </Typography>
                </Td>
                <Td>
                  <Typography size={12} fontWeight={400}>
                    Update Stock
                  </Typography>
                </Td>
                <Td>
                  <Typography size={12} fontWeight={400}>
                    10
                  </Typography>
                </Td>
                <Td>
                  <Typography size={12} fontWeight={400}>
                    5
                  </Typography>
                </Td>
                <Td>
                  <Typography size={12} fontWeight={400}>
                    5
                  </Typography>
                </Td>
                <Td>
                  <Typography size={12} fontWeight={400}>
                    5
                  </Typography>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter></Tfooter>
      </div>
    </div>
  );
};

export default StockATMGold;
