import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import { PaginationModel } from '@/domain/model/pagination_model';
import { UserVerificationModel } from '@/domain/model/user_verification_model';
import { UserVerificationRepository } from '@/domain/repository/user_verification_repository';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { RegionProvinceModel } from '@/domain/model/region_province_model.ts';
import { RegionCityModel } from '@/domain/model/region_city_model.ts';
import { RegionDistrictModel } from '@/domain/model/region_district_model.ts';
import { RegionVillageModel } from '@/domain/model/region_village_model.ts';
import { RegionRepository } from '@/domain/repository/region_repository.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';
import { ProfileRepository } from '@/domain/repository/profile_repository.ts';
import { ProfileModel } from '@/domain/model/profile_model.ts';
import { MasterBankRepository } from '@/domain/repository/master_bank_repository.ts';
import { MasterBankModel } from '@/domain/model/master_bank_model.ts';
import { UserWithdrawalAccountRepository } from '@/domain/repository/user_withdrawal_account_repository.ts';
import { UserWithdrawalAccountModel } from '@/domain/model/user_withdrawal_account_model.ts';
import { MEMBER_E_MONEY_TYPE } from '@/domain/constant/member_e_money/member_e_money_type.ts';
import UploadMedia from '@/infrastructure/helper/uploadMedia.tsx';
import MEDIA_PATH from '@/domain/constant/media/media_path.ts';
import AddressModel from '@/domain/model/address_model.ts';
import { AddressRepository } from '@/domain/repository/address_repository.ts';
import { NATIONAL_ID } from '@/domain/constant/national_id.ts';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';

interface MemberVerifyContextContextProps {
  children: ReactNode;
}

interface MemberVerificationValue {
  page?: number;
  limit?: number;
  user_uid?: string;
}

interface FormMemberVerify {
  uid?: string;
  flags?: string[];
  status?: string;
  user_uid?: string;
  name?: string;
  gender?: string;
  religion?: string;
  birth_place?: string;
  birth_date?: string;
  identity_number?: string;
  identity_media_uid?: string;
  bank_media_uid?: string;
  selfie_media_uid?: string;
  master_bank_uuid?: string;
  type?: string;
  account_number?: string;
  account_holder_name?: string;
  notes?: string;
  media_uid?: string;
  latitude?: string;
  longitude?: string;
  full_address?: string;
  national_id?: string;
  province_id?: number;
  city_id?: number;
  district_id?: number;
  village_id?: number;
  zip_code?: string;

  selfie_media_url?: string;
  media_url?: string;
  identity_media_url?: string;

  identity_media?: any;
  selfie_media?: any;
}

interface FormUserWithdrawal {
  uid?: string;
  master_bank_uuid?: string;
  account_number?: string;
  account_holder_name?: string;
  media?: any;
  media_uid?: string;
  status?: string;
  user_uuid?: string;
}

interface FormFilterMemberVerify {
  status?: string;
  search?: string;
  account_holder_name?: string;
  account_number?: string;
  master_bank_uuid?: string;
  reg_start?: string;
  reg_end?: string;
}

interface MemberVerifyContextValue {
  userWithdrawal?: UserWithdrawalAccountModel;
  formUserWithdrawal: FormUserWithdrawal;
  masterBanks: MasterBankModel[];
  user?: ProfileModel;
  formFilterMemberVerify: FormFilterMemberVerify;
  setFormFilterMemberVerify: Dispatch<SetStateAction<FormFilterMemberVerify>>;
  userVerification?: UserVerificationModel;
  formMemberVerify: FormMemberVerify;
  setFormUserWithdrawal: Dispatch<SetStateAction<FormUserWithdrawal>>;
  setFormMemberVerify: Dispatch<SetStateAction<FormMemberVerify>>;
  userVerificationPagination?: PaginationModel;
  userVerifications: UserVerificationModel[];
  handleDetailMemberVerifyModal: ModalObject;
  handleEditUserWithdrawalModal: ModalObject;
  handleEditAddressModal: ModalObject;
  handleEditDocumentModal: ModalObject;
  handleSubmitAddCounterGold: () => void;
  handleFetchUserDataVerification: (_data: MemberVerificationValue) => void;
  handleShowMemberVerify: (_data: FormMemberVerify) => void;
  handleUpdateMemberVerify: () => void;
  handleShowUser: (_data: MemberVerificationValue) => void;
  handleFetchMasterBank: () => void;
  handleShowUpdateIdentityModal: ModalObject;
  handleUpdateDataIdentity: () => void;
  handleShowUserWithdrawal: (_data: ProfileModel) => void;
  handleUpdateUserWithdrawal: () => void;
  handleUpdateDocument: () => void;
  handleShowAddress: (_data: AddressModel) => void;
  handleUpdateAddress: () => void;
  fullAddress: string;
  setFullAddress: Dispatch<SetStateAction<string>>;
  zipCode: string;
  setZipCode: Dispatch<SetStateAction<string>>;

  provinces: RegionProvinceModel[];
  cities: RegionDistrictModel[];
  districts: RegionDistrictModel[];
  villages: RegionVillageModel[];
  handleFetchProvince: () => void;
  handleFetchCities: () => void;
  handleFetchDistricts: () => void;
  handleFetchVillage: () => void;

  provinceID: number;
  setProvinceID: Dispatch<SetStateAction<number>>;
  cityID: number;
  setCityID: Dispatch<SetStateAction<number>>;
  districtID: number;
  setDistrictID: Dispatch<SetStateAction<number>>;
  villageID: number;
  setVillageID: Dispatch<SetStateAction<number>>;
}

const MemberVerifyContext = createContext<MemberVerifyContextValue | null>(null);

const userVerificationRep = new UserVerificationRepository();
const regionRepository = new RegionRepository();
const userRep = new ProfileRepository();
const masterBankRep = new MasterBankRepository();
const userWithdrawalRep = new UserWithdrawalAccountRepository();
const addressRep = new AddressRepository();

const MemberVerifyProvider: React.FC<MemberVerifyContextContextProps> = ({ children }) => {
  const navigate = useNavigate();
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [userWithdrawal, setUserWithdrawal] = useState<UserWithdrawalAccountModel>();
  const [user, setUser] = useState<ProfileModel>();
  const [userVerification, setUserVerification] = useState<UserVerificationModel>();
  const [userVerifications, setUserVerifications] = useState<UserVerificationModel[]>([]);
  const [userVerificationPagination, setUserVerificationPagination] = useState<PaginationModel>();

  const [masterBanks, setMasterBanks] = useState<MasterBankModel[]>([]);

  const [formMemberVerify, setFormMemberVerify] = useState<FormMemberVerify>({});
  const [formUserWithdrawal, setFormUserWithdrawal] = useState<FormUserWithdrawal>({});

  const [provinces, setProvinces] = useState<RegionProvinceModel[]>([]);
  const [cities, setCities] = useState<RegionCityModel[]>([]);
  const [districts, setDistricts] = useState<RegionDistrictModel[]>([]);
  const [villages, setVillages] = useState<RegionVillageModel[]>([]);

  const [addressUUID, setAddressUUID] = useState<string>('');
  const [provinceID, setProvinceID] = useState<number>(0);
  const [cityID, setCityID] = useState<number>(0);
  const [districtID, setDistrictID] = useState<number>(0);
  const [villageID, setVillageID] = useState<number>(0);
  const [fullAddress, setFullAddress] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');

  const handleDetailMemberVerifyModal = useVisibleComponent(false);
  const handleShowUpdateIdentityModal = useVisibleComponent(false);
  const handleEditUserWithdrawalModal = useVisibleComponent(false);
  const handleEditDocumentModal = useVisibleComponent(false);
  const handleEditAddressModal = useVisibleComponent(false);

  const [formFilterMemberVerify, setFormFilterMemberVerify] = useState<FormFilterMemberVerify>({});

  //HANDLE ADD STOCK
  const handleSubmitAddCounterGold = () => {
    alertToast.loadingAlert('');
  };

  //HANDLE FETCH DATA USER VERIFICATION
  const handleFetchUserDataVerification = (_data: MemberVerificationValue) => {
    const x = alertToast.loadingAlert('Fetching member verify');

    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      search: formFilterMemberVerify.search ?? null,
      account_holder_name: formFilterMemberVerify.account_holder_name ?? null,
      account_number: formFilterMemberVerify.account_number ?? null,
      master_bank_uuid: formFilterMemberVerify.master_bank_uuid ?? null,
      status: formFilterMemberVerify.status ?? null,
      date_range: formFilterMemberVerify.reg_start
        ? [`${formatDateParam(formFilterMemberVerify.reg_start)}`, `${formatDateParam(formFilterMemberVerify.reg_end)}`]
        : null
    };

    userVerificationRep
      .fetchUserVerification(params)
      .then((result: any) => {
        setUserVerifications(result.data);
        setUserVerificationPagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW USER
  const handleShowUser = (_data: MemberVerificationValue) => {
    const params = {
      uuid: _data.user_uid
    };

    userRep
      .showProfile(params)
      .then((result: any) => {
        setUser(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW MEMBER VERIFY
  const handleShowMemberVerify = (_data: FormMemberVerify) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.uid
    };

    userVerificationRep
      .showUserVerification(params)
      .then((res: any) => {
        const result: UserVerificationModel = res;
        setUserVerification(result);
        setFormMemberVerify({
          uid: result.UID,
          name: result.name,
          identity_number: result.identityNumber,
          religion: result.user.religion,
          birth_place: result.user.birthPlace,
          birth_date: result.user.birthDate,
          gender: result.user.gender,
          full_address: result.fullAddress,
          zip_code: result.zipCode,
          media_url: result.getMediaUrl,
          selfie_media_url: result.getSelfieMediaUrl,
          identity_media_url: result.getIdentityMediaUrl,
          status: result.status,
          notes: result.notes,
          user_uid: result.userUID,
          media_uid: result.mediaUID,
          identity_media_uid: result.identityMediaUID,
          selfie_media_uid: result.selfieMediaUID,
          master_bank_uuid: result.masterBankUUID,
          type: result.type,
          account_number: result.accountNumber,
          account_holder_name: result.accountHolderName,
          bank_media_uid: result.masterBankUUID
        });
        setProvinceID(parseInt(result.provinceID));
        setCityID(parseInt(result.cityID));
        setDistrictID(parseInt(result.districtID));
        setVillageID(parseInt(result.villageID));

        handleDetailMemberVerifyModal.setState(true);

        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE DATA KTP
  const handleUpdateDataIdentity = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      uid: formMemberVerify.uid,
      user_uid: formMemberVerify.user_uid,
      identity_number: formMemberVerify.identity_number,
      status: formMemberVerify.status
    };

    userVerificationRep
      .updateUserVerification(params)
      .then(() => {
        //UPDATE DATA PROFILE USER
        const paramProfile = {
          uuid: formMemberVerify.user_uid,
          birth_place: formMemberVerify.birth_place,
          birth_date: formMemberVerify.birth_date,
          religion: formMemberVerify.religion,
          gender: formMemberVerify.gender
        };

        //UPDATE DATA USER
        userRep
          .updateProfile(paramProfile)
          .then(() => {
            handleShowMemberVerify({ uid: formMemberVerify.uid });
            alertToast.successAlert();
            handleShowUpdateIdentityModal.setState(false);
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW USER WITHDRAWAL
  const handleShowUserWithdrawal = (_data: ProfileModel) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uid: _data.userVerification.UID
    };

    userVerificationRep
      .showUserVerification(params)
      .then((result: any) => {
        const res: UserVerificationModel = result;

        setFormUserWithdrawal({
          uid: res.UID,
          account_holder_name: res.accountHolderName,
          account_number: res.accountNumber,
          master_bank_uuid: res.masterBankUUID,
          media_uid: res.mediaUID,
          status: res.status,
          user_uuid: res.userUID
        });

        //SHOW USER WITHDRAWAL
        const userWithdrawalBank = _data.userWithdrawal.find(item => item.type === MEMBER_E_MONEY_TYPE.BANK);

        if (userWithdrawalBank) {
          const paramsWithdrawal = {
            uid: userWithdrawalBank?.UID
          };

          userWithdrawalRep
            .showUserWithdrawalAccount(paramsWithdrawal)
            .then((result: any) => {
              setUserWithdrawal(result);
              handleEditUserWithdrawalModal.setState(true);
              alertToast.updateLoading(x);
            })
            .catch(error => {
              alertToast.updateLoading(x);
              alertToast.errorAlert(error);
            });
        } else {
          handleEditUserWithdrawalModal.setState(true);
          alertToast.updateLoading(x);
        }
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE USER WITHDRAWAL
  const handleUpdateUserWithdrawal = async () => {
    const x = alertToast.loadingAlert('');

    let mediaUUID = '';
    if (formUserWithdrawal.media) {
      mediaUUID = await UploadMedia(formUserWithdrawal.media, MEDIA_PATH.CHECKING_ACCOUNT);
    } else {
      mediaUUID = '';
    }

    const params = {
      uid: formUserWithdrawal.uid,
      master_bank_uuid: formUserWithdrawal.master_bank_uuid,
      account_number: formUserWithdrawal.account_number,
      account_holder_name: formUserWithdrawal.account_holder_name,
      media_uid: mediaUUID ? mediaUUID : formUserWithdrawal.media_uid,
      status: formUserWithdrawal.status,
      user_uid: formUserWithdrawal.user_uuid
    };

    userVerificationRep
      .updateUserVerification(params)
      .then((result: any) => {
        handleShowMemberVerify({ uid: formMemberVerify.uid });

        //UPDATE USER WITHDRAWAL
        if (userWithdrawal) {
          const paramsWithdrawal = {
            uid: userWithdrawal?.UID,
            master_bank_uuid: formUserWithdrawal.master_bank_uuid,
            account_number: formUserWithdrawal.account_number,
            account_holder_name: formUserWithdrawal.account_holder_name,
            media_uid: mediaUUID ? mediaUUID : formUserWithdrawal.media_uid,
            status: userWithdrawal?.status,
            user_uid: formUserWithdrawal.user_uuid
          };

          userWithdrawalRep
            .updateUserWithdrawalAccount(paramsWithdrawal)
            .then((result: any) => {
              setUserWithdrawal(result);
              alertToast.successAlert();
              handleEditUserWithdrawalModal.setState(false);
              alertToast.updateLoading(x);
            })
            .catch(error => {
              alertToast.updateLoading(x);
              alertToast.errorAlert(error);
            });
        } else {
          alertToast.successAlert();
          alertToast.updateLoading(x);
          handleEditUserWithdrawalModal.setState(false);
        }
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE DATA USER
  const handleUpdateDocument = async () => {
    const x = alertToast.loadingAlert('');

    let identityMediaUID = null;
    if (formMemberVerify.identity_media) {
      identityMediaUID = await UploadMedia(formMemberVerify.identity_media, MEDIA_PATH.IDENTITY);
    } else {
      identityMediaUID = null;
    }

    let selfieMediaUID = null;
    if (formMemberVerify.selfie_media) {
      selfieMediaUID = await UploadMedia(formMemberVerify.selfie_media, MEDIA_PATH.SELFIE);
    } else {
      selfieMediaUID = null;
    }

    const params = {
      uid: formMemberVerify.uid,
      identity_media_uid: identityMediaUID ? identityMediaUID : formMemberVerify.identity_media_uid,
      selfie_media_uid: selfieMediaUID ? selfieMediaUID : formMemberVerify.selfie_media_uid,
      status: formMemberVerify.status,
      user_uid: formMemberVerify.user_uid
    };

    userVerificationRep
      .updateUserVerification(params)
      .then(() => {
        handleShowMemberVerify({ uid: formMemberVerify.uid });
        handleEditDocumentModal.setState(false);
        alertToast.updateLoading(x);
        alertToast.successAlert();
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW USER ADDRESS
  const handleShowAddress = (_data: AddressModel) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: _data.UUID
    };

    addressRep
      .showAddress(params)
      .then((result: any) => {
        const res: AddressModel = result;

        setAddressUUID(res.UUID);
        setProvinceID(parseInt(res.provinceID));
        setCityID(parseInt(res.cityID));
        setDistrictID(parseInt(res.districtID));
        setVillageID(parseInt(res.villageID));
        setFullAddress(res.fullAddress);
        setZipCode(res.zipCode);

        handleEditAddressModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW USER ADDRESS
  const handleUpdateAddress = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: addressUUID,
      city_id: cityID,
      district_id: districtID,
      full_address: fullAddress,
      national_id: NATIONAL_ID,
      province_id: provinceID,
      village_id: villageID,
      zip_code: zipCode
    };

    addressRep
      .updateAddress(params)
      .then(() => {
        // handleShowMemberVerify({ uid: formMemberVerify.uid });
        handleShowUser({ user_uid: formMemberVerify.user_uid });
        handleEditAddressModal.setState(false);
        alertToast.updateLoading(x);
        alertToast.successAlert();
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE MEMBER VERIFY
  const handleUpdateMemberVerify = () => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params: FormMemberVerify = {
          // uid: formMemberVerify.uid,
          name: formMemberVerify.name,
          identity_number: formMemberVerify.identity_number,
          religion: formMemberVerify.religion,
          birth_place: formMemberVerify.birth_place,
          birth_date: formMemberVerify.birth_date,
          gender: formMemberVerify.gender,
          full_address: formMemberVerify.full_address,
          zip_code: formMemberVerify.zip_code,
          // media_url: formMemberVerify.getMediaUrl,
          // selfie_media_url: formMemberVerify.getSelfieMediaUrl,
          // identity_media_url: formMemberVerify.getIdentityMediaUrl,
          status: formMemberVerify.status,
          notes: formMemberVerify.notes,
          province_id: provinceID,
          city_id: cityID,
          district_id: districtID,
          village_id: villageID,
          user_uid: formMemberVerify.user_uid,
          identity_media_uid: formMemberVerify.identity_media_uid,
          selfie_media_uid: formMemberVerify.selfie_media_uid,
          master_bank_uuid: formMemberVerify.master_bank_uuid,
          type: formMemberVerify.type,
          account_number: formMemberVerify.account_number,
          account_holder_name: formMemberVerify.account_holder_name,
          bank_media_uid: formMemberVerify?.media_uid
        };

        userVerificationRep
          .storeUserVerificationDashboard(params)
          .then(() => {
            alertToast.updateLoading(x);
            handleDetailMemberVerifyModal.setState(false);
            handleFetchUserDataVerification({ page: 1 });
            navigate(Routing.DATA_MEMBER.MEMBER_VERIFY);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const handleFetchMasterBank = () => {
    const x = alertToast.loadingAlert('Fetching master bank');

    const params = {
      page: 1,
      limit: -1,
      e_money: false
    };

    masterBankRep
      .fetchMasterBank(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setMasterBanks(result.data);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  // --- LOCATION FETCH AND SHOW BELOW ---
  //HANDLE FETCH PROVINCE
  const handleFetchProvince = () => {
    regionRepository
      .fetchProvince()
      .then((result: any) => {
        setProvinces(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH CITIES
  const handleFetchCities = () => {
    const params = {
      parent_id: provinceID
    };
    regionRepository
      .fetchCities(params)
      .then((result: any) => {
        setCities(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH DISTRICT
  const handleFetchDistricts = () => {
    const params = {
      parent_id: cityID
    };
    regionRepository
      .fetchDistrict(params)
      .then((result: any) => {
        setDistricts(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH VILLAGE
  const handleFetchVillage = () => {
    const params = {
      parent_id: districtID
    };

    regionRepository
      .fetchVillage(params)
      .then((result: any) => {
        setVillages(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const contextValue: MemberVerifyContextValue = {
    fullAddress,
    setFullAddress,
    zipCode,
    setZipCode,
    handleUpdateUserWithdrawal,
    handleShowUserWithdrawal,
    userWithdrawal,
    formUserWithdrawal,
    setFormUserWithdrawal,
    user,
    handleShowUser,
    formFilterMemberVerify,
    setFormFilterMemberVerify,
    userVerification,
    userVerificationPagination,
    userVerifications,
    handleDetailMemberVerifyModal,
    handleShowUpdateIdentityModal,
    handleEditUserWithdrawalModal,
    handleEditAddressModal,
    handleEditDocumentModal,
    formMemberVerify,
    handleUpdateDataIdentity,
    handleUpdateMemberVerify,
    setFormMemberVerify,
    handleSubmitAddCounterGold,
    handleFetchUserDataVerification,
    handleShowMemberVerify,
    masterBanks,
    handleFetchMasterBank,
    handleUpdateDocument,
    handleShowAddress,
    handleUpdateAddress,

    provinces,
    cities,
    districts,
    villages,
    handleFetchProvince,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    villageID,
    setVillageID
  };

  return <MemberVerifyContext.Provider value={contextValue}>{children}</MemberVerifyContext.Provider>;
};

const useMemberVefiryContext = (): MemberVerifyContextValue => {
  const context = useContext(MemberVerifyContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a MemberVefiryProvider');
  }
  return context;
};

export { MemberVerifyProvider, useMemberVefiryContext };
