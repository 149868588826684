import Select from 'react-select';
import clsx from 'clsx';

interface Select2props {
  label?: string;
  block?: boolean;
  options?: Array<{ label: any; value: string }>;
  value?: any;
  defaultValue?: any;
  onChange?: (e: any) => void;
  placeholder?: string;
  disabled?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  required?: boolean;
  ref?: any;
}

const Select2 = (props: Select2props) => {
  const {
    options,
    label,
    block,
    value,
    defaultValue,
    onChange,
    placeholder,
    disabled,
    isSearchable,
    isClearable,
    isMulti = false,
    required = false,
    ref
  } = props;
  return (
    <div className={`${block ? 'w-full' : ''} flex flex-col`}>
      <div className={'flex gap-[3px]'}>
        {label ? <div className="text-text-secondary-invert text-[14px] text-[500] mb-[6px]">{label}</div> : ''}
        {required && <span className="text-text-danger text-xs">*</span>}
      </div>

      <Select
        ref={ref}
        value={
          value && isMulti
            ? value.map((item: any) => options?.find(option => option.value === item))
            : options?.find(option => (option.value === value ? value : defaultValue))
        }
        classNames={{
          control: () =>
            clsx(
              'border-border-tertiary border focus:outline-border-secondary text-text-primary-invert text-[400] text-[16px] py-[8px] px-[10px] rounded-[8px]'
            ),
          menu: () =>
            clsx(
              'mt-2 bg-text-white rounded-md transition duration-300 ease-in-out cursor-pointer overflow-hidden border-border-tertiary border'
            ),
          option: ({ isFocused, isSelected }) =>
            clsx(
              'flex w-full px-3 py-2 cursor-pointer transition duration-300 ease-in-out cursor-pointer',
              isFocused && 'bg-content-bg-secondary',
              isSelected && 'bg-content-bg-secondary'
            ),

          multiValueLabel: () => clsx('font-semibold'),
          multiValueRemove: () => clsx('cursor-pointer'),
          noOptionsMessage: () => clsx('px-3 py-2'),
          placeholder: () => clsx('text-sm')
        }}
        noOptionsMessage={() => 'No Data Found'}
        unstyled={true}
        styles={{
          control: provided => ({
            ...provided,
            outline: 'outline',
            cursor: 'pointer',
            zIndex: 0
          }),
          option: provided => ({
            ...provided,
            cursor: 'pointer'
          })
        }}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        isDisabled={disabled}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isMulti={isMulti}
      />
    </div>
  );
};

export default Select2;
