import { BaseModel } from '@/domain/model/base_model';
import { formatDate, formatDateTime } from '@/infrastructure/helper/formatDate.ts';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';
import { ProfileModel } from '@/domain/model/profile_model.ts';

export class GoldRateModel extends BaseModel {
  buy: number;
  sell: number;
  status: string;
  updatedByUser: ProfileModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    buy: number,
    sell: number,
    status: string,
    updatedByUser: ProfileModel
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.buy = buy;
    this.sell = sell;
    this.status = status;
    this.updatedByUser = updatedByUser;
  }

  get getCreatedAt(): string {
    if (this.createdAt) {
      return formatDateTime(this.createdAt);
    }
    return '-';
  }

  get getCreatedAtDate(): string {
    if (this.createdAt) {
      return formatDate(this.createdAt);
    }
    return '-';
  }

  get getUpdatedAt(): string {
    if (this.updatedAt) {
      return formatDateTime(this.updatedAt);
    }
    return '-';
  }

  get getUpdatedBy(): string {
    if (this.updatedBy) {
      return this.updatedBy;
    }
    return '-';
  }

  get getBuy(): string {
    if (this.buy) {
      return formatRupiah(this.buy);
    }
    return '-';
  }

  get getSell(): string {
    if (this.sell) {
      return formatRupiah(this.sell);
    }
    return '-';
  }

  public static fromJson(data: any): any | GoldRateModel {
    try {
      return new GoldRateModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.buy,
        data.sell,
        data.status,
        data.updated_by_user ? ProfileModel.fromJson(data.updated_by_user) : null
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): GoldRateModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(GoldRateModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
