import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
// import { AffiliateRepository } from '@/domain/repository/affiliate_repository.ts';
// import { AffiliateModel } from '@/domain/model/affiliate_model.ts';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { AffiliateUserRepository } from '@/domain/repository/affiliate_user_repository.ts';
import { AffiliateRepository } from '@/domain/repository/affiliate_repository.ts';
import { AffiliateModel } from '@/domain/model/affiliate_model.ts';
import { ProfileRepository } from '@/domain/repository/profile_repository.ts';
import { ProfileModel } from '@/domain/model/profile_model.ts';

interface AffiliateContextContextProps {
  children: ReactNode;
}

interface AffiliateValue {
  page?: number;
  limit?: number;
  search?: string;
  user_uuid?: string;
}

interface FormAffiliateValue {
  commission?: number;
  commission_type?: string;
  type?: string;
}

interface FormFilterAffiliateList {
  search?: string;
}

interface AffiliateContextValue {
  profileAffiliates: AffiliateModel[];
  user?: ProfileModel;
  affiliateUsersExport: AffiliateModel[];
  formFilterAffiliateList: FormFilterAffiliateList;
  setFormFilterAffiliateList: Dispatch<SetStateAction<FormFilterAffiliateList>>;
  formAffiliate: FormAffiliateValue;
  setFormAffiliate: Dispatch<SetStateAction<FormAffiliateValue>>;
  affiliatePagination?: PaginationModel;
  affiliateUsers: AffiliateModel[];
  handleCreateMemberAffiliateModal: ModalObject;
  handleExportDataAffiliateListModal: ModalObject;
  handleFetchAffiliateList: (_data: AffiliateValue) => Promise<void>;
  handleStoreAffiliateList: () => void;
  handleExportAffiliateList: () => void;
  handleShowProfileUser: (_data: AffiliateValue) => void;
  handleFetchProfileAffiliate: (_data: AffiliateValue) => void;
}

const MemberVerifyContext = createContext<AffiliateContextValue | null>(null);

// const affiliateList = new AffiliateRepository();
const affiliateUserRep = new AffiliateUserRepository();
const affiliateRep = new AffiliateRepository();
const userRep = new ProfileRepository();

const AffiliateListProvider: React.FC<AffiliateContextContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const handleCreateMemberAffiliateModal = useVisibleComponent(false);
  const handleExportDataAffiliateListModal = useVisibleComponent(false);

  const [affiliateUsersExport, setAffiliateUsersExport] = useState<AffiliateModel[]>([]);
  const [affiliateUsers, setAffiliateUsers] = useState<AffiliateModel[]>([]);

  const [profileAffiliates, setProfileAffiliates] = useState<AffiliateModel[]>([]);
  const [affiliatePagination, setAffiliatePagination] = useState<PaginationModel>();

  const [formAffiliate, setFormAffiliate] = useState<FormAffiliateValue>({});

  const [formFilterAffiliateList, setFormFilterAffiliateList] = useState<FormFilterAffiliateList>({});

  const [user, setUser] = useState<ProfileModel>();

  //HANDLE FETCH AFFILIATE
  const handleFetchAffiliateList = async (_data: AffiliateValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      search: formFilterAffiliateList.search || null
    };

    return affiliateRep
      .fetchAffiliateUser(params)
      .then((result: any) => {
        setAffiliateUsers(result.data);
        setAffiliatePagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH PROFILE AFFILIATE
  const handleFetchProfileAffiliate = (_data: AffiliateValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      user_uid: _data.user_uuid
    };

    affiliateRep
      .fetchAffiliateUser(params)
      .then((result: any) => {
        setProfileAffiliates(result.data);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH AFFILIATE
  const handleStoreAffiliateList = () => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        const params = {
          commission: formAffiliate.commission,
          commission_type: 'percent',
          type: formAffiliate.type
        };

        affiliateUserRep
          .storeAffiliateUser(params)
          .then((result: any) => {
            handleCreateMemberAffiliateModal.setState(false);
            handleFetchAffiliateList({ page: 1 });
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE EXPORT AFFILIATE LIST
  const handleExportAffiliateList = () => {
    const x = alertToast.loadingAlert('Preparing Data');

    const params = {
      page: 1,
      limit: -1,
      search: formFilterAffiliateList.search || null
    };

    affiliateRep
      .fetchAffiliateCount(params)
      .then((result: any) => {
        handleExportDataAffiliateListModal.setState(true);
        setAffiliateUsersExport(result.data);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW PROFILE USER
  const handleShowProfileUser = (_data: AffiliateValue) => {
    const params = {
      uuid: _data.user_uuid
    };

    userRep
      .showProfile(params)
      .then((result: any) => {
        setUser(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const contextValue: AffiliateContextValue = {
    profileAffiliates,
    user,
    affiliateUsersExport,
    formFilterAffiliateList,
    setFormFilterAffiliateList,
    formAffiliate,
    setFormAffiliate,
    affiliatePagination,
    affiliateUsers,
    handleCreateMemberAffiliateModal,
    handleExportDataAffiliateListModal,
    handleFetchAffiliateList,
    handleStoreAffiliateList,
    handleExportAffiliateList,
    handleShowProfileUser,
    handleFetchProfileAffiliate
  };

  return <MemberVerifyContext.Provider value={contextValue}>{children}</MemberVerifyContext.Provider>;
};

const useAffiliateListContext = (): AffiliateContextValue => {
  const context = useContext(MemberVerifyContext);
  if (!context) {
    throw new Error('useAffiliateListContext must be used within a AffiliateListProvider');
  }
  return context;
};

export { AffiliateListProvider, useAffiliateListContext };
