import DCARequestInterface, { DCARequestValue } from '@/domain/repository/interface/dca_request_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { DCARequestModel } from '@/domain/model/dca_request_model.ts';
import { DCA, DCARequest } from '@/domain/constant/api_list';
import { DCAModel } from '@/domain/model/dca_model.ts';
import { DCAStatisticModel } from '@/domain/model/dca_statistic_model.ts';

export class DCARequestRepository implements DCARequestInterface {
  api = new ApiClient();

  async showDCAStatistic() {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(DCARequest.STATISTIC, {})
          .then(result => {
            const data = DCAStatisticModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchDCARequest(data: DCARequestValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(DCARequest.FETCH, {
            params: data
          })
          .then(result => {
            const data = DCARequestModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchDCA(data: DCARequestValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(DCA.FETCH, {
            params: data
          })
          .then(result => {
            const data = DCAModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showDCARequest(data: DCARequestValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(DCARequest.SHOW, {
            params: data
          })
          .then(result => {
            const data = DCARequestModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeDCARequest(data: DCARequestValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(DCARequest.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkDCARequest(data: DCARequestValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(DCARequest.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateDCARequest(data: DCARequestValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(DCARequest.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteDCARequest(data: DCARequestValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(DCARequest.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
