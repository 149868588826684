import Moment from 'moment-timezone';

// export const formatDate = (date: any) => format(date, 'dd-MM-yyyy');

// DD MMM YYYY HH:mm:ss
export const formatDateTime = (date: any) => {
  //   Moment.locale('en');
  // const dateNow = Moment(date).format('DD MMM YYYY');
  //
  // const dateObject = new Date(date);
  //
  // const hours = dateObject.getUTCHours().toString().padStart(2, '0');
  // const minutes = dateObject.getUTCMinutes().toString().padStart(2, '0');
  // const second = dateObject.getUTCSeconds().toString().padStart(2, '0');
  //
  // const hourNow = `${hours}:${minutes}:${second}`;
  //
  // return `${dateNow} ${hourNow}`;
  const dateMoment = Moment(date).local();

  // Format tanggal
  const dateNow = dateMoment.format('DD MMM YYYY HH:mm:ss');

  return `${dateNow}`;
};

export const formatDateTimeYMD = (date: any) => {
  //   Moment.locale('en');
  return Moment(date).utc().format('YYYY MMM DD HH:mm:ss');
};

// DD MMM YYYY
export const formatDate = (date: any) => {
  //   Moment.locale('en');
  return Moment(date).format('DD MMM YYYY');
};

export const formatTime = (date: any) => {
  const dateObject = new Date(date);

  const hours = dateObject.getUTCHours().toString().padStart(2, '0');
  const minutes = dateObject.getUTCMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;

  // return Moment(date).format('HH:mm');
};

export const formatDateParam = (date: any) => {
  //   Moment.locale('en');
  if (date) {
    return Moment(date).format('YYYY-MM-DD');
  }
  return '';
};

export const formatDateTimeParam = (date: any) => {
  //   Moment.locale('en');
  if (date) {
    return Moment(date).format('YYYY-MM-DD HH:mm:ss');
  }
  return '';
};

export const formatDateRange = (date1: any, date2: any) => {
  //   Moment.locale('en');
  const date1Day = Moment(date1).format('DD');
  const date2Day = Moment(date2).format('DD');
  const date1Month = Moment(date1).format('MMM');
  const date2Month = Moment(date2).format('MMM');

  if (date1Day === date2Day) {
    return `${Moment(date1).format('DD MMM YYYY')}`;
  } else {
    if (date1Month === date2Month) {
      return `${Moment(date1).format('DD')} - ${Moment(date2).format(
        'DD'
      )} ${date1Month} ${Moment(date1).format('YYYY')}`;
    } else {
      return `${Moment(date1).format('DD MMM')} - ${Moment(date2).format('DD MMM')} ${Moment(date1).format('YYYY')}`;
    }
  }
};

export const formatDateTimeTParam = (dateString: string | undefined) => {
  if (dateString) {
    const inputDate = new Date(dateString);

    // Get the UTC time by adding the time zone offset
    const utcTime = inputDate.getTime() + inputDate.getTimezoneOffset() * 60000;

    // Create a new Date object with the UTC time
    const utcDate = new Date(utcTime);

    // Adjust the date to the next day
    utcDate.setDate(utcDate.getDate() + 1);

    // Format the date according to ISO 8601 format
    const isoDateString = utcDate.toISOString();

    return isoDateString;
  }
};
