import { Table, TBox, Td, Th, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useHistoryContext } from '@/delivery/ui/admin/transaction/take_gold/history/context/history_context.tsx';
import { useEffect, useState } from 'react';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import CONFIRM_FLOW_TYPE from '@/domain/constant/history/confirm_flow_type.ts';
import { useParams } from 'react-router-dom';
import DialogRejectPrintGold from '@/delivery/ui/admin/transaction/take_gold/history/detail/dialog/dialog_confirm_reject_print_gold.tsx';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';
import Icon from '@/domain/constant/icon.tsx';
import { AWB_LINK } from '@/domain/constant/transaction/awb_link.ts';

const DataPrintGoldHistory = () => {
  const { uid } = useParams();
  const { handleRejectPrintGoldModal, handleConfirmPrintGoldPickUp, history } = useHistoryContext();

  const [goldPieces, setGoldPieces] = useState<string>('');

  const [trxFee, setTrxFee] = useState<string>('');
  const [shippingFee, setShippingFee] = useState<string>('');
  const [dcaFee, setDCAFee] = useState<string>('');
  const [insuranceFee, setInsuranceFee] = useState<string>('');

  useEffect(() => {
    if (history) {
      //keping emas
      let result = '';
      history.trxPrint.forEach(item => {
        result += `${item.gram}gr: ${item.quantity} keping`;
        if (item !== history.trxPrint[history.trxPrint.length - 1]) {
          result += ' / ';
        }
      });
      setGoldPieces(result);
    }
  }, [history]);

  useEffect(() => {
    if (history) {
      const feeTrx = formatRupiah(history?.trxFee.find(itemTrx => itemTrx.type === 'transaction_fee')?.amount || 0);
      setTrxFee(feeTrx);

      const feeShipping = formatRupiah(history?.trxFee.find(itemTrx => itemTrx.type === 'shipping_fee')?.amount || 0);
      setShippingFee(feeShipping);

      const feeDCA = formatRupiah(history?.trxFee.find(itemTrx => itemTrx.type === 'dca_fee')?.amount || 0);
      setDCAFee(feeDCA);

      const feeShippingInsurance = formatRupiah(
        history?.trxFee.find(itemTrx => itemTrx.type === 'shipping_insurance_fee')?.amount || 0
      );
      setInsuranceFee(feeShippingInsurance);
    }
  }, [history]);

  return (
    <div>
      <Theader>
        <Typography fontWeight={600} size={16} type="primary-invert">
          Data Cetak Emas
        </Typography>
        <div className={'flex gap-[10px]'}>
          {/*IF STATUS REQUEST*/}
          {history?.status === STATUS_TRANSACTION.REQUEST ? (
            <>
              <Button
                label={'Confirm'}
                onClick={() => handleConfirmPrintGoldPickUp({ flow: CONFIRM_FLOW_TYPE.CONFIRM, trx_uid: uid })}
              />
              <Button type={'danger'} label={'Reject'} onClick={() => handleRejectPrintGoldModal.setState(true)} />
            </>
          ) : (
            ''
          )}

          {/*IF STATUS REQUEST*/}
          {history?.status === STATUS_TRANSACTION.AWAITING_PICKUP ? (
            <>
              <Button
                label={'Finish'}
                onClick={() => handleConfirmPrintGoldPickUp({ flow: CONFIRM_FLOW_TYPE.FINISH, trx_uid: uid })}
              />
              <Button type={'danger'} label={'Reject'} onClick={() => handleRejectPrintGoldModal.setState(true)} />
            </>
          ) : (
            ''
          )}
        </div>
      </Theader>
      <TBox>
        <Table className="rounded-b-[8px]">
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Trx ID
                </Typography>
              </div>
            </Th>
            <Td className={'w-full'}>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.code}
              </Typography>
            </Td>
          </Tr>

          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  AWB
                </Typography>
              </div>
            </Th>
            <Td className={'w-full'}>
              <div className={'flex justify-between items-center'}>
                <Typography tag={'p'} fontWeight={400} size={12} type="primary-invert">
                  {history?.trxShipping?.awb || '-'}
                </Typography>
                {history?.trxShipping ? (
                  <div>
                    <a target={'_blank'} href={`${AWB_LINK}${history?.trxShipping?.awb}`}>
                      <Button iconButton icon={Icon.print} />
                    </a>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Pick Up Code
                </Typography>
              </div>
            </Th>
            <Td className={'w-full'}>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.trxShipping?.pickUpCode || '-'}
              </Typography>
            </Td>
          </Tr>

          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Pick Up Pin
                </Typography>
              </div>
            </Th>
            <Td className={'w-full'}>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.trxShipping?.pickUpPin || '-'}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Keping Cetak
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {goldPieces}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Gramasi / Rate
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.gram} / {history?.rate.getSell}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Amount
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.getAmountSingle}
              </Typography>
            </Td>
          </Tr>

          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  DCA Fee
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {dcaFee}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Insurance Fee
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {insuranceFee}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Transaction Fee
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {trxFee}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Shipping Fee
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {shippingFee}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Total Fee
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.getFeeRupiahSingle}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Alamat Pengiriman
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.trxShipping?.destinationAddress}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Pengambilan
                </Typography>
              </div>
            </Th>
            <Td>
              {history?.trxPrint[0]?.offlineCounter?.name ? (
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {history?.trxPrint[0]?.offlineCounter?.name || '-'}
                </Typography>
              ) : (
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {history?.trxVending?.vendingMachine.name || '-'}
                </Typography>
              )}
            </Td>
          </Tr>

          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  System notes
                </Typography>
              </div>
            </Th>
            <Td>
              <Typography fontWeight={400} size={12} type="primary-invert">
                {history?.systemNotes || '-'}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <div className={'text-nowrap'}>
                <Typography fontWeight={500} size={12} type="tertiary-invert">
                  Status
                </Typography>
              </div>
            </Th>
            <Td>
              <Chips type={history?.getStatusColor} label={history?.status} />
            </Td>
          </Tr>
        </Table>
      </TBox>

      {handleRejectPrintGoldModal.state && <DialogRejectPrintGold modal={handleRejectPrintGoldModal} />}
    </div>
  );
};

export default DataPrintGoldHistory;
