import { BaseModel } from '@/domain/model/base_model.ts';
import { formatDate, formatDateTime } from '@/infrastructure/helper/formatDate.ts';
import formatCurrency from '@/infrastructure/helper/format_currency.ts';
import { TypeFeeConst } from '@/domain/constant/type_fee.tsx';

export class PaymentChannelFeeModel extends BaseModel {
  paymentChannelUID: string;
  fee: number;
  type: string;
  additionalPercentFee: number;
  additionalFlatFee: number;
  effectiveDateStart: string;
  effectiveDateEnd: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    paymentChannelUID: string,
    fee: number,
    type: string,
    additionalPercentFee: number,
    additionalFlatFee: number,
    effectiveDateStart: string,
    effectiveDateEnd: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.paymentChannelUID = paymentChannelUID;
    this.fee = fee;
    this.type = type;
    this.additionalPercentFee = additionalPercentFee;
    this.additionalFlatFee = additionalFlatFee;
    this.effectiveDateStart = effectiveDateStart;
    this.effectiveDateEnd = effectiveDateEnd;
  }

  get getEffectiveDateEnd(): string {
    if (this.effectiveDateEnd) {
      return formatDate(this.effectiveDateEnd);
    }
    return '-';
  }

  get getEffectiveDateStart(): string {
    if (this.effectiveDateStart) {
      return formatDate(this.effectiveDateStart);
    }
    return '-';
  }

  get getCreatedAt(): string {
    if (this.createdAt) {
      return formatDateTime(this.createdAt);
    }
    return '-';
  }

  get getFeeRupiah(): string {
    if (this.fee && this.type === TypeFeeConst.FLAT) {
      return formatCurrency(this.fee);
    }

    if (this.fee && this.type === TypeFeeConst.PERCENT) {
      return `${this.fee}%`;
    }
    return '-';
  }

  public static fromJson(data: any): any | PaymentChannelFeeModel {
    try {
      return new PaymentChannelFeeModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.payment_channel_uid,
        data.fee,
        data.type,
        data.additional_percent_fee,
        data.additional_flat_fee,
        data.effective_date_start,
        data.effective_date_end
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): PaymentChannelFeeModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(PaymentChannelFeeModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
