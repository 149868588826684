import { BaseModel } from '@/domain/model/base_model.ts';

export class UserDetailModel extends BaseModel {
  userUID: string;
  gender: string;
  religion: string;
  birthPlace: string;
  dateOfBirth: string;
  identityNumber: string;
  identityMediaUID: string;
  selfieMediaUID: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    userUID: string,
    gender: string,
    religion: string,
    birthPlace: string,
    dateOfBirth: string,
    identityNumber: string,
    identityMediaUID: string,
    selfieMediaUID: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.userUID = userUID;
    this.gender = gender;
    this.religion = religion;
    this.birthPlace = birthPlace;
    this.dateOfBirth = dateOfBirth;
    this.identityNumber = identityNumber;
    this.identityMediaUID = identityMediaUID;
    this.selfieMediaUID = selfieMediaUID;
  }

  public static fromJson(data: any): any | UserDetailModel {
    try {
      return new UserDetailModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.user_uid,
        data.gender,
        data.religion,
        data.birth_place,
        data.date_of_birth,
        data.identity_number,
        data.identity_media_uid,
        data.selfie_media_uid
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): UserDetailModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(UserDetailModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
