import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import CustomAccessInterface, { CustomAccessValue } from '@/domain/repository/interface/custom_access_interface.ts';
import { CustomAccess } from '@/domain/constant/api_list';
import { CustomAccessModel } from '@/domain/model/custom_access_model.ts';

interface roleValue {
  data: any;
  pagination: any;
}

export class CustomAccessRepository implements CustomAccessInterface {
  api = new ApiClient();

  async fetchCustomAccess(data: CustomAccessValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(CustomAccess.FETCH, {
            params: data
          })
          .then(result => {
            const data = CustomAccessModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role: roleValue = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showCustomAccess(data: CustomAccessValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(CustomAccess.SHOW, {
            params: data
          })
          .then(result => {
            const data = CustomAccessModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeCustomAccess(data: CustomAccessValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(CustomAccess.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkCustomAccess(data: CustomAccessValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(CustomAccess.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateCustomAccess(data: CustomAccessValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(CustomAccess.UPDATE + data.uuid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteCustomAccess(data: CustomAccessValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(CustomAccess.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
