import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { IoWarningOutline } from 'react-icons/io5';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useCorporateContext } from '@/delivery/ui/admin/data_member/member_list/context/corporate_context.tsx';

const DialogConfirmApproveBusiness = (props: ModalDialogProps) => {
  const { user, handleDialogCreateBusinessAccountModal, handleDialogConfirmBusinessModal } = useCorporateContext();

  return (
    <ModalDialog noHeader size="xsmall" title="Lock Member Balance" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <div
          className={
            'font-weight-600 text-[24px] bg-bg-amber text-text-warning rounded-full w-[48px] h-[48px] flex justify-center items-center'
          }
        >
          <IoWarningOutline />
        </div>
        {user?.corporate ? (
          <div>
            <Typography fontWeight={600} size={16}>
              User ini sudah memiliki akun bisnis
            </Typography>
            <Typography fontWeight={400} size={14}>
              Silahkan lihat detail akun bisnis pada detail user
            </Typography>
          </div>
        ) : (
          <div>
            <Typography fontWeight={600} size={16}>
              Approve Akun Bisnis
            </Typography>
            <Typography fontWeight={400} size={14}>
              Apakah Anda akan menjadikan user ini sebagai user bisnis?
            </Typography>
          </div>
        )}
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleDialogConfirmBusinessModal.setState(false)} />
        {!user?.corporate && (
          <Button
            label="Ya, Lanjutkan"
            onClick={() => {
              handleDialogCreateBusinessAccountModal.setState(true);
              handleDialogConfirmBusinessModal.setState(false);
            }}
          />
        )}
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogConfirmApproveBusiness;
