import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';

export default class TransactionChartModel {
  date: string;
  totalFiat: number;
  type: string;

  constructor(date: string, totalFiat: number, type: string) {
    this.date = date;
    this.totalFiat = totalFiat;
    this.type = type;
  }

  public static fromJson(data: any): TransactionChartModel | any {
    try {
      return new TransactionChartModel(
        data.date,
        data.statistic.find(
          (item: any) =>
            item.type === TYPE_TRANSACTION.SELL || (item.type === TYPE_TRANSACTION.BUY && STATUS_TRANSACTION.SUCCESS)
        )?.total_fiat,
        data.statistic.find(
          (item: any) =>
            item.type === TYPE_TRANSACTION.SELL || (item.type === TYPE_TRANSACTION.BUY && STATUS_TRANSACTION.SUCCESS)
        )?.type
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): TransactionChartModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(TransactionChartModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
