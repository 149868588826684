import WithdrawalMethodInterface, {
  WithdrawalMethodValue
} from '@/domain/repository/interface/withdrawal_method_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { WithdrawalMethodModel } from '@/domain/model/withdrawal_method_model.ts';
import { WithdrawalMethod } from '@/domain/constant/api_list';

export class WithdrawalMethodRepository implements WithdrawalMethodInterface {
  api = new ApiClient();

  async fetchWithdrawalMethod(data: WithdrawalMethodValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(WithdrawalMethod.FETCH, {
            params: data
          })
          .then(result => {
            const data = WithdrawalMethodModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showWithdrawalMethod(data: WithdrawalMethodValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(WithdrawalMethod.SHOW, {
            params: data
          })
          .then(result => {
            const data = WithdrawalMethodModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeWithdrawalMethod(data: WithdrawalMethodValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(WithdrawalMethod.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkWithdrawalMethod(data: WithdrawalMethodValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(WithdrawalMethod.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateWithdrawalMethod(data: WithdrawalMethodValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(WithdrawalMethod.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteWithdrawalMethod(data: WithdrawalMethodValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(WithdrawalMethod.DELETE + data.uid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
