import { BaseModel } from '@/domain/model/base_model.ts';
import { ChipType } from '@/domain/constant/component/chip_colors.ts';
import { MEMBER_LOYALTY } from '@/domain/constant/member/member_loyalty.ts';
import { formatDateTime } from '@/infrastructure/helper/formatDate.ts';
import { truncateDecimal } from '@/infrastructure/helper/truncateDecimalNumber.ts';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';

export class UserModelQuery extends BaseModel {
  balance: number;
  corporate: string;
  dcaGram: number;
  dreamGold: number;
  email: string;
  emailVerified: boolean;
  firstName: string;
  flow: string;
  lastName: string;
  lastTrx: number;
  lockMember: number;
  lockSystem: number;
  phone: number;
  registerAt: string;
  serialID: string;
  tier: string;
  totalBalance: number;
  trx: number;
  verificationStatus: string;
  dcaStatus: string;
  status: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    balance: number,
    corporate: string,
    dcaGram: number,
    dreamGold: number,
    email: string,
    emailVerified: boolean,
    firstName: string,
    flow: string,
    lastName: string,
    lastTrx: number,
    lockMember: number,
    lockSystem: number,
    phone: number,
    registerAt: string,
    serialID: string,
    tier: string,
    totalBalance: number,
    trx: number,
    verificationStatus: string,
    dcaStatus: string,
    status: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.balance = balance;
    this.corporate = corporate;
    this.dcaGram = dcaGram;
    this.dreamGold = dreamGold;
    this.email = email;
    this.emailVerified = emailVerified;
    this.firstName = firstName;
    this.flow = flow;
    this.lastName = lastName;
    this.lastTrx = lastTrx;
    this.lockMember = lockMember;
    this.lockSystem = lockSystem;
    this.phone = phone;
    this.registerAt = registerAt;
    this.serialID = serialID;
    this.tier = tier;
    this.totalBalance = totalBalance;
    this.trx = trx;
    this.verificationStatus = verificationStatus;
    this.dcaStatus = dcaStatus;
    this.status = status;
  }

  get getTierColor(): ChipType {
    if (this.tier === MEMBER_LOYALTY.PLATINUM) {
      return 'primary-light';
    }
    if (this.tier === MEMBER_LOYALTY.GOLD) {
      return 'warning-light';
    }
    if (this.tier === MEMBER_LOYALTY.SILVER) {
      return 'info-light';
    }
  }

  get getFullName(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }
    return '-';
  }

  get getCreatedAt(): string {
    if (this.registerAt) {
      return formatDateTime(this.registerAt);
    }
    return '-';
  }

  get getTruncateAmountDecimal(): number {
    if (this.balance) {
      return truncateDecimal(this.balance);
    }

    return 0;
  }

  get getTruncateSystemAmountDecimal(): number {
    if (this.lockSystem) {
      return truncateDecimal(this.lockSystem);
    }

    return 0;
  }

  get getTruncateMemberAmountDecimal(): number {
    if (this.lockMember) {
      return truncateDecimal(this.lockMember);
    }

    return 0;
  }

  get getDCAStatusColor(): ChipType {
    if (this.dcaStatus === STATUS_CORPORATE.VERIFIED) {
      return 'primary-light';
    }

    if (this.dcaStatus === STATUS_CORPORATE.REQUEST) {
      return 'warning-light';
    }

    if (this.dcaStatus === STATUS_CORPORATE.REJECT) {
      return 'danger-light';
    }

    if (this.dcaStatus === STATUS_CORPORATE.UNVERIFIED) {
      return 'danger-light';
    }
  }

  public static fromJson(data: any): any | UserModelQuery {
    try {
      return new UserModelQuery(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.balance,
        data.corporate,
        data.dca_gram,
        data.dream_gold,
        data.email,
        data.email_verified,
        data.first_name,
        data.flow,
        data.last_name,
        data.last_trx,
        data.lock_member,
        data.lock_system,
        data.phone,
        data.register_at,
        data.serial_id,
        data.tier,
        data.total_balance,
        data.trx,
        data.verification_status,
        data.dca_status,
        data.status
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): UserModelQuery[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(UserModelQuery.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
