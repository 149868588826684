import { BaseModel } from '@/domain/model/base_model.ts';
import { UserDetailModel } from '@/domain/model/user_detail_model.ts';
import { UserVerificationModel } from '@/domain/model/user_verification_model.ts';
import { UserWithdrawalAccountModel } from '@/domain/model/user_withdrawal_account_model.ts';
import { UserHeirModel } from '@/domain/model/user_heir_model.ts';
import { DreamGoldModel } from '@/domain/model/dream_gold_model.ts';
import { BalanceModel } from '@/domain/model/balance_model.ts';
import CompanyAddressModel from '@/domain/model/company_address_model.ts';
import { UserModel } from '@/domain/model/user_model.ts';
import { formatDate, formatDateTime } from '@/infrastructure/helper/formatDate.ts';
import { ChipType } from '@/domain/constant/component/chip_colors.ts';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';
import { truncateDecimal } from '@/infrastructure/helper/truncateDecimalNumber.ts';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';
import { TierModel } from '@/domain/model/tier_model.ts';
import { CorporateModel } from '@/domain/model/corporate_model.ts';
import { IMAGE_URL } from '@/domain/constant/image_url.ts';
import { MEMBER_LOYALTY } from '@/domain/constant/member/member_loyalty.ts';

export class ProfileModel extends BaseModel {
  username: string;
  lastName: string;
  firstName: string;
  serialID: string;
  birtDate: string;
  email: string;
  emailVerified: boolean;
  verifiedAt: string;
  phone: string;
  status: string;
  type: string;
  profileImgUUID: string;
  userCompany: UserModel;
  defaultCompanyUUID: string;
  defaultAddress: CompanyAddressModel;
  defaultUserWithdrawalUID: string;
  userDetail: UserDetailModel;
  userVerification: UserVerificationModel;
  userWithdrawal: UserWithdrawalAccountModel[];
  defaultUserWithdrawal: UserWithdrawalAccountModel[];
  userHeir: UserHeirModel;
  dreamGold: DreamGoldModel;
  balanceTotal: BalanceModel;
  balanceSystemLock: BalanceModel;
  balanceMemberLock: BalanceModel;
  balance: BalanceModel;
  balanceCoin: BalanceModel;
  balanceLock: BalanceModel;
  tier: TierModel;
  corporate: CorporateModel;
  identityNumber: string;
  birthDate: string;
  birthPlace: string;
  religion: string;
  gender: string;
  selfieMediaUID: string;
  identityMediaUID: string;
  dcaStatus: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    username: string,
    lastName: string,
    firstName: string,
    serialID: string,
    birtDate: string,
    email: string,
    emailVerified: boolean,
    verifiedAt: string,
    phone: string,
    status: string,
    type: string,
    profileImgUUID: string,
    userCompany: UserModel,
    defaultCompanyUUID: string,
    defaultAddress: CompanyAddressModel,
    defaultUserWithdrawalUID: string,
    userDetail: UserDetailModel,
    userVerification: UserVerificationModel,
    userWithdrawal: UserWithdrawalAccountModel[],
    defaultUserWithdrawal: UserWithdrawalAccountModel[],
    userHeir: UserHeirModel,
    dreamGold: DreamGoldModel,
    balanceTotal: BalanceModel,
    balanceSystemLock: BalanceModel,
    balanceMemberLock: BalanceModel,
    balance: BalanceModel,
    balanceCoin: BalanceModel,
    balanceLock: BalanceModel,
    tier: TierModel,
    corporate: CorporateModel,
    identityNumber: string,
    birthDate: string,
    birthPlace: string,
    religion: string,
    gender: string,
    selfieMediaUID: string,
    identityMediaUID: string,
    dcaStatus: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.username = username;
    this.lastName = lastName;
    this.firstName = firstName;
    this.serialID = serialID;
    this.birtDate = birtDate;
    this.email = email;
    this.emailVerified = emailVerified;
    this.verifiedAt = verifiedAt;
    this.phone = phone;
    this.status = status;
    this.type = type;
    this.profileImgUUID = profileImgUUID;
    this.userCompany = userCompany;
    this.defaultCompanyUUID = defaultCompanyUUID;
    this.defaultAddress = defaultAddress;
    this.defaultUserWithdrawalUID = defaultUserWithdrawalUID;
    this.userDetail = userDetail;
    this.userVerification = userVerification;
    this.userWithdrawal = userWithdrawal;
    this.defaultUserWithdrawal = defaultUserWithdrawal;
    this.userHeir = userHeir;
    this.dreamGold = dreamGold;
    this.balanceTotal = balanceTotal;
    this.balanceSystemLock = balanceSystemLock;
    this.balanceMemberLock = balanceMemberLock;
    this.balance = balance;
    this.balanceCoin = balanceCoin;
    this.balanceLock = balanceLock;
    this.tier = tier;
    this.corporate = corporate;
    this.identityNumber = identityNumber;
    this.birthDate = birthDate;
    this.birthPlace = birthPlace;
    this.religion = religion;
    this.gender = gender;
    this.selfieMediaUID = selfieMediaUID;
    this.identityMediaUID = identityMediaUID;
    this.dcaStatus = dcaStatus;
  }

  get getBirthDate(): string {
    if (this.birthDate) {
      return formatDate(this.birthDate);
    }
    return '-';
  }
  get getFullName(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }

    if (this.firstName && this.lastName === null) {
      return `${this.firstName}`;
    }

    if (this.firstName === null && this.lastName) {
      return `${this.lastName}`;
    }

    if (this.firstName) {
      return `${this.firstName}`;
    }

    return '-';
  }

  get getCreatedAt(): string {
    if (this.createdAt) {
      return formatDateTime(this.createdAt);
    }
    return '-';
  }

  get getVerifiedAt(): string {
    if (this.verifiedAt) {
      return formatDateTime(this.verifiedAt);
    }
    return '-';
  }

  get getStatusUserColor(): ChipType {
    if (this.userVerification?.status === STATUS_CORPORATE.VERIFIED) {
      return 'primary-light';
    }

    if (this.userVerification?.status === STATUS_CORPORATE.REQUEST) {
      return 'warning-light';
    }

    if (this.userVerification?.status === STATUS_CORPORATE.REJECT) {
      return 'danger-light';
    }
  }

  get getDCAStatusColor(): ChipType {
    if (this.dcaStatus === STATUS_CORPORATE.VERIFIED) {
      return 'primary-light';
    }

    if (this.dcaStatus === STATUS_CORPORATE.REQUEST) {
      return 'warning-light';
    }

    if (this.dcaStatus === STATUS_CORPORATE.REJECT) {
      return 'danger-light';
    }

    if (this.dcaStatus === STATUS_CORPORATE.UNVERIFIED) {
      return 'danger-light';
    }
  }

  get getTierColor(): ChipType {
    if (this.tier.name === MEMBER_LOYALTY.PLATINUM) {
      return 'primary-light';
    }
    if (this.tier.name === MEMBER_LOYALTY.GOLD) {
      return 'warning-light';
    }
    if (this.tier.name === MEMBER_LOYALTY.SILVER) {
      return 'info-light';
    }
  }

  get getTruncateAmountDecimal(): number {
    if (this.balance.amount) {
      return truncateDecimal(this.balance.amount);
    }

    return 0;
  }

  get getTruncateSystemAmountDecimal(): number {
    if (this.balanceSystemLock.amount) {
      return truncateDecimal(this.balanceSystemLock.amount);
    }

    return 0;
  }

  get getTruncateMemberAmountDecimal(): number {
    if (this.balanceMemberLock.amount) {
      return truncateDecimal(this.balanceMemberLock.amount);
    }

    return 0;
  }

  get getFiatBalanceSystemLockRupiah(): string {
    if (this.balanceSystemLock.fiat) {
      return formatRupiah(this.balanceSystemLock.fiat);
    }

    return '-';
  }

  get getSelfieMediaUrl(): string {
    if (this.selfieMediaUID) {
      return `${IMAGE_URL}/${this.selfieMediaUID}`;
    } else {
      return '#';
    }
  }

  get getIdentityMediaUrl(): string {
    if (this.identityMediaUID) {
      return `${IMAGE_URL}/${this.identityMediaUID}`;
    } else {
      return '#';
    }
  }

  public static fromJson(data: any): any | ProfileModel {
    try {
      return new ProfileModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.username,
        data.last_name,
        data.first_name,
        data.serial_id,
        data.birth_date,
        data.email,
        data.email_verified,
        data.verified_at,
        data.phone,
        data.status,
        data.type,
        data.profile_img_uuid,
        data.user_company,
        data.default_company_uuid,
        data.default_address ? CompanyAddressModel.fromJson(data.default_address) : null,
        data.default_user_withdrawal_uid,
        data.user_detail ? UserDetailModel.fromJson(data.user_detail) : null,
        data.user_verification ? UserVerificationModel.fromJson(data.user_verification) : null,
        data.user_withdrawal ? UserWithdrawalAccountModel.fromJsonList(data.user_withdrawal) : [],
        data.default_user_withdrawal ? UserWithdrawalAccountModel.fromJsonList(data.default_user_withdrawal) : [],
        data.user_heir,
        data.dream_gold,
        data.balance_total ? BalanceModel.fromJson(data.balance_total) : null,
        data.balance_system_lock ? BalanceModel.fromJson(data.balance_system_lock) : null,
        data.balance_member_lock ? BalanceModel.fromJson(data.balance_member_lock) : null,
        data.balance ? BalanceModel.fromJson(data.balance) : null,
        data.balance_coin,
        data.balance_lock,
        data.tier ? TierModel.fromJson(data.tier) : null,
        data.corporate ? CorporateModel.fromJson(data.corporate) : null,
        data.identity_number,
        data.birth_date,
        data.birth_place,
        data.religion,
        data.gender,
        data.selfie_media_uid,
        data.identity_media_uid,
        data.dca_status
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): ProfileModel[] | any {
    try {
      const listCompanyAddressModel = [];

      for (let x = 0; x < data.length; x++) {
        listCompanyAddressModel.push(ProfileModel.fromJson(data[x]));
      }

      return listCompanyAddressModel;
    } catch {
      return [];
    }
  }
}
