import { useEffect } from 'react';
import CorporateListPage from '@/delivery/ui/admin/data_corporate/corporate_list';
import { CorporateProvider } from '@/delivery/ui/admin/data_corporate/corporate_list/context/corporate_list_context.tsx';

const ViewCorporateList = () => {
  useEffect(() => {
    document.title = 'List Member Corporate';
  }, []);

  return (
    <div>
      <CorporateProvider>
        <CorporateListPage />
      </CorporateProvider>
    </div>
  );
};

export default ViewCorporateList;
