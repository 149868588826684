import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { PaginationModel } from '@/domain/model/pagination_model';
// import { UserModel } from '@/domain/model/user_model';
// import { UserRepository } from '@/domain/repository/user_repository';
import React, { createContext, useContext, ReactNode, useState } from 'react';
import { ProfileRepository } from '@/domain/repository/profile_repository.ts';
import { ProfileModel } from '@/domain/model/profile_model.ts';
import { BalanceLockRepository } from '@/domain/repository/balance_lock_repository.ts';
import { DreamGoldRepository } from '@/domain/repository/dream_gold_repository.ts';
import { DreamGoldModel } from '@/domain/model/dream_gold_model.ts';
import { DREAM_GOLD_STATUS } from '@/domain/constant/dream_gold/dream_gold_status.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';
import { UserAddressModel } from '@/domain/model/user_address_model.ts';
import { UserAddressRepository } from '@/domain/repository/user_address_repository.ts';
import { UserRepository } from '@/domain/repository/user_repository.ts';
import { AddressRepository } from '@/domain/repository/address_repository.ts';
import AddressModel from '@/domain/model/address_model.ts';
import { RegionRepository } from '@/domain/repository/region_repository.ts';
import { RegionProvinceModel } from '@/domain/model/region_province_model.ts';
import { RegionDistrictModel } from '@/domain/model/region_district_model.ts';
import { RegionVillageModel } from '@/domain/model/region_village_model.ts';
import { RegionCityModel } from '@/domain/model/region_city_model.ts';
import { NATIONAL_ID } from '@/domain/constant/national_id.ts';
import UploadMedia from '@/infrastructure/helper/uploadMedia.tsx';
import MEDIA_PATH from '@/domain/constant/media/media_path.ts';
import { MasterBankRepository } from '@/domain/repository/master_bank_repository.ts';
import { MasterBankModel } from '@/domain/model/master_bank_model.ts';
import {
  FormDreamGold,
  FormFilterMember,
  FormLockMemberBalance,
  FormUser,
  FormUserWithdrawal,
  MemberListContextValue,
  MemberListValue
} from '@/delivery/ui/admin/data_member/member_list/context/interface/member_list_interface.tsx';
import { UserModelQuery } from '@/domain/model/user_model_query.ts';
import { MEMBER_STATUS } from '@/domain/constant/member/member_status.ts';
import { UserVerificationRepository } from '@/domain/repository/user_verification_repository.ts';
import { UserVerificationModel } from '@/domain/model/user_verification_model.ts';
import { UserWithdrawalAccountRepository } from '@/domain/repository/user_withdrawal_account_repository.ts';
import { UserWithdrawalAccountModel } from '@/domain/model/user_withdrawal_account_model.ts';
import { MEMBER_E_MONEY_TYPE } from '@/domain/constant/member_e_money/member_e_money_type.ts';
// import { MEMBER_TYPE } from '@/domain/constant/member/member_type.ts';

interface MemberListContextContextProps {
  children: ReactNode;
}

const MemberListContext = createContext<MemberListContextValue | null>(null);

// const user = new UserRepository();
const profile = new ProfileRepository();
const balanceLock = new BalanceLockRepository();
const dreamGold = new DreamGoldRepository();
const userAddressRep = new UserAddressRepository();
const userRep = new UserRepository();
const addressRep = new AddressRepository();
const regionRepository = new RegionRepository();
const masterBankRep = new MasterBankRepository();
const userVerificationRep = new UserVerificationRepository();
const userWithdrawalRep = new UserWithdrawalAccountRepository();

const MemberListProvider: React.FC<MemberListContextContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [userAddress, setUserAddress] = useState<UserAddressModel>();

  const [userWithdrawal, setUserWithdrawal] = useState<UserWithdrawalAccountModel>();
  const [userVerification, setUserVerification] = useState<UserVerificationModel>();
  const [user, setUser] = useState<ProfileModel>();
  const [users, setUsers] = useState<UserModelQuery[]>([]);
  const [usersPagination, setUsersPagination] = useState<PaginationModel>();
  const [masterBanks, setMasterBanks] = useState<MasterBankModel[]>([]);

  const [dreamGolds, setDreamGolds] = useState<DreamGoldModel[]>([]);

  const [formFilterMember, setFormFilterMember] = useState<FormFilterMember>({});
  const [formLockMember, setFormLockMember] = useState<FormLockMemberBalance>({});
  const [formDreamGold, setFormDreamGold] = useState<FormDreamGold>({});
  const [formUser, setFormUser] = useState<FormUser>({});
  const [formUserWithdrawal, setFormUserWithdrawal] = useState<FormUserWithdrawal>({});

  const handleDetailMemberListModal = useVisibleComponent(false);
  const handleDialogLockMemberBalance = useVisibleComponent(false);
  const handleEditDataUserModal = useVisibleComponent(false);
  const handleEditAddressModal = useVisibleComponent(false);
  const handleEditDataIDModal = useVisibleComponent(false);
  const handleEditDocumentModal = useVisibleComponent(false);
  const handleEditUserWithdrawalModal = useVisibleComponent(false);
  const handleDialogConfirmSuspendModal = useVisibleComponent(false);
  const handleDialogConfirmActiveModal = useVisibleComponent(false);
  // const handleDialogConfirmBusinessModal = useVisibleComponent(false);

  const [provinces, setProvinces] = useState<RegionProvinceModel[]>([]);
  const [cities, setCities] = useState<RegionCityModel[]>([]);
  const [districts, setDistricts] = useState<RegionDistrictModel[]>([]);
  const [villages, setVillages] = useState<RegionVillageModel[]>([]);

  const [addressUUID, setAddressUUID] = useState<string>('');
  const [provinceID, setProvinceID] = useState<number>(0);
  const [cityID, setCityID] = useState<number>(0);
  const [districtID, setDistrictID] = useState<number>(0);
  const [villageID, setVillageID] = useState<number>(0);
  const [fullAddress, setFullAddress] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');

  //FETCH USER / MEMBER LIST
  const handleFetchUser = (_data: MemberListValue) => {
    const x = alertToast.loadingAlert('');
    const params = {
      // type: MEMBER_TYPE.USER,
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      search: formFilterMember.search ?? null,
      created_at: formFilterMember.created_at ? formatDateParam(formFilterMember.created_at) : null,
      email: formFilterMember.email ?? null,
      phone: formFilterMember.phone ?? null
    };

    profile
      .fetchProfile(params)
      .then((result: any) => {
        setUsers(result.data);
        setUsersPagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW USER
  const handleShowUser = (_data: MemberListValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: _data.uuid
    };

    profile
      .showProfile(params)
      .then((result: any) => {
        setUser(result);

        const res: ProfileModel = result;

        const paramAddress = {
          user_uuid: res.UUID
        };

        userAddressRep
          .showUserAddress(paramAddress)
          .then((resultAddress: any) => {
            alertToast.updateLoading(x);
            setUserAddress(resultAddress);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW USER VERIFICATION
  const handleShowUserVerification = (_data: UserVerificationModel) => {
    const x = alertToast.loadingAlert('Showing Data Verification');

    const paramAddress = {
      uid: _data.UID
    };

    userVerificationRep
      .showUserVerification(paramAddress)
      .then((result: any) => {
        setUserVerification(result);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW MODAL LOCK BALANCE
  const handleShowLockBalance = (_data: MemberListValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: _data.uuid
    };

    profile
      .showProfile(params)
      .then((res: any) => {
        const result: ProfileModel = res;
        setUser(result);
        setFormLockMember({ user_uid: result.UUID });
        handleDialogLockMemberBalance.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  // //HANDLE CONFIRM AND SHOW MODAL
  // const handleOpenConfirmCreate = (_data: MemberListValue) => {
  //   const x = alertToast.loadingAlert('');
  //
  //   const params = {
  //     uuid: _data.uuid
  //   };
  //
  //   profile
  //     .showProfile(params)
  //     .then((res: any) => {
  //       const result: ProfileModel = res;
  //       setUser(result);
  //       handleDialogConfirmBusinessModal.setState(true);
  //       alertToast.updateLoading(x);
  //     })
  //     .catch(error => {
  //       alertToast.updateLoading(x);
  //       alertToast.errorAlert(error);
  //     });
  // };

  //HANDLE STORE LOCK MEMBER BALANCE / LOCK BY DINARAN
  const handleStoreLockMemberBalance = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      user_uid: formLockMember.user_uid,
      amount: formLockMember.amount,
      release_date: formLockMember.release_date
    };

    balanceLock
      .storeBalanceLock(params)
      .then(() => {
        handleDialogLockMemberBalance.setState(false);
        alertToast.successAlert();
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE DREAM GOLD / TAB EMAS IMPIAN
  const handleUpdateDreamGold = () => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        const params = {
          name: formDreamGold.name,
          status: DREAM_GOLD_STATUS.FORCE_CLOSE,
          target: formDreamGold.target,
          uid: formDreamGold.uid,
          user_uid: formLockMember.user_uid
        };

        dreamGold
          .updateDreamGold(params)
          .then(() => {
            handleDialogLockMemberBalance.setState(false);
            alertToast.successAlert();
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE DREAM GOLD BY USER
  const handleFetchDreamGold = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      user_uid: formLockMember.user_uid
    };

    dreamGold
      .fetchDreamGold(params)
      .then((result: any) => {
        const dreamGold: DreamGoldModel[] = result.data;
        const newDreamGold = dreamGold.filter(item => item.status === DREAM_GOLD_STATUS.IN_PROGRESS);
        setDreamGolds(newDreamGold);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //---UPDATE DATA DETAIL---

  //HANDLE SHOW USER WITHDRAWAL
  const handleShowUserWithdrawal = (_data: ProfileModel) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uid: _data.userVerification.UID
    };

    userVerificationRep
      .showUserVerification(params)
      .then((result: any) => {
        const res: UserVerificationModel = result;

        setFormUserWithdrawal({
          uid: res.UID,
          account_holder_name: res.accountHolderName,
          account_number: res.accountNumber,
          master_bank_uuid: res.masterBankUUID,
          media_uid: res.mediaUID,
          status: res.status,
          user_uuid: res.userUID
        });

        //SHOW USER WITHDRAWAL
        const userWithdrawalBank = _data.userWithdrawal.find(item => item.type === MEMBER_E_MONEY_TYPE.BANK);
        if (userWithdrawalBank) {
          const paramsWithdrawal = {
            uid: userWithdrawalBank.UID
          };

          userWithdrawalRep
            .showUserWithdrawalAccount(paramsWithdrawal)
            .then((result: any) => {
              setUserWithdrawal(result);
              handleEditUserWithdrawalModal.setState(true);
              alertToast.updateLoading(x);
            })
            .catch(error => {
              alertToast.updateLoading(x);
              alertToast.errorAlert(error);
            });
        } else {
          handleEditUserWithdrawalModal.setState(true);
          alertToast.updateLoading(x);
        }
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE USER WITHDRAWAL
  const handleUpdateUserWithdrawal = async () => {
    const x = alertToast.loadingAlert('');

    let mediaUUID = '';
    if (formUserWithdrawal.media) {
      mediaUUID = await UploadMedia(formUserWithdrawal.media, MEDIA_PATH.CHECKING_ACCOUNT);
    } else {
      mediaUUID = '';
    }

    const params = {
      uid: formUserWithdrawal.uid,
      master_bank_uuid: formUserWithdrawal.master_bank_uuid,
      account_number: formUserWithdrawal.account_number,
      account_holder_name: formUserWithdrawal.account_holder_name,
      media_uid: mediaUUID ? mediaUUID : formUserWithdrawal.media_uid,
      status: formUserWithdrawal.status,
      user_uid: formUserWithdrawal.user_uuid
    };

    userVerificationRep
      .updateUserVerification(params)
      .then((result: any) => {
        handleShowUser({ uuid: user?.UUID });

        if (userWithdrawal) {
          //UPDATE USER WITHDRAWAL
          const paramsWithdrawal = {
            uid: userWithdrawal?.UID,
            master_bank_uuid: formUserWithdrawal.master_bank_uuid,
            account_number: formUserWithdrawal.account_number,
            account_holder_name: formUserWithdrawal.account_holder_name,
            media_uid: mediaUUID ? mediaUUID : formUserWithdrawal.media_uid,
            status: userWithdrawal?.status,
            user_uid: formUserWithdrawal.user_uuid
          };

          userWithdrawalRep
            .updateUserWithdrawalAccount(paramsWithdrawal)
            .then((result: any) => {
              setUserWithdrawal(result);
              alertToast.successAlert();
              handleEditUserWithdrawalModal.setState(false);
              alertToast.updateLoading(x);
            })
            .catch(error => {
              alertToast.updateLoading(x);
              alertToast.errorAlert(error);
            });
        } else {
          alertToast.updateLoading(x);
          alertToast.successAlert();
          handleEditUserWithdrawalModal.setState(false);
        }
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW DOCUMENT
  const handleShowDocument = (_data: UserVerificationModel) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uid: _data.UID
    };

    userVerificationRep
      .showUserVerification(params)
      .then((result: any) => {
        const res: UserVerificationModel = result;
        setFormUser({
          uuid: res.userUID,
          identity_media_uid: res.identityMediaUID,
          selfie_media_uid: res.selfieMediaUID,
          status_verification: res.status,
          uid: res.UID
        });
        handleEditDocumentModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE DATA USER
  const handleUpdateDocument = async () => {
    const x = alertToast.loadingAlert('');

    let identityMediaUID = null;
    if (formUser.identity_media) {
      identityMediaUID = await UploadMedia(formUser.identity_media, MEDIA_PATH.IDENTITY);
    } else {
      identityMediaUID = null;
    }

    let selfieMediaUID = null;
    if (formUser.selfie_media) {
      selfieMediaUID = await UploadMedia(formUser.selfie_media, MEDIA_PATH.SELFIE);
    } else {
      selfieMediaUID = null;
    }

    const params = {
      uid: formUser.uid,
      identity_media_uid: identityMediaUID ? identityMediaUID : formUser.identity_media_uid,
      selfie_media_uid: selfieMediaUID ? selfieMediaUID : formUser.selfie_media_uid,
      status: formUser.status_verification,
      user_uid: formUser.uuid
    };

    userVerificationRep
      .updateUserVerification(params)
      .then(() => {
        handleShowUser({ uuid: user?.UUID });
        handleEditDocumentModal.setState(false);
        alertToast.updateLoading(x);
        alertToast.successAlert();
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE DATA USER
  const handleShowDataUser = (_data: ProfileModel) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: _data.UUID
    };

    userRep
      .showUser(params)
      .then((result: any) => {
        // setUser(result);
        const res: ProfileModel = result;
        setFormUser({
          uuid: res.UUID,
          first_name: res.firstName,
          last_name: res.lastName,
          email: res.email,
          phone: res.phone
        });
        handleEditDataUserModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE DATA USER
  const handleUpdateDataUser = async () => {
    const x = alertToast.loadingAlert('');

    userRep
      .updateUser(formUser)
      .then((result: any) => {
        const res: ProfileModel = result;
        handleShowUser({ uuid: res.UUID });
        handleEditDataUserModal.setState(false);
        alertToast.updateLoading(x);
        alertToast.successAlert();
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE DATA IDENTITY / KTP
  const handleShowDataID = (_data: ProfileModel) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: _data.UUID
    };

    profile
      .showProfile(params)
      .then((result: any) => {
        const res: ProfileModel = result;
        setFormUser({
          uuid: res.UUID,
          identity_number: res.userVerification.identityNumber,
          status_verification: res.userVerification.status,
          birth_place: res.birthPlace,
          birth_date: res.birthDate,
          religion: res.religion,
          gender: res.gender
        });

        handleEditDataIDModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE DATA PROFILE
  const handleUpdateDataID = () => {
    const x = alertToast.loadingAlert('');

    profile
      .updateProfile(formUser)
      .then(() => {
        const params = {
          uid: user?.userVerification.UID,
          user_uid: formUser.uuid,
          identity_number: formUser.identity_number,
          status: formUser.status_verification
        };

        userVerificationRep
          .updateUserVerification(params)
          .then(() => {
            handleShowUser({ uuid: user?.UUID });
            handleEditDataIDModal.setState(false);
            alertToast.updateLoading(x);
            alertToast.successAlert();
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW USER ADDRESS
  const handleShowAddress = (_data: AddressModel) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: _data.UUID
    };

    addressRep
      .showAddress(params)
      .then((result: any) => {
        const res: AddressModel = result;

        setAddressUUID(res.UUID);
        setProvinceID(parseInt(res.provinceID));
        setCityID(parseInt(res.cityID));
        setDistrictID(parseInt(res.districtID));
        setVillageID(parseInt(res.villageID));
        setFullAddress(res.fullAddress);
        setZipCode(res.zipCode);

        handleEditAddressModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW USER ADDRESS
  const handleUpdateAddress = (_data: MemberListValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: addressUUID,
      city_id: cityID,
      district_id: districtID,
      full_address: fullAddress,
      national_id: NATIONAL_ID,
      province_id: provinceID,
      village_id: villageID,
      zip_code: zipCode
    };

    addressRep
      .updateAddress(params)
      .then(() => {
        handleShowUser({ uuid: _data.uuid });
        handleEditAddressModal.setState(false);
        alertToast.updateLoading(x);
        alertToast.successAlert();
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  // ------ LOCATION ADDRESS BELOW ------ //

  //HANDLE FETCH PROVINCE
  const handleFetchProvince = () => {
    regionRepository
      .fetchProvince()
      .then((result: any) => {
        setProvinces(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH CITIES
  const handleFetchCities = () => {
    const params = {
      parent_id: provinceID
    };
    regionRepository
      .fetchCities(params)
      .then((result: any) => {
        setCities(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH DISTRICT
  const handleFetchDistricts = () => {
    const params = {
      parent_id: cityID
    };
    regionRepository
      .fetchDistrict(params)
      .then((result: any) => {
        setDistricts(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH VILLAGE
  const handleFetchVillage = () => {
    const params = {
      parent_id: districtID
    };

    regionRepository
      .fetchVillage(params)
      .then((result: any) => {
        setVillages(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  // HANDLE FETCH MASTER BANK
  const handleFetchMasterBank = () => {
    masterBankRep
      .fetchMasterBank({ page: 1, limit: -1 })
      .then((result: any) => {
        setMasterBanks(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW SUSPEND MEMBER
  const handleShowSuspendMemberModal = (_data: MemberListValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: _data.uuid
    };

    profile
      .showProfile(params)
      .then((result: any) => {
        setUser(result);
        alertToast.updateLoading(x);
        handleDialogConfirmSuspendModal.setState(true);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SUSPEND MEMBER
  const handleSuspendMember = (_data: ProfileModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        const params = {
          uuid: _data.UUID,
          status: MEMBER_STATUS.SUSPEND
        };

        profile
          .updateProfile(params)
          .then(() => {
            alertToast.updateLoading(x);
            handleFetchUser({});
            handleDialogConfirmSuspendModal.setState(false);
            alertToast.successAlert();
          })
          .catch(error => {
            alertToast.updateLoading(x);

            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE SHOW SUSPEND MEMBER
  const handleShowActiveMemberModal = (_data: MemberListValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: _data.uuid
    };

    profile
      .showProfile(params)
      .then((result: any) => {
        setUser(result);
        alertToast.updateLoading(x);
        handleDialogConfirmActiveModal.setState(true);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE ACTIVE MEMBER
  const handleActiveMember = (_data: ProfileModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        const params = {
          uuid: _data.UUID,
          status: MEMBER_STATUS.ACTIVE
        };

        profile
          .updateProfile(params)
          .then(() => {
            alertToast.updateLoading(x);
            handleFetchUser({});
            handleDialogConfirmActiveModal.setState(false);
            alertToast.successAlert();
          })
          .catch(error => {
            alertToast.updateLoading(x);

            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: MemberListContextValue = {
    userVerification,
    masterBanks,
    formUserWithdrawal,
    setFormUserWithdrawal,
    formUser,
    setFormUser,
    userAddress,
    dreamGolds,
    formFilterMember,
    setFormFilterMember,
    user,
    usersPagination,
    users,
    handleFetchUser,
    handleShowUser,
    handleEditDocumentModal,
    handleEditAddressModal,
    handleEditDataUserModal,
    handleDetailMemberListModal,
    handleEditUserWithdrawalModal,
    handleDialogConfirmSuspendModal,
    handleDialogConfirmActiveModal,
    handleEditDataIDModal,
    handleDialogLockMemberBalance,
    handleShowLockBalance,
    // handleOpenConfirmCreate,
    handleStoreLockMemberBalance,
    formLockMember,
    setFormLockMember,
    handleUpdateDreamGold,
    handleFetchDreamGold,
    formDreamGold,
    setFormDreamGold,
    handleShowDataUser,
    handleUpdateDataUser,
    handleShowAddress,
    handleSuspendMember,
    handleShowSuspendMemberModal,
    handleShowActiveMemberModal,
    handleActiveMember,

    provinces,
    cities,
    districts,
    villages,
    handleFetchProvince,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    villageID,
    setVillageID,

    fullAddress,
    setFullAddress,
    zipCode,
    setZipCode,

    handleUpdateAddress,
    handleShowDataID,
    handleUpdateDataID,
    handleShowDocument,
    handleUpdateDocument,
    handleShowUserWithdrawal,
    handleFetchMasterBank,
    handleUpdateUserWithdrawal,
    handleShowUserVerification
  };

  return <MemberListContext.Provider value={contextValue}>{children}</MemberListContext.Provider>;
};

const useMemberListContext = (): MemberListContextValue => {
  const context = useContext(MemberListContext);
  if (!context) {
    throw new Error('useMemberListContext must be used within a MemberListProvider');
  }
  return context;
};

export { MemberListProvider, useMemberListContext };
