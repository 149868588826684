import Button from '@/delivery/components/atoms/button/button';
import DepositByAdmin from './depost_by_admin';
import Navbar from '@/delivery/components/organisms/navbar/navbar';
import Icon from '@/domain/constant/icon';
import { useDepositByAdminContext } from './context/deposit_by_admin_context';
import DialogAddDepositGold from './dialog/dialog_add_deposit_gold';

const DepositByAdminPage = () => {
  const { handleAddDepositByAdminModal } = useDepositByAdminContext();
  return (
    <div>
      <Navbar label={'Deposit by Admin'}>
        <Button
          label="New Setor Emas"
          prefixIcon={Icon.plus}
          onClick={() => handleAddDepositByAdminModal.setState(true)}
        />
      </Navbar>
      <DepositByAdmin />

      {handleAddDepositByAdminModal.state && <DialogAddDepositGold modal={handleAddDepositByAdminModal} />}
    </div>
  );
};

export default DepositByAdminPage;
