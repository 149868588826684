import OverviewPage from '@/delivery/ui/admin/dashboard/overview';
import { useEffect } from 'react';
import { OverviewProvider } from '@/delivery/ui/admin/dashboard/overview/context/overview_context.tsx';

const ViewDashboardOverview = () => {
  useEffect(() => {
    document.title = 'Overview';
  }, []);

  return (
    <div>
      <OverviewProvider>
        <OverviewPage />
      </OverviewProvider>
    </div>
  );
};

export default ViewDashboardOverview;
