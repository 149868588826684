import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
// import Button from '@/delivery/components/atoms/button/button.tsx';
// import { FiDownloadCloud } from 'react-icons/fi';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import { useEffect } from 'react';

const FilterMemberList = () => {
  const { handleFetchUser, formFilterMember, setFormFilterMember } = useMemberListContext();

  const handleResetForm = () => {
    setFormFilterMember({
      search: '',
      created_at: '',
      email: '',
      phone: ''
    });
  };

  useEffect(() => {
    const allFieldsEmpty = Object.values(formFilterMember).every(value => value === '');

    if (allFieldsEmpty) {
      handleFetchUser({});
    }
  }, [formFilterMember]);

  return (
    <div className="flex gap-[12px]">
      <Button
        type="secondary"
        prefixIcon={Icon.reset}
        label={'Reset Filter'}
        onClick={() => {
          handleResetForm();
        }}
      />
      <ButtonFilter
        onClickApply={() => handleFetchUser({})}
        size={'small'}
        titleFilter={'Filter Member'}
        label={'More Filter'}
      >
        <div className={'flex flex-col gap-[20px]'}>
          <TextField
            value={formFilterMember.search}
            search
            placeholder={'Search...'}
            block
            onChange={e => setFormFilterMember({ ...formFilterMember, search: e.target.value })}
          />
          <div className={'flex gap-[20px]'}>
            {/*<TextField*/}
            {/*  value={formFilterMember.email}*/}
            {/*  block*/}
            {/*  label={'Email'}*/}
            {/*  onChange={e => setFormFilterMember({ ...formFilterMember, email: e.target.value })}*/}
            {/*/>*/}
            {/*<TextField*/}
            {/*  value={formFilterMember.phone}*/}
            {/*  type={'number'}*/}
            {/*  block*/}
            {/*  label={'Phone'}*/}
            {/*  onChange={e => setFormFilterMember({ ...formFilterMember, phone: e.target.value })}*/}
            {/*/>*/}
            <Datepicker
              value={formFilterMember.created_at}
              block
              label={'Register Date'}
              onChange={e => setFormFilterMember({ ...formFilterMember, created_at: e })}
            />
          </div>
          {/*<div className={'flex gap-[20px]'}>*/}
          {/*  <TextField block label={'Month Birth Date'} />*/}
          {/*  <TextField block label={'Religion'} />*/}
          {/*  <TextField block label={'Gender'} />*/}
          {/*  <TextField block label={'Provinsi'} />*/}
          {/*</div>*/}
        </div>
      </ButtonFilter>
      {/*<Button label="Export by Filter" type="secondary" prefixIcon={<FiDownloadCloud />} />*/}
    </div>
  );
};

export default FilterMemberList;
