import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { ProfileRepository } from '@/domain/repository/profile_repository.ts';
import { ProfileModel } from '@/domain/model/profile_model.ts';
import { MEMBER_TYPE } from '@/domain/constant/member/member_type.ts';
import { UserModelQuery } from '@/domain/model/user_model_query.ts';

interface DCAActivationContextProps {
  children: ReactNode;
}

interface DCAActivationParams {
  page?: number;
  limit?: number;
}

interface FormDCAActivationValue {
  uuid?: string;
  dca_status?: string;
}

interface FormFilterDCAActivation {
  search?: string;
  dca_status?: string;
}

interface DCAActivationContextValue {
  formFilterDCAActivation: FormFilterDCAActivation;
  setFormFilterDCAActivation: Dispatch<SetStateAction<FormFilterDCAActivation>>;
  formDCAActivation: FormDCAActivationValue;
  setFormDCAActivation: Dispatch<SetStateAction<FormDCAActivationValue>>;
  users: UserModelQuery[];
  userPagination?: PaginationModel;
  handleShowUserModal: ModalObject;
  handleFetchUser: (_data: DCAActivationParams) => void;
  handleShowUser: (_data: UserModelQuery) => void;
  handleUpdateUser: () => void;
}

const StockGoldContext = createContext<DCAActivationContextValue | null>(null);

const profileRep = new ProfileRepository();

const DCAActivationProvider: React.FC<DCAActivationContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const [users, setUsers] = useState<UserModelQuery[]>([]);
  const [userPagination, setUserPagination] = useState<PaginationModel>();

  const [formDCAActivation, setFormDCAActivation] = useState<FormDCAActivationValue>({});

  const [formFilterDCAActivation, setFormFilterDCAActivation] = useState<FormFilterDCAActivation>({});

  const handleShowUserModal = useVisibleComponent(false);

  //HANDLE FETCH USER
  const handleFetchUser = (_data: DCAActivationParams) => {
    const x = alertToast.loadingAlert('');

    const params = {
      type: MEMBER_TYPE.USER,
      page: _data?.page ?? 1,
      limit: _data?.limit ?? 10,
      dca_status: formFilterDCAActivation.dca_status ?? null,
      search: formFilterDCAActivation.search
    };
    profileRep
      .fetchProfile(params)
      .then((result: any) => {
        setUsers(result.data);
        setUserPagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW USER
  const handleShowUser = (_data: UserModelQuery) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uuid: _data.UUID
    };

    profileRep
      .showProfile(params)
      .then((result: any) => {
        const res: ProfileModel = result;
        setFormDCAActivation({
          uuid: res.UUID,
          dca_status: res.dcaStatus
        });
        handleShowUserModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW USER
  const handleUpdateUser = () => {
    const x = alertToast.loadingAlert('');
    const params = {
      uuid: formDCAActivation?.uuid,
      dca_status: formDCAActivation?.dca_status
    };

    profileRep
      .updateProfile(params)
      .then(() => {
        handleFetchUser({ page: 1 });
        handleShowUserModal.setState(false);
        alertToast.updateLoading(x);
        alertToast.successAlert();
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: DCAActivationContextValue = {
    formFilterDCAActivation,
    setFormFilterDCAActivation,
    formDCAActivation,
    setFormDCAActivation,
    handleShowUser,
    handleUpdateUser,
    users,
    userPagination,
    handleFetchUser,
    handleShowUserModal
  };

  return <StockGoldContext.Provider value={contextValue}>{children}</StockGoldContext.Provider>;
};

const useDCAActivationContext = (): DCAActivationContextValue => {
  const context = useContext(StockGoldContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a DCAActivationProvider');
  }
  return context;
};

export { DCAActivationProvider, useDCAActivationContext };
