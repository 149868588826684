import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Textarea from '@/delivery/components/atoms/textarea/textarea';
import Icon from '@/domain/constant/icon.tsx';
import { FaPlus } from 'react-icons/fa6';
import Line from '@/delivery/components/atoms/line/line.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import TypeGramAmount from '@/domain/constant/transaction/type_gram_amount_list.ts';
import TypeGramAmountConst from '@/domain/constant/transaction/type_gram_amount.ts';
import { TypeManualTransactionList } from '@/domain/constant/transaction/type_transaction_list.ts';
import { STATUS_TRANSACTION_LIST } from '@/domain/constant/transaction/status_transaction.ts';
import { useManualTransactionContext } from '@/delivery/ui/admin/transaction/manual_transaction/context/manual_transaction_context.tsx';
import { useState } from 'react';
import { TBox, Theader } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';

const SaleTransaction = () => {
  const {
    goldRates,
    handleSubmitManualTransaction,
    setFormManualTransaction,
    formManualTransaction,
    handleShowUserListModal,
    paymentChannels
  } = useManualTransactionContext();

  const [typeGramAmount, setTypeGramAmount] = useState('amount');

  return (
    <div>
      <Theader>
        <Typography size={14} fontWeight={600}>
          Create Manual Transaction
        </Typography>
      </Theader>
      <TBox>
        <Button prefixIcon={<FaPlus />} label={'Tambah User'} onClick={() => handleShowUserListModal.setState(true)} />
        <div className="grid grid-cols-1 gap-[24px] mt-[24px] w-full">
          <div className={'grid sm:grid-cols-1 lg:grid-cols-2 gap-[24px]'}>
            <TextField block placeholder="Nama pemegang rekening" value={formManualTransaction?.name} disabled />
            {/*<TextField block placeholder="Sisa Balance" disabled value={formManualTransaction?.amount_balance} />*/}
            <TextField block placeholder="Email member" disabled value={formManualTransaction?.email} />
            <TextField block placeholder="Phone number" disabled value={formManualTransaction?.phone} />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*<TextField*/}
          {/*  block*/}
          {/*  label="Buy Rate (today)"*/}
          {/*  placeholder="Buy Rate"*/}
          {/*  disabled*/}
          {/*  value={goldRateLatest?.currentRate.getBuy}*/}
          {/*/>*/}
          {/*<TextField*/}
          {/*  label="Sale Rate (today)"*/}
          {/*  block*/}
          {/*  placeholder="Sale Rate"*/}
          {/*  disabled*/}
          {/*  value={goldRateLatest?.currentRate.getSell}*/}
          {/*/>*/}

          {/*<TextField label="Transaction Date" block placeholder="Nama pemegang rekening" />*/}
          {/*<TextField label="Buy Rate" block placeholder="Buy Rate" />*/}
        </div>
        <div className={'flex flex-col lg:flex-row gap-[20px] py-[20px]'}>
          <Datepicker
            block
            label="Rate Date"
            placeholder="Rate Date"
            value={formManualTransaction.rate_date}
            onChange={e => {
              setFormManualTransaction({ ...formManualTransaction, rate_date: e });
            }}
          />
          <TextField
            type={'currency'}
            label="Rate Sell"
            block
            placeholder="Sale Rate"
            disabled
            value={goldRates[0]?.sell}
          />
          <TextField
            type={'currency'}
            label="Rate Buy"
            block
            placeholder="Sale Rate"
            disabled
            value={goldRates[0]?.buy}
          />
        </div>

        <div className={'col-span-2'}>
          <Line />
        </div>
        <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-[24px] mt-[24px] w-full">
          <div>
            <Select2
              value={typeGramAmount}
              options={TypeGramAmount.map(item => {
                return {
                  label: item.name,
                  value: item.UUID
                };
              })}
              label="Pilih Type (Nominal/Gramasi)"
              block
              onChange={e => setTypeGramAmount(e.value)}
            />
          </div>

          {typeGramAmount === TypeGramAmountConst.AMOUNT ? (
            <TextField
              type={'currency'}
              label="Nominal (Rp)"
              block
              placeholder="Nominal"
              onChange={(e: any) =>
                setFormManualTransaction({
                  ...formManualTransaction,
                  amount: parseInt(e.value)
                })
              }
            />
          ) : (
            <TextField
              type={'number'}
              label="Jumlah Gramasi (Gr)"
              block
              placeholder="Gr"
              onChange={e => setFormManualTransaction({ ...formManualTransaction, gram: parseInt(e.target.value) })}
            />
          )}
        </div>

        <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-[24px] mt-[24px] w-full">
          <Select2
            isSearchable={false}
            options={TypeManualTransactionList.map(item => {
              return {
                label: item.name,
                value: item.UUID
              };
            })}
            label="Type Transaksi"
            block
            placeholder="pilih type"
            onChange={e => setFormManualTransaction({ ...formManualTransaction, type: e.value })}
          />
          <TextField
            type={'currency'}
            label="Fee (Rp)"
            block
            placeholder="Masukan nominal (include fee)"
            onChange={(e: any) => setFormManualTransaction({ ...formManualTransaction, fee: parseInt(e.value) })}
          />
          <Select2
            isSearchable
            options={paymentChannels.map(item => {
              return {
                label: item.code,
                value: item.UID
              };
            })}
            label="Payment Channel"
            block
            placeholder="pilih payment channel"
            onChange={e => setFormManualTransaction({ ...formManualTransaction, payment_channel_uid: e.value })}
          />
          <Select2
            isSearchable
            options={STATUS_TRANSACTION_LIST.map(item => {
              return {
                label: item.name,
                value: item.UUID
              };
            })}
            label="Status"
            block
            placeholder="Status"
            onChange={e => setFormManualTransaction({ ...formManualTransaction, status: e.value })}
          />
        </div>

        <div className="mt-[24px]">
          <Textarea
            height={200}
            block
            placeholder="Notes"
            onChange={e => setFormManualTransaction({ ...formManualTransaction, notes: e.target.value })}
          />
        </div>
        <div className="pt-[24px] border-t border-border-secondary mt-[24px] justify-end flex">
          <Button
            label="Create Transaction New"
            prefixIcon={Icon.plus}
            onClick={() => handleSubmitManualTransaction()}
          />
        </div>
      </TBox>
    </div>
  );
};

export default SaleTransaction;
