import Navbar from '@/delivery/components/organisms/navbar/navbar';
import NotifyApp from './notify_app';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import DialogAddNotificationApp from '@/delivery/ui/admin/web_admin/notify_app/dialog/dialog_add_notification_app.tsx';
import { useNotifyAppContext } from '@/delivery/ui/admin/web_admin/notify_app/context/notify_app_context.tsx';

const NotifyAppPage = () => {
  const { handleAddNotifyAppModal } = useNotifyAppContext();
  return (
    <div>
      <Navbar label={'Notify App'}>
        <div className="flex gap-[12px]">
          <Button
            type="primary"
            prefixIcon={Icon.plus}
            label="Add Notification App"
            onClick={() => handleAddNotifyAppModal.setState(true)}
          />
        </div>
      </Navbar>
      <NotifyApp />

      {handleAddNotifyAppModal.state && <DialogAddNotificationApp modal={handleAddNotifyAppModal} />}
    </div>
  );
};

export default NotifyAppPage;
