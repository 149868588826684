import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useEffect } from 'react';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';
import { IMAGE_URL } from '@/domain/constant/image_url.ts';
import Dropzone from '@/delivery/components/atoms/dropzone/dropzone.tsx';
import { useBusinessAccountContext } from '@/delivery/ui/admin/data_member/member_list/context/business_account_context.tsx';

const DialogUpdateUserWithdrawal = (props: ModalDialogProps) => {
  const { handleEditCorporateVerify, handleFetchMasterBank, masterBanks, formCorporateVerify, setFormCorporateVerify } =
    useBusinessAccountContext();

  useEffect(() => {
    handleFetchMasterBank();
  }, []);

  return (
    <ModalDialog size="small" title="Update User" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Select2
          options={masterBanks.map(item => {
            return {
              value: item.UID,
              label: item.name
            };
          })}
          value={formCorporateVerify.master_bank_uuid}
          label={'Bank'}
          block
          onChange={e => setFormCorporateVerify({ ...formCorporateVerify, master_bank_uuid: e.value })}
        />
        <TextField
          value={formCorporateVerify.account_number}
          type={'number'}
          label={'Account Number'}
          block
          onChange={e => setFormCorporateVerify({ ...formCorporateVerify, account_number: e.target.value })}
        />
        <TextField
          value={formCorporateVerify.account_holder_name}
          label={'Account Holder Name '}
          block
          onChange={e => setFormCorporateVerify({ ...formCorporateVerify, account_holder_name: e.target.value })}
        />
        <Image url={`${IMAGE_URL}/${formCorporateVerify.checking_account_media_uid}`} width={300} />
        <Dropzone
          id={'media'}
          label={'Buku Tabungan'}
          onChange={e => setFormCorporateVerify({ ...formCorporateVerify, checking_account_media: e })}
        />
        <ModalDialogFooter className="flex gap-[20px] justify-end">
          <Button type="secondary" label="Cancel" />
          <Button label="Save" onClick={() => handleEditCorporateVerify()} />
        </ModalDialogFooter>
      </div>
    </ModalDialog>
  );
};

export default DialogUpdateUserWithdrawal;
