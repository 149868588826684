import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Typography from '@/delivery/components/atoms/typography/typography';
import { Table, Tbody, Td, Th, Thead, Theader, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import { useUserContext } from '@/delivery/ui/admin/settings/admin_access/context/user_context.tsx';
import { useEffect, useState } from 'react';
import { UserModel } from '@/domain/model/user_model.ts';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { IoKeyOutline } from 'react-icons/io5';
import { Routing } from '@/domain/constant/router_path.ts';
import { useNavigate } from 'react-router-dom';
import Icon from '@/domain/constant/icon.tsx';

const UserTab = () => {
  const navigate = useNavigate();
  const { handleDeleteUser, handleShowUser, handleAddUserModal, userPagination, users, handleFetchUser } =
    useUserContext();

  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    handleFetchUser({});
  }, []);

  useEffect(() => {
    if (search !== '') {
      handleFetchUser({ search: search });
    } else {
      handleFetchUser({});
    }
  }, [search]);

  return (
    <div>
      <Theader>
        <TextField
          search
          placeholder="Search"
          onChange={e =>
            setTimeout(() => {
              setSearch(e.target.value);
            }, 2000)
          }
        />
        <Button prefixIcon={Icon.plus} label="Add User Admin" onClick={() => handleAddUserModal.setState(true)} />
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Phone</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((item: UserModel, index) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.getFullName}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.email}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.phone}
                </Typography>
              </Td>
              <Td>
                <div className="flex gap-[4px]">
                  <Button iconButton iconType={'delete'} onClick={() => handleDeleteUser(item)} />
                  <Button iconButton iconType={'edit'} onClick={() => handleShowUser(item)} />
                  <Button
                    iconButton
                    icon={<IoKeyOutline />}
                    onClick={() => navigate(Routing.SETTINGS.ADMIN_ACCESS_DETAIL + item.UUID)}
                  />
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          handlePagination={page => {
            handleFetchUser({
              page: page
            });
          }}
          page={userPagination?.page || 1}
          totalPages={userPagination?.totalPages || 1}
        />
      </Tfooter>
    </div>
  );
};

export default UserTab;
