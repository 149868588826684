export const STATUS_TRANSACTION = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  REJECT: 'REJECT',
  FAILED: 'FAILED',
  REQUEST: 'REQUEST',
  CANCEL: 'CANCEL',
  AWAITING_PICKUP: 'AWAITING PICKUP',
  AWAITING_SETTLEMENT: 'AWAITING SETTLEMENT',
  AWAITING_COMMISSION: 'AWAITING COMMISSION',
  ON_DELIVERY: 'ON DELIVERY',
  EXPIRED: 'EXPIRED'
};

export const STATUS_TRANSACTION_LIST = [
  { UUID: 'SUCCESS', name: 'SUCCESS' },
  { UUID: 'PENDING', name: 'PENDING' },
  { UUID: 'REJECT', name: 'REJECT' },
  { UUID: 'FAILED', name: 'FAILED' },
  { UUID: 'REQUEST', name: 'REQUEST' },
  { UUID: 'CANCEL', name: 'CANCEL' },
  { UUID: 'AWAITING PICKUP', name: 'AWAITING PICKUP' },
  { UUID: 'AWAITING SETTLEMENT', name: 'AWAITING SETTLEMENT' },
  { UUID: 'AWAITING COMMISSION', name: 'AWAITING COMMISSION' },
  { UUID: 'ON_DELIVERY', name: 'ON DELIVERY' },
  { UUID: 'EXPIRED', name: 'EXPIRED' }
];
