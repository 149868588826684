import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';

import { useEventReferralContext } from '@/delivery/ui/admin/reseller_affiliate/event_referral/context/event_referral_context.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import { STATUS_LIST_LIST } from '@/domain/constant/status.ts';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';
import { REFERRAL_TYPE_LIST } from '@/domain/constant/affiliate/referral_type.ts';

const DialogUpdateEventReferral = (props: ModalDialogProps) => {
  const { handleUpdateEventReferral, formEventReferral, setFormEventReferral, handleAddEventReferralModal } =
    useEventReferralContext();

  return (
    <ModalDialog size="small" title="Update Event Referral" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField
          value={formEventReferral.name}
          label="Nama Event"
          placeholder="Nama Event"
          block
          onChange={e => setFormEventReferral({ ...formEventReferral, name: e.target.value })}
        />
        <Select2
          value={formEventReferral.type}
          options={REFERRAL_TYPE_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          label="Tipe Affiliate"
          onChange={e => setFormEventReferral({ ...formEventReferral, type: e.value })}
        />
        <Datepicker
          label={'Start Date'}
          value={formEventReferral.start_date}
          onChange={e => setFormEventReferral({ ...formEventReferral, start_date: formatDateParam(e) })}
        />
        <Datepicker
          label={'End Date'}
          value={formEventReferral.end_date}
          onChange={e => setFormEventReferral({ ...formEventReferral, end_date: formatDateParam(e) })}
        />
        <Select2
          value={formEventReferral.status}
          options={STATUS_LIST_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          label="Tipe Affiliate"
          onChange={e => setFormEventReferral({ ...formEventReferral, status: e.value })}
        />
        <Textarea
          value={formEventReferral.description}
          label="Description"
          placeholder="Description"
          block
          onChange={e => setFormEventReferral({ ...formEventReferral, description: e.target.value })}
        />
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleAddEventReferralModal.setState(false)} />
        <Button block label="Save" onClick={() => handleUpdateEventReferral()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogUpdateEventReferral;
