import OfflineCounterUnavailabilityInterface, {
  OfflineCounterUnavailabilityValue
} from '@/domain/repository/interface/offline_counter_unavailability_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { OfflineCounterUnavailabilityModel } from '@/domain/model/offline_counter_unavailability_model.ts';
import { OfflineCounterUnavailability } from '@/domain/constant/api_list';

export class OfflineCounterUnavailabilityRepository implements OfflineCounterUnavailabilityInterface {
  api = new ApiClient();

  async fetchOfflineCounterUnavailability(data: OfflineCounterUnavailabilityValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(OfflineCounterUnavailability.FETCH, {
            params: data
          })
          .then(result => {
            const data = OfflineCounterUnavailabilityModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showOfflineCounterUnavailability(data: OfflineCounterUnavailabilityValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(OfflineCounterUnavailability.SHOW, {
            params: data
          })
          .then(result => {
            const data = OfflineCounterUnavailabilityModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeOfflineCounterUnavailability(data: OfflineCounterUnavailabilityValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(OfflineCounterUnavailability.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkOfflineCounterUnavailability(data: OfflineCounterUnavailabilityValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(OfflineCounterUnavailability.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateOfflineCounterUnavailability(data: OfflineCounterUnavailabilityValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(OfflineCounterUnavailability.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteOfflineCounterUnavailability(data: OfflineCounterUnavailabilityValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(OfflineCounterUnavailability.DELETE + data.uid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
