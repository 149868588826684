import { BaseModel } from '@/domain/model/base_model.ts';
import { ChipType } from '@/domain/constant/component/chip_colors.ts';
import { DCA_STATUS } from '@/domain/constant/dca/dca_status.ts';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';

export class DCABalanceModel extends BaseModel {
  serialID: string;
  userUID: string;
  status: string;
  fiat: number;
  gram: number;
  rateUID: string;
  durationMonth: string;
  withdrawalType: string;
  notes: string;
  bankName: string;
  type: string;
  accountNumber: string;
  accountHolderName: string;
  approvedBy: string;
  approvedAt: string;
  dueDate: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    serialID: string,
    userUID: string,
    status: string,
    fiat: number,
    gram: number,
    rateUID: string,
    durationMonth: string,
    withdrawalType: string,
    notes: string,
    bankName: string,
    type: string,
    accountNumber: string,
    accountHolderName: string,
    approvedBy: string,
    approvedAt: string,
    dueDate: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.serialID = serialID;
    this.userUID = userUID;
    this.status = status;
    this.fiat = fiat;
    this.gram = gram;
    this.rateUID = rateUID;
    this.durationMonth = durationMonth;
    this.withdrawalType = withdrawalType;
    this.notes = notes;
    this.bankName = bankName;
    this.type = type;
    this.accountNumber = accountNumber;
    this.accountHolderName = accountHolderName;
    this.approvedBy = approvedBy;
    this.approvedAt = approvedAt;
    this.dueDate = dueDate;
  }

  get getStatusColor(): ChipType {
    if (this.status === DCA_STATUS.REQUEST) {
      return 'info-light';
    }

    if (this.status === DCA_STATUS.APPROVED) {
      return 'primary-light';
    }

    if (this.status === DCA_STATUS.CLOSED) {
      return 'danger-light';
    }
  }

  get getFiat(): string {
    if (this.fiat) {
      return formatRupiah(this.fiat);
    }
    return '-';
  }

  public static fromJson(data: any): any | DCABalanceModel {
    try {
      return new DCABalanceModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.serial_id,
        data.user_uid,
        data.status,
        data.fiat,
        data.gram,
        data.rate_uid,
        data.duration_month,
        data.withdrawal_type,
        data.notes,
        data.bank_name,
        data.type,
        data.account_number,
        data.account_holder_name,
        data.approved_by,
        data.approved_at,
        data.due_date
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): DCABalanceModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(DCABalanceModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
