import { IoDocumentTextOutline } from 'react-icons/io5';
import Button from '@/delivery/components/atoms/button/button';
import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card';
import { TBox, Theader } from '@/delivery/components/molecules/table/table';

import ChartMobileAppAccess from './chart_mobile_app_acccess';
import ChartMobileWebappWebsite from './chart_mobile_webapp_website';

const AssetStatistic = () => {
  return (
    <div>
      <div className="grid grid-cols-5 gap-[24px]">
        <SimpleCard title="Total Mobile Apps" bigTitle="307" />
        <SimpleCard title="Total Mobile Android" bigTitle="283" />
        <SimpleCard title="Total Mobile IOS" bigTitle="23" />
        <SimpleCard title="Total Web App" bigTitle="307" />
        <SimpleCard title="Total Website" bigTitle="283" />
      </div>
      <div className="mt-[32px]">
        <Theader>
          <span className="text-[18px] font-[600]">Akses Mobile Apps</span>
          <Button prefixIcon={<IoDocumentTextOutline />} label="Export PDF" type="secondary" />
        </Theader>
        <TBox>
          <ChartMobileAppAccess />
        </TBox>
      </div>
      <div className="mt-[32px]">
        <Theader>
          <span className="text-[18px] font-[600]">Akses Mobile Apps, Web Apps and Website</span>
          <Button prefixIcon={<IoDocumentTextOutline />} label="Export PDF" type="secondary" />
        </Theader>
        <TBox>
          <ChartMobileWebappWebsite />
        </TBox>
      </div>
    </div>
  );
};

export default AssetStatistic;
