import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';

const TabMemberAddress = () => {
  return (
    <div className="flex flex-col gap-[24px] px-[10px]">
      <div className="grid grid-cols-3 gap-[24px] items-center">
        {/* name */}
        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Provinsi
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField block placeholder="Dinaran Number" disabled={true} value={123456} />
        </div>
      </div>
    </div>
  );
};

export default TabMemberAddress;
