import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { CustomAccessRepository } from '@/domain/repository/custom_access_repository.ts';
import { CustomAccessModel } from '@/domain/model/custom_access_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { getProfile } from '@/infrastructure/helper/auth.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';

interface DialogContextProps {
  children: ReactNode;
}

interface PermissionValueParams {
  page?: number;
  limit?: number;
}

interface FormPermissionInterface {
  UUID?: string;
  access?: string;
  company_uuid?: string;
}

interface PermissionValue {
  formPermission: FormPermissionInterface;
  setFormPermission: Dispatch<SetStateAction<FormPermissionInterface>>;
  // permission: FormPermissionInterface | undefined;
  permissions: CustomAccessModel[];
  permissionPagination?: PaginationModel;
  handleAddPermissionModal: ModalObject;
  handleUpdatePermissionModal: ModalObject;
  handleFetchPermission: (_data: PermissionValueParams) => void;
  handleShowPermission: (_data: FormPermissionInterface) => void;
  handleUpdatePermission: () => void;
  handleAddPermission: () => void;
  handleDeletePermission: (_data: CustomAccessModel) => void;
}

const PermissionContext = createContext<PermissionValue | null>(null);

const permissionRepository = new CustomAccessRepository();

const PermissionProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const profile = getProfile();

  const handleAddPermissionModal = useVisibleComponent(false);
  const handleUpdatePermissionModal = useVisibleComponent(false);

  // const [permission, setPermission] = useState<CustomAccessModel>();
  const [permissions, setPermissions] = useState<CustomAccessModel[]>([]);
  const [permissionPagination, setPermissionPagination] = useState<PaginationModel>();

  const [formPermission, setFormPermission] = useState<FormPermissionInterface>({});

  //FETCH ROLE
  const handleFetchPermission = (_data: PermissionValueParams) => {
    // let x = alertToast.loadingAlert("");
    const params = {
      page: _data?.page ?? 1,
      limit: _data?.limit ?? 10
    };

    permissionRepository
      .fetchCustomAccess(params)
      .then((result: any) => {
        // alertToast.updateLoading(x);
        setPermissions(result.data);
        setPermissionPagination(result.pagination);
      })
      .catch(error => {
        // alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW ROLE
  const handleShowPermission = (_data: FormPermissionInterface) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uuid: _data.UUID
    };

    permissionRepository
      .showCustomAccess(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setFormPermission({
          UUID: result.UUID,
          access: result.access
        });
        handleUpdatePermissionModal.setState(true);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //UPDATE ROLE
  const handleUpdatePermission = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      uuid: formPermission.UUID,
      access: formPermission.access
    };

    permissionRepository
      .updateCustomAccess(params)
      .then(() => {
        alertToast.updateLoading(x);
        handleFetchPermission({ page: 1 });
        handleUpdatePermissionModal.setState(false);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //ADD ROLE
  const handleAddPermission = () => {
    const x = alertToast.loadingAlert('');
    const params = {
      access: formPermission.access,
      company_uuid: profile?.company_uuid
    };

    permissionRepository
      .storeCustomAccess(params)
      .then(() => {
        alertToast.updateLoading(x);
        alertToast.successAlert();
        handleAddPermissionModal.setState(false);
        handleFetchPermission({ page: 1 });
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //DELETE ROLE
  const handleDeletePermission = (_data: CustomAccessModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const params = {
          uuid: _data.UUID
        };
        permissionRepository
          .deleteCustomAccess(params)
          .then(() => {
            alertToast.successAlert();
            handleFetchPermission({ page: 1 });
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: PermissionValue = {
    formPermission,
    setFormPermission,
    // permission,
    permissions,
    permissionPagination,
    handleFetchPermission,
    handleAddPermissionModal,
    handleUpdatePermissionModal,
    handleShowPermission,
    handleUpdatePermission,
    handleAddPermission,
    handleDeletePermission
  };

  return <PermissionContext.Provider value={contextValue}>{children}</PermissionContext.Provider>;
};

const usePermissioneContext = (): PermissionValue => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a PermissionProvider');
  }
  return context;
};

export { PermissionProvider, usePermissioneContext };
