import { Table, Th, Thead, Tr, Tbody, Td } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useMemberVefiryContext } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';
import DialogUpdateAddress from '@/delivery/ui/admin/data_member/member_verify/detail/dialog/dialog_update_data_address.tsx';

const TableIdentityAddress = () => {
  const { handleEditAddressModal, handleShowAddress, user } = useMemberVefiryContext();
  return (
    <>
      <Table noBorder className="rounded-b-[8px]" rounded>
        <Thead>
          <Tr noBorder>
            <Th noBorder>
              <Typography fontWeight={600} size={14} type="primary-invert">
                Alamat Pengiriman
              </Typography>
            </Th>
            <Th noBorder>
              <div className={'justify-end flex'}>
                <span
                  className={'text-text-primary font-[600] text-[14px] cursor-pointer'}
                  onClick={() => {
                    if (user?.defaultAddress) handleShowAddress(user?.defaultAddress.address);
                  }}
                >
                  Edit
                </span>
              </div>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {/*provinsi*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Provinsi
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {user?.defaultAddress?.address?.province?.name}
              </Typography>
            </Td>
          </Tr>

          {/*kabupaten*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Kabupaten
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {user?.defaultAddress?.address?.city?.name}
              </Typography>
            </Td>
          </Tr>

          {/*Kecamatan*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Kecamatan
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {user?.defaultAddress?.address?.district?.name}
              </Typography>
            </Td>
          </Tr>

          {/*kelurahan*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Kelurahan
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {user?.defaultAddress?.address?.village?.name}
              </Typography>
            </Td>
          </Tr>

          {/*alamat*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Alamat
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {user?.defaultAddress?.address?.fullAddress}
              </Typography>
            </Td>
          </Tr>

          {/*Kode pos*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Kode POS
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {user?.defaultAddress?.address?.zipCode}
              </Typography>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      {handleEditAddressModal.state && <DialogUpdateAddress modal={handleEditAddressModal} />}
    </>
  );
};

export default TableIdentityAddress;
