import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';

import { useDownloadExcel } from 'react-export-table-to-excel';
import { useRef } from 'react';
import { formatDateTimeParam } from '@/infrastructure/helper/formatDate.ts';
import { useTransactionListContext } from '@/delivery/ui/admin/transaction/transaction_list/context/transaction_list_context.tsx';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';

const DialogExportTransaction = (props: ModalDialogProps) => {
  const tableRef = useRef(null);
  const { handleExportTransactionModal, transactionLists } = useTransactionListContext();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `transaction-list-${formatDateTimeParam(new Date())}`,
    sheet: 'transaction'
  });

  return (
    <ModalDialog size="xlarge" title="Preview Data Transaction" modal={props.modal}>
      <div className={'flex gap-[20px] justify-end mb-[10px]'}>
        <Button onClick={onDownload} label="Export" />
      </div>
      <div>
        <table ref={tableRef} className={'border border-solid'}>
          <tbody>
            <tr>
              <th className={'border border-solid'}>TRX ID</th>
              <th className={'border border-solid'}>Nama</th>
              <th className={'border border-solid'}>Email</th>
              <th className={'border border-solid'}>Type</th>
              <th className={'border border-solid'}>Reversal</th>
              <th className={'border border-solid'}>Pengirim</th>
              <th className={'border border-solid'}>Email Pengirim</th>
              <th className={'border border-solid'}>Penerima</th>
              <th className={'border border-solid'}>Email Penerima</th>
              <th className={'border border-solid'}>Status</th>
              <th className={'border border-solid'}>Trx Date</th>
              <th className={'border border-solid'}>Paid Date</th>
              <th className={'border border-solid'}>Paid Amount</th>
              <th className={'border border-solid'}>Fee</th>
              <th className={'border border-solid'}>Coin</th>
              <th className={'border border-solid'}>Vendor</th>
              <th className={'border border-solid'}>Vendor Ref ID</th>
              <th className={'border border-solid'}>Gramasi</th>
              <th className={'border border-solid'}>Buy Rate</th>
              <th className={'border border-solid'}>Sell Rate</th>
            </tr>

            {transactionLists.map((item: TransactionModel, index) => (
              <tr>
                <td className={'border border-solid'}>{item.code}</td>
                <td className={'border border-solid'}>{item.userEmail?.name}</td>
                <td className={'border border-solid'}>{item.userEmail?.email}</td>
                <td className={'border border-solid'}>
                  {item.flags ? (
                    <span className={'whitespace-nowrap'}>{item.flags.join('')}</span>
                  ) : (
                    <span className={'whitespace-nowrap'}>{item.type}</span>
                  )}
                </td>
                <td className={'border border-solid'}>
                  {item.type === TYPE_TRANSACTION.REVERSAL ? (
                    <span className={'whitespace-nowrap'}>{item.type}</span>
                  ) : (
                    '-'
                  )}
                </td>
                <td className={'border border-solid'}>{item.senderName}</td>
                <td className={'border border-solid'}>{item.senderEmail}</td>
                <td className={'border border-solid'}>{item.receiverName}</td>
                <td className={'border border-solid'}>{item.receiverEmail}</td>
                <td className={'border border-solid'}>
                  {item.flags && item.type === TYPE_TRANSACTION.REVERSAL ? (
                    <span>{`${item.status} ${item.type}`}</span>
                  ) : (
                    <span>{item.status}</span>
                  )}
                </td>
                <td className={'border border-solid'}>{item.getTransactionAt}</td>
                <td className={'border border-solid'}>{item.getSucceedAt}</td>
                <td className={'border border-solid'}>{item.amount?.total}</td>
                <td className={'border border-solid'}>{item.amount?.fee}</td>
                <td className={'border border-solid'}>{item.amount?.coin}</td>
                <td className={'border border-solid'}>{item.payment?.vendor}</td>
                <td className={'border border-solid'}>{item.payment?.vendorRefID}</td>
                <td className={'border border-solid'}>{item.amount?.gram}</td>
                <td className={'border border-solid'}>
                  <span>{item.rate.buy}</span>
                </td>
                <td className={'border border-solid'}>
                  <span>{item.rate.sell}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleExportTransactionModal.setState(false)} />
        {/*<DownloadTableExcel filename="affiliate-list" sheet="affiliate" currentTableRef={tableRef.current}>*/}
        {/*  <button>export</button>*/}
        <Button onClick={onDownload} label="Export" />
        {/*</DownloadTableExcel>*/}
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogExportTransaction;
