import {
  Table,
  Tbody,
  TBox,
  Td,
  Tfooter,
  Th,
  Thead,
  Theader,
  Tr
} from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import { PaymentChannelFeeModel } from '@/domain/model/payment/payment_channel_fee_model.ts';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { useOpenChannelContext } from '@/delivery/ui/admin/settings/open_channel/context/open_channel_context.tsx';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import DialogAddPaymentFee from '@/delivery/ui/admin/settings/open_channel/detail/payment_channel_fee/dialog_add_payment_fee.tsx';

const DetailPaymentChannelFee = () => {
  const { uid } = useParams();
  const { paymentChannelFeePagination, handlePaymentChannelFee, paymentChannelFees, handleAddPaymentFeeDetailModal } =
    useOpenChannelContext();

  useEffect(() => {
    if (uid) {
      handlePaymentChannelFee({ payment_channel_uid: uid });
    }
  }, [uid]);

  return (
    <TBox>
      <Theader>
        <Typography size={16} fontWeight={600} type={'secondary-invert'}>
          Channel Pambayaran Fee
        </Typography>
        <Button
          prefixIcon={Icon.plus}
          label={'Add Payment Fee'}
          onClick={() => handleAddPaymentFeeDetailModal.setState(true)}
        />
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Created At</Th>
            <Th>Payment Channel</Th>
            <Th>Type</Th>
            <Th>Fee</Th>
            {/*<Th>Start Date</Th>*/}
            {/*<Th>End Date</Th>*/}
          </Tr>
        </Thead>
        <Tbody>
          {paymentChannelFees?.map((item: PaymentChannelFeeModel, index: number) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.getCreatedAt}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.paymentChannelUID}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.type}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.getFeeRupiah}
                </Typography>
              </Td>
              {/*<Td>*/}
              {/*  <Typography fontWeight={400} size={14} type="tertiary-invert">*/}
              {/*    {item.getEffectiveDateStart}*/}
              {/*  </Typography>*/}
              {/*</Td>*/}
              {/*<Td>*/}
              {/*  <Typography fontWeight={400} size={14} type="tertiary-invert">*/}
              {/*    {item.getEffectiveDateEnd}*/}
              {/*  </Typography>*/}
              {/*</Td>*/}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={paymentChannelFeePagination?.page || 1}
          totalPages={paymentChannelFeePagination?.totalPages || 1}
          handlePagination={page => {
            handlePaymentChannelFee({
              page: page,
              payment_channel_uid: uid
            });
          }}
        />
      </Tfooter>

      {handleAddPaymentFeeDetailModal.state && <DialogAddPaymentFee modal={handleAddPaymentFeeDetailModal} />}
    </TBox>
  );
};

export default DetailPaymentChannelFee;
