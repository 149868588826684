export class MemberChartModel {
  date: string;
  count: string;

  constructor(date: string, count: string) {
    this.date = date;
    this.count = count;
  }

  public static fromJson(data: any): any | MemberChartModel {
    try {
      return new MemberChartModel(data.date ? data.date : null, data.count ? data.count : null);
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): MemberChartModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(MemberChartModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
