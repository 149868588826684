export const STATUS_CORPORATE = {
  VERIFIED: 'VERIFIED',
  UNVERIFIED: 'UNVERIFIED',
  REQUEST: 'REQUEST',
  REJECT: 'REJECT'
};

export const STATUS_CORPORATE_LIST = [
  { UUID: 'VERIFIED', name: 'VERIFIED' },
  { UUID: 'REQUEST', name: 'REQUEST' },
  { UUID: 'REJECT', name: 'REJECT' }
];
