import { useEffect } from 'react';
import CounterOfflinePage from '@/delivery/ui/admin/transaction/take_gold/counter_offline';
import { CounterOfflineProvider } from '@/delivery/ui/admin/transaction/take_gold/counter_offline/context/counter_offline_context';

const ViewCounterOffline = () => {
  useEffect(() => {
    document.title = 'Counter Offline';
  }, []);

  return (
    <div>
      <CounterOfflineProvider>
        <CounterOfflinePage />
      </CounterOfflineProvider>
    </div>
  );
};

export default ViewCounterOffline;
