export const formatRupiah = (number: number) => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 0
  }).format(number);
};

const formatUSD = (number: number) => {
  return new Intl.NumberFormat('us-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  }).format(number);
};

const formatCurrency = (number: number, currency?: string) => {
  if (currency === 'USD') {
    return formatUSD(number);
  } else {
    return formatRupiah(number);
  }
};

export default formatCurrency;
