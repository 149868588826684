import Chart from 'react-apexcharts';

interface DChartProps {
  options?: any;
  series?: any;
  type?: 'bar' | 'line' | 'area';
  height?: number | string;
  width?: number | string;
}

const DChart = (props: DChartProps) => {
  const { options, series, type, height, width } = props;
  return (
    <div>
      <Chart options={options} series={series} type={type} height={height ?? 350} width={width ?? '100%'} />
    </div>
  );
};

export default DChart;
