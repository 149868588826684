import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Chips from '@/delivery/components/atoms/chips/chips';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useAccountTransactionContext } from '@/delivery/ui/admin/data_dca/account_transactions/context/account_transaction_context.tsx';
import { useEffect } from 'react';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import DialogDetailAccountTransaction from '@/delivery/ui/admin/data_dca/account_transactions/dialog/dialog_detail_account_transaction.tsx';
import DialogUpdateTransactionAt from '@/delivery/ui/admin/data_dca/account_transactions/dialog/dialog_update_transaction_at.tsx';
import FilterAccountTransaction from '@/delivery/ui/admin/data_dca/account_transactions/filter_account_transaction.tsx';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';

const AccountTransaction = () => {
  const {
    handleShowAccountTransaction,
    handleDetailTransactionModal,
    accountTransactionPagination,
    handleFetchAccountTransaction,
    accountTransactions,
    // handleShowTransactionAt,
    handleUpdateTransactionAtModal,
    handleCancelTransaction
  } = useAccountTransactionContext();

  useEffect(() => {
    handleFetchAccountTransaction({});
  }, []);

  return (
    <div>
      <div>
        <div className={'flex justify-end mb-[20px]'}>
          <FilterAccountTransaction />
        </div>

        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Trx ID</Th>
              <Th>Member</Th>
              <Th>Tipe & Fasilitas</Th>
              <Th>Reversal</Th>
              <Th>Status</Th>
              <Th>Trx Date</Th>
              <Th>
                <span className={'whitespace-nowrap'}>Paid Date</span>
              </Th>
              <Th>Paid Amount</Th>
              <Th>Gramasi</Th>
              <Th>Gold Rate</Th>
              <Th>Fee Admin</Th>
              <Th>Total</Th>
              {/*<Th>Due Date</Th>*/}
              <Th>Payment</Th>
              <Th>Update By</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {accountTransactions.map((item: TransactionModel, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.code}
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {item.userEmail?.name}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.userEmail?.email}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.userEmail?.phone}
                  </Typography>
                </Td>
                <Td>
                  <Chips removeDot label={item.type} />
                </Td>
                <Td>
                  {item.flags?.includes(TYPE_TRANSACTION.REVERSAL) ? (
                    <Chips type={'danger-light'} removeDot label={item.flags?.join('')} />
                  ) : (
                    '-'
                  )}
                </Td>
                <Td>
                  <Chips label={item.status} type={item.getStatusColor} />
                </Td>
                <Td>
                  <div className={'text-nowrap'}>
                    <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                      {item.getTransactionAt}
                    </Typography>
                  </div>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getSucceedAt}
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getAmountRupiah}
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.amount?.gram}
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="p" fontWeight={400} size={12} type="primary-invert">
                    {item.rate.getBuy}
                  </Typography>
                  <Typography className="leading-4" tag="p" fontWeight={400} size={12} type="tertiary-invert">
                    {item.rate.getSell}
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getFeeRupiah}
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getSubTotalRupiah}
                  </Typography>
                </Td>
                {/*<Td>*/}
                {/*  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*    {item.d}*/}
                {/*  </Typography>*/}
                {/*</Td>*/}

                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.payment?.method}
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.userUpdate?.name}
                  </Typography>
                </Td>
                <Td>
                  <div className="flex gap-[4px]">
                    <Button iconButton iconType={'view'} onClick={() => handleShowAccountTransaction(item)} />
                    {/*<Button iconButton iconType={'edit'} onClick={() => handleShowTransactionAt(item)} />*/}
                    <Button iconButton iconType={'banned'} onClick={() => handleCancelTransaction(item)} />
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            page={accountTransactionPagination?.page || 1}
            totalPages={accountTransactionPagination?.totalPages || 1}
            handlePagination={page => {
              handleFetchAccountTransaction({
                page: page
              });
            }}
          />
        </Tfooter>
      </div>
      {handleUpdateTransactionAtModal.state && <DialogUpdateTransactionAt modal={handleUpdateTransactionAtModal} />}
      {handleDetailTransactionModal.state && <DialogDetailAccountTransaction modal={handleDetailTransactionModal} />}
    </div>
  );
};

export default AccountTransaction;
