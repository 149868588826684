import Button from '@/delivery/components/atoms/button/button';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import { TabContent, TabPane, TabPaneList } from '@/delivery/components/atoms/tab_pane/tab_pane';
import { useState } from 'react';

import { BsPerson } from 'react-icons/bs';
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { IoMdCheckboxOutline } from 'react-icons/io';
import DataRegister from './tabs/data_register';
import RequestVerify from './tabs/request_verify';
import ApprovalDataVerify from './tabs/approval_data_verify';
import { useMemberVefiryContext } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';

const DialogDetailMemberVerify = (props: ModalDialogProps) => {
  const { handleUpdateMemberVerify, handleDetailMemberVerifyModal } = useMemberVefiryContext();

  const [tabPage, setTabPage] = useState(0);
  const data = [
    {
      prefixIcon: <BsPerson />,
      label: 'Data Register',
      index: 0
      // handleClick: () => {}
    },
    {
      prefixIcon: <BsFillPersonCheckFill />,
      label: 'Request Verify',
      index: 1
      // handleClick: () => {}
    },
    {
      prefixIcon: <IoMdCheckboxOutline />,
      label: 'Approval Data Verify',
      index: 2
      // handleClick: () => {}
    }
  ];

  return (
    <ModalDialog size="medium" title="Add Stock Emas" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TabPane>
          {data?.map((item, index) => (
            <TabPaneList
              prefixIcon={item.prefixIcon}
              label={item.label}
              index={index}
              tab={tabPage}
              handleClick={() => setTabPage(index)}
            />
          ))}
        </TabPane>

        <TabContent index={0} tab={tabPage}>
          <DataRegister />
        </TabContent>
        <TabContent index={1} tab={tabPage}>
          <RequestVerify />
        </TabContent>
        <TabContent index={2} tab={tabPage}>
          <ApprovalDataVerify />
        </TabContent>
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleDetailMemberVerifyModal.setState(false)} />
        <Button label="Save changes" onClick={() => handleUpdateMemberVerify()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogDetailMemberVerify;
