import { useEffect } from 'react';
import ManualTransactionPage from '@/delivery/ui/admin/transaction/manual_transaction';
import { ManualTransactionProvider } from '@/delivery/ui/admin/transaction/manual_transaction/context/manual_transaction_context.tsx';

const ViewManualTransaction = () => {
  useEffect(() => {
    document.title = 'Manual Transaction';
  }, []);

  return (
    <div>
      <ManualTransactionProvider>
        <ManualTransactionPage />
      </ManualTransactionProvider>
    </div>
  );
};

export default ViewManualTransaction;
