import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Typography from '@/delivery/components/atoms/typography/typography';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import { useGoldPriceContext } from '@/delivery/ui/admin/gold_price/context/gold_price_context.tsx';

const DialogHargaEmas = (props: ModalDialogProps) => {
  const { goldRateLatest, handleAddGoldPriceModal, handleStoreGoldRate, setFormGoldRate, formGoldRate } =
    useGoldPriceContext();

  return (
    <ModalDialog size="xsmall" title="Tambah Harga Emas" modal={props.modal}>
      <div>
        <Typography size={12} fontWeight={400} type="tertiary-invert">
          Gold Rate Buy Rate Price Before
        </Typography>
        <div className="leading-[38px] mt-[8px]">
          <Typography tag="span" size={24} fontWeight={600}>
            {goldRateLatest?.currentRate.getBuy}
          </Typography>
          <Typography tag="span" fontWeight={400} type="tertiary-invert" className="font-[400]">
            /gram
          </Typography>
        </div>
        <div className="mt-[16px]">
          <TextField
            type={'currency'}
            block
            label="Gold Rate Buy Rate Price After"
            onChange={(e: any) =>
              setFormGoldRate({
                ...formGoldRate,
                buy: e.floatValue
              })
            }
          />
        </div>
      </div>
      <hr className="my-[24px] border-t-[1px] border-[200] border-border-secondary" />
      <div>
        <Typography size={12} fontWeight={400} type="tertiary-invert">
          Gold Rate Buy Rate Price Before
        </Typography>
        <div className="leading-[38px] mt-[8px]">
          <Typography tag="span" size={24} fontWeight={600}>
            {goldRateLatest?.currentRate.getSell}
          </Typography>
          <Typography tag="span" fontWeight={400} type="tertiary-invert" className="font-[400]">
            /gram
          </Typography>
        </div>
        <div className="mt-[16px]">
          <TextField
            type={'currency'}
            block
            label="Gold Rate Buy Rate Price After"
            onChange={(e: any) =>
              setFormGoldRate({
                ...formGoldRate,
                sell: e.floatValue
              })
            }
          />
        </div>
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleAddGoldPriceModal.setState(false)} />
        <Button block label="Confirm" onClick={() => handleStoreGoldRate({})} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogHargaEmas;
