import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { TBox } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { STATUS_LIST } from '@/domain/constant/status.ts';
import { ModalDialogFooter } from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useWithdrawalContext } from '@/delivery/ui/admin/settings/withdrawal/context/withdrawal_context.tsx';

const DetailWithdrawalMethod = () => {
  const { uid } = useParams();
  const { handleUpdateWithdrawalMethod, handleShowWithdrawalMethod, formWithdrawal, setFormWithdrawal } =
    useWithdrawalContext();

  useEffect(() => {
    if (uid) {
      handleShowWithdrawalMethod({ uid: uid });
    }
  }, [uid]);

  return (
    <TBox className={'lg:w-1/2 w-full'}>
      <div className={'flex gap-[20px]'}>
        <div className="flex flex-col gap-[20px] w-full">
          <Typography fontWeight={600} size={14}>
            Withdrawal Method
          </Typography>
          <Line />
          <TextField disabled={true} value={formWithdrawal.name} label="Nama" placeholder="Nama" block />
          <TextField disabled={true} value={formWithdrawal.code} label="Code" placeholder="Channel" block />
          <TextField disabled={true} value={formWithdrawal.vendor_uid} label="Vendor" placeholder="Method" block />

          <Textarea
            label="Description"
            placeholder="Description"
            block
            value={formWithdrawal.description}
            onChange={e => {
              setFormWithdrawal({
                ...formWithdrawal,
                description: e.target.value
              });
            }}
          />

          <Select2
            value={formWithdrawal.status}
            label={'Status'}
            options={STATUS_LIST.map(item => {
              return {
                label: item.name,
                value: item.UUID
              };
            })}
            onChange={(e: any) => {
              setFormWithdrawal({
                ...formWithdrawal,
                status: e.value
              });
            }}
          />
        </div>
      </div>

      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button label="Save" onClick={() => handleUpdateWithdrawalMethod()} />
      </ModalDialogFooter>
    </TBox>
  );
};

export default DetailWithdrawalMethod;
