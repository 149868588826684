import { TBox, Theader } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { Routing } from '@/domain/constant/router_path.ts';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import Line from '@/delivery/components/atoms/line/line.tsx';
import TableKTP from '@/delivery/ui/admin/data_member/member_verify/detail/table_ktp.tsx';
import TableBankAccount from '@/delivery/ui/admin/data_member/member_verify/detail/table_bank_account.tsx';
import TableIdentityAddress from '@/delivery/ui/admin/data_member/member_verify/detail/table_identity_address.tsx';
import TableDocument from '@/delivery/ui/admin/data_member/member_verify/detail/table_document.tsx';
import { useEffect } from 'react';
import { useMemberVefiryContext } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';
import TableDataRegister from '@/delivery/ui/admin/data_member/member_verify/detail/table_data_register.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { STATUS_CORPORATE, STATUS_CORPORATE_LIST } from '@/domain/constant/status_corporate.ts';

const DetailMemberVerify = () => {
  const navigate = useNavigate();
  const {
    user,
    // userVerification,
    handleShowUser,
    handleShowMemberVerify,
    handleUpdateMemberVerify,
    formMemberVerify,
    setFormMemberVerify
  } = useMemberVefiryContext();
  const { uid } = useParams();

  useEffect(() => {
    if (uid) {
      handleShowMemberVerify({ uid: uid });
    }
  }, [uid]);

  useEffect(() => {
    if (formMemberVerify.user_uid) {
      handleShowUser({ user_uid: formMemberVerify.user_uid });
    }
  }, [formMemberVerify.user_uid]);

  return (
    <div className={'mt-[24px] flex flex-col gap-[20px]'}>
      <div
        className={'flex gap-[10px] items-center text-text-primary cursor-pointer'}
        onClick={() => navigate(Routing.DATA_MEMBER.MEMBER_VERIFY)}
      >
        <FaArrowLeft />
        <Typography size={14} fontWeight={500}>
          Back to member list
        </Typography>
      </div>
      <div>
        <Typography size={24} fontWeight={600}>
          Member Request Verify Detail
        </Typography>
      </div>
      <Line />
      <div>
        <Theader>
          <div>
            <Typography size={14} fontWeight={600}>
              Data Verifikasi
            </Typography>
          </div>

          {/*<div>*/}
          {/*  <span className={'text-text-primary font-[600] text-[14px] cursor-pointer'}>Edit</span>*/}
          {/*</div>*/}
        </Theader>
        <TBox>
          <div className={'grid lg:grid-cols-2 sm:grid-cols-1 gap-[20px]'}>
            <div className={'flex flex-col gap-[20px]'}>
              <TableKTP />
              <TableBankAccount />
            </div>
            <div className={'flex flex-col gap-[20px]'}>
              <TableIdentityAddress />
              <TableDocument />
            </div>
          </div>
        </TBox>
      </div>
      <div className={'grid lg:grid-cols-2 sm:grid-cols-1 gap-[20px]'}>
        <TableDataRegister />
        <div>
          <Theader>
            <Typography fontWeight={600} size={14}>
              Approval Data Verify
            </Typography>
          </Theader>
          <TBox>
            {user?.userVerification.status === STATUS_CORPORATE.VERIFIED ? (
              <div>
                Status User: <span className={'font-bold text-text-primary-green'}>VERIFIED</span>
              </div>
            ) : (
              <div className={'flex flex-col gap-[20px]'}>
                <Select2
                  isSearchable={false}
                  value={formMemberVerify?.status}
                  options={STATUS_CORPORATE_LIST.map(item => {
                    return {
                      label: item.name,
                      value: item.UUID
                    };
                  })}
                  block
                  label={'Status'}
                  onChange={e => setFormMemberVerify({ ...formMemberVerify, status: e.value })}
                />

                <Textarea
                  block
                  label={'Notes'}
                  height={150}
                  value={formMemberVerify.notes}
                  onChange={e =>
                    setFormMemberVerify({
                      ...formMemberVerify,
                      notes: e.target.value
                    })
                  }
                />

                <Line />
                <Button label={'Save'} block onClick={() => handleUpdateMemberVerify()} />
              </div>
            )}
          </TBox>
        </div>
      </div>
    </div>
  );
};

export default DetailMemberVerify;
