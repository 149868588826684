import AffiliateUserInterface, { AffiliateUserValue } from '@/domain/repository/interface/affiliate_user_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { AffiliateUserModel } from '@/domain/model/affiliate_user_model.ts';
import { AffiliateUser } from '@/domain/constant/api_list';

export class AffiliateUserRepository implements AffiliateUserInterface {
  api = new ApiClient();

  async fetchAffiliateUser(data: AffiliateUserValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(AffiliateUser.FETCH, {
            params: data
          })
          .then(result => {
            const data = AffiliateUserModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showAffiliateUser(data: AffiliateUserValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(AffiliateUser.SHOW, {
            params: data
          })
          .then(result => {
            const data = AffiliateUserModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeAffiliateUser(data: AffiliateUserValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(AffiliateUser.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkAffiliateUser(data: AffiliateUserValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(AffiliateUser.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateAffiliateUser(data: AffiliateUserValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(AffiliateUser.UPDATE + data.uuid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteAffiliateUser(data: AffiliateUserValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(AffiliateUser.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
