import { Table, Tbody, Td, Tfooter, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { PaymentChannelModel } from '@/domain/model/payment/payment_channel_model.ts';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { useOpenChannelContext } from '@/delivery/ui/admin/settings/open_channel/context/open_channel_context.tsx';
import { useEffect, useState } from 'react';
import DialogUpdateOpenChannel from '@/delivery/ui/admin/settings/open_channel/dialog/dialog_update_open_channel.tsx';
import STATUS_CONSTANT from '@/domain/constant/status_constanat.ts';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';

const TablePaymentChannel = () => {
  const navigate = useNavigate();
  const {
    handleDeleteManualChannel,
    paymentChannelPagination,
    handleFetchPaymentChannel,
    paymentChannels,
    handleUpdateOpenChannelModal
  } = useOpenChannelContext();

  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    handleFetchPaymentChannel({});
  }, []);

  useEffect(() => {
    if (search !== '') {
      handleFetchPaymentChannel({ name: search });
    } else {
      handleFetchPaymentChannel({});
    }
  }, [search]);

  return (
    <div>
      <Theader>
        <Typography size={16} fontWeight={600} type={'secondary-invert'}>
          Channel Pambayaran
        </Typography>
        <div>
          <TextField
            search
            placeholder={'search nama penyedia'}
            onChange={e => {
              setTimeout(() => {
                setSearch(e.target.value);
              }, 1000);
            }}
          />
        </div>
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Nama Penyedia</Th>
            <Th>Channel</Th>
            <Th>Icon</Th>
            <Th>Status</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {paymentChannels?.map((item: PaymentChannelModel, index: number) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.name}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.code}
                </Typography>
              </Td>
              <Td>
                <div>
                  {item.getMediaUrl !== '#' ? (
                    <Image url={item.getMediaUrl} height={30} />
                  ) : (
                    <Typography style={'italic'} fontWeight={400} size={12} type="tertiary-invert">
                      (no image)
                    </Typography>
                  )}
                </div>
              </Td>
              <Td>
                <Chips
                  type={item.status === STATUS_CONSTANT.active ? 'primary-light' : 'danger-light'}
                  label={item.status}
                />
              </Td>
              <Td>
                <div className="flex gap-[4px]">
                  <Button
                    iconButton
                    iconType={'edit'}
                    onClick={() => navigate(Routing.SETTINGS.OPEN_CHANNEL_DETAIL + item.UID)}
                  />
                  {item.flags?.includes('MANUAL') && (
                    <Button iconButton iconType={'delete'} onClick={() => handleDeleteManualChannel(item)} />
                  )}
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={paymentChannelPagination?.page || 1}
          totalPages={paymentChannelPagination?.totalPages || 1}
          handlePagination={page => {
            handleFetchPaymentChannel({
              page: page
            });
          }}
        />
      </Tfooter>

      {handleUpdateOpenChannelModal.state && <DialogUpdateOpenChannel modal={handleUpdateOpenChannelModal} />}
    </div>
  );
};

export default TablePaymentChannel;
