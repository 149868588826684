import { useEffect } from 'react';
import RankingPage from '@/delivery/ui/admin/dashboard/ranking';
import { DashboardRankingProvider } from '@/delivery/ui/admin/dashboard/ranking/context/dashboard_ranking_context.tsx';

const ViewDashboardRanking = () => {
  useEffect(() => {
    document.title = 'Ranking';
  }, []);

  return (
    <div>
      <DashboardRankingProvider>
        <RankingPage />
      </DashboardRankingProvider>
    </div>
  );
};

export default ViewDashboardRanking;
