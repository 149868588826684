import { ReactNode } from 'react';
import styles from './CardRate.module.css';
import clsx from 'clsx';

import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import Loading from '@/delivery/components/atoms/loading/loading.tsx';
import GOLD_RATE_ARROW from '@/domain/constant/gold_rate/gold_rate_arrow.ts';

interface CardRateProps {
  children?: ReactNode;
  title?: string;
  ratePrice?: string;
  ratePercentage?: string | number;
  loading?: boolean;
  arrow?: string;
}

const CardRate = (props: CardRateProps) => {
  const { arrow = 'up', loading = false, title, ratePrice, ratePercentage } = props;
  return (
    <div className={clsx(styles['card'])}>
      <div className="px-[24px] py-[24px] gap-2 flex flex-col">
        <span className="text-[14px] font-[500] leading-[20px]">{title}</span>
        {!loading ? (
          <>
            <div className="leading-[38px]">
              <span className="text-[30px] font-[600]">{ratePrice}</span>
              <span className="font-[400]">/gram</span>
            </div>
            <div className="leading-[20px] flex ">
              {arrow === GOLD_RATE_ARROW.UP ? (
                <span className="text-[20px] text-text-primary-green">
                  <FiArrowUp />
                </span>
              ) : (
                <>
                  {arrow === GOLD_RATE_ARROW.DOWN ? (
                    <span className="text-[20px] text-text-danger">
                      <FiArrowDown />
                    </span>
                  ) : (
                    ''
                  )}
                </>
              )}

              <span className={`text-[14px] font-[500]`}>
                <span
                  className={`${arrow === GOLD_RATE_ARROW.UP ? 'text-text-primary-green' : ''} ${arrow === GOLD_RATE_ARROW.DOWN ? 'text-text-danger' : ''} ${arrow === GOLD_RATE_ARROW.FLAT ? 'text-text-warning' : ''}`}
                >
                  {ratePercentage}%
                </span>{' '}
                Since last day
              </span>
            </div>
          </>
        ) : (
          <div className={'flex justify-start'}>
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardRate;
