import { BsFilter } from 'react-icons/bs';
import { FiDownloadCloud } from 'react-icons/fi';

import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Theader, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import TextField from '@/delivery/components/atoms/text_field/text_field';

import Typography from '@/delivery/components/atoms/typography/typography';

const ReferralList = () => {
  return (
    <div>
      <div className="mt-[32px]">
        <Theader>
          <TextField search placeholder="Search" />
          <div className="flex gap-[12px]">
            <Button label="More Filter" type="secondary" prefixIcon={<BsFilter />} />
            <Button label="Export by Filter" type="secondary" prefixIcon={<FiDownloadCloud />} />
          </div>
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>TrxID</Th>
              <Th>Corporate Member</Th>
              <Th>Referral Member</Th>
              <Th>Token Referral</Th>
              <Th>Total Earned</Th>
            </Tr>
          </Thead>
          <Tbody>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    123456
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    PT Harapan Sentosa Indonesia
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    ptharapansentosaindonesia@gmail.com
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    0812345678
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    Asmawati
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    asmawati@gmail.com
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    0812345678
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    56LXRVDSA
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    204.500
                  </Typography>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter></Tfooter>
      </div>
    </div>
  );
};

export default ReferralList;
