import { useEffect } from 'react';
import MasterMemberLoyaltyPage from '@/delivery/ui/admin/master/member_loyalty';
import { TierProvider } from '@/delivery/ui/admin/master/member_loyalty/context/member_loyalty_context.tsx';

const ViewMasterMemberLoyalty = () => {
  useEffect(() => {
    document.title = 'Master Member Loyalty';
  }, []);

  return (
    <div>
      <TierProvider>
        <MasterMemberLoyaltyPage />
      </TierProvider>
    </div>
  );
};

export default ViewMasterMemberLoyalty;
