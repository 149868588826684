import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Chips from '@/delivery/components/atoms/chips/chips';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useAffiliateListContext } from '@/delivery/ui/admin/reseller_affiliate/affiliate_list/context/affiliate_list_context.tsx';
import { useEffect } from 'react';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { AffiliateModel } from '@/domain/model/affiliate_model.ts';
import FilterAffiliateList from '@/delivery/ui/admin/reseller_affiliate/affiliate_list/filter_affiliate_list.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import { Routing } from '@/domain/constant/router_path.ts';
import { useNavigate } from 'react-router-dom';

const AffiliateList = () => {
  const navigate = useNavigate();
  const { affiliatePagination, affiliateUsers, handleFetchAffiliateList } = useAffiliateListContext();

  useEffect(() => {
    handleFetchAffiliateList({});
  }, []);

  // const dataDummy = [
  //   {
  //     user_uid: '321906',
  //     email: 'primawinangun@gmail.com',
  //     first_name: 'prima',
  //     last_name: 'winangun',
  //     created_at: '2024-02-19T06:04:49.899143Z',
  //     email_verified: true,
  //     status: 'VERIFIED',
  //     total: 120000,
  //     total_gram: 0.1076923076923077,
  //     total_trx: 2,
  //     token: '775M2ZLM9'
  //   }
  // ];
  return (
    <div>
      {/*<div className="flex gap-[24px]">*/}
      {/*  <SimpleCard title="Total Affiliate Member" bigTitle="37.365" />*/}
      {/*  <SimpleCard title="Total Affiliate Reguler" bigTitle="55.642" />*/}
      {/*  <SimpleCard title="Total Affiliate Eksklusif" bigTitle="62" />*/}
      {/*  <SimpleCard title="Total Affiliate Reseller" bigTitle="93" />*/}
      {/*  <SimpleCard title="Total Affiliate Community" bigTitle="93" />*/}
      {/*</div>*/}
      <div>
        <div className="flex gap-[12px] mb-[12px] justify-end">
          <FilterAffiliateList />
        </div>

        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              {/*<Th>Affiliate ID</Th>*/}
              <Th>Member Affiliate</Th>
              {/*<Th>Type Affiliate</Th>*/}
              <Th>Token</Th>
              {/*<Th>Komisi %</Th>*/}
              <Th>Total Trx Affiliate</Th>
              <Th>Earnings (Rp)</Th>
              <Th>Earnings (gr)</Th>
              <Th>Status</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {affiliateUsers?.map((item: AffiliateModel, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {item.firstName}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.email}
                  </Typography>
                </Td>

                <Td>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.token}
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.totalTrx}
                  </Typography>
                </Td>

                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getEarningRupiah}
                  </Typography>
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.earningGram}
                  </Typography>
                </Td>
                <Td>
                  <Chips label={item.status} type={item.getStatusColor} removeDot />
                </Td>
                <Td>
                  <Button
                    iconButton
                    icon={Icon.eye}
                    onClick={() => navigate(Routing.RESELLER_AFFILIATE.AFFILIATE_LIST_DETAIL + item.userUID)}
                  />
                </Td>
              </Tr>
            ))}
            {/*{dataDummy?.map((item, index) => (*/}
            {/*  <Tr>*/}
            {/*    /!*<Td>*!/*/}
            {/*    /!*  <Typography fontWeight={400} size={12} type="tertiary-invert">*!/*/}
            {/*    /!*    {item.token}*!/*/}
            {/*    /!*  </Typography>*!/*/}
            {/*    /!*</Td>*!/*/}
            {/*    <Td>*/}
            {/*      <Typography fontWeight={600} size={12} type="primary-invert">*/}
            {/*        {item.first_name}*/}
            {/*      </Typography>*/}
            {/*      <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
            {/*        {item.email}*/}
            {/*      </Typography>*/}
            {/*      /!*<Typography fontWeight={400} size={12} type="tertiary-invert">*!/*/}
            {/*      /!*  {item.phone}*!/*/}
            {/*      /!*</Typography>*!/*/}
            {/*    </Td>*/}
            {/*    /!*<Td>*!/*/}
            {/*    /!*  <Chips label={item.type} type="primary" removeDot />*!/*/}
            {/*    /!*</Td>*!/*/}
            {/*    <Td>*/}
            {/*      <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
            {/*        {item.token}*/}
            {/*      </Typography>*/}
            {/*    </Td>*/}
            {/*    /!*<Td>*!/*/}
            {/*    /!*  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">*!/*/}
            {/*    /!*    {item.commission}*!/*/}
            {/*    /!*  </Typography>*!/*/}
            {/*    /!*</Td>*!/*/}
            {/*    <Td>*/}
            {/*      <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
            {/*        {item.total_gram}*/}
            {/*      </Typography>*/}
            {/*    </Td>*/}
            {/*    <Td>*/}
            {/*      <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
            {/*        {item.total}*/}
            {/*      </Typography>*/}
            {/*    </Td>*/}
            {/*    <Td>*/}
            {/*      <Button*/}
            {/*        iconButton*/}
            {/*        icon={Icon.eye}*/}
            {/*        onClick={() => navigate(Routing.RESELLER_AFFILIATE.AFFILIATE_LIST_DETAIL + item.user_uid)}*/}
            {/*      />*/}
            {/*    </Td>*/}
            {/*  </Tr>*/}
            {/*))}*/}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            page={affiliatePagination?.page || 1}
            totalPages={affiliatePagination?.totalPages || 1}
            handlePagination={page => {
              handleFetchAffiliateList({
                page: page
              });
            }}
          />
        </Tfooter>
      </div>
    </div>
  );
};

export default AffiliateList;
