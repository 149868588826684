import { useEffect } from 'react';
import WebPagesPage from '@/delivery/ui/admin/web_admin/web_pages';
import { WebPageProvider } from '@/delivery/ui/admin/web_admin/web_pages/context/web_page_context.tsx';

const ViewWebPages = () => {
  useEffect(() => {
    document.title = 'Web Pages';
  }, []);

  return (
    <div>
      <WebPageProvider>
        <WebPagesPage />
      </WebPageProvider>
    </div>
  );
};

export default ViewWebPages;
