interface StatisticInt {
  count: number;
  gram: number;
  fiat: number;
}

export class DCAStatisticModel {
  all: StatisticInt;
  request: StatisticInt;
  approved: StatisticInt;
  closed: StatisticInt;
  reject: StatisticInt;

  constructor(
    all: StatisticInt,
    request: StatisticInt,
    approved: StatisticInt,
    closed: StatisticInt,
    reject: StatisticInt
  ) {
    this.all = all;
    this.request = request;
    this.approved = approved;
    this.closed = closed;
    this.reject = reject;
  }

  public static fromJson(data: any): any | DCAStatisticModel {
    try {
      return new DCAStatisticModel(data.all, data.request, data.approved, data.closed, data.reject);
    } catch {
      return null;
    }
  }
}
