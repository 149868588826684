import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { STATUS_LIST } from '@/domain/constant/status.ts';
import TypeAppNotify from '@/domain/constant/type_app_notify.ts';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import Switch from '@/delivery/components/atoms/switch/switch.tsx';
import Dropzone from '@/delivery/components/atoms/dropzone/dropzone.tsx';
import { useNotifyAppContext } from '@/delivery/ui/admin/web_admin/notify_app/context/notify_app_context.tsx';
import ChannelNotify from '@/domain/constant/channel_notification.ts';
import { Image } from '@/delivery/components/atoms/image/image.tsx';

const DialogUpdateNotificationApp = (props: ModalDialogProps) => {
  const { formNotifyApp, setFormNotifyApp, handleUpdateNotifyAppModal, handleUpdateDataNotification } =
    useNotifyAppContext();
  return (
    <ModalDialog size="medium" title="Add Notification App" modal={props.modal}>
      <TextField
        value={formNotifyApp.title}
        block
        label={'Title'}
        onChange={e => setFormNotifyApp({ ...formNotifyApp, title: e.target.value })}
      />
      <div className={'grid grid-cols-1 lg:grid-cols-2 gap-[24px] my-[24px]'}>
        <Select2
          required
          value={formNotifyApp.type}
          isMulti
          options={TypeAppNotify.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          block
          label={'Type'}
          onChange={e => {
            setFormNotifyApp({
              ...formNotifyApp,
              type: e.map((item: any) => item.value)
            });
          }}
        />
        <Select2
          required
          value={formNotifyApp.status}
          options={STATUS_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          block
          label={'Status'}
          onChange={e => {
            setFormNotifyApp({ ...formNotifyApp, status: e.value });
          }}
        />
        <Datepicker
          value={formNotifyApp.start_date}
          block
          label={'Publish Date'}
          onChange={e => {
            setFormNotifyApp({ ...formNotifyApp, start_date: e });
          }}
        />
        <Datepicker
          value={formNotifyApp.end_date}
          block
          label={'End Date'}
          onChange={e => {
            setFormNotifyApp({ ...formNotifyApp, end_date: e });
          }}
        />
        <Select2
          value={formNotifyApp.channels}
          isMulti
          options={ChannelNotify.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          block
          label={'Channels'}
          onChange={e => {
            {
              setFormNotifyApp({
                ...formNotifyApp,
                channels: e.map((item: any) => item.value)
              });
            }
          }}
        />

        <div className={'flex items-center'}>
          <Switch
            checked={formNotifyApp.one_time_notification}
            label={'Notifikasi Sekali Muncul'}
            onChange={e => {
              setFormNotifyApp({
                ...formNotifyApp,
                one_time_notification: e.target.checked
              });
            }}
          />
        </div>
      </div>
      <div className={'flex flex-col gap-[24px]'}>
        <TextField
          value={formNotifyApp.cta}
          block
          label={'Link Notification'}
          onChange={e => setFormNotifyApp({ ...formNotifyApp, cta: e.target.value })}
        />
        {formNotifyApp.media_uid !== '-' ? (
          <div className={'flex'}>
            <div className={'w-full'}>
              <p className={'text-[14px] font-[400] text-text-tertiary-invert mb-[5px]'}>Current Image</p>
              <Image url={formNotifyApp.media_uid} height={150} />
            </div>
            <div className={'w-full'}>
              <Dropzone label={'New Image'} onChange={e => setFormNotifyApp({ ...formNotifyApp, media: e })} />
            </div>
          </div>
        ) : (
          <Dropzone label={'Image'} onChange={e => setFormNotifyApp({ ...formNotifyApp, media: e })} />
        )}

        <Textarea
          value={formNotifyApp.message}
          block
          label={'Message'}
          height={150}
          onChange={e => setFormNotifyApp({ ...formNotifyApp, message: e.target.value })}
        />
      </div>

      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleUpdateNotifyAppModal.setState(false)} />
        <Button block label="Save" onClick={() => handleUpdateDataNotification()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogUpdateNotificationApp;
