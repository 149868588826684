import { BaseModel } from '@/domain/model/base_model.ts';
import { GoldRateModel } from '@/domain/model/gold_rate_model.ts';

export class GoldRateLatestModel extends BaseModel {
  currentRate: GoldRateModel;
  previousRate: GoldRateModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    currentRate: GoldRateModel,
    previousRate: GoldRateModel
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.currentRate = currentRate;
    this.previousRate = previousRate;
  }

  public static fromJson(data: any): any | GoldRateLatestModel {
    try {
      return new GoldRateLatestModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.current_rate ? GoldRateModel.fromJson(data.current_rate) : null,
        data.previous_rate ? GoldRateModel.fromJson(data.previous_rate) : null
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): GoldRateLatestModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(GoldRateModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
