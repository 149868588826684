import { ApiClient } from '@/main/apiClient';
import { MediaModel } from '@/domain/model/media_model';
import MediaInterface, { MediaValue } from './interface/media_interface';
import { PaginationModel } from '@/domain/model/pagination_model';
import { Media } from '@/domain/constant/api_list.ts';

interface IMedia {
  data: MediaModel;
  pagination: PaginationModel;
}

class MediaRepository implements MediaInterface {
  api = new ApiClient();

  async fetchMedia(data: MediaValue) {
    return new Promise((resolve, reject) => {
      this.api
        .get(Media.FETCH, {
          params: data
        })
        .then((result: any) => {
          const data = MediaModel.fromJsonList(result.data.data.result);
          const pagination = PaginationModel.fromJson(result.data.data.pagination);

          const media: IMedia = {
            data: data,
            pagination: pagination
          };

          return resolve(media);
        })
        .catch(err => {
          return reject(err);
        });
    });
  }

  async showMedia(data: MediaValue) {
    return new Promise((resolve, reject) => {
      this.api
        .get(Media.SHOW, {
          params: data
        })
        .then((result: any) => {
          const data = MediaModel.fromJson(result.data.data);
          return resolve(data);
        })
        .catch((err: any) => {
          return reject(err);
        });
    });
  }

  async storeMedia(data: any) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Media.STORE, data, {
            contentType: 'multipart/form-data'
          })
          .then((result: any) => {
            const data = MediaModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    } catch {
      return null;
    }
  }

  async storeMediaMultiple(data: any) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(Media.STORE_MULTIPLE, data, {
            contentType: 'multipart/form-data'
          })
          .then((result: any) => {
            const data = MediaModel.fromJsonList(result.data.data);
            resolve(data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    } catch {
      return null;
    }
  }

  async deleteMedia(_uuid: string) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(Media.DELETE + _uuid, '')
          .then((result: any) => {
            resolve(result);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    } catch {
      return null;
    }
  }
}

export default MediaRepository;
