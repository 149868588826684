import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useMemberVefiryContext } from '@/delivery/ui/admin/data_member/member_verify/context/member_verify_context.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { useEffect, useState } from 'react';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import { STATUS_CORPORATE_LIST } from '@/domain/constant/status_corporate.ts';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';

const FilterMemberVerifyList = () => {
  const { handleFetchMasterBank, handleFetchUserDataVerification, formFilterMemberVerify, setFormFilterMemberVerify } =
    useMemberVefiryContext();

  useEffect(() => {
    handleFetchMasterBank();
  }, []);

  const [isResetting, setIsResetting] = useState(false);

  const handleReset = () => {
    setFormFilterMemberVerify({ search: '', master_bank_uuid: '', account_number: '', account_holder_name: '' });
    setIsResetting(true);
  };

  useEffect(() => {
    if (isResetting) {
      handleFetchUserDataVerification({ page: 1, limit: 10 });
      setIsResetting(false);
    }
  }, [setFormFilterMemberVerify, isResetting]);

  return (
    <div className="flex gap-[12px]">
      <Button type={'secondary'} prefixIcon={Icon.reset} label={'Reset Filter'} onClick={handleReset} />
      <ButtonFilter
        onClickApply={() => handleFetchUserDataVerification({})}
        size={'small'}
        titleFilter={'Filter Member Verify'}
        label={'More Filter'}
      >
        <div className={'flex flex-col gap-[20px]'}>
          <TextField
            value={formFilterMemberVerify.search}
            search
            placeholder={'Search...'}
            block
            onChange={e => setFormFilterMemberVerify({ ...formFilterMemberVerify, search: e.target.value })}
          />
          <div className={'flex gap-[20px]'}>
            <Select2
              value={formFilterMemberVerify.master_bank_uuid}
              block
              options={STATUS_CORPORATE_LIST.map(item => {
                return {
                  label: item.name,
                  value: item.UUID
                };
              })}
              label={'Status'}
              onChange={e => setFormFilterMemberVerify({ ...formFilterMemberVerify, status: e.value })}
            />
            <TextField
              value={formFilterMemberVerify.account_holder_name}
              block
              label={'Bank Acc Holder'}
              onChange={e =>
                setFormFilterMemberVerify({ ...formFilterMemberVerify, account_holder_name: e.target.value })
              }
            />
            <TextField
              value={formFilterMemberVerify.account_number}
              type={'number'}
              block
              label={'Bank Acc Number'}
              onChange={e =>
                setFormFilterMemberVerify({
                  ...formFilterMemberVerify,
                  account_number: e.target.value
                })
              }
            />
          </div>
          <div className={'flex gap-[20px]'}>
            <Datepicker
              value={formFilterMemberVerify.reg_start}
              label={'Start Date'}
              block
              onChange={e =>
                setFormFilterMemberVerify({
                  ...formFilterMemberVerify,
                  reg_start: e
                })
              }
            />
            <Datepicker
              value={formFilterMemberVerify.reg_end}
              label={'End Date'}
              block
              onChange={e =>
                setFormFilterMemberVerify({
                  ...formFilterMemberVerify,
                  reg_end: e
                })
              }
            />
          </div>
          {/*<div className={'flex gap-[20px]'}>*/}
          {/*  <TextField block label={'Gender'} />*/}
          {/*  <TextField block label={'Status'} />*/}
          {/*  <Datepicker block label={'Date'} />*/}
          {/*</div>*/}
        </div>
      </ButtonFilter>
    </div>
  );
};

export default FilterMemberVerifyList;
