import { BaseModel } from '@/domain/model/base_model';
import { IMAGE_URL } from '@/domain/constant/image_url.ts';
import { UserModel } from '@/domain/model/user_model.ts';
import { MasterBankModel } from '@/domain/model/master_bank_model.ts';
import { formatDateTime } from '@/infrastructure/helper/formatDate.ts';

export class UserVerificationModel extends BaseModel {
  status: string;
  userUID: string;
  name: string;
  gender: string;
  religion: string;
  birthPlace: string;
  dateOfBirth: string;
  identityNumber: string;
  identityMediaUID: string;
  selfieMediaUID: string;
  masterBankUUID: string;
  type: string;
  accountNumber: string;
  accountHolderName: string;
  notes: string;
  mediaUID: string;
  latitude: string;
  longitude: string;
  fullAddress: string;
  nationalID: string;
  provinceID: string;
  cityID: string;
  districtID: string;
  villageID: string;
  zipCode: string;
  user: UserModel;
  masterBank: MasterBankModel;
  updatedByUser: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    status: string,
    userUID: string,
    name: string,
    gender: string,
    religion: string,
    birthPlace: string,
    dateOfBirth: string,
    identityNumber: string,
    identityMediaUID: string,
    selfieMediaUID: string,
    masterBankUUID: string,
    type: string,
    accountNumber: string,
    accountHolderName: string,
    notes: string,
    mediaUID: string,
    latitude: string,
    longitude: string,
    fullAddress: string,
    nationalID: string,
    provinceID: string,
    cityID: string,
    districtID: string,
    villageID: string,
    zipCode: string,
    user: UserModel,
    masterBank: MasterBankModel,
    updatedByUser: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.UID = UID;
    this.status = status;
    this.userUID = userUID;
    this.name = name;
    this.gender = gender;
    this.religion = religion;
    this.birthPlace = birthPlace;
    this.dateOfBirth = dateOfBirth;
    this.identityNumber = identityNumber;
    this.identityMediaUID = identityMediaUID;
    this.selfieMediaUID = selfieMediaUID;
    this.masterBankUUID = masterBankUUID;
    this.type = type;
    this.accountNumber = accountNumber;
    this.accountHolderName = accountHolderName;
    this.notes = notes;
    this.mediaUID = mediaUID;
    this.latitude = latitude;
    this.longitude = longitude;
    this.fullAddress = fullAddress;
    this.nationalID = nationalID;
    this.provinceID = provinceID;
    this.cityID = cityID;
    this.districtID = districtID;
    this.villageID = villageID;
    this.zipCode = zipCode;
    this.user = user;
    this.masterBank = masterBank;
    this.updatedByUser = updatedByUser;
  }

  get getSelfieMediaUrl(): string {
    if (this.selfieMediaUID) {
      return `${IMAGE_URL}/${this.selfieMediaUID}`;
    }
    return '#';
  }

  get getMediaUrl(): string {
    if (this.mediaUID) {
      return `${IMAGE_URL}/${this.mediaUID}`;
    }
    return '#';
  }

  get getIdentityMediaUrl(): string {
    if (this.identityMediaUID) {
      return `${IMAGE_URL}/${this.identityMediaUID}`;
    }
    return '#';
  }

  get getCreatedAt(): string {
    if (this.createdAt) {
      return formatDateTime(this.createdAt);
    }
    return '-';
  }

  public static fromJson(data: any): any | UserVerificationModel {
    try {
      return new UserVerificationModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.status,
        data.user_uid,
        data.name,
        data.gender,
        data.religion,
        data.birth_place,
        data.date_of_birth,
        data.identity_number,
        data.identity_media_uid,
        data.selfie_media_uid,
        data.master_bank_uuid,
        data.type,
        data.account_number,
        data.account_holder_name,
        data.notes,
        data.media_uid,
        data.latitude,
        data.longitude,
        data.full_address,
        data.national_id,
        data.province_id,
        data.city_id,
        data.district_id,
        data.village_id,
        data.zip_code,
        data.user ? UserModel.fromJson(data.user) : null,
        data.master_bank ? MasterBankModel.fromJson(data.master_bank) : null,
        data.updated_by_user ? data.updated_by_user.first_name : ''
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): UserVerificationModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(UserVerificationModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
