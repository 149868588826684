import UserDetail from '@/delivery/ui/admin/settings/admin_access/detail/user/user_detail.tsx';
import { UserProvider } from '@/delivery/ui/admin/settings/admin_access/context/user_context.tsx';

const DetailUserPage = () => {
  return (
    <UserProvider>
      <UserDetail />
    </UserProvider>
  );
};

export default DetailUserPage;
