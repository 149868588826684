import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { DCARequestModel } from '@/domain/model/dca_request_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { DCARequestRepository } from '@/domain/repository/dca_request_repository.ts';
import { formatDateTimeTParam } from '@/infrastructure/helper/formatDate.ts';
import { DCA_STATUS } from '@/domain/constant/dca/dca_status.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';

interface DialogContextProps {
  children: ReactNode;
}

interface FormFilterAccountRequest {
  approved_at?: string;
  fiat?: number;
  gram?: number;
  search?: string;
}

interface AccountRequestValueParams {
  page?: number;
  limit?: number;
}

interface FormAccountRequestInterface {
  uid?: string;
  commission?: number;
  commission_type?: string;
  type?: string;
  status?: string;
  close_method?: string;
}

interface AccountRequestValue {
  accountRequest?: DCARequestModel;
  formFilterAccountRequest: FormFilterAccountRequest;
  setFormFilterAccountRequest: Dispatch<SetStateAction<FormFilterAccountRequest>>;
  formAccountRequest: FormAccountRequestInterface;
  setFormAccountRequest: Dispatch<SetStateAction<FormAccountRequestInterface>>;
  // accountRequest: FormAccountRequestInterface | undefined;
  accountRequests: DCARequestModel[];
  accountRequestPagination?: PaginationModel;
  handleAddAccountRequestModal: ModalObject;
  handleUpdateAccountRequestModal: ModalObject;
  handleUpdateCloseDCAModal: ModalObject;
  handleFetchAccountRequest: (_data: AccountRequestValueParams) => void;
  handleShowAccountRequest: (_data: DCARequestModel) => void;
  handleUpdateAccountRequest: () => void;
  handleShowAccountRequestCloseDCA: (_data: DCARequestModel) => void;
  handleCloseDCA: () => void;
}

const AccountRequestContext = createContext<AccountRequestValue | null>(null);

const accountRequestRepository = new DCARequestRepository();

const AccountRequestProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const handleAddAccountRequestModal = useVisibleComponent(false);
  const handleUpdateAccountRequestModal = useVisibleComponent(false);
  const handleUpdateCloseDCAModal = useVisibleComponent(false);

  const [formFilterAccountRequest, setFormFilterAccountRequest] = useState<FormFilterAccountRequest>({});

  // const [accountRequest, setAccountRequest] = useState<AccountRequestModel>();
  const [accountRequest, setAccountRequest] = useState<DCARequestModel>();
  const [accountRequests, setAccountRequests] = useState<DCARequestModel[]>([]);
  const [accountRequestPagination, setAccountRequestPagination] = useState<PaginationModel>();

  const [formAccountRequest, setFormAccountRequest] = useState<FormAccountRequestInterface>({});

  //FETCH AFFILIATE
  const handleFetchAccountRequest = (_data: AccountRequestValueParams) => {
    const x = alertToast.loadingAlert('');

    const params = {
      page: _data?.page ?? 1,
      limit: _data?.limit ?? 10,
      approved_at: formFilterAccountRequest.approved_at
        ? formatDateTimeTParam(formFilterAccountRequest.approved_at)
        : null,
      fiat: formFilterAccountRequest.fiat ?? null,
      gram: formFilterAccountRequest.gram ?? null,
      search: formFilterAccountRequest.search ?? null
    };

    accountRequestRepository
      .fetchDCARequest(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setAccountRequests(result.data);
        setAccountRequestPagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW AFFILIATE
  const handleShowAccountRequest = (_data: DCARequestModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };

    accountRequestRepository
      .showDCARequest(params)
      .then((res: any) => {
        const result: DCARequestModel = res;
        alertToast.updateLoading(x);
        setAccountRequest(result);
        setFormAccountRequest({
          uid: result.UID,
          status: result.status
        });
        handleUpdateAccountRequestModal.setState(true);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //UPDATE AFFILIATE
  const handleUpdateAccountRequest = () => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: formAccountRequest.uid,
      status: formAccountRequest.status
    };

    accountRequestRepository
      .updateDCARequest(params)
      .then(() => {
        alertToast.updateLoading(x);
        handleUpdateAccountRequestModal.setState(false);
        handleFetchAccountRequest({ page: 1 });
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //SHOW AFFILIATE
  const handleShowAccountRequestCloseDCA = (_data: DCARequestModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };

    accountRequestRepository
      .showDCARequest(params)
      .then((res: any) => {
        const result: DCARequestModel = res;
        alertToast.updateLoading(x);
        setFormAccountRequest({
          uid: result.UID,
          status: result.status
        });
        handleUpdateCloseDCAModal.setState(true);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE CLOSE DCA
  const handleCloseDCA = () => {
    alertSweet.questionAlert('Apakah anda yakin close DCA?').then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          uid: formAccountRequest.uid,
          status: DCA_STATUS.CLOSED,
          meta: { 'CLOSE METHOD': formAccountRequest.close_method }
        };

        accountRequestRepository
          .updateDCARequest(params)
          .then(() => {
            alertToast.updateLoading(x);
            handleUpdateCloseDCAModal.setState(false);
            handleFetchAccountRequest({ page: 1 });
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: AccountRequestValue = {
    accountRequest,
    formFilterAccountRequest,
    setFormFilterAccountRequest,
    handleUpdateAccountRequest,
    formAccountRequest,
    setFormAccountRequest,
    // accountRequest,
    accountRequests,
    accountRequestPagination,
    handleFetchAccountRequest,
    handleAddAccountRequestModal,
    handleUpdateAccountRequestModal,
    handleUpdateCloseDCAModal,
    handleShowAccountRequest,
    handleShowAccountRequestCloseDCA,
    handleCloseDCA
  };

  return <AccountRequestContext.Provider value={contextValue}>{children}</AccountRequestContext.Provider>;
};

const useAccountRequestContext = (): AccountRequestValue => {
  const context = useContext(AccountRequestContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a AccountRequestProvider');
  }
  return context;
};

export { AccountRequestProvider, useAccountRequestContext };
