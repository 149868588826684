import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useDCAActivationContext } from '@/delivery/ui/admin/data_dca/dca_activation/context/dca_activation_context.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { DCA_STATUS_ACTIVATION_LIST } from '@/domain/constant/dca/dca_status.ts';

const FilterDCAActivation = () => {
  const { handleFetchUser, formFilterDCAActivation, setFormFilterDCAActivation } = useDCAActivationContext();

  return (
    <div className="flex gap-[12px]">
      <ButtonFilter
        onClickApply={() => handleFetchUser({})}
        size={'large'}
        titleFilter={'Filter DCA Activation'}
        label={'More Filter'}
      >
        <div className={'flex flex-col gap-[20px]'}>
          <TextField
            search
            placeholder={'Search...'}
            block
            onChange={e => setFormFilterDCAActivation({ ...formFilterDCAActivation, search: e.target.value })}
          />

          <Select2
            label={'Status DCA'}
            options={DCA_STATUS_ACTIVATION_LIST.map(item => {
              return {
                value: item.UUID,
                label: item.name
              };
            })}
            placeholder={'Status'}
            block
            onChange={e => setFormFilterDCAActivation({ ...formFilterDCAActivation, dca_status: e.value })}
          />
        </div>
      </ButtonFilter>
    </div>
  );
};

export default FilterDCAActivation;
