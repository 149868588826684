import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { STATUS_LIST } from '@/domain/constant/status.ts';
import { useEffect } from 'react';
import { useVendingMachineContext } from '@/delivery/ui/admin/transaction/take_gold/vending_machine/context/vending_machine_context.tsx';

const DialogAddVendingMachine = (props: ModalDialogProps) => {
  const {
    handleAddVendingMachineModal,
    vendors,
    handleSubmitAddVendingMachine,
    formVendingMachine,
    setFormVendingMachine,
    handleFetchVendors,

    handleFetchProvince,
    provinces,
    cities,
    districts,
    villages,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    villageID,
    setVillageID
  } = useVendingMachineContext();

  useEffect(() => {
    handleFetchProvince();
    handleFetchVendors({});
  }, []);

  //fetch cities
  useEffect(() => {
    if (provinceID !== null) {
      handleFetchCities();
    }
  }, [provinceID]);

  //fetch district
  useEffect(() => {
    if (cityID !== 0) {
      handleFetchDistricts();
    }
  }, [cityID]);

  //fetch village
  useEffect(() => {
    if (districtID > 0) {
      handleFetchVillage();
    }
  }, [districtID]);
  return (
    <ModalDialog size="small" title="Add Vending Machine" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField
          value={formVendingMachine.name}
          label="Nama"
          placeholder="Nama"
          block
          onChange={e => {
            setFormVendingMachine({
              ...formVendingMachine,
              name: e.target.value
            });
          }}
        />
        <TextField
          value={formVendingMachine.code}
          label="Code"
          placeholder="Code"
          block
          onChange={e => {
            setFormVendingMachine({
              ...formVendingMachine,
              code: e.target.value
            });
          }}
        />

        <Select2
          value={formVendingMachine.vendorUID}
          label={'Vendor'}
          options={vendors.map(item => {
            return {
              label: item.name,
              value: item.UID
            };
          })}
          onChange={(e: any) => {
            setFormVendingMachine({
              ...formVendingMachine,
              vendorUID: e?.value
            });
          }}
        />

        <Select2
          value={provinceID.toString()}
          block
          label={'Provinsi'}
          options={provinces?.map(item => {
            return {
              label: item.name,
              value: item.ID.toString()
            };
          })}
          onChange={e => {
            setProvinceID(parseInt(e.value));
          }}
        />
        <Select2
          value={cityID.toString()}
          block
          label={'Kota/Kab'}
          options={cities?.map(item => {
            return {
              label: item.name,
              value: item.ID.toString()
            };
          })}
          onChange={e => setCityID(parseInt(e.value))}
        />
        <Select2
          value={districtID.toString()}
          block
          label={'Kecamatan'}
          options={districts?.map(item => {
            return {
              label: item.name,
              value: item.ID.toString()
            };
          })}
          onChange={e => setDistrictID(parseInt(e.value))}
        />
        <Select2
          value={villageID.toString()}
          block
          label={'Kelurahan'}
          options={villages?.map(item => {
            return {
              label: item.name,
              value: item.ID.toString()
            };
          })}
          onChange={e => setVillageID(parseInt(e.value))}
        />
        <TextField
          value={formVendingMachine.fullAddress}
          block
          label={'Alamat Lengkap'}
          onChange={e =>
            setFormVendingMachine({
              ...formVendingMachine,
              fullAddress: e.target.value
            })
          }
        />
        <TextField
          value={formVendingMachine.zipCode}
          type={'number'}
          block
          label={'Kode POS'}
          onChange={e => setFormVendingMachine({ ...formVendingMachine, zipCode: e.target.value })}
        />

        <Select2
          value={formVendingMachine.status}
          label={'Status'}
          options={STATUS_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          onChange={(e: any) => {
            setFormVendingMachine({
              ...formVendingMachine,
              status: e.value
            });
          }}
        />
      </div>
      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleAddVendingMachineModal.setState(false)} />
        <Button label="Submit" onClick={() => handleSubmitAddVendingMachine()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddVendingMachine;
