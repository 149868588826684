import Navbar from '@/delivery/components/organisms/navbar/navbar';
import MemberVerify from './member_verify';

const MemberVerifyPage = () => {
  return (
    <div>
      <Navbar label={'Member Request Verify'}></Navbar>
      <MemberVerify />
    </div>
  );
};

export default MemberVerifyPage;
