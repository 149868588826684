import Button from '@/delivery/components/atoms/button/button';
import { TBox, Theader } from '@/delivery/components/molecules/table/table';
import ChartBuySellTransaction from './chart_buy_sell_transaction';
import SummaryTransaction from '@/delivery/ui/admin/dashboard/transaction/summary_transaction.tsx';
import { useDashboardTransactionContext } from '@/delivery/ui/admin/dashboard/transaction/context/dashboard_transaction_context.tsx';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';
import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import { useEffect, useState } from 'react';
import { getFirstAndLastDateOfCurrentMonth } from '@/domain/constant/get_first_data_and_last_date.ts';
import Icon from '@/domain/constant/icon.tsx';
import { usePDF } from 'react-to-pdf';

const Transaction = () => {
  const { toPDF, targetRef } = usePDF({ filename: `grafik-transaksi-jual-beli-${formatDateParam(new Date())}.pdf` });
  const { firstDate, lastDate } = getFirstAndLastDateOfCurrentMonth();
  const alertToast = useAlertToast();

  const {
    transactionChartBuyData,
    transactionChartSellData,
    formDashboardTransaction,
    setFormDashboardTransaction,
    transactionChartSell,
    transactionChartBuy
  } = useDashboardTransactionContext();
  const [initialChart, setInitialChart] = useState(false);

  const handleApplyFilter = () => {
    const x = alertToast.loadingAlert('');
    transactionChartSell()
      .then(() => {
        alertToast.updateLoading(x);
        transactionChartBuy()
          .then(() => {
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //INITIAL
  useEffect(() => {
    if (firstDate) {
      setFormDashboardTransaction({
        ...formDashboardTransaction,
        start_date: firstDate.toString(),
        end_date: lastDate.toString()
      });
      setInitialChart(true);
    }
  }, [firstDate]);

  useEffect(() => {
    if (initialChart) {
      setTimeout(() => {
        handleApplyFilter();
      }, 2000);
      setInitialChart(false);
    }
  }, [initialChart]);

  return (
    <div>
      <SummaryTransaction />
      <div className="mt-[32px]">
        <Theader>
          <span className="text-[18px] font-[600]">Grafik Transaksi Jual dan Beli</span>
          <div className={'flex gap-[10px]'}>
            <ButtonFilter
              onClickApply={() => handleApplyFilter()}
              size={'small'}
              titleFilter={'Filter Grafik Jual dan Beli'}
              label={'More Filter'}
            >
              <div className={'flex gap-[20px]'}>
                <Datepicker
                  label={'Start Date'}
                  placeholder={'Start Date'}
                  value={formDashboardTransaction.start_date}
                  block
                  onChange={e =>
                    setFormDashboardTransaction({
                      ...formDashboardTransaction,
                      start_date: formatDateParam(e)
                    })
                  }
                />
                <Datepicker
                  label={'End Date'}
                  value={formDashboardTransaction.end_date}
                  placeholder={'End Date'}
                  block
                  onChange={e =>
                    setFormDashboardTransaction({
                      ...formDashboardTransaction,
                      end_date: formatDateParam(e)
                    })
                  }
                />
              </div>
            </ButtonFilter>
            <Button label="Export PDF" type="secondary" prefixIcon={Icon.document} onClick={() => toPDF()} />
          </div>
        </Theader>
        <TBox>
          <div ref={targetRef}>
            <ChartBuySellTransaction dataTrxBuy={transactionChartBuyData} dataTrxSell={transactionChartSellData} />
          </div>
        </TBox>
      </div>
    </div>
  );
};

export default Transaction;
