import Navbar from '@/delivery/components/organisms/navbar/navbar';
import FormatEmail from './format_email';

import Button from '@/delivery/components/atoms/button/button';
import Icon from '@/domain/constant/icon.tsx';

const FormatEmailPage = () => {
  return (
    <div>
      <Navbar label={'Format Email'}>
        <Button prefixIcon={Icon.plus} label="Add Format Email" />
      </Navbar>
      <FormatEmail />
    </div>
  );
};

export default FormatEmailPage;
