import { ApiClient } from '@/main/apiClient';
import UserInterface, { UserValue } from '@/domain/repository/interface/user_interface';
import { User } from '@/domain/constant/api_list';
import { PaginationModel } from '@/domain/model/pagination_model';
import { UserModel } from '@/domain/model/user_model';

export class UserRepository implements UserInterface {
  api = new ApiClient();

  async fetchUser(data: UserValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(User.FETCH, {
            params: data
          })
          .then(result => {
            const data = UserModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showUser(data: UserValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(User.SHOW, {
            params: data
          })
          .then(result => {
            const data = UserModel.fromJson(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateUser(data: UserValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(User.UPDATE + data.uuid, data)
          .then(result => {
            const data = UserModel.fromJson(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteUser(data: UserValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(User.DELETE + data.uuid, '')
          .then(result => {
            const data = UserModel.fromJsonList(result.data.data.result);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
