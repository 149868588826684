export const Auth = {
  LOGIN: '/login',
  INQUIRY: '/auth/inquiry',
  LOGIN_OTP: '/login-otp/email/',
  EXC_LOGIN_OTP: '/login-otp/',
  USER_CLAIM: '/api/me/claim',
  USER_INFO: '/api/me/user-info',
  USER_ACCESS: '/api/me/user-access',
  SWITCH_COMPANY: 'switch-company/',
  REFRESH_TOKEN: '/api/refresh/', //{refreshToken},
  REGISTER: '/register/user',
  RESET_PASSWORD: '/api/reset-password/email',
  VERIFY_EMAIL: '/api/verify-email' //send {email}, get {token}
} as const;

export const LoginOtp = {
  SEND_EMAIL_LOGIN: '/api/login-otp/email/', //EMAIL OTP
  EXCEC_LOGIN_OTP_CODE: '/api/login-otp/' //OTP CODE
};

export const Role = {
  STORE: '/api/role',
  FETCH: '/api/roles',
  UPDATE: '/api/role/',
  DELETE: '/api/role/',
  SHOW: '/api/role',
  STORE_BULK: '/api/roles'
} as const;

export const RoleAccess = {
  STORE: '/api/role-access',
  STORE_BULK: '/api/role-accesses',
  FETCH: '/api/role-accesses',
  UPDATE: '/api/role-access/',
  DELETE: '/api/role-access/',
  SHOW: '/api/role-access'
} as const;

export const CustomAccess = {
  STORE: '/api/custom-access',
  STORE_BULK: '/api/custom-accesses',
  FETCH: '/api/custom-accesses',
  UPDATE: '/api/custom-access/',
  DELETE: '/api/custom-access/',
  SHOW: '/api/custom-access'
} as const;

export const AccessController = {
  FETCH: '/api/access-controller/access',
  MODULE: '/api/access-controller/access/module'
} as const;

export const User = {
  STORE: '/api/user',
  FETCH: '/api/users',
  UPDATE: '/api/user/', //{uuid}
  DELETE: '/api/user/', //{uuid}
  SHOW: '/api/user'
} as const;

export const UserVerification = {
  STORE: '/api/user_verification',
  STORE_DASHBOARD: '/api/dashboard/user-verifications',
  STORE_BULK: '/api/user-verification',
  FETCH: '/api/user-verifications',
  UPDATE: '/api/user-verification/',
  DELETE: '/api/user-verification/',
  SHOW: '/api/user-verification'
};

export const UserWithdrawalAccount = {
  STORE: '/api/user_withdrawal_account',
  STORE_BULK: '/api/user_withdrawal_account',
  FETCH: '/api/user-withdrawal-accounts',
  UPDATE: '/api/user-withdrawal-account/',
  DELETE: '/api/user_withdrawal_account/',
  SHOW: '/api/user-withdrawal-account'
};

export const GoldRate = {
  STORE: '/api/gold-rate',
  STORE_BULK: '/api/gold-rate',
  FETCH: '/api/gold-rates',
  UPDATE: '/api/gold-rate/',
  DELETE: '/api/gold-rate/',
  SHOW: '/api/gold-rate',
  LATEST: '/api/gold-rate/latest'
};

export const GoldStock = {
  SUMMARY: '/api/gold-stock-summary',
  STORE: '/api/gold-stock',
  STORE_BULK: '/api/gold-stock',
  FETCH: '/api/gold_stocks',
  UPDATE: '/api/gold-stock/',
  DELETE: '/api/gold-stock/',
  SHOW: '/api/gold-stock'
};

export const Transaction = {
  MANUAL: '/api/transaction-manual',
  LOCK: '/api/transaction-lock',
  FETCH: '/api/transactions',
  FETCH_DASHBOARD: '/api/dashboard/transactions',
  FETCH_PRINT: '/api/transaction-prints',
  UPDATE: '/api/transaction/',
  SETTLEMENT: '/api/transaction/settlement',
  CANCEL: '/api/transaction/cancel/',
  DELETE: '/api/transaction/',
  SHOW: '/api/transaction',
  BUY: '/api/transaction-buy',
  WITHDRAW: '/api/transaction-withdraw'
};

export const TransactionPrint = {
  CONFIRMATION: '/api/transaction-print/confirmation'
};

export const Affiliate = {
  STORE: '/api/affiliate',
  STORE_BULK: '/api/affiliates',
  FETCH: '/api/affiliates',
  UPDATE: '/api/affiliate/',
  DELETE: '/api/affiliate/',
  SHOW: '/api/affiliate',
  FETCH_TRANSACTION: '/api/transaction-affiliates',
  FETCH_AFFILIATE_COUNT: '/api/profile-affiliates',
  FETCH_AFFILIATE_USER: '/api/transaction-affiliate-user'
};

export const PaymentChannel = {
  STORE: '/api/payment-channel',
  STORE_BULK: '/api/payment_channels',
  FETCH: '/api/payment_channels',
  UPDATE: '/api/payment-channel/',
  DELETE: '/api/payment-channel/',
  SHOW: '/api/payment-channel'
};

export const PaymentMethod = {
  STORE: '/api/payment-method',
  STORE_BULK: '/api/payment_methods',
  FETCH: '/api/payment_methods',
  UPDATE: '/api/payment-method/',
  DELETE: '/api/payment-method/',
  SHOW: '/api/payment-method'
};

export const PaymentChannelFee = {
  STORE: '/api/payment-channel-fee',
  FETCH: '/api/payment_channel_fees',
  SHOW: '/api/payment-channel-fee'
};

export const Corporate = {
  STORE: '/api/corporate',
  STORE_BULK: '/api/corporates',
  FETCH: '/api/corporates',
  UPDATE: '/api/corporate/',
  DELETE: '/api/corporate/',
  SHOW: '/api/corporate'
} as const;

export const CorporateVerification = {
  STORE: '/api/corporate-verification',
  STORE_BULK: '/api/corporate_verifications',
  FETCH: '/api/corporate_verifications',
  UPDATE: '/api/corporate-verification/',
  DELETE: '/api/corporate-verification/',
  SHOW: '/api/corporate-verification'
} as const;

export const Region = {
  NATIONALITY: '/api/region/nationalities',
  PROVINCE: '/api/region/provinces',
  CITY: '/api/region/cities',
  DISTRICT: '/api/region/districts',
  VILLAGE: '/api/region/villages'
} as const;

export const MediaAssignments = {
  STORE: '/api/media_assignment',
  FETCH: '/api/media_assignments',
  UPDATE: '/api/media_assignment',
  DELETE: '/api/media_assignment',
  SHOW: '/api/media_assignment',
  IMPORT: 'media_assignment/import'
} as const;

export const Media = {
  STORE_MULTIPLE: '/api/media-multiple',
  STORE: '/api/media',
  FETCH: '/api/medias',
  DELETE: '/api/media/', //{uuid}
  SHOW: '/api/media/'
} as const;

export const Notification = {
  STORE_BULK: '/api/notifications',
  STORE: '/api/notification',
  FETCH: '/api/notifications',
  DELETE: '/api/notification/', //{uuid}
  SHOW: '/api/notification',
  UPDATE: '/api/notification/'
} as const;

export const WebPage = {
  STORE_BULK: '/api/web_pages',
  STORE: '/api/web-page',
  FETCH: '/api/web_pages',
  DELETE: '/api/web-page/', //{uuid}
  SHOW: '/api/web-page',
  UPDATE: '/api/web-page/'
} as const;

export const MasterBank = {
  STORE_BULK: '/api/master_banks',
  STORE: '/api/master-bank',
  FETCH: '/api/master_banks',
  DELETE: '/api/master-bank/', //{uuid}
  SHOW: '/api/master-bank',
  UPDATE: '/api/master-bank/'
} as const;

export const Profile = {
  FETCH: '/api/profiles',
  SHOW: '/api/profile/',
  UPDATE: '/api/profile/'
} as const;

export const DinaranSetting = {
  STORE_BULK: '/api/dinaran_settings',
  STORE: '/api/dinaran-setting',
  FETCH: '/api/dinaran_settings',
  DELETE: '/api/dinaran-setting/', //{uuid}
  SHOW: '/api/dinaran-setting',
  UPDATE: '/api/dinaran-setting/'
} as const;

export const CompanySetting = {
  STORE: '/api/company_setting',
  FETCH: '/api/company_settings',
  DELETE: '/api/company_setting/', //{uuid}
  SHOW: '/api/company_setting',
  UPDATE: '/api/company_setting/'
} as const;

export const WithdrawalMethod = {
  STORE: '/api/withdrawal-method',
  STORE_BULK: '/api/withdrawal_methods',
  FETCH: '/api/withdrawal_methods',
  DELETE: '/api/withdrawal-method/', //{uuid}
  SHOW: '/api/withdrawal-method',
  UPDATE: '/api/withdrawal-method/'
} as const;

export const WithdrawalFee = {
  STORE: '/api/withdrawal-fee',
  STORE_BULK: '/api/withdrawal_fees',
  FETCH: '/api/withdrawal_fees',
  DELETE: '/api/withdrawal-fee/', //{uuid}
  SHOW: '/api/withdrawal-fee',
  UPDATE: '/api/withdrawal-fee/'
} as const;

export const Tier = {
  STORE_BULK: '/api/tiers',
  STORE: '/api/tier',
  FETCH: '/api/tiers',
  DELETE: '/api/tier/', //{uuid}
  SHOW: '/api/tier',
  UPDATE: '/api/tier/'
} as const;

export const OfflineCounter = {
  STORE_BULK: '/api/offline_counters',
  STORE: '/api/offline-counter',
  FETCH: '/api/offline_counters',
  DELETE: '/api/offline-counter/', //{uuid}
  SHOW: '/api/offline-counter',
  UPDATE: '/api/offline-counter/'
} as const;

export const OfflineCounterUnavailability = {
  STORE_BULK: '/api/offline_counter_unavailabilitys',
  STORE: '/api/offline-counter-unavailability',
  FETCH: '/api/offline_counter_unavailabilitys',
  DELETE: '/api/offline-counter-unavailability/', //{uuid}
  SHOW: '/api/offline-counter-unavailability',
  UPDATE: '/api/offline-counter-unavailability/'
} as const;

export const BalanceLock = {
  STORE_BULK: '/api/balance_locks',
  STORE: '/api/balance-lock',
  RELEASE: '/api/balance-lock-release',
  FETCH: '/api/balance_locks',
  DELETE: '/api/balance-lock/', //{uuid}
  SHOW: '/api/balance-lock',
  UPDATE: '/api/balance-lock/'
} as const;

export const EventReferral = {
  STORE_BULK: '/api/event_affiliates',
  STORE: '/api/event-affiliate',
  FETCH: '/api/event_affiliates',
  DELETE: '/api/event-affiliate/', //{uuid}
  SHOW: '/api/event-affiliate',
  UPDATE: '/api/event-affiliate/'
} as const;

export const AffiliateUser = {
  STORE_BULK: '/api/user_affiliates',
  STORE: '/api/user-affiliate',
  FETCH: '/api/user_affiliates',
  DELETE: '/api/user-affiliate/', //{uuid}
  SHOW: '/api/user-affiliate',
  UPDATE: '/api/user-affiliate/'
} as const;

export const DreamGold = {
  STORE_BULK: '/api/dream_golds',
  STORE: '/api/dream-gold',
  FETCH: '/api/dream_golds',
  DELETE: '/api/dream-gold/', //{uuid}
  SHOW: '/api/dream-gold',
  UPDATE: '/api/dream-gold/'
} as const;

export const DCARequest = {
  STORE_BULK: '/api/dca_requests',
  STORE: '/api/dca-request',
  FETCH: '/api/dca_requests',
  DELETE: '/api/dca-request/', //{uuid}
  SHOW: '/api/dca-request',
  UPDATE: '/api/dca-request/',
  STATISTIC: '/api/dca-request-statistic'
} as const;

export const DCA = {
  FETCH: '/api/dca-account'
} as const;

export const Address = {
  STORE: '/api/address',
  FETCH: '/api/addresses',
  DELETE: '/api/address/', //{uuid}
  SHOW: '/api/address',
  UPDATE: '/api/address/'
} as const;

export const UserAddress = {
  STORE: '/api/user_address',
  FETCH: '/api/user-addresses',
  DELETE: '/api/user_address/', //{uuid}
  SHOW: '/api/user_address',
  UPDATE: '/api/user_address/'
} as const;

export const UserHeir = {
  STORE_BULK: '/api/user_heirs',
  STORE: '/api/user-heir',
  FETCH: '/api/user_heirs',
  DELETE: '/api/user-heir/', //{uuid}
  SHOW: '/api/user-heir',
  UPDATE: '/api/user-heir/'
} as const;

export const UserDashboard = {
  SHOW_PROFILE: '/api/user-dashboard/profile',
  SHOW_BALANCE: '/api/user-dashboard/balance'
} as const;

export const UserCompany = {
  STORE: '/api/user_company',
  STORE_BULK: '/api/usercompanies',
  FETCH: '/api/usercompanies',
  UPDATE: '/api/user_company/', //{uuid}
  DELETE: '/api/user_company/', //{uuid}
  SHOW: '/api/user_company'
} as const;

export const RoleUserCompany = {
  STORE: '/api/role-user-company',
  STORE_BULK: '/api/role-user-companies',
  FETCH: '/api/role-user-companies',
  UPDATE: '/api/role-user-company/', //{uuid}
  DELETE: '/api/role-user-company/', //{uuid}
  SHOW: '/api/role-user-company'
} as const;

export const GoldPiece = {
  STORE: '/api/gold-piece',
  STORE_BULK: '/api/gold-pieces',
  FETCH: '/api/gold-pieces',
  DELETE: '/api/gold-piece/', //{uuid}
  SHOW: '/api/gold-piece',
  UPDATE: '/api/gold-piece/'
} as const;

export const GoldPieceFee = {
  STORE: '/api/gold-piece-fee',
  STORE_BULK: '/api/gold-pieces',
  FETCH: '/api/gold_piece_fees',
  DELETE: '/api/gold-piece-fee/', //{uuid}
  SHOW: '/api/gold-piece-fee',
  UPDATE: '/api/gold-piece-fee/'
} as const;

export const RiceRate = {
  STORE: '/api/rice-rate',
  STORE_BULK: '/api/rice_rates',
  FETCH: '/api/rice_rates',
  DELETE: '/api/rice-rate/', //{uuid}
  SHOW: '/api/rice-rate',
  UPDATE: '/api/rice-rate/'
} as const;

export const ICDX = {
  SEND: '/api/icdx/send-transaction'
} as const;

export const UserStatistic = {
  USER_STATUS: '/api/user-statistic/count-user',
  USER_VERIFICATION: '/api/user-statistic/count-user-verification',
  USER_TRANSACTION: '/api/user-statistic/count-user-transaction'
} as const;

export const QueryTransactionVendor = {
  TRANSACTION_VENDOR_BUY: '/api/query-transaction-vendor/buy'
} as const;

export const Vendor = {
  STORE: '/api/vendor',
  STORE_BULK: '/api/vendors',
  FETCH: '/api/vendors',
  DELETE: '/api/vendor/', //{uuid}
  SHOW: '/api/vendor',
  UPDATE: '/api/vendor/'
} as const;

export const VendingMachine = {
  STORE: '/api/vending-machine',
  STORE_BULK: '/api/vending_machines',
  FETCH: '/api/vending_machines',
  DELETE: '/api/vending-machine/', //{uuid}
  SHOW: '/api/vending-machine',
  UPDATE: '/api/vending-machine/'
} as const;

export const VendingMachineRack = {
  STORE: '/api/vending-machine-rack',
  STORE_BULK: '/api/vending_machine_racks',
  FETCH: '/api/vending_machine_racks',
  DELETE: '/api/vending-machine-rack/', //{uuid}
  SHOW: '/api/vending-machine-rack',
  UPDATE: '/api/vending-machine-rack/'
} as const;

export const GoldStockVendingMachine = {
  STOCK_SUMMARY: '/api/gold-stock-vending-machine/summary-machine',
  STORE: '/api/gold-stock-vending-machine',
  FETCH: '/api/gold-stock-vending-machines',
  SYNC: '/api/gold-stock-vending-machine/sync-to-local'
} as const;

//DASHBOARD API
export const StatisticRegisterMember = {
  DAILY: '/api/user-dashboard/statistic/register-daily',
  MONTHLY: '/api/user-dashboard/statistic/register-monthly'
};

export const TransactionStatistic = {
  SUMMARY_STATUS: '/api/transaction-statistic/summary-status'
} as const;

export const StatisticTransaction = {
  TRANSACTION_STATISTIC: '/api/dashboard-statistic',
  TRANSACTION_SUMMARY: '/api/dashboard-transaction-summary',
  TRANSACTION_RANK: '/api/dashboard-rank'
};
