import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Th, Thead, Tr, Tfooter, Td } from '@/delivery/components/molecules/table/table';
import { useDCAActivationContext } from '@/delivery/ui/admin/data_dca/dca_activation/context/dca_activation_context.tsx';
import { useEffect } from 'react';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import DialogUpdateDCA from '@/delivery/ui/admin/data_dca/dca_activation/dialog/dialog_update_dca.tsx';
import FilterDCAActivation from '@/delivery/ui/admin/data_dca/dca_activation/filter_dca_activation.tsx';
import { UserModelQuery } from '@/domain/model/user_model_query.ts';

const DCAActivation = () => {
  const { handleShowUser, handleShowUserModal, users, userPagination, handleFetchUser } = useDCAActivationContext();

  useEffect(() => {
    handleFetchUser({});
  }, []);

  return (
    <div>
      <div>
        <div className={'flex justify-end mb-[20px]'}>
          <FilterDCAActivation />
        </div>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Member</Th>
              {/*<Th>Balance (gr)</Th>*/}
              {/*<Th>Bal DCA (80%)</Th>*/}
              {/*<Th>Fasilitas DCA</Th>*/}
              {/*<Th>Lock DCA (20%)</Th>*/}
              <Th>Reg Date</Th>
              <Th>DCA Status</Th>
              <Th>Updated By </Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((item: UserModelQuery, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={400} size={12} type="primary-invert">
                    <span className={'text-text-primary'}>{item.serialID}</span>
                  </Typography>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {item.getFullName}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.email}
                  </Typography>
                </Td>
                {/*<Td>*/}
                {/*  <div className="text-right">*/}
                {/*    <Typography fontWeight={600} size={12} type="primary-invert">*/}
                {/*      {truncateDecimal(item.totalBalance.amount)}*/}
                {/*    </Typography>*/}
                {/*    <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*      {item.totalBalance.amount}*/}
                {/*    </Typography>*/}
                {/*  </div>*/}
                {/*</Td>*/}
                {/*<Td>*/}
                {/*  <div className="text-right ">*/}
                {/*    <Typography fontWeight={600} size={12} type="primary-invert">*/}
                {/*      {item.dcaBalanceFiat}*/}
                {/*    </Typography>*/}
                {/*    <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*      {item.dcaBalanceGram} gr*/}
                {/*    </Typography>*/}
                {/*  </div>*/}
                {/*</Td>*/}
                {/*<Td>*/}
                {/*  <div className={'flex flex-col gap-[10px]'}>*/}
                {/*    <table>*/}
                {/*      <tbody>*/}
                {/*        {item.dcaBalance.map(item => (*/}
                {/*          <tr>*/}
                {/*            <td className={'text-nowrap px-[10px] py-[8px]'}>*/}
                {/*              <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*                {item.serialID}*/}
                {/*              </Typography>*/}
                {/*            </td>*/}
                {/*            <td className={'text-left'}>*/}
                {/*              <Typography tag="span" fontWeight={600} size={12} type="tertiary-invert">*/}
                {/*                {item.getFiat}*/}
                {/*              </Typography>*/}
                {/*            </td>*/}
                {/*            <td>*/}
                {/*              <Chips label={item.status} type={item.getStatusColor} />*/}
                {/*            </td>*/}
                {/*          </tr>*/}
                {/*        ))}*/}
                {/*      </tbody>*/}
                {/*    </table>*/}
                {/*  </div>*/}
                {/*</Td>*/}

                {/*<Td>*/}
                {/*  <div className="text-right ">*/}
                {/*    <Typography fontWeight={600} size={12} type="primary-invert">*/}
                {/*      {truncateDecimal(item.dcaLockGram)}*/}
                {/*    </Typography>*/}
                {/*    <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*      {item.dcaLockGram}*/}
                {/*    </Typography>*/}
                {/*  </div>*/}
                {/*</Td>*/}
                <Td>
                  <Typography className="leading-4" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getCreatedAt}
                  </Typography>
                </Td>
                <Td>
                  <Chips type={item.getDCAStatusColor} label={item.dcaStatus} className="leading-4" />
                </Td>
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    System
                  </Typography>
                </Td>
                <Td>
                  <div className="flex gap-[4px]">
                    <Button iconButton iconType={'edit'} onClick={() => handleShowUser(item)} />
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            handlePagination={page => {
              handleFetchUser({
                page: page
              });
            }}
            page={userPagination?.page || 1}
            totalPages={userPagination?.totalPages || 1}
          />
        </Tfooter>
      </div>

      {handleShowUserModal.state && <DialogUpdateDCA modal={handleShowUserModal} />}
    </div>
  );
};

export default DCAActivation;
