import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useEventReferralContext } from '@/delivery/ui/admin/reseller_affiliate/event_referral/context/event_referral_context.tsx';

const FilterEventReferral = () => {
  const { handleFetchEventReferral, formFilterEventReferral, setFormFilterEventReferral } = useEventReferralContext();

  return (
    <div className="flex gap-[12px]">
      <ButtonFilter
        onClickApply={() => handleFetchEventReferral({})}
        size={'large'}
        titleFilter={'Filter Transaction'}
        label={'More Filter'}
      >
        <div className={'flex flex-col gap-[20px]'}>
          <div className={'grid grid-cols-2 gap-[20px]'}>
            <TextField
              block
              value={formFilterEventReferral.name}
              label={'Name'}
              onChange={e => setFormFilterEventReferral({ name: e.target.value })}
            />
            <TextField
              block
              value={formFilterEventReferral.type}
              label={'Jenis Referal'}
              onChange={e => setFormFilterEventReferral({ type: e.target.value })}
            />
          </div>
        </div>
      </ButtonFilter>
    </div>
  );
};

export default FilterEventReferral;
