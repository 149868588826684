import useAlertToast from '@/delivery/hooks/useAlertComponent';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { VendingMachineRackRepository } from '@/domain/repository/vending_machine_rack_repository.ts';
import { VendingMachineRackModel } from '@/domain/model/vending_machine_rack_model.ts';
import { GoldPieceRepository } from '@/domain/repository/gold_piece_repository.ts';
import { GoldPieceModel } from '@/domain/model/gold_piece_model.ts';
import { ModalObject } from '@/delivery/interface/modal_interface.ts';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent.tsx';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { GoldStockVendingMachineRepository } from '@/domain/repository/gold_stock_vending_machine_repository.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface VendingMachineParams {
  uid?: string;
  page?: number;
  limit?: number;
  vending_machine_uid?: string;
}

interface FormVendingMachineInterface {
  uid?: string;
  goldPieceUID?: string;
  rack?: string;
  vendingMachineUID?: string;
  stock?: number;

  type?: string;
  quantity?: number;
}

interface VendingMachineValue {
  vendingMachineRack?: VendingMachineRackModel;
  goldPieces: GoldPieceModel[];
  vendingMachineRacks: VendingMachineRackModel[];
  vendingMachineRackPagination?: PaginationModel;
  formVendingMachineRack: FormVendingMachineInterface;
  setFormVendingMachineRack: Dispatch<SetStateAction<FormVendingMachineInterface>>;
  handleFetchVendingMachineRack: (_data: VendingMachineParams) => void;
  handleFetchGoldPiece: () => void;
  handleAddVendingMachineRackModal: ModalObject;
  handleUpdateVendingMachineRackModal: ModalObject;
  handleStoreVendingMachineRack: (_data: VendingMachineParams) => void;
  handleDeleteVendingMachineRack: (_data: VendingMachineRackModel) => void;
  handleShowVendingMachineRack: (_data: VendingMachineRackModel) => void;
  handleUpdateVendingMachine: (_data: VendingMachineParams) => void;
}

const VendingMachineRackContext = createContext<VendingMachineValue | null>(null);

const vendingMachineRackRepository = new VendingMachineRackRepository();
const goldPieceRepository = new GoldPieceRepository();
const goldStockVendingMachineRepository = new GoldStockVendingMachineRepository();

const VendingMachineRackProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const handleAddVendingMachineRackModal = useVisibleComponent(false);
  const handleUpdateVendingMachineRackModal = useVisibleComponent(false);

  const [vendingMachineRack, setVendingMachineRack] = useState<VendingMachineRackModel>();
  const [vendingMachineRacks, setVendingMachineRacks] = useState<VendingMachineRackModel[]>([]);
  const [vendingMachineRackPagination, setVendingMachineRackPagination] = useState<PaginationModel>();

  const [goldPieces, setGoldPieces] = useState<GoldPieceModel[]>([]);

  const [formVendingMachineRack, setFormVendingMachineRack] = useState<FormVendingMachineInterface>({});

  //FETCH VENDING MACHINE RACK
  const handleFetchVendingMachineRack = (_data: VendingMachineParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      vending_machine_uid: _data.vending_machine_uid
    };

    vendingMachineRackRepository
      .fetchVendingMachineRack(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setVendingMachineRacks(result.data);
        setVendingMachineRackPagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //STORE VENDING MACHINE RACK
  const handleStoreVendingMachineRack = (_data: VendingMachineParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      gold_piece_uid: formVendingMachineRack.goldPieceUID,
      rack: formVendingMachineRack.rack,
      vending_machine_uid: _data.vending_machine_uid,
      stock: formVendingMachineRack.stock
    };

    vendingMachineRackRepository
      .storeVendingMachineRack(params)
      .then((resultRack: any) => {
        const res: VendingMachineRackModel = resultRack;

        if (res) {
          const paramsRack = {
            type: formVendingMachineRack.type,
            quantity: formVendingMachineRack.stock,
            vending_machine_rack_uid: res.UID
          };

          //store vending machine rack
          goldStockVendingMachineRepository
            .storeGoldStockVendingMachine(paramsRack)
            .then(() => {
              alertToast.updateLoading(x);
              alertToast.successAlert();
              handleAddVendingMachineRackModal.setState(false);
              handleFetchVendingMachineRack({ vending_machine_uid: _data.vending_machine_uid });
            })
            .catch(error => {
              alertToast.updateLoading(x);
              alertToast.errorAlert(error);
            });
        }
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE DELETE DELETE VENDING MACHINE RACK
  const handleDeleteVendingMachineRack = (_data: VendingMachineRackModel) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const params = {
          uid: _data.UID
        };

        vendingMachineRackRepository
          .deleteVendingMachineRack(params)
          .then((result: any) => {
            handleAddVendingMachineRackModal.setState(false);
            handleFetchVendingMachineRack({ vending_machine_uid: _data.vendingMachineUID });
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE SHOW VENDING MACHINE RACK
  const handleShowVendingMachineRack = (_data: VendingMachineRackModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };

    vendingMachineRackRepository
      .showVendingMachineRack(params)
      .then((result: any) => {
        const res: VendingMachineRackModel = result;
        setVendingMachineRack(res);
        setFormVendingMachineRack({
          uid: res.UID,
          rack: res.rack,
          stock: res.stock,
          vendingMachineUID: res.vendingMachineUID,
          goldPieceUID: res.goldPieceUID
        });
        handleUpdateVendingMachineRackModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE VENDING MACHINE RACK
  const handleUpdateVendingMachine = (_data: VendingMachineParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: formVendingMachineRack.uid,
      gold_piece_uid: formVendingMachineRack.goldPieceUID,
      rack: formVendingMachineRack.rack,
      vending_machine_uid: _data.vending_machine_uid,
      stock: formVendingMachineRack.stock
    };

    vendingMachineRackRepository
      .updateVendingMachineRack(params)
      .then((res: any) => {
        if (res) {
          const paramsRack = {
            type: formVendingMachineRack.type,
            quantity: formVendingMachineRack.stock,
            vending_machine_rack_uid: formVendingMachineRack.uid
          };

          //store vending machine rack
          goldStockVendingMachineRepository
            .storeGoldStockVendingMachine(paramsRack)
            .then(() => {
              alertToast.updateLoading(x);
              alertToast.successAlert();
              handleUpdateVendingMachineRackModal.setState(false);
              handleFetchVendingMachineRack({ vending_machine_uid: _data.vending_machine_uid });
              setFormVendingMachineRack({});
            })
            .catch(error => {
              alertToast.updateLoading(x);
              alertToast.errorAlert(error);
            });
        }
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH GOLD PIECE
  const handleFetchGoldPiece = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      page: 1,
      limit: -1
    };

    goldPieceRepository
      .fetchGoldPiece(params)
      .then((result: any) => {
        setGoldPieces(result.data);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: VendingMachineValue = {
    vendingMachineRack,
    handleAddVendingMachineRackModal,
    goldPieces,
    vendingMachineRacks,
    formVendingMachineRack,
    setFormVendingMachineRack,
    vendingMachineRackPagination,
    handleFetchVendingMachineRack,
    handleFetchGoldPiece,
    handleStoreVendingMachineRack,
    handleDeleteVendingMachineRack,
    handleUpdateVendingMachineRackModal,
    handleShowVendingMachineRack,
    handleUpdateVendingMachine
  };

  return <VendingMachineRackContext.Provider value={contextValue}>{children}</VendingMachineRackContext.Provider>;
};

const useVendingMachineRackContext = (): VendingMachineValue => {
  const context = useContext(VendingMachineRackContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a VendingMachineRackProvider');
  }
  return context;
};

export { VendingMachineRackProvider, useVendingMachineRackContext };
