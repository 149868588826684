import useAlertToast from '@/delivery/hooks/useAlertComponent';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { CorporateRepository } from '@/domain/repository/corporate_repository.ts';
import { CorporateModel } from '@/domain/model/corporate_model.ts';
import { ModalObject } from '@/delivery/interface/modal_interface.ts';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent.tsx';
import { CorporateVerifyRepository } from '@/domain/repository/corporate_verify_repository.ts';
import { MasterBankRepository } from '@/domain/repository/master_bank_repository.ts';
import { MasterBankModel } from '@/domain/model/master_bank_model.ts';
import UploadMedia from '@/infrastructure/helper/uploadMedia.tsx';
import MEDIA_PATH from '@/domain/constant/media/media_path.ts';
import { RegionProvinceModel } from '@/domain/model/region_province_model.ts';
import { RegionCityModel } from '@/domain/model/region_city_model.ts';
import { RegionDistrictModel } from '@/domain/model/region_district_model.ts';
import { RegionVillageModel } from '@/domain/model/region_village_model.ts';
import { RegionRepository } from '@/domain/repository/region_repository.ts';

interface BusinessAccountContextProps {
  children: ReactNode;
}

interface BusinessAccountParams {
  user_uid?: string;
  uid?: string;
  uuid?: string;
  page?: number;
  limit?: number;
}

interface FormCorporate {
  uid?: string;
  name?: string;
  email_corporate?: string;
  website?: string;
  phone_corporate?: string;
  status?: string;

  full_address?: string;
  national_id?: number;
  province_id?: number;
  city_id?: number;
  district_id?: number;
  village_id?: number;
  zip_code?: string;

  company_registration_number_media?: any;
  company_deed_media?: any;
  tax_payer_identification_number_media?: any;
  identity_media?: any;

  company_registration_number_media_uid?: string;
  company_deed_media_uid?: string;
  tax_payer_identification_number_media_uid?: string;
  identity_media_uid?: string;
}

interface FormCorporateVerify {
  uid?: string;
  checking_account_media_uid?: string;
  master_bank_uuid?: string;
  account_number?: string;
  account_holder_name?: string;
  checking_account_media?: any;
  status?: string;
}

interface BusinessAccountContextValue {
  masterBanks: MasterBankModel[];
  formCorporateVerify: FormCorporateVerify;
  setFormCorporateVerify: Dispatch<SetStateAction<FormCorporateVerify>>;
  masterBank?: MasterBankModel;
  corporateVerify?: CorporateModel;
  formCorporate: FormCorporate;
  setFormCorporate: Dispatch<SetStateAction<FormCorporate>>;
  corporate?: CorporateModel;
  handleShowCorporate: (_data: BusinessAccountParams) => void;
  handleUpdateCorporate: () => void;
  handleShowCorporateVerification: () => void;
  handleFetchMasterBank: () => void;
  handleEditCorporateVerify: () => void;
  handleEditDocument: () => void;

  handleDialogEditInfoBusinessModal: ModalObject;
  handleEditMasterBankModal: ModalObject;
  handleEditDocumentModal: ModalObject;
  handleEditAddressModal: ModalObject;

  provinces: RegionProvinceModel[];
  cities: RegionDistrictModel[];
  districts: RegionDistrictModel[];
  villages: RegionVillageModel[];
  handleFetchProvince: () => void;
  handleFetchCities: () => void;
  handleFetchDistricts: () => void;
  handleFetchVillage: () => void;
  handleUpdateAddress: () => void;

  provinceID: number;
  setProvinceID: Dispatch<SetStateAction<number>>;
  cityID: number;
  setCityID: Dispatch<SetStateAction<number>>;
  districtID: number;
  setDistrictID: Dispatch<SetStateAction<number>>;
  villageID: number;
  setVillageID: Dispatch<SetStateAction<number>>;

  fullAddress: string;
  setFullAddress: Dispatch<SetStateAction<string>>;
  zipCode: string;
  setZipCode: Dispatch<SetStateAction<string>>;
}

const StockGoldContext = createContext<BusinessAccountContextValue | null>(null);

const businessAccountRep = new CorporateRepository();
const corporateVerificationRep = new CorporateVerifyRepository();
const masterBankRep = new MasterBankRepository();
const regionRepository = new RegionRepository();

const BusinessAccountProvider: React.FC<BusinessAccountContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const [masterBank, setMasterBank] = useState<MasterBankModel>();
  const [corporate, setCorporate] = useState<CorporateModel>();
  const [corporateVerify, setCorporateVerify] = useState<CorporateModel>();
  const [masterBanks, setMasterBanks] = useState<MasterBankModel[]>([]);

  const [formCorporate, setFormCorporate] = useState<FormCorporate>({});
  const [formCorporateVerify, setFormCorporateVerify] = useState<FormCorporateVerify>({});

  const [provinces, setProvinces] = useState<RegionProvinceModel[]>([]);
  const [cities, setCities] = useState<RegionCityModel[]>([]);
  const [districts, setDistricts] = useState<RegionDistrictModel[]>([]);
  const [villages, setVillages] = useState<RegionVillageModel[]>([]);

  const [provinceID, setProvinceID] = useState<number>(0);
  const [cityID, setCityID] = useState<number>(0);
  const [districtID, setDistrictID] = useState<number>(0);
  const [villageID, setVillageID] = useState<number>(0);
  const [fullAddress, setFullAddress] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');

  const handleDialogEditInfoBusinessModal = useVisibleComponent(false);
  const handleEditMasterBankModal = useVisibleComponent(false);
  const handleEditDocumentModal = useVisibleComponent(false);
  const handleEditAddressModal = useVisibleComponent(false);

  //HANDLE SHOW CORPORATE
  const handleShowCorporate = (_data: BusinessAccountParams) => {
    const x = alertToast.loadingAlert('Showing corporate');
    const param = {
      uid: _data.uid
    };
    businessAccountRep
      .showCorporate(param)
      .then((result: any) => {
        const res: CorporateModel = result;
        setCorporate(result);

        setFormCorporate({
          uid: res.UID,
          email_corporate: res.emailCorporate,
          phone_corporate: res.phoneCorporate,
          name: res.name,
          website: res.website,
          status: res.status
        });

        setProvinceID(res.provinceID);
        setCityID(res.cityID);
        setDistrictID(res.districtID);
        setVillageID(res.villageID);
        setFullAddress(res.fullAddress);
        setZipCode(res.zipCode);

        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW CORPORATE VERIFICATION
  const handleShowCorporateVerification = () => {
    const x = alertToast.loadingAlert('Fetching Bank Corporate');

    const params = {
      corporate_uid: formCorporate.uid
    };

    corporateVerificationRep
      .showCorporateVerify(params)
      .then((result: any) => {
        const resCorporate: CorporateModel = result;
        setFormCorporateVerify({
          uid: resCorporate.UID,
          account_holder_name: resCorporate.accountHolderName,
          account_number: resCorporate.accountNumber,
          checking_account_media_uid: resCorporate.checkingAccountMediaUID,
          master_bank_uuid: resCorporate.masterBankUUID,
          status: resCorporate.status
        });

        //SHOW MASTER BANK
        masterBankRep
          .showMasterBank({ uid: resCorporate.masterBankUUID })
          .then((resultMasterBank: any) => {
            const resMasterBank: MasterBankModel = resultMasterBank;
            setCorporateVerify(result);
            setMasterBank(resMasterBank);
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE EDIT DOCUMENT
  const handleEditDocument = async () => {
    const x = alertToast.loadingAlert('');

    let identityMediaUID = '';
    if (formCorporate.identity_media) {
      identityMediaUID = await UploadMedia(formCorporate.identity_media, MEDIA_PATH.IDENTITY);
    } else {
      identityMediaUID = '';
    }

    let companyRegistrationNumberMediaUID = '';
    if (formCorporate.company_registration_number_media) {
      companyRegistrationNumberMediaUID = await UploadMedia(
        formCorporate.company_registration_number_media,
        MEDIA_PATH.COMPANY_REGISTRATION_NUMBER
      );
    } else {
      companyRegistrationNumberMediaUID = '';
    }

    let companyDeedMediaUID = '';
    if (formCorporate.company_deed_media) {
      companyDeedMediaUID = await UploadMedia(formCorporate.company_deed_media, MEDIA_PATH.COMPANY_DEED);
    } else {
      companyDeedMediaUID = '';
    }

    let taxPayerIdentificationNumberMediaUID = '';
    if (formCorporate.tax_payer_identification_number_media) {
      taxPayerIdentificationNumberMediaUID = await UploadMedia(
        formCorporate.tax_payer_identification_number_media,
        MEDIA_PATH.TAX_PAYER_IDENTIFICATION_NUMBER
      );
    } else {
      taxPayerIdentificationNumberMediaUID = '';
    }

    const params = {
      uid: formCorporate.uid,
      status: formCorporate.status,
      identity_media_uid: identityMediaUID,
      company_registration_number_media_uid: companyRegistrationNumberMediaUID,
      company_deed_media_uid: companyDeedMediaUID,
      tax_payer_identification_number_media_uid: taxPayerIdentificationNumberMediaUID
    };

    businessAccountRep
      .updateCorporate(params)
      .then(() => {
        //UPDATE CORPORATE VERIFY
        const paramsVerify = {
          uid: formCorporateVerify.uid,
          status: formCorporateVerify.status,
          identity_media_uid: identityMediaUID,
          company_registration_number_media_uid: companyRegistrationNumberMediaUID,
          company_deed_media_uid: companyDeedMediaUID,
          tax_payer_identification_number_media_uid: taxPayerIdentificationNumberMediaUID
        };

        corporateVerificationRep
          .updateCorporateVerify(paramsVerify)
          .then(() => {
            handleEditDocumentModal.setState(false);
            handleShowCorporate({ uid: formCorporate.uid });
            alertToast.successAlert();
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE CORPORATE VERIFY
  const handleEditCorporateVerify = async () => {
    const x = alertToast.loadingAlert('');

    let checkingAccountUUID = '';
    if (formCorporateVerify.checking_account_media) {
      checkingAccountUUID = await UploadMedia(formCorporateVerify.checking_account_media, MEDIA_PATH.CHECKING_ACCOUNT);
    } else {
      checkingAccountUUID = '';
    }

    const params = {
      uid: formCorporateVerify.uid,
      checking_account_media_uid: checkingAccountUUID,
      account_holder_name: formCorporateVerify.account_holder_name,
      account_number: formCorporateVerify.account_number,
      master_bank_uuid: formCorporateVerify.master_bank_uuid,
      status: formCorporateVerify.status
    };

    //update corporate to update media account checking
    corporateVerificationRep
      .updateCorporateVerify(params)
      .then(() => {
        const paramsCorporate = {
          uid: formCorporate.uid,
          status: formCorporate.status,
          checking_account_media_uid: checkingAccountUUID
        };

        businessAccountRep
          .updateCorporate(paramsCorporate)
          .then(() => {
            handleShowCorporateVerification();
            handleEditMasterBankModal.setState(false);
            alertToast.successAlert();
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW INFO BUSINESS
  const handleUpdateCorporate = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      uid: formCorporate.uid,
      email_corporate: formCorporate.phone_corporate,
      phone_corporate: formCorporate.phone_corporate,
      name: formCorporate.name,
      website: formCorporate.website,
      status: formCorporate.status
    };

    businessAccountRep
      .updateCorporate(params)
      .then(() => {
        handleDialogEditInfoBusinessModal.setState(false);

        handleShowCorporate({ uid: formCorporate.uid });
        alertToast.successAlert();
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE ADDRESS
  const handleUpdateAddress = () => {
    const x = alertToast.loadingAlert('');

    const params = {
      uid: formCorporate.uid,
      status: formCorporate.status,
      province_id: provinceID,
      city_id: cityID,
      district_id: districtID,
      village_id: villageID,
      full_address: fullAddress,
      zipCode: zipCode
    };

    businessAccountRep
      .updateCorporate(params)
      .then(() => {
        handleEditAddressModal.setState(false);

        handleShowCorporate({ uid: formCorporate.uid });
        alertToast.successAlert();
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  // HANDLE FETCH MASTER BANK
  const handleFetchMasterBank = () => {
    masterBankRep
      .fetchMasterBank({ page: 1, limit: -1 })
      .then((result: any) => {
        setMasterBanks(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  // ------ LOCATION ADDRESS BELOW ------ //

  //HANDLE FETCH PROVINCE
  const handleFetchProvince = () => {
    regionRepository
      .fetchProvince()
      .then((result: any) => {
        setProvinces(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH CITIES
  const handleFetchCities = () => {
    const params = {
      parent_id: provinceID
    };
    regionRepository
      .fetchCities(params)
      .then((result: any) => {
        setCities(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH DISTRICT
  const handleFetchDistricts = () => {
    const params = {
      parent_id: cityID
    };
    regionRepository
      .fetchDistrict(params)
      .then((result: any) => {
        setDistricts(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH VILLAGE
  const handleFetchVillage = () => {
    const params = {
      parent_id: districtID
    };

    regionRepository
      .fetchVillage(params)
      .then((result: any) => {
        setVillages(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const contextValue: BusinessAccountContextValue = {
    masterBanks,
    formCorporateVerify,
    setFormCorporateVerify,
    masterBank,
    corporateVerify,
    formCorporate,
    setFormCorporate,
    corporate,
    handleShowCorporate,
    handleDialogEditInfoBusinessModal,
    handleUpdateCorporate,
    handleShowCorporateVerification,
    handleFetchMasterBank,
    handleEditCorporateVerify,
    handleEditDocument,
    handleEditDocumentModal,
    handleEditMasterBankModal,
    handleEditAddressModal,
    handleUpdateAddress,

    provinces,
    cities,
    districts,
    villages,
    handleFetchProvince,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    villageID,
    setVillageID,

    fullAddress,
    setFullAddress,
    zipCode,
    setZipCode
  };

  return <StockGoldContext.Provider value={contextValue}>{children}</StockGoldContext.Provider>;
};

const useBusinessAccountContext = (): BusinessAccountContextValue => {
  const context = useContext(StockGoldContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a BusinessAccountProvider');
  }
  return context;
};

export { BusinessAccountProvider, useBusinessAccountContext };
