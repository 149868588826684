import { BaseModel } from '@/domain/model/base_model.ts';
import { RoleModel } from '@/domain/model/role_model.ts';

export class RoleUserCompanyModel extends BaseModel {
  userUUID: string;
  userCompanyUUID: string;
  roleUUID: string;
  role: RoleModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    userUUID: string,
    userCompanyUUID: string,
    roleUUID: string,
    role: RoleModel
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.flags = flags;
    this.userUUID = userUUID;
    this.userCompanyUUID = userCompanyUUID;
    this.roleUUID = roleUUID;
    this.role = role;
  }

  public static fromJson(data: any): any | RoleUserCompanyModel {
    try {
      return new RoleUserCompanyModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.user_uuid,
        data.user_company_uuid,
        data.role_uuid,
        data.role ? RoleModel.fromJson(data.role) : null
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): RoleUserCompanyModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(RoleUserCompanyModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
