import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useVerifyEMoneyContext } from '@/delivery/ui/admin/data_member/verify_e_money/context/verify_e_money_context.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { useEffect, useState } from 'react';
import { STATUS_CORPORATE_LIST } from '@/domain/constant/status_corporate.ts';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import MEMBER_E_MONEY_TYPE_LIST from '@/domain/constant/member_e_money/member_e_money_type.ts';

const FilterMemberVerifyEMoney = () => {
  const {
    masterBanks,
    handleFetchMasterBank,
    handleFetchMemberRequestVerifyEMoney,
    formFilterMemberVerifyEMoney,
    setFormFilterMemberVerifyEMoney
  } = useVerifyEMoneyContext();

  useEffect(() => {
    handleFetchMasterBank();
  }, []);

  const [isResetting, setIsResetting] = useState(false);

  const handleReset = () => {
    setFormFilterMemberVerifyEMoney({
      search: '',
      master_bank_uuid: '',
      account_number: '',
      account_holder_name: '',
      type: '',
      status: ''
    });
    setIsResetting(true);
  };

  useEffect(() => {
    if (isResetting) {
      handleFetchMemberRequestVerifyEMoney({ page: 1, limit: 10 });
      setIsResetting(false);
    }
  }, [setFormFilterMemberVerifyEMoney, isResetting]);

  return (
    <div className="flex gap-[12px]">
      <Button type={'secondary'} prefixIcon={Icon.reset} label={'Reset Filter'} onClick={handleReset} />
      <ButtonFilter
        onClickApply={() => handleFetchMemberRequestVerifyEMoney({})}
        size={'large'}
        titleFilter={'Filter Request Verify E-money'}
        label={'More Filter'}
      >
        <div className={'flex flex-col gap-[20px]'}>
          <TextField
            value={formFilterMemberVerifyEMoney.search}
            search
            placeholder={'Search...'}
            block
            onChange={e => setFormFilterMemberVerifyEMoney({ ...formFilterMemberVerifyEMoney, search: e.target.value })}
          />
          <div className={'flex gap-[20px]'}>
            <Select2
              value={formFilterMemberVerifyEMoney.master_bank_uuid}
              block
              options={masterBanks.map(item => {
                return {
                  label: item.name,
                  value: item.UID
                };
              })}
              label={'Bank Name'}
              onChange={e =>
                setFormFilterMemberVerifyEMoney({ ...formFilterMemberVerifyEMoney, master_bank_uuid: e.value })
              }
            />
            <TextField
              value={formFilterMemberVerifyEMoney.account_holder_name}
              block
              label={'Bank Acc Holder'}
              onChange={e =>
                setFormFilterMemberVerifyEMoney({
                  ...formFilterMemberVerifyEMoney,
                  account_holder_name: e.target.value
                })
              }
            />
            <TextField
              value={formFilterMemberVerifyEMoney.account_number}
              type={'number'}
              block
              label={'Bank Acc Number'}
              onChange={e =>
                setFormFilterMemberVerifyEMoney({ ...formFilterMemberVerifyEMoney, account_number: e.target.value })
              }
            />
          </div>
          <div className={'flex gap-[20px]'}>
            <Select2
              options={MEMBER_E_MONEY_TYPE_LIST.map(item => {
                return {
                  label: item.name,
                  value: item.UUID
                };
              })}
              value={formFilterMemberVerifyEMoney.type}
              block
              label={'Type'}
              onChange={e => setFormFilterMemberVerifyEMoney({ ...formFilterMemberVerifyEMoney, type: e.value })}
            />

            <Select2
              value={formFilterMemberVerifyEMoney.status}
              options={STATUS_CORPORATE_LIST.map(item => {
                return {
                  label: item.name,
                  value: item.UUID
                };
              })}
              block
              label={'Status'}
              onChange={e => setFormFilterMemberVerifyEMoney({ ...formFilterMemberVerifyEMoney, status: e.value })}
            />
          </div>
        </div>
      </ButtonFilter>
    </div>
  );
};

export default FilterMemberVerifyEMoney;
