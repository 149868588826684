import { useEffect } from 'react';
import MediaPage from '@/delivery/ui/admin/web_admin/media';
import { MediaProvider } from '@/delivery/ui/admin/web_admin/media/context/media_context';

const ViewMedia = () => {
  useEffect(() => {
    document.title = 'Media';
  }, []);

  return (
    <div>
      <MediaProvider>
        <MediaPage />
      </MediaProvider>
    </div>
  );
};

export default ViewMedia;
