import DetailMember from '@/delivery/ui/admin/data_member/member_list/detail/detail_member.tsx';
import { MemberListProvider } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';
import { UserHeirProvider } from '@/delivery/ui/admin/data_member/member_list/context/user_heir_context.tsx';
import { BusinessAccountProvider } from '@/delivery/ui/admin/data_member/member_list/context/business_account_context.tsx';
import { ReferralProvider } from '@/delivery/ui/admin/data_member/member_list/context/referral_context.tsx';
import { BalanceProvider } from '@/delivery/ui/admin/data_member/member_list/context/balance_context.tsx';

const DetailMemberPage = () => {
  return (
    <div>
      <BalanceProvider>
        <ReferralProvider>
          <BusinessAccountProvider>
            <UserHeirProvider>
              <MemberListProvider>
                <DetailMember />
              </MemberListProvider>
            </UserHeirProvider>
          </BusinessAccountProvider>
        </ReferralProvider>
      </BalanceProvider>
    </div>
  );
};

export default DetailMemberPage;
