import { useEffect } from 'react';
import WebPopUpPage from '@/delivery/ui/admin/web_admin/web_pop_up';

const ViewWebPopUp = () => {
  useEffect(() => {
    document.title = 'Web Pop Up';
  }, []);

  return (
    <div>
      <WebPopUpPage />
    </div>
  );
};

export default ViewWebPopUp;
