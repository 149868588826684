import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import Radio from '@/delivery/components/atoms/radio/radio.tsx';
import RadioGroup from '@/delivery/components/molecules/radio_group/radio_group.tsx';

const TabMemberProfile = () => {
  return (
    <div className="flex flex-col gap-[24px] px-[10px]">
      <div className="grid grid-cols-3 gap-[24px] items-center">
        {/* name */}
        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Dinaran Number
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField block placeholder="Dinaran Number" disabled={true} value={123456} />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Nama
          </Typography>
        </div>
        <div>
          <TextField block placeholder="Nama Depan" />
        </div>
        <div>
          <TextField block placeholder="Nama Belakang" />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Email
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField block placeholder="Email" />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Phone
          </Typography>
        </div>
        <div className="col-span-2">
          <TextField block placeholder="Phone" />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Email Verified
          </Typography>
        </div>
        <div className="col-span-2">
          <Chips type={'primary-light'} label={'Verified'} />
        </div>

        <div>
          <Typography size={14} fontWeight={500} type="tertiary-invert">
            Account Type
          </Typography>
        </div>
        <div className="col-span-2">
          <RadioGroup radioCount={'2'} direction={'horizontal'}>
            <Radio name="account_type" label="Individual" />
            <Radio name="account_type" label="Corporate" />
          </RadioGroup>
        </div>
      </div>
    </div>
  );
};

export default TabMemberProfile;
