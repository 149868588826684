import clsx from 'clsx';
import styles from './Table.module.css';
import { ReactNode } from 'react';

interface TableProps {
  children?: ReactNode;
  noBorder?: boolean;
  className?: string;
  colSpan?: number;
  rounded?: boolean;
  key?: number | string;
  ref?: any;
}

const Table = (props: TableProps) => {
  const { ref, children, noBorder, className, rounded } = props;
  return (
    <div className={`overflow-x-auto ${rounded ? 'border-[0.5px] border-border-secondary rounded-md' : ''}`}>
      <table ref={ref} className={clsx(`${noBorder ? styles['table-no-border'] : styles['table']}`, className)}>
        {children}
      </table>
    </div>
  );
};

const Thead = (props: TableProps) => {
  return <thead>{props.children}</thead>;
};

const Tr = (props: TableProps) => {
  return <tr>{props.children}</tr>;
};

const Th = (props: TableProps) => {
  return (
    <th colSpan={props.colSpan} className={props.noBorder ? styles['th-no-border'] : styles['th']}>
      {props.children}
    </th>
  );
};

const Td = (props: TableProps) => {
  return (
    <td
      width={50}
      colSpan={props.colSpan}
      className={props.noBorder ? `${styles['td-no-border']} ${props.className}` : `${styles['td']} ${props.className}`}
    >
      {props.children}
    </td>
  );
};

const Tbody = (props: TableProps) => {
  return <tbody className="bg-content-bg-white">{props.children}</tbody>;
};

const Theader = (props: TableProps) => {
  return (
    <div
      className={clsx(
        'rounded-t-[8px] border-[1px] border-b-0 border-border-secondary py-[16px] px-[24px] flex lg:flex-row flex-col gap-[10px] justify-between items-start lg:items-center bg-content-bg-white',
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

const TBox = (props: TableProps) => {
  return (
    <div
      className={clsx(
        'rounded-b-[8px] border-[1px] border-border-secondary py-[24px] px-[24px] bg-content-bg-white',
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

const Tfooter = (props: TableProps) => {
  return (
    <div
      className={clsx(
        'overflow-x-auto rounded-b-[8px] border-[1px] border-t-0 border-border-secondary py-[16px] px-[24px] flex justify-between items-center bg-content-bg-white',
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

export { Table, Thead, Tbody, Tr, Th, Td, Theader, Tfooter, TBox };
