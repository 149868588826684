import { useEffect } from 'react';
import AffiliateListPage from '@/delivery/ui/admin/reseller_affiliate/affiliate_list';
import { AffiliateListProvider } from '@/delivery/ui/admin/reseller_affiliate/affiliate_list/context/affiliate_list_context';

const ViewAffiliateList = () => {
  useEffect(() => {
    document.title = 'Affiliate List';
  }, []);

  return (
    <div>
      <AffiliateListProvider>
        <AffiliateListPage />
      </AffiliateListProvider>
    </div>
  );
};

export default ViewAffiliateList;
