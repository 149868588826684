import { BaseModel } from '@/domain/model/base_model.ts';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';

export class TierModel extends BaseModel {
  name: string;
  gramMinimal: number;
  maximumSell: number;
  maximumSellDaily: number;
  maximumTransfer: number;
  maximumTransferDaily: number;
  description: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    name: string,
    gramMinimal: number,
    maximumSell: number,
    maximumSellDaily: number,
    maximumTransfer: number,
    maximumTransferDaily: number,
    description: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.name = name;
    this.gramMinimal = gramMinimal;
    this.maximumSell = maximumSell;
    this.maximumSellDaily = maximumSellDaily;
    this.maximumTransfer = maximumTransfer;
    this.maximumTransferDaily = maximumTransferDaily;
    this.description = description;
  }

  get getMaximumSell(): string {
    if (this.maximumSell) {
      return formatRupiah(this.maximumSell);
    }

    return '-';
  }

  get getMaximumSellDaily(): string {
    if (this.maximumSellDaily) {
      return formatRupiah(this.maximumSellDaily);
    }

    return '-';
  }

  get getMaximumTransfer(): string {
    if (this.maximumTransfer) {
      return formatRupiah(this.maximumTransfer);
    }

    return '-';
  }

  get getMaximumTransferDaily(): string {
    if (this.maximumTransferDaily) {
      return formatRupiah(this.maximumTransferDaily);
    }

    return '-';
  }

  public static fromJson(data: any): TierModel | any {
    try {
      return new TierModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.name,
        data.gram_minimal,
        data.maximum_sell,
        data.maximum_sell_daily,
        data.maximum_transfer,
        data.maximum_transfer_daily,
        data.description
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): TierModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(TierModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
