import DChart from '@/delivery/components/atoms/chart/chart';

const ChartMobileWebappWebsite = () => {
  const state: any = {
    series: [
      {
        name: 'Website',
        data: [44, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22, 43]
      },
      {
        name: 'Web App',
        data: [13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27]
      },
      {
        name: 'Mobile App',
        data: [15, 13, 30, 7, 33, 57, 23, 13, 40, 9, 33, 37]
      }
    ],
    options: {
      colors: ['#6B7280', '#374151', '#111827'],
      dataLabels: {
        enabled: false
      },
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          columnWidth: '50%',
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            enabled: false,
            total: {
              enabled: false,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        }
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + '%';
          }
        }
      },
      legend: {
        position: 'top'
      },
      fill: {
        opacity: 1
      }
    }
  };

  return (
    <div>
      <DChart options={state.options} series={state.series} type="bar" height={350} />
    </div>
  );
};

export default ChartMobileWebappWebsite;
