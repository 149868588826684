// import { AiOutlinePlus } from 'react-icons/ai';
import Navbar from '@/delivery/components/organisms/navbar/navbar';
import AffiliateList from './affiliate_list';
// import Button from '@/delivery/components/atoms/button/button';
import DialogAffiliateList from './dialog/dialog_affiliate_list';

import { useAffiliateListContext } from './context/affiliate_list_context';

const AffiliateListPage = () => {
  const { handleCreateMemberAffiliateModal } = useAffiliateListContext();
  return (
    <div>
      <Navbar label={'Affiliate List'}>
        {/*<Button*/}
        {/*  prefixIcon={<AiOutlinePlus />}*/}
        {/*  label="Add Member Affiliate"*/}
        {/*  onClick={() => handleCreateMemberAffiliateModal.setState(true)}*/}
        {/*/>*/}
      </Navbar>
      <AffiliateList />

      {handleCreateMemberAffiliateModal.state && <DialogAffiliateList modal={handleCreateMemberAffiliateModal} />}
    </div>
  );
};

export default AffiliateListPage;
