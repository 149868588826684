import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { useAffiliateContext } from '@/delivery/ui/admin/master/member_affiliate/context/member_affiliate_context.tsx';
import COMMISSION_TYPE_LIST, { COMMISSION_TYPE } from '@/domain/constant/affiliate/type_affiliate.ts';

const DialogAddMemberAffiliate = (props: ModalDialogProps) => {
  const { handleAddAffiliateModal, handleAddAffiliate, formAffiliate, setFormAffiliate } = useAffiliateContext();

  return (
    <ModalDialog size="small" title="Add Affiliate" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField
          label="Tipe Affiliate"
          placeholder="Tipe Affiliate"
          block
          onChange={e => setFormAffiliate({ ...formAffiliate, type: e.target.value })}
        />
        <Select2
          isSearchable={false}
          value={formAffiliate.commission_type}
          options={COMMISSION_TYPE_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          label="Tipe Komisi"
          onChange={e => setFormAffiliate({ ...formAffiliate, commission_type: e.value })}
        />
        {formAffiliate.commission_type === COMMISSION_TYPE.PERCENT ? (
          <TextField
            type={'number'}
            label="Komisi (%)"
            placeholder="Komisi"
            block
            onChange={e => setFormAffiliate({ ...formAffiliate, commission: parseInt(e.target.value) })}
          />
        ) : (
          ''
        )}

        {formAffiliate.commission_type === COMMISSION_TYPE.FLAT ? (
          <TextField
            type={'currency'}
            label="Komisi (Rp)"
            placeholder="Komisi"
            block
            onChange={(e: any) => setFormAffiliate({ ...formAffiliate, commission: parseInt(e.value) })}
          />
        ) : (
          ''
        )}
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleAddAffiliateModal.setState(false)} />
        <Button block label="Save" onClick={() => handleAddAffiliate()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddMemberAffiliate;
