import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Chips from '@/delivery/components/atoms/chips/chips';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useHistoryContext } from '@/delivery/ui/admin/transaction/take_gold/history/context/history_context.tsx';
import { useEffect } from 'react';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';
import FilterHistory from '@/delivery/ui/admin/transaction/take_gold/history/filter_history.tsx';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';

const HistoryTakeGold = () => {
  const navigate = useNavigate();
  const { handleFetchTransaction, histories, historyPagination } = useHistoryContext();

  useEffect(() => {
    handleFetchTransaction({});
  }, []);

  return (
    <div>
      <div>
        <div className={'flex justify-end mb-[20px]'}>
          <FilterHistory />
        </div>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Trx Date</Th>
              <Th>Member</Th>
              <Th>Keping Cetak</Th>
              <Th>
                <span className={'text-nowrap'}>Total Gr</span>
              </Th>
              <Th>Amount</Th>
              <Th>
                <span className={'text-nowrap'}>Biaya</span>
              </Th>
              <Th>Pengambilan</Th>
              <Th>Reversal</Th>
              <Th>Status</Th>
              <Th>Gold Rate</Th>
              <Th>Updated By</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {histories.map((item, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.getTransactionAt}
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {item.userEmail?.name}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.userEmail?.email}
                  </Typography>
                </Td>
                <Td>
                  <div className={'text-nowrap'}>
                    {/*<Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
                    {/*  {formatPrintGold(item.trxPrint)}*/}
                    {/*</Typography>*/}
                    {item.goldPieces.map(item => (
                      <Typography tag="p" fontWeight={400} size={12} type="tertiary-invert">
                        {item.gram} gram: {item.quantity} keping
                      </Typography>
                    ))}
                  </div>
                </Td>
                <Td>
                  <Typography tag="p" fontWeight={600} size={12} type="tertiary-invert">
                    {item.amount?.gram} gr
                  </Typography>
                  <Typography tag="p" fontWeight={400} size={12} type="tertiary-invert">
                    {item.rate.getSell}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getAmountRupiah}
                  </Typography>
                </Td>

                <Td>
                  <div className={'whitespace-nowrap'}>
                    <Typography tag="p" fontWeight={400} size={12} type="tertiary-invert">
                      Fee : {item.getPrintFeeRupiah}
                    </Typography>

                    <Typography tag="p" fontWeight={400} size={12} type="tertiary-invert">
                      Ongkir : {item.getPrintDeliveryRupiah}
                    </Typography>
                  </div>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.printLocation?.location}
                  </Typography>
                </Td>
                <Td>
                  {/*<Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
                  {/*  {item.printLocation?.type}*/}
                  {/*</Typography>*/}
                  {item.flags?.includes(TYPE_TRANSACTION.REVERSAL) ? (
                    <Chips type={'danger-light'} label={item.flags ? item.flags?.join(' ') : ''} removeDot />
                  ) : (
                    '-'
                  )}
                </Td>
                <Td>
                  <div className={'text-nowrap'}>
                    <Chips label={item.status} type={item.getStatusColor} />
                  </div>
                </Td>
                <Td>
                  <Typography tag="p" fontWeight={600} size={12} type="tertiary-invert">
                    {item.rate.getBuy}
                  </Typography>
                  <Typography tag="p" fontWeight={400} size={12} type="tertiary-invert">
                    {item.rate.getSell}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.userUpdate?.name}
                  </Typography>
                </Td>
                <Td>
                  <div className="flex gap-[4px]">
                    <Button
                      iconButton
                      iconType={'view'}
                      onClick={() => navigate(Routing.TRANSACTION.AMBIL_EMAS.HISTORY_DETAIL + item.UID)}
                    />
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            page={historyPagination?.page || 1}
            totalPages={historyPagination?.totalPages || 1}
            handlePagination={page => {
              handleFetchTransaction({
                page: page
              });
            }}
          />
        </Tfooter>
      </div>
    </div>
  );
};

export default HistoryTakeGold;
