import useAlertToast from '@/delivery/hooks/useAlertComponent';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import { TransactionRepository } from '@/domain/repository/transaction_repository.ts';
import { ProfileModel } from '@/domain/model/profile_model.ts';
import { ModalObject } from '@/delivery/interface/modal_interface.ts';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent.tsx';
import { GoldRateRepository } from '@/domain/repository/gold_rate_repository.ts';
import { GoldRateLatestModel } from '@/domain/model/gold_rate_lates_model.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import { PaymentChannelRepository } from '@/domain/repository/payment_channel_repository.ts';
import { PaymentChannelModel } from '@/domain/model/payment/payment_channel_model.ts';
// import { STATUS_CONST } from '@/domain/constant/status.ts';
import { GoldRateModel } from '@/domain/model/gold_rate_model.ts';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';
import STATUS_CONSTANT from '@/domain/constant/status_constanat.ts';
import { QueryTransactionVendorRepository } from '@/domain/repository/query_transaction_vendor_repository.ts';
import { VendorRepository } from '@/domain/repository/vendor_repository.ts';
import { VendorModel } from '@/domain/model/vendor_model.ts';
import { QueryTransactionVendorModel } from '@/domain/model/query_transaction_vendor_model.ts';
import { UserRepository } from '@/domain/repository/user_repository.ts';
// import { MEMBER_TYPE } from '@/domain/constant/member/member_type.ts';
// import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
// import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface FormManualTransactionInterface {
  amount_balance?: number;
  userUID?: string;
  rateUID?: string;
  referenceID?: string;
  type?: string;
  status?: string;
  name?: string;
  amount?: number;
  gram?: number;
  notes?: string;
  flow?: string;
  relatedUID?: string;
  transactionAt?: string;
  settledAt?: string;
  succeedAt?: string;
  reconAt?: string;
  email?: string;
  phone?: string;
  fee?: number;
  payment_channel_uid?: string;
  rate_date?: string;
}

interface ManualTransactionParams {
  search?: string;
  reference_id?: string;
  vendor_uid?: string;
  type?: string;
}

interface ManualTransactionValue {
  transactionVendor?: QueryTransactionVendorModel;
  vendors: VendorModel[];
  goldRates: GoldRateModel[];
  paymentChannels: PaymentChannelModel[];
  goldRateLatest?: GoldRateLatestModel;
  loading: boolean;
  users: ProfileModel[];
  handleShowUserListModal: ModalObject;
  setUsers: Dispatch<SetStateAction<ProfileModel[]>>;
  formManualTransaction: FormManualTransactionInterface;
  setFormManualTransaction: Dispatch<SetStateAction<FormManualTransactionInterface>>;
  manualTransaction: TransactionModel | undefined;
  handleShowTransaction: (_data: TransactionModel) => void;
  handleFetchUser: (_data: ManualTransactionParams) => void;
  handleSelectUser: (_data: ProfileModel) => void;
  handleFetchGoldRateLatest: () => void;
  handleSubmitManualTransaction: () => void;
  handleFetchPaymentChannel: () => void;
  handleGetRateByDate: () => void;
  handleShowQueryTransactionVendorBuy: (_data: ManualTransactionParams) => void;
  handleFetchVendor: (_data: ManualTransactionParams) => void;
}

const ManualTransactionContext = createContext<ManualTransactionValue | null>(null);

const manualTransactionRepository = new TransactionRepository();
const profileRepository = new UserRepository();
const goldRateRepository = new GoldRateRepository();
const paymentChannelRepository = new PaymentChannelRepository();
const queryTransactionVendorRepository = new QueryTransactionVendorRepository();
const vendorRepository = new VendorRepository();

const ManualTransactionProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [manualTransaction, setManualTransaction] = useState<TransactionModel>();
  const [users, setUsers] = useState<ProfileModel[]>([]);
  const [paymentChannels, setPaymentChannels] = useState<PaymentChannelModel[]>([]);
  const [vendors, setVendors] = useState<VendorModel[]>([]);

  const [transactionVendor, setTransactionVendor] = useState<QueryTransactionVendorModel>();

  const [formManualTransaction, setFormManualTransaction] = useState<FormManualTransactionInterface>({});
  const [goldRates, setGoldRates] = useState<GoldRateModel[]>([]);
  const [goldRateLatest, setGoldRateLatest] = useState<GoldRateLatestModel>();
  const handleShowUserListModal = useVisibleComponent(false);

  const [loading, setLoading] = useState<boolean>(false);

  const handleShowTransaction = (_data: TransactionModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };

    manualTransactionRepository
      .showTransaction(params)
      .then((result: any) => {
        setManualTransaction(result);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //FETCH PAYMENT CHANNEL
  const handleFetchPaymentChannel = () => {
    const params = {
      page: 1,
      limit: -1,
      status: STATUS_CONSTANT.active,
      with_manual: true
    };

    paymentChannelRepository
      .fetchPaymentChannel(params)
      .then((result: any) => {
        setPaymentChannels(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //FETCH GOLD RATE
  const handleFetchGoldRateLatest = () => {
    goldRateRepository
      .latestGoldRate()
      .then((result: any) => {
        setGoldRateLatest(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //FETCH USER TRANSACTION SEARCH
  const handleFetchUser = (_data: ManualTransactionParams) => {
    setLoading(true);
    const params = {
      search: _data.search
      // type: MEMBER_TYPE.USER
    };
    profileRepository
      .fetchUser(params)
      .then((result: any) => {
        setLoading(false);
        setUsers(result.data);
      })
      .catch(error => {
        setLoading(false);
        alertToast.errorAlert(error);
      });
  };

  //SELECT USER
  const handleSelectUser = (_data: ProfileModel) => {
    setFormManualTransaction({
      ...formManualTransaction,
      userUID: _data.UUID,
      name: _data.firstName + ' ' + _data.lastName,
      email: _data.email,
      phone: _data.phone
      // amount_balance: _data.balance.amount
    });

    handleShowUserListModal.setState(false);
  };

  //SUBMIT TRANSACTION MANUAL
  const handleSubmitManualTransaction = () => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          amount: formManualTransaction.amount,
          fee: formManualTransaction.fee,
          gram: formManualTransaction.gram,
          notes: formManualTransaction.notes,
          payment_channel_uid: formManualTransaction.payment_channel_uid,
          rate_uid: goldRates.length !== 0 ? goldRates[0].UID : goldRateLatest?.currentRate.UID,
          // recon_at: '{{recon_at_time.Time}}',
          // reference_id: '{{reference_id_string}}',
          // related_uid: '{{related_uid_string}}',
          // settled_at: '{{settled_at_time.Time}}',
          status: formManualTransaction.status,
          succeed_at: formManualTransaction.status === STATUS_TRANSACTION.SUCCESS ? new Date() : null,
          type: formManualTransaction.type,
          user_uid: formManualTransaction.userUID
          // user_withdrawal_uid: '{{user_withdrawal_uid_string}}'
        };

        manualTransactionRepository
          .manualTransaction(params)
          .then(() => {
            alertToast.successAlert('Success');
            alertToast.updateLoading(x);
            setFormManualTransaction({
              amount: 0,
              fee: 0,
              gram: 0,
              notes: '',
              payment_channel_uid: '',
              status: '',
              succeedAt: '',
              type: '',
              userUID: ''
            });
          })
          .catch(error => {
            alertToast.errorAlert(error);
            alertToast.updateLoading(x);
          });
      }
    });
  };

  //HANDLE FETCH RATES BY DATE
  const handleGetRateByDate = () => {
    const x = alertToast.loadingAlert('Getting Rate');
    const params: any = {
      // status: STATUS_CONST.ACTIVE,
      date_range: formatDateParam(formManualTransaction.rate_date)
    };

    goldRateRepository
      .fetchGoldRateByDate(params)
      .then((result: any) => {
        const res: GoldRateModel[] = result.data;

        if (res.length === 0) {
          alertToast.warningAlert(
            'gold rate pada tanggal ini tidak tersedia, transaksi akan menggunakan rate terakhir'
          );
        }

        setGoldRates(result.data);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW QUERY TRANSACTION VENDOR
  const handleShowQueryTransactionVendorBuy = (_data: ManualTransactionParams) => {
    const x = alertToast.loadingAlert('Showing Buy transaction');

    const params = {
      reference_id: _data.reference_id,
      vendor_uid: _data.vendor_uid
    };

    queryTransactionVendorRepository
      .showQueryTransactionVendorBuy(params)
      .then((result: any) => {
        setTransactionVendor(result.data);
        alertToast.updateLoading(x);
      })
      .catch(() => {
        alertToast.updateLoading(x);
      });
  };

  //HANDLE FETCH VENDOR
  const handleFetchVendor = (_data: ManualTransactionParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: 1,
      limit: -1,
      type: _data.type
    };

    vendorRepository
      .fetchVendor(params)
      .then((result: any) => {
        setVendors(result.data);
        alertToast.updateLoading(x);
      })
      .catch(() => {
        alertToast.updateLoading(x);
      });
  };

  const contextValue: ManualTransactionValue = {
    transactionVendor,
    vendors,
    goldRates,
    goldRateLatest,
    loading,
    users,
    setUsers,
    manualTransaction,
    handleShowTransaction,
    formManualTransaction,
    setFormManualTransaction,
    handleFetchUser,
    handleShowUserListModal,
    handleSelectUser,
    handleFetchGoldRateLatest,
    handleSubmitManualTransaction,
    handleFetchPaymentChannel,
    paymentChannels,
    handleGetRateByDate,
    handleShowQueryTransactionVendorBuy,
    handleFetchVendor
  };

  return <ManualTransactionContext.Provider value={contextValue}>{children}</ManualTransactionContext.Provider>;
};

const useManualTransactionContext = (): ManualTransactionValue => {
  const context = useContext(ManualTransactionContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a ManualTransactionProvider');
  }
  return context;
};

export { ManualTransactionProvider, useManualTransactionContext };
