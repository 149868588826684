import { Routing } from '@/domain/constant/router_path.ts';
import { FaArrowLeft } from 'react-icons/fa6';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { TabContent, TabPane2, TabPaneList2 } from '@/delivery/components/atoms/tab_pane/tab_pane.tsx';
import VendingMachine from '@/delivery/ui/admin/transaction/take_gold/vending_machine/detail/vending_machine/vending_machine.tsx';
import TableVendingMachineRack from '@/delivery/ui/admin/transaction/take_gold/vending_machine/detail/vending_machine_rack/table_vending_machine_rack.tsx';
// import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card.tsx';

const DetailVendingMachine = () => {
  const navigate = useNavigate();
  const [tabPage, setTabPage] = useState(0);
  const data = [
    {
      label: 'Detail',
      index: 0
      // handleClick: () => {}
    },
    {
      label: 'Vending Machine Rack',
      index: 1
      // handleClick: () => {}
    }
  ];

  return (
    <div className={'mt-[24px] flex flex-col gap-[20px]'}>
      <div
        className={'flex gap-[10px] items-center text-text-primary cursor-pointer'}
        onClick={() => navigate(Routing.TRANSACTION.AMBIL_EMAS.VENDING_MACHINE)}
      >
        <FaArrowLeft />
        <Typography size={14} fontWeight={500}>
          Back to vending machine
        </Typography>
      </div>
      <div>
        <Typography size={24} fontWeight={600}>
          Detail Vending Machine
        </Typography>
      </div>
      {/*<div>*/}
      {/*  <div className="flex gap-[24px]">*/}
      {/*    <SimpleCard title={`Keping tersedia 10 gr`} bigTitle={'10'} />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className={'flex flex-col gap-[20px]'}>
        <TabPane2>
          {data?.map((item, index) => (
            <TabPaneList2 label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
          ))}
        </TabPane2>

        <TabContent index={0} tab={tabPage}>
          <VendingMachine />
        </TabContent>
        <TabContent index={1} tab={tabPage}>
          <TableVendingMachineRack />
        </TabContent>
      </div>
    </div>
  );
};

export default DetailVendingMachine;
