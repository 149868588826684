import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';
import DialogUpdateDataID from '@/delivery/ui/admin/data_member/member_list/detail/tabs/data_profile/dialog/dialog_update_data_id.tsx';

const TableKTP = () => {
  const { userVerification, handleEditDataIDModal, user, handleShowDataID } = useMemberListContext();
  return (
    <>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr noBorder>
            <Th noBorder>
              <Typography fontWeight={600} size={14} type="primary-invert">
                KTP
              </Typography>
            </Th>
            <Th noBorder>
              <div className={'justify-end flex'}>
                <span
                  className={'text-text-primary font-[600] text-[14px] cursor-pointer'}
                  onClick={() => {
                    if (user) handleShowDataID(user);
                  }}
                >
                  Edit
                </span>
              </div>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {/*NIK*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                NIK
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {userVerification?.identityNumber}
              </Typography>
            </Td>
          </Tr>

          {/*tempat lahir*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Tempat Lahir
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {user?.birthPlace}
              </Typography>
            </Td>
          </Tr>

          {/*tanggal lahir*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Tanggal lahir
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {user?.getBirthDate}
              </Typography>
            </Td>
          </Tr>

          {/*agama*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Agama
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {user?.religion}
              </Typography>
            </Td>
          </Tr>

          {/*janis kelamin*/}
          <Tr>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="tertiary-invert">
                Jenis Kelamin
              </Typography>
            </Td>
            <Td noBorder>
              <Typography fontWeight={400} size={14} type="primary-invert">
                : {user?.gender}
              </Typography>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      {handleEditDataIDModal.state && <DialogUpdateDataID modal={handleEditDataIDModal} />}
    </>
  );
};

export default TableKTP;
