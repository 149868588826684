import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Chips from '@/delivery/components/atoms/chips/chips';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useEventReferralContext } from '@/delivery/ui/admin/reseller_affiliate/event_referral/context/event_referral_context.tsx';
import { useEffect } from 'react';
import { EventReferralModel } from '@/domain/model/event_referral_model.ts';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import DialogUpdateEventReferral from '@/delivery/ui/admin/reseller_affiliate/event_referral/dialog/dialog_update_event_referral.tsx';
import { STATUS_CONST } from '@/domain/constant/status.ts';
import FilterEventReferral from '@/delivery/ui/admin/reseller_affiliate/event_referral/filter_event_referral.tsx';

const EventReferral = () => {
  const {
    handleUpdateEventReferralModal,
    handleShowEventReferral,
    eventReferrals,
    eventReferralPagination,
    handleFetchEventReferral
  } = useEventReferralContext();

  useEffect(() => {
    handleFetchEventReferral({});
  }, []);

  return (
    <div>
      <div className="flex gap-[12px] justify-end mb-[12px]">
        <FilterEventReferral />
      </div>

      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Nama Event</Th>
            <Th>Jenis Referral</Th>
            <Th>Mulai Event</Th>
            <Th>Akhir Event</Th>
            <Th>Status</Th>
            <Th>Updated By </Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {eventReferrals.map((item: EventReferralModel, index) => (
            <Tr>
              <Td>
                <Typography fontWeight={500} size={12} type="primary-invert">
                  {item.name}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {item.type}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {item.getStartDate}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {item.getEndDate}
                </Typography>
              </Td>
              <Td>
                <Chips
                  label={item.status}
                  type={item.status === STATUS_CONST.ACTIVE ? 'primary-light' : 'danger-light'}
                />
              </Td>
              <Td>
                <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                  System
                </Typography>
              </Td>
              <Td>
                <div className="flex gap-[4px]">
                  <Button iconButton iconType="view" />
                  <Button iconButton iconType="edit" onClick={() => handleShowEventReferral(item)} />
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={eventReferralPagination?.page || 1}
          totalPages={eventReferralPagination?.totalPages || 0}
          handlePagination={page => {
            handleFetchEventReferral({
              page: page
            });
          }}
        />
      </Tfooter>

      {handleUpdateEventReferralModal.state && <DialogUpdateEventReferral modal={handleUpdateEventReferralModal} />}
    </div>
  );
};

export default EventReferral;
