import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { AFFILIATE_TYPE_LIST } from '@/domain/constant/affiliate/affiliate_type.ts';
import { useAffiliateListContext } from '@/delivery/ui/admin/reseller_affiliate/affiliate_list/context/affiliate_list_context.tsx';
import { useEffect } from 'react';

const DialogAffiliateList = (props: ModalDialogProps) => {
  const { handleCreateMemberAffiliateModal, handleStoreAffiliateList, formAffiliate, setFormAffiliate } =
    useAffiliateListContext();

  useEffect(() => {
    if (formAffiliate.type) {
      setFormAffiliate({});
    }
  }, [formAffiliate]);
  return (
    <ModalDialog size="small" title="Add Reseller / Affiliate" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Select2
          value={formAffiliate.type}
          options={AFFILIATE_TYPE_LIST.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          label="Tipe Affiliate"
          onChange={e => setFormAffiliate({ ...formAffiliate, type: e.value })}
        />
        <TextField
          type={'number'}
          label="Komisi (%)"
          placeholder="Komisi"
          block
          onChange={e => setFormAffiliate({ ...formAffiliate, commission: parseInt(e.target.value) })}
        />
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button
          block
          type="secondary"
          label="Reversal"
          onClick={() => handleCreateMemberAffiliateModal.setState(false)}
        />
        <Button block label="Add Reseller / Affiliate" onClick={() => handleStoreAffiliateList()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAffiliateList;
