import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useUserHeirContext } from '@/delivery/ui/admin/data_member/member_list/context/user_heir_context.tsx';
import { useParams } from 'react-router-dom';

const FormAddEditUserHeir = () => {
  const { uuid } = useParams();
  const { handleSubmitAddEditUserHeir, handleAddEditUserHeir, formUserHeir, setFormUserHeir } = useUserHeirContext();

  return (
    <div className={'flex flex-col gap-[20px]'}>
      <div className={'grid grid-cols-2 gap-[20px]'}>
        <div className={'flex flex-col gap-[20px]'}>
          <Typography size={14} fontWeight={600}>
            Ahli Waris 1 (satu)
          </Typography>
          <Line />
          <TextField
            label={'Nama Lengkap'}
            placeholder={'Nama Lengkap'}
            block
            value={formUserHeir.fullName1}
            onChange={e => setFormUserHeir({ ...formUserHeir, fullName1: e.target.value })}
          />
          <TextField
            label={'Phone'}
            type={'number'}
            placeholder={'Phone'}
            block
            value={formUserHeir.phone1}
            onChange={e => setFormUserHeir({ ...formUserHeir, phone1: e.target.value })}
          />
          <TextField
            label={'Hubungan'}
            placeholder={'contoh: saudara kandung, orang tua'}
            block
            value={formUserHeir.relation1}
            onChange={e => setFormUserHeir({ ...formUserHeir, relation1: e.target.value })}
          />
        </div>
        <div>
          <div className={'flex flex-col gap-[20px]'}>
            <Typography size={14} fontWeight={600}>
              Ahli Waris 2 (dua)
            </Typography>
            <Line />
            <TextField
              label={'Nama Lengkap'}
              placeholder={'Nama Lengkap'}
              block
              value={formUserHeir.fullName2}
              onChange={e => setFormUserHeir({ ...formUserHeir, fullName2: e.target.value })}
            />
            <TextField
              label={'Phone'}
              type={'number'}
              placeholder={'Phone'}
              block
              value={formUserHeir.phone2}
              onChange={e => setFormUserHeir({ ...formUserHeir, phone2: e.target.value })}
            />
            <TextField
              label={'Hubungan'}
              placeholder={'contoh: saudara kandung, orang tua'}
              block
              value={formUserHeir.relation2}
              onChange={e => setFormUserHeir({ ...formUserHeir, relation2: e.target.value })}
            />
          </div>
        </div>
      </div>
      <Line />
      <div className={'flex justify-end gap-[20px]'}>
        <Button type={'secondary'} label={'Cancel'} onClick={() => handleAddEditUserHeir({ user_uid: uuid })} />
        <Button label={'Simpan'} onClick={() => handleSubmitAddEditUserHeir({ user_uid: uuid })} />
      </div>
    </div>
  );
};

export default FormAddEditUserHeir;
