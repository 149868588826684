import { ReactNode } from 'react';
import styles from './Typography.module.css';
import clsx from 'clsx';

interface TypographyProps {
  className?: string;
  children?: ReactNode;
  size?: number;
  fontWeight?: number;
  type?: 'primary' | 'primary-invert' | 'secondary-invert' | 'tertiary-invert';
  tag?: 'p' | 'span';
  style?: 'italic' | 'bold';
}

const Typography = (props: TypographyProps) => {
  const { style = '', className, children, size, fontWeight, type, tag = 'p' } = props;

  const classProps = clsx(
    className,
    styles[`text-${size}`],
    styles[`font-weight-${fontWeight}`],
    styles[`${type}`],
    style
  );

  return (
    <>
      {tag === 'p' ? <p className={classProps}>{children}</p> : ''}
      {tag === 'span' ? <span className={classProps}>{children}</span> : ''}
    </>
  );
};

export default Typography;
