import Navbar from '@/delivery/components/organisms/navbar/navbar';
import TransactionLog from './transaction_log';

const TransactionLogPage = () => {
  return (
    <div>
      <Navbar label={'Transaction Log'}></Navbar>
      <TransactionLog />
    </div>
  );
};

export default TransactionLogPage;
