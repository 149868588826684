const formatNumberWithDot = (number: number): string => {
  if (Number.isInteger(number)) {
    // If the number is an integer, format without decimals
    return number.toLocaleString('de-DE', { minimumFractionDigits: 0 });
  } else {
    // If the number is a decimal, format with two decimal places
    const truncatedNumber = Math.floor(number * 100) / 100;
    return truncatedNumber.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
};

export default formatNumberWithDot;
