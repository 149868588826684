import { ApiClient } from '@/main/apiClient';
import { QueryTransactionVendor } from '@/domain/constant/api_list.ts';
import QueryTransactionVendorInterface, {
  QueryTransactionVendorValue
} from '@/domain/repository/interface/query_transaction_vendor_interface.ts';
import { QueryTransactionVendorModel } from '@/domain/model/query_transaction_vendor_model.ts';

export class QueryTransactionVendorRepository implements QueryTransactionVendorInterface {
  api = new ApiClient();

  async showQueryTransactionVendorBuy(data?: QueryTransactionVendorValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(QueryTransactionVendor.TRANSACTION_VENDOR_BUY, {
            params: data
          })
          .then(result => {
            const data = QueryTransactionVendorModel.fromJson(result.data.data);

            const nationality = {
              data: data
            };
            resolve(nationality);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
