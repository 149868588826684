import DChart from '@/delivery/components/atoms/chart/chart';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';
import { formatDate } from '@/infrastructure/helper/formatDate.ts';
import TransactionChartModel from '@/domain/model/dashboard/transaction_chart_model.ts';

interface ChartBuySellTransactionProps {
  dataTrxSell: TransactionChartModel[];
  dataTrxBuy: TransactionChartModel[];
}

const ChartBuySellTransaction = (props: ChartBuySellTransactionProps) => {
  const { dataTrxSell, dataTrxBuy } = props;

  const state: any = {
    series: [
      {
        name: 'TRX Jual',
        data: dataTrxSell?.map(item => item.totalFiat)
      },
      {
        name: 'TRX Beli',
        data: dataTrxBuy?.map(item => item.totalFiat)
      }
    ],
    options: {
      legend: {
        show: true,
        position: 'top'
      },
      colors: ['#426B3C', '#69A85E'],
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        with: 1,
        curve: 'smooth'
      },
      xaxis: {
        categories: dataTrxBuy?.map(item => formatDate(item.date))
      },
      yaxis: {
        title: {
          text: 'Fiat'
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          formatter: function (val: any) {
            return formatRupiah(val);
          }
        }
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        }
      }
    }
  };

  return (
    <div>
      <DChart options={state.options} series={state.series} type="area" height={350} />
    </div>
  );
};

export default ChartBuySellTransaction;
