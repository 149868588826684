import { HistoryProvider } from '@/delivery/ui/admin/transaction/take_gold/history/context/history_context.tsx';
import DetailHistory from '@/delivery/ui/admin/transaction/take_gold/history/detail/detail_history.tsx';

const DetailHistoryPage = () => {
  return (
    <div>
      <HistoryProvider>
        <DetailHistory />
      </HistoryProvider>
    </div>
  );
};

export default DetailHistoryPage;
