import GoldStockInterface, { GoldStockValue } from '@/domain/repository/interface/gold_stock_interface';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { GoldStockModel } from '@/domain/model/gold_stock_model';
import { GoldStock } from '@/domain/constant/api_list';
import { GoldStockSummaryModel } from '@/domain/model/gold_stock_summary_model.ts';

export class GoldStockRepository implements GoldStockInterface {
  api = new ApiClient();

  async fetchGoldStock(data: GoldStockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(GoldStock.FETCH, {
            params: data
          })
          .then(result => {
            const data = GoldStockModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchGoldStockSummary(data: GoldStockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(GoldStock.SUMMARY, {
            params: data
          })
          .then(result => {
            const data = GoldStockSummaryModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showGoldStock(data: GoldStockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(GoldStock.SHOW, {
            params: data
          })
          .then(result => {
            const data = GoldStockModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeGoldStock(data: GoldStockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(GoldStock.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkGoldStock(data: GoldStockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(GoldStock.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updateGoldStock(data: GoldStockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(GoldStock.UPDATE + data.uuid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deleteGoldStock(data: GoldStockValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(GoldStock.DELETE + data.uuid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
