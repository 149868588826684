import Button from '@/delivery/components/atoms/button/button';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import { useAccountTransactionContext } from '@/delivery/ui/admin/data_dca/account_transactions/context/account_transaction_context.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';

const DialogUpdateTransactionAt = (props: ModalDialogProps) => {
  const {
    setFormAccountTransaction,
    formAccountTransaction,
    handleUpdateTransactionAtModal,
    handleUpdateTransactionAt
  } = useAccountTransactionContext();
  return (
    <ModalDialog overflow={false} size="xsmall" title="Edit Paid Date Transaction" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Datepicker
          value={formAccountTransaction.succeed_at}
          label={'Paid Date'}
          onChange={e => setFormAccountTransaction({ ...formAccountTransaction, succeed_at: e })}
        />
      </div>
      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleUpdateTransactionAtModal.setState(false)} />
        <Button label="Confirm" onClick={() => handleUpdateTransactionAt()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogUpdateTransactionAt;
