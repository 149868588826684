import Button from '@/delivery/components/atoms/button/button';
import Chips from '@/delivery/components/atoms/chips/chips';
import { Image } from '@/delivery/components/atoms/image/image';
import Typography from '@/delivery/components/atoms/typography/typography';
import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';

const WebPopUp = () => {
  return (
    <div>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Content</Th>
            <Th>URL</Th>
            <Th>Publish</Th>
            <Th>Status</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {[1, 2, 3, 4, 5, 6].map((item, index) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  Notification
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  <Image width={200} height={200} url="https://picsum.photos/200/300" />
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  Gedung STC Senayan, Lantai 3 No. 178B. Jl. Asia Afrika Pintu IX, Gelora Senayan, Jakarta Pusat - 10270
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  2024-02-01
                </Typography>
              </Td>
              <Td>
                <Chips label="Aktif" type="primary-light" />
              </Td>
              <Td>
                <div className="flex gap-[4px]">
                  <Button iconButton iconType={'edit'} />
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter></Tfooter>
    </div>
  );
};

export default WebPopUp;
