import { useEffect, useState } from 'react';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useAccessControllereContext } from '@/delivery/ui/admin/settings/admin_access/context/access_controller_context.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa6';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import {
  AccessItem,
  getAccessItems,
  getAccessItemCurrent,
  GroupedAccessItemInterface
} from '@/infrastructure/helper/getAccessItems.ts';

import AvailableAccess from '@/delivery/ui/admin/settings/admin_access/tabs/access_controller/available_access.tsx';
import AssignedAccess from '@/delivery/ui/admin/settings/admin_access/tabs/access_controller/assigned_access.tsx';
import Loading from '@/delivery/components/atoms/loading/loading.tsx';
import { RoleAccessModel } from '@/domain/model/role_access_model.ts';

const AccessControllerTab = () => {
  const {
    accessControllers,
    handleFetchAccessController,
    handlefetchRole,
    handleFetchAccessRole,
    roles,
    roleAccesses,
    setFormAccessController,
    formAccessController,

    handleAssignAvailableRole,
    removeCurrentRole
  } = useAccessControllereContext();

  const [loadingAvailable, setLoadingAvailable] = useState<boolean>(false);
  const [loadingCurrent, setLoadingCurrent] = useState<boolean>(false);

  const [roleAccessesString, setRoleAccessesString] = useState<string[]>([]);
  const [accessControllerResult, setAccessControllerResult] = useState<string[]>([]);
  const [searchUsedRoleResult, setUsedRoleResult] = useState<RoleAccessModel[]>([]);

  const [searchAvailableRole, setSearchAvailableRole] = useState<string>('');
  const [searchUsedRole, setSearchUsedRole] = useState<string>('');

  useEffect(() => {
    handleFetchAccessController();
    handlefetchRole();
  }, []);

  //fetch role access after change role
  useEffect(() => {
    if (formAccessController.role_uuid) {
      handleFetchAccessRole();
    }
  }, [formAccessController]);

  //convert roleAccesses to string[]
  useEffect(() => {
    setLoadingCurrent(true);
    if (roleAccesses) {
      const _roleAccessString: string[] = [];
      for (let x = 0; roleAccesses.length > x; x++) {
        _roleAccessString.push(roleAccesses[x].access);
      }

      setRoleAccessesString(_roleAccessString);
      setLoadingCurrent(false);

      //SEARCH
      if (searchUsedRole !== '') {
        const newRoleAccessString = roleAccesses.filter(item => item.access.includes(searchUsedRole));
        setUsedRoleResult(newRoleAccessString);
      } else {
        setUsedRoleResult(roleAccesses);
      }
    }
  }, [roleAccesses, searchUsedRole]);

  //filter accessControllers with currentAccess
  useEffect(() => {
    setLoadingAvailable(true);
    const fetchData = async () => {
      if (accessControllers && roleAccessesString) {
        const accessControllersRes = accessControllers.filter(item => !roleAccessesString.includes(item));
        setAccessControllerResult(accessControllersRes);
        setLoadingAvailable(false);

        //SEARCH ROLE
        if (searchAvailableRole) {
          const newAccessControllerResult = accessControllerResult.filter(item => item.includes(searchAvailableRole));
          setAccessControllerResult(newAccessControllerResult);
        } else {
          setAccessControllerResult(accessControllersRes);
        }
      }
    };

    fetchData();
  }, [accessControllers, roleAccessesString, searchAvailableRole]);

  return (
    <div>
      <div className={'mb-[20px]'}>
        <Select2
          placeholder={'pilih role'}
          label={'Role'}
          isSearchable={false}
          options={roles.map(item => {
            return {
              value: item.UUID,
              label: item.name
            };
          })}
          onChange={e => {
            {
              setFormAccessController({
                ...formAccessController,
                role_uuid: e.value
              });
            }
          }}
        />
      </div>
      <div className={'overflow-x-auto flex gap-[20px]'}>
        <div className={'w-full flex flex-col gap-[20px]'}>
          <span>Akses Tersedia</span>
          <TextField
            value={searchAvailableRole}
            search
            block
            placeholder={'search'}
            onChange={e => setSearchAvailableRole(e.target.value)}
          />
          <div className="border border-text-tertiary-invert p-[10px] rounded-[8px] w-full border-opacity-30 h-[550px] overflow-auto">
            {!loadingAvailable ? (
              <>
                {getAccessItems(accessControllerResult)?.map((item: AccessItem, index) => (
                  <AvailableAccess data={item} />
                ))}
              </>
            ) : (
              <span>
                <Loading />
              </span>
            )}
          </div>
        </div>

        {/*BUTTONS*/}
        <div className={'flex flex-col justify-center gap-[20px]'}>
          <Button label={'assign'} suffixIcon={<FaChevronRight />} onClick={() => handleAssignAvailableRole()} />
          <Button type={'danger'} label={'remove'} prefixIcon={<FaChevronLeft />} onClick={() => removeCurrentRole()} />
        </div>
        {/*END BUTTONS*/}

        <div className={'w-full flex flex-col gap-[20px] '}>
          <span>Akses Digunakan</span>
          <TextField
            value={searchUsedRole}
            search
            block
            placeholder={'search'}
            onChange={e => setSearchUsedRole(e.target.value)}
          />

          <div className="border border-text-tertiary-invert p-[10px] rounded-[8px] w-full border-opacity-30 h-[550px] overflow-auto">
            {!loadingCurrent ? (
              <>
                {getAccessItemCurrent(searchUsedRoleResult).map((item: GroupedAccessItemInterface, index) => (
                  <AssignedAccess data={item} />
                ))}
              </>
            ) : (
              <span>
                <Loading />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessControllerTab;
