import Chips from '@/delivery/components/atoms/chips/chips';
import Typography from '@/delivery/components/atoms/typography/typography';
import { ModalDialog, ModalDialogProps } from '@/delivery/components/molecules/modal_dialog/modal_dialog';
import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table';

const DialogDepositByAdmin = (props: ModalDialogProps) => {
  return (
    <ModalDialog size="medium" title="Detail Setor Emas by Admin" modal={props.modal}>
      <div className="flex lg:flex-row flex-col gap-[24px] justify-between">
        {/* SECTION 1 */}
        <div className="w-full">
          <Table noBorder>
            <Thead>
              <Tr>
                <Th noBorder colSpan={2}>
                  Data Member
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Nama
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      Iva Ryan
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Email
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      iva838@outlook.com
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Phone
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      089043352403
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Status
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Chips label="Verified" type="primary-light" />
                  </div>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </div>

        {/* SECTION 2 */}
        <div className="w-full">
          <Table noBorder className="rounded-b-[8px] w-full">
            <Thead>
              <Tr>
                <Th noBorder colSpan={2}>
                  Data Transaksi
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    ID Transaksi
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      451.777
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Tanggal Trx
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      2023-05-27 09:43:32
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Ref ID
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      2023052709433213
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Type Transaksi
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Chips label="Pembelian" type="warning" removeDot />
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Total Gramasi
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      0,495 gr
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Gold Rate
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      1.061.700,00 IDR
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Total Amount
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      500.000,00 IDR
                    </Typography>
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Status Pembayaran
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Chips label="Pending" type="warning-light" />
                  </div>
                </Td>
              </Tr>
              <Tr>
                <Td noBorder>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    Note
                  </Typography>
                </Td>
                <Td noBorder>
                  <div className="text-right">
                    <Typography size={12} fontWeight={400} type="primary-invert">
                      -
                    </Typography>
                  </div>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </div>
      </div>
    </ModalDialog>
  );
};

export default DialogDepositByAdmin;
