const TypeFee = [
  { UUID: 'PERCENT', name: 'Percent (%)' },
  { UUID: 'FLAT', name: 'Flat (Rp)' }
];

export const TypeFeeConst = {
  PERCENT: 'PERCENT',
  FLAT: 'FLAT'
};

export default TypeFee;
