import SidebarPage from '@/delivery/components/organisms/sidebar/sidebar.tsx';
import { AuthProvider } from '@/delivery/ui/login/context/auth_context.tsx';
import { ReactNode } from 'react';

interface Sidebar {
  children?: ReactNode;
  label?: string;
}

const Sidebar = (props: Sidebar) => {
  return (
    <div>
      <AuthProvider>
        <SidebarPage>{props.children}</SidebarPage>
      </AuthProvider>
    </div>
  );
};

export default Sidebar;
