import styles from './Select.module.css';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

interface SelectProps {
  value?: any;
  className?: string;
  label?: string;
  placeholder?: string;
  items?: any[];
  selectedID?: string;
  selectedName?: string;
  onChange: (value: any) => void;
}

const Select = (props: SelectProps) => {
  const {
    label,
    className,
    placeholder,
    items = [],
    selectedID = 'UUID',
    selectedName = 'name',
    onChange,
    value
  } = props;

  const [selectedIdHook, setSelectedIdHook] = useState();
  const [selectedNameHook, setSelectedNameHook] = useState();

  const [openCloseList, setOpenCloseList] = useState(false);
  const handleOpenCloseList = () => {
    setOpenCloseList(current => !openCloseList);
  };

  const handleSelect = (data: any) => {
    setOpenCloseList(current => !openCloseList);
    setSelectedIdHook(data[selectedID]);
    setSelectedNameHook(data[selectedName]);
    onChange(data);
  };

  useEffect(() => {
    if (value) {
      setSelectedIdHook(value[selectedID]);
      setSelectedNameHook(value[selectedName]);
    }
  }, [value]);

  const classProps = clsx(className, styles['select']);

  return (
    <div className="relative">
      {label ? <div className="text-text-secondary-invert text-[14px] text-[500] mb-[6px]">{label}</div> : ''}

      <button type="button" className={classProps} onClick={handleOpenCloseList}>
        <div className="flex justify-between">
          <span
            className={` ${
              selectedNameHook ? 'text-text-primary-invert' : 'text-text-tertiary-invert'
            } flex items-center `}
          >
            {selectedNameHook ? selectedNameHook : placeholder}
          </span>
          <span className="flex items-center text-text-primary-invert">
            {openCloseList ? <BsChevronUp /> : <BsChevronDown />}
          </span>
        </div>
      </button>
      {items!.length > 0 ? (
        <ul
          className={
            openCloseList
              ? `absolute w-full mt-1 rounded-[8px] border border-border-tertiary z-10 bg-text-white`
              : `hidden`
          }
        >
          {items?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleSelect(item)}
              className={`${
                item[selectedID] === selectedIdHook ? `bg-content-bg-secondary` : `hover:bg-content-bg-secondary`
              } px-[14px] py-[10px] cursor-pointer`}
            >
              <span>{item[selectedName]}</span>
            </div>
          ))}
        </ul>
      ) : (
        <ul
          className={
            openCloseList ? `absolute w-full mt-1 rounded-[8px] border border-border-tertiary bg-text-white` : `hidden`
          }
        >
          <div className="px-[14px] py-[10px] text-text-tertiary-invert flex justify-center">
            <span>{'( no data )'}</span>
          </div>
        </ul>
      )}
    </div>
  );
};

export default Select;
