import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import TypeFee, { TypeFeeConst } from '@/domain/constant/type_fee.tsx';
import { useEffect } from 'react';
// import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import { useParams } from 'react-router-dom';
import { useWithdrawalContext } from '@/delivery/ui/admin/settings/withdrawal/context/withdrawal_context.tsx';

const DialogAddWithdrawalFee = (props: ModalDialogProps) => {
  const { uid } = useParams();
  const { handleCreateWithdrawalFeeModal, formWithdrawalFee, handleAddWithdrawalFee, setFormWithdrawalFee } =
    useWithdrawalContext();

  useEffect(() => {
    setFormWithdrawalFee({});
  }, []);

  useEffect(() => {
    if (uid) {
      setFormWithdrawalFee({ withdrawal_method_uid: uid });
    }
  }, [uid]);

  return (
    <ModalDialog size="small" title="Add Payment Fee" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <Select2
          label={'Type'}
          options={TypeFee.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          onChange={e => setFormWithdrawalFee({ ...formWithdrawalFee, type: e.value })}
        />

        {formWithdrawalFee.type === TypeFeeConst.FLAT ? (
          <TextField
            type={'currency'}
            label={'Fee (Rp)'}
            block
            onChange={(e: any) => {
              setFormWithdrawalFee({
                ...formWithdrawalFee,
                fee: e.floatValue
              });
            }}
          />
        ) : (
          ''
        )}

        {formWithdrawalFee.type === TypeFeeConst.PERCENT ? (
          <TextField
            type={'number'}
            label={'Fee (%)'}
            block
            onChange={(e: any) => {
              setFormWithdrawalFee({
                ...formWithdrawalFee,
                fee: parseInt(e.target.value)
              });
            }}
          />
        ) : (
          ''
        )}

        {/*<Datepicker*/}
        {/*  value={formChannelFee.effectiveDateStart}*/}
        {/*  label={'Effective End Start'}*/}
        {/*  block*/}
        {/*  onChange={e =>*/}
        {/*    setFormChannelFee({*/}
        {/*      ...formChannelFee,*/}
        {/*      effectiveDateStart: e*/}
        {/*    })*/}
        {/*  }*/}
        {/*/>*/}

        {/*<Datepicker*/}
        {/*  value={formChannelFee.effectiveDateEnd}*/}
        {/*  label={'Effective End Date'}*/}
        {/*  block*/}
        {/*  onChange={e =>*/}
        {/*    setFormChannelFee({*/}
        {/*      ...formChannelFee,*/}
        {/*      effectiveDateEnd: e*/}
        {/*    })*/}
        {/*  }*/}
        {/*/>*/}
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleCreateWithdrawalFeeModal.setState(false)} />
        <Button block label="Save" onClick={() => handleAddWithdrawalFee({ withdrawal_method_uid: uid })} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddWithdrawalFee;
