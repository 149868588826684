import { TBox } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Switch from '@/delivery/components/atoms/switch/switch.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useCompanySettingContext } from '@/delivery/ui/admin/settings/company/context/company_setting_context.tsx';
import { useEffect } from 'react';

const CompanySetting = () => {
  const { handleUpdateCompanySetting, handleShowCompanySetting, formCompanySetting, setFormCompanySetting } =
    useCompanySettingContext();

  useEffect(() => {
    handleShowCompanySetting();
  }, []);

  return (
    <div className={'flex justify-center'}>
      <TBox className={'lg:w-3/4 w-full flex flex-col gap-[20px]'}>
        <div className={'grid grid-cols-2 gap-[20px]'}>
          <Typography size={14} fontWeight={500}>
            Item Price Include Tax
          </Typography>
          <div>
            <Switch
              checked={formCompanySetting.include_tax}
              onChange={e => {
                setFormCompanySetting({ ...formCompanySetting, include_tax: e.target.checked });
              }}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500}>
            Tax Collection
          </Typography>
          <div>
            <Switch
              checked={formCompanySetting.is_tax_collection}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, is_tax_collection: e.target.checked })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500}>
            Midtrans Sandbox
          </Typography>
          <div>
            <Switch
              checked={formCompanySetting.midtrans_sandbox}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, midtrans_sandbox: e.target.checked })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Midtrans System Key
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.midtrans_system_key}
              block
              placeholder={'midtrans system key'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, midtrans_system_key: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          {/*<Typography size={14} fontWeight={500} className={'flex items-center'}>*/}
          {/*  Max Sub Company*/}
          {/*</Typography>*/}
          {/*<div>*/}
          {/*  <TextField*/}
          {/*    value={formCompanySetting.max_sub_company}*/}
          {/*    type={'number'}*/}
          {/*    block*/}
          {/*    placeholder={'max_sub_company'}*/}
          {/*    onChange={e =>*/}
          {/*      setFormCompanySetting({ ...formCompanySetting, max_sub_company: parseInt(e.target.value) })*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={'col-span-2'}>*/}
          {/*  <Line />*/}
          {/*</div>*/}
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Login Expired On Minute
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.login_expired_on_minute}
              type={'number'}
              block
              placeholder={'login_expired_on_minute'}
              onChange={e =>
                setFormCompanySetting({ ...formCompanySetting, login_expired_on_minute: parseInt(e.target.value) })
              }
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          {/*<Typography size={14} fontWeight={500} className={'flex items-center'}>*/}
          {/*  Invoice Expired On Minute*/}
          {/*</Typography>*/}
          {/*<div>*/}
          {/*  <TextField*/}
          {/*    value={formCompanySetting.invoice_expired_on_minute}*/}
          {/*    type={'number'}*/}
          {/*    block*/}
          {/*    placeholder={'invoice_expired_on_minute'}*/}
          {/*    onChange={e =>*/}
          {/*      setFormCompanySetting({ ...formCompanySetting, invoice_expired_on_minute: e.target.value })*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={'col-span-2'}>*/}
          {/*  <Line />*/}
          {/*</div>*/}
          {/*---*/}
          {/*<Typography size={14} fontWeight={500} className={'flex items-center'}>*/}
          {/*  Currency*/}
          {/*</Typography>*/}
          {/*<div>*/}
          {/*  <TextField*/}
          {/*    value={formCompanySetting.currency}*/}
          {/*    block*/}
          {/*    placeholder={'currency'}*/}
          {/*    onChange={e => setFormCompanySetting({ ...formCompanySetting, currency: e.target.value })}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={'col-span-2'}>*/}
          {/*  <Line />*/}
          {/*</div>*/}
          {/*---*/}
          {/*<Typography size={14} fontWeight={500} className={'flex items-center'}>*/}
          {/*  Time Zone*/}
          {/*</Typography>*/}
          {/*<div>*/}
          {/*  <TextField*/}
          {/*    value={formCompanySetting.time_zone}*/}
          {/*    block*/}
          {/*    placeholder={'time_zone'}*/}
          {/*    onChange={e => setFormCompanySetting({ ...formCompanySetting, time_zone: e.target.value })}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={'col-span-2'}>*/}
          {/*  <Line />*/}
          {/*</div>*/}
          {/*---*/}
          {/*<Typography size={14} fontWeight={500} className={'flex items-center'}>*/}
          {/*  Default Sales Station uuid*/}
          {/*</Typography>*/}
          {/*<div>*/}
          {/*  <TextField*/}
          {/*    value={formCompanySetting.default_sales_station_uuid}*/}
          {/*    block*/}
          {/*    placeholder={'default_sales_station_uuid'}*/}
          {/*    onChange={e =>*/}
          {/*      setFormCompanySetting({ ...formCompanySetting, default_sales_station_uuid: e.target.value })*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={'col-span-2'}>*/}
          {/*  <Line />*/}
          {/*</div>*/}
          {/*---*/}
          {/*<Typography size={14} fontWeight={500} className={'flex items-center'}>*/}
          {/*  Default Receiving Station uuid*/}
          {/*</Typography>*/}
          {/*<div>*/}
          {/*  <TextField*/}
          {/*    value={formCompanySetting.default_receiving_station_uuid}*/}
          {/*    block*/}
          {/*    placeholder={'default_receiving_station_uuid'}*/}
          {/*    onChange={e =>*/}
          {/*      setFormCompanySetting({ ...formCompanySetting, default_receiving_station_uuid: e.target.value })*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={'col-span-2'}>*/}
          {/*  <Line />*/}
          {/*</div>*/}
          {/*---*/}
          {/*<Typography size={14} fontWeight={500} className={'flex items-center'}>*/}
          {/*  Transit Station uuid*/}
          {/*</Typography>*/}
          {/*<div>*/}
          {/*  <TextField*/}
          {/*    value={formCompanySetting.transit_station_uuid}*/}
          {/*    block*/}
          {/*    placeholder={'transit_station_uuid'}*/}
          {/*    onChange={e => setFormCompanySetting({ ...formCompanySetting, transit_station_uuid: e.target.value })}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={'col-span-2'}>*/}
          {/*  <Line />*/}
          {/*</div>*/}
          {/*---*/}
          {/*<Typography size={14} fontWeight={500} className={'flex items-center'}>*/}
          {/*  Email Maximum Stock Alert*/}
          {/*</Typography>*/}
          {/*<div>*/}
          {/*  <TextField*/}
          {/*    value={formCompanySetting.email_maximum_stock_alert}*/}
          {/*    block*/}
          {/*    placeholder={'email_maximum_stock_alert'}*/}
          {/*    onChange={e =>*/}
          {/*      setFormCompanySetting({ ...formCompanySetting, email_maximum_stock_alert: e.target.value })*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={'col-span-2'}>*/}
          {/*  <Line />*/}
          {/*</div>*/}
          {/*---*/}
          {/*<Typography size={14} fontWeight={500} className={'flex items-center'}>*/}
          {/*  Email Minimal Stock Alert*/}
          {/*</Typography>*/}
          {/*<div>*/}
          {/*  <TextField*/}
          {/*    value={formCompanySetting.email_minimal_stock_alert}*/}
          {/*    block*/}
          {/*    placeholder={'email_minimal_stock_alert'}*/}
          {/*    onChange={e =>*/}
          {/*      setFormCompanySetting({ ...formCompanySetting, email_minimal_stock_alert: e.target.value })*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={'col-span-2'}>*/}
          {/*  <Line />*/}
          {/*</div>*/}
          {/*---*/}
          {/*<Typography size={14} fontWeight={500} className={'flex items-center'}>*/}
          {/*  Phone Minimal Stock Alert*/}
          {/*</Typography>*/}
          {/*<div>*/}
          {/*  <TextField*/}
          {/*    value={formCompanySetting.phone_minimal_stock_alert}*/}
          {/*    block*/}
          {/*    placeholder={'phone_minimal_stock_alert'}*/}
          {/*    onChange={e =>*/}
          {/*      setFormCompanySetting({ ...formCompanySetting, phone_minimal_stock_alert: e.target.value })*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={'col-span-2'}>*/}
          {/*  <Line />*/}
          {/*</div>*/}
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Tax Holder
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.tax_holder}
              block
              placeholder={'tax_holder'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, tax_holder: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Taxable Income Date
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.taxable_income_date}
              block
              placeholder={'taxable_income_date'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, taxable_income_date: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Tax Number
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.tax_number}
              type={'number'}
              block
              placeholder={'tax_number'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, tax_number: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Tax Type
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.tax_type}
              block
              placeholder={'tax_type'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, tax_type: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          {/*<Typography size={14} fontWeight={500} className={'flex items-center'}>*/}
          {/*  Tax Address UUID*/}
          {/*</Typography>*/}
          {/*<div>*/}
          {/*  <TextField*/}
          {/*    value={formCompanySetting.tax_address_uuid}*/}
          {/*    block*/}
          {/*    placeholder={'tax_address_uuid'}*/}
          {/*    onChange={e => setFormCompanySetting({ ...formCompanySetting, tax_address_uuid: e.target.value })}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={'col-span-2'}>*/}
          {/*  <Line />*/}
          {/*</div>*/}
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Xendit System Key
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.xendit_system_key}
              block
              placeholder={'xendit_system_key'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, xendit_system_key: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Xendit X Callback
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.xendit_x_callback}
              block
              placeholder={'xendit_x_callback'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, xendit_x_callback: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Xendit Endpoint
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.xendit_endpoint}
              block
              placeholder={'xendit_endpoint'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, xendit_endpoint: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Doku Secret
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.doku_secret}
              block
              placeholder={'doku_secret'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, doku_secret: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Doku Client ID
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.doku_client_id}
              type={'number'}
              block
              placeholder={'doku_client_id'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, doku_client_id: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Doku Endpoint
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.doku_endpoint}
              block
              placeholder={'doku_endpoint'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, doku_endpoint: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Ipaymu Api Key
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.ipaymu_api_key}
              block
              placeholder={'ipaymu_api_key'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, ipaymu_api_key: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Ipaymu Secret Key
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.ipaymu_secret_key}
              block
              placeholder={'ipaymu_secret_key'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, ipaymu_secret_key: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Expired On Minute
          </Typography>
          <div>
            <TextField
              type={'number'}
              value={formCompanySetting.expired_on_minute}
              block
              placeholder={'expired_on_minute'}
              onChange={e =>
                setFormCompanySetting({ ...formCompanySetting, expired_on_minute: parseInt(e.target.value) })
              }
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Email Approve Sender
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.email_approve_sender}
              block
              placeholder={'email_approve_sender'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, email_approve_sender: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Phone Approve Sender
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.phone_approve_sender}
              type={'number'}
              block
              placeholder={'phone_approve_sender'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, phone_approve_sender: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Email OTP
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.email_otp}
              block
              placeholder={'email_otp'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, email_otp: e.target.value })}
            />
          </div>
          <div className={'col-span-2'}>
            <Line />
          </div>
          {/*---*/}
          <Typography size={14} fontWeight={500} className={'flex items-center'}>
            Phone Access OTP
          </Typography>
          <div>
            <TextField
              value={formCompanySetting.phone_access_otp}
              type={'number'}
              block
              placeholder={'phone_access_otp'}
              onChange={e => setFormCompanySetting({ ...formCompanySetting, phone_access_otp: e.target.value })}
            />
          </div>
        </div>

        <Line />
        <div className={'flex justify-end gap-[20px]'}>
          <Button label={'Save Changes'} onClick={() => handleUpdateCompanySetting()} />
        </div>
      </TBox>
    </div>
  );
};

export default CompanySetting;
