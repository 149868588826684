import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { DCARequestModel } from '@/domain/model/dca_request_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { DCARequestRepository } from '@/domain/repository/dca_request_repository.ts';
import { DCAModel } from '@/domain/model/dca_model.ts';
import { DCAStatisticModel } from '@/domain/model/dca_statistic_model.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface FormFilterAccountList {
  search?: string;
  email?: string;
  phone?: string;
  status?: string;
  type?: string;
}

interface AccountListValueParams {
  page?: number;
  limit?: number;
}

interface FormAccountListInterface {
  uid?: string;
  commission?: number;
  commission_type?: string;
  type?: string;
}

interface AccountListValue {
  accountListExport: DCAModel[];
  DCAStatistic?: DCAStatisticModel;
  formFilterAccountList: FormFilterAccountList;
  setFormFilterAccountList: Dispatch<SetStateAction<FormFilterAccountList>>;
  formAccountList: FormAccountListInterface;
  setFormAccountList: Dispatch<SetStateAction<FormAccountListInterface>>;
  // accountList: FormAccountListInterface | undefined;
  accountLists: DCAModel[];
  accountListPagination?: PaginationModel;
  handleAddAccountListModal: ModalObject;
  handleUpdateAccountListModal: ModalObject;
  handleFetchAccountList: (_data: AccountListValueParams) => void;
  handleShowAccountList: (_data: DCARequestModel) => void;
  handleShowStatistic: () => void;
  fetchAccountListExport: () => void;
}

const AccountListContext = createContext<AccountListValue | null>(null);

const accountListRepository = new DCARequestRepository();

const AccountListProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const [formFilterAccountList, setFormFilterAccountList] = useState<FormFilterAccountList>({});

  const handleAddAccountListModal = useVisibleComponent(false);
  const handleUpdateAccountListModal = useVisibleComponent(false);

  const [DCAStatistic, setDCAStatistic] = useState<DCAStatisticModel>();
  // const [accountList, setAccountList] = useState<AccountListModel>();
  const [accountListExport, setAccountListExport] = useState<DCAModel[]>([]);
  const [accountLists, setAccountLists] = useState<DCAModel[]>([]);
  const [accountListPagination, setAccountListPagination] = useState<PaginationModel>();

  const [formAccountList, setFormAccountList] = useState<FormAccountListInterface>({});

  //FETCH DATA ACCOUNT LIST
  const fetchAccountListExport = () => {
    const x = alertToast.loadingAlert('Preparing data please wait');
    const params = {
      page: 1,
      limit: -1,
      search: formFilterAccountList.search ?? null,
      status: formFilterAccountList.status ?? null,
      email: formFilterAccountList.email ?? null,
      phone: formFilterAccountList.phone ?? null,
      type: formFilterAccountList.type ?? null
    };

    accountListRepository
      .fetchDCA(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setAccountListExport(result.data);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //FETCH AFFILIATE
  const handleFetchAccountList = (_data: AccountListValueParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data?.page ?? 1,
      limit: _data?.limit ?? 10,
      search: formFilterAccountList.search ?? null,
      status: formFilterAccountList.status ?? null,
      email: formFilterAccountList.email ?? null,
      phone: formFilterAccountList.phone ?? null,
      type: formFilterAccountList.type ?? null
    };

    accountListRepository
      .fetchDCA(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setAccountLists(result.data);
        setAccountListPagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW AFFILIATE
  const handleShowAccountList = (_data: DCARequestModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };

    accountListRepository
      .showDCARequest(params)
      .then((res: any) => {
        const result: DCARequestModel = res;
        alertToast.updateLoading(x);
        setFormAccountList({
          uid: result.UID
        });
        handleUpdateAccountListModal.setState(true);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW STATISTIC
  const handleShowStatistic = () => {
    const x = alertToast.loadingAlert('');

    accountListRepository
      .showDCAStatistic()
      .then((result: any) => {
        setDCAStatistic(result);
        alertToast.updateLoading(x);
      })
      .catch(() => {
        alertToast.updateLoading(x);
      });
  };

  const contextValue: AccountListValue = {
    accountListExport,
    fetchAccountListExport,
    DCAStatistic,
    formFilterAccountList,
    setFormFilterAccountList,
    formAccountList,
    setFormAccountList,
    // accountList,
    accountLists,
    accountListPagination,
    handleFetchAccountList,
    handleAddAccountListModal,
    handleUpdateAccountListModal,
    handleShowAccountList,
    handleShowStatistic
  };

  return <AccountListContext.Provider value={contextValue}>{children}</AccountListContext.Provider>;
};

const useAccountListContext = (): AccountListValue => {
  const context = useContext(AccountListContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a AccountListProvider');
  }
  return context;
};

export { AccountListProvider, useAccountListContext };
