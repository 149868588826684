import { ReactNode } from 'react';

interface RadioGroupProps {
  children?: ReactNode;
  direction?: 'vertical' | 'horizontal';
  radioCount: string;
}

const RadioGroup = (props: RadioGroupProps) => {
  const { children, direction = 'horizontal', radioCount } = props;

  return (
    <div className="border-border-tertiary rounded-[8px] border">
      <div
        className={`grid grid-${direction === 'horizontal' ? 'cols' : 'rows'}-${radioCount} divide-${
          direction === 'horizontal' ? 'x' : 'y'
        } divide-border-tertiary`}
      >
        {children}
      </div>
    </div>
  );
};

export default RadioGroup;
