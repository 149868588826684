import { ReactNode } from 'react';

export interface NavbarProps {
  label?: string;
  children?: ReactNode;
}

const Navbar = (props: NavbarProps) => {
  const { label, children } = props;
  return (
    <div className="pb-[32px]">
      <div className="border-b border-b-border-secondary w-full pb-[24px] pt-[32px] flex lg:flex-row flex-col justify-between">
        <p className="text-[24px] font-[600] text-text-primary-invert">{label ? label : 'Dinaran'}</p>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Navbar;
