import { useEffect } from 'react';
import AssetStatistic from './access_statistic';
import Button from '@/delivery/components/atoms/button/button';

import { AiOutlineCalendar } from 'react-icons/ai';
import { BsFilter } from 'react-icons/bs';
import Navbar from '@/delivery/components/organisms/navbar/navbar';

const AccessStatisticPage = () => {
  useEffect(() => {
    document.title = 'Statistik Akses';
  }, []);

  return (
    <div>
      <Navbar label={'Statistik Akses'}>
        <div className="flex gap-[12px]">
          <Button type="secondary" prefixIcon={<AiOutlineCalendar />} label="19 Apr 2023 - 19 May 2023" />
          <Button type="secondary" prefixIcon={<BsFilter />} label="Filter" />
        </div>
      </Navbar>
      <AssetStatistic />
    </div>
  );
};

export default AccessStatisticPage;
