import { ReactNode } from 'react';

import Notification from '@/delivery/components/atoms/notification/notification.tsx';
import Sidebar from '@/delivery/components/organisms/sidebar';

interface LayoutAdminProps {
  children?: ReactNode;
  label?: string;
}

const LayoutAdmin = (props: LayoutAdminProps) => {
  const { children } = props;

  return (
    <>
      <Notification />
      <div className="bg-content-bg-secondary h-[100vh] fixed w-full z-[-1]"></div>
      <div>
        <Sidebar>
          <div>{children}</div>
        </Sidebar>
      </div>
    </>
  );
};

export default LayoutAdmin;
