import GoldPrice from './gold_price';
import Navbar from '@/delivery/components/organisms/navbar/navbar';
import Button from '@/delivery/components/atoms/button/button';
import DialogHargaEmas from './dialog/dialog_harga_emas';
import { useGoldPriceContext } from './context/gold_price_context';
import Icon from '@/domain/constant/icon.tsx';

const GoldPricePage = () => {
  const { handleAddGoldPriceModal, handleAddGoldRateModal } = useGoldPriceContext();
  return (
    <div>
      <Navbar label="Harga Emas">
        <Button
          onClick={() => handleAddGoldRateModal()}
          prefixIcon={Icon.plus}
          label="Add/Update Harga Emas Hari Ini"
        />
      </Navbar>
      <GoldPrice />

      {handleAddGoldPriceModal.state && <DialogHargaEmas modal={handleAddGoldPriceModal} />}
    </div>
  );
};

export default GoldPricePage;
