import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';

import Button from '@/delivery/components/atoms/button/button.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import { APP_VERSION } from '@/domain/constant/setting.ts';
import { useAuthContext } from '@/delivery/ui/login/context/auth_context.tsx';
import { ENV_LOCAL_BASE_URL_NAME } from '@/domain/constant/environment.ts';
import { useState } from 'react';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';

const DialogAddCustomBaseUrl = (props: ModalDialogProps) => {
  const navigate = useNavigate();
  const alertToast = useAlertToast();
  const { handleAddBaseUrlModal } = useAuthContext();

  const [apiUrl, setApiUrl] = useState<string>('');

  const handleSaveLocalEnv = () => {
    localStorage.setItem(ENV_LOCAL_BASE_URL_NAME, apiUrl);
    alertToast.successAlert('Local Env Tersimpan');
    setTimeout(() => {
      navigate(Routing.LOGIN);
      location.reload();
    }, 500);
  };

  const handleClearEnv = () => {
    localStorage.removeItem(ENV_LOCAL_BASE_URL_NAME);
    alertToast.successAlert('Local Env Dibersihkan');
    setTimeout(() => {
      navigate(Routing.LOGIN);
      location.reload();
    }, 500);
  };

  return (
    <ModalDialog size="small" title="Dev Mode" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <div>
          <Chips removeDot label={APP_VERSION} type={'warning-light'} />
        </div>

        <TextField
          value={localStorage.getItem(ENV_LOCAL_BASE_URL_NAME)?.toString()}
          label={'Base URL'}
          block
          onChange={e => setApiUrl(e.target.value)}
        />
      </div>
      <ModalDialogFooter className="flex justify-end gap-[10px]">
        <Button type="secondary" label="Cancel" onClick={() => handleAddBaseUrlModal.setState(false)} />
        <Button type="danger" label="Reset" onClick={() => handleClearEnv()} />
        <Button label="Save" onClick={() => handleSaveLocalEnv()} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddCustomBaseUrl;
