import Navbar from '@/delivery/components/organisms/navbar/navbar';
import MasterMemberAffiliate from './master_member_affliate';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useAffiliateContext } from '@/delivery/ui/admin/master/member_affiliate/context/member_affiliate_context.tsx';
import DialogAddMemberAffiliate from '@/delivery/ui/admin/master/member_affiliate/dialog/dialog_add_member_affiliate.tsx';
import Icon from '@/domain/constant/icon.tsx';

const MasterMemberAffiliatePage = () => {
  const { handleAddAffiliateModal } = useAffiliateContext();
  return (
    <div>
      <Navbar label={'Master Member Affiliate'}>
        <Button
          prefixIcon={Icon.plus}
          label="Add Member Affiliate"
          onClick={() => handleAddAffiliateModal.setState(true)}
        />
      </Navbar>
      <MasterMemberAffiliate />

      {handleAddAffiliateModal.state && <DialogAddMemberAffiliate modal={handleAddAffiliateModal} />}
    </div>
  );
};

export default MasterMemberAffiliatePage;
