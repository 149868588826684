import { useEffect } from 'react';
import AccountTransactionPage from '@/delivery/ui/admin/data_dca/account_transactions';
import { AccountTransactionProvider } from '@/delivery/ui/admin/data_dca/account_transactions/context/account_transaction_context.tsx';

const ViewAccountTransaction = () => {
  useEffect(() => {
    document.title = 'Account Transaction';
  }, []);

  return (
    <div>
      <AccountTransactionProvider>
        <AccountTransactionPage />
      </AccountTransactionProvider>
    </div>
  );
};

export default ViewAccountTransaction;
