import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { EventReferralRepository } from '@/domain/repository/event_referral_repository.ts';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { EventReferralModel } from '@/domain/model/event_referral_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';

interface EventReferralContextContextProps {
  children: ReactNode;
}

interface EventReferralParams {
  page?: number;
  limit?: number;
}

interface FormEventReferral {
  uid?: string;
  description?: string;
  end_date?: string;
  flags?: string;
  name?: string;
  start_date?: string;
  type?: string;
  status?: string;
}

interface FormFilterEventReferral {
  name?: string;
  type?: string;
}

interface EventReferralContextValue {
  formFilterEventReferral: FormFilterEventReferral;
  setFormFilterEventReferral: Dispatch<SetStateAction<FormFilterEventReferral>>;
  formEventReferral: FormEventReferral;
  setFormEventReferral: Dispatch<SetStateAction<FormEventReferral>>;
  eventReferrals: EventReferralModel[];
  eventReferralPagination?: PaginationModel;
  handleAddEventReferralModal: ModalObject;
  handleUpdateEventReferralModal: ModalObject;
  handleFetchEventReferral: (_data: EventReferralParams) => void;
  handleStoreEventReferral: () => void;
  handleShowEventReferral: (_data: EventReferralModel) => void;
  handleUpdateEventReferral: () => void;
}

const MemberVerifyContext = createContext<EventReferralContextValue | null>(null);

const eventReferralRepository = new EventReferralRepository();

const EventReferralProvider: React.FC<EventReferralContextContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  // const alertSweet = useAlertSweetComponent();

  const [eventReferrals, seEventReferrals] = useState<EventReferralModel[]>([]);
  const [eventReferralPagination, seEventReferralPagination] = useState<PaginationModel>();

  const [formEventReferral, setFormEventReferral] = useState<FormEventReferral>({});

  const [formFilterEventReferral, setFormFilterEventReferral] = useState<FormFilterEventReferral>({});

  const handleAddEventReferralModal = useVisibleComponent(false);
  const handleUpdateEventReferralModal = useVisibleComponent(false);

  //HANDLE FETCH EVENT REFERRAL
  const handleFetchEventReferral = (_data: EventReferralParams) => {
    const x = alertToast.loadingAlert('');

    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      name: formFilterEventReferral.name ?? null,
      type: formFilterEventReferral.type ?? null
    };

    eventReferralRepository
      .fetchEventReferral(params)
      .then((result: any) => {
        seEventReferrals(result.data);
        seEventReferralPagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE STORE EVENT REFERRAL
  const handleStoreEventReferral = () => {
    const x = alertToast.loadingAlert('');

    eventReferralRepository
      .storeEventReferral(formEventReferral)
      .then(() => {
        handleFetchEventReferral({ page: 1 });
        handleAddEventReferralModal.setState(false);
        alertToast.successAlert('Success');
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW EVENT REFERRAL
  const handleShowEventReferral = (_data: EventReferralModel) => {
    const x = alertToast.loadingAlert('');

    const params = {
      uid: _data.UID
    };

    eventReferralRepository
      .showEventReferral(params)
      .then((res: any) => {
        const result: EventReferralModel = res;
        setFormEventReferral({
          uid: result.UID,
          type: result.type,
          status: result.status,
          description: result.description,
          start_date: formatDateParam(result.startDate),
          end_date: formatDateParam(result.endDate),
          name: result.name
        });
        handleUpdateEventReferralModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE EVENT REFERRAL
  const handleUpdateEventReferral = () => {
    const x = alertToast.loadingAlert('');

    eventReferralRepository
      .updateEventReferral(formEventReferral)
      .then(() => {
        handleFetchEventReferral({ page: 1 });
        handleUpdateEventReferralModal.setState(false);
        alertToast.successAlert('Success');
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: EventReferralContextValue = {
    formFilterEventReferral,
    setFormFilterEventReferral,
    formEventReferral,
    setFormEventReferral,
    eventReferrals,
    eventReferralPagination,
    handleAddEventReferralModal,
    handleFetchEventReferral,
    handleStoreEventReferral,
    handleUpdateEventReferralModal,
    handleShowEventReferral,
    handleUpdateEventReferral
  };

  return <MemberVerifyContext.Provider value={contextValue}>{children}</MemberVerifyContext.Provider>;
};

const useEventReferralContext = (): EventReferralContextValue => {
  const context = useContext(MemberVerifyContext);
  if (!context) {
    throw new Error('useEventReferralContext must be used within a EventReferralProvider');
  }
  return context;
};

export { EventReferralProvider, useEventReferralContext };
