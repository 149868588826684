import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
// import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { WithdrawalMethodRepository } from '@/domain/repository/withdrawal_method_repository.ts';
import { WithdrawalMethodModel } from '@/domain/model/withdrawal_method_model.ts';
import { PaymentVendorModel } from '@/domain/model/payment/payment_vendor_model.ts';
import { WithdrawalFeeRepository } from '@/domain/repository/withdrawal_fee_repository.ts';
import { WithdrawalFeeModel } from '@/domain/model/withdrawal_fee_model.ts';

interface withdrawalContextContextProps {
  children: ReactNode;
}

interface WithdrawalValue {
  uid?: string;
  withdrawal_method_uid?: string;
  page?: number;
  limit?: number;
}

interface FormWithdrawalFeeInterface {
  user_uid?: string;
  withdrawal_method_uid?: string;
  additional_flat_fee?: number;
  additional_percent_fee?: number;
  effective_date_end?: string;
  effective_date_start?: string;
  fee?: number;
  type?: string;
}

interface FormWithdrawalValue {
  uid?: string;
  vendor_uid?: string;
  code?: string;
  name?: string;
  description?: string;
  status?: string;
  withdrawal_flow?: string;
  vendor?: PaymentVendorModel;
}

interface withdrawalContextValue {
  formWithdrawalFee: FormWithdrawalFeeInterface;
  setFormWithdrawalFee: Dispatch<SetStateAction<FormWithdrawalFeeInterface>>;
  withdrawalFees: WithdrawalFeeModel[];
  withdrawalFeePagination?: PaginationModel;
  formWithdrawal: FormWithdrawalValue;
  setFormWithdrawal: Dispatch<SetStateAction<FormWithdrawalValue>>;
  withdrawalMethodPagination?: PaginationModel;
  withdrawalMethods: WithdrawalMethodModel[];
  handleCreateWithdrawalFeeModal: ModalObject;
  handleFetchWithdrawalMethod: (_data: WithdrawalValue) => void;
  handleShowWithdrawalMethod: (_data: WithdrawalValue) => void;
  handleUpdateWithdrawalMethod: () => void;
  handleFetchWithdrawalFee: (_data: WithdrawalValue) => void;
  handleAddWithdrawalFee: (_data: WithdrawalValue) => void;
}

const MemberVerifyContext = createContext<withdrawalContextValue | null>(null);

const withdrawalMethod = new WithdrawalMethodRepository();
const withdrawalFee = new WithdrawalFeeRepository();

const WithdrawalProvider: React.FC<withdrawalContextContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  // const alertSweet = useAlertSweetComponent();

  const handleCreateWithdrawalFeeModal = useVisibleComponent(false);

  const [withdrawalMethods, setWithdrawalMethods] = useState<WithdrawalMethodModel[]>([]);
  const [withdrawalMethodPagination, setWithdrawalMethodPagination] = useState<PaginationModel>();

  const [withdrawalFees, setWithdrawalFees] = useState<WithdrawalFeeModel[]>([]);
  const [withdrawalFeePagination, setWithdrawalFeePagination] = useState<PaginationModel>();

  const [formWithdrawal, setFormWithdrawal] = useState<FormWithdrawalValue>({});
  const [formWithdrawalFee, setFormWithdrawalFee] = useState<FormWithdrawalFeeInterface>({});

  //HANDLE FETCH withdrawal
  const handleFetchWithdrawalMethod = (_data: WithdrawalValue) => {
    const params = {
      page: 1
    };
    withdrawalMethod
      .fetchWithdrawalMethod(params)
      .then((result: any) => {
        setWithdrawalMethods(result.data);
        setWithdrawalMethodPagination(result.pagination);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW WITHDRAWAL METHOD
  const handleShowWithdrawalMethod = (_data: WithdrawalValue) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.uid
    };
    withdrawalMethod
      .showWithdrawalMethod(params)
      .then((res: any) => {
        alertToast.updateLoading(x);
        const result: WithdrawalMethodModel = res;
        setFormWithdrawal({
          code: result.code,
          description: result.description,
          name: result.name,
          status: result.status,
          uid: result.UID,
          withdrawal_flow: result.withdrawalFlow,
          vendor_uid: result.vendorUUID,
          vendor: result.vendor
        });
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE WITHDRAWAL METHOD
  const handleUpdateWithdrawalMethod = () => {
    const x = alertToast.loadingAlert('');
    withdrawalMethod
      .updateWithdrawalMethod(formWithdrawal)
      .then((res: any) => {
        alertToast.updateLoading(x);
        alertToast.successAlert('Success');
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH WITHDRAWAL FEE
  const handleFetchWithdrawalFee = (_data: WithdrawalValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      withdrawal_method_uid: _data.withdrawal_method_uid
    };

    withdrawalFee
      .fetchWithdrawalFee(params)
      .then((res: any) => {
        setWithdrawalFees(res.data);
        setWithdrawalFeePagination(res.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE ADD WITHDRAWAL FEE
  const handleAddWithdrawalFee = (_data: WithdrawalValue) => {
    const x = alertToast.loadingAlert('');

    withdrawalFee
      .storeWithdrawalFee(formWithdrawalFee)
      .then((res: any) => {
        handleFetchWithdrawalFee({ withdrawal_method_uid: _data.withdrawal_method_uid });
        handleCreateWithdrawalFeeModal.setState(false);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: withdrawalContextValue = {
    formWithdrawalFee,
    setFormWithdrawalFee,
    handleShowWithdrawalMethod,
    formWithdrawal,
    setFormWithdrawal,
    withdrawalMethodPagination,
    withdrawalMethods,
    handleCreateWithdrawalFeeModal,
    handleFetchWithdrawalMethod,
    handleUpdateWithdrawalMethod,
    handleFetchWithdrawalFee,
    withdrawalFees,
    withdrawalFeePagination,
    handleAddWithdrawalFee
  };

  return <MemberVerifyContext.Provider value={contextValue}>{children}</MemberVerifyContext.Provider>;
};

const useWithdrawalContext = (): withdrawalContextValue => {
  const context = useContext(MemberVerifyContext);
  if (!context) {
    throw new Error('useWithdrawalContext must be used within a WithdrawalProvider');
  }
  return context;
};

export { WithdrawalProvider, useWithdrawalContext };
