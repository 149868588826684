import PaymentChannelFeeInterface, {
  PaymentChannelFeeValue
} from '@/domain/repository/interface/payment_channel_fee_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { PaymentChannelFeeModel } from '@/domain/model/payment/payment_channel_fee_model.ts';
import { PaymentChannelFee } from '@/domain/constant/api_list';

export class PaymentChannelFeeRepository implements PaymentChannelFeeInterface {
  api = new ApiClient();

  async fetchPaymentChannelFee(data: PaymentChannelFeeValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(PaymentChannelFee.FETCH, {
            params: data
          })
          .then(result => {
            const data = PaymentChannelFeeModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showPaymentChannelFee(data: PaymentChannelFeeValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(PaymentChannelFee.SHOW, {
            params: data
          })
          .then(result => {
            const data = PaymentChannelFeeModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storePaymentChannelFee(data: PaymentChannelFeeValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(PaymentChannelFee.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
