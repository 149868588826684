import { BaseModel } from '@/domain/model/base_model.ts';
import { truncateDecimal } from '@/infrastructure/helper/truncateDecimalNumber.ts';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';
import { formatDateTime } from '@/infrastructure/helper/formatDate.ts';

export class BalanceModel extends BaseModel {
  userUID: string;
  trxUID: string;
  releaseDate: string;
  type: string;
  amount: number;
  incoming: number;
  outgoing: number;
  lastBalance: number;
  notes: string;
  fiat: number;
  goldRateUID: number;
  gram: number;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    userUID: string,
    trxUID: string,
    releaseDate: string,
    type: string,
    amount: number,
    incoming: number,
    outgoing: number,
    lastBalance: number,
    notes: string,
    fiat: number,
    goldRateUID: number,
    gram: number
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.userUID = userUID;
    this.trxUID = trxUID;
    this.releaseDate = releaseDate;
    this.type = type;
    this.amount = amount;
    this.incoming = incoming;
    this.outgoing = outgoing;
    this.lastBalance = lastBalance;
    this.notes = notes;
    this.fiat = fiat;
    this.goldRateUID = goldRateUID;
    this.gram = gram;
  }

  get getTruncateAmountDecimal(): number {
    if (this.amount) {
      return truncateDecimal(this.amount);
    }

    return 0;
  }

  get getFiatRupiah(): string {
    if (this.fiat) {
      return formatRupiah(this.fiat);
    }

    return '-';
  }

  get getAmountRupiah(): string {
    if (this.amount) {
      return formatRupiah(this.amount);
    }

    return '-';
  }

  get getCreatedAt(): string {
    if (this.createdAt) {
      return formatDateTime(this.createdAt);
    }

    return '-';
  }

  get getReleaseAt(): string {
    if (this.releaseDate) {
      return formatDateTime(this.releaseDate);
    }

    return '-';
  }

  public static fromJson(data: any): any | BalanceModel {
    try {
      return new BalanceModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.user_uid,
        data.trx_uid,
        data.release_date,
        data.type,
        data.amount,
        data.incoming,
        data.outgoing,
        data.last_balance,
        data.notes,
        data.fiat,
        data.gold_rate_uid,
        data.gram
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): BalanceModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(BalanceModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
