import GoldPiece from '@/delivery/ui/admin/master/gold_piece/gold_piece.tsx';
import Navbar from '@/delivery/components/organisms/navbar/navbar.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import DialogAddGoldPiece from '@/delivery/ui/admin/master/gold_piece/dialog/dialog_add_gold_piece.tsx';
import { useGoldPieceContext } from '@/delivery/ui/admin/master/gold_piece/context/gold_piece_context.tsx';
import Icon from '@/domain/constant/icon.tsx';

const GoldPiecePage = () => {
  const { handleAddGoldPieceModal } = useGoldPieceContext();
  return (
    <div>
      <Navbar label={'Gold Piece'}>
        <Button prefixIcon={Icon.plus} label="Add Gold Piece" onClick={() => handleAddGoldPieceModal.setState(true)} />
      </Navbar>
      <GoldPiece />

      {handleAddGoldPieceModal.state && <DialogAddGoldPiece modal={handleAddGoldPieceModal} />}
    </div>
  );
};

export default GoldPiecePage;
