// import { UserModel } from "./user_model";

export class BaseModel {
  ID: bigint;
  UID: string;
  UUID: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: string;
  deletedBy: string;
  flags?: string[];
  meta?: any;

  // createdByUser?: UserModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags?: string[],
    meta?: any
    // createdByUser?: UserModel
  ) {
    this.ID = ID;
    this.UID = UID;
    this.UUID = UUID;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;
    this.deletedAt = deletedAt;
    this.deletedBy = deletedBy;
    this.flags = flags;
    this.meta = meta;
    // this.createdByUser = createdByUser;
  }
}
