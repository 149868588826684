import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';
import Line from '@/delivery/components/atoms/line/line.tsx';
import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import { useAffiliateListContext } from '@/delivery/ui/admin/reseller_affiliate/affiliate_list/context/affiliate_list_context.tsx';
import { useEffect } from 'react';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import { STATUS_EMAIL_VERIFIED } from '@/domain/constant/status_email_verified.ts';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';
import TableDataReferral from '@/delivery/ui/admin/reseller_affiliate/affiliate_list/detail/table_data_referral.tsx';

const DetailAffiliate = () => {
  const { uuid } = useParams();
  const { handleFetchProfileAffiliate, user, handleShowProfileUser } = useAffiliateListContext();
  const navigate = useNavigate();

  useEffect(() => {
    handleShowProfileUser({ user_uuid: uuid });
    handleFetchProfileAffiliate({ user_uuid: uuid });
  }, []);

  return (
    <div className={'mt-[24px] flex flex-col gap-[20px]'}>
      <div
        className={'flex gap-[10px] items-center text-text-primary cursor-pointer'}
        onClick={() => navigate(Routing.RESELLER_AFFILIATE.AFFILIATE_LIST)}
      >
        <FaArrowLeft />
        <Typography size={14} fontWeight={500}>
          Back to affiliate list
        </Typography>
      </div>
      <div>
        <Typography size={24} fontWeight={600}>
          Affiliate Detail
        </Typography>
      </div>
      <Line />
      <div className={'flex flex-col gap-[20px]'}>
        <div>
          <Theader>
            <div>
              <Typography size={14} fontWeight={600}>
                Data Register
              </Typography>
            </div>

            <div></div>
          </Theader>
          <Table noBorder className="rounded-b-[8px]">
            <Thead>
              <Tr>
                <Th>Dinaran Number</Th>
                <Th>Nama Lengkap</Th>
                <Th>Email</Th>
                <Th>Phone</Th>
                <Th>Reg Date</Th>
                <Th>Email Verified</Th>
                <Th>Member Verified</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  <Typography fontWeight={400} size={12} type="primary-invert">
                    {user?.serialID}
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={400} size={12} type="primary-invert">
                    {user?.getFullName}
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={400} size={12} type="primary-invert">
                    {user?.email}
                  </Typography>
                </Td>

                <Td>
                  <Typography fontWeight={400} size={12} type="primary-invert">
                    {user?.phone}
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {user?.getCreatedAt}
                  </Typography>
                </Td>
                <Td>
                  <Chips
                    label={user?.emailVerified ? STATUS_EMAIL_VERIFIED.VERIFIED : STATUS_EMAIL_VERIFIED.UNVERIFIED}
                    type={user?.emailVerified ? 'primary-light' : 'danger-light'}
                  />
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {user?.getVerifiedAt}
                  </Typography>
                </Td>
                <Td>
                  <Chips
                    label={user?.userVerification?.status ?? STATUS_CORPORATE.REQUEST}
                    type={user?.getStatusUserColor ?? 'warning-light'}
                  />
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {user?.userVerification?.getCreatedAt}
                  </Typography>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </div>
        <TableDataReferral />
        {/*<TableListReferralMember />*/}
      </div>
    </div>
  );
};

export default DetailAffiliate;
